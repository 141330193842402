import React, { useEffect, useState } from "react";
import { State } from "../../../../../../../rootReducer";
import { connect } from "react-redux";
import { TextareaAutosize } from "@material-ui/core";

import "./AddTreatyStage.scss";
import {
  setExtraFormTreatyEditFormOpen,
  setExtraFormTreatyStageData,
  setExtraFormTreatyDefaultOpen,
  updateTreatyStageStore,
  addStageTreaty,
  setExtraFormTreatyTasksAndBPFormOpen,
} from "../../../../../actions";
import PreloaderLocal from "../../../../../../../common/components/PreloaderLocal/PreloaderLocal";
import { nanoid } from "nanoid";

const AddTreatyStage: React.FC<{
  isEditOpen: boolean;
  setExtraFormTreatyStageData: (mode: any) => void;
  setExtraFormTreatyEditFormOpen: (mode: boolean) => void;
  setExtraFormTreatyDefaultOpen: (mode: boolean) => void;
  name: string;
  isCreateStage: boolean;
  id: number | string;
  updateTreatyStageStore: (id: number | string, name: string) => void;
  addStageTreaty: (data: any) => void;
  setExtraFormTreatyTasksAndBPFormOpen: (mode: boolean) => void;
}> = ({
  isEditOpen,
  setExtraFormTreatyStageData,
  name,
  setExtraFormTreatyEditFormOpen,
  setExtraFormTreatyDefaultOpen,
  isCreateStage,
  id,
  updateTreatyStageStore,
  addStageTreaty,
  setExtraFormTreatyTasksAndBPFormOpen
}) => {
  const [newStageName, setNewStageName] = useState<string>("")
  const onEditHandler = (e) => {
    setExtraFormTreatyStageData({ nameStage: e.target.value });
    setNewStageName(e.target.value)
  };

  const onCloseHandler = () => {
    setExtraFormTreatyEditFormOpen(false);
    setExtraFormTreatyDefaultOpen(true);
    setExtraFormTreatyStageData({ nameStage: "" });
  };

  const onSubmitHandler = () => {
    if (isCreateStage){addStageTreaty({ id: nanoid(6), name: newStageName, isNew: true });
    setExtraFormTreatyStageData({ nameStage: "" });
  }
    else {updateTreatyStageStore(id, name)}
  };

  useEffect(() => {
    if(isCreateStage){
      setExtraFormTreatyStageData({ nameStage: "" });
    }
  },[isCreateStage])

  return (
    <div
      className={
        "extra-form-stage-add" +
        (isEditOpen || isCreateStage ? " extra-form-stage-add--open" : "")
      }
    >
      <h2 className="extra-form-stage-add__stage-title">
        {isCreateStage
          ? "Добавляем этап в структуру исполнения договора"
          : "Редактируем наименование этапа в структуре исполнения договора"}
      </h2>
      <TextareaAutosize
        className={"add_stage_form"}
        onChange={(e) => {
          onEditHandler(e);
        }}
        placeholder="Укажите наименование этапа"
        value={name}
      />
      <div className="extra-form-stage-add__btns">
        <button
          className={
            "extra-form-stage-add__btn extra-form-stage-add__btn--submit"
          }
          onClick={onSubmitHandler}
          disabled={!name}
        >
          <span className="extra-form-stage-add__btn-title">
            {isCreateStage ? "Добавить" : "Сохранить"}
          </span>
          <span className="extra-form-stage-add__btn-preloader">
            <PreloaderLocal />
          </span>
        </button>

        <button
          className="extra-form-stage-add__btn extra-form-stage-add__btn--cancel"
          onClick={onCloseHandler}
        >
          <span className="extra-form-stage-add__btn-title">Отменить</span>
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = (state: State) => {
  return {
    isEditOpen: state.typicalTreatyPage.extraForm.isEditStageOpen,
    isCreateStage: state.typicalTreatyPage.extraForm.isCreateStageOpen,
    name: state.typicalTreatyPage.extraForm.isEditStage.nameStage,
    id: state.typicalTreatyPage.extraForm.isEditStage.id,
  };
};

const mapDispatchToProps = {
  setExtraFormTreatyStageData,
  setExtraFormTreatyEditFormOpen,
  setExtraFormTreatyDefaultOpen,
  updateTreatyStageStore,
  addStageTreaty,
  setExtraFormTreatyTasksAndBPFormOpen
};

export default connect(mapStateToProps, mapDispatchToProps)(AddTreatyStage);
