import { type } from "os";
import { TypeTreatyActual, TypeTypicalTreatiesActivity } from "./action_types";

export interface INormalFormTreaty {
  main?: boolean,
  id?: string | number,
  name?: string;
  description?: string;
  actualTreatyId?: TypeTreatyActual;
  idTreatyEdit?: number | string;
  stages?: Stage[] | any;
  author?: any;
  createdAt?: string;
  activity?: TypeTypicalTreatiesActivity;
  selectedItemId?: number | string;
}

export enum TabIdsExtraFormTreaty {
  BPS_TAB,
  TASKS_TAB
}

export interface IExtraFormData {
  treatyBp: any;
  currentPageBpListTreaty: number;
  isLoading: boolean;
  // treatyTasks: [];
  isExtraFormTreatyDefaultOpen: boolean;
  isTasksAndBpOpen: boolean;
  isEditStageOpen: boolean;
  isCreateStageOpen: boolean;
  isEditStage: IEditStage,
  activeTabIndex: number,
  searchTypicalBps: string | null;
  treatyForFilter: ITreatyFilterFormExtra;
}

export interface IEditStage {
    id: number | string;
    nameStage: string;
}

export enum TypicalTreatyActual {
  ACTUAL_TEXT = "Требуется",
  NOT_ACTUAL_TEXT = "Не требуется",
  ACTUAL_STATUS = 1,
  NOT_ACTUAL_STATUS = 0,
}

export interface ISortConditions {
  columnId: string;
  value: number;
}

export enum TypicalTreatiesActivity {
  ALL_TEXT = "Все",
  ACTIVE_TEXT = "Активный",
  ACTIVE_TEXT_FORM = "Активный",
  NOT_ACTIVE_TEXT_FORM = "Неактивный",
  NOT_ACTIVE_TEXT = "Неактивный",
  ALL_STATUS = 0,
  ACTIVE_STATUS = 1,
  NOT_ACTIVE_STATUS = 2,
}

export enum TypicalTreatiesPersonal {
  NOT_PERSONAL_STATUS = 0,
  PERSONAL_STATUS = 1,
}


export interface IMainTreatyServerData {
  id: number;
  name: string;
  description: string;
  stage_count: number;
  task_count: number;
  bp_count: number;
  duration: string;
  author: string;
  company_id: number;
  stages: any;
  created_at: string;
}

export type Stage = {
  id: number;
  name: string;
  status: number;
  author: {
    id: number;
    surname: string;
    name: string;
    image: string;
    is_active: boolean;
  };
  item_list: StageItemList[],
  company_id: number;
  created_at: string;
  count_bp?: number | string;
  count_task?: number | string;
  duration?: any;
}

export type StageItemList = {
  duration?: any;
  order_id: number;
  type: number;
  item: {
    id: number;
    name: string;
    task_count: number;
    duration: number;
    typical_tasks_nodes: {
      id: number;
      name: string;
      duration: number;
    }[];
  };
}


export interface ITypicalTreatiesServerData {
  id: number;
  name: string;
  description: string;
  stage_count: number;
  task_count: number;
  bp_count: number;
  duration: string;
  author: {
    id: number;
    surname: string;
    name: string;
    image: string;
    is_active: boolean;
  };
  status: boolean;
  company_id: number;
  stages: {
    id: number;
    name: string;
    status: boolean;
    author: {
      id: number;
      surname: string;
      name: string;
      image: string;
      is_active: boolean;
    };
    company_id: number;
    item_list: {
      order_id: number;
      type: number;
      item: {
        id: number;
        name: string;
        task_count: number;
        duration: number;
        typical_tasks_nodes: {
          id: number;
          name: string;
          duration: number;
        }[];
      };
    }[];
    created_at: string;
  };
  created_at: string;
}

export interface IDeleteTypicalTreatiesModal {
  TtId?: string|number,
  isDeleteModalOpen?: boolean,
  isShowPreloader?: boolean,
  statusFromServer?: {constraints: string[] | []}
}

export interface IDeleteStageTreatiesModal {
  stageId?: string|number,
  isDeleteModalOpen?: boolean,
  isShowPreloader?: boolean,
  statusFromServer?: {constraints: string[] | []}
}

export interface IDeleteStageItemTreatiesModal {
  stageId?: string|number,
  itemId?: "",
  isDeleteModalOpen?: boolean,
  isShowPreloader?: boolean,
  statusFromServer?: {constraints: string[] | []}
}

export interface ITreatyFilterFormExtra {
  value: string|number,
  label: string
}