import { Action } from "redux";
import {
  ISortConditions,
  IExtraFormBPData,
  INormalFormBPData,
  BusinessProcessActivity,
  BusinessProcessPersonal,
  IDeleteBusinessProcessModal,
  ITaskConnectModal,
  IBPFilterFormExtra,
  IActiveBusinessProcessModal, 
  ITaskDeleteConnectionModal,
} from "./interfaces";
import { ITypicalTaskServerData } from "../TypicalTasks/interfaces";
import { IMeta } from "../../common/types";

export type TypeBPFilterActivity =
  | BusinessProcessActivity.ACTIVE_STATUS
  | BusinessProcessActivity.NOT_ACTIVE_STATUS;

  export type TypeBPFilterPersonal =
  | BusinessProcessPersonal.PERSONAL_STATUS
  | BusinessProcessPersonal.NOT_PERSONAL_STATUS;

export interface IBusinessPageState {
  businessProcess: {
    id: number;
    name: string;
    treaty: {
      name: string;
      id: number;
    }[];
    count_tasks: number;
    duration: string;
    author: {
      id: string | number;
      surname: string;
      name: string;
      image: string | null;
    };
    executor?: { name: string; surname: string };
    created_at: string;
    status: number;
  }[];
  currentPage: number;
  isLoadingBp: boolean;
  sortCondition: ISortConditions;
  isFormBPOpen: boolean;
  isFullFormBPOpen: boolean;
  isLoadingBpCard: boolean;
  isCreatingBP: boolean;
  isEditForm: boolean;
  idTaskEdited: boolean;
  normalFormBP: INormalFormBPData;
  filters: {
    activity: TypeBPFilterActivity;
    personal: TypeBPFilterPersonal;
  },
  extraFormBP: IExtraFormBPData;
  deleteBusinessProcessModal: IDeleteBusinessProcessModal;
  activeStateBusinessProcessModal: IActiveBusinessProcessModal;
  searchBP: string | null;
  taskConnectModal: ITaskConnectModal;
  taskDeleteConnectionModal: ITaskDeleteConnectionModal;
  businessProcessMeta: IMeta | null;
}

export enum BusinessPageActionTypes {
  GET_BUSINESS_PROCESS = "@@BUSINESS_PAGE/GET_BUSINESS_PROCESS",
  SET_BUSINESS_PROCESS = "@@BUSINESS_PAGE/SET_BUSINESS_PROCES",
  SET_CURRENT_PAGE = "@@BUSINESS_PAGE/SET_CURRENT_PAGE",
  SET_SORT_CONDITION = "@@BUSINESS_PAGE/SET_SORT_CONDITION",
  SET_LOADING_BUSINESS_PROCESS = "@@BUSINESS_PAGE/SET_LOADING_BUSINESS_PROCESS",
  SET_DELETE_MODAL_DATA_BUSINESS_PROCESS = "@@BUSINESS_PAGE/SET_DELETE_MODAL_DATA_BUSINESS_PROCESS",
  SET_BP_FULL_FORM_OPEN = "@@BUSINESS_PAGE/SET_BP_FULL_FORM_OPEN",
  SET_BP_FORM_OPEN = "@@BUSINESS_PAGE/SET_BP_FORM_OPEN",
  SET_BUSINESS_PROCESS_FILTER_ACTIVITY = "@@BUSINESS_PAGE/SET_BUSINESS_PROCESS_FILTER_ACTIVITY",
  SET_BUSINESS_PROCESS_FILTER_PERSONAL = "@@BUSINESS_PAGE/SET_BUSINESS_PROCESS_FILTER_PERSONAL",
  SET_DELETE_MODAL_BP = "@@BUSINESS_PAGE/SET_DELETE_MODAL_BP",
  DELETE_BUSINESS_PROCESS = "@@BUSINESS_PAGE/DELETE_BUSINESS_PROCESS",
  CHECK_DELETE_BUSINESS_PROCESS =  "@@BUSINESS_PAGE/CHECK_DELETE_BUSINESS_PROCESS",
  DELETE_BUSINESS_PROCESS_FROM_STATE = "@@BUSINESS_PAGE/DELETE_BUSINESS_PROCESS_FROM_STATE",
  SET_TASK_CONNECT_MODAL_BP = "@@BUSINESS_PAGE/SET_TASK_CONNECT_MODAL_BP",
  SET_ACTION_CONNECT_ID_TRIGGER = "@@BUSINESS_PAGE/SET_ACTION_CONNECT_ID_TRIGGER",
  SET_TASK_DELETE_CONNECTION_MODAL_BP = "@@BUSINESS_PAGE/SET_TASK_DELETE_CONNECTION_MODAL_BP",
  SET_SEARCH_BUSINESS_PROCESS = "@@BUSINESS_PAGE/SET_SEARCH_BUSINESS_PROCESS",
  SET_NORMAL_FORM_BP = "@@BUSINESS_PAGE/SET_NORMAL_FORM_BP",
  SET_NORMAL_FORM_DEFAULT_VALUES_BP = "@@BUSINESS_PAGE/SET_NORMAL_FORM_DEFAULT_VALUES_BP",
  CREATE_BP = "@@BUSINESS_PAGE/CREATE_BP",
  SET_LOADING_TYPICAL_TASKS_BP = "@@BUSINESS_PAGE/SET_LOADING_TYPICAL_TASKS_BP",
  GET_TYPICAL_TASKS_BP = "@@BUSINESS_PAGE/GET_TYPICAL_TASKS_BP",
  SET_TYPICAL_TASKS_BP = "@@BUSINESS_PAGE/SET_TYPICAL_TASKS_BP",
  SET_CURRENT_PAGE_TYPICAL_TASKS_BP = "@@BUSINESS_PAGE/SET_CURRENT_PAGE_TYPICAL_TASKS_BP",
  SET_SEARCH_TYPICAL_TASKS_BP = "@@BUSINESS_PAGE/SET_SEARCH_TYPICAL_TASKS_BP",
  SET_BP_META =  "@@BUSINESS_PAGE/SET_BP_META",
  SET_EXTRA_FORM_BP_DEFAULT_OPEN = "@@BUSINESS_PAGE/SET_EXTRA_FORM_BP_DEFAULT_OPEN",
  SET_BP_FORM_FILTER = "@@BUSINESS_PAGE/SET_BP_FORM_FILTER",
  SET_TASK_ITEMS_BP = "@@BUSINESS_PAGE/SET_TASK_ITEMS_BP",
  SET_IS_EDIT_FORM_BP = "@@BUSINESS_PAGE/SET_IS_EDIT_FORM_BP",
  GET_ONE_BP =  "@@BUSINESS_PAGE/GET_ONE_BP",
  UPDATE_BP_STORE =  "@@BUSINESS_PAGE/UPDATE_BP_STORE",
  UPDATE_BP = "@@BUSINESS_PAGE/UPDATE_BP",
  SET_LOADING_BPS = "@@BUSINESS_PAGE/SET_LOADING_BPS",
  SET_ACTIVE_STATE_MODAL_BP = "@@BUSINESS_PAGE/SET_ACTIVE_STATE_MODAL_BP",
  CHECK_ACTIVE_STATE_BP = "@@BUSINESS_PAGE/CHECK_ACTIVE_STATE_BP",
  SET_IS_CREATING_BP = "@@BUSINESS_PAGE/SET_IS_CREATING_BP",
  SET_TREATY_FORM_FILTER_TASKS = "@@BUSINESS_PAGE/SET_TREATY_FORM_FILTER_TASKS",
}

export interface setIsCreatingBpAction
  extends Action<BusinessPageActionTypes.SET_IS_CREATING_BP> {
  isCreatingBP: boolean;
}

export interface getBusinessProcessAction
  extends Action<BusinessPageActionTypes.GET_BUSINESS_PROCESS> {
  params?: object;
  action?: string;
}
export interface setBusinessProcessAction
  extends Action<BusinessPageActionTypes.SET_BUSINESS_PROCESS> {
  businessProcess: any;
  action?: string;
}
export interface setCurrentPageAction
  extends Action<BusinessPageActionTypes.SET_CURRENT_PAGE> {
  currentPage: number;
}
export interface setSortConditionAction
  extends Action<BusinessPageActionTypes.SET_SORT_CONDITION> {
  sortCondition: ISortConditions;
}

export interface setLoadingBusinessProcessAction
  extends Action<BusinessPageActionTypes.SET_LOADING_BUSINESS_PROCESS> {
  isLoadingBp: boolean;
}

export interface setLoadingBPCardAction
  extends Action<BusinessPageActionTypes.SET_LOADING_BPS> {
  isLoadingBpCard: boolean;
}

export interface setFormBPOpenAction
  extends Action<BusinessPageActionTypes.SET_BP_FORM_OPEN> {
  isFormBPOpen: boolean;
}

export interface setBPFullFormOpenAction
  extends Action<BusinessPageActionTypes.SET_BP_FULL_FORM_OPEN> {
  isFullFormBPOpen: boolean;
}

export interface setBPFilterActivityAction
  extends Action<BusinessPageActionTypes.SET_BUSINESS_PROCESS_FILTER_ACTIVITY> {
  activity: TypeBPFilterActivity;
}

export interface setBPFilterPersonalAction
  extends Action<BusinessPageActionTypes.SET_BUSINESS_PROCESS_FILTER_PERSONAL> {
  personal: TypeBPFilterPersonal;
  }
export interface setDeleteModalBpAction extends Action<BusinessPageActionTypes.SET_DELETE_MODAL_BP> {
  data: IDeleteBusinessProcessModal
}

export interface deleteBpAction extends Action<BusinessPageActionTypes.DELETE_BUSINESS_PROCESS> {
  id: string|number
}

export interface deleteBpFromStateAction extends Action<BusinessPageActionTypes.DELETE_BUSINESS_PROCESS_FROM_STATE> {
  id: string|number
}
  export interface checkDeleteBpAction
  extends Action<BusinessPageActionTypes.CHECK_DELETE_BUSINESS_PROCESS> {
  id: string | number;
}

export interface setTaskConnectModalBpAction extends Action<BusinessPageActionTypes.SET_TASK_CONNECT_MODAL_BP> {
  data: ITaskConnectModal
}

export interface setActionConnectIdTriggerAction extends Action<BusinessPageActionTypes.SET_ACTION_CONNECT_ID_TRIGGER> {
  idTrigger: number|null
}

export interface setTaskDeleteConnectionModalBpAction extends Action<BusinessPageActionTypes.SET_TASK_DELETE_CONNECTION_MODAL_BP> {
  data: ITaskDeleteConnectionModal
}

export interface setSearchBPAction extends Action<BusinessPageActionTypes.SET_SEARCH_BUSINESS_PROCESS> {
  searchBP: string|null;
}

export interface setNormalFormBPAction
  extends Action<BusinessPageActionTypes.SET_NORMAL_FORM_BP> {
  normalFormBP: INormalFormBPData;
}

export interface setNormalFormDefaultValuesBPAction
  extends Action<BusinessPageActionTypes.SET_NORMAL_FORM_DEFAULT_VALUES_BP> {

}

export interface createBPAction
  extends Action<BusinessPageActionTypes.CREATE_BP> {
  data: object;
}

export interface setLoadingTypicalTasksBpAction
  extends Action<BusinessPageActionTypes.SET_LOADING_TYPICAL_TASKS_BP> {
  isLoading: boolean;
}

export interface getTypicalTasksBPExtraFormAction
  extends Action<BusinessPageActionTypes.GET_TYPICAL_TASKS_BP> {
  params?: object;
  action?: string;
}
export interface setTypicalTasksBPExtraFormAction
  extends Action<BusinessPageActionTypes.SET_TYPICAL_TASKS_BP> {
  typicalTasksBp: ITypicalTaskServerData | ITypicalTaskServerData[];
  action?: string; 
}

export interface setCurrentPageTypicalTasksBpAction
  extends Action<BusinessPageActionTypes.SET_CURRENT_PAGE_TYPICAL_TASKS_BP> {
  currentPageTypicalTasks: number;
}

export interface setSearchTypicalTasksBpAction extends Action<BusinessPageActionTypes.SET_SEARCH_TYPICAL_TASKS_BP> {
  searchTypicalTasksBp: string|null;
}

export interface setBPMetaAction extends Action<BusinessPageActionTypes.SET_BP_META> {
  businessProcessMeta: IMeta;
}

export interface setExtraFormBPDefaultOpenAction
  extends Action<BusinessPageActionTypes.SET_EXTRA_FORM_BP_DEFAULT_OPEN> {
  isExtraFormBPDefaultOpen: boolean;
}

export interface setBPForFilterExtraAction
  extends Action<BusinessPageActionTypes.SET_BP_FORM_FILTER> {
  bpForFilter: IBPFilterFormExtra;
}

export interface setTaskItemsBpExtraAction
  extends Action<BusinessPageActionTypes.SET_TASK_ITEMS_BP> {
  taskItems: any;
  updateTrigger: string
}

export interface setIsEditFormAction
  extends Action<BusinessPageActionTypes.SET_IS_EDIT_FORM_BP> {
  isEditForm: boolean;
}

export interface getOneBPAction
  extends Action<BusinessPageActionTypes.GET_ONE_BP> {
  id: number|string;
}

export interface updateBPStoreAction
  extends Action<BusinessPageActionTypes.UPDATE_BP_STORE> {
    businessProcess: any;
}

export interface updateBPAction
  extends Action<BusinessPageActionTypes.UPDATE_BP> {
  data: object;
}

export interface setChangeActiveStateBPModalBpAction extends Action<BusinessPageActionTypes.SET_ACTIVE_STATE_MODAL_BP> {
  data: IActiveBusinessProcessModal
}

export interface checkActiveStateBpAction
extends Action<BusinessPageActionTypes.CHECK_ACTIVE_STATE_BP> {
id: string | number;
value: any
}

export interface setTreatyForFilterExtraTasksAction
  extends Action<BusinessPageActionTypes.SET_TREATY_FORM_FILTER_TASKS> {
  treatyForFilterTasks: IBPFilterFormExtra;
}


export type BusinessPageActions =
  | setIsCreatingBpAction
  | getBusinessProcessAction
  | setBusinessProcessAction
  | setCurrentPageAction
  | setSortConditionAction
  | setLoadingBusinessProcessAction
  | setBPFullFormOpenAction
  | setFormBPOpenAction
  | setBPFilterActivityAction
  | setBPFilterPersonalAction
  | setDeleteModalBpAction
  | deleteBpAction
  | deleteBpFromStateAction
  | setTaskConnectModalBpAction
  | setTaskDeleteConnectionModalBpAction
  | setActionConnectIdTriggerAction
  | checkDeleteBpAction
  | setSearchBPAction
  | setNormalFormBPAction
  | setNormalFormDefaultValuesBPAction
  | createBPAction
  | setLoadingTypicalTasksBpAction
  | getTypicalTasksBPExtraFormAction
  | setTypicalTasksBPExtraFormAction
  | setCurrentPageTypicalTasksBpAction
  | setSearchTypicalTasksBpAction
  | setBPMetaAction
  | setExtraFormBPDefaultOpenAction
  | setBPForFilterExtraAction
  | setTaskItemsBpExtraAction
  | setIsEditFormAction
  | getOneBPAction
  | updateBPStoreAction
  | updateBPAction
  | setLoadingBPCardAction
  | setChangeActiveStateBPModalBpAction
  | checkActiveStateBpAction
  | setTreatyForFilterExtraTasksAction;
