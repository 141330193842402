import React, { useState } from "react";
import { connect } from "react-redux";
import { State } from "../../../../../../rootReducer";
import './styles.scss'

const columnsOfTables = [
  {
    columnId: "id",
    showingText: "№",
    style: { minWidth: "110px", paddingLeft: "38px" },
  },
  {
    columnId: "nameTask",
    showingText: "Задача",
    style: { minWidth: "65%", maxWidth: "800px", marginLeft: "11px" },
  },
  {
    columnId: "executor_id",
    showingText: "Исполнитель",
    style: {
      width: "210px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
  {
    columnId: "status",
    showingText: "Cтатус",
    style: { minWidth: "222px", },
  },
  {
    columnId: "end",
    showingText: "Дедлайн",
    style: { minWidth: "195px" },
  },
  {
    columnId: "priority_id",
    showingText: "Приоритет",
    style: { minWidth: "195px" },
  },
];

const ObjectTasksTableHead: React.FC<{}> = ({}) => {
  const [Clicked, setClicked] = useState<any>({ col: "id", count: 1 });

  return (
    <div className="tasks_table_head_objects_task">
      <div className="block_head-objects-task">
        {Object.keys(columnsOfTables).map((key) => {
          return (
            (columnsOfTables[key].columnId === "id" ||
              columnsOfTables[key].columnId === "nameTask") && (
              <div
                className="header_item_objects_task"
                key={key}
                style={columnsOfTables[key].style}
                onClick={() => {
                  setClicked({
                    col: columnsOfTables[key].columnId,
                    count:
                      Clicked.col === columnsOfTables[key].columnId
                        ? Clicked.count === 2
                          ? 1
                          : Clicked.count + 1
                        : 1,
                  });
                }}
              >
                <span>{columnsOfTables[key].showingText}</span>
              </div>
            )
          );
        })}
      </div>

      <div className="block_head-objects-task__right" >
        {Object.keys(columnsOfTables).map((key) => {
          return columnsOfTables[key].columnId === "executor_id" ||
            columnsOfTables[key].columnId === "status" ||
            columnsOfTables[key].columnId === "end" ||
            columnsOfTables[key].columnId === "priority_id" ? (
            <div
              className="header_item_objects_task"
              key={columnsOfTables[key].columnId}
              style={columnsOfTables[key].style}
              onClick={() => {
                setClicked({
                  col: columnsOfTables[key].columnId,
                  count:
                    Clicked.col === columnsOfTables[key].columnId
                      ? Clicked.count === 2
                        ? 1
                        : Clicked.count + 1
                      : 1,
                });
              }}
            >
              <span>{columnsOfTables[key].showingText}</span>
            </div>
          ) : null;
        })}
      </div>
    </div>
  );
};

const mapStateToProps = (state: State) => {
  return {};
};

const mapDispatchToProps = {};

export default connect()(ObjectTasksTableHead);
