import React, { useEffect, useState } from "react";
// import Input from '../../../common/components/Inputs/Input';
import SectionElem from "./SectionElem";
import { connect, useDispatch } from "react-redux";
import { setProjectSections, addSection } from "../actions";
import { State } from "../../../rootReducer";
import { getSections } from "../selectors";
import styled from "styled-components";
import { SectionInComponent } from "../../../common/types";

import Button from "../../../common/components/newComponent/Buttons/Button";
import CustomInput from "../../../common/components/newComponent/Inputs/CustomInput";
import {
  fetchProjectSectionsTaskPlate,
  setProjectSectionsTaskPlate,
} from "../../TaskInfoPlate/actions";
import {
  fetchProjectSectionsTask,
  setProjectSectionsTask,
} from "../../NewTask/actions";
import {
  setTaskSection
} from "../../../pages/TaskInfoPlate/actions"

type Props = {
  setTaskSection: (id: number) => void;
  projectId: number;
  sections: SectionInComponent[];
  setProjectSections: (sections: SectionInComponent[]) => void;
  addSection: (projectId: number, name: string) => void;
  closeHandler:() => void;
  saveHandler:() => void;
};

const WrapperBlockInput = styled.div`
  display: flex;
  justify-content: space-between;
`;

const WrapperBlockSections = styled.div`
  max-width: 500px;
  width: 100%;

  li {
    display: flex;
    justify-content: space-between;
    min-height: 24px;
    line-height: 24px;
    margin: 0.5rem 0;
  }
`;

let sectionsLength=0;

const ProjectSections: React.FC<Props> = ({
  setTaskSection,
  closeHandler,
  saveHandler,
  sections,
  projectId,
  setProjectSections,
  addSection,
}) => {
  const [sectionName, setSectionName] = useState("");
  const dispatch = useDispatch();
  const [isAddSection,setIsAddSection]=useState(false);


  useEffect(
    ()=>{
     // console.log("***sections",sections);
      // setIsAddSection(false);
     if (sections)  sectionsLength = sections.length;

    },[]
  )

  useEffect(
    ()=>{
      if (sections && (sections.length - sectionsLength)===1) {
        // console.log("new Id sections:",sections.length-1 )
        setTaskSection(sections[sections.length-1].id as number);
      }
     // console.log("sections******",sections);
    },[sections]
  )

  const addHandler = () => {
    if (!sectionName) return;
    if (projectId) {
      addSection(projectId, sectionName);
      setIsAddSection(true);
      dispatch(setProjectSectionsTask([...sections]));
      dispatch(setProjectSectionsTaskPlate([...sections]));
      dispatch(fetchProjectSectionsTaskPlate(projectId));
      dispatch(fetchProjectSectionsTask(projectId));
    } else {
      dispatch(
        setProjectSectionsTask([...sections, { name: sectionName, id: 0 }])
      );
      setProjectSections([...sections, { name: sectionName, id: 0 }]);
      dispatch(fetchProjectSectionsTaskPlate(projectId));
      dispatch(fetchProjectSectionsTask(projectId));
    }
    setSectionName("");
    saveHandler();
  };

  return (
    <WrapperBlockSections className="project_sections">
      {/*sections.length > 0 && (
        <ol>
          {sections.map((section, ind) => (
            <SectionElem
              name={section.name}
              id={section.id ? section.id : 0}
              key={`${performance.now()}_${ind}`}
              index={ind}
            />
          ))}
        </ol>
          )*/}
      <WrapperBlockInput>
        {/* <Input
          inputType="text"
          placeholder=""
          title=""
          value={sectionName}
          changeHandler={setSectionName}
        /> */}
        <CustomInput
          // placeholder="Обязательное поле"
          value={sectionName}
          inputChangeHandler={setSectionName}
        />
        <Button
          onClick={()=>addHandler()}
          title="Добавить"
          design="outline"
          style={{ marginLeft: ".5rem", alignItems: "center" }}
        />
      </WrapperBlockInput>
    </WrapperBlockSections>
  );
};

const mapStateToProps = (state: State) => {
  return {
    sections: getSections(state),
    projectId: state.newProject.id,
  };
};

const mapDispatchToProps = {
  setTaskSection,
  setProjectSections,
  addSection,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectSections);
