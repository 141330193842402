import React from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { State } from "../../../../../rootReducer";
import { IBPFilterFormExtra, IBusinessProcessServerData } from "../../../interfaces";
import { setBPForFilterExtra, setCurrentPageTypicalTasksBp } from "../../../actions";

const FilteTypicalTaskForBP: React.FC<{
  bp: IBusinessProcessServerData[];
  bpSelect: IBPFilterFormExtra
  setBPForFilterExtra: (mode: IBPFilterFormExtra) => void;
  setCurrentPageTypicalTasksBp: (mode: number) => void;
}> = ({ bp, bpSelect, setBPForFilterExtra, setCurrentPageTypicalTasksBp }) => {
  const bpForSelect = bp?.filter((item) => item.status)
    .map((item) => {
      return {
        value: item.id,
        label: item.name,
      };
    });

  let filters = [
    { value: "all", label: "Не важно" },
    { value: "notActive", label: "Не используется" },
  ].concat(bpForSelect as any);

  const onChangeBPHandler = (value: IBPFilterFormExtra) => {
    setCurrentPageTypicalTasksBp(1)
    setBPForFilterExtra(value)
  }
  
  return (
    <>
      <Select
        type="text"
        value={bpSelect}
        options={filters}
        className="react-select"
        classNamePrefix="react-select"
        onChange={(value) => {onChangeBPHandler(value)}}
      />
    </>
  );
};

const mapStateToProps = (state: State) => {
  return {
    bp: state.businessPage.businessProcess,
    bpSelect: state.businessPage.extraFormBP.bpForFilter
  };
};

const mapDispatchToProps = {
    setBPForFilterExtra,
    setCurrentPageTypicalTasksBp
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilteTypicalTaskForBP);
