import React, { FC, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { State } from "../../../../../../../../rootReducer";
import { getUserForSelect } from "../../../../../../selectors";
import { ObjectsPageActionTypes } from "../../../../../../action_types";
import { setObjectsUserResponsibleSelected } from "../../../../../../actions";
import Select from "react-select";
import { IEditingObjectsFormData, IUserForSelect } from "../../../../../../interfaces";
import { errorValidationMessages } from "../../../../../../constants";

import './ResponsibilitySelect.scss';

const ResponsibilitySelect: FC<{
  setObjectsUserResponsibleSelected: ( userSelected: { value: string, label: string }) => void,
  usersForSelect: IUserForSelect[],
  isEditingObjectsFormOpen: boolean,
  userSelected?: IUserForSelect,
  checkTrigger?: string,
  name: keyof IEditingObjectsFormData
}> = ({
  setObjectsUserResponsibleSelected,
  usersForSelect,
  isEditingObjectsFormOpen,
  userSelected,
  checkTrigger,
  name
}) => {
  const dispatch = useDispatch();
  
  const [skipCheck, setSkipCheck] = useState<boolean>(true);
  const [errorState, setErrorState] = useState<string[]>([]);
  
  const errorMessages = {
    required: errorValidationMessages.REQUIRED
  };

  const rules = {
    responsible: [{required: true}]
  };
  
  const checkHelper = () => {
    if(skipCheck) {
      setSkipCheck(false);
      return;
    }

    setErrorState([]);

    if(name) {
      if(rules[name]) {
        rules[name].forEach(rule => {
          if(rule['required'] && !userSelected?.value) {
            setErrorState((state) => ([ ...state, errorMessages[ 'required' ]]));
          }
        });
      }
    }
  };
  
  useEffect(() => {
    if(isEditingObjectsFormOpen) {
      dispatch({type: ObjectsPageActionTypes.GET_OBJECTS_USERS_RESPONSIBLE});
    }
  }, [isEditingObjectsFormOpen]);

  useEffect(() => {
    checkHelper();
  }, [checkTrigger, userSelected]);
  
  return (
    <>
      <Select
        type="text"
        value={userSelected}
        isClearable={true}
        options={usersForSelect}
        className={"react-select" +
                  (errorState.length ? ' react-select--error' : '')
        }
        classNamePrefix="react-select"
        onChange={(value) => {
          if(value) {
            setObjectsUserResponsibleSelected(value);
          } else {
            setObjectsUserResponsibleSelected({value: '', label: ''});
          }
        }}
      />

      {errorState.length > 0 && (
        <div className="input-objects__error-messages">
          {errorState.map((error, i) => (
            <div className="input-objects__error-message" key={i}>
              {error}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state: State) => {
  return {
    usersForSelect: getUserForSelect(state),
    isEditingObjectsFormOpen: state.objectsPage.isEditingObjectsFormOpen,
    userSelected: state.objectsPage.editingObjectsForm.data.responsible?.userSelected,
    checkTrigger: state.objectsPage.editingObjectsForm.checkTrigger
  };
};

const mapDispatchToProps = {
  setObjectsUserResponsibleSelected
};

export default connect(mapStateToProps, mapDispatchToProps)(ResponsibilitySelect);
