import React, { useEffect } from "react";
import { setProvideTo } from "../../../TaskInfoPlate/actions";
import { connect, useDispatch } from "react-redux";
import { State } from "../../../../rootReducer";
import Checkbox from "../../../../common/components/CheckboxItem/Checkbox";
import {
  getTaskObserversData,
} from "../../../TaskInfoPlate/selectors";
import { setNormalFormData } from "../../actions";
import Select from "react-select";
import './executors.scss'
import TooltipQuestion from "../../../../common/components/ui/TooltipQuestion/TooltipQuestion";
import { TypicalTasksTooltips } from "../../../../common/constants";
import { IExecutorFormNormal, INormalFormData } from "../../interfaces";
import { TypicalTasksActionTypes } from "../../action_types";
import { getUsers } from "../../selectors";
import { User } from "../../../../common/types";
import PreloaderLocal from "../../../../common/components/PreloaderLocal/PreloaderLocal";

type Props = {
  checkedPersonalTask?: boolean;
  disabled?: boolean;
  delegation?: boolean;
  setProvideTo: (val: number) => void;
  observersSelectData: any;
  setNormalFormData?: (normalFormData: INormalFormData) => void,
  executor?: IExecutorFormNormal,
  usersForSelect: User[],
  isFormTasksOpen: boolean,
  wasFetchedSlowRequests: boolean
};

const ExecutorSelect: React.FC<Props> = ({
  delegation,
  setNormalFormData,
  executor,
  usersForSelect,
  isFormTasksOpen,
  wasFetchedSlowRequests
}) => {
  const dispatch = useDispatch();
  
  const executors = usersForSelect?.map((user) => {
    return {
      value: user.id,
      label: user.surname + ' ' + user.name,
    };
  });
  
  const onChangeExecutorHandler = (value: IExecutorFormNormal) => {
    if(setNormalFormData) {
      setNormalFormData({executor: value});
    }
  }
  
  const onCheckboxCheckedHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if(setNormalFormData) {
      setNormalFormData({delegation: e.target.checked});
    }
  }

  useEffect(() => {
    if(isFormTasksOpen) {
      dispatch({type: TypicalTasksActionTypes.GET_TYPICAL_TASKS_USERS_FOR_FILTER});
    }
  }, [isFormTasksOpen]);
  
  return (
    <div className="executors-select">
      <div className="executors-select__wr">
        <div className={'executors-select__preloader' +
                      (!wasFetchedSlowRequests ? ' executors-select__preloader--show' : '')
        }>
          <PreloaderLocal/>
        </div>
        
        <Select
          type="text"
          value={executor}
          isClearable={true}
          options={executors}
          className="react-select"
          classNamePrefix="react-select"
          onChange={(value) => {
            if(value) {
              onChangeExecutorHandler(value);
            } else {
              onChangeExecutorHandler({value: '', label: ''});
            }
          }}
        />
      </div>
      
      <div className="block_checkbox">
        <Checkbox
          disabled={false}
          checked={delegation}
          onChange={(e) => {
            onCheckboxCheckedHandler(e as React.ChangeEvent<HTMLInputElement>);
          }}
        />
        <div className='block_checkbox__title'
          onClick={() => {
            onCheckboxCheckedHandler({target: {checked: !delegation}} as React.ChangeEvent<HTMLInputElement>);
          }}
          style={{ marginLeft: ".5rem" }}
        >
          Разрешить делегирование
        </div>

        <div className="block_checkbox__tooltip">
          <TooltipQuestion title={TypicalTasksTooltips.CHECKBOX_EXECUTORS} />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: State) => {
  return {
    delegation: state.typicalTasksPage.normalForm.delegation,
    executor: state.typicalTasksPage.normalForm.executor,
    observersSelectData: getTaskObserversData(state),
    usersForSelect: getUsers(state),
    isFormTasksOpen: state.typicalTasksPage.isFormTasksOpen,
    wasFetchedSlowRequests: state.commonInfo.wasFetchedSlowRequests
  };
};
 
const mapDispatchToProps = {
  setProvideTo,
  setNormalFormData
};

export default connect(mapStateToProps, mapDispatchToProps)(ExecutorSelect);
