import React, { FC } from "react";
import { columnsOfTableslTasks } from "../../sharedData";
import SortIcon from "../../../../common/components/SortIcon";
import { connect } from "react-redux";
import { State } from "../../../../rootReducer";
import { sortConditions } from "../../constants";
import { ISortConditions } from "../../interfaces";
import { getTasks, setCurrentPage, setSortCondition } from "../../actions";

import "./TableHeaderTasks.scss";

const TableHeaderTasks: FC<{
  sortCondition: ISortConditions;
  setSortCondition: (sortCondition: ISortConditions) => void;
  getTasks: (params?: object) => void;
  setCurrentPage: (currentPage: number) => void;
}> = ({ sortCondition, setSortCondition, getTasks, setCurrentPage }) => {
  const onClickCondition = (columnId, value) => {
    setCurrentPage(1);
    setSortCondition({ columnId, value });
    getTasks({ columnId, value });
  };

  return (
    <div className="tasks_table_header_tasks">
      <div className="block_table_head_tasks">
        {Object.keys(columnsOfTableslTasks).map((key) => {
          return columnsOfTableslTasks[key].columnId === sortConditions.ID ||
            columnsOfTableslTasks[key].columnId === sortConditions.NAME ? (
            <div
              className={`header_item_tasks__${columnsOfTableslTasks[key].columnId}`}
              key={columnsOfTableslTasks[key].columnId}
              //   style={columnsOfTableslTasks[key].style}
            >
              <span
                onClick={() => {

                  const value =
                    sortCondition.columnId ===
                    columnsOfTableslTasks[key].columnId
                      ? sortCondition.value === 2
                        ? 1
                        : 2
                      : 1;

                  onClickCondition(columnsOfTableslTasks[key].columnId, value);
                }}
                className={`header_items_tasks ${columnsOfTableslTasks[key].columnId}`}
              >
                <span className="text_header">
                  {columnsOfTableslTasks[key].showingText}
                </span>
                <SortIcon
                  active={
                    sortCondition.columnId ===
                    columnsOfTableslTasks[key].columnId
                  }
                  activeSort={
                    sortCondition.columnId ===
                      columnsOfTableslTasks[key].columnId &&
                    sortCondition.value === 1
                      ? "top"
                      : "bottom"
                  }
                />
              </span>
            </div>
          ) : null;
        })}
      </div>
      <div className="block_table_head_tasks">
        {Object.keys(columnsOfTableslTasks).map((key) => {
          return columnsOfTableslTasks[key].columnId ===
            sortConditions.AUTHOR ||
            columnsOfTableslTasks[key].columnId === sortConditions.EXECUTOR ||
            columnsOfTableslTasks[key].columnId === sortConditions.STATUS ||
            columnsOfTableslTasks[key].columnId === sortConditions.END ||
            columnsOfTableslTasks[key].columnId ===
              sortConditions.OBJECT_NAME ||
            columnsOfTableslTasks[key].columnId === sortConditions.PRIORITY ? (
            <div
              className={`header_item_tasks__${columnsOfTableslTasks[key].columnId}`}
              key={columnsOfTableslTasks[key].columnId}
              //   style={columnsOfTableslTasks[key].style}
            >
              <span
                onClick={() => {
                  const value =
                    sortCondition.columnId ===
                    columnsOfTableslTasks[key].columnId
                      ? sortCondition.value === 2
                        ? 1
                        : 2
                      : 1;

                  onClickCondition(columnsOfTableslTasks[key].columnId, value);
                }}
                className={`header_items_tasks ${columnsOfTableslTasks[key].columnId}`}
              >
                <span className="text_header">
                  {columnsOfTableslTasks[key].showingText}
                </span>
                <SortIcon
                  active={
                    sortCondition.columnId ===
                    columnsOfTableslTasks[key].columnId
                  }
                  activeSort={
                    sortCondition.columnId ===
                      columnsOfTableslTasks[key].columnId &&
                    sortCondition.value === 1
                      ? "top"
                      : "bottom"
                  }
                />
              </span>
            </div>
          ) : null;
        })}
      </div>
    </div>
  );
};

const mapStateToProps = (state: State) => ({
  sortCondition: state.tasksObjects.sortConditions,
});

const mapDispatchToProps = {
  setSortCondition,
  getTasks,
  setCurrentPage,
};

export default connect(mapStateToProps, mapDispatchToProps)(TableHeaderTasks);
