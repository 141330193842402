import {
  createObjectAction,
  setObjectsAction,
  ObjectsPageActionTypes,
  setCreatingObjectAction,
  setCurrentPageAction,
  setIsEditObjectFormAction,
  setLoadingObjectsAction,
  setObjectEditingFormActiveTabIndexAction,
  setObjectsEditingFormOpenAction,
  setObjectSectionTaskListAction,
  updateObjectsAction,
  updateObjectsStoreAction,
  setSortConditionAction,
  setObjectSectionListAction,
  setIsShowMenuPreloaderAction,
  checkDeleteObjectAction,
  setDeleteObjectAction,
  deleteObjectAction,
  deleteObjectFromStateAction,
  setDeleteTaskAction,
  checkDeleteTaskAction,
  setDeleteStateTaskRegularAction,
  deleteTaskAction,
  getObjectsAction,
  // deleteTaskSectionAction,
  // deleteTaskSectionFromStateAction,
  getObjectAction,
  setLoadingObjectAction,
  setObjectsEditingFormCheckTriggerAction,
  setObjectsEditingFormDataAction,
  setObjectsEditingIdAction,
  setObjectsUsersResponsibleAction,
  setObjectsUsersResponsibleSelectedAction,
  setObjectsUsersResponsibleLoadingAction,
  setCheckCreateObjectModalAction,
  deleteFileStoreAction,
  TypeObjectsFilterTerms,
  setObjectsFilterTermsAction,
  setObjectsFilterUsersAction,
  setObjectsFilterPersonalAction,
  TypeFilterPersonal,
  setObjectsUsersForFilterAction,
  setObjectsUsersForFilterIsLoadingAction,
  setSearchObjectsAction,
  deleteTaskFromStateAction,
  setObjectsEditFormDefaultValuesAction,
  getTaskStatisticAction,
  getObjectSectionAction,
  getTasksAction,
  setMetaAction,
  getMetaAction,
  updateTaskStoreAction, 
  updateObjectFilesStoreAction
} from "./action_types";
import { IDeleteObjectModal, IDeleteTaskModal, IObjectsServerData, ISortConditions } from "./interfaces";

import { ICheckAddTaskModal, IEditingObjectsFormData } from "./interfaces";
import { IMeta, User } from "../../common/types";

export const getMeta = (): getMetaAction => ({
  type: ObjectsPageActionTypes.GET_META
});

export const setMeta = (meta: IMeta): setMetaAction => ({
  type: ObjectsPageActionTypes.SET_META,
  meta
});

export const getObjects = (params?: object, action?: string): getObjectsAction => ({
  type: ObjectsPageActionTypes.GET_OBJECTS_DATA,
  params,
  action
});

export const getTasks = (sectionId: string|number): getTasksAction => ({
  type: ObjectsPageActionTypes.GET_TASKS,
  sectionId
});

export const getTaskStatistics = (sectionId: string|number): getTaskStatisticAction => ({
  type: ObjectsPageActionTypes.GET_TASK_STATISTIC,
  sectionId
});

export const getObjectSection = (objectId: string|number): getObjectSectionAction => ({
  type: ObjectsPageActionTypes.GET_OBJECT_SECTION,
  objectId
});

export const setObjects = (objects:  IObjectsServerData | IObjectsServerData[], action?: string): setObjectsAction => ({
  type: ObjectsPageActionTypes.SET_OBJECTS_DATA,
  objects,
  action
})

export const setObjectsFilterTerms = (terms: number[]): setObjectsFilterTermsAction => ({
  type: ObjectsPageActionTypes.SET_OBJECTS_FILTER_TERMS,
  terms
});

export const setObjectsFilterUsers = (users: number[]): setObjectsFilterUsersAction => ({
  type: ObjectsPageActionTypes.SET_OBJECTS_FILTER_USERS,
  users
});

export const setObjectsUsersForFilter = (usersDataForFilter: User[]): setObjectsUsersForFilterAction => ({
  type: ObjectsPageActionTypes.SET_OBJECTS_USERS_FOR_FILTER,
  usersDataForFilter
});

export const setObjectsFilterPersonal = (personal: TypeFilterPersonal): setObjectsFilterPersonalAction => ({
  type: ObjectsPageActionTypes.SET_OBJECTS_FILTER_PERSONAL,
  personal
});

export const setObjectsUsersForFilterIsLoading = (usersDataForFilterIsLoading: boolean): setObjectsUsersForFilterIsLoadingAction => ({
  type: ObjectsPageActionTypes.SET_OBJECTS_USERS_FOR_FILTER_IS_LOADING,
  usersDataForFilterIsLoading
});

export const setSearchObjects = (searchObjects: string|null): setSearchObjectsAction => ({
  type: ObjectsPageActionTypes.SET_SEARCH_OBJECTS,
  searchObjects
});

export const setCheckCreateObjectModal = (data: ICheckAddTaskModal): setCheckCreateObjectModalAction => ({
  type: ObjectsPageActionTypes.SET_CHECK_OBJECT_MODAL,
  data
});

export const createObject = (data: object): createObjectAction => ({
  type: ObjectsPageActionTypes.CREATE_OBJECT,
  data
});

export const updateObjects = (data: object, files: FileList): updateObjectsAction => ({
  type: ObjectsPageActionTypes.UPDATE_OBJECT,
  data,
  files
});

export const updateTaskStore = (task: any): updateTaskStoreAction => ({
  type: ObjectsPageActionTypes.UPDATE_TASK_STORE,
  task
});

export const updateObjectsStore = (data: IObjectsServerData): updateObjectsStoreAction => ({
  type: ObjectsPageActionTypes.UPDATE_OBJECTS_STORE,
  data
});

export const setIsEditObjectForm = (isEditObjectsForm: boolean): setIsEditObjectFormAction => ({
  type: ObjectsPageActionTypes.SET_IS_EDIT_OBJECT_FORM,
  isEditObjectsForm
});

export const setIsCreatingObject = (isCreatingObject: boolean): setCreatingObjectAction => ({
  type: ObjectsPageActionTypes.SET_CREATING_OBJECT,
  isCreatingObject
});

export const setObjectsEditingFormOpen = (isEditingObjectsFormOpen: boolean): setObjectsEditingFormOpenAction => ({
  type: ObjectsPageActionTypes.SET_OBJECTS_EDITING_FORM_OPEN,
  isEditingObjectsFormOpen
});

export const setObjectsEditingFormActiveTabIndex = (activeTabIndex: number): setObjectEditingFormActiveTabIndexAction => ({
  type: ObjectsPageActionTypes.SET_OBJECTS_EDITING_FORM_ACTIVE_TAB_INDEX,
  activeTabIndex
});

export const setLoadingObjects= (isLoadingObjects: boolean): setLoadingObjectsAction => ({
  type: ObjectsPageActionTypes.SET_LOADING_OBJECTS,
  isLoadingObjects
});

export const setCurrentPage = (currentPage: number): setCurrentPageAction => ({
  type: ObjectsPageActionTypes.SET_CURRENT_PAGE,
  currentPage
});

export const setObjectSectionsList = (sections: any[]): setObjectSectionListAction => ({
  type: ObjectsPageActionTypes.SET_OBJECT_SECTION_LIST,
  sections,
});

export const setObjectSectionTaskList = (tasks: any[]): setObjectSectionTaskListAction => ({
  type: ObjectsPageActionTypes.SET_SECTION_TASK_LIST,
  tasks,
});

export const setSortCondition = (sortCondition: ISortConditions): setSortConditionAction => ({
  type: ObjectsPageActionTypes.SET_SORT_CONDITION,
  sortCondition
});

export const setIsShowMenuPreloader = (isShowMenuPreloader: boolean): setIsShowMenuPreloaderAction => ({
  type: ObjectsPageActionTypes.SET_IS_SHOW_MENU_PRELOADER,
  isShowMenuPreloader
});

export const deleteObject = (id: string|number): deleteObjectAction => ({
  type: ObjectsPageActionTypes.DELETE_OBJECT,
  id
});

export const setDeleteObjectModalData = (data: IDeleteObjectModal): setDeleteObjectAction => ({
  type: ObjectsPageActionTypes.SET_DELETE_OBJECT_MODAL_DATA,
  data
});

export const setDeleteTaskModalData = (data: IDeleteTaskModal): setDeleteTaskAction => ({
  type: ObjectsPageActionTypes.SET_DELETE_TASK_MODAL_DATA,
  data
});

export const checkDeleteObject = (id: string|number): checkDeleteObjectAction => ({
  type: ObjectsPageActionTypes.CHECK_DELETE_OBJECT,
  id
});

export const checkDeleteTask = (id: string|number): checkDeleteTaskAction => ({
  type: ObjectsPageActionTypes.CHECK_DELETE_TASK,
  id
});

export const deleteObjectFromState = (id: string|number): deleteObjectFromStateAction => ({
  type: ObjectsPageActionTypes.DELETE_OBJECT_FROM_STATE,
  id
});

export const setDeleteStateTaskRegular = (deleteStateRegular: boolean): setDeleteStateTaskRegularAction => ({
  type: ObjectsPageActionTypes.SET_DELETE_TASK_REGULAR,
  deleteStateRegular
});


export const deleteTaskSection = (taskId: string|number): deleteTaskAction => ({
  type: ObjectsPageActionTypes.DELETE_TASK_SECTION,
  taskId,
});

export const deleteTaskSectionFromState = (id: string|number): deleteTaskFromStateAction => ({
  type: ObjectsPageActionTypes.DELETE_TASK_SECTION_FROM_STATE,
  id
});
export const setObjectEditingFormData = (editingFormData: IEditingObjectsFormData): setObjectsEditingFormDataAction => ({
  type: ObjectsPageActionTypes.SET_OBJECTS_EDITING_FORM_DATA,
  editingFormData
});

export const setObjectEditingFormCheckTrigger = (checkTrigger: string): setObjectsEditingFormCheckTriggerAction => ({
  type: ObjectsPageActionTypes.SET_OBJECTS_EDITING_FORM_CHECK_TRIGGER,
  checkTrigger
});

export const getObject = (id: number|string): getObjectAction => ({
  type: ObjectsPageActionTypes.GET_OBJECT,
  id
});

export const setLoadingObject = (isLoadingObject: boolean): setLoadingObjectAction => ({
  type: ObjectsPageActionTypes.SET_LOADING_OBJECT,
  isLoadingObject
});

export const setObjectEditingId = (idObjectEdited: string|number): setObjectsEditingIdAction => ({
  type: ObjectsPageActionTypes.SET_OBJECTS_EDITING_ID,
  idObjectEdited
});

export const setObjectsUsersResponsible = (usersData: User[]): setObjectsUsersResponsibleAction => ({
  type: ObjectsPageActionTypes.SET_OBJECTS_USERS_RESPONSIBLE,
  usersData
});

export const setObjectsUsersResponsibleLoading = (usersDataIsLoading: boolean): setObjectsUsersResponsibleLoadingAction => ({
  type: ObjectsPageActionTypes.SET_OBJECTS_USERS_RESPONSIBLE_LOADING,
  usersDataIsLoading
});

export const setObjectsUserResponsibleSelected = (userSelected: {value: string, label: string}): setObjectsUsersResponsibleSelectedAction => ({
  type: ObjectsPageActionTypes.SET_OBJECTS_USER_RESPONSIBLE_SELECTED,
  userSelected
});

export const deleteFileStore = (id: string|number): deleteFileStoreAction => ({
  type: ObjectsPageActionTypes.DELETE_FILE_STORE,
  id
});

export const setObjectsEditFormDefaultValues = (trigger: string): setObjectsEditFormDefaultValuesAction => ({
  type: ObjectsPageActionTypes.SET_OBJECTS_EDIT_FORM_DEFAULT_VALUES_TRIGGER,
  trigger
});

export const updateObjectFilesStore = (files: any): updateObjectFilesStoreAction => ({
  type: ObjectsPageActionTypes.UPDATE_OBJECT_FILES_STORE,
  files
});
