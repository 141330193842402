import { Action } from "redux";
import { IObjectSectionData, IObjectsFormAddTaskData, IObjectsFormAddTaskDataSelect } from "./interfaces";
import { IEditingObjectsFile } from "../../interfaces";

export enum FormAddTaskActionTypes {
  SET_SELECT_VALUES = "@@OBJECTS_FORM_ADD_TASK/SET_SELECT_VALUES",
  SET_SELECT_SELECTED_VALUE = "@@OBJECTS_FORM_ADD_TASK/SET_SELECT_SELECTED_VALUE",
  SET_CHECKBOX = "@@OBJECTS_FORM_ADD_TASK/SET_CHECKBOX",
  SET_INPUT = "@@OBJECTS_FORM_ADD_TASK/SET_INPUT",
  SET_OBSERVERS = "@@OBJECTS_FORM_ADD_TASK/SET_OBSERVERS",
  SET_FILE = "@@OBJECTS_FORM_ADD_TASK/SET_FILE",
  SET_FORM_OPEN = "@@OBJECTS_FORM_ADD_TASK/SET_FORM_OPEN",
  SET_CHECK_TRIGGER = "@@OBJECTS_FORM_ADD_TASK/SET_CHECK_TRIGGER",
  CREATE_TASK = "@@OBJECTS_FORM_ADD_TASK/CREATE_TASK",
  SET_CREATING_TASK = "@@OBJECTS_FORM_ADD_TASK/SET_CREATING_TASK",
  SET_OBJECT_ID = "@@OBJECTS_FORM_ADD_TASK/SET_OBJECT_ID",
  GET_OBJECT_SECTION_DATA = "@@OBJECTS_FORM_ADD_TASK/GET_OBJECT_SECTION_DATA",
  SET_OBJECT_SECTION_DATA = "@@OBJECTS_FORM_ADD_TASK/SET_OBJECT_SECTION_DATA",
  SET_FORM_DEFAULT_VALUES = "@@OBJECTS_FORM_ADD_TASK/SET_FORM_DEFAULT_VALUES",
}

export interface setCreatingTaskAction
  extends Action<FormAddTaskActionTypes.SET_CREATING_TASK> {
  isCreatingTask: boolean
}

export interface getObjectSectionDataAction
  extends Action<FormAddTaskActionTypes.GET_OBJECT_SECTION_DATA> {
  objectId: string|number
}

export interface setObjectSectionDataAction
  extends Action<FormAddTaskActionTypes.SET_OBJECT_SECTION_DATA> {
  objectData: IObjectSectionData[]
}

export interface setObjectIdAction
  extends Action<FormAddTaskActionTypes.SET_OBJECT_ID> {
  id: string|number
}

export interface setFormDefaultValuesAction
  extends Action<FormAddTaskActionTypes.SET_FORM_DEFAULT_VALUES> {
}

export interface createTaskAction
  extends Action<FormAddTaskActionTypes.CREATE_TASK> {
  data: object
}

export interface setFormOpenAction
  extends Action<FormAddTaskActionTypes.SET_FORM_OPEN> {
  isFormOpen: boolean
}

export interface setCheckTriggerAction
  extends Action<FormAddTaskActionTypes.SET_CHECK_TRIGGER> {
  checkTrigger: string
}

export interface setFileAction
  extends Action<FormAddTaskActionTypes.SET_FILE> {
  file: IEditingObjectsFile
}

export interface setSelectObserversAction
  extends Action<FormAddTaskActionTypes.SET_OBSERVERS> {
  data: number[]
}

export interface setSelectValuesAction
  extends Action<FormAddTaskActionTypes.SET_SELECT_VALUES> {
  data: IObjectsFormAddTaskDataSelect[]
  name: keyof IObjectsFormAddTaskData
}

export interface setSelectSelectedValueAction
  extends Action<FormAddTaskActionTypes.SET_SELECT_SELECTED_VALUE> {
  data: IObjectsFormAddTaskDataSelect
  name: keyof IObjectsFormAddTaskData
}

export interface setCheckboxAction
  extends Action<FormAddTaskActionTypes.SET_CHECKBOX> {
  value: boolean
  name: keyof IObjectsFormAddTaskData
}

export interface setInputAction
  extends Action<FormAddTaskActionTypes.SET_INPUT> {
  value: string|Date
  name: keyof IObjectsFormAddTaskData
}

export type FormAddTaskAction =
  | getObjectSectionDataAction
  | setObjectSectionDataAction
  | setFormDefaultValuesAction
  | setObjectIdAction
  | setCreatingTaskAction
  | createTaskAction
  | setCheckTriggerAction
  | setFormOpenAction
  | setFileAction
  | setSelectObserversAction
  | setSelectValuesAction
  | setInputAction
  | setCheckboxAction
  | setSelectSelectedValueAction;
