import React, { FC } from "react";
import SelectTypicalTask from "../../ui/SelectTypicalTask/SelectTypicalTask";
import { TypeFilterActivity } from "../../../../action_types";
import { connect } from "react-redux";
import { State } from "../../../../../../rootReducer";
import { setNormalFormData } from "../../../../actions";
import { INormalFormData, } from "../../../../interfaces";
import { TypicalTasksActivity } from "../../../../../../common/constants";

import {ReactComponent as CalendarIco} from '../../../../../../img/TrueIcons/calendar.svg';
import {ReactComponent as UserTaskIco} from '../../../../../../img/TrueIcons/user-task.svg';
import {ReactComponent as BriefcaseIco} from '../../../../../../img/TrueIcons/briefcase.svg';

import './OtherTypicalTask.scss';
import moment from "moment";

const OtherTypicalTask: FC<{
  setNormalFormData: (normalFormData: INormalFormData) => void,
  author?: string,
  createdAt?: string,
  activity?: TypeFilterActivity
}> = ({
  setNormalFormData,
  author,
  createdAt,
  activity
}) => {
  const formatDate = (date) => moment(date).format('DD.MM.YYYY');
  const dataActivityValues = [
    {text: TypicalTasksActivity.ACTIVE_TEXT_FORM as string, value: TypicalTasksActivity.ACTIVE_STATUS},
    {text: TypicalTasksActivity.NOT_ACTIVE_TEXT_FORM as string, value: TypicalTasksActivity.NOT_ACTIVE_STATUS}
  ];

  const onChangeSelect = (action?: string, value?: TypeFilterActivity) => {
    setNormalFormData({activity: value});
  };
  
  return (
    <div className="other-typical-task">
      <div className="other-typical-task__title">
        Прочее
      </div>
      
      <div className="other-typical-task__items">
        {author && (
          <div className="other-typical-task__item">
            <div className="other-typical-task__item-left">
              <div className="other-typical-task__item-title-wr">
                <div className="other-typical-task__item-title-ico">
                  <UserTaskIco />
                </div>
                <div className="other-typical-task__item-title">Автор</div>
              </div>
            </div>

            <div className="other-typical-task__item-right">
              <div className="other-typical-task__item-text">{author}</div>
            </div>
          </div>
        )}

        {createdAt && (
          <div className="other-typical-task__item">
            <div className="other-typical-task__item-left">
              <div className="other-typical-task__item-title-wr">
                <div className="other-typical-task__item-title-ico">
                  <CalendarIco />
                </div>
                <div className="other-typical-task__item-title">Дата создания</div>
              </div>
            </div>

            <div className="other-typical-task__item-right">
              <div className="other-typical-task__item-text">{formatDate(createdAt)}</div>
            </div>
          </div>
        )}

        <div className="other-typical-task__item">
          <div className="other-typical-task__item-left">
            <div className="other-typical-task__item-title-wr">
              <div className="other-typical-task__item-title-ico">
                <BriefcaseIco />
              </div>
              <div className="other-typical-task__item-title">Состояние</div>
            </div>
          </div>

          <div className="other-typical-task__item-right">
            <div className="other-typical-task__item-select">
              <SelectTypicalTask
                itemsList={dataActivityValues}
                onChangeSelect={(value) => onChangeSelect('', value)}
                selectedItem={activity}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: State) => {
  return {
    author: state.typicalTasksPage.normalForm.author,
    activity: state.typicalTasksPage.normalForm.activity,
    createdAt: state.typicalTasksPage.normalForm.createdAt,
  }
};

const mapDispatchToProps = {
  setNormalFormData
};

export default connect(mapStateToProps, mapDispatchToProps)(OtherTypicalTask);
