import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { StatusContext } from "../../context/status";
import TaskPriority from "../create-task-form/components/taskPriority/TaskPriority";
import BpItemStatus from "../ui/bp-item-status/BpItemStatus";
import BpItemTaskMenu from "../ui/bp-item-task-menu/BpItemTaskMenu";
import messageBp from "../Icons/icon.chat.svg";
import infoBp from "../Icons/VectorBp.svg";
import { fetchData } from "../../../../../utils/fetchData";

const TaskItem = ({ style, el }) => {
  const {
    createBpStatus,
    setOpenMenuTasks,
    openMenuTasks,
    openMenuBp,
    createTaskStatus,
    createBpSampleStatus,
    setIdCall,
    setOpenMenuBp,
    setSelectedTaskOfBP,
    setTaskId,
    bp,
    createBpFromSample,
    setIdBp,
    setOpenMenuTask,
    setStatusOfTask,
    setExecutorIdofTask,
    users,
    taskId,
    relatedTaskId,
    setSelectedBp,
    setRedirectedByClick,
    setThisTabs,
    setShowChatWindow,
    projects,
    statusOfTask,
    selectColumns,
  } = useContext(StatusContext);
  const [classTask, setClassTask] = useState(
    "dependencies__content-list__item"
  );

  const [project, setProject] = useState();
  const [projectSection, setProjectSection] = useState({});
  const [isAuthorInfo, setIsAuthorInfo] = useState();
  const [isExecutorInfo, setIsExecutorInfo] = useState();

  useEffect(() => {
    if (projects)
      setProject((prev) =>
        projects.find((project) => project.id === el.data.project_id)
      );
  }, [projects, el, el.data, el.data.project_id]);

  useEffect(() => {
    const initiator = users.find((user) => user.id === el.data.author_id);
    if (!initiator) {
      fetchData
        .get(`/api/v1/users/${el.data.author_id}`)
        .then((res) => setIsAuthorInfo(res));
    } else {
      setIsAuthorInfo(initiator);
    }
  }, [el.data.author_id]);
  useEffect(() => {
    const executor = users.find((user) => user.id === el.executor_id);
    if (!executor) {
      fetchData
        .get(`/api/v1/users/${el.executor_id}`)
        .then((res) => setIsExecutorInfo(res));
    } else {
      setIsExecutorInfo(executor);
    }
  }, [el.executor_id]);

  useEffect(() => {
    if (project)
      setProjectSection(
        project.sections.find(
          (section) => section.id === el.data.project_section_id
        )
      );
  }, [project, el, el.data, el.data.project_section_id]);

  // Для выделения задачи после перехода к определенному БП
  useEffect(() => {
    if (relatedTaskId === el.id) setTaskId(relatedTaskId);
  }, [relatedTaskId]);

  useEffect(() => {
    if (style === "dropdown") {
      if (
        openMenuTasks ||
        openMenuBp ||
        createTaskStatus ||
        createBpSampleStatus ||
        createBpStatus
      ) {
        setClassTask(
          "dependencies__content-list__item dependencies__content-list__item-dropdown"
        );
      } else {
        setClassTask(
          "dependencies__content-list__item dependencies__content-list__item-dropdown"
        );
      }
    } else {
      if (
        openMenuTasks ||
        openMenuBp ||
        createTaskStatus ||
        createBpSampleStatus ||
        createBpStatus
      ) {
        setClassTask(
          "dependencies__content-list__item dependencies__content-list__item-active"
        );
      } else {
        setClassTask("dependencies__content-list__item");
      }
    }
  }, [
    style,
    openMenuTasks,
    openMenuBp,
    createTaskStatus,
    createBpSampleStatus,
    createBpStatus,
  ]);
  // console.log(el);

  // При создании бизнес-процесса по шаблону- автоматическое открытие
  // 1 задачи
  useEffect(() => {
    if (createBpFromSample && bp && bp.tasks && bp.tasks[0] && bp.tasks[0].id) {
      let el1 = bp.tasks[0];
      setIdCall(el1.id);
      setOpenMenuTasks(true);
      setOpenMenuBp(true);
      setSelectedTaskOfBP(el1.name);
      setTaskId(el1.id);
      setStatusOfTask(el1.status);
      setExecutorIdofTask(el1.executor_id);
      setSelectedBp(bp.id);
    }
  }, []);
  // console.log(el.commentsCount);
  return (
    <>
      <div
        // style={el.id === taskId ? { background: "red" } : null}
        className={
          el.id === taskId &&
          (openMenuTasks ||
            openMenuBp ||
            createTaskStatus ||
            createBpSampleStatus ||
            createBpStatus)
            ? "dependencies__content-list__item dependencies__content-list__item-dropdown_active"
            : classTask
        }
        id={el.id}
        onClick={() => {
          setIdCall(el.id);
          setOpenMenuTasks(true);
          setOpenMenuBp(true);
          setSelectedTaskOfBP(el.name);
          setTaskId(el.id);
          setStatusOfTask(el.status);
          setExecutorIdofTask(el.executor_id);
          setShowChatWindow(false);
        }}
      >
        <span className="bp_task_line">|</span>
        {/* <div className="dependencies__content-list__item__btn"> */}
        <div className={el.id === taskId ? "task_card_one" : "task_card"}>
          <div className="task_left_item">
            <div
              className="business__main-content__list-block__item__arrow"
              style={{ paddingLeft: "15px" }}
            >
              <img
                src={`${process.env.REACT_APP_PUBLIC_URL}/assets/ShapeBlack.svg`}
                alt="drop"
                style={{ opacity: 0, transform: "rotate(-90deg)" }}
              />
            </div>
            {selectColumns.includes("id1") && (
              <div className="item_id">{el.id}</div>
            )}
            <div
              className="business__main-content__list-block__item__message business__main-content__list-block__item__message-active"
              id={"business-item-btn-" + 1}
              onClick={(e) => {
                if (
                  statusOfTask === 2 ||
                  statusOfTask === 4 ||
                  statusOfTask === 7
                ) {
                  e.stopPropagation();
                  setOpenMenuBp(true);
                  setThisTabs(3);
                  setShowChatWindow(true);
                  setRedirectedByClick(false);
                  setTaskId(el.id);
                  setThisTabs(3);
                }
              }}
              style={{ paddingLeft: "10px", display: "flex" }}
            >
              <div style={{ marginRight: "5px", paddingLeft: "2px" }}>
                <img
                  src={messageBp}
                  alt="message"
                  title="Чат бизнес-процесса"
                />
              </div>
              <span style={{ width: "20px" }}>
                {el.commentsCount ? el.commentsCount : "0"}
              </span>
            </div>
            {/* </div> */}
            {selectColumns.includes("name") && (
              <div
                style={{ padding: "0" }}
                className={
                  createBpStatus ||
                  createBpSampleStatus ||
                  openMenuTasks ||
                  openMenuBp
                    ? "item_name_task"
                    : "item_name_task_one"
                }
              >
                <p
                  className="p-black"
                  title={el.name}
                  style={{ paddingLeft: "2px", marginBottom: "5px" }}
                >
                  {el.name}
                </p>
                <span title={el.description} className="p-grey">
                  {el.description}
                </span>
              </div>
            )}
          </div>
          <div className="task_right_item">
            {selectColumns.includes("author") && (
              <div style={{ width: "56px" }}>
                <div
                  className="business__main-content__list-block__item__logo business__main-content__list-block__item__logo__task"
                  style={{
                    opacity: `${isAuthorInfo?.is_active ? "1" : "0.5"}`,
                  }}
                >
                  {isAuthorInfo ? (
                    isAuthorInfo.image ? (
                      <img
                        title={isAuthorInfo?.name + isAuthorInfo?.surname}
                        src={`${process.env.REACT_APP_PUBLIC_URL}/files/${isAuthorInfo?.image.url}/download`}
                        alt="logo"
                      />
                    ) : (
                      <span title={isAuthorInfo?.name + isAuthorInfo?.surname}>
                        {isAuthorInfo?.name[0] + isAuthorInfo?.surname[0]}
                      </span>
                    )
                  ) : null}
                </div>
              </div>
            )}
            {selectColumns.includes("initiator_id") && (
              <div className="logo_parent">
                <div
                  className="business__main-content__list-block__item__logo logo_task_1"
                  style={{
                    border: `${
                      isExecutorInfo?.is_active ? "none" : "4px solid orange"
                    }`,
                  }}
                >
                  {isExecutorInfo ? (
                    isExecutorInfo.image ? (
                      <img
                        title={isExecutorInfo?.name + isExecutorInfo?.surname}
                        src={`${process.env.REACT_APP_PUBLIC_URL}/files/${isExecutorInfo?.image.url}/download`}
                        alt="logo"
                      />
                    ) : (
                      <span
                        title={isExecutorInfo?.name + isExecutorInfo?.surname}
                      >
                        {isExecutorInfo?.name[0] + isExecutorInfo?.surname[0]}
                      </span>
                    )
                  ) : null}
                </div>
              </div>
            )}
            {selectColumns.includes("status") && (
              <BpItemStatus
                status={el.status}
                workflow={el.workflow_id}
                id={el.id}
                taskEnd={el.end}
              />
            )}
            {selectColumns.includes("deadline") && (
              <div
                // style={{ marginRight: "20px" }}
                className="dependencies__content-list__deadline p-black"
              >
                {new Date(new Date(el.end).toJSON()).toLocaleString("ru", {
                  month: "long",
                  day: "numeric",
                })}
              </div>
            )}
            {selectColumns.includes("duration") && (
              <div style={{ width: "106px" }}></div>
            )}
            {selectColumns.includes("project_id") && (
              <div className="dependencies__content-list__project">
                <div style={{ paddingTop: "10px", width: "148px" }}>
                  <p className="p-black project-section-name">
                    {project?.name ? project?.name : ""}
                  </p>
                  <span className="p-grey project-section-name">
                    {projectSection?.name ? projectSection?.name : ""}
                  </span>
                </div>
                {/* {createBpStatus ||
              createTaskStatus ||
              createBpSampleStatus ||
              openMenuTasks ||
              openMenuBp ? (
                <></>
              ) : ( */}
                <div className="dependencies__content-list__item__right__priority">
                  {el.id && (
                    <TaskPriority
                      priorityId={el.data.priority_id || el.priority_id}
                    />
                  )}
                </div>
                {/* )} */}
              </div>
            )}
            <div
              className="dependencies__content-list__item__right__list"
              onClick={(e) => {
                // console.log(el.id);
                e.stopPropagation();

                setIdBp(+el.id);
                // setSelectedBp(+el.id);
              }}
            >
              <img
                src={`${process.env.REACT_APP_PUBLIC_URL}/assets/drop-down-menu.svg`}
                alt="list"
                onClick={(e) => {
                  e.stopPropagation();

                  setTaskId(+el.id);
                  setOpenMenuTask(true);
                }}
              />
              <BpItemTaskMenu
                id={el.id}
                status={el.status}
                el={el}
                onClick={() => setTaskId(+el.id)}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TaskItem;
