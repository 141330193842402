import React from 'react'
import './PersonalToggle.scss'
import { connect } from 'react-redux'
import { State } from '../../../../../rootReducer'
import { TypicalTreatiesPersonal } from '../../../interfaces'
import { getTypicalTreaties, setCurrentPage, setTypicalTreatiesFilterPersonal } from '../../../actions'

const PersonalToggle: React.FC<{
  personalFilterId: TypicalTreatiesPersonal,
  setTypicalTreatiesFilterPersonal: (id: TypicalTreatiesPersonal) => void,
  getTypicalTreaties: (params?: object) => void,
  setCurrentPage: (currentPage: number) => void
}> = ({
  personalFilterId,
  setTypicalTreatiesFilterPersonal,
  getTypicalTreaties,
  setCurrentPage,
}) => {

  const defaultValues = [
    { text: 'Все', value: TypicalTreatiesPersonal.NOT_PERSONAL_STATUS },
    { text: 'Мои', value: TypicalTreatiesPersonal.PERSONAL_STATUS },
  ]

  const setValueHandler = (value) => {
    setCurrentPage(1);
    setTypicalTreatiesFilterPersonal(value);
    
    getTypicalTreaties();
  }

  return (
    <div className="personal-toggle">
      {defaultValues.map((item, index) => (
        <div
          className={
            "personal-toggle__item" +
            (personalFilterId === item.value
              ? " personal-toggle__item--active"
              : "")
          }
          key={index}
          onClick={() => setValueHandler(item.value)}
        >
          {item.text}
        </div>
      ))}

    </div>
  )
}

const mapStateToProps = (state: State) => {
  return {
    personalFilterId: state.typicalTreatyPage.filters.personal
  }
}

const mapDispatchToProps = {
  setCurrentPage,
  setTypicalTreatiesFilterPersonal,
  getTypicalTreaties,
}

export default connect(mapStateToProps, mapDispatchToProps)(PersonalToggle)