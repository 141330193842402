import { Reducer } from "redux";
import {
  TabIdsObjectEditingForm,
  sortConditions,
  sortOrders,
} from "./constants";
import { ObjectsPageAction, ObjectsPageActionTypes } from "./action_types";
import { IEditingObjectsFile, IObjectsPageState } from "./interfaces";
import { OjectsPersonal } from "../../common/constants";

const initialState: IObjectsPageState = {
  objects: [],
  sections: [],
  tasks: [],
  isLoadingObject: false,
  isCreatingObject: false,
  isEditingObjectsFormOpen: false,
  // isEditingObjectsFormOpen: true,
  // isEditObjectsForm: true,
  isEditObjectsForm: false,
  filters: {
    usersDataForFilter: [],
    usersDataForFilterIsLoading: false,
    usersIds: [],
    terms: [],
    personal: OjectsPersonal.NOT_PERSONAL_STATUS,
  },
  editingObjectsForm: {
    idObjectEdited: "",
    activeTabIndex: TabIdsObjectEditingForm.DESCRIPTION_TAB,
    checkTrigger: "",
    data: {
      author: {
        id: '',
        surname: "",
        name: "",
      },
      commentsCount: "",
      documentsCount: '',
      createdAt: "",
      name: "",
      stage: "",
      declarant: "",
      title: "",
      numberSap: "",
      responsible: {
        usersData: [],
        usersDataIsLoading: true,
        userSelected: { value: "", label: "Введите значение..." },
      },
      contractNumber: "",
      startDate: "",
      endDate: "",
      cost: "",
      entryDocuments: [],
      importantDocuments: [],
      importantInfo: [],
      documents: [],
      sections: []
    },
  },
  currentPage: 1,
  isShowMenuPreloader: false,
  isLoadingObjects: false,
  sortCondition: {
    columnId: sortConditions.ID,
    value: sortOrders.DESC
  }, 
  searchObjects: "",
  deleteObjectModal: {
    objectId: "",
    isDeleteModalOpen: false,
    isShowPreloader: false,
    statusFromServer: { constraints: [] },
  },
  deleteTaskModal: {
    taskId: "",
    isDeleteTaskModalOpen: false,
    isShowTaskPreloader: false,
    statusTaskFromServer: { constraints: [] },
  },
  deleteStateRegular: false,
  modalCheckAddTask: {},
  meta: null
};

export type ObjectsPageState = typeof initialState;

const reducer: Reducer<ObjectsPageState, ObjectsPageAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case ObjectsPageActionTypes.SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.currentPage
      };
      
    case ObjectsPageActionTypes.SET_META:
      return {
        ...state,
        meta: action.meta
      };
      
    case ObjectsPageActionTypes.SET_OBJECTS_DATA:
      if (Array.isArray(action.objects)) {
        if(action.action === 'add') {
          return {
            ...state,
            objects: [
              ...state.objects,
              ...action.objects
            ]
          };
        }

        return {
          ...state,
          objects: action.objects
        };
      } 
      else {
        return {
          ...state,
          objects: [action.objects, ...state.objects],
        };
      }

    case ObjectsPageActionTypes.SET_OBJECTS_EDIT_FORM_DEFAULT_VALUES_TRIGGER:
      return {
        ...state,
        editingObjectsForm: initialState.editingObjectsForm
      };

    case ObjectsPageActionTypes.SET_OBJECT_SECTION_LIST:
      const index = state.sections.findIndex(section => section.id === action.sections[0].id);

      // Обновление
      if(index !== -1) {
        const newSections = [...action.sections];
        newSections[index] = action.sections[0];
        
        return {
          ...state,
          sections: newSections
        };
      }
      
      return {
        ...state,
        sections: [
          ...state.sections,
          ...action.sections
        ]
      };

    case ObjectsPageActionTypes.SET_SECTION_TASK_LIST:
      const newTasks = [...state.tasks];
      
      if(newTasks.length) {
        action.tasks.forEach(actionTask => {
          let isUnique = true;
          
          newTasks.forEach(stateTask => {
            if(stateTask.id === actionTask.id) {
              isUnique = false;
            }
          });

          if(isUnique) {
            newTasks.push(actionTask);
          }
        });
      }
      else {
        newTasks.push(...action.tasks);
      }

      return {
        ...state,
        tasks: newTasks
      };

    case ObjectsPageActionTypes.DELETE_TASK_SECTION_FROM_STATE:
      return {
        ...state,
        tasks: state.tasks.filter(item => item.id !== action.id)
      };

    case ObjectsPageActionTypes.SET_OBJECTS_FILTER_USERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          usersIds: action.users,
        },
      };

    case ObjectsPageActionTypes.SET_OBJECTS_FILTER_TERMS:
      return {
        ...state,
        filters: {
          ...state.filters,
          terms: action.terms,
        },
      };

    case ObjectsPageActionTypes.SET_OBJECTS_FILTER_PERSONAL:
      return {
        ...state,
        filters: {
          ...state.filters,
          personal: action.personal,
        },
      };

    case ObjectsPageActionTypes.SET_OBJECTS_USERS_FOR_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          usersDataForFilter: action.usersDataForFilter,
        },
      };

    case ObjectsPageActionTypes.SET_OBJECTS_USERS_FOR_FILTER_IS_LOADING:
      return {
        ...state,
        filters: {
          ...state.filters,
          usersDataForFilterIsLoading: action.usersDataForFilterIsLoading,
        },
      };

    case ObjectsPageActionTypes.SET_SEARCH_OBJECTS:
      return {
        ...state,
        searchObjects: action.searchObjects,
      };

    case ObjectsPageActionTypes.UPDATE_OBJECTS_STORE:
      const newObjects = [...state.objects];
      const updateIndex = state.objects.findIndex(object => object.id === action.data.id);

      if(updateIndex === -1) {
        return state;
      }

      newObjects[updateIndex] = action.data;

      return {...state, objects: newObjects};

    case ObjectsPageActionTypes.UPDATE_TASK_STORE: {
      const newTasks = [...state.tasks];
      const updateIndex = state.tasks.findIndex(task => task.id === action.task.id);

      if (updateIndex === -1) {
        return state;
      }

      newTasks[ updateIndex ] = action.task;
      
      return { ...state, tasks: newTasks };
    }

    case ObjectsPageActionTypes.SET_IS_EDIT_OBJECT_FORM: {
      return {
        ...state,
        isEditObjectsForm: action.isEditObjectsForm,
      };
    }

    case ObjectsPageActionTypes.DELETE_FILE_STORE: {
      return {
        ...state,
        editingObjectsForm: {
          ...state.editingObjectsForm,
          data: {
            ...state.editingObjectsForm.data,
            entryDocuments: [
              // @ts-ignore
              ...state.editingObjectsForm.data.entryDocuments?.filter(
                (file) => file.id !== action.id
              ),
            ],
          },
        },
      };
    }

    case ObjectsPageActionTypes.SET_CREATING_OBJECT: {
      return {
        ...state,
        isCreatingObject: action.isCreatingObject,
      };
    }

    case ObjectsPageActionTypes.SET_OBJECTS_EDITING_FORM_OPEN:
      return {
        ...state,
        isEditingObjectsFormOpen: action.isEditingObjectsFormOpen,
      };

    case ObjectsPageActionTypes.SET_OBJECTS_EDITING_FORM_ACTIVE_TAB_INDEX: {
      return {
        ...state,
        editingObjectsForm: {
          ...state.editingObjectsForm,
          activeTabIndex: action.activeTabIndex,
        },
      };
    }

    case ObjectsPageActionTypes.SET_OBJECTS_EDITING_ID: {
      return {
        ...state,
        editingObjectsForm: {
          ...state.editingObjectsForm,
          idObjectEdited: action.idObjectEdited,
        },
      };
    }

    case ObjectsPageActionTypes.SET_LOADING_OBJECTS:
      return {
        ...state,
        isLoadingObjects: action.isLoadingObjects,
      };

    case ObjectsPageActionTypes.SET_SORT_CONDITION:
      return {
        ...state,
        sortCondition: {
          ...state.sortCondition,
          ...action.sortCondition,
        },
      };

    case ObjectsPageActionTypes.SET_IS_SHOW_MENU_PRELOADER: {
      return {
        ...state,
        isShowMenuPreloader: action.isShowMenuPreloader,
      };
    }

    case ObjectsPageActionTypes.SET_DELETE_TASK_REGULAR: {
      return {
        ...state,
        deleteStateRegular: action.deleteStateRegular,
      };
    }

    case ObjectsPageActionTypes.SET_DELETE_OBJECT_MODAL_DATA:
      return {
        ...state,
        deleteObjectModal: {
          ...state.deleteObjectModal,
          ...action.data,
        },
      };

    case ObjectsPageActionTypes.SET_DELETE_TASK_MODAL_DATA:
      return {
        ...state,
        deleteTaskModal: {
          ...state.deleteTaskModal,
          ...action.data,
        },
      };

    case ObjectsPageActionTypes.DELETE_OBJECT_FROM_STATE:
      return {
        ...state,
        objects: state.objects.filter((task) => task.id !== action.id),
      };

    case ObjectsPageActionTypes.UPDATE_OBJECT_FILES_STORE: {
      return {
        ...state,
        editingObjectsForm: {
          ...state.editingObjectsForm,
          data: {
            ...state.editingObjectsForm.data,
            entryDocuments: action.files
          }
        }
      };
    }
      
    case ObjectsPageActionTypes.SET_OBJECTS_EDITING_FORM_DATA:
      let editingObjectsFilesLocal: IEditingObjectsFile[] = [
        ...(state.editingObjectsForm.data.entryDocuments as IEditingObjectsFile[]),
      ];

      if(action.editingFormData?.entryDocuments) {
        let needPush = true;
        
        editingObjectsFilesLocal.forEach((file, i) => {
          // Если в стейте есть файл без ссылки, то он добавлен, но не загружен на сервер
          // Тогда заменяем его на новый выбранный
          if(!file.link?.length) {
            // @ts-ignore
            editingObjectsFilesLocal[i] = action.editingFormData.entryDocuments[0];
            needPush = false;
          }
        });
        
        // Если не нашли ни одного файла со ссылкой, то просто пушим новые файлы
        if(needPush) {
          editingObjectsFilesLocal.push(...action.editingFormData.entryDocuments);
        }
      }
      else {
        // Добавляем данные с сервера
         editingObjectsFilesLocal = [
            // Для множества файлов
            ...(state.editingObjectsForm.data.entryDocuments as IEditingObjectsFile[])
          ];
      }

      return {
        ...state,
        editingObjectsForm: {
          ...state.editingObjectsForm,
          data: {
            ...state.editingObjectsForm.data,
            ...action.editingFormData,
            entryDocuments: editingObjectsFilesLocal,
          },
        },
      };

    case ObjectsPageActionTypes.SET_OBJECTS_EDITING_FORM_CHECK_TRIGGER: {
      return {
        ...state,
        editingObjectsForm: {
          ...state.editingObjectsForm,
          checkTrigger: action.checkTrigger,
        },
      };
    }

    case ObjectsPageActionTypes.SET_LOADING_OBJECT:
      return {
        ...state,
        isLoadingObject: action.isLoadingObject,
      };

    case ObjectsPageActionTypes.SET_OBJECTS_USERS_RESPONSIBLE:
      return {
        ...state,
        editingObjectsForm: {
          ...state.editingObjectsForm,
          data: {
            ...state.editingObjectsForm.data,
            responsible: {
              ...state.editingObjectsForm.data.responsible,
              usersData: action.usersData,
            },
          },
        },
      };

    case ObjectsPageActionTypes.SET_OBJECTS_USERS_RESPONSIBLE_LOADING:
      return {
        ...state,
        editingObjectsForm: {
          ...state.editingObjectsForm,
          data: {
            ...state.editingObjectsForm.data,
            responsible: {
              ...state.editingObjectsForm.data.responsible,
              usersDataIsLoading: action.usersDataIsLoading,
            },
          },
        },
      };

    case ObjectsPageActionTypes.SET_OBJECTS_USER_RESPONSIBLE_SELECTED:
      return {
        ...state,
        editingObjectsForm: {
          ...state.editingObjectsForm,
          data: {
            ...state.editingObjectsForm.data,
            responsible: {
              ...state.editingObjectsForm.data.responsible,
              userSelected: action.userSelected,
            },
          },
        },
      };

    case ObjectsPageActionTypes.SET_CHECK_OBJECT_MODAL:
      return {
        ...state,
        modalCheckAddTask: {
          ...state.modalCheckAddTask,
          ...action.data,
        },
      };

    default:
      return state;
  }
};

export default reducer;
