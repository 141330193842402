import { sortConditions } from "./constants";

export const columnsOfTableslTasks = [
  {
    columnId: sortConditions.ID,
    showingText: "№",
    style: { minWidth: "121px", paddingLeft: "20px" },
  },
  {
    columnId: sortConditions.NAME,
    showingText: "Задача",
    style: { minWidth: "256px", maxWidth: "750px" },
  },
  {
    columnId: sortConditions.AUTHOR,
    showingText: "Автор",
    style: { minWidth: "59px" },
  },
  {
    columnId: sortConditions.EXECUTOR,
    showingText: "Исполнитель",
    style: { minWidth: "64px" },
  },
  {
    columnId: sortConditions.STATUS,
    showingText: "Статус",
    style: { minWidth: "161px" },
  },
  {
    columnId: sortConditions.END,
    showingText: "Дедлайн",
    style: { minWidth: "93px" },
  },

  {
    columnId: sortConditions.OBJECT_NAME,
    showingText: "Объект",
    style: { minWidth: "157px" },
  },
  {
    columnId: sortConditions.PRIORITY,
    showingText: "Приоритет",
    style: { minWidth: "136px" },
  },
];
