import React from "react";
import styled from "styled-components";
import { columnsOfTables } from "../../shared_data";
import { datePicker } from "../../constants";

type Props = {
  deadline: string;
  end?: string;
  hot?: boolean;
  style?: any;
};
const WrapperDeadLineCell = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-right: 4px;
  line-height: 16px;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  min-width: 100px;
`;
const DateStyle = styled.span`
  font-size: 14px;
  color: #212121;
  min-width: 70px;
  text-align: left;
`;
const DayStyle = styled.span`
  font-size: 13px;
  color: #a8a8a8;
`;

// deadline и end - одно и то же поле, просто разный формат.
// deadline - сокращенный формат 9 дек 22
// end - полный формат 09-12-2022 00:29:44

const DeadLineCell: React.FC<Props> = ({ deadline, end, hot, style }) => {
  // console.log('deadline', deadline);

  let endFormat = deadline;

  if (end === null || end === undefined) {
    endFormat = "";
  } else {
    if (end.trim().length > 1 && end.slice(6, 10) == "2200") {
      endFormat = "";
    }
  }

  // console.log('endFormat', endFormat);

  return (
    <WrapperDeadLineCell style={style}>
      <DateStyle>
        {endFormat}

        <span>&nbsp;</span>
        {hot && endFormat ? (
          <svg
            width="9"
            height="11"
            viewBox="0 0 9 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.24179 5.82274C7.4566 3.63399 4.66096 3.51597 5.33612 0.334775C5.38613 0.0987332 5.15107 -0.0836624 4.96103 0.0397229C3.14561 1.18774 1.84031 3.48915 2.93556 6.50404C3.02558 6.75081 2.75552 6.98149 2.56048 6.82055C1.65527 6.0856 1.56024 5.02878 1.64026 4.27237C1.67027 3.99342 1.33019 3.8593 1.18516 4.08998C0.84508 4.64789 0.5 5.54914 0.5 6.90638C0.690044 9.91054 3.05559 10.8333 3.90579 10.9513C5.12107 11.1176 6.43637 10.8762 7.38159 9.9481C8.42183 8.91273 8.80192 7.26044 8.24179 5.82274ZM3.60072 8.52112C4.32088 8.33336 4.69097 7.77544 4.79099 7.2819C4.95603 6.51477 4.31088 5.76373 4.74598 4.55133C4.91102 5.55451 6.38136 6.18216 6.38136 7.27654C6.42137 8.63378 5.05105 9.79789 3.60072 8.52112Z"
              fill="#F44336"
            />
          </svg>
        ) : null}
      </DateStyle>
      <DayStyle>{}</DayStyle>
    </WrapperDeadLineCell>
  );
};

export default DeadLineCell;
