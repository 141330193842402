import { ITaskBP, ITaskFlattenBP } from "../interfaces";

export const getIndexUnderElement = (
  currentIndex: number,
  currentElementPosition: DOMRect, 
  allElementsPositions: DOMRect[])
: number => {
  const index = allElementsPositions.findIndex((el, i) => {
    if(currentIndex !== i) {
      if(
        (currentElementPosition.top <= el.bottom) &&
        (currentElementPosition.bottom >= el.top))
      {
        return true;
      }
    }

    return false;
  });

  return index;
}

export const getAllElementsPositions = (elementClassName: string): DOMRect[] => {
  if(!elementClassName?.length) return [];
  
  let positions: any = [];
  const allElements = document.querySelectorAll(elementClassName);
  
  if(allElements.length) {
    allElements.forEach(el => {
      positions.push(el.getBoundingClientRect());
    });
  }

  return positions;
}

export const getCurrentElementsPosition = (currentEl): DOMRect => {
  return currentEl.current.getBoundingClientRect();
}

export const flatten = (items: ITaskBP[]): ITaskFlattenBP[] => {
  let result: any = [];
  
  items.forEach((item, i) => {
    let parentIndex = i;
    
    if(item.parentIndex) parentIndex = item.parentIndex;
    
    result.push({
      ...item,
      parentIndex,
      isParent: true,
      isLastParent: i === (items.length - 1),
      connectionAmount: item.children?.length ?? 1
    });

    if(item.children?.length) {
      item.children.forEach((child) => {
        result.push({
          ...child,
          parentIndex,
          isParent: false
        });
      });
    }
  })

  return result;
};

export const unflatten = (items: ITaskFlattenBP[]): ITaskBP[] => {
  let result: any = [];
  
  items.forEach(item => {
    if(item.isParent) {
      result.push({ ...item, children: [] });
    }
  });
  
  items.forEach(item => {
    if(!item.isParent) {
      result.forEach((res, j) => {
        if(item.parentIndex === res.parentIndex) {
          result[j]?.children.push(item);
        }
      })
    }
  });
  
  result = syncParentIndex(result);
  return result;
}

// Переместить из текущей позиции в новую
export function arrayMove<T>(array: T[], from: number, to: number): T[] {
  const newArray = array.slice();
  newArray.splice(
    to < 0 ? newArray.length + to : to,
    0,
    newArray.splice(from, 1)[0]
  );

  return newArray;
}

export function syncParentIndex(items: ITaskBP[]): ITaskBP[] {
  let result: any = [];

  items.forEach((item, i) => {
    result.push({ ...item, parentIndex: i});
    
    if(item.children?.length) {
      result[i].children = [];

      item.children.forEach((child) => {
        result[i].children.push({...child, parentIndex: i});
      });
    }
  });

  return result;
}

export const parentWithChildsMove = (
  items: ITaskFlattenBP[], 
  isParent: boolean, 
  from: number, 
  to: number
): ITaskFlattenBP[] => {
  let indexOffset = from < to ? 0 : 1; // Перетаскиваем сверху вниз - 0
  let fromIndex: null|number = null;
  let toIndex: null|number = null;
  
  let result: any = [...items];

  result = unflatten(result);
  
  result.forEach((el, i) => {
    if(el.parentIndex === items[from].parentIndex) {
      fromIndex = i;
    }
    
    // Перемещение под самый последний элемент
    if(to === -1) {
      toIndex = result.length - 1;
    }
    else {
      if(el.parentIndex === items[to].parentIndex) {
        toIndex = i + indexOffset;
      }
    }
  });

  if(fromIndex !== null && toIndex !== null) {
    result = arrayMove(result, fromIndex, toIndex);
    result = syncParentIndex(result);
  }
  
  return flatten(result);
}

export const childrenMove = (
  items: ITaskFlattenBP[],
  from: number,
  to: number,
  action?: string
): ITaskFlattenBP[] => {
  let result: any = [];
  
  let toEl = {...items[to]};
  let fromEl = {...items[from]};
  let newChildren: any = [];
  let newTo = 0;
  
  if(action === 'subtaskChildren') {
    result = unflatten(items);
    
    result.forEach((res, i) => {
      if(res.parentIndex === fromEl.parentIndex) {
        newTo = i;
        
        res.children.forEach((child, j) => {
          if(child.id !== fromEl.id) {
            newChildren.push(child);
          }
        });
        
        res.children = [...newChildren];

        if(result[toEl.parentIndex].isParent) {
          result[toEl.parentIndex] = {
            ...result[toEl.parentIndex],
            children: [
              ...result[toEl.parentIndex].children,
              { ...fromEl, isParent: false, controlledPosition: {y: 0, x: 0}, parentIndex: result[toEl.parentIndex].parentIndex}
            ]
          }

          result = flatten(result);
        }
      }
    });
  }
  
  if(action === 'parallel') {
    result = childToParent(items, from, to);
  }
  
  if(action === 'simpleMove') {
    result = [...items];
    
    // На первый элемент ребенок-родитель
    if(!result[ to - 1 ]) {
      if(toEl.isParent) {
        result = arrayMove(items, from, 1);

        result[1].parentIndex = 0;
        result[1].isParent = false;
      }
    }
    else {
      let indexOffset = from < to ? 0 : 1; // Перетаскиваем сверху вниз - 0
      
      result = arrayMove(items, from, to + indexOffset);
      
      result[to + indexOffset].parentIndex = result[indexOffset ? to : to - 1]?.parentIndex ?? 0;
      result[to + indexOffset].isParent = false;
    }
  }

  result = syncConnectAmount(result);

  return result;
};

export const childToParent = (
  items: ITaskFlattenBP[],
  from: number,
  to: number
): ITaskFlattenBP[] => {
  let result: any = [];
  let toEl = {...items[to]};
  let fromEl = {...items[from]};
  
  result = items.filter(res => res.id !== fromEl.id);
  
  result = unflatten(result);

  result.push({ ...fromEl, isParent: true, controlledPosition: {y: 0, x: 0}});
  result = arrayMove(result, result.length - 1, toEl.parentIndex + 1);

  result = syncParentIndex(result);
  result = flatten(result);
  
  return result;
}

export const syncConnectAmount = (items: ITaskFlattenBP[]) => {
  let result: any = [...items];

  result = unflatten(result)

  result.forEach((res) => {
    res.connectionAmount =  res.children?.length || 1;
  });

  result = flatten(result);

  return result;
}

export const setActiveElement = (
  currentIndex: number, 
  overIndex: number, 
  items: ITaskFlattenBP[]
): ITaskFlattenBP[] => {
  let result: any = [];

  result = items.map(item => {
    item = {
      ...item,
      isActive: false
    }
    
    if(item?.controlledPosition) {
      delete item.controlledPosition;
    }
    
    return item;
  })
  
  if(overIndex >= 0) {
    result[overIndex].isActive = true;
  }

  return result;
}

export const getIndexOffset = (currentIndex: number, overIndex: number): number => {
  // Перетаскиваем сверху вниз - 0
  return currentIndex < overIndex ? 0 : 1;
}

// Показать или скрыть полосу позиции над элементом задачи .typical-task-bp__position
export const setTaskPosition = (
  currentIndex: number,
  overIndex: number,
  showPosition: number,
  items: ITaskFlattenBP[]
): ITaskFlattenBP[] => {
  
  items[currentIndex] = {
    ...items[currentIndex],
    showPosition
  };

  return items;
};

export const returnBackElementToPosition = ( currentIndex: number, items: ITaskFlattenBP[]) => {
  items[currentIndex] = {
    ...items[currentIndex],
    controlledPosition: {x: 0, y: 0}
  };
  
  return items;
} 

export const deleteTaskBP = (
  currentIndex: number,
  items: ITaskFlattenBP[]
): ITaskFlattenBP[] => {
  let result: any = null;
  const currentElement = items[currentIndex];
  
  result = unflatten(items);
  
  result = result.filter((item: ITaskBP) => {
    if(item.children?.length) {
      item.children = item.children.filter(child => child.id !== currentElement.id);
    }
    
    return item.id !== currentElement.id;
  });
  
  result = syncParentIndex(result);
  result = flatten(result);

  return result;
}
