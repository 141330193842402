import { IObjectSectionData, IObjectsFormAddTaskData, IObjectsFormAddTaskDataSelect } from "./interfaces";
import {
  setCheckTriggerAction,
  FormAddTaskActionTypes,
  setCheckboxAction,
  setFileAction,
  setFormOpenAction,
  setInputAction,
  setSelectObserversAction,
  setSelectSelectedValueAction,
  setSelectValuesAction,
  createTaskAction,
  setCreatingTaskAction,
  setObjectIdAction,
  setFormDefaultValuesAction, 
  setObjectSectionDataAction, 
  getObjectSectionDataAction
} from "./action_types";
import { IEditingObjectsFile } from "../../interfaces";

export const setSelectObservers = (data: number[]): setSelectObserversAction => ({
  type: FormAddTaskActionTypes.SET_OBSERVERS,
  data
});

export const setObjectId = (id: string|number): setObjectIdAction => ({
  type: FormAddTaskActionTypes.SET_OBJECT_ID,
  id
});

export const getObjectSectionData = (objectId: string|number): getObjectSectionDataAction => ({
  type: FormAddTaskActionTypes.GET_OBJECT_SECTION_DATA,
  objectId
});

export const setObjectSectionData = (objectData: IObjectSectionData[]): setObjectSectionDataAction => ({
  type: FormAddTaskActionTypes.SET_OBJECT_SECTION_DATA,
  objectData
});

export const setFormDefaultValues = (): setFormDefaultValuesAction => ({
  type: FormAddTaskActionTypes.SET_FORM_DEFAULT_VALUES
});

export const createTask = (data: object): createTaskAction => ({
  type: FormAddTaskActionTypes.CREATE_TASK,
  data
});

export const setCreatingTask = (isCreatingTask: boolean): setCreatingTaskAction => ({
  type: FormAddTaskActionTypes.SET_CREATING_TASK,
  isCreatingTask
});

export const setCheckTrigger = (checkTrigger: string): setCheckTriggerAction => ({
  type: FormAddTaskActionTypes.SET_CHECK_TRIGGER,
  checkTrigger
});

export const setSelectSelectedValue = (data: IObjectsFormAddTaskDataSelect, name: keyof IObjectsFormAddTaskData): setSelectSelectedValueAction => ({
  type: FormAddTaskActionTypes.SET_SELECT_SELECTED_VALUE,
  data,
  name
});

export const setSelectValues = (data: IObjectsFormAddTaskDataSelect[], name: keyof IObjectsFormAddTaskData): setSelectValuesAction => ({
  type: FormAddTaskActionTypes.SET_SELECT_VALUES,
  data,
  name
});

export const setCheckbox = (value: boolean, name: keyof IObjectsFormAddTaskData): setCheckboxAction => ({
  type: FormAddTaskActionTypes.SET_CHECKBOX,
  value,
  name
});

export const setInput = (value: string|Date, name: keyof IObjectsFormAddTaskData): setInputAction => ({
  type: FormAddTaskActionTypes.SET_INPUT,
  value,
  name
});

export const setFile = (file: IEditingObjectsFile): setFileAction => ({
  type: FormAddTaskActionTypes.SET_FILE,
  file
});

export const setFormOpen = (isFormOpen: boolean): setFormOpenAction => ({
  type: FormAddTaskActionTypes.SET_FORM_OPEN,
  isFormOpen
});
