import React, { FC, useEffect, useState } from "react";
import { State } from "../../../../../rootReducer";
import { connect } from "react-redux";
import {
  IExtraFormCheckboxTabData, 
  IExtraFormData,
  IExtraFormDocumentTabData,
  IExtraFormInputTabData,
  IExtraFormRadioTabData,
  IExtraFormSelectTabData
} from "../../../action_types";
import AdditionalFieldsItem from "./components/AdditionalFields/AdditionalFieldsItem";
import { DataTypes, FieldSizes, SelectedTabNameExtraForm } from "../../../constants";
import {
  setExtraFormCheckboxTabData,
  setExtraFormCheckTrigger,
  setExtraFormDocumentTabData,
  setExtraFormInputTabData,
  setExtraFormRadioTabData,
  setExtraFormSelectTabData,
  setExtraFormRefreshCheckboxTrigger,
  setExtraFormActiveTabIndex,
  setTaskFullFormOpen, 
  setExtraFormDefaultOpen,
} from "../../../actions";
import { nanoid } from "nanoid";

import {
  DndContext,
  useSensors,
  useSensor,
  PointerSensor,
  KeyboardSensor,
  closestCenter,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";

import { IAdditionalFieldTypicalTask } from "../../../interfaces";

import './AdditionalFields.scss';

const AdditionalFields: FC<{
  data?: IAdditionalFieldTypicalTask[],
  isEditForm: boolean,
  addFieldTrigger?: string,
  extraForm: IExtraFormData,
  setExtraFormInputTabData: (inputTabData: IExtraFormInputTabData) => void,
  setExtraFormSelectTabData: (selectTabData: IExtraFormSelectTabData) => void,
  setExtraFormRadioTabData: (radioTabData: IExtraFormRadioTabData) => void,
  setExtraFormCheckboxTabData: (checkboxTabData: IExtraFormCheckboxTabData) => void,
  setExtraFormDocumentTabData: (documentTabData: IExtraFormDocumentTabData) => void,
  setExtraFormRefreshCheckboxTrigger: (checkboxTrigger: string) => void,
  setExtraFormActiveTabIndex: (activeTabIndex: number) => void,
  setTaskFullFormOpen: (isFullFormTasksOpen: boolean) => void;
  setExtraFormDefaultOpen: (isExtraFormDefaultOpen: boolean) => void;
  isFullFormTasksOpen: boolean,
  isExtraFormDefaultOpen: boolean,
  onChangeFieldsPosition: (fields: IAdditionalFieldTypicalTask[]) => void,
  formDefaultValuesTrigger: string
}> = ({
  data,
  isEditForm,
  addFieldTrigger,
  extraForm, 
  setExtraFormInputTabData,
  setExtraFormSelectTabData,
  setExtraFormRadioTabData,
  setExtraFormCheckboxTabData,
  setExtraFormDocumentTabData,
  setExtraFormRefreshCheckboxTrigger,
  setExtraFormActiveTabIndex,
  setTaskFullFormOpen,
  isFullFormTasksOpen,
  isExtraFormDefaultOpen,
  setExtraFormDefaultOpen,
  onChangeFieldsPosition,
  formDefaultValuesTrigger
}) => {
  const [additionalFieldsData, setAdditionalFieldsData] = useState<IAdditionalFieldTypicalTask[]>([]);
  const [isShowEditFormButton, setIsShowEditFormButton] = useState<boolean>(false);
  
  const sensors = useSensors(useSensor(PointerSensor), useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  const handleDragEnd = (event) => {
    const { active, over } = event;
    
    if (active?.id !== over?.id) {
      setAdditionalFieldsData((prev) => {
        const activeIndex = prev.findIndex((item) => item.id === active?.id);
        const overIndex = prev.findIndex((item) => item.id === over?.id);
        
        return arrayMove(prev, activeIndex, overIndex);
      });
    }
  }
  
  const setAdditionalFieldsHelper = (type: string, data: object) => {
    setAdditionalFieldsData(state => {
      const newFields = [...state];
      const updateIndex = state.findIndex(field => field.type === type);

      if(updateIndex === -1) {
        return [
          ...state,
          {
            id: nanoid(6),
            type,
            ...data
          }
        ];
      }

      newFields[updateIndex] = {id: newFields[updateIndex]?.id, type, ...data};
      
      return newFields;
    });
  }
  
  const setAdditionalFieldsAdapter = () => {
    if(extraForm.inputTab.isValid) {
      setAdditionalFieldsHelper(SelectedTabNameExtraForm.INPUT_TAB, extraForm.inputTab);
    }

    if(extraForm.selectTab.isValid) {
      setAdditionalFieldsHelper(SelectedTabNameExtraForm.SELECT_TAB, extraForm.selectTab);
    }

    if(extraForm.radioTab.isValid) {
      setAdditionalFieldsHelper(SelectedTabNameExtraForm.RADIO_TAB, extraForm.radioTab);
    }

    if(extraForm.checkboxTab.isValid) {
      setAdditionalFieldsHelper(SelectedTabNameExtraForm.CHECKBOX_TAB, extraForm.checkboxTab);
    }

    if(extraForm.documentTab.isValid) {
      setAdditionalFieldsHelper(SelectedTabNameExtraForm.DOCUMENT_TAB, extraForm.documentTab);
    }
  }
  
  const deleteHandler = (id: string, fieldType: string) => {
    const resetData = { isValid: false, isRequired: false, dataType: DataTypes.VALUE_ANY, title: '', fieldSize: FieldSizes.VALUE_STANDARD, isSendToProject: false, arrayValues: [] } as any;

    setAdditionalFieldsData(state => {
      let newFields = [ ...state ];

      return newFields.filter(field => field.id !== id);
    });

    if(fieldType === SelectedTabNameExtraForm.INPUT_TAB) {
      setExtraFormInputTabData(resetData);
    }
    if(fieldType === SelectedTabNameExtraForm.SELECT_TAB) {
      setExtraFormSelectTabData(resetData);
    }
    if(fieldType === SelectedTabNameExtraForm.RADIO_TAB) {
      setExtraFormRadioTabData(resetData);
    }
    if(fieldType === SelectedTabNameExtraForm.CHECKBOX_TAB) {
      setExtraFormCheckboxTabData(resetData);
    }
    if(fieldType === SelectedTabNameExtraForm.DOCUMENT_TAB) {
      setExtraFormDocumentTabData(resetData);
    }

    setExtraFormRefreshCheckboxTrigger(nanoid(6));
  }

  const changeHandler = (
    id: string, 
    fieldType: string, 
    action: string, 
    value: boolean
  ) => {
    setAdditionalFieldsData(state => {
      const newFields = [ ...state ];
      const updateIndex = state.findIndex(field => field.id === id);

      if(updateIndex === -1) {
        return state;
      }

      newFields[updateIndex][action] = value;

      return newFields;
    });

    if(fieldType === SelectedTabNameExtraForm.INPUT_TAB) {
      setExtraFormInputTabData({[action]: value});
    }
    if(fieldType === SelectedTabNameExtraForm.SELECT_TAB) {
      setExtraFormSelectTabData({[action]: value});
    }
    if(fieldType === SelectedTabNameExtraForm.RADIO_TAB) {
      setExtraFormRadioTabData({[action]: value});
    }
    if(fieldType === SelectedTabNameExtraForm.CHECKBOX_TAB) {
      setExtraFormCheckboxTabData({[action]: value});
    }
    if(fieldType === SelectedTabNameExtraForm.DOCUMENT_TAB) {
      setExtraFormDocumentTabData({[action]: value});
    }

    setExtraFormRefreshCheckboxTrigger(nanoid(6));
  }

  const onOpenExtraFormTab = (fieldType: string) => {
    const activeTabIndex = Object.values(SelectedTabNameExtraForm).findIndex((el) => el === fieldType);

    if(typeof activeTabIndex === 'number') {
      setExtraFormDefaultOpen(false);
      setExtraFormActiveTabIndex(activeTabIndex);
    }
  };

  const onOpenExtraForm = () => {
    setTaskFullFormOpen(true);
    setExtraFormDefaultOpen(false);
  };

  const onOpenExtraFormShowEditFormButton = () => {
    setIsShowEditFormButton(false);
    
    setTaskFullFormOpen(true);
    setExtraFormDefaultOpen(false);
  };
  
  useEffect(() => {
    if(addFieldTrigger?.length) {
      setAdditionalFieldsAdapter();
    }
  }, [addFieldTrigger]);

  useEffect(() => {
    onChangeFieldsPosition(additionalFieldsData);
  }, [additionalFieldsData]);
  
  useEffect(() => {
    if(formDefaultValuesTrigger.length) {
      setAdditionalFieldsData([]);
    }
  }, [formDefaultValuesTrigger]);

  useEffect(() => {
    if(data?.length) {
      setAdditionalFieldsData(data);
    }
  }, [data]);
  
  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      {additionalFieldsData?.length > 0 && (
        <div className={"additional-fields" +
                       ((isEditForm && !isFullFormTasksOpen) ? " additional-fields--edit-form" : "")
        }>
          <div className="additional_title">
            <div className="field_type">Тип поля</div>
            <div className="name_field">Наименование поля</div>
            <div className="necessarily_field">Обязательно</div>
            {/* Временно скрыто */}
            {/*<div className="transfer_field">Передавать в объект</div>*/}
          </div>

          <div className="additional-fields__items">
            <SortableContext items={additionalFieldsData as []}
                             strategy={verticalListSortingStrategy}
            >
              {additionalFieldsData?.map(field => (
                <div className="additional-fields__item"
                     key={field.id}
                >
                  <AdditionalFieldsItem
                    fieldType={field.type as string}
                    fieldData={field}
                    isDisabled={isEditForm && !isFullFormTasksOpen}
                    onOpenExtraFormTab={onOpenExtraFormTab}
                    deleteHandler={deleteHandler}
                    changeHandler={changeHandler}
                  />
                </div>
              ))}
            </SortableContext>
          </div>

          {!additionalFieldsData.length && (
            <div className="additional-fields__no-items">Нет дополнительных полей...</div>
          )}
        </div>
      )}

      {/* Общее */}
      
      {!additionalFieldsData.length && (
        <div className="additional-fields__no-items additional-fields__no-items--big">
          Дополнительные поля пока не добавлены.

          {(isFullFormTasksOpen && !isExtraFormDefaultOpen) && (
            <div>Заполните форму справа.</div>
          )}
        </div>
      )}
      
      {/* Для обычной формы*/}

      {(!isEditForm && 
        (!isFullFormTasksOpen || 
          (isFullFormTasksOpen && isExtraFormDefaultOpen))) && (
        <button className='additional-fields__add-field'
                onClick={onOpenExtraForm}
        >
          Добавить поле
        </button>
      )}
      
      {/* Для формы редактирования*/}
      
      {(isEditForm && !isFullFormTasksOpen) && (
        <button className='additional-fields__add-field'
                onClick={onOpenExtraFormShowEditFormButton}
        >
          Настроить форму исполнения задачи
        </button>
      )}

      {(isEditForm && 
        (isFullFormTasksOpen && isExtraFormDefaultOpen)) && (
        <button className='additional-fields__add-field'
                onClick={onOpenExtraForm}
        >
          Добавить поле
        </button>
      )}
    </DndContext>
  );
};

const mapStateToProps = (state: State) => {
  return {
    isEditForm: state.typicalTasksPage.isEditForm,
    isExtraFormDefaultOpen: state.typicalTasksPage.extraForm.isExtraFormDefaultOpen,
    isExtraForm: state.typicalTasksPage.extraForm,
    addFieldTrigger: state.typicalTasksPage.normalForm.addFieldTrigger,
    extraForm: state.typicalTasksPage.extraForm,
    additionalFields: state.typicalTasksPage.normalForm.additionalFields,
    isFullFormTasksOpen: state.typicalTasksPage.isFullFormTasksOpen,
    formDefaultValuesTrigger: state.typicalTasksPage.formDefaultValuesTrigger
  };
};

const mapDispatchToProps = {
  setExtraFormInputTabData,
  setExtraFormSelectTabData,
  setExtraFormCheckTrigger,
  setExtraFormRadioTabData,
  setExtraFormCheckboxTabData,
  setExtraFormDocumentTabData,
  setExtraFormRefreshCheckboxTrigger,
  setExtraFormActiveTabIndex,
  setTaskFullFormOpen,
  setExtraFormDefaultOpen
};

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalFields);
