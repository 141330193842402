import React, { FC } from "react";
import { State } from "../../../../rootReducer";
import { connect } from "react-redux";
import PreloaderLocal from "../../../../common/components/PreloaderLocal/PreloaderLocal";
import { IMeta } from "../../../../common/types";
import {
  getBusinessProcess,
  setBPFormOpen,
  setCurrentPage,
} from "../../actions";

import "./BusinessProcessList.scss";
import BusinessProcess from "./components/BusinessProcess/BusinessProcess";
import BusinessProcessTableHeader from "../BusinessProcessTableHeader/BusinessProcessTableHeader";
import { IBusinessProcessServerData } from "../../interfaces";
import FiltersBP from "../FilterBP/FilterBP";

const BusinessProcessList: FC<{
  isLoadingBp: boolean;
  bp: IBusinessProcessServerData[];
  setBPFormOpen: (mode: boolean) => void;
  businessProcessMeta: IMeta | null;
  getBusinessProcess: (params?: object, action?: string) => void;
  setCurrentPage: (currentPage: number) => void;
  currentPage: number;
}> = ({
  isLoadingBp,
  bp,
  setBPFormOpen,
  businessProcessMeta,
  getBusinessProcess,
  setCurrentPage,
  currentPage,
}) => {
  const scrollHandler = (e: React.UIEvent<HTMLDivElement>) => {
    if (!businessProcessMeta) return;

    const { scrollHeight, scrollTop, clientHeight } = e.currentTarget;

    if (
      scrollHeight <= scrollTop + clientHeight &&
      currentPage < businessProcessMeta.last_page
    ) {
      setCurrentPage(currentPage + 1);
      getBusinessProcess({}, "add");
    }
  };

  const formOpenHandler = () => {
    setBPFormOpen(true);
  };

  return (
    <div className="main-business_process">
      <h1 className="main-business_process__title">Бизнес-процессы</h1>

      <div className="main-business_process__filters">
        <FiltersBP />
      </div>

      <div className="main-business_process__content">
        {bp.length > 0 && <BusinessProcessTableHeader />}
        <div className="main-business_process__table">
          <div
            className={`main-business_process__preloader-wr 
                          ${
                            isLoadingBp
                              ? " main-business_process__preloader-wr--show"
                              : ""
                          }`}
          >
            <div className="main-business_process__preloader">
              <PreloaderLocal />
            </div>
          </div>

          <div
            className="main-business_process__table-items"
            onScroll={(e) => scrollHandler(e)}
          >
            {bp?.map((item) => (
              <BusinessProcess key={item.id} bp={item} />
            ))}

            {!bp.length && (
              <div className="main-business_process__none-list">
                Список бизнес-процессов пока пуст, вы можете
                <span
                  className="main-business_process__add_bp"
                  onClick={formOpenHandler}
                >
                  добавить бизнес-процесс
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: State) => ({
  isLoadingBp: state.businessPage.isLoadingBp,
  bp: state.businessPage.businessProcess,
  businessProcessMeta: state.businessPage.businessProcessMeta,
  currentPage: state.businessPage.currentPage,
});

const mapDispatchToProps = {
  getBusinessProcess,
  setCurrentPage,
  setBPFormOpen,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BusinessProcessList);
