import React, { FC } from "react";
import CommentTab from "../../../../../TaskInfoPlate/components/CommentTab";
import { connect } from "react-redux";
import { State } from "../../../../../../rootReducer";

import './CommentsTab.scss';

const CommentsTab: FC<{
  idTaskEdited: string|number,
  isUserCanEdit: boolean
}> = ({
  idTaskEdited,
  isUserCanEdit
}) => {
  return (
    <div className='comments-tab-objects-task-edit'>
     {/*Вернуть с проверкой на !isUserCanEdit див с классом = comments-tab-objects-task-edit--disabled, если надо ограничить редактирование */}
      
      <CommentTab requestFrom='taskMeridian' 
                  idRequestFrom={idTaskEdited}
      />
    </div>
  );
};

const mapStateToProps = (state: State) => ({
  idTaskEdited: state.objectsFormEditTask.data.taskId
});

const mapDispatchToProps = {
  
}

export default connect(mapStateToProps, mapDispatchToProps)(CommentsTab);
