import axios from "axios";
import React, {
  useContext,
  useEffect,
  useMemo,
  useState,
  useCallback,
} from "react";
import { StatusContext } from "../../../context/status";
import TextareaAutosize from "react-textarea-autosize";
import procent from "../../../../../../img/percentage.svg";
import TaskCheckList from "../../create-task-form/components/taskCheckList/TaskCheckList";
import TaskPriority from "../../create-task-form/components/taskPriority/TaskPriority";
import TaskCreateAddFileButton from "../../create-task-form/components/TaskCreateAddFileButton";
import TaskCreateLoad from "../../create-task-form/components/TaskCreateLoad";
import { CreateNotif } from "../../../../../../utils/createNotification";
import MultySelect from "../../create-task-form/components/MultySelect/MultySelect";
import { formatToStringUTC } from "../../../../../../common/format";
import { fetchData } from "../../../../../../utils/fetchData";

const TaskInfoMenu = ({
  headers,
  changeStatus,
  businessProcess,
  checklistTask,
  setChecklistTask,
  projectTask,
  setProjectTask,
  projectSectionTask,
  setProjectSectionTask,
  priorityTask,
  setPriorityTask,
  authorTask,
  setAuthorTask,
  filesTask,
  setFilesTask,
  taskLoad,
  setTaskLoad,
  workLoad,
  setWorkLoad,
  nameTask,
  setNameTask,
  selectedExecutor,
  setSelectedExecutor,
  beginDayTask,
  setBeginDayTask,
  endDayTask,
  setEndDayTask,
  beginTask,
  setBeginTask,
  endTask,
  setEndTask,
  beginTimeTask,
  setBeginTimeTask,
  endTimeTask,
  setEndTimeTask,
  sectionsOfTask,
  nameBp,
  setNameBp,
  projectBp,
  setProjectBp,

  sectionsBp,
  setSectionsBp,
  setProjectSectionsBp,
}) => {
  const {
    isAdmin,
    bearer,
    openMenuTasks,
    taskCurrentGlobal,
    setTaskCurrentGlobal,
    openMenuBp,
    setOpenMenuTasks,
    setOpenMenuBp,
    apiBp,
    users,
    selectedTaskOfBP,
    setStatusTask,
    setStatusBp,
    setStatusBpId,
    setStatusTaskId,
    setChange,
    taskId,
    selectedBp,
    setCreateBpFromSample,
    setBpListChange,
    isTemplateTask,
    setIsTemplateTask,
    thisTabs,
    statusOfTask,
    executorIdofTask,
    userId,
    businessProcessCurrentGlobal,
    setBusinessProcessCurrentGlobal,
    company_id,
    setIdCall,
    setExecutorIdofTask,
    setStatusOfTask,
    setTaskId,
    setSelectedTaskOfBP,
    setRedirectedByClick,
    descript,
    setDescript,
    bp,
    projects,
    etTaskId,
    projectSectionBp,
    setProjectSectionBp,
    setIdExecutor,
  } = useContext(StatusContext);

  const [tasksOfBp, setTasksOfBp] = useState();
  const [prevTaskIndex, setPrevTaskIndex] = useState();
  const [nextTaskIndex, setNextTaskIndex] = useState();
  const [dateError, setDateError] = useState(false);

  const [typeOfValue, setTypeOfValue] = useState("task_load");
  const [blockChanges, setBlockChanges] = useState("task_load");
  const [project, setProject] = useState();
  const [projectSection, setProjectSection] = useState({});

  // console.log("0000000000000000000", projectTask, projectSectionTask);

  useEffect(() => {
    switch (true) {
      case statusOfTask === 0: {
        setBlockChanges(false);
        break;
      }
      case statusOfTask === 2: {
        setBlockChanges(false);
        break;
      }

      default: {
        setBlockChanges(true);
        break;
      }
    }
  }, [statusOfTask]);

  // useEffect(() => {
  //   if (taskId) {
  //     let config = {
  //       method: "get",
  //       url: `${apiBp}/task/${taskId}`,
  //       headers: headers,
  //     };

  //     axios(config)
  //       .then(function (response) {
  //         // setTaskCurrentGlobal(response.data);
  //         if (response.data.template_id) {
  //           setIsTemplateTask(true);
  //         } else {
  //           setIsTemplateTask(false);
  //         }
  //         if (etTaskId) {
  //           // setChecklistTask(response.data.et_task.checklist);
  //           setCurrentEtTask(response.data.et_task);
  //         } else {
  //           setChecklistTask(response.data.data.checklist);
  //         }
  //         // setSelectedExecutor(+response.data.executor_id);
  //         // setBeginTask(response.data.begin);
  //         // setEndTask(response.data.end);
  //         // setBeginDayTask(response.data.begin.slice(0, 10));
  //         // setEndDayTask(response.data.end.slice(0, 10));
  //         // setBeginTimeTask(response.data.begin.slice(11, 16));
  //         // setEndTimeTask(response.data.end.slice(11, 16));
  //         // setNameTask(response.data.name);
  //         // setDescript(response.data.description);
  //         // setStatusTask(response.data.status);
  //         // setEtTaskId(+response.data.et_task_id);
  //         // setCurrentEtTask(+response.data.et_task);
  //         // setAuthorTask(+response.data.data.author_id);
  //         // setPriorityTask(+response.data.data.priority_id);
  //         // setProjectTask(+response.data.data.project_id);
  //         // setProjectSectionTask(+response.data.data.project_section_id);
  //         // setTaskLoad(+response.data.data.task_load);
  //         // setWorkLoad(+response.data.data.work_load);
  //       })
  //       .catch((e) => {
  //         console.log(e);
  //       });
  //   }
  // }, [
  //   taskId,
  //   selectedBp,
  //   change,
  //   openMenuTasks,
  //   createBpFromSample,
  //   selectedBp,
  //   thisTabs,
  //   closeWindowTask,
  //   changeTask,
  //   businessProcessCurrentGlobal,
  // ]);
  const executors = useMemo(() => users, [users]);
  const etApi = process.env.REACT_APP_PUBLIC_API;

  const [execNameAndSurname, setExecNameAndSurname] = useState();

  const [execNameAndSurname2, setExecNameAndSurname2] = useState();

  useEffect(() => {
    if (users.length) {
      const initiator = users.find(
        (user) => user.id == businessProcess.initiator_id
      );
      if (!initiator) {
        fetchData
          .get(`/api/v1/users/${businessProcess.initiator_id}`)
          .then((res) => {
            setExecNameAndSurname(`${res?.name} ${res?.surname} (неактивен)`);
          });
      } else {
        setExecNameAndSurname(`${initiator.name} ${initiator.surname}`);
      }
    }
  }, [businessProcess, users]);
  useEffect(() => {
    const initiator = users.find((user) => user.id == selectedExecutor);
    if (!initiator && selectedExecutor) {
      fetchData
        .get(`/api/v1/users/${selectedExecutor}`)
        .then((res) => setExecNameAndSurname2(res));
    } else {
      setExecNameAndSurname2(initiator);
    }
  }, [selectedExecutor]);

  // console.log(execNameAndSurname2);
  /* Дата начала 1 задачи- сегодняшнее число
  Дата окончания последней задачи- себгодняшнее число + 10 дней
  Дополнительно добавлена возможность указать срок окончания из созданного БП для отображения во вкладке общей информации */

  let begin = new Date(Date.now());
  let end = new Date(Date.parse(businessProcess.deadline));

  // Для бизнес-процесса "Договор". (11 задач в типовой задаче. По одной задаче на день, включая сегодняшний )
  let monthBegin =
    begin.getMonth() + 1 < 10
      ? "0" + (begin.getMonth() + 1)
      : begin.getMonth() + 1;
  let dayBegin = begin.getDate() < 10 ? "0" + begin.getDate() : begin.getDate();
  let monthEnd =
    end.getMonth() + 1 < 10 ? "0" + (end.getMonth() + 1) : end.getMonth() + 1;
  let dayEnd = end.getDate() < 10 ? "0" + end.getDate() : end.getDate();

  let dateBegin = begin.getFullYear() + "-" + monthBegin + "-" + dayBegin;

  let dateEnd = end.getFullYear() + "-" + monthEnd + "-" + dayEnd;
  // console.log(businessProcess);
  // console.log(monthEnd);
  // console.log(dayEnd);
  let hoursEnd = end.getHours() < 10 ? "0" + end.getHours() : end.getHours();

  let minutesEnd =
    end.getMinutes() < 10 ? "0" + end.getMinutes() : end.getMinutes();

  let timeEnd = hoursEnd + ":" + minutesEnd;
  const [author, setAuthor] = useState();
  useEffect(() => {
    if (executors.length && taskCurrentGlobal?.data?.author_id) {
      const author = executors.find(
        (user) => user.id === taskCurrentGlobal?.data?.author_id
      );
      if (author) {
        setAuthor(`${author?.name} ${author?.surname}`);
      } else {
        fetchData
          .get(`/api/v1/users/${taskCurrentGlobal?.data?.author_id}`)
          .then((res) => {
            setAuthor(`${res.name} ${res.surname} (неактивен)`);
          });
      }
    }
  }, [taskCurrentGlobal, executors]);

  useEffect(() => {
    if (businessProcess && businessProcess.tasks && taskId) {
      setTasksOfBp(businessProcess.tasks);
      const tasks = businessProcess.tasks;

      tasks.forEach((task, index, arr) => {
        if (task.id === taskId) {
          if (index - 1 >= 0) {
            setPrevTaskIndex(index - 1);
          } else {
            setPrevTaskIndex();
          }
          if (index + 1 < arr.length) {
            setNextTaskIndex(index + 1);
          } else {
            setNextTaskIndex();
          }
        }
      });
    }
  }, [businessProcess, taskId]);

  // useEffect(() => {etTaskId? changeHandler('',value):changeHandler('',value)}, [filesTask]);

  // useEffect(() => {
  //   if (
  //     checklistTask &&
  //     (taskCurrentGlobal.status === 0 || taskCurrentGlobal.status === 2) &&
  //     !etTaskId
  //   ) {
  //     changeHandler("checkListBp", checklistTask);
  //   }
  // }, [taskChange]);

  useEffect(() => {
    if (
      priorityTask &&
      (taskCurrentGlobal.status === 0 || taskCurrentGlobal.status === 2)
    ) {
      // etTaskId
      //   ? changeHandler("priorityEt", priorityTask, "et")
      //   :
      changeHandler("priorityBp", priorityTask);
    }
  }, [priorityTask]);

  useEffect(() => {
    if (
      typeOfValue &&
      +executorIdofTask > 0 &&
      beginTask &&
      endTask &&
      (taskCurrentGlobal.status === 0 || taskCurrentGlobal.status === 2)
    ) {
      switch (typeOfValue) {
        case "task_load": {
          let config = {
            method: "get",
            url: `${etApi}/task-load?userId=${+executorIdofTask}&begin=${formatToStringUTC(
              beginTask
            )}&end=${formatToStringUTC(endTask)}&task_load=${+taskLoad}`,
            headers: {
              Authorization: bearer,
              "company-id": company_id,
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          };
          axios(config)
            .then((res) => {
              setWorkLoad(+res.data.work_load);
            })
            .then(() => {
              if (etTaskId) {
                // changeHandler("loadEt", 1, "et");
                changeHandler("loadBp");
              } else {
                changeHandler("loadBp");
              }
            });
          break;
        }
        case "work_load": {
          let config = {
            method: "get",
            url: `${etApi}/task-load?userId=${+executorIdofTask}&begin=${formatToStringUTC(
              beginTask
            )}&end=${formatToStringUTC(endTask)}&work_load=${+workLoad}`,
            headers: {
              Authorization: bearer,
              "company-id": company_id,
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          };
          axios(config)
            .then((res) => {
              setTaskLoad(+res.data.task_load);
            })
            .then(() => {
              // if (etTaskId) {
              //   changeHandler("loadEt", 1, "et");
              // } else {
              changeHandler("loadBp");
              // }
            });
          break;
        }
        default:
          console.log("IBRA");
          break;
      }
    }
  }, [typeOfValue, taskLoad, workLoad]);

  const changeHandler = (type, value = true, et = false) => {
    // console.log("сработала", type);
    let data;
    switch (type) {
      //Название
      case "name": {
        data = {
          name: value,
        };
        break;
      }
      // Описание
      case "description": {
        data = {
          description: value,
        };
        break;
      }

      // Чек-лист БП
      // case "checkListBp": {
      //   console.log(1);
      //   data = {
      //     data: {
      //       ...taskCurrentGlobal.data,
      //       checklist: value,
      //     },
      //   };
      //   break;
      // }

      // Проект
      case "projectBp": {
        // console.log(2);
        data = {
          data: {
            ...taskCurrentGlobal.data,
            project_id: Number(value),
          },
        };
        break;
      }

      // Раздел
      case "projectSectionBp": {
        // console.log(3);
        data = {
          data: {
            ...taskCurrentGlobal.data,
            project_section_id: Number(value),
          },
        };
        break;
      }

      // Приоритет
      case "priorityBp": {
        // console.log(4);
        data = {
          data: {
            ...taskCurrentGlobal.data,
            priority_id: Number(value),
          },
        };
        break;
      }

      // Исполнитель
      case "executor": {
        data = {
          executor_id: Number(value),
        };
        break;
      }

      // Дата начала
      case "beginDay": {
        let beginInput = value + "T" + beginTimeTask; // введенное дата и время в форме
        if (value > endDayTask) {
          break;
        } else {
          data = { begin: new Date(beginInput) };
          break;
        }
      }

      // Время начала
      case "beginTime": {
        let beginInput = beginDayTask + "T" + value; // введенное дата и время в форме
        data = { begin: new Date(beginInput) };
        break;
      }

      // Дата окончания
      case "endDay": {
        let endInput = value + "T" + endTimeTask; // введенное дата и время в форме
        data = { end: new Date(endInput) };
        break;
      }

      // Время окончания
      case "endTime": {
        let endInput = endDayTask + "T" + value; // введенное дата и время в форме
        data = { end: new Date(endInput) };
        break;
      }

      // Прикрепить файл
      // case "executor": {
      //   data = {
      //     executor_id: Number(value),
      //   };
      //   break;
      // }

      // Загрузка
      case "loadBp": {
        if (taskLoad > 0 && workLoad > 0) {
          data = {
            data: {
              ...taskCurrentGlobal.data,
              task_load: taskLoad,
              work_load: workLoad,
            },
          };
        } else if (taskLoad === 0 || workLoad === 0) {
          data = {
            data: {
              ...taskCurrentGlobal.data,
              task_load: 0,
              work_load: 0,
            },
          };
        }
        break;
      }
      default:
        break;
    }

    let config = {
      method: "patch",
      url: `${apiBp}/task/${taskId}`,
      headers: {
        Authorization: bearer,
        "company-id": company_id,
        "secret-token": document.cookie.replace(
          /(?:(?:^|.*;\s*)access_token_jwt\s*\=\s*([^;]*).*$)|^.*$/,
          "$1"
        ),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data),
    };

    if (config)
      axios(config)
        .then((res) => {
          // console.log(res.data);
          setBpListChange((prev) => !prev);
          setTaskCurrentGlobal(res.data);
          // setProjectTask(res.data.data.project_id);
          // setProjectSectionTask(res.data.data.project_section_id);
        })
        .catch((error) => {
          let text = [];
          let field;
          for (field in error.response.data.errors) {
            for (text in error.response.data.errors[field]) {
              CreateNotif(
                `${error.response.data.errors[field][text]}`,
                "error"
              );
            }
          }
          console.log("ошибка обновления задачи ", error);
        });
  };

  const changeBp = (type, value = true, et = false) => {
    // console.log("сработала", type);
    let data;
    switch (type) {
      //Название
      case "name": {
        data = {
          name: value,
        };
        break;
      }
      // Описание
      // case "description": {
      //   data = {
      //     description: value,
      //   };
      //   break;
      // }

      // Проект
      case "projectIdBp": {
        // console.log("projectIdBp");
        data = { project_id: Number(value) };
        break;
      }

      // Раздел
      case "projectSectionIdBp": {
        // console.log(3);
        data = {
          project_section_id: Number(value),
        };
        break;
      }

      default:
        break;
    }

    let configBp = {
      method: "patch",
      url: `${apiBp}/bp/${selectedBp}`,
      headers: {
        Authorization: bearer,
        "company-id": company_id,
        "secret-token": document.cookie.replace(
          /(?:(?:^|.*;\s*)access_token_jwt\s*\=\s*([^;]*).*$)|^.*$/,
          "$1"
        ),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data),
    };

    if (configBp)
      axios(configBp)
        .then((res) => {
          // console.log(res.data);
          setBpListChange((prev) => !prev);
          setBusinessProcessCurrentGlobal(res.data);
          // setProjectTask(res.data.data.project_id);
          // setProjectSectionTask(res.data.data.project_section_id);
        })
        .catch((error) => {
          setBpListChange((prev) => !prev);
          let text = [];
          let field;
          for (field in error.response.data.errors) {
            for (text in error.response.data.errors[field]) {
              CreateNotif(
                `${error.response.data.errors[field][text]}`,
                "error"
              );
            }
          }
          console.log("ошибка обновления задачи ", error);
        });
  };

  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState({
    value: "",
    id: "",
  });
  const [searchData, setSearchData] = useState("");

  useEffect(() => {
    if (execNameAndSurname2) {
      setSelectedItem({
        value: `${execNameAndSurname2?.name} ${execNameAndSurname2?.surname} ${
          execNameAndSurname2.is_active ? "" : "(неактивен)"
        }`,
        id: execNameAndSurname2?.id,
      });
    }
  }, [execNameAndSurname2]);

  // console.log(execNameAndSurname2, selectedExecutor);

  useEffect(() => {
    setIdExecutor(selectedItem.id);
  }, [selectedItem]);

  const outsideClickHandler = useCallback(
    (e) => {
      if (isOpen) {
        setIsOpen(false);
      }
    },
    [isOpen]
  );

  useEffect(() => {
    document.addEventListener("click", outsideClickHandler);
    return () => document.removeEventListener("click", outsideClickHandler);
  }, [outsideClickHandler]);

  return (
    <>
      <>
        {openMenuTasks && taskId && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 35 + "px",
            }}
          >
            <div>
              <label className="p__drop-content" htmlFor="input-name-Task">
                <img
                  src={`${process.env.REACT_APP_PUBLIC_URL}/assets/input/Article.svg`}
                />
                Наименование задачи*
              </label>
              <input
                name={"nameOfTaskInChatMenu"}
                className="input-form"
                type={"text"}
                value={
                  businessProcess.template_id === null
                    ? nameTask
                    : selectedTaskOfBP
                }
                readOnly={
                  businessProcess.template_id === null && statusOfTask === 0
                    ? false
                    : true
                }
                style={
                  businessProcess.template_id === null && statusOfTask === 0
                    ? { cursor: "pointer" }
                    : { cursor: "default" }
                }
                onChange={(e) => {
                  if (
                    businessProcess.template_id === null &&
                    (statusOfTask === 0 || statusOfTask === 2)
                  ) {
                    setNameTask((prev) => e.target.value);
                    changeHandler("name", e.target.value);
                  }
                }}
              />
            </div>
            {!businessProcessCurrentGlobal.template_id && (
              <>
                <div>
                  <label className="p__drop-content">
                    <img
                      src={`${process.env.REACT_APP_PUBLIC_URL}/assets/input/NewspaperClipping.svg`}
                    />
                    Описание
                  </label>

                  <TextareaAutosize
                    minRows={5}
                    className="input-form input-form-create-bp"
                    value={
                      businessProcess.template_id === null
                        ? (descript ?? '')
                        : selectedTaskOfBP
                    }
                    readOnly={
                      businessProcess.template_id === null && statusOfTask === 0
                        ? false
                        : true
                    }
                    style={
                      businessProcess.template_id === null && statusOfTask === 0
                        ? { cursor: "pointer" }
                        : { cursor: "default" }
                    }
                    onChange={(e) => {
                      if (
                        businessProcess.template_id === null &&
                        (statusOfTask === 0 || statusOfTask === 2)
                      ) {
                        setDescript((prev) => e.target.value);
                        changeHandler("description", e.target.value);
                      }
                    }}
                  />
                </div>
                {/* <div>
                  <TaskCheckList
                    checkListArr={checklistTask}
                    setCheckListArr={setChecklistTask}
                    blockChanges={blockChanges}
                  />
                </div> */}
                <div>
                  <label className="p__drop-content" htmlFor="input-project">
                    <img
                      src={`${process.env.REACT_APP_PUBLIC_URL}/assets/input/Article.svg`}
                      alt="Article"
                    />
                    Проект*
                  </label>
                  <select
                    id="input-project"
                    className={
                      statusOfTask === 0
                        ? "input-form"
                        : "input-form blockOfFormAutor"
                    }
                    value={projectTask}
                    style={{
                      pointerEvents: `${isTemplateTask ? "none" : ""}`,
                      cursor: "pointer",
                    }}
                    onChange={(e) => {
                      if (e.target.value !== "Выбрать" && taskId) {
                        // console.log("99999999", e.target.value);
                        setProjectTask(e.target.value);
                        changeHandler("projectBp", e.target.value);
                      }
                    }}
                  >
                    <option value={null}>Выбрать</option>
                    {projects.map((el) => {
                      return (
                        <option key={el.id} value={el.id}>
                          {el.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div>
                  <label
                    className="p__drop-content"
                    htmlFor="input-project-section"
                  >
                    <img
                      src={`${process.env.REACT_APP_PUBLIC_URL}/assets/input/Article.svg`}
                      alt="Article"
                    />
                    Раздел проекта*
                  </label>
                  <select
                    id="input-project-section"
                    className={
                      statusOfTask === 0
                        ? "input-form"
                        : "input-form blockOfFormAutor"
                    }
                    value={projectSectionTask}
                    style={{
                      pointerEvents: `${isTemplateTask ? "none" : ""}`,
                      cursor: "pointer",
                    }}
                    onChange={(e) => {
                      if (e.target.value !== "Выбрать" && taskId) {
                        setProjectSectionTask(+e.target.value);
                        changeHandler("projectSectionBp", e.target.value);
                      }
                    }}
                  >
                    <option value={null}>Выбрать</option>
                    {sectionsOfTask?.map((el) => {
                      return (
                        <option key={el.id} value={el.id}>
                          {el.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div>
                  <div className="oneRowTwoElem">
                    <div>
                      <label
                        className="p__drop-content "
                        htmlFor="businessTask__author"
                      >
                        <img
                          src={`${process.env.REACT_APP_PUBLIC_URL}/assets/input/User.svg`}
                        />
                        Автор
                      </label>
                      <input
                        type="text"
                        className="input-form blockOfFormAutor"
                        id="businessTask__author"
                        value={
                          authorTask && author
                           
                        }
                      ></input>
                    </div>

                    <div>
                      <label
                        className="p__drop-content"
                        // htmlFor="businessTask__executor"
                      >
                        <img
                          src={`${process.env.REACT_APP_PUBLIC_URL}/assets/input/Article.svg`}
                          alt="Article"
                        />
                        Приоритет
                      </label>
                      <div className={statusOfTask === 0 ? "" : "blockChange"}>
                        <TaskPriority
                          priorityId={priorityTask}
                          setPriorityId={setPriorityTask}
                          blockChanges={blockChanges}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}

            {/* <div>
              <label className="p__drop-content" htmlFor="input-project">
                <img
                  src={`${process.env.REACT_APP_PUBLIC_URL}/assets/input/Article.svg`}
                  alt="Article"
                />
                Проект*
              </label>
              <select
                className="input-form"
                value={
                  createBpSampleForm.businessProcess.project_id
                    ? createBpSampleForm.businessProcess.project_id
                    : ""
                }
                onChange={(e) => {
                  setCreateBpSampleForm({
                    ...createBpSampleForm,
                    businessProcess: {
                      ...createBpSampleForm.businessProcess,
                      project_id: parseInt(e.target.value),
                    },
                  });
                  setCreateBpForm({
                    ...createBpForm,
                    project_id: parseInt(e.target.value),
                  });
                  setCreateTaskForm(
                    {
                    ...createTaskForm,
              data: {
                ...createTaskForm.data,
                project_id: parseInt(e.target.value),
              },
                  });
                }}
              >
                <option value={null}>Выбрать</option>
                {projects.map((el) => {
                  return (
                    <option key={el.id} value={el.id}>
                      {el.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div>
              <label
                className="p__drop-content"
                htmlFor="input-project-section"
              >
                <img
                  src={`${process.env.REACT_APP_PUBLIC_URL}/assets/input/Article.svg`}
                  alt="Article"
                />
                Раздел проекта*
              </label>
              <select
                id="input-project-section"
                className="input-form"
                disabled={!createBpForm.project_id ? true : false}
                value={
                  createBpSampleForm.businessProcess.project_section_id
                    ? createBpSampleForm.businessProcess.project_section_id
                    : ""
                }
                onChange={(e) => {
                  console.log(e.target.value);
                  setCreateBpSampleForm({
                    ...createBpSampleForm,
                    businessProcess: {
                      ...createBpSampleForm.businessProcess,
                      project_section_id: parseInt(e.target.value),
                    },
                  });
                  setCreateBpForm({
                    ...createBpForm,
                    project_section_id: parseInt(e.target.value),
                  });
                  setCreateTaskForm({
                    ...createTaskForm,
              data: {
                ...createTaskForm.data,
                project_section_id: parseInt(e.target.value),
              },
                  });
                }}
              >
                <option value={null}>Выбрать</option>
                {projectSections.map((el) => {
                  return (
                    <option key={el.id} value={el.id}>
                      {el.name}
                    </option>
                  );
                })}
              </select>
            </div> */}

            <div className="deleteArrow">
              <label
                className="p__drop-content"
                htmlFor="businessTask__executor"
              >
                <img
                  src={`${process.env.REACT_APP_PUBLIC_URL}/assets/input/User.svg`}
                />
                Исполнитель
              </label>
              {/* <select
                className={
                  statusOfTask === 0
                    ? "input-form"
                    : "input-form blockOfFormAutor"
                }
                id="businessTask__executor"
                value={selectedExecutor}
                style={{
                  pointerEvents: `${isTemplateTask ? "none" : ""}`,
                  cursor: "pointer",
                }}
                onChange={(e) => {
                  setSelectedExecutor((prev) => e.target.value);
                  changeHandler("executor", e.target.value);
                }}
              >
                {executors.map((executor) => (
                  <option key={executor.id} value={executor.id}>
                    {executor.name} {executor.surname}
                  </option>
                ))}
              </select> */}
              {/* <MultySelect
                executors={executors}
                setIdExecutor={setIdExecutorPatch}
                selectedExecutor={selectedExecutor}
              /> */}

              <div
                style={
                  statusOfTask === 0
                    ? { position: "relative" }
                    : { position: "relative", pointerEvents: "none" }
                }
              >
                <input
                  list={"executor_task"}
                  style={{ marginLeft: "40px", width: 600 }}
                  className="input-form executor_input"
                  id="executor_tasks_bp"
                  value={searchData ? searchData : selectedItem?.value}
                  onChange={(e) => {
                    setIsOpen(true);
                    setSearchData(e.target.value);
                  }}
                  onClick={() => {
                    setIsOpen(!isOpen);
                    setSelectedItem({ value: "", id: "" });
                    setIdExecutor(selectedItem.id);
                  }}
                />
                {isOpen && (
                  <div
                    className="select_div"
                    style={{
                      position: "absolute",
                      flexDirection: "column",
                    }}
                  >
                    {executors
                      .filter((item) => {
                        if (searchData) {
                          if (
                            item.name
                              .toLowerCase()
                              .indexOf(searchData.toLowerCase()) >= 0 ||
                            item.surname
                              .toLowerCase()
                              .indexOf(searchData.toLowerCase()) >= 0
                          ) {
                            return true;
                          } else {
                            return false;
                          }
                        } else {
                          return true;
                        }
                      })
                      .map((item) => (
                        <div
                          key={item.id}
                          className={"item_sel"}
                          id={item.id}
                          onClick={(e) => {
                            e.stopPropagation();
                            setSearchData("");
                            if (item.value !== selectedItem?.value) {
                              setSelectedItem({
                                value: `${item.name} ${item.surname}`,
                                id: item.id,
                              });
                            }
                            changeHandler("executor", item.id);
                            setIsOpen(false);
                          }}
                        >
                          {item.name} {item.surname}
                        </div>
                      ))}
                  </div>
                )}
              </div>
            </div>
            <div className="input__date">
              <label className="p__drop-content">
                <img
                  src={`${process.env.REACT_APP_PUBLIC_URL}/assets/input/CalendarBlank.svg`}
                />
                Дата и время начала
              </label>
              <div>
                <input
                  className={
                    statusOfTask === 0 ? "input-form" : "input-form blockChange"
                  }
                  type="date"
                  id="businessTask__date-start"
                  htmlFor="businessTask__date-start"
                  value={beginDayTask}
                  onChange={(e) => {
                    if (
                      businessProcess.template_id === null &&
                      (statusOfTask === 0 || statusOfTask === 2)
                    ) {
                      // console.log("e.target.value data", e.target.value);
                      if (
                        e.target.value < endDayTask ||
                        e.target.value === endDayTask
                      ) {
                        setDateError(false);
                        setBeginDayTask(e.target.value);
                        changeHandler("beginDay", e.target.value);
                      } else {
                        setDateError(true);
                        setBeginDayTask((prev) => e.target.value);
                      }
                    }
                  }}
                />
                <input
                  className={
                    statusOfTask === 0 ? "input-form" : "input-form blockChange"
                  }
                  type="time"
                  value={beginTimeTask}
                  onChange={(e) => {
                    if (
                      businessProcess.template_id === null &&
                      (statusOfTask === 0 || statusOfTask === 2)
                    ) {
                      // console.log("e.target.value", e.target.value);
                      setBeginTimeTask(e.target.value);
                      changeHandler("beginTime", e.target.value);
                    }
                  }}
                />
              </div>
              {dateError && (
                <div>
                  <span style={{ color: "red", margin: "5px 43px" }}>
                    Ошибка в значении даты! Дата окончания задачи должа быть
                    позднее даты начала.
                  </span>
                </div>
              )}
            </div>
            <div
              className={
                statusOfTask === 0 ? "input__date" : "input__date blockChange"
              }
            >
              <label
                className="p__drop-content"
                htmlFor="businessTask__date-end"
              >
                <img
                  src={`${process.env.REACT_APP_PUBLIC_URL}/assets/input/CalendarBlank.svg`}
                />
                Дата и время окончания
              </label>
              <div>
                <input
                  className={
                    statusOfTask === 0 ? "input-form" : "input-form blockChange"
                  }
                  type="date"
                  id="businessTask__date-end"
                  value={endDayTask}
                  onChange={(e) => {
                    if (
                      businessProcess.template_id === null &&
                      (statusOfTask === 0 || statusOfTask === 2)
                    ) {
                      if (
                        e.target.value > beginDayTask ||
                        e.target.value === beginDayTask
                      ) {
                        setDateError(false);
                        setEndDayTask(e.target.value);
                        changeHandler("endDay", e.target.value);
                      } else {
                        setDateError(true);
                        setEndDayTask(e.target.value);
                      }
                    }
                  }}
                />
                <input
                  className={
                    statusOfTask === 0 ? "input-form" : "input-form blockChange"
                  }
                  type="time"
                  value={endTimeTask}
                  onChange={(e) => {
                    if (
                      businessProcess.template_id === null &&
                      (statusOfTask === 0 || statusOfTask === 2)
                    ) {
                      // console.log("e.target.value", e.target.value);
                      setEndTimeTask(e.target.value);
                      changeHandler("endTime", e.target.value);
                    }
                  }}
                />
              </div>
              {dateError && (
                <div>
                  <span style={{ color: "red", margin: "5px 43px" }}>
                    Ошибка в значении даты! Дата окончания задачи должа быть
                    позднее даты начала.
                  </span>
                </div>
              )}
            </div>
            {!businessProcessCurrentGlobal.template_id && (
              <span className={statusOfTask === 0 ? "" : "blockChange"}>
                {/* <div>
                  <label className="p__drop-content">
                    <img
                      src={`${process.env.REACT_APP_PUBLIC_URL}/assets/input/Vector.svg`}
                    />
                    Прикрепить файл
                  </label>
                  <TaskCreateAddFileButton
                    allFiles={filesTask}
                    setAllFiles={setFilesTask}
                  />
                </div> */}

                <TaskCreateLoad
                  taskLoadValue={taskLoad}
                  setTaskLoadValue={setTaskLoad}
                  workLoadValue={workLoad}
                  setWorkLoadValue={setWorkLoad}
                  typeOfValue={typeOfValue}
                  setTypeOfValue={setTypeOfValue}
                  blockChanges={blockChanges}
                />
              </span>
            )}

            <div className="form-task__dependencies">
              <div className="p__drop-content">
                <img
                  src={`${process.env.REACT_APP_PUBLIC_URL}/assets/input/ArrowUDownRight.svg`}
                />
                Зависимости
              </div>
              <div className="form-task__dependencies__btns">
                {/* <button>Родительская</button>
                  <button>Дочерняя</button> */}

                <div>
                  <button
                    onClick={() => {
                      if (
                        tasksOfBp &&
                        tasksOfBp.length > 0 &&
                        (prevTaskIndex || prevTaskIndex === 0)
                      ) {
                        let prevTask = tasksOfBp[prevTaskIndex];
                        setIdCall(prevTask.id);
                        setOpenMenuTasks(true);
                        setOpenMenuBp(true);
                        setSelectedTaskOfBP(prevTask.name);
                        setTaskId(prevTask.id);
                        setStatusOfTask(prevTask.status);
                        setExecutorIdofTask(prevTask.executor_id);
                        setRedirectedByClick(true);
                      }
                    }}
                  >
                    Предыдущая
                  </button>
                </div>
                <div>
                  <button
                    onClick={() => {
                      if (tasksOfBp && tasksOfBp.length > 0 && nextTaskIndex) {
                        let nextTask = tasksOfBp[nextTaskIndex];
                        setIdCall(nextTask.id);
                        setOpenMenuTasks(true);
                        setOpenMenuBp(true);
                        setSelectedTaskOfBP(nextTask.name);
                        setTaskId(nextTask.id);
                        setStatusOfTask(nextTask.status);
                        setExecutorIdofTask(nextTask.executor_id);
                        setRedirectedByClick(true);
                      }
                    }}
                  >
                    Следующая
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {!taskId && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 35 + "px",
            }}
            className="deleteArrow"
          >
            <div>
              <label className="p__drop-content" htmlFor="input-name-Task">
                <img
                  src={`${process.env.REACT_APP_PUBLIC_URL}/assets/input/Article.svg`}
                />
                {businessProcess.template_id
                  ? "Наименование бизнес-процесса"
                  : "Наименование типового бизнес-процесса"}
              </label>
              <input
                name={"nameOfBPInChatMenu"}
                className="input-form"
                type={"text"}
                value={nameBp}
                onChange={(e) => {
                  if (
                    businessProcess.template_id === null &&
                    businessProcess.status === 0
                  ) {
                    setNameBp((prev) => e.target.value);
                    changeBp("name", e.target.value);
                  }
                }}
                readOnly={
                  businessProcess.template_id === null &&
                  businessProcess.status === 0
                    ? false
                    : true
                }
                style={{ cursor: "default" }}
              />
            </div>
            <div>
              <label className="p__drop-content" htmlFor="input-project-bp">
                <img
                  src={`${process.env.REACT_APP_PUBLIC_URL}/assets/input/Article.svg`}
                  alt="Article"
                />
                Проект*
              </label>
              <select
                id="input-project-bp"
                className={
                  businessProcess.template_id === null &&
                  businessProcess.status === 0
                    ? "input-form"
                    : "input-form blockOfFormAutor"
                }
                value={projectBp}
                style={{
                  pointerEvents: `${
                    businessProcess.status === 0 ? "" : "none"
                  }`,
                  cursor: "pointer",
                }}
                onChange={(e) => {
                  if (e.target.value !== "Выбрать" && selectedBp) {
                    // console.log("99999999", e.target.value);
                    setProjectBp(e.target.value);
                    changeBp("projectIdBp", e.target.value);
                  }
                }}
              >
                <option value={null}>Выбрать</option>
                {projects.map((el) => {
                  return (
                    <option key={el.id} value={el.id}>
                      {el.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div>
              <label
                className="p__drop-content"
                htmlFor="input-project-section-bp"
              >
                <img
                  src={`${process.env.REACT_APP_PUBLIC_URL}/assets/input/Article.svg`}
                  alt="Article"
                />
                Раздел проекта*
              </label>
              <select
                id="input-project-section-bp"
                className={
                  businessProcess.status === 0
                    ? "input-form"
                    : "input-form blockOfFormAutor"
                }
                value={projectSectionBp}
                style={{
                  pointerEvents: `${
                    businessProcess.status === 0 ? "" : "none"
                  }`,
                  cursor: "pointer",
                }}
                onChange={(e) => {
                  if (e.target.value !== "Выбрать" && selectedBp) {
                    setProjectSectionBp(e.target.value);
                    changeBp("projectSectionIdBp", e.target.value);
                  }
                }}
              >
                <option value={null}>Выбрать</option>
                {sectionsBp?.map((el) => {
                  return (
                    <option key={el.id} value={el.id}>
                      {el.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div>
              <label
                className="p__drop-content"
                htmlFor="businessTask__executor"
              >
                <img
                  src={`${process.env.REACT_APP_PUBLIC_URL}/assets/input/User.svg`}
                />
                Инициатор
              </label>
              <input
                className="input-form"
                id="businessTask__executor"
                value={execNameAndSurname}
                style={{ pointerEvents: "none" }}
              ></input>
            </div>

            <div className="input__date">
              <label
                className="p__drop-content"
                htmlFor="businessTask__date-end"
              >
                <img
                  src={`${process.env.REACT_APP_PUBLIC_URL}/assets/input/CalendarBlank.svg`}
                />
                Общий срок
              </label>
              <div>
                <input
                  className="input-form"
                  type="date"
                  id="businessTask__date-end"
                  defaultValue={dateEnd}
                  style={{ pointerEvents: "none" }}
                />
                <input
                  className="input-form"
                  type="time"
                  defaultValue={timeEnd}
                  style={{ pointerEvents: "none" }}
                />
              </div>
            </div>
          </div>
        )}
      </>

      {taskId &&
        !businessProcessCurrentGlobal.template_id &&
        ((taskCurrentGlobal?.status === 2 &&
          (executorIdofTask === userId || isAdmin)) ||
          (taskCurrentGlobal?.status === 3 &&
            (businessProcess.initiator_id === userId || isAdmin))) && (
          <div className="chatMenu-footer chat_menu_nosample_button">
            <div className="chatMenu-footer__nav">
              <div
                className={
                  "chatMenu-footer__nav-item chatMenu-footer__nav-item-active"
                }
                onClick={() => {
                  if (taskCurrentGlobal?.status == 2) {
                    let config = {
                      method: "post",
                      url: `${process.env.REACT_APP_BACKEND}/api/v1/workflow/task/${taskCurrentGlobal.id}/complete`,
                      headers: headers,
                    };

                    axios(config)
                      .then(function (response) {
                        setChange((prev) => prev + 1);
                        // console.log(JSON.stringify(response.data));
                      })
                      .catch((e) => {
                        console.log(e);
                      });
                  }

                  if (taskCurrentGlobal.status == 3) {
                    let config = {
                      method: "post",
                      url: `${process.env.REACT_APP_BACKEND}/api/v1/workflow/task/${taskCurrentGlobal.id}/finish`,
                      headers: headers,
                    };

                    axios(config)
                      .then(function (response) {
                        setChange((prev) => prev + 1);
                        // console.log(JSON.stringify(response.data));
                      })
                      .catch((e) => {
                        console.log(e);
                      });
                  }

                  // console.log("bp", bp);
                  setStatusTaskId(taskId);
                  setStatusBpId(bp.id);
                  setStatusBp(2);
                  setStatusTask((prev) => changeStatus(prev));
                  setOpenMenuTasks(false);
                  setOpenMenuBp(false);
                }}
              >
                {taskCurrentGlobal.status === 2 ? "Выполнено" : "Завершить"}
              </div>
              {taskCurrentGlobal &&
                taskCurrentGlobal.status === 3 &&
                (businessProcess.initiator_id === userId || isAdmin) && (
                  <div
                    className={
                      "chatMenu-footer__nav-item chatMenu-footer__nav-item-active"
                    }
                    style={{ marginLeft: "10px", minWidth: "210px" }}
                    onClick={() => {
                      if (taskCurrentGlobal.status == 3) {
                        let config = {
                          method: "post",
                          url: `${process.env.REACT_APP_BACKEND}/api/v1/workflow/task/${taskCurrentGlobal.id}/rework`,
                          headers: headers,
                        };

                        axios(config)
                          .then(function (response) {
                            setChange((prev) => prev + 1);
                            // console.log(JSON.stringify(response.data));
                          })
                          .catch((e) => {
                            console.log(e);
                          });
                      }

                      // setTaskCurrentGlobtaskCurrentGlobal(...,status:3)

                      // console.log("bp", bp);
                      setStatusTaskId(taskId);
                      setStatusBpId(bp.id);
                      setStatusBp(2);
                      setStatusTask((prev) => changeStatus(prev));
                      setOpenMenuTasks(false);
                      setOpenMenuBp(false);
                    }}
                  >
                    Вернуть в работу
                  </div>
                )}
            </div>
          </div>
        )}
    </>
  );
};

export default TaskInfoMenu;
