// ********************************************************************
// Главное окно для добавления новой задачи или редактирования имеющейся
// (смотреть не в NewTask а тут код описан)
import React, {
  useState,
  useEffect,
  useRef
} from "react";
import { connect } from "react-redux";
import {
  setShownTaskInfoPlate,
  setTaskInfoActiveTab,
  setProjectUsers,
  setComentList,
  setExecutorSchedule,
  fetchProjectSectionsTaskPlate,
  rewriteWholeTaskList,
  selectTask,
  setTaskName,
  setTaskPrivate,
  setDefaultValues,
  sendReqToChangeTemplRepTask,
  setCyclicTaskToChange,
  setFlagRepeatTaskToChange,
  clearCyclicTaskToChange,
  setLabelsData
} from "./actions";
import { Task, User, Comment, Schedule } from "../../common/types";
import { State } from "../../rootReducer";
import "./styles.sass";
import { updateTask } from "../../common/actions";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useContext } from "react";
import { useStateContext } from "../../useStateContext";
import MainAddTask from "./components/Main";

type Props = {
  isShownTaskInfoPlate: boolean;
  selectedTask: Task | null;
  checkList: any[];
  taskInfoActiveTab: string;
  selectedUserId: number;
  taskName: string;
  users: User[];
  commentList: Comment[];
  setShownTaskInfoPlate: (isShownTaskInfoPlate: boolean) => void;
  setTaskInfoActiveTab: (activeTab: string) => void;
  setProjectUsers: (users: User[]) => void;
  setComentList: (commentList: Comment[]) => void;
  updateTask: (id: number, params: any) => void;
  setExecutorSchedule: (schedule: Schedule) => void;
  fetchProjectSectionsTaskPlate: (projectId: number) => void;
  rewriteWholeTaskList: (response: any) => void;
  currentUserId: number | undefined;
  currentUserTopRole: number | undefined;
  selectTask: (val: Task | null) => void;
  setTaskName: (name: string) => void;
  setDefaultValues: () => void;
  flagForActionCyclycTask: string;
  rememberObjectCyclicTaskToChange: {};
  sendReqToChangeTemplRepTask: (obj: {}) => void;
  setCyclicTaskToChange: (val: {}) => void;
  setFlagRepeatTaskToChange: (val: string) => void;
  clearCyclicTaskToChange: () => void;
  labelsData: {
    comments_count: number;
    files_count: number;
  };
  setLabelsData: (comments_count?: number, files_count?: number) => void;
};

const WrapperTaskInfoPlate = styled.div`
  position: fixed;
  top: 76px;
  right: ${({ isShownTaskInfoPlate }) =>
    isShownTaskInfoPlate ? "0" : "-600px"};
  box-shadow: 0 0 10px rgb(0 0 0 / 10%);
  background: #ffffff;
  transition: right 0.8s ease;
  z-index: 100;
  padding: 16px;
  overflow-y: auto;
  overflow-x: hidden;
  flex-direction: column;
  color: #212121 !important;
  display: flex;
  width: 584px;
  height: calc(100vh - 76px);
`;

const TaskInfoPlate: React.FC<Props> = (props) => {
  const {
    setShownTaskInfoPlate,
    selectedUserId,
    setDefaultValues,
    isShownTaskInfoPlate,
    selectedTask,
    users,
  } = props;

  const {
    checkedPersonalTask,
    setCheckedPersonalTask,
    setCheckedTaskBegin,
    setCheckedTaskEnd,
  } = useContext(useStateContext);

  let plate2 = useRef<HTMLDivElement>(null);
  const [isShowAddObject, setIsShowAddObject] = useState<boolean>(false);
  const [showContextDropDown, setShowContextDropDown] = useState(false);
  let history = useHistory();

  useEffect(() => {
    if(!isShownTaskInfoPlate) setDefaultValues();
  }, [isShownTaskInfoPlate]);

  return (
    <WrapperTaskInfoPlate
      ref={plate2}
      isShownTaskInfoPlate={isShownTaskInfoPlate}
      className={`task-info-plate`}
    >
      
      <MainAddTask
        isShowAddObject={isShowAddObject}
        setIsShowAddObject={setIsShowAddObject}
      />
    </WrapperTaskInfoPlate>
  );
};

const mapStateToProps = (state: State) => {
  return {
    currentUserId: state.commonInfo.currentUserInfo?.id,
    checkList: state.taskInfoPlate.checkList,
    selectedUserId: state.tasksPage.selectedUserId,
    currentUserTopRole:
      state.commonInfo.currentUserInfo?.roles &&
      state.commonInfo.currentUserInfo?.roles[0],
    selectedTask: state.taskInfoPlate.selectedTask,
    taskName: state.taskInfoPlate.name,
    users: state.commonInfo.users,
    taskInfoActiveTab: state.taskInfoPlate.taskInfoActiveTab,
    isShownTaskInfoPlate: state.taskInfoPlate.isShownTaskInfoPlate,
    flagForActionCyclycTask: state.taskInfoPlate.flagForActionCyclycTask,
    rememberObjectCyclicTaskToChange:
      state.taskInfoPlate.rememberObjectCyclicTaskToChange,
    commentList: state.taskInfoPlate.commentList,
    labelsData: state.taskInfoPlate.labelsData,
  };
};

const mapDispatchToProps = {
  setTaskPrivate,
  setShownTaskInfoPlate,
  setTaskInfoActiveTab,
  setProjectUsers,
  setComentList,
  updateTask,
  setExecutorSchedule,
  fetchProjectSectionsTaskPlate,
  rewriteWholeTaskList,
  selectTask,
  setTaskName,
  setDefaultValues,
  sendReqToChangeTemplRepTask,
  setCyclicTaskToChange,
  setFlagRepeatTaskToChange,
  clearCyclicTaskToChange,
  setLabelsData,
};

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(TaskInfoPlate);
