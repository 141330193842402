import { Action } from "redux";
import {
  TypicalTasksActivity,
  TypicalTasksPersonal,
  TypicalTasksTime,
} from "../../common/constants";
import { IMeta, User } from "../../common/types";
import { DataTypes, FieldSizes } from "./constants";
import {
  IDeleteTypicalTaskModal,
  INormalFormData,
  ISortConditions,
  ITypicalTaskServerData
} from "./interfaces";

export interface ITypicalState {
  tasks: ITypicalTaskServerData[];
  isLoadingTypicalTasks: boolean;
  isLoadingTypicalTask: boolean;
  isCreatingTypicalTask: boolean;
  isShowMenuPreloader: boolean;
  formDefaultValuesTrigger: string;
  isEditForm: boolean;
  filters: {
    usersDataForFilter: User[];
    usersDataForFilterIsLoading: boolean;
    usersIds: number[];
    activity: TypeFilterActivity;
    personal: TypeFilterPersonal;
  },
  sortCondition: ISortConditions,
  isFormTasksOpen,
  normalForm: INormalFormData,
  extraForm: IExtraFormData,
  isFullFormTasksOpen: boolean;
  deleteTypicalTaskModal: IDeleteTypicalTaskModal,
  searchTypicalTasks: string | null,
  typicalTasksMeta: IMeta | null,
  currentPage: number
}

export interface IExtraFormData {
  activeTabIndex: number,
  fieldAddToNormalForm: number|null,
  checkTrigger: string,
  refreshCheckboxTrigger: string,
  isExtraFormDefaultOpen: boolean,
  inputTab: IExtraFormInputTabData,
  selectTab: IExtraFormSelectTabData,
  radioTab: IExtraFormRadioTabData,
  checkboxTab: IExtraFormCheckboxTabData,
  documentTab: IExtraFormDocumentTabData,
}

export interface IExtraFormTabData {
  title?: string;
  isRequired?: boolean;
  isSendToProject?: boolean;
  isValid?: boolean;
}

export interface IExtraFormInputTabData extends IExtraFormTabData {
  dataType?: TypeDataTypes;
  fieldSize?: TypeFiledSizes;
}

export interface IExtraFormSelectTabData extends IExtraFormTabData {
  arrayValues?: TArrayValues[];
}

export interface IExtraFormRadioTabData extends IExtraFormTabData {
  arrayValues?: TArrayValues[]
}

export interface IExtraFormCheckboxTabData extends IExtraFormTabData {
  arrayValues?: TArrayValues[]
}

export interface IExtraFormDocumentTabData extends IExtraFormTabData {

}

export type TArrayValues = {
  id: string;
  value: string | number;
};

export type TypeFilterActivity =
  | TypicalTasksActivity.ACTIVE_STATUS
  | TypicalTasksActivity.NOT_ACTIVE_STATUS;

export type TypeFilterPersonal =
  | TypicalTasksPersonal.PERSONAL_STATUS
  | TypicalTasksPersonal.NOT_PERSONAL_STATUS;

export type TypeTimeTask =
  | TypicalTasksTime.DAY_FORMAT
  | TypicalTasksTime.HOUR_FORMAT;

export type TypeDataTypes = DataTypes.VALUE_ANY | DataTypes.VALUE_NUMBERS;
export type TypeFiledSizes = FieldSizes.VALUE_STANDARD | FieldSizes.VALUE_BIG;

export enum TypicalTasksActionTypes {
  SET_TYPICAL_TASKS_FILTER_USERS = "@@TYPICAL_TASKS/SET_TYPICAL_TASKS_FILTER_USERS",
  SET_TYPICAL_TASKS_FILTER_ACTIVITY = "@@TYPICAL_TASKS/SET_TYPICAL_TASKS_FILTER_ACTIVITY",
  SET_TYPICAL_TASKS_FILTER_PERSONAL = "@@TYPICAL_TASKS/SET_TYPICAL_TASKS_FILTER_PERSONAL",
  GET_TYPICAL_TASKS_USERS_FOR_FILTER = "@@TYPICAL_TASKS/GET_TYPICAL_TASKS_USERS_FOR_FILTER",
  SET_TYPICAL_TASKS_USERS_FOR_FILTER = "@@TYPICAL_TASKS/SET_TYPICAL_TASKS_USERS_FOR_FILTER",
  SET_TYPICAL_TASKS_USERS_FOR_FILTER_IS_LOADING = "@@TYPICAL_TASKS/SET_TYPICAL_TASKS_USERS_FOR_FILTER_IS_LOADING",
  SET_TYPICAL_TASKS = "@@TYPICAL_TASKS/SET_TYPICAL_TASKS",
  GET_TYPICAL_TASKS = "@@TYPICAL_TASKS/GET_TYPICAL_TASKS",
  SET_LOADING_TYPICAL_TASKS = "@@TYPICAL_TASKS/SET_LOADING_TYPICAL_TASKS",
  SET_LOADING_TYPICAL_TASK = "@@TYPICAL_TASKS/SET_LOADING_TYPICAL_TASK",
  SET_TASK_FORM_OPEN = "@@TYPICAL_TASKS/SET_TASK_FORM_OPEN",
  SET_TASK_FULL_FORM_OPEN = "@@TYPICAL_TASKS/SET_TASK_FULL_FORM_OPEN",
  SET_EXTRA_FORM_DEFAULT_OPEN = "@@TYPICAL_TASKS/SET_EXTRA_FORM_DEFAULT_OPEN",
  SET_EXTRA_FORM_INPUT_TAB_DATA = "@@TYPICAL_TASKS/SET_EXTRA_FORM_INPUT_TAB_DATA",
  DISPATCH_CHECKBOX_ITEM = "@@TYPICAL_TASKS/DISPATCH_CHECKBOX_ITEM",
  SET_EXTRA_FORM_SELECT_TAB_DATA = "@@TYPICAL_TASKS/SET_EXTRA_FORM_SELECT_TAB_DATA",
  SET_EXTRA_FORM_RADIO_TAB_DATA = "@@TYPICAL_TASKS/SET_EXTRA_FORM_RADIO_TAB_DATA",
  SET_EXTRA_FORM_CHECKBOX_TAB_DATA = "@@TYPICAL_TASKS/SET_EXTRA_FORM_CHECKBOX_TAB_DATA",
  SET_EXTRA_FORM_DOCUMENT_TAB_DATA = "@@TYPICAL_TASKS/SET_EXTRA_FORM_DOCUMENT_TAB_DATA",
  SET_EXTRA_FORM_ARRAY_VALUES = "@@TYPICAL_TASKS/SET_EXTRA_FORM_ARRAY_VALUES",
  DELETE_EXTRA_FORM_ARRAY_VALUES = "@@TYPICAL_TASKS/DELETE_EXTRA_FORM_ARRAY_VALUES",
  SET_EXTRA_FORM_CHECK_TRIGGER = "@@TYPICAL_TASKS/SET_EXTRA_FORM_CHECK_TRIGGER",
  SET_NORMAL_FORM_ADD_FILED_TRIGGER = "@@TYPICAL_TASKS/SET_NORMAL_FORM_ADD_FILED_TRIGGER",
  SET_EXTRA_FORM_REFRESH_CHECKBOX_TRIGGER = "@@TYPICAL_TASKS/SET_EXTRA_FORM_REFRESH_CHECKBOX_TRIGGER",
  GET_TYPICAL_TASK = "@@TYPICAL_TASKS/GET_TYPICAL_TASK",
  SET_NORMAL_FORM_DATA = "@@TYPICAL_TASKS/SET_NORMAL_FORM_DATA",
  SET_EXTRA_FORM_ACTIVE_TAB_INDEX = "@@TYPICAL_TASKS/SET_EXTRA_FORM_ACTIVE_TAB_INDEX",
  CREATE_TYPICAL_TASK = "@@TYPICAL_TASKS/CREATE_TYPICAL_TASK",
  UPDATE_TYPICAL_TASK = "@@TYPICAL_TASKS/UPDATE_TYPICAL_TASK",
  UPDATE_TYPICAL_TASK_STORE = "@@TYPICAL_TASKS/UPDATE_TYPICAL_TASK_STORE",
  SET_CREATING_TYPICAL_TASK = "@@TYPICAL_TASKS/SET_CREATING_TYPICAL_TASK",
  SET_IS_EDIT_FORM = "@@TYPICAL_TASKS/SET_IS_EDIT_FORM",
  SET_FORM_DEFAULT_VALUES_TRIGGER = "@@TYPICAL_TASKS/SET_FORM_DEFAULT_VALUES_TRIGGER",
  SET_IS_SHOW_MENU_PRELOADER = "@@TYPICAL_TASKS/SET_IS_SHOW_MENU_PRELOADER",
  SET_DELETE_TYPICAL_TASK_MODAL_DATA = "@@TYPICAL_TASKS/SET_DELETE_TYPICAL_TASK_MODAL_DATA",
  CHECK_DELETE_TYPICAL_TASK = "@@TYPICAL_TASKS/CHECK_DELETE_TYPICAL_TASK",
  DELETE_TYPICAL_TASK_FROM_STATE = "@@TYPICAL_TASKS/DELETE_TYPICAL_TASK_FROM_STATE",
  DELETE_TYPICAL_TASK = "@@TYPICAL_TASKS/DELETE_TYPICAL_TASK",
  SET_SORT_CONDITION = "@@TYPICAL_TASKS/SET_SORT_CONDITION",
  SET_SEARCH_TYPICAL_TASKS = "@@TYPICAL_TASKS/SET_SEARCH_TYPICAL_TASKS",
  SET_TYPICAL_TASKS_META = "@@TYPICAL_TASKS/SET_TYPICAL_TASKS_META",
  SET_CURRENT_PAGE = "@@TYPICAL_TASKS/SET_CURRENT_PAGE",
}

export interface setTypicalTasksAction
  extends Action<TypicalTasksActionTypes.SET_TYPICAL_TASKS> {
  tasks: ITypicalTaskServerData|ITypicalTaskServerData[];
  action?: string
}

export interface getTypicalTasksAction
  extends Action<TypicalTasksActionTypes.GET_TYPICAL_TASKS> {
  params?: object
  action?: string
}

export interface setLoadingTypicalTasksAction
  extends Action<TypicalTasksActionTypes.SET_LOADING_TYPICAL_TASKS> {
  isLoadingTypicalTasks: boolean;
}

export interface setLoadingTypicalTaskAction
  extends Action<TypicalTasksActionTypes.SET_LOADING_TYPICAL_TASK> {
  isLoadingTypicalTask: boolean;
}

export interface setNormalFormDataAction
  extends Action<TypicalTasksActionTypes.SET_NORMAL_FORM_DATA> {
  normalFormData: INormalFormData;
}

export interface setTypicalTasksFilterUsersAction
  extends Action<TypicalTasksActionTypes.SET_TYPICAL_TASKS_FILTER_USERS> {
  users: number[];
}

export interface setTypicalTasksFilterActivityAction
  extends Action<TypicalTasksActionTypes.SET_TYPICAL_TASKS_FILTER_ACTIVITY> {
  activity: TypeFilterActivity;
}

export interface setTypicalTasksFilterPersonalAction
  extends Action<TypicalTasksActionTypes.SET_TYPICAL_TASKS_FILTER_PERSONAL> {
  personal: TypeFilterPersonal;
}

export interface setTypicalTasksUsersForFilterAction
  extends Action<TypicalTasksActionTypes.SET_TYPICAL_TASKS_USERS_FOR_FILTER> {
  usersDataForFilter: User[];
}

export interface setTypicalTasksUsersForFilterIsLoadingAction
  extends Action<TypicalTasksActionTypes.SET_TYPICAL_TASKS_USERS_FOR_FILTER_IS_LOADING> {
  usersDataForFilterIsLoading: boolean;
}

export interface setTaskFormOpenAction
  extends Action<TypicalTasksActionTypes.SET_TASK_FORM_OPEN> {
  isFormTasksOpen: boolean;
}

export interface setExtraFormDefaultOpenAction
  extends Action<TypicalTasksActionTypes.SET_EXTRA_FORM_DEFAULT_OPEN> {
  isExtraFormDefaultOpen: boolean;
}

export interface setExtraFormInputTabDataAction
  extends Action<TypicalTasksActionTypes.SET_EXTRA_FORM_INPUT_TAB_DATA> {
  inputTabData: IExtraFormInputTabData;
}

export interface setTaskFullFormOpenAction
  extends Action<TypicalTasksActionTypes.SET_TASK_FULL_FORM_OPEN> {
  isFullFormTasksOpen: boolean;
}

export interface setChangeCheckboxItemAction
  extends Action<TypicalTasksActionTypes.DISPATCH_CHECKBOX_ITEM> {
  orderNumber: number | null;
  text: string;
  checkboxState: boolean;
  actionType: string;
}
export interface setExtraFormSelectTabDataAction
  extends Action<TypicalTasksActionTypes.SET_EXTRA_FORM_SELECT_TAB_DATA> {
  selectTabData: IExtraFormSelectTabData;
}

export interface setExtraFormRadioTabDataAction extends Action<TypicalTasksActionTypes.SET_EXTRA_FORM_RADIO_TAB_DATA> {
  radioTabData: IExtraFormRadioTabData;
}

export interface setExtraFormCheckboxTabDataAction extends Action<TypicalTasksActionTypes.SET_EXTRA_FORM_CHECKBOX_TAB_DATA> {
  checkboxTabData: IExtraFormCheckboxTabData;
}

export interface setExtraFormDocumentTabDataAction extends Action<TypicalTasksActionTypes.SET_EXTRA_FORM_DOCUMENT_TAB_DATA> {
  documentTabData: IExtraFormDocumentTabData;
}

export interface setExtraFormArrayValuesAction extends Action<TypicalTasksActionTypes.SET_EXTRA_FORM_ARRAY_VALUES> {
  selectedTabName: string;
  value: string | number;
}

export interface deleteExtraFormArrayValuesAction
  extends Action<TypicalTasksActionTypes.DELETE_EXTRA_FORM_ARRAY_VALUES> {
  selectedTabName: string;
  id: string | number;
}

export interface setExtraFormCheckTriggerAction
  extends Action<TypicalTasksActionTypes.SET_EXTRA_FORM_CHECK_TRIGGER> {
  checkTrigger: string;
}

export interface getTypicalTaskAction
  extends Action<TypicalTasksActionTypes.GET_TYPICAL_TASK> {
  id: number|string;
}

export interface setNormalFormAddFiledTriggerAction
  extends Action<TypicalTasksActionTypes.SET_NORMAL_FORM_ADD_FILED_TRIGGER> {
  addFieldTrigger: string;
}

export interface setExtraFormRefreshCheckboxTriggerAction
  extends Action<TypicalTasksActionTypes.SET_EXTRA_FORM_REFRESH_CHECKBOX_TRIGGER> {
  refreshCheckboxTrigger: string;
}

export interface setExtraFormActiveTabIndexAction
  extends Action<TypicalTasksActionTypes.SET_EXTRA_FORM_ACTIVE_TAB_INDEX> {
  activeTabIndex: number;
}

export interface createTypicalTaskAction
  extends Action<TypicalTasksActionTypes.CREATE_TYPICAL_TASK> {
  data: object;
}

export interface updateTypicalTaskAction
  extends Action<TypicalTasksActionTypes.UPDATE_TYPICAL_TASK> {
  data: object;
}

export interface updateTypicalTaskStoreAction
  extends Action<TypicalTasksActionTypes.UPDATE_TYPICAL_TASK_STORE> {
  task: ITypicalTaskServerData;
}

export interface setCreatingTypicalTaskAction
  extends Action<TypicalTasksActionTypes.SET_CREATING_TYPICAL_TASK> {
  isCreatingTypicalTask: boolean;
}

export interface setFormDefaultValuesTriggerAction
  extends Action<TypicalTasksActionTypes.SET_FORM_DEFAULT_VALUES_TRIGGER> {
  formDefaultValuesTrigger: string;
}

export interface setIsEditFormAction
  extends Action<TypicalTasksActionTypes.SET_IS_EDIT_FORM> {
  isEditForm: boolean;
}

export interface setIsShowMenuPreloaderAction
  extends Action<TypicalTasksActionTypes.SET_IS_SHOW_MENU_PRELOADER> {
  isShowMenuPreloader: boolean;
}

export interface checkDeleteTypicalTaskAction extends Action<TypicalTasksActionTypes.CHECK_DELETE_TYPICAL_TASK> {
  id: string|number;
}

export interface setDeleteTypicalTaskDataAction extends Action<TypicalTasksActionTypes.SET_DELETE_TYPICAL_TASK_MODAL_DATA> {
  data: IDeleteTypicalTaskModal
}

export interface deleteTypicalTaskFromStateAction extends Action<TypicalTasksActionTypes.DELETE_TYPICAL_TASK_FROM_STATE> {
  id: string|number;
}

export interface deleteTypicalTaskAction extends Action<TypicalTasksActionTypes.DELETE_TYPICAL_TASK> {
  id: string|number;
}

export interface setSortConditionAction extends Action<TypicalTasksActionTypes.SET_SORT_CONDITION> {
  sortCondition: ISortConditions;
}

export interface setSearchTypicalTasksAction extends Action<TypicalTasksActionTypes.SET_SEARCH_TYPICAL_TASKS> {
  searchTypicalTasks: string|null;
}

export interface setTypicalTasksMetaAction extends Action<TypicalTasksActionTypes.SET_TYPICAL_TASKS_META> {
  typicalTasksMeta: IMeta;
}

export interface setCurrentPageAction extends Action<TypicalTasksActionTypes.SET_CURRENT_PAGE> {
  currentPage: number;
}

export type TypicalTasksAction =
  | setTypicalTasksFilterUsersAction
  | setTypicalTasksFilterActivityAction
  | setTypicalTasksFilterPersonalAction
  | setTypicalTasksUsersForFilterAction
  | setTypicalTasksUsersForFilterIsLoadingAction
  | setTypicalTasksAction
  | getTypicalTasksAction
  | setLoadingTypicalTasksAction
  | setLoadingTypicalTaskAction
  | setTaskFormOpenAction
  | setExtraFormDefaultOpenAction
  | setTaskFullFormOpenAction
  | setChangeCheckboxItemAction
  | setExtraFormInputTabDataAction
  | setExtraFormSelectTabDataAction
  | setExtraFormArrayValuesAction
  | deleteExtraFormArrayValuesAction
  | setExtraFormCheckTriggerAction
  | setExtraFormRadioTabDataAction
  | setExtraFormCheckboxTabDataAction
  | setExtraFormDocumentTabDataAction
  | getTypicalTaskAction
  | setNormalFormDataAction
  | setNormalFormAddFiledTriggerAction
  | setExtraFormRefreshCheckboxTriggerAction
  | setExtraFormActiveTabIndexAction
  | setCreatingTypicalTaskAction
  | setFormDefaultValuesTriggerAction
  | setIsEditFormAction
  | setIsShowMenuPreloaderAction
  | checkDeleteTypicalTaskAction
  | setDeleteTypicalTaskDataAction
  | deleteTypicalTaskFromStateAction
  | deleteTypicalTaskAction
  | setSortConditionAction
  | updateTypicalTaskAction
  | createTypicalTaskAction
  | updateTypicalTaskStoreAction
  | setSearchTypicalTasksAction
  | setTypicalTasksMetaAction
  | setCurrentPageAction;
