export enum DataTypes {
  TEXT_ANY = 'Любой',
  TEXT_NUMBERS = 'Только цифры',
  VALUE_ANY = 0,
  VALUE_NUMBERS = 1
}

export enum FieldSizes {
  TEXT_STANDARD = 'Стандартный',
  TEXT_BIG = 'Большой',
  VALUE_STANDARD = 0,
  VALUE_BIG = 1
}

export enum TabIdsExtraForm {
  INPUT_TAB,
  SELECT_TAB,
  RADIO_TAB,
  CHECKBOX_TAB,
  DOCUMENT_TAB
}

// Порядок важен!
export enum SelectedTabNameExtraForm {
  INPUT_TAB = 'inputTab',  
  SELECT_TAB = 'selectTab',
  RADIO_TAB = 'radioTab',
  CHECKBOX_TAB = 'checkboxTab',
  DOCUMENT_TAB = 'documentTab'
}

export enum deleteTypicalTaskStatuses {
  DELETE_TASK = 0,
  DELETE_TASK_ALLOWED = 'allowed',
  DELETE_TASK_ERROR = 'error'
}

export enum deleteFieldsStatuses {
  DELETE_FIELD = 0,
  DELETE_FIELD_ALLOWED = 'allowed',
  DELETE_FIELD_ERROR = 'error'
}

export enum messagesTypicalTask {
  DELETE_TASK_ERROR = 'Произошла ошибка при удалении типовой задачи...',
  DELETE_TASK_SUCCESS = 'Типовая задача удалена',
  CREATE_TASK_SUCCESS = 'Типовая задача успешно создана',
  UPDATE_TASK_SUCCESS = 'Типовая задача успешно обновлена'
}

export enum sortConditions {
  ID = 'id',
  NAME = 'name',
  BUSINESS = 'bp',
  AUTHOR = 'author',
  EXECUTOR = 'executor',
  CREATED_AT = 'createdAt',
  ACTIVITY = 'activity'
}

export enum sortOrders {
  ASC,
  DESC
}