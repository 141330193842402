import React, { FC } from "react";
import { Checkbox, FormControlLabel } from "@material-ui/core";

import './CheckboxMui.scss';

const CheckboxMui: FC<{
  title: string,
  checked: boolean,
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}> = ({
  title,
  checked,
  handleChange
}) => {
  return (
    <div className='checkbox-mui'>
      <FormControlLabel
        className='checkbox-mui__label'
        control={
          <Checkbox
            className='checkbox-mui__chk'
            color='default'
            checked={checked}
            onChange={handleChange}
          />
        }
        label={title}
      />
    </div>
  )  
};

export default CheckboxMui;
