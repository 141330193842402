import styled from "styled-components";
import React, { useState } from "react";
import Select from "react-select";
import { connect } from "react-redux";
import { fetchUsers, setCurrentPage, setUsersFilter } from "../actions";
import { State } from "../../../rootReducer";

interface Props {
  setUsersFilter: (filter: string)=> void;
  setCurrentPage: (pageNum: number) => void;
  currentPage: number;
  fetchUsers: () => void;
}

const FilterShow = styled.div`
  max-width: 400px;
  margin-left: 40px;
  display: flex;
  gap: 40px;
`;

const FilterTitile = styled.div`
  display: flex;
  align-items: center;
`;

const StyledSelect = styled(Select)`
  /* Обертка React Select */
  .react-select-container {
    position: relative;
    width: 100% !important;
  }

  /* Основной блок Select */
  .react-select__control {
    display: flex;
    align-items: center;
    height: 42px;
    border-radius: 4px;
    box-shadow: none;
    transition: all 0.2s;
    font-size: 16px;
    font-family: "Roboto", sans-serif;
  }

  /* Активный Select */
  .react-select__control:hover {
    box-shadow: none;
    background-color: #e8e8e8;
  }

  /* Скрытие подчеркивания при фокусе на Select */
  .react-select__control--is-focused {
    box-shadow: none;
  }

  /* Скрытие стрелки Select */
  .react-select__indicator-separator {
    display: none;
  }

  /* Иконка стрелки */
  .react-select__indicator {
    color: #bdbdbd;
    margin-right: 12px;
    transition: transform 0.2s;
  }

  /* Иконка стрелки при наведении */
  .react-select__indicator:hover {
    color: #757575;
    transform: rotate(180deg);
  }

  /* Разделитель между опциями */
  .react-select__menu-list {
  }

  /* Опции Select */
  .react-select__option {
    font-size: 16px;
    font-family: "Roboto", sans-serif;
  }

  /* Активная опция */
  .react-select__option--is-focused {
    background-color: #e8e8e8;
  }

  /* Выбранная опция */
  .react-select__option--is-selected {
    background-color: #d6d6d6;
    color: #212121;
  }
`;

const UsersFilter:React.FC<Props> = ({setUsersFilter, fetchUsers, setCurrentPage, currentPage}) => {
  let filters = [
    { value: "all", label: "Все" },
    { value: "active", label: "Активные" },
    { value: "inactive", label: "Неактивные" },
  ];
  const [filter, setFilter] = useState(filters[0]);

  const onChangeFilter = (e) => {
    setFilter(e.value);
    setUsersFilter(e.value);
    setCurrentPage(1)
    fetchUsers()
  }
  
  return (
    <div>
      <FilterShow>
        <FilterTitile>Показать </FilterTitile>
        <div style={{ width: "100%" }}>
          <StyledSelect
            placeholder="Председатель"
            defaultValue={filter}
            options={filters}
            className="react-select"
            classNamePrefix="react-select"
            onChange={(e) => {
              onChangeFilter((e))
            }}
          />
        </div>
      </FilterShow>
    </div>
  );
};
const mapStateToProps = (state: State) => {
  return {
    currentPage: state.usersPage.currentPage
  };
};
const mapDispatchToProps = {
 setUsersFilter,
 setCurrentPage,
 fetchUsers
};

export default connect(mapStateToProps,mapDispatchToProps)(UsersFilter);
