import React, { FC, useEffect, useState } from "react";
import { connect } from "react-redux";
import { State } from "../../../../rootReducer";
import { checkInputs } from "../../../../utils/helpers/helpers";

import './TextAreaSimple.scss';

const TextAreaSimple: FC<{
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void,
  value: string,
  checkTrigger?: string
  name: string,
  placeholder?: string,
  checkTriggerExternal?: string,
  rules?: any
}> = ({
  onChange,
  value,
  checkTrigger,
  name,
  placeholder = 'Введите значение...',
  checkTriggerExternal,
  rules
}) => {
  const [skipCheck, setSkipCheck] = useState<boolean>(true);
  const [errorState, setErrorState] = useState<string[]>([]);
  
  const checkHelper = () => {
    if(skipCheck) {
      setSkipCheck(false);
      return;
    }

    setErrorState([]);

    const checkResult = checkInputs(rules, value);
    
    if(Array.isArray(checkResult)) {
      setErrorState(checkResult);
    }
  };
  
  const onChangeLocal = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange(e);
  }

  useEffect(() => {
    checkHelper();
  }, [value, checkTriggerExternal]);
  
  return ( 
    <div className={'textarea-objects' +
                    (errorState.length ? ' textarea-objects--error' : '')
    }>
      <textarea className="textarea-objects__input" 
             placeholder={placeholder}
             value={value}
             onChange={onChangeLocal}
      ></textarea>

      {errorState.length > 0 && (
        <div className="input-objects__error-messages">
          {errorState.map((error, i) => (
            <div className="input-objects__error-message" key={i}>
              {error}
            </div>
          ))}
        </div>
      )}
    </div>
  )  
}
const mapStateToProps = (state: State) => {
  return {
    
  }
}

const mapDispatchToProps = {
  
}

export default connect(mapStateToProps, mapDispatchToProps)(TextAreaSimple);
