import React, { FC, useEffect } from "react";
import { connect } from "react-redux";
import { State } from "../../rootReducer";
import Tasks from "./components/TasksList/Tasks";
import { getTasks, setCurrentPage } from "./actions";
import FormEditTask from "../ObjectsPage/components/FormEditTask/FormEditTask";
import FormAddTask from "../ObjectsPage/components/FormAddTask/FormAddTask";
import { getAllProjects } from "../Projects/actions";

const TasksPage: FC<{
  getTasks: (params?: object) => void;
  setCurrentPage: (currentPage: number) => void;
  getAllProjects: () => void;
}> = ({ getTasks, setCurrentPage , getAllProjects}) => {

  useEffect(() => {
    setCurrentPage(1);
    getTasks();
    getAllProjects()
  }, []);

  return (
    <div
      className="tasks-page"
      style={{
        position: "relative",
        width: "100%",
        height: "100%",
        overflow: "hidden",
      }}
    >
      <FormAddTask isFormFromTaskPage={true}/>
      <FormEditTask />
      <Tasks />
    </div>
  );
};

const mapStateToProps = (state: State) => ({});

const mapDispatchToProps = { getTasks, setCurrentPage, getAllProjects };

export default connect(mapStateToProps, mapDispatchToProps)(TasksPage);
