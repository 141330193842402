import React, { useContext, useMemo, useRef } from "react";
import { SelectContext } from "./context";
import { CreateNotif } from "../../../utils/createNotification"; // Добавился сервис для предупреждений
import { fetchData } from "../../../utils/fetchData";
import {useState } from "react";

import {
  Section,
  Project,
} from "../../../common/types";

const SelectBtn = ({
  searchString,
  setSearchString,
  placeholder = "Выберите",
  projectsSelect,
}) => {
  const context = useContext(SelectContext);
  let plate = useRef<HTMLDivElement>(null);

  function typing(event) {
    setSearchString(event.target.value);
  }
  useMemo(() => {
    setSearchString("");
    if (context.selectedItem && context.selectedItem?.text !== "") {
      plate.current?.closest(".input_div")?.classList.add("text_exist");
    } else {
      plate.current?.closest(".input_div")?.classList.remove("text_exist");
    }
  }, [context.selectedItem]);

  const [projectsAll, setProjectsAll] = useState< Project[]>();
  const [sectionsAll, setSectionsAll] = useState< Section[]>();

  return (
    <div
      className={`secondary${context.isOpen ? " is_open" : ""}${
        context.disabled ? " disabled" : ""
      }`}
      ref={plate}
    >
      <div className={"current_item_text"} style={{ display: "block" }}>
        {context.selectedItem?.text}
      </div>
      <div className={"input_search_box"} style={{ display: "none" }}>
        <input
          type={"text"}
          // Выводим предупреждение при фокусировке
          onFocus={() => {
            projectsSelect === 0
              ? CreateNotif(
                  'Создайте хотя бы один проект в разделе "Проект"',
                  "warning"
                )
              : console.log("");
          }}
          onChange={(event) => typing(event)}
          value={searchString}
          placeholder={placeholder}
        />
      </div>
    </div>
  );
};

export default SelectBtn;
