import React, { FC, useEffect, useState } from "react";
import TitleTasks from "../TableHeaderTasks/TableHeaderTasks";
import { State } from "../../../../rootReducer";
import { connect } from "react-redux";
import PreloaderLocal from "../../../../common/components/PreloaderLocal/PreloaderLocal";
import { IMeta } from "../../../../common/types";
import { getTasks, setCurrentPage, setSelectedUserId } from "../../actions";

import "./Tasks.scss";
import Task from "./Task/Task";
import FiltersTasks from "../FilterTasks/FiltersTasks";
import { useParams } from "react-router-dom";

const Tasks: FC<{
  isLoadingTasks: boolean;
  tasks: any[];
  setSelectedUserId: (id: number) => void;
  currentId: number;
  tasksMeta: IMeta | null;
  getTasks: (params?: object, action?: string) => void;
  setCurrentPage: (currentPage: number) => void;
  currentPage: number;
}> = ({
  isLoadingTasks,
  tasks,
  setSelectedUserId,
  currentId,
  tasksMeta,
  getTasks,
  setCurrentPage,
  currentPage,
}) => {
  const { id } = useParams<{ id: string }>();

  const scrollHandler = (e) => {
    if(!tasksMeta) return;
    const { scrollHeight, scrollTop, clientHeight } = e.currentTarget;

    if (
      scrollHeight <= scrollTop + clientHeight + 2 &&
      currentPage < (tasksMeta?.last_page ?? 1) && !isLoadingTasks
    ) {
      setCurrentPage(currentPage + 1);
      getTasks({}, "add");
    }    
  };

  useEffect(() => {
    if (id) setSelectedUserId(parseInt(id));
    else setSelectedUserId(currentId);
  }, [currentId, id, setSelectedUserId]);

  return (
    <div className="main-task">
      <h1 className="main-task__title">Задачи</h1>

      <div className="main-task__filters">
        <FiltersTasks />
      </div>

      <div className="main-task__content">
        <TitleTasks />

        <div className="main-task__table">
          <div
            className={`main-task__preloader-wr 
                          ${
                            isLoadingTasks
                              ? " main-task__preloader-wr--show"
                              : ""
                          }`}
          >
            <div className="main-task__preloader">
              <PreloaderLocal />
            </div>
          </div>

          <div
            className="main-task__table-items"
            onScroll={(e: React.MouseEvent<HTMLDivElement>) => scrollHandler(e)}
          >
            {tasks?.map((item) => (
              <Task key={item.id} task={item} />
            ))}

            {!tasks.length && (
              <div className="main-task__default">Задачи не найдены...</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: State) => ({
  isLoadingTasks: state.tasksObjects.isLoading,
  tasks: state.tasksObjects.tasks,
  currentPage: state.tasksObjects.currentPage,
  currentId: state.commonInfo.current_user,
  tasksMeta: state.tasksObjects.tasksMeta,
});

const mapDispatchToProps = {
  setSelectedUserId,
  getTasks,
  setCurrentPage,
};

export default connect(mapStateToProps, mapDispatchToProps)(Tasks);
