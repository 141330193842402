import React, { useCallback, useMemo } from "react";
import styled from "styled-components";
import DeadLineCell from "../../../../../../../common/components/TableCells/DeadLineCell";
import ExecutorCell from "../../../../../../../common/components/TableCells/ExecutorCell";
import PriorityCell from "../../../../../../../common/components/TableCells/PriorityCell";
import TaskNameCell from "../../../../../../../common/components/TableCells/TaskNameCell";
import TaskWorkLoadingCell from "../../../../../../../common/components/TableCells/TaskWorkLoadingCell";
import { Vector3 } from "../../../../../../../img/Task";
import DateMaster from "../../../../../../../utils/DateMaster";
import ProjectCell from "../../../../../../../common/components/TableCells/MeetingProjectSection";
import {
  selectTask,
  setShownTaskInfoPlate,
} from "../../../../../../TaskInfoPlate/actions";
import { getSelectedTask } from "../../../../../../TaskInfoPlate/selectors";
import { connect } from "react-redux";
import { State } from "../../../../../../../rootReducer";
import { Task, User } from "../../../../../../../common/types";
import { urlApp } from "../../../../../../../App";


type Props = {
  item: any;
  // task: Task;
  selectedTask: Task | null;
  selectTask: (task: Task) => void;
  setShownTaskInfoPlate: (show: boolean) => void;
  isParent?: boolean;
  counter?: number;
  isOpen?: boolean;
  setOpen?: (open: boolean) => void;
  partitions: object;
  activeColumns: string[];
  idOfTaskToShow: number;
  setIdOfTaskToShow: (value: number) => void;
  setShowSidePlate: (value: boolean) => void;
};

const TextId = styled.p`
  display: block;
  color: #292a34;
  // margin-right: 10px;
  font-size: 14px;
  margin-top: 6px;
  width: 45px;
  color: #a8a8a8;
  text-align: center;
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  margin-left: 40px;
`;
const TaskStyle = styled.div`
  display: flex;
  margin: 0 12px;
  margin-bottom: 7px;
  /* margin-right: 40px; */
  justify-content: space-between;
  min-height: 84px;
  padding: 15px 0 15px 20px;
  // box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  /* border-bottom: 1px solid; */
  border-image: linear-gradient(to right, #ffffff, #e0e0e0, #e0e0e0, #ffffff)
    45% 0%;
  background: #ffffff;
  position: relative;
  cursor: pointer;
  transition: background-color 200ms ease;

  &:hover {
    box-shadow: 0px 4px 5px rgb(0 0 0 / 20%);
  }
  ${({ active }) =>
    active &&
    `
  background: linear-gradient(to right, white 0%,#F3FAFE 5%, #F3FAFE 90%, white 100%)!important;
  border-image: linear-gradient(to right,#ffffff,#99D7F5,#99D7F5,#ffffff ) 0% 5% 75% 7%;

  `}
`;
const BlockLeftFlex = styled.div`
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: center;
`;
const BlockRightFlex = styled.div`
  position: relative;
  display: flex;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const MeetingTaskSection: React.FC<any> = ({
  item,
  tasksState,
  activeColumns,
  projects,
  partitions,
  // task,
  users,
  selectedTask,
  selectTask,
  setShownTaskInfoPlate,
  isParent = false,
  counter = 0,
  isOpen = false,
  setOpen = () => {},
  idOfTaskToShow,
  setIdOfTaskToShow,
  setShowSidePlate,
}) => {
  const dateMaster = useMemo(() => {
    return new DateMaster(new Date());
  }, []);

  const executor = users.find((user) => user.id === item.executor_id);
  const author = users.find((user) => user.id === item.author_id);

  // const trClickHandler = useCallback(
  //   (e: React.MouseEvent) => {
  //     if (!(e.target as HTMLElement).closest(".task_status_wrapper"))
  //       setShownTaskInfoPlate(true);
  //     if (selectedTask?.id !== task.id) selectTask(task);
  //   },
  //   [setShownTaskInfoPlate, selectedTask, task, selectTask]
  // );

    const trClickHandler = useCallback(
    (e: React.MouseEvent) => {
      if (!(e.target as HTMLElement).closest(".task_status_wrapper"))
        setShownTaskInfoPlate(true);
      if (selectedTask?.id !== item.id) selectTask(item);
    },
    [setShownTaskInfoPlate, selectedTask, item, selectTask]
  );

  let project_of_task;
  let section_of_task;
  Object.keys(projects).forEach((id) => {
    if (projects[id].id === item.project_id) {
      project_of_task = projects[id].name;
      projects[id].sections.forEach((section) => {
        if (section.id === item.project_section_id) {
          section_of_task = section.name;
        }
      });
    }
  });
// console.log(item);

  return (
    <TaskStyle  
    active={item.id === selectedTask?.id}
    onClick={trClickHandler}
    >
      <BlockLeftFlex>
        <TextId>{item.id}</TextId>

        <div style={{ display: "flex", alignItems: "center " }}>
          <Vector3 />
        </div>
        <span
          style={{
            margin: "0px 12px 0px 5px",
            display: "flex",
            alignItems: "center ",
            width: "13px",
            color: "#94959A",
          }}
        >
           {item.comments_count}
        </span>
        <TaskNameCell
          id={item.id}
          name={item.name}
          desc={item.description}
          width={true}
        />
      </BlockLeftFlex>
      <BlockRightFlex>
        <div
          style={{ display: "flex", alignItems: "center", marginRight: "15px" }}
        >
          <ExecutorCell
            minWidth={"87px"}
            url={author?.image
              ? urlApp + `/files/${author.image.url}/download`
              : null}
            executorName={author ? `${author.surname} ${author.name}` : "Неизвестен"}
          />

          <ExecutorCell
            minWidth={"87px"}
            url={executor?.image
              ? urlApp + `/files/${executor.image.url}/download`
              : null}
            executorName={executor ? `${executor.surname} ${executor.name}` : "Неизвестен"}
          />
        </div>
        <ProjectCell
          data_project={project_of_task}
          data_section={section_of_task}
        />
        <DeadLineCell
            end={item.end}
            deadline={dateMaster.deadlineMini}
            hot={item.hot}
            style={{ alignItems: "flex-start" }}
        />

        <TaskWorkLoadingCell
          taskLoad={item.task_load}
          workLoad={item.work_load}
          style={{
            justifyContent: "flex-start",
            width: "95px",
            paddingLeft: "15px",
          }}
        />
        <PriorityCell
          style={{ backgroundColor: "transparent", width: "100px" }}
          priorityId={item.priority_id}
        />

        <div
          id={"12"}
          style={{
            marginRight: "12px",
            marginTop: "19px",
            // marginLeft: "20px",
            position: "relative",
            zIndex: 4,
          }}
        ></div>
      </BlockRightFlex>
    </TaskStyle>
  );
};

const mapStateToProps = (state: State) => {
  return {
    users: state.commonInfo.users,
    projects: state.projectsPage.projectsAll,
    partitions: state.commonInfo.sections,
    activeColumns: state.projectsPage.activeColumns,
    selectedTask: getSelectedTask(state),
  };
};

const mapDispatchToProps = {
  selectTask,
  setShownTaskInfoPlate,
};

export default connect(mapStateToProps, mapDispatchToProps)(MeetingTaskSection);
