import { call, debounce, put, select, takeEvery } from "redux-saga/effects";
import { fetchData } from "../../utils/fetchData";
import { deleteBusinessProcessStatuses, messagesBusinessProcess, sortOrders } from "./constants";
import {
  deleteBpFromState, setBPFormOpen, setBPFullFormOpen,
  setBusinessProcess,
  setChangeActiveStateModalBp,
  setDeleteModalBp, setIsCreatingBp,
  setLoadingBPCard,
  setLoadingBusinessProcess,
  setLoadingTypicalTasksBp,
  setNormalFormBp, setNormalFormDefaultValuesBp,
  setTypicalTasksBp,
  updateBPStore
} from "./actions";
import { BusinessPageActionTypes, checkDeleteBpAction, deleteBpAction } from "./action_types";
import { CreateNotif } from "../../utils/createNotification";
import { State } from "../../rootReducer";
import { BusinessProcessActivity, BusinessProcessPersonal, ITaskBP } from "./interfaces";
import { ITypicalTaskServerData } from "../TypicalTasks/interfaces";

export function* watchCheckDeleteBp() {
    yield takeEvery(BusinessPageActionTypes.CHECK_DELETE_BUSINESS_PROCESS, checkDeleteBp);
  }

  export function* watchDeleteBp() {
    yield takeEvery(BusinessPageActionTypes.DELETE_BUSINESS_PROCESS, deleteBusinessProcess);
  }

  export function* watchGetBp() {
    yield takeEvery(BusinessPageActionTypes.GET_BUSINESS_PROCESS, getBPs);
  }

  export function* watchSearchBP() {
    yield debounce(500, BusinessPageActionTypes.SET_SEARCH_BUSINESS_PROCESS, getBPs);
  }

  export function* watchCreateBP() {
    yield takeEvery(BusinessPageActionTypes.CREATE_BP, createBP);
  }

  export function* watchGetTypicalTasksForBp() {
    yield takeEvery(BusinessPageActionTypes.GET_TYPICAL_TASKS_BP, getTypicalTasksBp);
  }

  export function* watchSearchTypicalTasksBP() {
    yield takeEvery(BusinessPageActionTypes.SET_SEARCH_TYPICAL_TASKS_BP, getTypicalTasksBp);
  }

  export function* watchSortTypicalTasksInBP() {
    yield takeEvery(BusinessPageActionTypes.SET_BP_FORM_FILTER, getTypicalTasksBp);
  }

  export function* watchSortTypicalTasksInTreaty() {
    yield takeEvery(BusinessPageActionTypes.SET_TREATY_FORM_FILTER_TASKS, getTypicalTasksBp);
  }

  export function* watchGetBusinessProcess() {
    yield takeEvery(BusinessPageActionTypes.GET_ONE_BP, getBusinessProcess);
  }

  export function* watchUpdateBusinessProcess() {
    yield takeEvery(BusinessPageActionTypes.UPDATE_BP, updateBP);
  }

  export function* watchCheckChangeStateBp() {
    yield takeEvery(BusinessPageActionTypes.CHECK_ACTIVE_STATE_BP, checkStatusBp);
  }
  
function* checkDeleteBp({ id }: checkDeleteBpAction) {
    yield put(
        setDeleteModalBp({
        bpId: id,
        isDeleteModalOpen: true,
        isShowPreloader: true,
      })
    );
  
    let response: any = null;
  
    //   response = yield call(
    //     fetchData.get,
    //     `/api/v1/bp/${id}/check-delete`
    //   );
  
    //   if (response?.constraints.length === 0) {
      response = { constraints: [deleteBusinessProcessStatuses.DELETE_BUSINESS_PROCESS_ALLOWED] };
    //   }
  
    //   if (!response) {
    // response = { constraints: [deleteBusinessProcessStatuses.DELETE_BUSINESS_PROCESS_ERROR] };
    //   }
  
    yield put(
      setDeleteModalBp({
        bpId: id,
        isDeleteModalOpen: true,
        statusFromServer: response,
        isShowPreloader: false,
      })
    );
  }

  function* deleteBusinessProcess({ id }: deleteBpAction) {
    const errorStatuses = [403, 404, 500];
  
    yield put(
        setDeleteModalBp({
        bpId: id,
        isDeleteModalOpen: true,
        isShowPreloader: true,
      })
    );
  
    const response = yield call(
      fetchData.delete,
      `/api/v1/typical-bp/${id}`
    );
  
      yield put(
        setDeleteModalBp({
          bpId: id,
          isDeleteModalOpen: false,
          isShowPreloader: false,
        })
      );
  
    // if(response?.status === 204) {
    yield put(deleteBpFromState(id));
    CreateNotif(messagesBusinessProcess.DELETE_BUSINESS_PROCESS_SUCCESS, "success");
    // }
  
    if(errorStatuses.includes(response?.status)) {
      CreateNotif(messagesBusinessProcess.DELETE_BUSINESS_PROCESS_ERROR);
    }
  }

  function* getBPs({params, action}: any) {
    const currentCompanyId = localStorage.getItem('company_id');
    const currentUser = yield select((state: State) => state.commonInfo.currentUserInfo);
  
    if(!currentCompanyId) return;
    
    const filters = yield select((state: State) => state.businessPage.filters);
    const search = yield select((state: State) => state.businessPage.searchBP);
    const sortConditions = yield select((state: State) => state.businessPage.sortCondition);
    const currentPage = yield select((state: State) => state.businessPage.currentPage);
    
    let orderType = '';
    let url = '?';
    
    if(sortConditions) {
      let columnId = sortConditions.columnId;
      
      orderType = sortConditions.value === sortOrders.DESC ? '' : '-';
      url += 'sort=' + orderType + columnId;
    }
  
    if(filters) {
      if(filters.activity === BusinessProcessActivity.ACTIVE_STATUS) {
        url += '&filter[status]=1';
      }
      
      if(filters.activity === BusinessProcessActivity.NOT_ACTIVE_STATUS) {
        url += '&filter[status]=0';
      }
      
      if(filters.personal === BusinessProcessPersonal.PERSONAL_STATUS) {
        url += `&filter[author_id][]=${currentUser.id}`;
      }
    }
    if(currentPage) {
      url += `&page=${currentPage}`;
    }

    if(search?.length) {
      url += `&filter[search]=${search}`;
    }
  
    yield put(setLoadingBusinessProcess(true));
  
    const response: any[] = yield call(
      fetchData.get,
      `/api/v1/${currentCompanyId}/typical-bp${url}`
    );
  
    if(response) {      
      yield put(setBusinessProcess(response, action));
    }
  
    yield put(setLoadingBusinessProcess(false));
  }

  function* createBP({data}: any) {
    yield put(setIsCreatingBp(true));

    const currentCompanyId = localStorage.getItem('company_id');
    const convertedData = yield convertFrontToServerDataHelper(data);
  
    const response: any = yield call(
      fetchData.post,
      `/api/v1/${currentCompanyId}/typical-bp`,
      JSON.stringify(convertedData)
    );
  
    if(response) {
      yield put(setBusinessProcess(response, "add"));
      yield put(setBPFullFormOpen(false));
      yield put(setBPFormOpen(false));
      yield put(setNormalFormDefaultValuesBp());
      
      CreateNotif(messagesBusinessProcess.CREATE_BUSINESS_PROCESS_SUCCESS, 'success');
    }

    yield put(setIsCreatingBp(false));
  }

  function* getTypicalTasksBp({params, action}: any) {
    const currentCompanyId = localStorage.getItem('company_id');
  
    if(!currentCompanyId) return;
    
    const search = yield select((state: State) => state.businessPage.extraFormBP.searchTypicalTasksBp);
    const currentPage = yield select((state: State) => state.businessPage.extraFormBP.currentPageTypicalTasks);
    const bpTasks = yield select((state: State) => state.businessPage.extraFormBP.bpForFilter);
    const bpTreaty= yield select((state: State) => state.businessPage.extraFormBP.treatyForFilterTasks);

    
    let url = '?sort=name&filter[status]=1';

    if(currentPage) {
      url += `&page=${currentPage}`;
    }
  
    if(search?.length) {
      url += `&filter[search]=${search}`;
    }

    if(bpTasks){
      if(bpTasks.value === "notActive"){
      url += `&filter[in_bps]=0`
     }else if(typeof bpTasks.value === 'number'){
      url += `&filter[bp_id]=${bpTasks.value}`
    }
    }

    if(bpTreaty){
      if(bpTreaty.value === "notActive"){
        url += `&filter[in_agreements]=0`
       }else if(typeof bpTreaty.value === 'number'){
        url += `&filter[stage_id]=${bpTreaty.value}`
      }
    }
  
    yield put(setLoadingTypicalTasksBp(true));
  
    const response: ITypicalTaskServerData[] = yield call(
      fetchData.get,
      `/api/v1/${currentCompanyId}/typical-tasks${url}`
    );
  
    if(response) {
      yield put(setTypicalTasksBp(response, action));
    }
  
    yield put(setLoadingTypicalTasksBp(false));
  }

  function* getBusinessProcess({id}: any) {
    yield put(setLoadingBPCard(true));
    
    let response: any = yield call(
      fetchData.get,
      `/api/v1/typical-bp/${id}`
    );

    if(response) {
      const convertedData = convertServerToFrontDataHelper(response);
      yield put(setNormalFormBp(convertedData));
    }
    
    yield put(setLoadingBPCard(false));
  }

  function* updateBP({data}: any) {
    yield put(setIsCreatingBp(true));

    const convertedData = yield convertFrontToServerDataHelper(data);
    
    const response: any = yield call(
      fetchData.put,
      `/api/v1/typical-bp/${data.idBPEditing}`,
      JSON.stringify(convertedData)
    );

    if(response) {
      yield put(updateBPStore(response));
  
      CreateNotif(messagesBusinessProcess.UPDATE_BUSINESS_PROCESS_SUCCESS, 'success');
    }

    yield put(setIsCreatingBp(false));
  }

  function* checkStatusBp({ id, value }: any) {
    // проверка на возможность изменения состояния
  
    let response: any = null;

  //запрос на возможность изменения состояния, если состояние нельзя изменить то вылетает модалка, иначе изменяется

    //   response = yield call(
    //     fetchData.get,
    //     `/api/v1/bp/${id}/check-`
    //   );
  
    //   if (response?.constraints.length === 0) {
      // yield put(setNormalFormBp({activity: value}));
    //   }
  
    //   if (!response) {
    // response = { constraints: [activeStateBusinessProcessStatuses.ACTIVE_BUSINESS_PROCESS_ERROR] };
    yield put(
      setChangeActiveStateModalBp({
        bpId: id,
        isActiveModalOpen: true,
        statusFromServer: response,
        isShowPreloader: false,
      })
      );
    //   }
  }

function* convertFrontToServerDataHelper(data: any){
  return {
    name: data.name,
    status: (data.activity === BusinessProcessActivity.ACTIVE_STATUS),
    typical_tasks: convertFrontTasksToServerHelper(data.tasksItems)
  };
}

function convertServerToFrontDataHelper(data: any): any {
  return {
    idBPEditing: data.id,
    name: data.name,
    author: data.author?.surname + ' ' + data.author?.name,
    authorId: data.author?.id,
    createdAt: data?.created_at,
    activity: data.status ? BusinessProcessActivity.ACTIVE_STATUS : BusinessProcessActivity.NOT_ACTIVE_STATUS,
    tasksItems: convertServerTasksToFrontHelper(data.typical_tasks)
  }
}
  
function convertServerTasksToFrontHelper(tasks): ITaskBP[] {
  let tasksLocal: ITaskBP[] = [];
  
  if(tasks?.length) {
    tasksLocal = tasks.map(task => ({
      id: task.task_id,
      title: task.title,
      taskType: task.task_type,
      children: convertServerTasksToFrontHelper(task.children) ?? [],
      time: task.time
    }));
  }
  
  return tasksLocal;
}
  
function convertFrontTasksToServerHelper(tasks): ITaskBP[] {
  let tasksLocal: any = [];
  
  if(tasks?.length) {
    tasksLocal = tasks.map(task => ({
      task_id: task.id,
      type: task.taskType,
      children: convertFrontTasksToServerHelper(task.children) ?? []
    }));
  }
  
  return tasksLocal;
}
