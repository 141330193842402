import {
  setTypicalTasksUsersForFilterAction,
  setTypicalTasksFilterActivityAction,
  setTypicalTasksFilterPersonalAction,
  setTypicalTasksFilterUsersAction,
  TypeFilterActivity,
  TypeFilterPersonal,
  TypicalTasksActionTypes,
  setTypicalTasksUsersForFilterIsLoadingAction,
  setTypicalTasksAction,
  getTypicalTasksAction,
  setLoadingTypicalTasksAction,
  setTaskFormOpenAction,
  setExtraFormDefaultOpenAction,
  setExtraFormInputTabDataAction,
  IExtraFormInputTabData,
  setTaskFullFormOpenAction,
  setChangeCheckboxItemAction,
  IExtraFormSelectTabData,
  setExtraFormSelectTabDataAction,
  setExtraFormArrayValuesAction,
  deleteExtraFormArrayValuesAction,
  setExtraFormCheckTriggerAction,
  IExtraFormRadioTabData,
  setExtraFormRadioTabDataAction,
  IExtraFormCheckboxTabData,
  setExtraFormCheckboxTabDataAction,
  IExtraFormDocumentTabData,
  setExtraFormDocumentTabDataAction,
  getTypicalTaskAction,
  setLoadingTypicalTaskAction,
  setNormalFormDataAction,
  setNormalFormAddFiledTriggerAction,
  setExtraFormRefreshCheckboxTriggerAction,
  setExtraFormActiveTabIndexAction,
  createTypicalTaskAction,
  setCreatingTypicalTaskAction,
  setFormDefaultValuesTriggerAction,
  setIsEditFormAction,
  setIsShowMenuPreloaderAction,
  checkDeleteTypicalTaskAction,
  setDeleteTypicalTaskDataAction,
  deleteTypicalTaskFromStateAction,
  deleteTypicalTaskAction,
  setSortConditionAction,
  updateTypicalTaskAction,
  updateTypicalTaskStoreAction, 
  setSearchTypicalTasksAction, 
  setTypicalTasksMetaAction, 
  setCurrentPageAction
}
  from "./action_types";
import { IMeta, User } from "../../common/types";
import {
  IDeleteTypicalTaskModal,
  INormalFormData,
  ISortConditions,
  ITypicalTaskServerData
} from "./interfaces";

export const getTypicalTasks = (params?: {}, action?: string): getTypicalTasksAction => ({
  type: TypicalTasksActionTypes.GET_TYPICAL_TASKS,
  params,
  action
});

export const setTypicalTasks = (tasks: ITypicalTaskServerData|ITypicalTaskServerData[], action?: string): setTypicalTasksAction => ({
  type: TypicalTasksActionTypes.SET_TYPICAL_TASKS,
  tasks,
  action
});

export const setLoadingTypicalTasks = (isLoadingTypicalTasks: boolean): setLoadingTypicalTasksAction => ({
  type: TypicalTasksActionTypes.SET_LOADING_TYPICAL_TASKS,
  isLoadingTypicalTasks
});

export const setLoadingTypicalTask = (isLoadingTypicalTask: boolean): setLoadingTypicalTaskAction => ({
  type: TypicalTasksActionTypes.SET_LOADING_TYPICAL_TASK,
  isLoadingTypicalTask
});

export const setTypicalTasksFilterUsers = (users: number[]): setTypicalTasksFilterUsersAction => ({
  type: TypicalTasksActionTypes.SET_TYPICAL_TASKS_FILTER_USERS,
  users
});

export const setTypicalTasksFilterActivity = (activity: TypeFilterActivity): setTypicalTasksFilterActivityAction => ({
  type: TypicalTasksActionTypes.SET_TYPICAL_TASKS_FILTER_ACTIVITY,
  activity
});

export const setTypicalTasksFilterPersonal = (personal: TypeFilterPersonal): setTypicalTasksFilterPersonalAction => ({
  type: TypicalTasksActionTypes.SET_TYPICAL_TASKS_FILTER_PERSONAL,
  personal
});

export const setTypicalTasksUsersForFilter = (usersDataForFilter: User[]): setTypicalTasksUsersForFilterAction => ({
  type: TypicalTasksActionTypes.SET_TYPICAL_TASKS_USERS_FOR_FILTER,
  usersDataForFilter
});

export const setTypicalTasksUsersForFilterIsLoading = (usersDataForFilterIsLoading: boolean): setTypicalTasksUsersForFilterIsLoadingAction => ({
  type: TypicalTasksActionTypes.SET_TYPICAL_TASKS_USERS_FOR_FILTER_IS_LOADING,
  usersDataForFilterIsLoading
});

export const setTaskFormOpen = (isFormTasksOpen: boolean): setTaskFormOpenAction => ({
  type: TypicalTasksActionTypes.SET_TASK_FORM_OPEN,
  isFormTasksOpen
});

export const setExtraFormDefaultOpen = (isExtraFormDefaultOpen: boolean): setExtraFormDefaultOpenAction => ({
  type: TypicalTasksActionTypes.SET_EXTRA_FORM_DEFAULT_OPEN,
  isExtraFormDefaultOpen
});

export const setExtraFormInputTabData = (inputTabData: IExtraFormInputTabData): setExtraFormInputTabDataAction => ({
  type: TypicalTasksActionTypes.SET_EXTRA_FORM_INPUT_TAB_DATA,
  inputTabData
});

export const setTaskFullFormOpen = (isFullFormTasksOpen: boolean): setTaskFullFormOpenAction => ({
  type: TypicalTasksActionTypes.SET_TASK_FULL_FORM_OPEN,
  isFullFormTasksOpen
});

export const dispatchCheckboxItem = (
  orderNumber: number | null,
  text: string,
  checkboxState: boolean,
  actionType: string
): setChangeCheckboxItemAction => ({
  type: TypicalTasksActionTypes.DISPATCH_CHECKBOX_ITEM,
  orderNumber,
  text,
  checkboxState,
  actionType,
});

export const setExtraFormSelectTabData = (selectTabData: IExtraFormSelectTabData): setExtraFormSelectTabDataAction => ({
  type: TypicalTasksActionTypes.SET_EXTRA_FORM_SELECT_TAB_DATA,
  selectTabData
});

export const setExtraFormRadioTabData = (radioTabData: IExtraFormRadioTabData): setExtraFormRadioTabDataAction => ({
  type: TypicalTasksActionTypes.SET_EXTRA_FORM_RADIO_TAB_DATA,
  radioTabData
});

export const setExtraFormCheckboxTabData = (checkboxTabData: IExtraFormCheckboxTabData): setExtraFormCheckboxTabDataAction => ({
  type: TypicalTasksActionTypes.SET_EXTRA_FORM_CHECKBOX_TAB_DATA,
  checkboxTabData
});

export const setExtraFormDocumentTabData = (documentTabData: IExtraFormDocumentTabData): setExtraFormDocumentTabDataAction => ({
  type: TypicalTasksActionTypes.SET_EXTRA_FORM_DOCUMENT_TAB_DATA,
  documentTabData
});

export const setExtraFormArrayValues = (selectedTabName: string, value: string|number): setExtraFormArrayValuesAction => ({
  type: TypicalTasksActionTypes.SET_EXTRA_FORM_ARRAY_VALUES,
  selectedTabName,
  value
});

export const deleteExtraFormArrayValues = (selectedTabName: string, id: string|number): deleteExtraFormArrayValuesAction => ({
  type: TypicalTasksActionTypes.DELETE_EXTRA_FORM_ARRAY_VALUES,
  selectedTabName,
  id
});

export const setExtraFormCheckTrigger = (checkTrigger: string): setExtraFormCheckTriggerAction => ({
  type: TypicalTasksActionTypes.SET_EXTRA_FORM_CHECK_TRIGGER,
  checkTrigger
});

export const getTypicalTask = (id: number|string): getTypicalTaskAction => ({
  type: TypicalTasksActionTypes.GET_TYPICAL_TASK,
  id
});

export const setNormalFormData = (normalFormData: INormalFormData): setNormalFormDataAction => ({
  type: TypicalTasksActionTypes.SET_NORMAL_FORM_DATA,
  normalFormData
});

export const setNormalFormAddFiledTrigger = (addFieldTrigger: string): setNormalFormAddFiledTriggerAction => ({
  type: TypicalTasksActionTypes.SET_NORMAL_FORM_ADD_FILED_TRIGGER,
  addFieldTrigger
});

export const setExtraFormRefreshCheckboxTrigger = (refreshCheckboxTrigger: string): setExtraFormRefreshCheckboxTriggerAction => ({
  type: TypicalTasksActionTypes.SET_EXTRA_FORM_REFRESH_CHECKBOX_TRIGGER,
  refreshCheckboxTrigger
});

export const setExtraFormActiveTabIndex = (activeTabIndex: number): setExtraFormActiveTabIndexAction => ({
  type: TypicalTasksActionTypes.SET_EXTRA_FORM_ACTIVE_TAB_INDEX,
  activeTabIndex
});

export const createTypicalTask = (data: object): createTypicalTaskAction => ({
  type: TypicalTasksActionTypes.CREATE_TYPICAL_TASK,
  data
});

export const updateTypicalTask = (data: object): updateTypicalTaskAction => ({
  type: TypicalTasksActionTypes.UPDATE_TYPICAL_TASK,
  data
});

export const updateTypicalTaskStore = (task: ITypicalTaskServerData): updateTypicalTaskStoreAction => ({
  type: TypicalTasksActionTypes.UPDATE_TYPICAL_TASK_STORE,
  task
});

export const setIsCreatingTypicalTask = (isCreatingTypicalTask: boolean): setCreatingTypicalTaskAction => ({
  type: TypicalTasksActionTypes.SET_CREATING_TYPICAL_TASK,
  isCreatingTypicalTask
});

export const setFormDefaultValuesTrigger = (formDefaultValuesTrigger: string): setFormDefaultValuesTriggerAction => ({
  type: TypicalTasksActionTypes.SET_FORM_DEFAULT_VALUES_TRIGGER,
  formDefaultValuesTrigger
});

export const setIsEditForm = (isEditForm: boolean): setIsEditFormAction => ({
  type: TypicalTasksActionTypes.SET_IS_EDIT_FORM,
  isEditForm
});

export const setIsShowMenuPreloader = (isShowMenuPreloader: boolean): setIsShowMenuPreloaderAction => ({
  type: TypicalTasksActionTypes.SET_IS_SHOW_MENU_PRELOADER,
  isShowMenuPreloader
});

export const checkDeleteTypicalTask = (id: string|number): checkDeleteTypicalTaskAction => ({
  type: TypicalTasksActionTypes.CHECK_DELETE_TYPICAL_TASK,
  id
});

export const setDeleteTypicalTaskModalData = (data: IDeleteTypicalTaskModal): setDeleteTypicalTaskDataAction => ({
  type: TypicalTasksActionTypes.SET_DELETE_TYPICAL_TASK_MODAL_DATA,
  data
});

export const deleteTypicalTaskFromState = (id: string|number): deleteTypicalTaskFromStateAction => ({
  type: TypicalTasksActionTypes.DELETE_TYPICAL_TASK_FROM_STATE,
  id
});

export const deleteTypicalTask = (id: string|number): deleteTypicalTaskAction => ({
  type: TypicalTasksActionTypes.DELETE_TYPICAL_TASK,
  id
});

export const setSortCondition = (sortCondition: ISortConditions): setSortConditionAction => ({
  type: TypicalTasksActionTypes.SET_SORT_CONDITION,
  sortCondition
});

export const setSearchTypicalTasks = (searchTypicalTasks: string|null): setSearchTypicalTasksAction => ({
  type: TypicalTasksActionTypes.SET_SEARCH_TYPICAL_TASKS,
  searchTypicalTasks
});

export const setTypicalTasksMeta = (typicalTasksMeta: IMeta): setTypicalTasksMetaAction => ({
  type: TypicalTasksActionTypes.SET_TYPICAL_TASKS_META,
  typicalTasksMeta
});

export const setCurrentPage = (currentPage: number): setCurrentPageAction => ({
  type: TypicalTasksActionTypes.SET_CURRENT_PAGE,
  currentPage
});
