import React, { FC, useEffect, useState } from "react";
import { AppBar, Box, Tab, Tabs, Typography } from "@material-ui/core";
import { connect } from "react-redux";
import { State } from "../../../../../rootReducer";
import Fade from "@material-ui/core/Fade";
import TooltipQuestion from "../../../../../common/components/ui/TooltipQuestion/TooltipQuestion";
import { TypicalTasksTooltips } from "../../../../../common/constants";
import CheckboxMui from "../../../../../common/components/ui/CheckboxMui/CheckboxMui";
import {
  setExtraFormActiveTabIndex,
  setExtraFormCheckboxTabData,
  setExtraFormCheckTrigger,
  setExtraFormDefaultOpen,
  setExtraFormDocumentTabData,
  setExtraFormInputTabData,
  setExtraFormRadioTabData,
  setExtraFormSelectTabData,
  setNormalFormAddFiledTrigger
} from "../../../actions";
import InputTabTypicalTask from "../components/InputTabTypicalTask/InputTabTypicalTask";

import './ExtraForm.scss';
import {
  IExtraFormCheckboxTabData, 
  IExtraFormDocumentTabData,
  IExtraFormInputTabData,
  IExtraFormRadioTabData,
  IExtraFormSelectTabData
} from "../../../action_types";
import SelectTabTypicalTask from "../components/SelectTabTypicalTask/SelectTabTypicalTask";
import { nanoid } from "nanoid";
import { TabIdsExtraForm } from "../../../constants";
import RadioTabTypicalTask from "../components/RadioTabTypicalTask/RadioTabTypicalTask";
import CheckboxTabTypicalTask from "../components/CheckboxTabTypicalTask/CheckboxTabTypicalTask";
import DocumentTabTypicalTask from "../components/DocumentTabTypicalTask/DocumentTabTypicalTask";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Fade in={value === index}>
      <div
        hidden={value !== index}
        id={`extra-form-tabpanel-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            <Typography component='div'>{children}</Typography>
          </Box>
        )}
      </div>
    </Fade>
  );
}

const ExtraForm: FC<{
  isExtraFormDefaultOpen: boolean,
  setExtraFormActiveTabIndex: (activeTabIndex: number) => void,
  activeTabIndex: number,
  setExtraFormDefaultOpen: (isFormOpen: boolean) => void,
  setExtraFormInputTabData: (inputTabData: IExtraFormInputTabData) => void,
  setExtraFormSelectTabData: (selectTabData: IExtraFormSelectTabData) => void,
  setExtraFormRadioTabData: (radioTabData: IExtraFormRadioTabData) => void,
  setExtraFormCheckboxTabData: (checkboxTabData: IExtraFormCheckboxTabData) => void,
  setExtraFormDocumentTabData: (documentTabData: IExtraFormDocumentTabData) => void,
  setExtraFormCheckTrigger: (checkTrigger: string) => void,
  setNormalFormAddFiledTrigger: (addFiledTrigger: string) => void,
  inputTab: IExtraFormInputTabData,
  selectTab: IExtraFormSelectTabData,
  radioTab: IExtraFormRadioTabData,
  checkboxTab: IExtraFormCheckboxTabData,
  documentTab: IExtraFormDocumentTabData,
  refreshCheckboxTrigger: string,
}> = ({
  setExtraFormActiveTabIndex,
  activeTabIndex,
  isExtraFormDefaultOpen,
  setExtraFormDefaultOpen,
  setExtraFormInputTabData,
  setExtraFormSelectTabData,
  setExtraFormRadioTabData,
  setExtraFormCheckboxTabData,
  setExtraFormDocumentTabData,
  setExtraFormCheckTrigger,
  setNormalFormAddFiledTrigger,
  inputTab,
  selectTab,
  radioTab,
  checkboxTab,
  documentTab,
  refreshCheckboxTrigger
}) => {
  const [checkboxRequired, setCheckboxRequired] = useState<boolean>(false);
  const [checkboxToProject, setCheckboxToProject] = useState<boolean>(false);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setExtraFormActiveTabIndex(newValue);
  };

  const onChangeCheckboxRequired = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCheckboxRequired(event.target.checked);
    setCheckboxDataHelper("required");
  };

  const onChangeCheckboxToProject = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCheckboxToProject(event.target.checked);
    setCheckboxDataHelper("toProject");
  };

  const onCloseFormHandler = () => {
    setExtraFormDefaultOpen(true);
  };

  const setCheckboxDataHelper = (action: "required" | "toProject") => {
    let data: Pick<IExtraFormInputTabData, "isRequired" | "isSendToProject"> =
      {};

    if(action === 'required') {
      data = {isRequired: !checkboxRequired};
    }
    if(action === 'toProject') {
      data = {isSendToProject: !checkboxToProject};
    }

    if(activeTabIndex === TabIdsExtraForm.INPUT_TAB) {
      setExtraFormInputTabData(data);
    }
    if(activeTabIndex === TabIdsExtraForm.SELECT_TAB) {
      setExtraFormSelectTabData(data);
    }
    if(activeTabIndex === TabIdsExtraForm.RADIO_TAB) {
      setExtraFormRadioTabData(data);
    }
    if(activeTabIndex === TabIdsExtraForm.CHECKBOX_TAB) {
      setExtraFormCheckboxTabData(data);
    }
    if(activeTabIndex === TabIdsExtraForm.DOCUMENT_TAB) {
      setExtraFormDocumentTabData(data);
    }
  };

  const getCheckboxDataHelper = () => {
    let data: Pick<IExtraFormInputTabData, 'isRequired'|'isSendToProject'> = {
      isRequired: false, 
      isSendToProject: false
    };
    
    if(activeTabIndex === TabIdsExtraForm.INPUT_TAB) {
      data = {
        isRequired: inputTab.isRequired,
        isSendToProject: inputTab.isSendToProject
      };
    }
    if(activeTabIndex === TabIdsExtraForm.SELECT_TAB) {
      data = {
        isRequired: selectTab.isRequired,
        isSendToProject: selectTab.isSendToProject
      };
    }
    if(activeTabIndex === TabIdsExtraForm.RADIO_TAB) {
      data = {
        isRequired: radioTab.isRequired,
        isSendToProject: radioTab.isSendToProject
      };
    }
    if(activeTabIndex === TabIdsExtraForm.CHECKBOX_TAB) {
      data = {
        isRequired: checkboxTab.isRequired,
        isSendToProject: checkboxTab.isSendToProject
      };
    }
    if(activeTabIndex === TabIdsExtraForm.DOCUMENT_TAB) {
      data = {
        isRequired: documentTab.isRequired,
        isSendToProject: documentTab.isSendToProject
      };
    }

    setCheckboxRequired(data.isRequired as boolean);
    setCheckboxToProject(data.isSendToProject as boolean);
  }
  
  const onSubmitHandler = () => {
    setExtraFormCheckTrigger(nanoid(6));
    setTimeout(() => setNormalFormAddFiledTrigger(nanoid(6))); // В конец макрозадач
  }
  
  useEffect(() => {
    getCheckboxDataHelper();
  }, [activeTabIndex, refreshCheckboxTrigger]);

  return (
    <div className="extra-form">
      <div className="extra-form__content">
        <h2 className="extra-form__title-wr">
          <div className="extra-form__title">
            Добавляем поле в форму исполнения задачи
          </div>
          <div className="extra-form__tooltip">
            <TooltipQuestion title={TypicalTasksTooltips.ADD_FIELD} />
          </div>
        </h2>

        <div className="extra-form__tabs">
          <AppBar className="extra-form__tabs-header" position="static">
            <Tabs value={activeTabIndex} onChange={handleChange}>
              <Tab label="Input" />
              <Tab label="Select" />
              <Tab label="Radio Button" />
              <Tab label="Checkbox" />
              <Tab label="Document" />
            </Tabs>
          </AppBar>
          
          <TabPanel value={activeTabIndex} index={TabIdsExtraForm.INPUT_TAB}>
            <InputTabTypicalTask />
          </TabPanel>
          <TabPanel value={activeTabIndex} index={TabIdsExtraForm.SELECT_TAB}>
            <SelectTabTypicalTask />
          </TabPanel>
          <TabPanel value={activeTabIndex} index={TabIdsExtraForm.RADIO_TAB}>
            <RadioTabTypicalTask />
          </TabPanel>
          <TabPanel value={activeTabIndex} index={TabIdsExtraForm.CHECKBOX_TAB}>
            <CheckboxTabTypicalTask />
          </TabPanel>
          <TabPanel value={activeTabIndex} index={TabIdsExtraForm.DOCUMENT_TAB}>
            <DocumentTabTypicalTask />
          </TabPanel>
        </div>

        <div className="extra-form__parameters">
          <div className="extra-form__parameters-title">Параметры</div>

          <div className="extra-form__parameters-items">
            <div className="extra-form__parameters-item">
              <CheckboxMui
                title="Обязательно для заполнения"
                checked={checkboxRequired}
                handleChange={onChangeCheckboxRequired}
              />
            </div>

            {/* Временно скрыто */}
            {/*<div className="extra-form__parameters-item">
              <CheckboxMui
                title="Передавать в объект"
                checked={checkboxToProject}
                handleChange={onChangeCheckboxToProject}
              />
            </div>*/}
          </div>
        </div>

        <div className="extra-form__btns">
          <button className="extra-form__btn extra-form__btn--submit"
                  onClick={onSubmitHandler}
          >
            <span className="extra-form__btn-title">Готово</span>
          </button>

          <button
            className="extra-form__btn extra-form__btn--cancel"
            onClick={onCloseFormHandler}
          >
            <span className="extra-form__btn-title">Отмена</span>
          </button>
        </div>
      </div>

      <div
        className={
          "extra-form__default" +
          (isExtraFormDefaultOpen ? " extra-form__default--open" : "")
        }
      >
        <h2 className="extra-form__default-title">
          Настройте состав, параметры и порядок следования полей в форме
          исполнения задачи
        </h2>

        <ul className="extra-form__default-text">
          <li className="extra-form__default-text-item">
            Для добавления дополнительного поля в форму исполнения задачи
            кликните по ссылке "Добавить поле" в левой части страницы.
          </li>
          <li className="extra-form__default-text-item">
            Кликните мышью на любое дополнительное поле чтобы отредактировать
            его параметры.
          </li>
          <li className="extra-form__default-text-item">
            Схватите мышью любое дополнительное поле и переместите его в любое
            место в списке.
          </li>
        </ul>
      </div>
    </div>
  );
};

const mapStateToProps = (state: State) => {
  return {
    activeTabIndex: state.typicalTasksPage.extraForm.activeTabIndex,
    isExtraFormDefaultOpen: state.typicalTasksPage.extraForm.isExtraFormDefaultOpen,
    refreshCheckboxTrigger: state.typicalTasksPage.extraForm.refreshCheckboxTrigger,
    inputTab: state.typicalTasksPage.extraForm.inputTab,
    selectTab: state.typicalTasksPage.extraForm.selectTab,
    radioTab: state.typicalTasksPage.extraForm.radioTab,
    checkboxTab: state.typicalTasksPage.extraForm.checkboxTab,
    documentTab: state.typicalTasksPage.extraForm.documentTab
  }
}

const mapDispatchToProps = {
  setExtraFormActiveTabIndex,
  setExtraFormDefaultOpen,
  setExtraFormInputTabData,
  setExtraFormSelectTabData,
  setExtraFormCheckTrigger,
  setExtraFormRadioTabData,
  setExtraFormCheckboxTabData,
  setExtraFormDocumentTabData,
  setNormalFormAddFiledTrigger
}

export default connect(mapStateToProps, mapDispatchToProps)(ExtraForm);
