import React, { FC, useEffect } from "react";
import { connect } from "react-redux";
import { State } from "../../../../../../../rootReducer";
import { IActiveBusinessProcessModal, INormalFormBPData } from "../../../../../interfaces";
import {ReactComponent as CalendarIcon} from '../../../../../../../img/TrueIcons/calendar.svg';
import {ReactComponent as UserTaskIco} from '../../../../../../../img/TrueIcons/user-task.svg';
import {ReactComponent as BriefcaseIco} from '../../../../../../../img/TrueIcons/briefcase.svg';
import moment from "moment";
import { BusinessProcessActivity } from "../../../../../interfaces";
import { TypeBPFilterActivity } from "../../../../../action_types";
import { checkActiveStateBp, setNormalFormBp } from "../../../../../actions"
import SelectTypicalTask from "../../../../../../TypicalTasks/components/FormTasks/ui/SelectTypicalTask/SelectTypicalTask";

import './OtherBP.scss';

const OtherBP: FC<{
  setNormalFormBp: (normalFormData: INormalFormBPData) => void,
  author?: string,
  createdAt?: string,
  activity?: TypeBPFilterActivity,
  id: number | string;
  checkActiveStateBp: (id: number | string, value: any) => void
}> = ({
  setNormalFormBp,
  author,
  createdAt,
  activity,
  id,
  checkActiveStateBp
}) => {
  const formatDate = (date) => moment(date).format('DD.MM.YYYY');
  const dataActivityValues = [
    {text: BusinessProcessActivity.ACTIVE_TEXT_FORM as string, value: BusinessProcessActivity.ACTIVE_STATUS},
    {text: BusinessProcessActivity.NOT_ACTIVE_TEXT_FORM as string, value: BusinessProcessActivity.NOT_ACTIVE_STATUS}
  ];

  const onChangeSelect = (action?: string, value?: TypeBPFilterActivity) => {
    //если идет деактивация бп то идет проверка на использование в договоре, пока нет на бэке
    // if(value === BusinessProcessActivity.NOT_ACTIVE_STATUS){
    //     checkActiveStateBp(id, value)
    // }else{
        setNormalFormBp({activity: value})
    // }  
  };
  
  return (
    <div className="other-bp">
      <div className="other-bp__title">
        Прочее
      </div>
      
      <div className="other-bp__items">
        {author && (
          <div className="other-bp__item">
            <div className="other-bp__item-left">
              <div className="other-bp__item-title-wr">
                <div className="other-bp__item-title-ico">
                  <UserTaskIco />
                </div>
                <div className="other-bp__item-title">Автор</div>
              </div>
            </div>

            <div className="other-bp__item-right">
              <div className="other-bp__item-text">{author}</div>
            </div>
          </div>
        )}

        {createdAt && (
          <div className="other-bp__item">
            <div className="other-bp__item-left">
              <div className="other-bp__item-title-wr">
                <div className="other-bp__item-title-ico">
                  <CalendarIcon />
                </div>
                <div className="other-bp__item-title">Дата создания</div>
              </div>
            </div>

            <div className="other-bp__item-right">
              <div className="other-bp__item-text">{formatDate(createdAt)}</div>
            </div>
          </div>
        )}

        <div className="other-bp__item">
          <div className="other-bp__item-left">
            <div className="other-bp__item-title-wr">
              <div className="other-bp__item-title-ico">
                <BriefcaseIco />
              </div>
              <div className="other-bp__item-title">Состояние</div>
            </div>
          </div>

          <div className="other-bp__item-right">
            <div className="other-bp__item-select">
              <SelectTypicalTask
                itemsList={dataActivityValues}
                onChangeSelect={(value) => onChangeSelect('', value)}
                selectedItem={activity}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: State) => {
  return {
    author: state.businessPage.normalFormBP.author,
    activity: state.businessPage.normalFormBP.activity,
    createdAt: state.businessPage.normalFormBP.createdAt,
  }
};

const mapDispatchToProps = {
  setNormalFormBp,
  checkActiveStateBp
};

export default connect(mapStateToProps, mapDispatchToProps)(OtherBP);
