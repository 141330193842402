import React, { FC, useEffect, useState } from "react";
import { Checkbox } from "@material-ui/core";
import {ReactComponent as CloseIcon} from '../../../../../../../img/TrueIcons/close.svg';
import {ReactComponent as BurgerIcon} from '../../../../../../../img/TrueIcons/burger.svg';
import { SelectedTabNameExtraForm } from "../../../../../constants";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { CustomTooltip } from "../../../../../../../common/components/ui/Tooltip/CustomTooltip";

import './AdditionalFieldsItem.scss';
import ModalDeleteFields from "../../../../ui/ModalDeleteFields/ModalDeleteFields";

const AdditionalFieldsItem: FC<{
  fieldType: string,
  fieldData: any,
  deleteHandler: (id: string, fieldType: string) => void,
  onOpenExtraFormTab: (tabId: string) => void,
  changeHandler: (id: string, fieldType: string, action: string, value: boolean) => void,
  isDisabled: boolean,
}> = ({
  fieldType,
  fieldData,
  onOpenExtraFormTab,
  deleteHandler,
  changeHandler,
  isDisabled
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({
    id: fieldData.id,
  });
  const [openDeleteFieldModal, setOpenDeleteFieldModal] =useState(false)
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const [type, setType] = useState();

  const setTypeHelper = (type: string) => {
    const typesArr = {
      [SelectedTabNameExtraForm.INPUT_TAB]: 'Input',
      [SelectedTabNameExtraForm.SELECT_TAB]: 'Select',
      [SelectedTabNameExtraForm.RADIO_TAB]: 'Radio-Button',
      [SelectedTabNameExtraForm.CHECKBOX_TAB]: 'Checkbox',
      [SelectedTabNameExtraForm.DOCUMENT_TAB]: 'Document'
    };

    setType(typesArr[type]);
  };
  
  useEffect(() => {
    if(fieldType.length) {
      setTypeHelper(fieldType);
    }
  }, [fieldType]);

  return (
    <div className={"additional-fields-item" +
                    (isDisabled ? " additional-fields-item--disabled" : "")
    }
         ref={setNodeRef}
         style={style}
         {...attributes}
    > 
      <div className="additional-fields-item__type-drag-place"
           {...listeners}
      >
        <BurgerIcon />
      </div>
      
      <div className="additional-fields-item__type">
        {type}
      </div>
      
      <div className="additional-fields-item__title"
           onClick={() => onOpenExtraFormTab(fieldType)}
      >
        {fieldData.isRequired && (
          "* "
        )}
        {fieldData.title}
      </div>
      <div className="additional-fields-item__required">
        <Checkbox
          className="additional-fields-item__checkbox-mui"
          color="default"
          checked={fieldData.isRequired}
          onChange={() => changeHandler(fieldData.id, fieldType, 'isRequired', !fieldData.isRequired)}
        />
      </div>
      
      <div className="additional-fields-item__send-to-project">
        {/* Временно скрыто */}
        {/*<Checkbox
          className="additional-fields-item__checkbox-mui"
          color="default"
          checked={fieldData.isSendToProject}
          onChange={() => changeHandler(fieldData.id, fieldType, 'isSendToProject', !fieldData.isSendToProject)}
        />*/}
      </div>

      <button className="additional-fields-item__btn additional-fields-item__btn--delete"
              onClick={() => 
                // deleteHandler(fieldData.id, fieldType)
                setOpenDeleteFieldModal(true)
              }
      >
        <CloseIcon />
      </button>

      <div className="additional-fields-item__status">
        {/* Временно скрыто */}
        {/*{fieldData.isSendToProject && (
          <CustomTooltip title='Передавать в проект'>
            <div className="additional-fields-item__status-item">
              <div className="additional-fields-item__status-ico">
                П
              </div>
            </div>
          </CustomTooltip>
        )}*/}
      </div>
      {openDeleteFieldModal && 
      <ModalDeleteFields
      isDeleteModalOpen={openDeleteFieldModal} 
      setDeleteFieldsModalData={setOpenDeleteFieldModal} 
      deleteFields={deleteHandler} 
      id={fieldData.id}
      type={fieldType}/>}
    </div>
  )
}

export default AdditionalFieldsItem;
