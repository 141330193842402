import React, { useState } from "react";
import { State } from "../../../../../rootReducer";
import { connect } from "react-redux";
import IdCell from "../../../../../common/components/TableCells/IdCell";
import { Vector3 } from "../../../../../img/Task";
import TaskNameCell from "../../../../../common/components/TableCells/TaskNameCell";
import ExecutorCell from "../../../../../common/components/TableCells/ExecutorCell";
import { ReactComponent as DotsIcon } from "../../../../../img/TrueIcons/dots.svg";
import ProjectCell from "../../../../../common/components/TableCells/ProjectSection";
import PriorityCell from "../../../../../common/components/TableCells/PriorityCell";
import { urlApp } from "../../../../../App";
import TaskStatus from "../../../../../common/components/TaskStatus/TaskStatus";
import moment from "moment";
import {
  getTask,
  setDefaultValues,
  setFormOpen,
} from "../../../../ObjectsPage/components/FormEditTask/actions";

import "./Task.scss";
import MenuTask from "../../ui/MenuTask/MenuTask";
import { statusTask } from "../../../constants";
import PreloaderLocal from "../../../../../common/components/PreloaderLocal/PreloaderLocal";

const Task: React.FC<{
  task: any;
  users: any[];
  projects: any[];
  setFormOpen: (isFormOpen: boolean) => void;
  setDefaultValues: () => void;
  getTask: (taskId: string | number) => void;
  wasFetchedSlowRequests: boolean;
}> = ({
  task,
  users,
  projects,
  setFormOpen,
  setDefaultValues,
  getTask,
  wasFetchedSlowRequests,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleSetAnchorEl = (value) => {
    setAnchorEl(value);
  };

  const clickTaskActionsOpenHandler = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const formatDate = (date) => moment(date).format("DD.MM.YYYY");

  const executor = users.find((user) => user.id === task.executor_id);

  let project_of_task;
  let section_of_task;

  projects?.find((project) => {
    if (project?.id === task.project_id) {
      project_of_task = project.name;
    }
  });

  const onOpenTaskEdit = (taskId: string | number) => {
    setDefaultValues();

    getTask(taskId);
    setFormOpen(true);
  };

  return (
    <div className={"task-style"} onClick={(e) => onOpenTaskEdit(task?.id)}>
      <div className="left_box">
        <IdCell id={task.id} />
        <div
          style={{ display: "flex", alignItems: "center", marginLeft: "7px" }}
        >
          <Vector3 />
        </div>
        <span
          style={{
            display: "flex",
            alignItems: "center ",
            minWidth: "18px",
            marginRight: "10px",
            width: "fit-content",
            color: "#94959A",
            paddingLeft: "4px",
          }}
        >
          {task.comments_count}
        </span>
        <div className="name_section">
          <TaskNameCell
            name={task.name}
            desc={task.description}
            notificationBall={false}
            id={task.id}
          />
        </div>
      </div>
      <div className="right_flex">
        <div className="executor_for_task">
          <ExecutorCell
            url={
              task?.author?.image
                ? urlApp + `/files/${task?.author?.image?.url}/download`
                : null
            }
            executorName={
              task.author
                ? `${task?.author?.surname} ${task?.author?.name}`
                : "Неизвестен"
            }
            isActive={task.author?.is_active}
          />
          <div className="executor_for_task__name">
            {task?.author?.surname} {task?.author?.name}
          </div>
        </div>

        <div className="executor_for_task">
          <div
            className={
              "executor_for_task__preloader" +
              (!wasFetchedSlowRequests
                ? " executor_for_task__preloader--show"
                : "")
            }
          >
            <PreloaderLocal />
          </div>

          <ExecutorCell
            url={
              executor?.image
                ? urlApp + `/files/${executor.image.url}/download`
                : null
            }
            executorName={
              executor ? `${executor.surname} ${executor.name}` : "Неизвестен"
            }
            isActive={executor?.is_active}
            isExucutor={true}
          />
          <div className="executor_for_task__name">
            {executor?.surname} {executor?.name}
          </div>
        </div>
        <TaskStatus // Из этого дочернего компонента- при начала работы с дочерним компонентом вернуть в родительский statusWindow= false.
          id={
            task.status_id === statusTask.IN_WORK_STATUS &&
            moment(task?.end).isBefore(moment())
              ? statusTask.OVERDUE_STATUS
              : task?.status_id
          }
          taskId={task?.id}
          id_bp={task?.id_bp}
        />
        <div className="deadline_task">{formatDate(task.end)}</div>

        <ProjectCell
          data_project={project_of_task}
          data_section={section_of_task}
        />
        <PriorityCell
          style={{ backgroundColor: "transparent" }}
          priorityId={task.priority_id}
        />

        <div className="task-style__actions">
          <button
            className="task-style__actions-btn"
            onClick={(e) => clickTaskActionsOpenHandler(e)}
          >
            <DotsIcon />
          </button>
        </div>
        <MenuTask
          id={task.id}
          anchorEl={anchorEl}
          setAnchorEl={handleSetAnchorEl}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state: State) => ({
  users: state.commonInfo.users,
  projects: state.projectsPage.projectsAll,
  isFormOpen: state.objectsFormEditTask.isFormOpen,
  wasFetchedSlowRequests: state.commonInfo.wasFetchedSlowRequests,
});

const mapDispatchToProps = {
  setFormOpen,
  setDefaultValues,
  getTask,
};

export default connect(mapStateToProps, mapDispatchToProps)(Task);
