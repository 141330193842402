import { call, put, takeEvery, select, debounce } from "redux-saga/effects";
import {
  checkDeleteTypicalTaskAction,
  deleteTypicalTaskAction,
  TypicalTasksActionTypes
} from "./action_types";
import {
  deleteTypicalTaskFromState,
  setDeleteTypicalTaskModalData,
  setExtraFormCheckboxTabData,
  setExtraFormDocumentTabData,
  setExtraFormInputTabData,
  setExtraFormRadioTabData,
  setExtraFormSelectTabData,
  setIsCreatingTypicalTask,
  setIsShowMenuPreloader,
  setLoadingTypicalTask,
  setLoadingTypicalTasks,
  setNormalFormData,
  setTaskFormOpen, setTypicalTasks,
  setTypicalTasksUsersForFilter,
  setTypicalTasksUsersForFilterIsLoading,
  updateTypicalTaskStore
} from "./actions";
import { fetchData } from "../../utils/fetchData";
import { IAdditionalFieldTypicalTask, ITypicalTaskFrontendData, ITypicalTaskServerData } from "./interfaces";
import {
  DataTypes,
  deleteTypicalTaskStatuses,
  FieldSizes,
  messagesTypicalTask,
  SelectedTabNameExtraForm, 
  sortOrders
} from "./constants";
import { CreateNotif } from "../../utils/createNotification";
import { State } from "../../rootReducer";
import { TypicalTasksActivity, TypicalTasksPersonal, TypicalTasksTime } from "../../common/constants";
import moment from "moment";
import { nanoid } from "nanoid";

export function* watchGetUsersDataForFilter() {
  yield takeEvery(TypicalTasksActionTypes.GET_TYPICAL_TASKS_USERS_FOR_FILTER, getUsersDataForFilter);
}

export function* watchGetTypicalTasks() {
  yield takeEvery(TypicalTasksActionTypes.GET_TYPICAL_TASKS, getTypicalTasks);
}

export function* watchSearchTypicalTasks() {
  yield debounce(500, TypicalTasksActionTypes.SET_SEARCH_TYPICAL_TASKS, getTypicalTasks);
}

export function* watchGetTypicalTask() {
  yield takeEvery(TypicalTasksActionTypes.GET_TYPICAL_TASK, getTypicalTask);
}

export function* watchCreateTypicalTask() {
  yield takeEvery(TypicalTasksActionTypes.CREATE_TYPICAL_TASK, createTypicalTask);
}

export function* watchUpdateTypicalTask() {
  yield takeEvery(TypicalTasksActionTypes.UPDATE_TYPICAL_TASK, updateTypicalTask);
}

export function* watchCheckDeleteTypicalTask() {
  yield takeEvery(TypicalTasksActionTypes.CHECK_DELETE_TYPICAL_TASK, checkDeleteTypicalTask);
}

export function* watchDeleteTypicalTask() {
  yield takeEvery(TypicalTasksActionTypes.DELETE_TYPICAL_TASK, deleteTypicalTask);
}

function* getTypicalTasks({params, action}: any) {
  const currentCompanyId = localStorage.getItem('company_id');
  const currentUser = yield select((state: State) => state.commonInfo.currentUserInfo);

  if(!currentCompanyId) return;
  
  const filters = yield select((state: State) => state.typicalTasksPage.filters);
  const sortConditions = yield select((state: State) => state.typicalTasksPage.sortCondition);
  const search = yield select((state: State) => state.typicalTasksPage.searchTypicalTasks);
  const currentPage = yield select((state: State) => state.typicalTasksPage.currentPage);
  
  let orderType = '';
  let url = '?';
  
  if(sortConditions) {
    let columnId = sortConditions.columnId;
    
    if(columnId === 'activity') columnId = 'status';
    if(columnId === 'createdAt') columnId = 'created_at';
    
    orderType = sortConditions.value === sortOrders.DESC ? '' : '-';
    url += 'sort=' + orderType + columnId;
  }

  if(filters) {
    if(filters.activity === TypicalTasksActivity.ACTIVE_STATUS) {
      url += '&filter[status]=1';
    }
    
    if(filters.activity === TypicalTasksActivity.NOT_ACTIVE_STATUS) {
      url += '&filter[status]=0';
    }
    
    if(filters.usersIds.length) {
      filters.usersIds.forEach(userId => {
        url += `&filter[author_id][]=${userId}`;
      });
    }
    
    if(filters.personal === TypicalTasksPersonal.PERSONAL_STATUS) {
      url += `&filter[author_id][]=${currentUser.id}`;
    }
  }

  if(search?.length) {
    url += `&filter[search]=${search}`;
  }

  if(currentPage) {
    url += `&page=${currentPage}`;
  }

  yield put(setLoadingTypicalTasks(true));

  const response: ITypicalTaskServerData[] = yield call(
    fetchData.get,
    `/api/v1/${currentCompanyId}/typical-tasks${url}`
  );

  if(response) {
    yield put(setTypicalTasks(response, action));
  }

  yield put(setLoadingTypicalTasks(false));
}

function* updateTypicalTask({data}: any) {
  yield put(setIsCreatingTypicalTask(true));
  
  const convertedData = yield convertFrontToServerDataHelper(data);

  const response: ITypicalTaskServerData = yield call(
    fetchData.put,
    `/api/v1/typical-tasks/${convertedData.id}`,
    JSON.stringify(convertedData)
  );
  
  if(response) {
    yield put(updateTypicalTaskStore(response));

    CreateNotif(messagesTypicalTask.UPDATE_TASK_SUCCESS, 'success');
  }

  yield put(setIsCreatingTypicalTask(false));
}

function* createTypicalTask({data}: any) {
  yield put(setIsCreatingTypicalTask(true));

  const currentCompanyId = localStorage.getItem('company_id');
  const convertedData = yield convertFrontToServerDataHelper(data);

  const response: ITypicalTaskServerData = yield call(
    fetchData.post,
    `/api/v1/${currentCompanyId}/typical-tasks`,
    JSON.stringify(convertedData)
  );

  if(response) {
    yield put(setTypicalTasks(response));

    CreateNotif(messagesTypicalTask.CREATE_TASK_SUCCESS, 'success');
  }

  yield put(setIsCreatingTypicalTask(false));
}

function* getUsersDataForFilter() {
  // Здесь должна быть выборка авторов типовых задач
  // Сейчас грузятся 20 юзеров
  
  yield put(setTypicalTasksUsersForFilterIsLoading(true));
  
  let response = yield call(
    fetchData.get,
    '/api/v1/users'
  );

  if(response) {
    yield put(setTypicalTasksUsersForFilter(response));
  }
  
  yield put(setTypicalTasksUsersForFilterIsLoading(false));
}

function* getTypicalTask({id}: any) {
  yield put(setLoadingTypicalTask(true));
  yield put(setTaskFormOpen(true));
  
  let response: ITypicalTaskServerData = yield call(
    fetchData.get,
    `/api/v1/typical-tasks/${id}`
  );

  if(response) {
    const convertedData = convertServerToFrontDataHelper(response);

    yield put(setNormalFormData(convertedData));
    
    if(convertedData?.additionalFields) {
      for(let field of convertedData.additionalFields) {
        const {type} = field;

        if(type === SelectedTabNameExtraForm.INPUT_TAB) {
          yield put(setExtraFormInputTabData(field));
        }
        if(type === SelectedTabNameExtraForm.SELECT_TAB) {
          yield put(setExtraFormSelectTabData(field));
        }
        if(type === SelectedTabNameExtraForm.RADIO_TAB) {
          yield put(setExtraFormRadioTabData(field));
        }
        if(type === SelectedTabNameExtraForm.CHECKBOX_TAB) {
          yield put(setExtraFormCheckboxTabData(field));
        }
        if(type === SelectedTabNameExtraForm.DOCUMENT_TAB) {
          yield put(setExtraFormDocumentTabData(field));
        }
      }
    }
  }
  
  yield put(setLoadingTypicalTask(false));
}

function* checkDeleteTypicalTask({id}: checkDeleteTypicalTaskAction) {
  // По ТЗ должна быть проверка на возможность удаления задачи
  // Сейчас сделано без этой проверки

  yield put(setIsShowMenuPreloader(true));
  
  let response: any = null;
  
 /* response = yield call(
    fetchData.get,
    `/api/v1/typical-tasks/${id}/check-delete`
  );*/
  
  if(response?.constraints.length === 0) {
    response = {constraints: [deleteTypicalTaskStatuses.DELETE_TASK_ALLOWED]};
  }

  if(!response) {
    response = {constraints: [deleteTypicalTaskStatuses.DELETE_TASK_ERROR]};
  }
  
  yield put(setDeleteTypicalTaskModalData({
    taskId: id,
    isDeleteModalOpen: true,
    statusFromServer: {constraints: [deleteTypicalTaskStatuses.DELETE_TASK_ALLOWED as string]} 
  }));

  yield put(setIsShowMenuPreloader(false));
}

function* deleteTypicalTask({id}: deleteTypicalTaskAction) {
  const errorStatuses = [403, 404, 500];

  yield put(setDeleteTypicalTaskModalData({ isShowPreloader: true }));

  const response = yield call(
    fetchData.delete,
    `/api/v1/typical-tasks/${id}`
  );

  yield put(setDeleteTypicalTaskModalData({ isDeleteModalOpen: false, isShowPreloader: false }));
  
  if(response?.status === 204) {
    yield put(deleteTypicalTaskFromState(id));
    
    CreateNotif(messagesTypicalTask.DELETE_TASK_SUCCESS, 'success');
  }

  if(errorStatuses.includes(response?.status)) {
    CreateNotif(messagesTypicalTask.DELETE_TASK_ERROR);
  }
}

function* convertFrontToServerDataHelper(data: ITypicalTaskFrontendData) {
  const currentCompanyId = localStorage.getItem('company_id');
  const currentUser = yield select((state: State) => state.commonInfo.currentUserInfo);

  // Дополнительные поля
  const extra = data.fields?.map((field: IAdditionalFieldTypicalTask) => {
    const idsArr = [
      SelectedTabNameExtraForm.INPUT_TAB,
      SelectedTabNameExtraForm.SELECT_TAB,
      SelectedTabNameExtraForm.RADIO_TAB,
      SelectedTabNameExtraForm.CHECKBOX_TAB,
      SelectedTabNameExtraForm.DOCUMENT_TAB
    ];
    const id = idsArr.findIndex((el) => el === field.type);

    const result: any = {
      id,
      name: field.title,
      required: field.isRequired,
      passToObject: field.isSendToProject,
    };

    if(field.type === SelectedTabNameExtraForm.INPUT_TAB) {
      result.data = {
        type: field.dataType,
        size: field.fieldSize
      }
    }

    if(field.arrayValues?.length) {
      result.data = {
        list: field.arrayValues.map(field => field.value)
      };
    }

    return result;
  });

  const checklist = data.checkList?.map(item => item.name);

  const timeFormat = data.timeType === TypicalTasksTime.DAY_FORMAT ? 'days' : 'hours';
  const isInteger = Number.isInteger(data.timeValue ?? 0);
  let duration
  if(data.timeValue){
  if (isInteger && timeFormat === 'days') {
    duration = moment.duration(data.timeValue, timeFormat).toISOString();
    } else if(timeFormat !== 'days') {
      duration = moment.duration(data.timeValue, timeFormat).toISOString();
    } else{
    const days = Math.floor(data?.timeValue);
    const hours = Math.round((data?.timeValue - days) * 24);
    duration = `P${days}DT${hours}H`;
    }
   }
  
  return {
    id: data.idTaskEdited,
    name: data.name,
    description: data.description,
    executor_id: data.executor?.value ?? '',
    // При редактировании автор берется тот, который есть. При создании новой задачи - текущий юзер
    author_id: data.authorId ?? currentUser.id, 
    company_id: currentCompanyId,
    delegate: data.delegation,
    duration,
    checklist,
    extra,
    status: (data.activity === TypicalTasksActivity.ACTIVE_STATUS)
    // status: (data.activity === TypicalTasksActivity.ACTIVE_STATUS) ? 0 : 1
  };
}

export function mapExtraFieldTypeHelper(field, idFromServer) {
  return Object.values(SelectedTabNameExtraForm)[idFromServer];
}

function convertServerToFrontDataHelper(data: ITypicalTaskServerData): ITypicalTaskFrontendData {
  let extra: IAdditionalFieldTypicalTask[] = [];
  let timeValue = parseFloat((moment.duration(data.duration).asDays()).toFixed(1));
  let timeType = TypicalTasksTime.DAY_FORMAT
  if(timeValue < 1) {
    timeValue = parseFloat((moment.duration(data.duration).asHours()).toFixed(1));
    timeType = TypicalTasksTime.HOUR_FORMAT;
  }
 
  if(data.extra?.length) {
    extra = data?.extra.map(field => ({
      id: nanoid(6),
      type: mapExtraFieldTypeHelper(field, field.id),
      dataType: field.data?.type ?? DataTypes.VALUE_ANY,
      fieldSize: field.data?.size ?? FieldSizes.VALUE_STANDARD,
      isRequired: field.required,
      isSendToProject: field.passToObject,
      title: field.name,
      arrayValues: field.data?.list?.map(value => ({
        id: nanoid(6),
        value
      })) ?? []
    }));
  }

  let dataLocal = {
    idTaskEdited: data.id,
    name: data.name,
    bp: data?.bp ?? [],
    timeValue,
    timeType,
    executor: {
      value: data.executor?.id ?? '',
      label: data.executor?.surname + ' ' + data.executor?.name
    },
    delegation: !!data.delegate,
    description: data.description,
    checkList: data.checklist?.map(el => ({name: el})),
    author: data.author?.surname + ' ' + data.author?.name,
    authorId: data.author?.id,
    createdAt: data?.created_at,
    activity: (data.status === 1) ? 1 : 2,
    additionalFields: extra
  }

  return dataLocal;
}
