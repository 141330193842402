import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { Item } from "../../../types";
import SearchInput from "../../newComponent/Inputs/SearchInput";
import CheckboxPeople from "../../CheckboxItem/CheckboxPeopel";
import {ReactComponent as ArrowDownIcon} from '../../../../img/TrueIcons/arrow-down.svg';
import PreloaderLocal from "../../PreloaderLocal/PreloaderLocal";

import './MultiSelectVanilla.scss';

export interface IMultiSelectVanillaList {
  text: string;
  value: string | number;
}

type Props = {
  selectedItems: Item[];
  itemsList: Item[];
  disabled?: boolean;
  placeholder?: string;
  isSingleSelect?: boolean; // возможность выбрать 1 элемент (true) или несколько(false)
  isValid?: boolean;
  addHandler: (value: any) => void;
  removeHandler: (value: number) => void;
  showSearchField?: boolean;
  classname?: string;
  textFieldValue?: string;
  attached_items?: Item[];
  current_value?: string;
  length_block?: number;
  withImage?: boolean;
  selectedItemImage?: string;
  showAvatar?: boolean;
  IconName?: string;
  openHandler?: (value?: any) => void;
  isLoading?: boolean;
};
const WrapperMultySelect = styled.div`
  position: relative;
`;

const MultiSelectVanilla: React.FC<Props> = ({
  classname,
  textFieldValue,
  attached_items,
  current_value,
  length_block = "100%",
  selectedItemImage,
  showAvatar,
  IconName,
  placeholder,
  withImage,
  selectedItems,
  itemsList,
  addHandler,
  removeHandler,
  disabled = false,
  isSingleSelect = false,
  showSearchField = true,
  openHandler,
  isLoading,
}) => {
  const [show, setShow] = useState<boolean>(false);
  const list = useRef<HTMLDivElement>(null);
  const [filterItemsList, setFilterItemsList] = useState([...itemsList]);
  const [value, setValue] = useState("");
  const [isVanillaSelectOpen, setIsVanillaSelect] = useState<boolean>(false);
  
  const search = (event) => {
    setValue('');

    setFilterItemsList([...itemsList]);
    let searchString = event.target.value.toLowerCase();
    setValue(searchString);

    let filter = itemsList.filter((data) => {
      if (data.rus_name) {
        // приоритет - смотреть по параметру rus_name
        return data.rus_name?.toLowerCase().includes(searchString);
      } else {
        return data.text?.toLowerCase().includes(searchString);
      }
    });

    if (searchString.length === 0) setFilterItemsList([...itemsList]);
    else setFilterItemsList(filter);
  };

  const closeVanillaSelect = (e) => {
    if (!e.currentTarget?.contains(e.relatedTarget)) {
      setIsVanillaSelect(false);
    }
  };

  const openVanillaSelect = () => {
    // if(isVanillaSelectOpen) return;

    setIsVanillaSelect(!isVanillaSelectOpen);
    setShow(true);

    if(typeof openHandler === 'function') {
      openHandler();
    }
  };
  
  useEffect(() => {
    if (current_value) setValue(current_value as string);
  }, [current_value]); 

  useEffect(() => {
    if (textFieldValue !== undefined) setValue(textFieldValue as string);
  }, [textFieldValue]);

  useEffect(() => {
    if(selectedItems.length === 0) {
      setFilterItemsList([...itemsList]);
    }
  }, [selectedItems.length, isSingleSelect, itemsList]);
  
  return (
    <WrapperMultySelect
      className={
        'multi-select-vanilla' + 
        (!showSearchField ? ' multi-select-vanilla--without-search' : '') +
        (isVanillaSelectOpen ? ' multi-select-vanilla--open' : '') +
        (isLoading ? ' multi-select-vanilla--show-preloader' : '')
      }
      tabIndex={-1}
      onBlur={closeVanillaSelect}
      ref={list}
      style={{
        width:
          typeof length_block === "number" ? length_block + "px" : length_block,}}
    >
      <div className="multi-select-vanilla__title-wr"
           onClick={() => openVanillaSelect()}
      >
        <div className="multi-select-vanilla__title">
          {placeholder}
        </div>
        <div className="multi-select-vanilla__title-ico">
          <ArrowDownIcon />
        </div>
      </div>
      
      <div className="multi-select-vanilla__wr">
        {showSearchField &&
          // Множественный выбор
          (!isSingleSelect ? (
            <SearchInput
              fontSize="14px"
              value={value}
              classname={classname + ' multi-select-vanilla__search-input'}
              disabled={disabled}
              placeholder='Поиск...'
              onChange={search}
              onClick={() => {
                setFilterItemsList([...itemsList]);
              }}
            />
          ) : isSingleSelect && selectedItems.length === 0 ? (
            // Одиночный выбор
            <SearchInput
              fontSize="14px"
              single_choice={isSingleSelect && !show}
              value={value}
              selectedItemImage={selectedItemImage}
              showAvatar={showAvatar}
              IconName={IconName}
              classname={classname + ' multi-select-vanilla__search-input'}
              disabled={disabled}
              placeholder='Поиск...'
              onChange={search}
              onClick={(e) => {
                e.stopPropagation();
                
                if (show) setValue(current_value as string);
                else setValue('');
                setFilterItemsList([...itemsList]);
              }}
            />
          ) : null)}

        {show && (
          <div className='multi-select-vanilla__content'>
            <div className='multi-select-vanilla__content-preloader'>
              <PreloaderLocal />
            </div>
            
            <CheckboxPeople
              length_block={length_block as number}
              users={filterItemsList}
              selectedUsers={selectedItems}
              attached_items={attached_items}
              addHandler={(arg) => {
                if(isSingleSelect) {
                  setIsVanillaSelect(false);
                }

                addHandler(arg);
              }}
              removeHandler={removeHandler}
              radio={isSingleSelect}
              withImage={withImage}
            />
          </div>
        )}
      </div>
    </WrapperMultySelect>
  );
};

export default MultiSelectVanilla;
