import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { State } from "../../../../../rootReducer";

import "./ExtraForm.scss";

import {
  setCurrentPageBpListTreaty,
  setExtraFormActiveTabIndex,
  setExtraFormTreatyDefaultOpen,
  setExtraFormTreatyTasksAndBPFormOpen,
  setSearchTypicalBps,
} from "../../../actions";
import AddTreatyStage from "./components/AddTreatyStage/AddTreatyStage";
import { AppBar, Box, Tab, Tabs, Typography } from "@material-ui/core";
import Fade from "@material-ui/core/Fade";
import { TabIdsExtraFormTreaty } from "../../../interfaces";
import ExtraTypicalTasks from "../../../../BusinessPage/components/FormBP/ExtraFormBP/components/ExtraTypicalTasks";
import { TextareaAutosize } from "@material-ui/core";
import BpsMainTreaty from "./components/BpMainTreaty/BpsMainTreaty";
import {
  setCurrentPageTypicalTasksBp,
  setSearchTypicalTasksBp,
} from "../../../../BusinessPage/actions";
import FilterItemsExtraForm from "./components/FilterItemsExtraForm/FilterItemsExtraForm";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Fade in={value === index}>
      <div
        hidden={value !== index}
        id={`extra-form-treaty-tabpanel-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            <Typography component='div'>{children}</Typography>
          </Box>
        )}
      </div>
    </Fade>
  );
}

const ExtraFormBP: React.FC<{
  isExtraFormTreatyDefaultOpen: boolean;
  setExtraFormTreatyDefaultOpen: (mode: boolean) => void;
  isEditOpen: boolean;
  activeTabIndex: number;
  setExtraFormActiveTabIndex: (activeTabIndex: number) => void;
  isTasksAndBpOpen: boolean;
  setExtraFormTreatyTasksAndBPFormOpen: (mode: boolean) => void;
  setSearchTypicalTasksBp: (mode: string) => void;
  setSearchTypicalBps: (mode: string) => void;
  setCurrentPageTypicalTasksBp: (mode: number) => void;
  setCurrentPageBpListTreaty: (mode: number) => void;
  isEditForm: boolean;
  author?: string;
  main?: boolean;
}> = ({
  isExtraFormTreatyDefaultOpen,
  setExtraFormTreatyDefaultOpen,
  isEditOpen,
  activeTabIndex,
  setExtraFormActiveTabIndex,
  isTasksAndBpOpen,
  setExtraFormTreatyTasksAndBPFormOpen,
  setSearchTypicalTasksBp,
  setSearchTypicalBps,
  setCurrentPageTypicalTasksBp,
  setCurrentPageBpListTreaty,
  author,
  isEditForm,
  main
}) => {
  const [value, setValue] = useState();

  const onCloseFormHandler = () => {
    setExtraFormTreatyDefaultOpen(true);
  };

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setExtraFormActiveTabIndex(newValue);
    setExtraFormTreatyDefaultOpen(false);
  };

  useEffect(() => {
    if (activeTabIndex === TabIdsExtraFormTreaty.TASKS_TAB) {
      setCurrentPageTypicalTasksBp(1);
    } else {
      setCurrentPageBpListTreaty(1);
    }
  }, [!!value]);

  const handleSearch = (e) => {
    setValue(e.target.value);
    if (activeTabIndex === TabIdsExtraFormTreaty.TASKS_TAB) {
      setSearchTypicalTasksBp(e.target.value);
    } else {
      setSearchTypicalBps(e.target.value);
    }
  };

  const onCloseHandler = () => {
    setExtraFormTreatyTasksAndBPFormOpen(false);
    setExtraFormTreatyDefaultOpen(true);
  };
  return (
    <div className="extra-form-treaty">
      <AddTreatyStage />
      <div
        className={
          "extra-form-treaty__tabs" +
          (isTasksAndBpOpen ? " extra-form-treaty__tabs--open" : "")
        }
      >
        <AppBar className="extra-form-treaty__tabs-header" position="static">
          <Tabs value={activeTabIndex} onChange={handleChange}>
            <Tab label="Список бизнес-процессов" />
            <Tab label="Список типовых задач" />
          </Tabs>
        </AppBar>
        <div className="extra-form-treaty__filters">
          <div className="extra-form-treaty__filter">
            <h3 className="extra-form-treaty__name-filter">
              Поиск по наименованию и id
            </h3>
            <TextareaAutosize
              className={"search-typical-task-bp"}
              onChange={(e) => {
                handleSearch(e);
              }}
              value={value}
            />
          </div>
          <div className="extra-form-treaty__filter">
            <h3 className="extra-form-treaty__name-filter">
              Используются в типовых договорах
            </h3>
            <FilterItemsExtraForm activeTabIndex={activeTabIndex}/>
          </div>
        </div>
        <TabPanel value={activeTabIndex} index={TabIdsExtraFormTreaty.BPS_TAB}>
          <BpsMainTreaty />
        </TabPanel>
        <TabPanel
          value={activeTabIndex}
          index={TabIdsExtraFormTreaty.TASKS_TAB}
        >
          <ExtraTypicalTasks treaty={true} />
        </TabPanel>
        <div className="extra-form-stage-add__btns">
          <button
            className="extra-form-stage-add__btn extra-form-stage-add__btn--cancel"
            onClick={onCloseHandler}
          >
            <span className="extra-form-stage-add__btn-title">Готово</span>
          </button>
        </div>
      </div>
      <div
        className={
          "extra-form-treaty__default" +
          (isExtraFormTreatyDefaultOpen
            ? " extra-form-treaty__default--open"
            : "")
        }
      >
        {main ? (
          <>
            <h2 className="extra-form-treaty__default-title">
              MAIN «Полный типовой договор»
            </h2>
            <span className="extra-form-treaty__default-text">
              MAIN. «Полный типовой договор» — базовый типовой договор, который
              содержит все возможные этапы и полный состав бизнес-процессов и
              задач в любом из этапов. Корректировки MAIN автоматически
              рспостраняются на все другие типовые договоры, созданные на его
              основе
            </span>
            <h2 className="extra-form-treaty__default-title">
              Управляйте составом и именами этапов
            </h2>
            <ul className="extra-form-treaty__default-text">
              <li className="extra-form-treaty__default-text-item">
                Для добавления дополнительного этапа кликните по ссылке
                "Добавить этап" в левой части страницы.
              </li>
              <li className="extra-form-treaty__default-text-item">
                Используейте контекстное меню (клик по "трем точкам") для
                удаления или редактирования имени этапа.
              </li>
            </ul>
            <h2 className="extra-form-treaty__default-title">
              Управляйте составом бизнес-процессов и задач в этапах
            </h2>
            <ul className="extra-form-treaty__default-text">
              <li className="extra-form-treaty__default-text-item">
                Кликните в наименование этапа, чтобы получить возможность
                сформировать в нем состав бизнес-процессов и типовых задач.
              </li>
            </ul>
          </>
        ) : (
          <>
            <h2 className="extra-form-treaty__default-title">
              Настройте актуальность этапов для этого типа договора
            </h2>

            <ul className="extra-form-treaty__default-text">
              <li className="extra-form-treaty__default-text-item">
                Все типовые договоры создаются на базе «MAIN. Полный типовой
                договор». Укажите для каждого конкретного этапа его актуальность
                для этого типового договора.
              </li>
            </ul>
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: State) => {
  return {
    isExtraFormTreatyDefaultOpen:
      state.typicalTreatyPage.extraForm.isExtraFormTreatyDefaultOpen,
    isEditOpen: state.typicalTreatyPage.extraForm.isEditStageOpen,
    activeTabIndex: state.typicalTreatyPage.extraForm.activeTabIndex,
    isTasksAndBpOpen: state.typicalTreatyPage.extraForm.isTasksAndBpOpen,
    author: state.typicalTreatyPage.normalForm.author,
    isEditForm: state.typicalTreatyPage.isEditForm,
    main: state.typicalTreatyPage.normalForm.main,
  };
};

const mapDispatchToProps = {
  setExtraFormTreatyDefaultOpen,
  setExtraFormActiveTabIndex,
  setExtraFormTreatyTasksAndBPFormOpen,
  setSearchTypicalTasksBp,
  setSearchTypicalBps,
  setCurrentPageTypicalTasksBp,
  setCurrentPageBpListTreaty,
};

export default connect(mapStateToProps, mapDispatchToProps)(ExtraFormBP);
