import React, { useState } from 'react';
import { connect } from 'react-redux';
import { State } from "../../../../../rootReducer";
import MultiSelectVanilla from "../../../../../common/components/ui/MultiSelectVanilla/MultiSelectVanilla";
import { getTypicalTasks, setCurrentPage, setTypicalTasksFilterActivity } from "../../../actions";
import { TypicalTasksActivity } from "../../../../../common/constants";
import { TypeFilterActivity } from "../../../action_types";

type Props = {
  activityFilterId: TypeFilterActivity;
  setTypicalTasksFilterActivity: (id: TypeFilterActivity) => void;
  disabled?: boolean;
  isSingleSelect?: boolean;
  getTypicalTasks: (params?: object) => void,
  setCurrentPage: (currentPage: number) => void
};

const UsersSelectTypicalTasks: React.FC<Props> = ({
  setTypicalTasksFilterActivity,
  disabled,
  isSingleSelect,
  getTypicalTasks,
  setCurrentPage
}) => {
  const [placeholder, setPlaceholder] = useState<any>(TypicalTasksActivity.ACTIVE_TEXT);
  const defaultValues = [
    {text: TypicalTasksActivity.ALL_TEXT, value: TypicalTasksActivity.ALL_STATUS},
    {text: TypicalTasksActivity.ACTIVE_TEXT, value: TypicalTasksActivity.ACTIVE_STATUS},
    {text: TypicalTasksActivity.NOT_ACTIVE_TEXT, value: TypicalTasksActivity.NOT_ACTIVE_STATUS}
  ];
  
  const addHandler = (value) => {
    setCurrentPage(1);
    setPlaceholder(defaultValues[value]?.text);
    setTypicalTasksFilterActivity(value);
    getTypicalTasks();
  };
  
  return (
    <MultiSelectVanilla
      placeholder={placeholder}
      withImage={false}
      showSearchField={false}
      isSingleSelect={isSingleSelect}  
      disabled={disabled}
      itemsList={defaultValues}
      selectedItems={[]}
      addHandler={(value) => addHandler(value)}
      removeHandler={() => {
        
      }}
    />
  );
};

const mapStateToProps = (state: State) => {
  return {
    activityFilterId: state.typicalTasksPage.filters.activity
  };
};

const mapDispatchToProps = {
  setTypicalTasksFilterActivity,
  getTypicalTasks,
  setCurrentPage
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersSelectTypicalTasks);
