import { call, put, takeEvery, select, debounce } from "redux-saga/effects";
import {
  deleteTaskFromTasksState,
  setDeleteTaskModalOpen,
  setLoadingTasks,
  setTasksList,
  taskPageUpdateTaskStore,
} from "./actions";
import { deleteTaskStatuses, sortOrders } from "./constants";
import {
  TasksActionTypes,
  checkDeleteTaskAction,
  deleteTaskAction,
  updateTaskAction,
} from "./action_types";
import { CreateNotif } from "../../utils/createNotification";
import { messagesTaskConstants } from "../../common/constants";
import { fetchData } from "../../utils/fetchData";
import { State } from "../../rootReducer";
import { formatToStringUTC } from "../../common/format";

export function* watchCheckDeleteTaskM() {
  yield takeEvery(TasksActionTypes.CHECK_DELETE_TASK, checkDeleteTask);
}

export function* watchDeleteTaskM() {
  yield takeEvery(TasksActionTypes.DELETE_TASK, deleteTask);
}

export function* watchGetTasksM() {
  yield takeEvery(TasksActionTypes.GET_TASKS, getTasks);
}

export function* watchSetStatusFilterTasksM() {
  yield takeEvery(TasksActionTypes.SET_FILTERS, getTasks);
}
export function* watchSearchTasksM() {
  yield debounce(500,TasksActionTypes.SET_SEARCH_TASKS, getTasks);
}

export function* watchUpdateTaskM() {
  yield takeEvery(TasksActionTypes.UPDATE_TASK, updateTask);
}

function* getTasks({ params, action }: any) {
  const currentCompanyId = localStorage.getItem("company_id");
  const currentUser = yield select(
    (state: State) => state.commonInfo.currentUserInfo
  );

  if (!currentCompanyId) return;

  const allFilters = yield select(
    (state: State) => state.tasksObjects.filters.appliedFilters
  );
  const sortConditions = yield select(
    (state: State) => state.tasksObjects.sortConditions
  );
  const search = yield select((state: State) => state.tasksObjects.searchTasks);
  const currentPage = yield select(
    (state: State) => state.tasksObjects.currentPage
  );
  let orderType = "";
  let url = "?";
if (currentPage) {
    url += `page=${currentPage}`;
  }

  if (sortConditions) {
    let columnId = sortConditions.columnId;
    orderType = sortConditions.value === sortOrders.DESC ? "&orderType=desc" : "";
    url += "&order=" + columnId + orderType;
  }  

  if (search?.length) {
    url += `&search=${search}`;
  }

  if (allFilters) {
    if (allFilters.date_from) {
      url += `&begin_from=${formatToStringUTC(allFilters.date_from)}`;
    }
    if (allFilters.date_to) {
      url += `&end_to=${formatToStringUTC(allFilters.date_to)}`;
    }

    if (allFilters.executors.length > 0) {
      const executorIds = allFilters.executors.join(",");

      url += `&executor_id=${executorIds}`;
    }

    if (allFilters.authors.length > 0) {
      const authorIds = allFilters.authors.join(",");
      url += `&author_id=${authorIds}`;
    }

    if (allFilters.statuses.length > 0) {
      const statuses = allFilters.statuses.join(",");
      url += `&status_id=${statuses}`;
    }

    if (allFilters.actual) {
      url += `&actual=true`;
    }
  }

  yield put(setLoadingTasks(true));

  const response: any[] = yield call(
    fetchData.get,
    `/api/v1/tasks${url}`
  );

  if (response) {
    yield put(setTasksList(response, action));
  }  
  
  yield put(setLoadingTasks(false));
}


function* checkDeleteTask({ id }: checkDeleteTaskAction) {
  yield put(
    setDeleteTaskModalOpen({
      taskId: id,
      isDeleteModalOpen: true,
      isShowMenuPreloader: true,
    })
  );

  let response: any = null;

    response = yield call(
      fetchData.get,
      `/api/v1/tasks/${id}/check-delete`
    );

    if (response?.constraints.length === 0) {
    response = { constraints: [deleteTaskStatuses.DELETE_TASK_ALLOWED] };
    }

    if (!response) {
  response = { constraints: [deleteTaskStatuses.DELETE_TASK_ERROR] };
    }

  yield put(
    setDeleteTaskModalOpen({
      taskId: id,
      isDeleteModalOpen: true,
      statusFromServer: response,
      isShowMenuPreloader: false,
    })
  );
}

function* deleteTask({ id }: deleteTaskAction) {
  const errorStatuses = [403, 404, 500];

  yield put(
    setDeleteTaskModalOpen({
      taskId: id,
      isDeleteModalOpen: true,
      isShowPreloader: true,
    })
  );

  const response = yield call(
    fetchData.delete,
    `/api/v1/tasks/${id}`
  );

    yield put(
      setDeleteTaskModalOpen({
        taskId: id,
        isDeleteModalOpen: false,
        isShowPreloader: false,
      })
    );

  if(response?.status === 204) {
  yield put(deleteTaskFromTasksState(id));
  CreateNotif(messagesTaskConstants.DELETE_TASK_SUCCESS, "success");
  }

  if(errorStatuses.includes(response?.status)) {
    CreateNotif(messagesTaskConstants.DELETE_TASK_ERROR);
  }
}

function* updateTask({taskId, data}: updateTaskAction) {
  const errorStatuses = [403, 404, 405, 500];
  // yield put(setIsCreatingTask(true));

  // const convertedData = yield convertFrontToServerDataHelper(data);
  
  const response: any = yield call(
    fetchData.patch,
    `/api/v1/tasks/${taskId}`,
    JSON.stringify(data)
  );
  
  if(response) {
    yield put(taskPageUpdateTaskStore(response));

    CreateNotif(messagesTaskConstants.UPDATE_TASK_SUCCESS, 'success');
  }
  if(errorStatuses.includes(response?.status)) {
    CreateNotif(messagesTaskConstants.UPDATE_TASK_ERROR);
  }

  // yield put(setIsCreatingTask(false));
}