import React, { FC, useEffect, useState } from 'react';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { connect } from "react-redux";
import { State } from "../../../../../../rootReducer";
import { ReactComponent as CloseIcon } from '../../../../../../img/TrueIcons/close.svg';
import {
  setTaskDeleteConnectionModalBp
} from "../../../../actions";
import { ITaskDeleteConnectionModal } from "../../../../interfaces";
import {
  taskDeleteConnectionActions,
  taskDeleteConnectionStatuses
} from "../../../../constants";
import { modalDeleteConnectionData } from "../../../../sharedData";

import './ModalTaskDeleteBp.scss';

interface IModalText {
  title: string,
  text?: string,
  btns: {actionId: taskDeleteConnectionActions, title: string}[]
}

const ModalTaskDeleteBp: FC<{
  isModalOpen?: boolean,
  connectionStatusTrigger?: number|null,
  setTaskDeleteConnectionModalBp: (data: ITaskDeleteConnectionModal) => void
}> = ({
  isModalOpen,
  connectionStatusTrigger,
  setTaskDeleteConnectionModalBp
}) => {
  const textObj = modalDeleteConnectionData;
  
  const [text, setText] = useState<IModalText>(
    textObj[taskDeleteConnectionStatuses.STATUS_ERROR_DELETE as number]
  );
  
  const onCloseHandle = () => {
    setTaskDeleteConnectionModalBp({
      isModalOpen: false, 
      connectionStatusTrigger: null
    });
  };
  
  const onButtonClickHandle = (actionId: taskDeleteConnectionActions) => {
    if(connectionStatusTrigger && connectionStatusTrigger < 1000) {
     setTaskDeleteConnectionModalBp({
       connectionActionTrigger: actionId
     });
    }
    
    if(connectionStatusTrigger && connectionStatusTrigger >= 1000) {
     setTaskDeleteConnectionModalBp({
       connectionActionTrigger: connectionStatusTrigger
     });
    }
    
    onCloseHandle();
  };

  useEffect(() => {
    if(typeof connectionStatusTrigger === 'number') {
      setText(textObj[connectionStatusTrigger]);
      setTaskDeleteConnectionModalBp({isModalOpen: true});
    }
  }, [connectionStatusTrigger]);
  
  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={'modal-task-delete-bp'}
        open={isModalOpen ?? false}
        onClose={onCloseHandle}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={isModalOpen}>
          <div className='modal-task-delete-bp__content-wr'>
            <div className='modal-task-delete-bp__title-wr'>
              <div className="modal-task-delete-bp__title">
                {text.title}
              </div>
              <div className="modal-task-delete-bp__delete-ico-wr"
                   onClick={() => onButtonClickHandle(taskDeleteConnectionActions.ACTION_CANCEL)}
              >
                <CloseIcon />
              </div>
            </div>

            {text?.text && (
              <div className="modal-task-delete-bp__content">
                <div className="modal-task-delete-bp__text"
                     dangerouslySetInnerHTML={{__html: text?.text}}
                >
                </div>
              </div>
            )}
            
            <div className="modal-task-delete-bp__btns">
              {!!text?.btns.length && (
                <div className="modal-task-delete-bp__btns-left">
                  {text.btns.map((btn, i) => (
                    <button className="modal-task-delete-bp__btn" 
                            key={i}
                            onClick={() => onButtonClickHandle(btn.actionId)}
                    >
                      <span className="modal-task-delete-bp__btn-title">{btn.title}</span>
                    </button>
                  ))}
                </div>
              )}
              
              <div className="modal-task-delete-bp__btns-right">
                <button className="modal-task-delete-bp__btn modal-task-delete-bp__btn--cancel"
                        onClick={() => onButtonClickHandle(taskDeleteConnectionActions.ACTION_CANCEL)}
                >
                  <span className="modal-task-delete-bp__btn-title">Отмена</span>
                </button>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
}

const mapStateToProps = (state: State) => {
  return {
    isModalOpen: state.businessPage.taskDeleteConnectionModal.isModalOpen,
    connectionStatusTrigger: state.businessPage.taskDeleteConnectionModal.connectionStatusTrigger
  };
};

const mapDispatchToProps = {
  setTaskDeleteConnectionModalBp
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalTaskDeleteBp);
