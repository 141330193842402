import { Action } from "redux";
import { ISortConditions } from "./interfaces";
import { deleteTaskStatuses } from "./constants";
import { File, IMeta, User } from "../../common/types";

export enum TasksActionTypes {
  GET_TASKS = "@@TASKS_PAGE/GET_TASKS",
  SET_TASKS_LIST = "@@TASKS_PAGE/SET_TASKS",
  SET_SORT_CONDITION = "@@TASKS_PAGE/SET_SORT_CONDITION",
  SET_CURRENT_PAGE = "@@TASKS_PAGE/SET_CURRENT_PAGE",
  DELETE_TASK = "@@TASKS_PAGE/DELETE_TASK",
  CHECK_DELETE_TASK = "@@TASKS_PAGE/CHECK_DELETE_TASK",
  SET_DELETE_MODAL_DATA = "@@TASKS_PAGE/SET_DELETE_MODAL_DATA",
  DELETE_TASK_FROM_STATE = "@@TASKS_PAGE/DELETE_TASK_FROM_STATE",
  SET_LOADING_TASK = "@@TASKS_PAGE/SET_LOADING_TASK",
  SET_SEARCH_TASKS = "@@TASKS_PAGE/SET_SEARCH_TASKS",
  SET_FILTERS = "@@TASKS_PAGE/SET_FILTERS",
  SET_SELECTED_USER_ID = "@@TASKS_PAGE/SET_SELECTED_USER_ID",
  SET_TASKS_META = "@@TASKS_PAGE/SET_TASKS_META",
  SET_LOADING_TASKS = "@@TASKS_PAGE/SET_LOADING_TASKS",
  UPDATE_STATUS_WINDOW_TRUE = "@@TASKS_PAGE/UPDATE_STATUS_WINDOW_TRUE",
  UPDATE_STATUS_WINDOW_FALSE = "@@TASKS_PAGE/UPDATE_STATUS_WINDOW_FALSE",
  LOCAL_TIMESHEET_RECORD = "@@TASKS_PAGE/LOCAL_TIMESHEET_RECORD",
  TASK_DONE_CONFIRMATION = "@@TASKS_PAGE/TASK_DONE_CONFIRMATION",
  TIMESHEET_CACHE_RECORD = "@@TASKS_PAGE/TIMESHEET_CACHE_RECORD",
  UPDATE_TASK_STORE = "@@TASKS_PAGE/UPDATE_TASK_STORE",
  UPDATE_TASK = "@@TASKS_PAGE/UPDATE_TASK",
}

export interface getTasksAction extends Action<TasksActionTypes.GET_TASKS> {
  params?: object
  action?: string
}

export interface setTasksListAction
  extends Action<TasksActionTypes.SET_TASKS_LIST> {
  tasks: any[];
  action?: string; 
}

export interface setSortConditionAction
  extends Action<TasksActionTypes.SET_SORT_CONDITION> {
  sortCondition: ISortConditions;
}

export interface setCurrentPageAction
  extends Action<TasksActionTypes.SET_CURRENT_PAGE> {
  currentPage: number;
}
export interface deleteTaskAction extends Action<TasksActionTypes.DELETE_TASK> {
  id: string | number;
}

export interface checkDeleteTaskAction
  extends Action<TasksActionTypes.CHECK_DELETE_TASK> {
  id: string | number;
}

export interface deleteTaskFromStateAction
  extends Action<TasksActionTypes.DELETE_TASK_FROM_STATE> {
  id: string | number;
}

export interface setDeleteModalOpenAction
  extends Action<TasksActionTypes.SET_DELETE_MODAL_DATA> {
  taskId: string | number;
  isDeleteModalOpen: boolean;
  isShowPreloader?: boolean;
  isShowMenuPreloader?: boolean;
  statusFromServer?: { constraints: deleteTaskStatuses[] | undefined };
}

export interface setLoadingTasksAction
  extends Action<TasksActionTypes.SET_LOADING_TASK> {
  isLoading: boolean;
}

export interface setSearchTasksAction
  extends Action<TasksActionTypes.SET_SEARCH_TASKS> {
  searchTasks: string | null;
}
export interface setStatusFilterAction
  extends Action<TasksActionTypes.SET_FILTERS> {
  filterObject: object;
}

export interface setSelectedUserIdAction
  extends Action<TasksActionTypes.SET_SELECTED_USER_ID> {
  id: number;
}

export interface setTasksMetaAction
  extends Action<TasksActionTypes.SET_TASKS_META> {
  tasksMeta: IMeta;
}

export interface updateStatusActionTrue
  extends Action<TasksActionTypes.UPDATE_STATUS_WINDOW_TRUE> {
  statusWindow: boolean;
}

export interface updateStatusActionFalse
  extends Action<TasksActionTypes.UPDATE_STATUS_WINDOW_FALSE> {
  statusWindow: boolean;
}

export interface LocalTimeSheetRecordAction
  extends Action<TasksActionTypes.LOCAL_TIMESHEET_RECORD> {
  actionType: string;
  taskId: number;
  task_load_sum?: number | null;
  timesheetId?: number | null;
  status_id?: number | null;
  commentText?: string;
  commentFiles?: File[];
}

export interface procedureFinishTask
  extends Action<TasksActionTypes.TASK_DONE_CONFIRMATION> {
  show: boolean;
  taskId: number;
  short_version: boolean;
}

export interface timesheetCacheRecordAction
  extends Action<TasksActionTypes.TIMESHEET_CACHE_RECORD> {
  actionType: string;
  task_id?: number;
  task_load_sum?: number;
  status_id?: number;
}

export interface updateTaskStoreAction
  extends Action<TasksActionTypes.UPDATE_TASK_STORE> {
  task: any;
}

export interface updateTaskAction extends Action<TasksActionTypes.UPDATE_TASK> {
  taskId: number | string;
  data: object;
}

export type TasksAction =
  | getTasksAction
  | setTasksListAction
  | setSortConditionAction
  | setCurrentPageAction
  | deleteTaskFromStateAction
  | deleteTaskAction
  | checkDeleteTaskAction
  | deleteTaskFromStateAction
  | setDeleteModalOpenAction
  | setLoadingTasksAction
  | setSearchTasksAction
  | setStatusFilterAction
  | setSelectedUserIdAction
  | setTasksMetaAction
  | updateStatusActionTrue
  | updateStatusActionFalse
  | LocalTimeSheetRecordAction
  | procedureFinishTask
  | timesheetCacheRecordAction
  | updateTaskStoreAction
  | updateTaskAction;
