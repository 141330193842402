import React, { FC } from "react";
import TitleTasks from "../TableHeaderTypicalTasks/TableHeaderTypicalTasks";
import TaskTypical from "./TypicalTask/TypicalTask";
import FiltersTasks from "../FiltersTasks/FiltersTasks";
import { State } from "../../../../rootReducer";
import { connect } from "react-redux";
import PreloaderLocal from "../../../../common/components/PreloaderLocal/PreloaderLocal";
import { ITypicalTaskServerData } from "../../interfaces";
import { IMeta } from "../../../../common/types";
import { getTypicalTasks, setCurrentPage } from "../../actions";

import "./TypicalTasks.scss";

const TypicalTasks: FC<{
  isLoadingProjects: boolean,
  tasks: ITypicalTaskServerData[],
  typicalTasksMeta: IMeta | null,
  getTypicalTasks: (params?: object, action?: string) => void,
  setCurrentPage: (currentPage: number) => void,
  currentPage: number
}> = ({
  isLoadingProjects,
  tasks,
  typicalTasksMeta,
  getTypicalTasks,
  setCurrentPage,
  currentPage
}) => {
  const scrollHandler = (e: React.UIEvent<HTMLDivElement>) => {
    if(!typicalTasksMeta) return;
    
    const { scrollHeight, scrollTop, clientHeight } = e.currentTarget;

    if(scrollHeight <= scrollTop + clientHeight &&
      currentPage < typicalTasksMeta.last_page
    ) {
      setCurrentPage(currentPage + 1);
      getTypicalTasks({}, 'add');
    }
  }

  return (
    <div className="main-typical-task"> 
      <h1 className="main-typical-task__title">Типовые задачи</h1>
      
      <div className="main-typical-task__filters">
        <FiltersTasks />
      </div>
      
      <div className="main-typical-task__content">
        <TitleTasks />

        <div className='main-typical-task__table'>
          <div className={`main-typical-task__preloader-wr 
                          ${isLoadingProjects ? ' main-typical-task__preloader-wr--show' : ''}`
          }>
            <div className="main-typical-task__preloader">
              <PreloaderLocal />
            </div>
          </div>

          <div className="main-typical-task__table-items"
               onScroll={(e) => scrollHandler(e)}
          >
            {tasks?.map((item) => (
              <TaskTypical key={item.id} task={item} />
            ))}
            
            {!tasks.length && (
              <div className='main-typical-task__default'>
                Типовые задачи не найдены...
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: State) => ({
  isLoadingProjects: state.typicalTasksPage.isLoadingTypicalTasks,
  tasks: state.typicalTasksPage.tasks,
  typicalTasksMeta: state.typicalTasksPage.typicalTasksMeta,
  currentPage: state.typicalTasksPage.currentPage
});

const mapDispatchToProps = {
  getTypicalTasks,
  setCurrentPage
};

export default connect(mapStateToProps, mapDispatchToProps)(TypicalTasks);
