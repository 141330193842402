import React, { FC } from "react";
import CommentTab from "../../../../../TaskInfoPlate/components/CommentTab";
import { connect } from "react-redux";
import { State } from "../../../../../../rootReducer";

import './CommentsTab.scss';

const CommentsTab: FC<{
  idObjectEdited?: string|number,
  isUserCanEdit: boolean
}> = ({
  idObjectEdited,
  isUserCanEdit
}) => {
  return (
    <div className='comments-tab'>
      {!isUserCanEdit && (
        <div className="comments-tab--disabled"></div>
      )}
      
      <CommentTab requestFrom='projects' 
                  idRequestFrom={idObjectEdited}
      />
    </div>
  );
};

const mapStateToProps = (state: State) => ({
  idObjectEdited: state.objectsPage.editingObjectsForm.idObjectEdited
});

const mapDispatchToProps = {
  
}

export default connect(mapStateToProps, mapDispatchToProps)(CommentsTab);
