import React, { FC, useEffect, useState } from 'react'
import { State } from '../../../../../rootReducer'
import {ReactComponent as CloseIcon} from '../../../../../img/TrueIcons/close.svg';
import { connect } from 'react-redux'

import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import { deleteItemFromStages, setDeleteItemStageTreatyModal } from '../../../actions';
import { IDeleteStageItemTreatiesModal, IDeleteStageTreatiesModal, IDeleteTypicalTreatiesModal } from '../../../interfaces';
import { deleteStageStatuses } from '../../../constants';
import Fade from '@material-ui/core/Fade';
import PreloaderLocal from '../../../../../common/components/PreloaderLocal/PreloaderLocal';


const ModalDeleteStageItem: FC<{
  isDeleteModalOpen?: boolean,
  deleteItemStageTreatyModal: IDeleteStageItemTreatiesModal,
  setDeleteItemStageTreatyModal: (data: IDeleteStageItemTreatiesModal) => void,
  deleteItemFromStages: (stageId: number | string, itemId: number | string) => void;
}> = ({
  isDeleteModalOpen,
  deleteItemStageTreatyModal,
  setDeleteItemStageTreatyModal,
  deleteItemFromStages
}) => {

  const textObj = {
    [deleteStageStatuses.DELETE_STAGE_ALLOWED]:
      {title: 'Удаление элемента этапа договора', text: 'Элемент этапа договора будет удален. Больше его никто не увидит.<br/>Действие необратимо.', btnDeleteText: 'Удалить'},
    [deleteStageStatuses.DELETE_STAGE_ERROR]:
      {title: 'Удаление элемента этапа договора', text: 'Произошла ошибка при удалении элемента этапа договора...'}
  }

  const [text, setText] = useState<{
    title: string,
    text: string,
    btnDeleteText?: string
  }>(textObj[deleteStageStatuses.DELETE_STAGE_ERROR])

  useEffect(() => {
    if (deleteItemStageTreatyModal.statusFromServer?.constraints.length) {
      const { constraints } = deleteItemStageTreatyModal.statusFromServer;
      const result = textObj[constraints[0]];

      if (result) setText(result);
      else setText(textObj[deleteStageStatuses.DELETE_STAGE_ERROR]);
    }
  }, [deleteItemStageTreatyModal.isDeleteModalOpen])

  const handleClose = () => {
    setDeleteItemStageTreatyModal({isDeleteModalOpen: false});
  }

  const deleteIsTypicalTreaty = () => {
    if(deleteItemStageTreatyModal.stageId && deleteItemStageTreatyModal.itemId){
        deleteItemFromStages(deleteItemStageTreatyModal.stageId, deleteItemStageTreatyModal.itemId);
    }
    setDeleteItemStageTreatyModal({isDeleteModalOpen: false});
  }


  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={'modal-delete-object'}
        open={isDeleteModalOpen ?? false}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={isDeleteModalOpen}>
          <div className={'modal-delete-treaty__content-wr'}>
            <div className="modal-delete-treaty__content">
              <div className="modal-delete-treaty__content-item">
                <div className={"modal-delete-treaty__title-wr"}>
                  <div className='modal-delete-treaty__title'>{text?.title}</div>
                  <div 
                    className='modal-delete-treaty__delete-ico-wr'
                    onClick={handleClose}
                  >
                    <CloseIcon />
                  </div>
                </div>
                <div>
                  <div 
                    className='modal-delete-treaty__text' 
                    dangerouslySetInnerHTML={{__html: text?.text}}
                  />
                </div>
              </div>

              <div className='modal-delete-treaty__btns'>
                {text?.btnDeleteText && (
                  <button className={
                    'modal-delete-treaty__btn modal-delete-treaty__btn-delete'
                    + (deleteItemStageTreatyModal.isShowPreloader 
                      ? ' modal-delete-treaty__btn--show-preloader' 
                      : '')}
                  >
                    <span 
                      className="modal-delete-treaty__btn-title"
                      onClick={deleteIsTypicalTreaty}
                    >
                      {text?.btnDeleteText}
                    </span>
                    <span
                      className="modal-delete-treaty__btn-preloader"
                    >
                      <PreloaderLocal />
                    </span>
                  </button>
                )}

                <button 
                  className="modal-delete-treaty__btn modal-delete-treaty__btn-cancel"
                  onClick={handleClose}
                >
                  <span className="modal-delete-treaty__btn-title">Отмена</span>
                </button>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  )
}

const mapStateToProps = (state: State) => {
  return {
    isDeleteModalOpen: state.typicalTreatyPage.deleteStageItemTreatyModal.isDeleteModalOpen,
    deleteItemStageTreatyModal: state.typicalTreatyPage.deleteStageItemTreatyModal
  }
}

const mapDispatchToProps = {
  deleteItemFromStages,
  setDeleteItemStageTreatyModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalDeleteStageItem)