import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { State } from "../../../../../rootReducer";

import "./ExtraFormBP.scss";
import TooltipQuestion from "../../../../../common/components/ui/TooltipQuestion/TooltipQuestion";
import {ReactComponent as BookIcon} from "../../../../../img/TrueIcons/book.svg";
import { TextareaAutosize } from "@material-ui/core";
import {
  setCurrentPageTypicalTasksBp,
  setExtraFormBPDefaultOpen,
  setSearchTypicalTasksBp,
  setBPForFilterExtra,
} from "../../../actions";
import FilteTypicalTaskForBP from "../../ui/selectors/FilteTypicalTaskForBP";
import { IBPFilterFormExtra } from "../../../interfaces";
import ExtraTypicalTasks from "./components/ExtraTypicalTasks";

const ExtraFormBP: React.FC<{
  setCurrentPageTypicalTasksBp: (mode: number) => void;
  setSearchTypicalTasksBp: (mode: string) => void;
  isExtraFormBPDefaultOpen: boolean;
  setExtraFormBPDefaultOpen: (mode: boolean) => void;
  bpSelect: IBPFilterFormExtra;
  setBPForFilterExtra: (mode: IBPFilterFormExtra) => void;
}> = ({
  setCurrentPageTypicalTasksBp,
  setSearchTypicalTasksBp,
  isExtraFormBPDefaultOpen,
  setExtraFormBPDefaultOpen,
  bpSelect,
  setBPForFilterExtra,
}) => {
  const [value, setValue] = useState();

  useEffect(() => {
    setCurrentPageTypicalTasksBp(1);
  }, [!!value]);

  const handleSearchTypicalTasks = (e) => {
    setValue(e.target.value);
    setSearchTypicalTasksBp(e.target.value);
  };

  const onCloseFormHandler = () => {
    setExtraFormBPDefaultOpen(true);
  };

  return (
    <div className="extra-form-bp">
      <div className="extra-form-bp__content">
        <h2 className="extra-form-bp__title-wr">
          <div className="extra-form__title">
            Формируем состав типовых задач в бизнес-процессе
          </div>
          <div className="block_checkbox-bp__tooltip">
            <TooltipQuestion title={""} />
          </div>
        </h2>
        <div className="extra-form-bp__filters">
          <div className="extra-form-bp__filter">
            <h3 className="extra-form-bp__name-filter">
              Поиск по наименованию и id
            </h3>
            <TextareaAutosize
              className={"search-typical-task-bp"}
              onChange={(e) => {
                handleSearchTypicalTasks(e);
              }}
              value={value}
            />
          </div>
          <div className="extra-form-bp__filter">
            <h3 className="extra-form-bp__name-filter">
              Используются в бизнес-процессах
              {bpSelect.value !== "all" ? (
                <span
                  className="extra-form-bp__bp-select"
                  onClick={() =>
                    setBPForFilterExtra({ value: "all", label: "Не важно" })
                  }
                >
                  (сбросить)
                </span>
              ) : (
                ""
              )}
            </h3>
            {/*  */}
            <FilteTypicalTaskForBP />
          </div>
        </div>
        <ExtraTypicalTasks />
        <div className="extra-form-bp__btns">
          <button
            className="extra-form-bp__btn extra-form__btn--submit"
            onClick={() => onCloseFormHandler()}
          >
            <span className="extra-form-bp__btn-title">Готово</span>
          </button>
        </div>
      </div>

      <div
        className={
          "extra-form-bp__default" +
          (isExtraFormBPDefaultOpen ? " extra-form-bp__default--open" : "")
        }
      >
        <h2 className="extra-form-bp__default-title">
          Настройте бизнес-процесс
        </h2>

        <ul className="extra-form-bp__default-text">
          <li className="extra-form-bp__default-text-item">
           <span className="extra-form-bp__default-text-item__text">Формируйте список типовых задач в бизнес процессе.</span> <br/> Воспользуйтесь
            ссылкой "Добавить типовую задачу" в левой части страницы, чтобы
            обратиться к списку доступных типовых задач.
          </li>
          <li className="extra-form-bp__default-text-item">
           <span className="extra-form-bp__default-text-item__text">Настраивате порядок задач в бизнес-процессе.</span> <br/> Схватите мышью любую
            задачу и переместите ее нужное место в бизнес-процессе.
          </li>
          <li className="extra-form-bp__default-text-item">
           <span className="extra-form-bp__default-text-item__text">Настраивате связи между задачами.</span> <br/> Схватите мышью любую задачу и
            переместите ее на другую задачу для формирования параллельной связи.
          </li>
          <li className="extra-form-bp__default-text-item">
           <span className="extra-form-bp__default-text-item__text">Формируйте стеки подзадач.</span>  <br/>Схватите мышью любую задачу и переместите
            ее на другую задачу для формирования стека подзадач.
          </li>
          <li className="extra-form-bp__default-text-instruction">
            <div className="extra-form-bp__instruction">
            <BookIcon/>
            <a className="extra-form-bp__instruction__text" href="">Подробная инструкция о настройке бизнес-процессов</a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

const mapStateToProps = (state: State) => {
  return {
    isExtraFormBPDefaultOpen:
      state.businessPage.extraFormBP.isExtraFormBPDefaultOpen,
    bpSelect: state.businessPage.extraFormBP.bpForFilter,
  };
};

const mapDispatchToProps = {
  setCurrentPageTypicalTasksBp,
  setSearchTypicalTasksBp,
  setExtraFormBPDefaultOpen,
  setBPForFilterExtra,
};

export default connect(mapStateToProps, mapDispatchToProps)(ExtraFormBP);
