import React, { FC, useEffect, useState } from "react";
import { State } from "../../../../../../rootReducer";
import { connect } from "react-redux";
import moment from "moment";
import { ReactComponent as DotsIcon } from "../../../../../../img/TrueIcons/dots.svg";
import { BusinessProcessActivity, IBusinessProcessServerData } from "../../../../interfaces";

import "./BusinessProcess.scss";
import MenuBusinessProcess from "../../../ui/MenuBusinessProcess/MenuBusinessProcess";
import { getOneBP, setBPFormOpen, setIsEditForm } from "../../../../actions";
import { getDuration } from "../../../../helpers/helpersCommon";

const BusinessProcess: FC<{
  bp: IBusinessProcessServerData;
  currentPage: number;
  setBPFormOpen: (mode: boolean) => void;
  setIsEditForm: (mode: boolean) => void;
  getOneBP: (id: string|number) => void,
}> = ({
  bp,
  currentPage,
  setBPFormOpen,
  setIsEditForm,
  getOneBP
}) => {
  const formatDate = (date) => moment(date).format("DD.MM.YYYY");
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const [authorName, setAuthorName] = useState<string>(
    bp.author?.surname + " " + bp.author?.name
  );
  const [executorName, setExecutorName] = useState<string>(
    bp.executor?.surname + " " + bp.executor?.name
  );
  const [bpCount, setBpCount] = useState<number>(0);
  const [bpDuration, setBpDuration] = useState<number|string>(0);

  const openEditHandler = (id: string | number) => {
    getOneBP(id);
    setBPFormOpen(true);
    setIsEditForm(true);
  };

  const handleSetAnchorEl = (value) => {
    setAnchorEl(value);
  };

  useEffect(() => {
    setAuthorName(bp.author?.surname + " " + bp.author?.name);
  }, [bp.author?.name, bp.author?.surname]);

  useEffect(() => {
    setExecutorName(bp.executor?.surname + " " + bp.executor?.name);
  }, [bp.executor?.name, bp.executor?.surname]);

  const clickBpActionsOpenHandler = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    if(bp.typical_tasks?.length) {
      setBpCount(bp.typical_tasks.length);
      
      let duration: any = 0;

      bp.typical_tasks.forEach(task => {
        if(task.time) {
          duration = moment.duration(duration).add(task.time);
        }
        
        if(task.children?.length) {
          setBpCount(bp.typical_tasks.length + task.children.length);

          task.children.forEach(child => {
            if(child.time) {
              duration = moment.duration(duration).add(child.time);
            }
          })
        }
      });
      
      setBpDuration(getDuration(duration));
    }
  }, [bp]);

  return (
    <div className="business_process" onClick={() => openEditHandler(bp.id)}>
      <div className="business_process_id">{bp.id}</div>
      <div className="business_process_name" title={bp.name}>
        {bp.name}
      </div>
      <div className="business_process_treaty">
        {bp?.treaty?.length ? (
          bp.treaty.map((item) => {
            return (
              <div
                key={item.id}
                title={item.name}
                className="business_process-treaty_name"
              >
                {item.name}
              </div>
            );
          })
        ) : (
          <span>&mdash;</span>
        )}
      </div>
      
      <div className="business_process_all-tasks">
        {bpCount}
      </div>
      
      <div className="business_process_duration">
        {bpDuration}
      </div>
      
      <div className="business_process_author">
        <div className="business_process__avatar">
          {bp.author?.image && (
            <div className="business_process__avatar-img-wr">
              <img
                className="business_process__avatar-img"
                src={bp.author.image}
                alt={authorName}
              />
            </div>
          )}

          {!bp.author?.image && (
            <div className="business_process__avatar-text">
              {bp.author?.surname.substring(0, 1)}
              {bp.author?.name.substring(0, 1)}
            </div>
          )}
        </div>
        {authorName}
      </div>

      <div className="business_process_date">{formatDate(bp?.created_at)}</div>

      <div className="business_process_activity">
        {bp.status
          ? BusinessProcessActivity.ACTIVE_TEXT_FORM
          : BusinessProcessActivity.NOT_ACTIVE_TEXT_FORM}
      </div>

      <div className="business_process__actions">
        <button
          className="business_process__actions-btn"
          onClick={(e) => clickBpActionsOpenHandler(e)}
        >
          <DotsIcon />
        </button>
      </div>

      <MenuBusinessProcess
      id={bp.id}
      anchorEl={anchorEl}
      setAnchorEl={handleSetAnchorEl}
    />
    </div>
  );
};

const mapStateToProps = (state: State) => ({
  currentPage: state.businessPage.currentPage,
});

const mapDispatchToProps = {
  setBPFormOpen,
  setIsEditForm,
  getOneBP
};

export default connect(mapStateToProps, mapDispatchToProps)(BusinessProcess);
