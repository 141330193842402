export enum TabIdsObjectEditingForm {
  DESCRIPTION_TAB,
  COMMENTS_TAB,
  DOCUMENTS_TAB,
  HISTORY_TAB
}

export enum sortOrders {
  ASC = 1,
  DESC = 2
}

export enum sortConditions {
  ID = 'id',
  DAYS = "agreement_days_left",
  NAME = 'name',
  TREATY = "agreement_number",
  EXECUTOR = 'project_manager_name',
  PROGRESS = "progress",
  IN_WORK = "in_work",
  COMPLETED = "completed_tasks_count",
  OVERDUE = "overdue_tasks_count",
  STATUS = "status"
}

export enum deleteObjectStatuses {
  DELETE_OBJECT = 0,
  DELETE_OBJECT_ALLOWED = 'allowed',
  DELETE_OBJECT_ERROR = 'error'
}

export enum messagesObjects {
  DELETE_OBJECT_ERROR = 'Произошла ошибка при удалении объекта...',
  DELETE_OBJECT_SUCCESS = 'Объект удален',
  CREATE_OBJECT_SUCCESS = 'Объект успешно создан',
  UPDATE_OBJECT_SUCCESS = 'Объект успешно обновлен'
}

export enum messagesObjectTask {
  DELETE_OBJECT_TASK_ERROR = 'Произошла ошибка при удалении задачи...',
  DELETE_OBJECT_TASK_SUCCESS = 'Задача удалена',
  CREATE_OBJECT_TASK_SUCCESS = 'Задача успешно создана',
  UPDATE_OBJECT_TASK_SUCCESS = 'Задача успешно обновлена'
}

export enum checkAddObjectStatuses {
  ADD_OBJECT = 0,
  ADD_OBJECT_ALLOWED = 'allowed',
  ADD_OBJECT_ERROR = 'errorAdd',
  UPDATE_OBJECT_ERROR = 'errorUpdate'
}

export enum priorityNames {
  LOW = 'Низкий',
  MEDIUM = 'Средний',
  HIGH = 'Высокий',
  URGENT = 'Срочный'
}

export enum priorityValues {
  LOW = 1,
  MEDIUM = 2,
  HIGH = 3,
  URGENT = 4
}

export enum errorValidationMessages {
  MIN1 = 'Не менее 1 символа',
  MAX4 = 'Не более 4х символов',
  MAX100 = 'Не более 100 символов',
  MAX200 = 'Не более 200 символов',
  MAX500 = 'Не более 500 символов',
  REQUIRED = 'Поле обязательно для заполнения'
}

export enum statusObject {
  IN_WORK_STATUS = 10, //в работе
  COMPLETED_STATUS = 13, //13 - завершено
  MADE_STATUS = 12, //12 - выполнено
  DRAFT_STATUS = 18, //18 - черновик
  CANCELED_STATUS = 11, //11 - отменено
}

export enum CheckContextsObjects {
  IS_USER_CAN_ADD_OBJECT = 'IS_USER_CAN_ADD_OBJECT',
  IS_USER_CAN_ADD_TASK = 'IS_USER_CAN_ADD_TASK',
  IS_USER_CAN_DELETE_TASK = 'IS_USER_CAN_DELETE_TASK',
  IS_USER_CAN_DELETE_OBJECT = 'IS_USER_CAN_DELETE_OBJECT',
  IS_USER_CAN_EDIT_TASK = 'IS_USER_CAN_DELETE_OBJECT',
  IS_USER_CAN_EDIT_OBJECT = 'IS_USER_CAN_EDIT_OBJECT',
}

export enum userRolesMeridian {
  ADMIN = 1,
  DIRECTOR = 2,
  CURATOR = 3,
  CPE = 4,
  HEAD_OF_DEPARTMENT = 5,
  TEAM_LEADER = 6,
  EXECUTOR = 7
}
