import { taskBPTypes, taskConnectActions, taskConnectStatuses } from "../constants";
import { childrenMove, parentWithChildsMove } from "./helpersCore";
import { ITaskFlattenBP } from "../interfaces";

export const getTasksConnectionStatus = (currentIndex, overIndex, items) => {
  return checkConnectionStatus(currentIndex, overIndex, items) ?? taskConnectStatuses.STATUS_ERROR;
}

export const getConnections = (actionConnectId, currentIndex, overIndex, items) => {
  const newState = [...items];

  const isCurrentParent = items[currentIndex].isParent;
  const isOverParent = items[overIndex].isParent;

  if(
    actionConnectId === taskConnectActions.ACTION_ERROR ||
    actionConnectId === taskConnectActions.ACTION_CANCEL
  ) {
    newState[currentIndex] = {
      ...newState[currentIndex],
      controlledPosition: {y: 0, x: 0}
    };
  }

  /* Для родителей */
  
  // Перетаскивания родителя на родителя или ребенка на родителя
  if(isCurrentParent || (!isCurrentParent && isOverParent)) {
    // Сделать параллельной с задачей «В» или
    // Добавить в группу параллельных задач
    if(
      actionConnectId === taskConnectActions.ACTION_1 ||
      actionConnectId === taskConnectActions.ACTION_3 ||
      actionConnectId === taskConnectActions.ACTION_5 ||
      actionConnectId === taskConnectActions.ACTION_7 ||
      actionConnectId === taskConnectActions.ACTION_13 ||
      actionConnectId === taskConnectActions.ACTION_15 ||
      actionConnectId === taskConnectActions.ACTION_17 ||
      actionConnectId === taskConnectActions.ACTION_19
    ) {
      let result: any = [];

      newState[overIndex].taskType = taskBPTypes.PARALLEL;
      
      // Перетаскивания родителя на родителя
      if(isCurrentParent) {
        newState[currentIndex] = {
          ...newState[currentIndex],
          controlledPosition: {y: 0, x: 0}
        };

        result = parentWithChildsMove(newState, isCurrentParent, currentIndex, overIndex);
      }

      // Перетаскивания ребенка на родителя
      if(!isCurrentParent) {
        result = childrenMove(newState, currentIndex, overIndex, 'parallel');
      }
      
      return result
    }

    // Сделать подзадачей для задачи «В»
    if(
      actionConnectId === taskConnectActions.ACTION_2 ||
      actionConnectId === taskConnectActions.ACTION_4 ||
      actionConnectId === taskConnectActions.ACTION_6 ||
      actionConnectId === taskConnectActions.ACTION_8 ||
      actionConnectId === taskConnectActions.ACTION_14 ||
      actionConnectId === taskConnectActions.ACTION_16 ||
      actionConnectId === taskConnectActions.ACTION_18 ||
      actionConnectId === taskConnectActions.ACTION_20
    ) {
      newState[currentIndex] = {
        ...newState[currentIndex],
        controlledPosition: {y: 0, x: 0}
      };
      
      let res: any = [];
      
      if(newState[currentIndex].isParent) {
        res = childrenMove(newState, currentIndex, overIndex, 'simpleMove');
      }
      else {
        res = childrenMove(newState, currentIndex, overIndex, 'subtaskChildren');
      }

      return res;
    }
  }

  /* Для дочерних элементов */
  
  // Перетаскивание дочернего элемента на дочерний или родительского на дочерний
  if(!isCurrentParent || (isCurrentParent && !isOverParent)) {
    // Сделать параллельной с задачей «В» или
    // Добавить в группу параллельных задач
    if(
      actionConnectId === taskConnectActions.ACTION_9 ||
      actionConnectId === taskConnectActions.ACTION_10 ||
      actionConnectId === taskConnectActions.ACTION_11 ||
      actionConnectId === taskConnectActions.ACTION_12 || 
      actionConnectId === taskConnectActions.ACTION_21 ||
      actionConnectId === taskConnectActions.ACTION_22
    ) {
      newState[overIndex].taskType = taskBPTypes.PARALLEL;

      newState[currentIndex] = {
        ...newState[currentIndex],
        controlledPosition: {y: 0, x: 0}
      };

      let res: any = [];

      res = childrenMove(newState, currentIndex, overIndex, 'simpleMove');
      
      return res;
    }
  }

  return newState;
}

/* Local fn */

const getUpParent = (
  overIndex: number,
  items: ITaskFlattenBP[]
): ITaskFlattenBP|null => {
  let parent: any = null;
  const parentIndex = items[overIndex]?.parentIndex;

  items = items.filter(item => item.isParent);

  items.forEach((item, i) => {
    if(item.parentIndex === parentIndex) {
      parent = items[i - 1] ?? null;
    }
  });

  return parent;
}

export const checkActionAllowedCenter = (status, currentIndex, overIndex, items): boolean => {
  let isAllowed = false;
  
  if(actionParentWithChildsCenterRestricted(status, currentIndex, overIndex, items)) isAllowed = true;
  
  return isAllowed;
}

const checkConnectionStatus = (currentIndex, overIndex, items) => {
  const isCurrentParent = items[currentIndex].isParent;
  const isOverParent = items[overIndex].isParent;
  
  /* Для родителей */

  // Перетаскивания родителя на родителя или ребенка на родителя
  if((isCurrentParent && isOverParent) || (!isCurrentParent && isOverParent)) {
    if(statusSerialSerial(currentIndex, overIndex, items)) return taskConnectStatuses.STATUS_1;
    if(statusSerialParallel(currentIndex, overIndex, items)) return taskConnectStatuses.STATUS_2;
    if(statusParallelSerial(currentIndex, overIndex, items)) return taskConnectStatuses.STATUS_3;
    if(statusParallelParallel(currentIndex, overIndex, items)) return taskConnectStatuses.STATUS_4;
    
    /* Границы */
    
    if(statusBoundarySerial(currentIndex, overIndex, items)) return taskConnectStatuses.STATUS_9;
    if(statusBoundaryParallel(currentIndex, overIndex, items)) return taskConnectStatuses.STATUS_10;
    if(statusSerialBoundary(currentIndex, overIndex, items)) return taskConnectStatuses.STATUS_11;
    if(statusParallelBoundary(currentIndex, overIndex, items)) return taskConnectStatuses.STATUS_12;
  }
  
  /* Для дочерних элементов */
  
  // Перетаскивание дочернего элемента на дочерний или родительского на дочерний
  if(!isCurrentParent || (isCurrentParent && !isOverParent)) {
    if(statusChildSerialSerial(currentIndex, overIndex, items)) return taskConnectStatuses.STATUS_5;
    if(statusChildSerialParallel(currentIndex, overIndex, items)) return taskConnectStatuses.STATUS_6;
    if(statusChildParallelSerial(currentIndex, overIndex, items)) return taskConnectStatuses.STATUS_7;
    if(statusChildParallelParallel(currentIndex, overIndex, items)) return taskConnectStatuses.STATUS_8;

    /* Границы */

    if(statusChildSerialBoundary(currentIndex, overIndex, items)) return taskConnectStatuses.STATUS_13;
    if(statusChildParallelBoundary(currentIndex, overIndex, items)) return taskConnectStatuses.STATUS_14;
  }
  
  return taskConnectStatuses.STATUS_ERROR;
}

/* Пояснения ТЗ, действуют для всех проверок:
* currentIndex (индекс в массиве items) - Задача А - перемещаемая задача
* overIndex (индекс в массиве items) - Задача В - задача, на которую перемещаем
* upElement - задача, позиция которой СВЕРХУ той задачи, на которую перемещаем
* downElement - задача, позиция которой СНИЗУ той задачи, на которую перемещаем
* Дай бог сил тому, кто будет со все этим разбираться :)
* Перемещаемая задача всегда встает под ту задачу, на которую перемещаем
* Значком «|» обозначена последовательная связь - SERIAL
* Значком «||» - параллельная - PARALLEL
* Значком «-» - граница сверху или снизу
* 
* Первая подзадача всегда имеет параллельную связь с родительской задачей,
* которую нельзя разорвать, потому что они на деле ВСЕГДА будут запускать одновременно
* */

/* Для родительских задач */

// Перемещение позиции на задачу первого уровня, у которой Сверху «|» Снизу «|», status = 1
const statusSerialSerial = (currentIndex, overIndex, items) => {
  const upElement = getUpParent(overIndex, items);
  const downElement = items[overIndex + 1];

  if(!upElement || !downElement) return false;

  return upElement.taskType === taskBPTypes.SERIAL && 
          downElement.taskType === taskBPTypes.SERIAL;
}

// Перемещение позиции на задачу первого уровня: Сверху «|» Снизу «||», status = 2
const statusSerialParallel = (currentIndex, overIndex, items) => {
  const upElement = getUpParent(overIndex, items);
  const downElement = items[overIndex + 1];

  if(!upElement || !downElement) return false;

  return upElement.taskType === taskBPTypes.SERIAL && 
          downElement.taskType === taskBPTypes.PARALLEL;
}

// Перемещение на задачу первого уровня: Сверху «||» Снизу «|», status = 3
const statusParallelSerial = (currentIndex, overIndex, items) => {
  const upElement = getUpParent(overIndex, items);
  const downElement = items[overIndex + 1];

  if(!upElement || !downElement) return false;

  return upElement.taskType === taskBPTypes.PARALLEL && 
          downElement.taskType === taskBPTypes.SERIAL;
}

// Перемещение на задачу первого уровня: Сверху «||» Снизу «||», status = 4
const statusParallelParallel = (currentIndex, overIndex, items) => {
  const upElement = getUpParent(overIndex, items);
  const downElement = items[overIndex + 1];

  if(!upElement || !downElement) return false;

  return upElement.taskType === taskBPTypes.PARALLEL && 
          downElement.taskType === taskBPTypes.PARALLEL;
}

/* Границы */

// Перемещение на задачу первого уровня, у которой Сверху «-» Снизу «|», status = 9
const statusBoundarySerial = (currentIndex, overIndex, items) => {
  const upElement = getUpParent(overIndex, items);
  const downElement = items[overIndex + 1];

  if(!downElement) return false;

  return !upElement && downElement.taskType === taskBPTypes.SERIAL;
}

// Перемещение на задачу первого уровня: Сверху «-» Снизу «||», status = 10
const statusBoundaryParallel = (currentIndex, overIndex, items) => {
  const upElement = getUpParent(overIndex, items);
  const downElement = items[overIndex + 1];

  if(!downElement) return false;

  return !upElement && downElement.taskType === taskBPTypes.PARALLEL;
}

// Перемещение на задачу первого уровня: Сверху «|» Снизу «-», status = 11
const statusSerialBoundary = (currentIndex, overIndex, items) => {
  const upElement = getUpParent(overIndex, items);
  const downElement = items[ overIndex + 1 ];
  
  if (!upElement) return false;

  return upElement.taskType === taskBPTypes.SERIAL && !downElement;
}

// Перемещение на задачу первого уровня: Сверху «||» Снизу «-», status = 12
const statusParallelBoundary = (currentIndex, overIndex, items) => {
  const upElement = getUpParent(overIndex, items);
  const downElement = items[ overIndex + 1 ];
  
  if (!upElement) return false;

  return upElement.taskType === taskBPTypes.PARALLEL && !downElement;
}

/* Для дочерних задач */

// Перемещение на задачу второго уровня, у которой Сверху «|» Снизу «|», status = 5
const statusChildSerialSerial = (currentIndex, overIndex, items) => {
  const upElement = items[ overIndex - 1 ];
  const downElement = items[ overIndex + 1 ];

  if(!upElement || !downElement) return false;

  return upElement.taskType === taskBPTypes.SERIAL &&
          downElement.taskType === taskBPTypes.SERIAL;
}

// Перемещение на задачу второго уровня: Сверху «|» Снизу «||», status = 6
const statusChildSerialParallel = (currentIndex, overIndex, items) => {
  const upElement = items[ overIndex - 1 ];
  const downElement = items[ overIndex + 1 ];

  if(!upElement || !downElement) return false;

  return upElement.taskType === taskBPTypes.SERIAL &&
          downElement.taskType === taskBPTypes.PARALLEL;
}

// Перемещение на задачу второго уровня: Сверху «||» Снизу «|», status = 7
const statusChildParallelSerial = (currentIndex, overIndex, items) => {
  const upElement = items[ overIndex - 1 ];
  const downElement = items[ overIndex + 1 ];

  if(!upElement || !downElement) return false;

  return upElement.taskType === taskBPTypes.PARALLEL &&
          downElement.taskType === taskBPTypes.SERIAL;
}

// Перемещение на задачу второго уровня: Сверху «||» Снизу «||», status = 8
const statusChildParallelParallel = (currentIndex, overIndex, items) => {
  const upElement = items[ overIndex - 1 ];
  const downElement = items[ overIndex + 1 ];

  if(!upElement || !downElement) return false;

  return upElement.taskType === taskBPTypes.PARALLEL &&
          downElement.taskType === taskBPTypes.PARALLEL;
}

/* Границы */

// Перемещение на задачу второго уровня, у которой Сверху «|» Снизу «-», status = 13
const statusChildSerialBoundary = (currentIndex, overIndex, items) => {
  const upElement = items[ overIndex - 1 ];
  const downElement = items[ overIndex + 1 ];

  if (!upElement) return false;

  return upElement.taskType === taskBPTypes.SERIAL && !downElement;
}

// Перемещение на задачу второго уровня, у которой: Сверху «||» Снизу «-», status = 14
const statusChildParallelBoundary = (currentIndex, overIndex, items) => {
  const upElement = items[ overIndex - 1 ];
  const downElement = items[ overIndex + 1 ];
  
  if (!upElement) return false;

  return upElement.taskType === taskBPTypes.PARALLEL && !downElement;
}

/* Проверка ограничений для действий */

// Попытка переместить родителя с дочерними элементами в другого родителя, для связей центр
const actionParentWithChildsCenterRestricted = (action, currentIndex, overIndex, items) => {
  let isAllowed = true;
  const isLastElement = ((items?.length - 1) === currentIndex);

  const allowedActions = [
    taskConnectActions.ACTION_1,
    taskConnectActions.ACTION_3,
    taskConnectActions.ACTION_5,
    taskConnectActions.ACTION_7,
    taskConnectActions.ACTION_13,
    taskConnectActions.ACTION_15,
    taskConnectActions.ACTION_17,
    taskConnectActions.ACTION_19
  ];
  
  const currentElement = items[currentIndex];
  
  // Если перетаскиваемый элемент - не последний
  if(!isLastElement) {
    if(!currentElement || !items[currentIndex + 1]) return false;

    if(
      (currentElement.isParent && !items[currentIndex + 1].isParent) &&
      !allowedActions.includes(action)
    ) {
      isAllowed = false;
    }
  }
  
  return isAllowed;
}
