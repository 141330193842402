import React from "react";
import { State } from "../../../../../rootReducer";
import { connect } from "react-redux";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import styled from "styled-components";
import Trash from "../../../../../img/projectIcons/Trash";
import PreloaderLocal from "../../../../../common/components/PreloaderLocal/PreloaderLocal";
import { checkDeleteTreaty } from "../../../actions";
import { IDeleteTypicalTreatiesModal } from "../../../interfaces";

import "./MenuTypicalTreatiesItems.scss"

interface ITypicalTreatiesItemsMenuProps {
  id: number | string;
  anchorEl: HTMLElement | null;
  setAnchorEl: (arg0: React.MouseEvent<HTMLButtonElement> | null) => void;
  deleteTypicalTreatiesModal: IDeleteTypicalTreatiesModal;
  checkDeleteTreaty: (id: string|number) => void,
}

const MenuStyle = {
  marginTop: "45px",
};

const MenuItemStyle = {
  display: "flex",
  alignItems: "center",
  marginBottom: "5px",
  fontSize: "14px",
};

const MenuIcon = styled.div`
  margin-right: 10px;
  display: flex;
  align-items: center;

  > svg {
    stroke: #292a34;
  }
`;

const MenuTitle = styled.div``;

const MenuTypicalTreatiesItems = ({
  id,
  anchorEl,
  setAnchorEl,
  deleteTypicalTreatiesModal,
  checkDeleteTreaty,
}: ITypicalTreatiesItemsMenuProps) => {

  const clickTaskActionsCloseHandler = () => {
    setAnchorEl(null);
  };

  const clickDeleteHandler = (id: string | number, e: any) => {
    checkDeleteTreaty(id)
    setAnchorEl(null);
    e.stopPropagation();
  };

  
  return (
    <Menu
    className="typical-treaties-items-menu"
    anchorEl={anchorEl}
    open={!!anchorEl}
    onClose={(e: any) => {
      clickTaskActionsCloseHandler();
      e.stopPropagation();
    }}
    style={MenuStyle}
  >
    <MenuItem
      style={{ ...MenuItemStyle, marginBottom: 0 }}
      onClick={(e) => clickDeleteHandler(id, e)}
    >
      <MenuIcon>
        <Trash />
      </MenuIcon>

      <MenuTitle className={
        "typical-treaties-items-menu__title-wr"
        + (deleteTypicalTreatiesModal.isShowPreloader 
          ? " typical-treaties-items-menu__title-wr--show-preloader" 
          : "")
        }
      >
        <div className="typical-treaties-items-menu__title">
          Удалить типовой договор
        </div>
        <div className='typical-treaties-items-menu__preloader'>
          <PreloaderLocal />
        </div>
      </MenuTitle>
    </MenuItem>
  </Menu>
  );
};

const mapStateToProps = (state: State) => ({
  isDeleteModalOpen: 
    state.typicalTreatyPage.deleteTypicalTreatiesModal.isDeleteModalOpen,
    deleteTypicalTreatiesModal: state.typicalTreatyPage.deleteTypicalTreatiesModal,
});

const mapDispatchToProps = {
  checkDeleteTreaty
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MenuTypicalTreatiesItems);
