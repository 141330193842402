import React, { FC, useEffect, useState } from 'react';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { connect } from "react-redux";
import { State } from "../../../../../rootReducer";
import {ReactComponent as CloseIcon} from '../../../../../img/TrueIcons/close.svg';
import { deleteFieldsStatuses } from "../../../constants";

import './ModalDeleteFields.scss';

const ModalDeleteFields: FC<{
  isDeleteModalOpen?: boolean,
  setDeleteFieldsModalData: (mode: boolean) => void,
  deleteFields: (id: string, fieldType: string) => void,
  type: string,
  id: string,
}> = ({
  isDeleteModalOpen,
  deleteFields,
  setDeleteFieldsModalData,
  type,
  id
}) => {
  const textObj = {
    [deleteFieldsStatuses.DELETE_FIELD_ALLOWED]:
      {title: 'Удаляем дополнительное поле', text: 'Поле будет удалено из формы исполнения задачи. <br/>Действие необратимо.', btnDeleteText: 'Удалить'},
  };
  
  const [text, setText] = useState<{
    title: string,
    text: string,
    btnDeleteText?: string
  }>(textObj[deleteFieldsStatuses.DELETE_FIELD_ALLOWED]);

  const handleClose = () => {
    setDeleteFieldsModalData(false);
  };

  const deleteTaskHandler = () => {
    deleteFields(id, type);
  };
  
  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={'modal-delete-field'}
        open={isDeleteModalOpen ?? false}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={isDeleteModalOpen}>
          <div className={'modal-delete-field__content-wr'}>
            <div className="modal-delete-field__content">

              <div className="modal-delete-field__content-item">
                <div className={'modal-delete-field__title-wr'}>
                  <div className="modal-delete-field__title">{text?.title}</div>
                  <div className="modal-delete-field__delete-ico-wr"
                       onClick={handleClose}
                  >
                    <CloseIcon />
                  </div>
                </div>

                <div className="modal-delete-field__text"
                     dangerouslySetInnerHTML={{__html: text?.text}}
                >
                </div>
              </div>
            </div>
            
            <div className="modal-delete-field__btns">
              {text?.btnDeleteText &&
                <button className={"modal-delete-field__btn modal-delete-field__btn--delete "}>
                <span className="modal-delete-field__btn-title"
                      onClick={deleteTaskHandler}
                >{text?.btnDeleteText}</span>
                </button>
              }
              
              <button className="modal-delete-field__btn modal-delete-field__btn--cancel"
                      onClick={handleClose}
              >
                <span className="modal-delete-field__btn-title">Отмена</span>
              </button>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
}

const mapStateToProps = (state: State) => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ModalDeleteFields);
