import { cookieMaster } from "./../../utils/CookieMaster";
import { fetchData } from "./../../utils/fetchData";
import { takeLatest, call, put } from "redux-saga/effects";
import {
  types,
  fetchTokenAction,
  fetchCreateCompanyAction,
} from "./action_types";
import { setPlateEditMode } from "../Company/actions";
import axios from "axios";

export function* watchFetchToken() {
  yield takeLatest(types.FETCH_TOKEN, fetchToken);
}

export function* watchFetchCreateCompany() {
  yield takeLatest(types.FETCH_CREATE_COMPANY, fetchCreateCompany);
}

function* fetchCreateCompany({
  dataCompany,
  employees = [],
}: fetchCreateCompanyAction) {
  // console.log("dataCompany", dataCompany);

  if (dataCompany?.logo instanceof File) {
    let formData = new FormData();
    formData.append("file", dataCompany?.logo);
    const image = yield call(fetchData.post, "/api/v1/files", formData, {
      Authorization: cookieMaster.getCookie("access_token"),
    });
    if (image) {
      dataCompany.logo = image.id;
    }
  }

  if (dataCompany["id"] && dataCompany["id"] !== 0) {
    if (dataCompany["logo"] instanceof Object)
      dataCompany.logo = dataCompany.logo = dataCompany?.logo?.id;
    // update
    const updated = yield call(
      fetchData.patch,
      `/api/v1/companies/${dataCompany["id"]}`,
      JSON.stringify(dataCompany)
    );
    if (updated) {
      yield put(setPlateEditMode(false));
    }

    yield window.location.reload();
  } else {
    let data = { ...dataCompany };
    delete data["plateEditMode"];

    const company = yield call(
      fetchData.post,
      "/api/v1/companies",
      JSON.stringify(data)
    );
    if (company?.id) {
      console.log("company", company);

      yield localStorage.setItem("company_id", company.id);
      if (Object.keys(employees).length) {
        yield employees.forEach((el, i) => {
          fetchData.post(`/api/v1/users`, JSON.stringify(el), {
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/json",
            Authorization: `${cookieMaster.getCookie("access_token")}`,
            "company-id": `${company.id}`,
          });
        });
      }
      yield window.location.replace("/");
    }
  }
}

function* fetchToken({ email, password }: fetchTokenAction) {
  cookieMaster.deleteCookie("access_token");
  cookieMaster.deleteCookie("access_token_jwt");
  cookieMaster.deleteCookie("login");
  cookieMaster.deleteCookie("company_id");
  cookieMaster.deleteCookie("user_id");

  let body = {
    email,
    password,
  };

  let data;

  try {
    data = yield call(fetchData.post, "/api/v1/login", JSON.stringify(body), {
      "Content-Type": "application/x-www-form-urlencoded",
    });
  } catch (error) {
    console.warn(error);
  }

  if (data?.access_token) {
    document.cookie = `access_token=${`${data.token_type} ${data.access_token}`}; path=/; expires=${
      data.expires_in
    }`;
    document.cookie = `user_id=${data.user_id}; path=/; expires=${data.expires_in}`;

    localStorage.setItem("access_token", data.access_token);
    localStorage.setItem("company_id", data.company_id);
    localStorage.setItem("user_id", data.user_id);
    localStorage.setItem("release_version", "3.1");

    fetch(`${process.env.REACT_APP_PUBLIC_API}/login`, {
      method: "POST",
      body: JSON.stringify({
        email: email,
        password: password,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        // console.log("res", res);
        // document.cookie = `access_token=${res.access_token}`;

        let configForOneUser = {
          method: "get",
          url: `${process.env.REACT_APP_PUBLIC_API}/users/${res.user_id}`,
          headers: {
            Authorization: `Bearer ${res.access_token}`,
            company_id: res.company_id,
          },
        };

        axios(configForOneUser).then(async (response) => {
          let user = response.data.data;
          let isAdmin = user.isAdmin;

          // Получение 2 токена- access_token_jwt
          /********************** */

          let user_id_string = localStorage.getItem("user_id");
          let user_id = user_id_string;

          // url: 'https://bpway.easy-task.ru/api/v1/login_and_get_key?isAuth=true&secret_key=kN6Gd9LpZBhpMbZNzVlAOsXLkIB7cZS36McLID2W7LJOU2GiweRxpuchNoiEg8ND8PI5Pf_easyTask&userId=756&isAdmin=true',

          let config = {
            method: "post",
            url: `${process.env.REACT_APP_BACKEND}/api/v1/login_and_get_key?isAuth=true&secret_key=kN6Gd9LpZBhpMbZNzVlAOsXLkIB7cZS36McLID2W7LJOU2GiweRxpuchNoiEg8ND8PI5Pf_easyTask&userId=${user_id}&isAdmin=${isAdmin}`,
            headers: {},
          };

          await axios(config)
            .then((response) => {
              // console.log("Успешно!!!");
              // console.log("response",response);
              localStorage.setItem("access_token_jwt", response.data);
              document.cookie = `access_token_jwt=${response.data}`;

              setTimeout(() => {
                window.location.replace("/");
              }, 1000);
            })
            .catch(function (error) {
              console.log(error);
            });
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  }
}
