import { Reducer } from "redux";
import { FormEditTaskAction, FormEditTaskActionTypes } from "./action_types";
import { IObjectsFormEditTaskState } from "./interfaces";
import { priorityNames, priorityValues } from "../../constants";
import { TabIdsObjectEditTaskForm } from "./constants";

const initialState: IObjectsFormEditTaskState = {
  isLoadingTask: false,
  isCreatingTask: false,
  isFormOpen: false,
  // isFormOpen: true,
  // activeTabIndex: TabIdsObjectEditTaskForm.EXECUTION_TAB,
  activeTabIndex: TabIdsObjectEditTaskForm.DESCRIPTION_TAB,
  checkTrigger: '',
  objects: [],
  check: {
    responsibilityIdObject: '',
    authorIdObject: ''
  },
  data: {
    project_id: '',
    project_section_id: '',
    author: {
      id: '',
      surname: '',
      name: ''
    },
    commentsCount: '',
    createdAt: '',
    taskId: '',
    status_id: '',
    duration: '',
    objects: {
      isLoading: false,
      selectedValue: {value: '', label: 'Введите значение...'},
      data: []
    },
    stages: {
      isLoading: false,
      selectedValue: {value: '1', label: 'ПИР'}, // Для первой реализации значение всегда будет ПИР
      data: [
        {value: '11', label: 'ПИР'}, // Для первой реализации значение всегда будет ПИР
      ]
    },
    title: '',
    description: '',
    priorities: {
      isLoading: false,
      selectedValue: {value: priorityValues.MEDIUM, label: priorityNames.MEDIUM},
      data: [
        {value: priorityValues.LOW, label: priorityNames.LOW},
        {value: priorityValues.MEDIUM, label: priorityNames.MEDIUM},
        {value: priorityValues.HIGH, label: priorityNames.HIGH},
        {value: priorityValues.URGENT, label: priorityNames.URGENT},
      ]
    },
    executor: {
      isLoading: false,
      selectedValue: {value: '', label: 'Введите значение...'},
      data: []
    },
    spectators: {
      usersData: [],
      usersDataIsLoading: false,
      usersIds: []
    },
    startDate: '',
    endDate: '',
  },
  additionalFields: {
    checklist: [],
    checklistSelected: [],
    inputSelected: { value: '', serverId: '' },
    checkboxesSelected: [],
    radioSelected: { value: '', serverId: '' },
    selectedFile: { id: "", link: "", name: "", size: '', type: "" },
    selectSelectedValues: [],
    selectSelectedValue: {value: '', label: 'Введите значение...', serverId: ''},
    extra: []
  }
}

export type FormEditTaskState = typeof initialState;

const FormEditTaskReducer: Reducer<FormEditTaskState, FormEditTaskAction> = (
  state = initialState,
  action
) => {
  switch(action.type) {
    case FormEditTaskActionTypes.SET_TASK_FORM_DATA:
      let newData = {...state.data};
      let newAdditionalFields = {...state.additionalFields};
      
      if(action.taskFormData?.author) {
        newData.author = action.taskFormData.author;
      }
      
      if(action.taskFormData?.title) {
        newData.title = action.taskFormData.title;
      }
      
      if(action.taskFormData?.taskId) {
        newData.taskId = action.taskFormData.taskId;
      }
      
      if(action.taskFormData?.status_id) {
        newData.status_id = action.taskFormData.status_id;
      }
      
      if(action.taskFormData?.startDate) {
        newData.startDate = action.taskFormData.startDate;
      }
      
      if(action.taskFormData?.endDate) {
        newData.endDate = action.taskFormData.endDate;
      }
      
      if(action.taskFormData?.description) {
        newData.description = action.taskFormData.description;
      }
      
      if(action.taskFormData?.createdAt) {
        newData.createdAt = action.taskFormData.createdAt;
      }
      
      if(action.taskFormData?.commentsCount) {
        newData.commentsCount = action.taskFormData.commentsCount;
      }
      
      if(action.taskFormData?.project_id) {
        newData.project_id = action.taskFormData.project_id;
      }
      
      if(action.taskFormData?.project_section_id) {
        newData.project_section_id = action.taskFormData.project_section_id;
      }
      
      if(action.taskFormData?.priorities?.selectedValue) {
        newData.priorities.selectedValue = action.taskFormData.priorities.selectedValue;
      }
      
      if(action.taskFormData?.executor?.selectedValue) {
        newData.executor.selectedValue = action.taskFormData.executor.selectedValue;
      }
      
      if(action.taskFormData?.spectators) {
        // @ts-ignore
        newData.spectators.usersIds = action.taskFormData.spectators;
      }
      
      if(action.additionalFields.extra?.length) {
        newAdditionalFields.extra = action.additionalFields.extra;
      }
      
      if(action.additionalFields.checklist?.length) {
        newAdditionalFields.checklist = action.additionalFields.checklist;
      }

      return {
        ...state,
        data: newData,
        additionalFields: newAdditionalFields
      };
      
    case FormEditTaskActionTypes.SET_ACTIVE_TAB_INDEX: {
      return {
        ...state,
        activeTabIndex: action.activeTabIndex
      }
    }
      
    case FormEditTaskActionTypes.SET_LOADING_TASK: {
      return {
        ...state,
        isLoadingTask: action.isLoadingTask
      }
    }
      
    case FormEditTaskActionTypes.SET_OBJECTS: {
      return {
        ...state,
        objects: action.objects
      }
    }
      
    case FormEditTaskActionTypes.SET_DEFAULT_VALUES: {
      const oldCheck = {...state.check};
      
      return {
        ...initialState,
        check: oldCheck
      }
    }
      
    case FormEditTaskActionTypes.SET_STAGES: {
      return {
        ...state,
        data: {
          ...state.data,
          stages: {
            ...state.data.stages,
            data: action.stages
          }
        }
      }
    }
    
    case FormEditTaskActionTypes.SET_FORM_OPEN:
      return {
        ...state,
        isFormOpen: action.isFormOpen
      }
      
    case FormEditTaskActionTypes.SET_CREATING_TASK:
      return {
        ...state,
        isCreatingTask: action.isCreatingTask
      }
      
    case FormEditTaskActionTypes.SET_CHECK_TRIGGER:
      return {
        ...state,
        checkTrigger: action.checkTrigger
      }
      
    case FormEditTaskActionTypes.SET_OBSERVERS:
      return {
        ...state,
        data: {
          ...state.data,
          spectators: {
            ...state.data.spectators,
            usersIds: action.data
          }
        }
      }
      
    case FormEditTaskActionTypes.SET_EXTRA_SELECT_VALUES:
      return {
        ...state,
        additionalFields: {
          ...state.additionalFields,
          selectSelectedValues: action.values
        }
      }
      
    case FormEditTaskActionTypes.SET_EXTRA_INPUT_VALUE:
      return {
        ...state,
        additionalFields: {
          ...state.additionalFields,
          inputSelected: {
            value: action.value,
            serverId: action.serverId
          }
        }
      }
      
    case FormEditTaskActionTypes.SET_EXTRA_SELECT_SELECTED_VALUE:
      return {
        ...state,
        additionalFields: {
          ...state.additionalFields,
          selectSelectedValue: action.value
        }
      }
      
    case FormEditTaskActionTypes.SET_EXTRA_RADIO:
      return {
        ...state,
        additionalFields: {
          ...state.additionalFields,
          radioSelected: {
            value: action.value,
            serverId: action.serverId
          }
        }
      }
      
    case FormEditTaskActionTypes.SET_EXTRA_FILE_VALUE:
      return {
        ...state,
        additionalFields: {
          ...state.additionalFields,
          selectedFile: action.value
        }
      }
      
    case FormEditTaskActionTypes.SET_SELECT_VALUES:
      return {
        ...state,
        data: {
          ...state.data,
          [action.name]: {
            ...state.data[action.name] as object,
            data: action.data
          }
        }
      }

    case FormEditTaskActionTypes.SET_CHECKLIST:
      if(Array.isArray(action.data)) {
        return {
          ...state,
          additionalFields: {
            ...state.additionalFields,
            checklist: action.data
          }
        }
      } 
      else {
        if(!state?.additionalFields.checklist) return state;
        
        const newChecklist: any = [...state.additionalFields.checklist];
        const updateIndex: any = state?.additionalFields?.checklist.findIndex(checklist => checklist.id === action.data?.id);
        
        if(updateIndex === -1) {
          return state;
        }

        newChecklist[updateIndex] = action.data;
        
        return {
          ...state,
          additionalFields: {
            ...state.additionalFields,
            checklist: newChecklist
          }
        }
      }
      
    case FormEditTaskActionTypes.SET_EXTRA_SELECTED_VALUE:
      if(Array.isArray(action.data)) {
        return {
          ...state,
          additionalFields: {
            ...state.additionalFields,
            [action.name]: [
              // @ts-ignore
              ...state.additionalFields[action.name],
              ...action.data
            ]
          }
        }
      }
      else {
        if(!state?.additionalFields[action.name]) return state;

        const newFieldData: any = [...state.additionalFields[action.name] as []];
        // @ts-ignore
        const updateIndex: any = state?.additionalFields[action.name].findIndex(checklist => checklist.id === action.data?.id);

        if(updateIndex === -1) {
          return state;
        }

        newFieldData[updateIndex] = action.data;

        return {
          ...state,
          additionalFields: {
            ...state.additionalFields,
            [action.name]: newFieldData
          }
        }
      }
      
    case FormEditTaskActionTypes.SET_SELECT_SELECTED_VALUE:
      return {
        ...state,
        data: {
          ...state.data,
          [action.name]: {
            ...state.data[action.name] as object,
            selectedValue: action.data
          }
        }
      }

    case FormEditTaskActionTypes.SET_CHECKBOX:
      return {
        ...state,
        data: {
          ...state.data,
          [action.name]: action.value
        }
      }
      
    case FormEditTaskActionTypes.SET_INPUT:
      return {
        ...state,
        data: {
          ...state.data,
          [action.name]: action.value
        }
      }
      
    case FormEditTaskActionTypes.SET_STATUS:
      return {
        ...state,
        data: {
          ...state.data,
          status_id: action.status_id
        }
      }
      
    case FormEditTaskActionTypes.SET_CHECK_RULES:
      return {
        ...state,
        check: action.rules
      }

    default:
      return state;
  }
}

export default FormEditTaskReducer;
