import React, { useEffect } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import styled from "styled-components";
import Trash from "../../../../../img/projectIcons/Trash";
import { connect } from "react-redux";
import { State } from "../../../../../rootReducer";
import PreloaderLocal from "../../../../../common/components/PreloaderLocal/PreloaderLocal";

import "./MenuBusinessProcess.scss";
import { IDeleteBusinessProcessModal } from "../../../interfaces";
import { checkDeleteBp, setDeleteModalBp } from "../../../actions";

interface IBusinessProcessMenuProps {
  id: number | string;
  anchorEl: HTMLElement | null;
  setAnchorEl: (arg0: React.MouseEvent<HTMLButtonElement> | null) => void;
  deleteBusinessProcessModal: IDeleteBusinessProcessModal;
  checkDeleteBp: (id: string|number) => void,
}

const MenuStyle = {
  marginTop: "45px",
};

const MenuItemStyle = {
  display: "flex",
  alignItems: "center",
  marginBottom: "5px",
  fontSize: "14px",
};

const MenuIcon = styled.div`
  margin-right: 10px;
  display: flex;
  align-items: center;

  > svg {
    stroke: #292a34;
  }
`;

const MenuTitle = styled.div``;

const BusinessProcessMenu = ({
  id,
  anchorEl,
  setAnchorEl,
  deleteBusinessProcessModal,
  checkDeleteBp
}: IBusinessProcessMenuProps) => {
  const clickTaskActionsCloseHandler = () => {
    setAnchorEl(null);
  };

  const clickDeleteHandler = (id: string | number, e: any) => {
    checkDeleteBp(id)
    setAnchorEl(null);
    e.stopPropagation();
  };

  return (
    <Menu
      className="business_process-menu"
      anchorEl={anchorEl}
      open={!!anchorEl}
      onClose={(e: any) => {
        clickTaskActionsCloseHandler();
        e.stopPropagation();
      }}
      style={MenuStyle}
    >
      <MenuItem
        style={{ ...MenuItemStyle, marginBottom: 0 }}
        onClick={(e) => clickDeleteHandler(id, e)}
      >
        <MenuIcon>
          <Trash />
        </MenuIcon>

        <MenuTitle
          className={
            "business_process-menu__title-wr"
            + (deleteBusinessProcessModal.isShowPreloader
              ? " business_process-menu__title-wr--show-preloader"
              : "")
          }
        >
          <div className="business_process-menu__title">
            Удалить бизнес-процесс
          </div>
          <div className="business_process-menu__preloader">
            <PreloaderLocal />
          </div>
        </MenuTitle>
      </MenuItem>
    </Menu>
  );
};

const mapStateToProps = (state: State) => {
  return {
    isDeleteModalOpen:
      state.businessPage.deleteBusinessProcessModal.isDeleteModalOpen,
      deleteBusinessProcessModal: state.businessPage.deleteBusinessProcessModal,
  };
};

const mapDispatchToProps = {
  checkDeleteBp
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BusinessProcessMenu);
