import React, { FC } from "react";
import { columnsOfTablesBusinessProcess } from "../../sharedData";
import SortIcon from "../../../../common/components/SortIcon";
import { connect } from "react-redux";
import { State } from "../../../../rootReducer";
import { sortConditions } from "../../constants";
import { ISortConditions } from "../../interfaces";
import {
  getBusinessProcess,
  setCurrentPage,
  setSortCondition,
} from "../../actions";

import "./BusinessProcessTableHeader.scss";

const TableHeaderTypicalTasks: FC<{
  sortCondition: ISortConditions;
  setSortCondition: (sortCondition: ISortConditions) => void;
  getBusinessProcess: (params?: object) => void;
  setCurrentPage: (currentPage: number) => void;
}> = ({
  sortCondition,
  setSortCondition,
  getBusinessProcess,
  setCurrentPage,
}) => {
  const onClickCondition = (columnId, value) => {
    setCurrentPage(1);
    setSortCondition({ columnId, value });
    getBusinessProcess({ columnId, value });
  };

  return (
    <div className="business_process_table_header">
      <div className="block_table_head">
        {Object.keys(columnsOfTablesBusinessProcess).map((key) => {
          return columnsOfTablesBusinessProcess[key].columnId ===
            sortConditions.ID ||
            columnsOfTablesBusinessProcess[key].columnId ===
              sortConditions.NAME ||
            columnsOfTablesBusinessProcess[key].columnId ===
              sortConditions.TREATY ||
            columnsOfTablesBusinessProcess[key].columnId ===
              sortConditions.ALL_TASKS ||
            columnsOfTablesBusinessProcess[key].columnId ===
              sortConditions.DURATION ||
            columnsOfTablesBusinessProcess[key].columnId ===
              sortConditions.AUTHOR ||
            columnsOfTablesBusinessProcess[key].columnId ===
              sortConditions.CREATED_AT ||
            columnsOfTablesBusinessProcess[key].columnId ===
              sortConditions.STATUS ? (
            <div
              className={`header-business_process__${columnsOfTablesBusinessProcess[key].columnId}`}
              key={columnsOfTablesBusinessProcess[key].columnId}
            >
              <span
                onClick={() => {
                  const value =
                    sortCondition.columnId ===
                    columnsOfTablesBusinessProcess[key].columnId
                      ? sortCondition.value === 2
                        ? 1
                        : 2
                      : 1;

                  onClickCondition(
                    columnsOfTablesBusinessProcess[key].columnId,
                    value
                  );
                }}
                className={`header_items_business_process ${columnsOfTablesBusinessProcess[key].columnId}`}
              >
                <span className="text_header">
                  {columnsOfTablesBusinessProcess[key].showingText}
                </span>
                <SortIcon
                  active={
                    sortCondition.columnId ===
                    columnsOfTablesBusinessProcess[key].columnId
                  }
                  activeSort={
                    sortCondition.columnId ===
                      columnsOfTablesBusinessProcess[key].columnId &&
                    sortCondition.value === 1
                      ? "top"
                      : "bottom"
                  }
                />
              </span>
            </div>
          ) : null;
        })}
      </div>
    </div>
  );
};

const mapStateToProps = (state: State) => ({
  sortCondition: state.businessPage.sortCondition,
});

const mapDispatchToProps = {
  setSortCondition,
  getBusinessProcess,
  setCurrentPage,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TableHeaderTypicalTasks);
