import React from "react";
import { State } from "../../../../rootReducer";
import { connect } from "react-redux";
import PreloaderLocal from "../../../../common/components/PreloaderLocal/PreloaderLocal";

import "./FormTreaties.scss";
import NormalForm from "./NormalForm/NormalForm";
import ExtraForm from "./ExtraForm/ExtraForm";

const FormTreaties: React.FC<{
  isFormTreatyOpen: boolean;
  isFullFormTreatyOpen: boolean;
    isLoadingTreaty: boolean;
    isLoadingTreatyCard: boolean;
}> = ({
  isFormTreatyOpen,
  isFullFormTreatyOpen,
    isLoadingTreaty,
    isLoadingTreatyCard
}) => {
  
  return (
    <div
      className={
        "form-tasks" +
        (isFormTreatyOpen ? " form-treaties--open" : "") +
        (isFullFormTreatyOpen ? " form-treaties--open-full" : "") +
        (isLoadingTreatyCard ? " form-treaties--show-preloader" : "")
      }
    >
      <div className="form-treaties__content">
        <div className="form-treaties__left">
          <div className="form-treaties__preloader">
            <PreloaderLocal size="big" />
          </div>

          <NormalForm />
        </div>

        <div className="form-treaties__right">
          <div className="form-treaties__preloader">
            <PreloaderLocal />
          </div>

          <ExtraForm />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: State) => {
  return {
    isFormTreatyOpen: state.typicalTreatyPage.isFormTreatyOpen,
    isFullFormTreatyOpen: state.typicalTreatyPage.isFullFormTreatyOpen,
    isLoadingTreaty: state.typicalTreatyPage.isLoadingTreaty,
    isLoadingTreatyCard: state.typicalTreatyPage.isLoadingTreatyCard
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(FormTreaties);
