import React, { FC, useEffect, useState } from "react";
import { ReactComponent as DotsIcon } from "../../../../img/TrueIcons/dots.svg";

import "./TypicalTreatiesComponentItem.scss";
import { State } from "../../../../rootReducer";
import { connect } from "react-redux";
import moment from "moment";
import {
  ITypicalTreatiesServerData,
  TypicalTreatiesActivity,
} from "../../interfaces";
import MenuTypicalTreatiesItems from "../ui/MenuTypicalTreatiesItems/MenuTypicalTreatiesItems";
import {
  getTypicalTreaty,
  setIsEditForm,
  setIsTreatyFormOpen,
  setMainBool,
} from "../../actions";
import { getDuration, isPositiveDuration } from "../../../BusinessPage/helpers/helpersCommon";

interface TypicalTreatiesComponentItemProps {
  treaties: ITypicalTreatiesServerData;
  setIsTreatyFormOpen: (mode: boolean) => void;
  setIsEditForm: (mode: boolean) => void;
  getTypicalTreaty: ( data: any) => void;
  setMainBool:(mode: boolean) => void;
  mainId: number;
}

const TypicalTreatiesComponentItem: FC<TypicalTreatiesComponentItemProps> = ({
  treaties,
  setIsTreatyFormOpen,
  setIsEditForm,
  getTypicalTreaty,
  setMainBool,
  mainId
}) => {
  const formatDate = (date) => moment(date).format("DD.MM.YYYY");

  const [authorName, setAuthorName] = useState<string>(
    treaties.author?.surname + " " + treaties.author?.name
  );

  useEffect(() => {
    setAuthorName(treaties.author?.surname + " " + treaties.author?.name);
  }, [treaties.author?.name, treaties.author?.surname]);

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleSetAnchorEl = (value) => {
    setAnchorEl(value);
  };

  const clickActionsOpenHandler = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const openEditHandler = (e, data) => {
    getTypicalTreaty(data.id);
    setIsTreatyFormOpen(true);
    setIsEditForm(true);
    e.stopPropagation();
    setMainBool(false)
  };

  return (
    <div onClick ={(e) => openEditHandler(e, treaties)} className="typical-treaties-item-component_wrapper">
      <div className="typical-treaties-item-component_container">
        <div className="typical-treaties-item-component">
          <div className="typical-treaties-item-component_id">
            {mainId + "." + treaties.id}
          </div>
          <div className="typical-treaties-item-component_name" title={treaties.name}>
            {treaties.name ? treaties.name : <span>&mdash;</span>}
          </div>
          <div className="typical-treaties-item-component_all_stage">
            {treaties?.stage_count ?? 0}
          </div>
          <div className="typical-treaties-item-component_all_business_process">
            {treaties?.bp_count ?? 0}
          </div>
          <div className="typical-treaties-item-component_all_tasks">
            {treaties?.task_count ?? 0}
          </div>
          <div className="typical-treaties-item-component_duration ">
            {isPositiveDuration(treaties.duration) ? getDuration(treaties.duration) : 0}
          </div>
          <div className="typical-treaties-item-component_author">
            <div className="typical-treaties-item-component__avatar">
              {treaties.author?.image && (
                <div className="typical-treaties-item-component__avatar-img-wr">
                  <img
                    className="typical-treaties-item-component__avatar-img"
                    src={treaties.author?.image}
                    alt={authorName}
                  />
                </div>
              )}

              {!treaties.author?.image && (
                <div className="typical-treaties-component__avatar-text">
                  {treaties.author?.surname?.substring(0, 1)}
                  {treaties.author?.name?.substring(0, 1)}
                </div>
              )}
            </div>
            {authorName}
          </div>
          <div className="typical-treaties-item-component_created_at">
            {formatDate(treaties.created_at)}
          </div>
          <div className="typical-treaties-item-component_status">
            {treaties.status
              ? TypicalTreatiesActivity.ACTIVE_TEXT
              : TypicalTreatiesActivity.NOT_ACTIVE_TEXT}
          </div>

          <div className="typical-treaties-item-component__actions">
            <button
              className="typical-treaties-item-component__actions-btn"
              onClick={(e) => clickActionsOpenHandler(e)}
            >
              <DotsIcon />
            </button>
          </div>

          <MenuTypicalTreatiesItems
            id={treaties.id}
            anchorEl={anchorEl}
            setAnchorEl={handleSetAnchorEl}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: State) => ({});

const mapDispatchToProps = {
  setIsTreatyFormOpen,
  setIsEditForm,
  getTypicalTreaty,
  setMainBool
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TypicalTreatiesComponentItem);
