import React, { FC } from "react";
import { columnsOfTablesTypicalTasks } from "../../sharedData";
import SortIcon from "../../../../common/components/SortIcon";
import { connect } from "react-redux";
import { State } from "../../../../rootReducer";
import { sortConditions } from "../../constants";
import { ISortConditions } from "../../interfaces";
import { getObjects, setCurrentPage, setSortCondition } from "../../actions";

import "./styles.scss";

const TableHeaderObjects: FC<{
  sortCondition: ISortConditions;
  setSortCondition: (sortCondition: ISortConditions) => void;
  setCurrentPage: (currentPage: number) => void;
  getObjects: (params?: object) => void
}> = ({ 
  sortCondition, 
  setSortCondition, 
  setCurrentPage,
  getObjects
}) => {
  const onClickCondition = (columnId, value) => {
    setCurrentPage(1);
    setSortCondition({ columnId, value });
    
    getObjects();
  };

  return (
    <div className="tasks_table_header_objects">
      <div className="block_table_head">
        {Object.keys(columnsOfTablesTypicalTasks).map((key) => {
          return columnsOfTablesTypicalTasks[key].columnId ===
            sortConditions.ID ||
            columnsOfTablesTypicalTasks[key].columnId === sortConditions.DAYS ||
            columnsOfTablesTypicalTasks[key].columnId ===
              sortConditions.NAME ? (
            <div
              className={`header-item-objects__${columnsOfTablesTypicalTasks[key].columnId}`}
              key={columnsOfTablesTypicalTasks[key].columnId}
              style={columnsOfTablesTypicalTasks[key].style}
            >
              <span
                onClick={() => {
                  const value =
                    sortCondition.columnId ===
                    columnsOfTablesTypicalTasks[key].columnId
                      ? sortCondition.value === 2
                        ? 1
                        : 2
                      : 1;

                  onClickCondition(
                    columnsOfTablesTypicalTasks[key].columnId,
                    value
                  );
                }}
                className={`header_items ${columnsOfTablesTypicalTasks[key].columnId}`}
              >
                <span className="text_header">
                  {columnsOfTablesTypicalTasks[key].showingText}
                </span>
                <SortIcon
                  active={
                    sortCondition.columnId ===
                    columnsOfTablesTypicalTasks[key].columnId
                  }
                  activeSort={
                    sortCondition.columnId ===
                      columnsOfTablesTypicalTasks[key].columnId &&
                    sortCondition.value === 1
                      ? "top"
                      : "bottom"
                  }
                />
              </span>
            </div>
          ) : null;
        })}
      </div>
      <div className="block_table_head">
        {Object.keys(columnsOfTablesTypicalTasks).map((key) => {
          return columnsOfTablesTypicalTasks[key].columnId ===
            sortConditions.TREATY ||
            columnsOfTablesTypicalTasks[key].columnId ===
              sortConditions.EXECUTOR ||
            columnsOfTablesTypicalTasks[key].columnId ===
              sortConditions.PROGRESS ? (
            <div
              className={`header-item-objects__${columnsOfTablesTypicalTasks[key].columnId}`}
              key={columnsOfTablesTypicalTasks[key].columnId}
            >
              <span
                onClick={() => {
                  const value =
                    sortCondition.columnId ===
                    columnsOfTablesTypicalTasks[key].columnId
                      ? sortCondition.value === 2
                        ? 1
                        : 2
                      : 1;

                  onClickCondition(
                    columnsOfTablesTypicalTasks[key].columnId,
                    value
                  );
                }}
                className={`header_items ${columnsOfTablesTypicalTasks[key].columnId}`}
              >
                <span className="text_header">
                  {columnsOfTablesTypicalTasks[key].showingText}
                </span>
                <SortIcon
                  active={
                    sortCondition.columnId ===
                    columnsOfTablesTypicalTasks[key].columnId
                  }
                  activeSort={
                    sortCondition.columnId ===
                      columnsOfTablesTypicalTasks[key].columnId &&
                    sortCondition.value === 1
                      ? "top"
                      : "bottom"
                  }
                />
              </span>
            </div>
          ) : null;
        })}
      </div>
      <div className="block_table_head">
        {Object.keys(columnsOfTablesTypicalTasks).map((key) => {
          return columnsOfTablesTypicalTasks[key].columnId ===
            sortConditions.IN_WORK ||
            columnsOfTablesTypicalTasks[key].columnId ===
              sortConditions.COMPLETED ||
            columnsOfTablesTypicalTasks[key].columnId ===
              sortConditions.OVERDUE ||
            columnsOfTablesTypicalTasks[key].columnId ===
              sortConditions.STATUS ? (
            <div
              className={`header-item-objects__${columnsOfTablesTypicalTasks[key].columnId}`}
              key={columnsOfTablesTypicalTasks[key].columnId}
              // style={columnsOfTablesTypicalTasks[key].style}
            >
              <span
                onClick={() => {
                  const value =
                    sortCondition.columnId ===
                    columnsOfTablesTypicalTasks[key].columnId
                      ? sortCondition.value === 2
                        ? 1
                        : 2
                      : 1;

                  onClickCondition(
                    columnsOfTablesTypicalTasks[key].columnId,
                    value
                  );
                }}
                className={`header_items ${columnsOfTablesTypicalTasks[key].columnId}`}
              >
                <span className="text_header">
                  {columnsOfTablesTypicalTasks[key].showingText}
                </span>
                <SortIcon
                  active={
                    sortCondition.columnId ===
                    columnsOfTablesTypicalTasks[key].columnId
                  }
                  activeSort={
                    sortCondition.columnId ===
                      columnsOfTablesTypicalTasks[key].columnId &&
                    sortCondition.value === 1
                      ? "top"
                      : "bottom"
                  }
                />
              </span>
            </div>
          ) : null;
        })}
      </div>
    </div>
  );
};

const mapStateToProps = (state: State) => ({
  sortCondition: state.objectsPage.sortCondition,
});

const mapDispatchToProps = {
  setCurrentPage,
  setSortCondition,
  getObjects
};

export default connect(mapStateToProps, mapDispatchToProps)(TableHeaderObjects);
