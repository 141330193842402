import React from "react";
import { State } from "../../../../rootReducer";
import { connect } from "react-redux";
import ActivitySelectTypicalTreaties from "../ui/selectors/ActivitySelectTypicalTreaties";

import "./FilterTypicalTreaties.scss";
import PersonalToggle from "../ui/toggle/PersonalToggle";
import { getMainTreaties, setIsEditForm, setIsTreatyFormOpen, setMainBool } from "../../actions";


const FilterTypicalTreaties: React.FC<{
  setIsTreatyFormOpen: (mode: boolean) => void; 
  setIsEditForm: (mode: boolean) => void;
  setMainBool: (mode: boolean) => void;
  getMainTreaties: (params?:object, action?: string,) => void;
}> = ({setIsTreatyFormOpen, setIsEditForm, setMainBool,
  getMainTreaties}) => {

  const formOpenHandler = () => {
    setIsEditForm(false)
    setIsTreatyFormOpen(true);
    getMainTreaties({}, "add")
    setMainBool(false)
  }

  return (
    <div className="filter_typical-treaties">
      <div className="filter_typical-treaties__left">
        <div className="filter_typical-treaties__filters-selectors">
          <div className="filter_typical-treaties__filters-selector-item">
            <div className="filter_typical-treaties__filters-selector-item-title">
              Состояние
            </div>
            <div className="filter_typical-treaties__filters-selector-item-element filter_typical-treaties__filters-selector-item-element--width">
              <ActivitySelectTypicalTreaties isSingleSelect={true} />
            </div>
          </div>
        </div>

        <div className="filter_typical-treaties__filters-toggle">
          <PersonalToggle />
        </div>
      </div>

      <div className="filter_typical-treaties__right">
        <button 
          className="filter_typical-treaties__add-btn"
          type="button"
          onClick={formOpenHandler}
        >
          + Добавить типовой договор
        </button>

      </div>

    </div>
  );
};

const mapStateToProps = (state: State) => {
  return {};
};

const mapDispatchToProps = {
  setIsTreatyFormOpen,
  setIsEditForm,
  setMainBool,
  getMainTreaties
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterTypicalTreaties);
