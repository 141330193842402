// Окошко с выбором статуса

import React, {
  useMemo,
  useState,
  useRef,
  useCallback,
  useEffect,
  useLayoutEffect,
} from "react";
import { connect } from "react-redux";
import { File, Status, Task } from "../../types";
import { State } from "../../../rootReducer";
import { fetchData } from "../../../utils/fetchData";
import { updateStatusWindowTrue } from "../../../pages/Tasks/actions"; // 571 задача redmine 
import { updateStatusWindowFalse } from "../../../pages/Tasks/actions"; // 571 задача redmine 
import styled, { css } from "styled-components";
import { columnsOfTables } from "../../shared_data";
import {
  launchProcedureFinishTask,
  LocalTimeSheetRecord,
  timesheetCacheRecord,
  updateTask
} from "../../../pages/TasksPage/actions";

import Button from "../newComponent/Buttons/Button";
import MeetingStatusCell from "../TableCells/MeetingStatusCell";

type Props = {
  statusWindow: boolean; // 571 задача redmine *********************************
  updateStatusWindowTrue: (statusWindow: boolean) => void; // 571 задача redmine *********************************
  updateStatusWindowFalse: (statusWindow: boolean) => void; // 571 задача redmine *********************************
  id: number | string;
  taskId?: number;
  id_bp?: number | null;
  passHigher?: (statusId: number) => void;
  disabled?: boolean;
  statuses: Status[];
  updateTask: (id: number, params: any, withNotif: boolean) => void;
  tasks: Task[];
  launchProcedureFinishTask: (val, taskId, shortVersion, task_load_sum) => void;
  LocalTimeSheetRecord: (
    actionType: string,
    taskId: number,
    task_load_sum?: number | null,
    timesheetId?: number | null,
    status_id?: number | null,
    commentText?: string,
    commentFiles?: File[]
  ) => void;
  closeDayFlag?: boolean;
  current_user: number;
  selectedStatuses: any;
  buttonWrapperSize?: number;
  timesheetCacheRecord: (
    actionType: string,
    task_id?: number,
    task_load_sum?: number,
    status_id?: number
  ) => void;
  customStatusList?: Status[]; //используется при передаче статусов митингов
  showStatusIcon?: boolean;
};

let status_work = require("../../../img/status_work.svg").default;
let status_finished = require("../../../img/status_finished.svg").default;
let status_overdue = require("../../../img/status_overdue.svg").default;
let status_returned = require("../../../img/status_returned.svg").default;
let status_cancelled = require("../../../img/status_cancelled.svg").default;
let status_completed = require("../../../img/status_completed.svg").default;
let status_draft = require("../../../img/status_draft.svg").default;
let status_author = require("../../../img/status_author.svg").default;
let status_executor = require("../../../img/status_executor.svg").default;
let status_arhiv = require("../../../img/status_arhiv.svg").default;

// аккуратнее с изменением цветовых стилей кнопок статусов - они экспортирются, парсятся и используются в common/FiltersWindow.
// Для экспорта в другой модуль
export const greenStatus = css`
  color: #008c6d;
  background: #f2fefc;
  border: 1px solid #008c6d;
`;
export const blueStatus = css`
  color: #02405e;
  background: #f3fafe;
`;
export const redStatus = css`
  color: #cc0000;
  background: #fff2f2;
  border: 1px solid #cc0000;
`;
export const yellowStatus = css`
  color: #b9932f;
  background: #fffdf7;
  border: 1px solid #b9932f;
`;
export const purpleStatus = css`
  color: #b201f1;
  background: #fbf3fe;
  border: 1px solid #b201f1;
`;
export const grayStatus = css`
  color: #707070;
  background: #f2f2f2;
  border: 1px solid #707070;
`;

// ***************************************************************************************************
// Для использования в этом модуле
export const greenStatus2 = css`
  color: #008c6d;
  // background: #f2fefc;
  //  border: 1px solid #008c6d;
`;
export const blueStatus2 = css`
  color: #02405e;
`;
export const redStatus2 = css`
  color: #cc0000;
`;
export const yellowStatus2 = css`
  color: #b9932f;
`;
export const purpleStatus2 = css`
  color: #b201f1;
`;
export const grayStatus2 = css`
  color: #707070;
`;

// **********************************************************************************************

const WraperTaskStatus = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ chipsWidth }) =>
    chipsWidth ? `${"max-width: " + chipsWidth + "px;"}` : null}

  width: ${({ buttonWrapperSize }) =>
    buttonWrapperSize ? buttonWrapperSize + "px" : ""};

  min-width: ${({ buttonWrapperSize }) =>
    buttonWrapperSize
      ? buttonWrapperSize + "px"
      : columnsOfTables[4].style?.minWidth};
  max-width: ${columnsOfTables[4].style?.minWidth};
`;

const StatusStyle = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: left;
  align-items: center;
  width: 150px;
  height: 32px;
  border-radius: 6px;
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  padding: 0 4px;

  ${({ status_id }) => {
    switch (status_id) {
      case 12:
      case 13:
        return greenStatus2;
      case 10:
        return blueStatus2;
      case 14:
        return redStatus2;
      case 6:
      case 17:
        return yellowStatus2;
      case 2:
      case 3:
      case 4:
      case 5:
        return purpleStatus2;
      case 15:
      case 11:
      case 1:
      case 9:
        return grayStatus2;
    }
  }}
`;

// Список статусов
const StatusListStyle = styled.div`
  border-radius: 6px;
  padding: 16px;
  background: #fff;
  z-index: 1;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 182px;
  position: absolute;
  top: 45px;
  left: -16px;
  z-index: 1000;
  
  & > div:not(:last-child) {
    margin-bottom: 16px;
  }
  height: auto;
  overflow-y: auto;
`;

//  Функция выбора статуса
const TaskStatus: React.FC<Props> = ({
  statusWindow, // 571 задача redmine *********************************
  updateStatusWindowTrue, // 571 задача redmine *********************************
  updateStatusWindowFalse, // 571 задача redmine *********************************
  id,
  statuses,
  taskId,
  updateTask,
  disabled,
  tasks,
  launchProcedureFinishTask,
  LocalTimeSheetRecord,
  timesheetCacheRecord,
  closeDayFlag = false,
  current_user,
  passHigher,
  selectedStatuses,
  buttonWrapperSize,
  customStatusList,
  id_bp,
  showStatusIcon = false,
}) => {
  const [show, setShow] = useState<boolean>(false);
  const [statusList, setStatusList] = useState<Status[]>(statuses);
  const currentStatus = useMemo(() => {
    let status = statuses.find((status) => status.id === id);
    if (status) return status;
    else return null;
  }, [id, statuses]);

  // 571 задача redmine *********************************;

  const list = useRef<HTMLDivElement>(null);

  // Окончание клика на окошке со статусом
  const outsideClick = useCallback((e: Event) => {
    if (!list.current?.contains(e.target as HTMLElement)) {
      setShow(false);

      // Сообщаем в state что окончился выбор статуса. В родительском компоненте TaskTr.tsx можно теперь выбирать другие задачи
      updateStatusWindowTrue(statusWindow); // 571 задача redmine *********************************

      document.removeEventListener("click", outsideClick);
    }
  }, []);

  const handleBlur = () => {
    setShow(false);
  };

  useLayoutEffect(() => {
    if (list) {
      list?.current?.focus();
    }
  });

  useEffect(() => {
    let SL = customStatusList ? [...customStatusList] : [...statuses];

    SL = SL.filter((item) => item.id !== id); // убрать из выпадающего списка статус, который сейчас активен

    if (selectedStatuses.length) {
      if (SL.length) {
        selectedStatuses.forEach((SSitem) => {
          SL.splice(
            SL.findIndex((SLitem) => SLitem.id === SSitem),
            1
          );
        });
        setStatusList(SL);
      }
    } else setStatusList(SL);
  }, [statuses, selectedStatuses, id]);

  // Тут вызывается по клику окошко со статусами
  const clickHandler = useCallback(
    (e: React.MouseEvent) => {
      (async () => {
        // Сообщаем в state что начался выбор статуса. В родительском компоненте TaskTr.tsx пока нельзя выбирать другие задачи
        updateStatusWindowFalse(statusWindow); // 571 задача redmine *********************************

        e.stopPropagation();
        if (!disabled) {
          if (taskId && !customStatusList) {
            const data = await fetchData.get(
              `/api/v1/tasks/${taskId}/statuses`
            );
            if (data) {
              let newData = id_bp
                ? data.filter((id) => [10, 12, 13].includes(id))
                : data;

              const result: Status[] = statuses.filter((elem) =>
                newData.includes(elem.id)
              );
              setStatusList(result);
            }
          }
          setShow(true);
        }
        document.addEventListener("click", outsideClick);
      })();
    },
    [outsideClick, statuses, taskId]
  );
  let nameStatus;
  if (Number(id) > 1 && Number(id) < 6) {
    nameStatus = currentStatus?.name.split(" ")[1][0].toUpperCase();
    nameStatus =
      nameStatus +
      currentStatus?.name.split(" ")[1].split("").splice(1).join("");
  } else {
    nameStatus = currentStatus?.name;
  }

  return (
    <WraperTaskStatus
      className={"taskStatusWrapper"}
      buttonWrapperSize={buttonWrapperSize}
    >
      {/* (showStatusIcon && id) - просто покажи иконку статуса, когда есть showStatusIcon и id статуса */}
      {((taskId && !customStatusList) || (showStatusIcon && id)) && (
        <StatusStyle status_id={id} disabled={disabled} onClick={clickHandler}>
          {nameStatus === "В работе" && <img src={status_work} />}
          {nameStatus === "Просрочена" && <img src={status_overdue} />}
          {nameStatus === "Выполнено" && <img src={status_completed} />}
          {nameStatus === "Завершено" && <img src={status_finished} />}
          {nameStatus === "Возвращена" && <img src={status_returned} />}
          {nameStatus === "Отменена" && <img src={status_cancelled} />}
          {nameStatus === "Черновик" && <img src={status_draft} />}
          {nameStatus === "В архиве" && <img src={status_arhiv} />}
          {nameStatus === "Автору" && <img src={status_author} />}
          {nameStatus === "Исполнителю" && <img src={status_executor} />}
          {nameStatus !== "В работе" &&
            nameStatus !== "Просрочена" &&
            nameStatus !== "Завершено" &&
            nameStatus !== "Возвращена" &&
            nameStatus !== "Отменена" &&
            nameStatus !== "Выполнено" &&
            nameStatus !== "Черновик" &&
            nameStatus !== "В архиве" &&
            nameStatus !== "Автору" &&
            nameStatus !== "Исполнителю" &&
            nameStatus}
        </StatusStyle>
      )}

      {taskId && customStatusList && (
        <div style={{ cursor: "pointer" }} onClick={clickHandler}>
          <MeetingStatusCell
            statusName={customStatusList.find((item) => item.id === id)?.name}
          />
        </div>
      )}

      {/* это используется в фильтрации на страницах tasks projects и в меридиане*/}
      {/* Показать кнопку, если нет ид задачи и showStatusIcon и нет ид статуса */}
      {(!taskId && (showStatusIcon && !id)) && (
        <Button
          style={{ marginBottom: "8px" }}
          onClick={clickHandler}
          design={"outline"}
          title={"Добавить"}
        />
      )}
      {/* Список статусов */}
      {show && statusList.length > 0 && (
        <StatusListStyle ref={list} className={"modalWindow"} onBlur={()=>handleBlur()} tabIndex={-1}>
          {statusList.map((status, index) => {
            if (customStatusList) {
              return (
                <div
                  style={{ cursor: "pointer" }}
                  key={index}
                  onClick={() => {
                    if (passHigher) passHigher(status.id);
                    setShow(false); //  закрыть выпадающий список со статусами
                  }}
                >
                  <MeetingStatusCell statusName={status.name} />
                </div>
              );
            } else {
              let nameStatus;
              if (status.id > 1 && status.id < 6) {
                nameStatus = status?.name.split(" ")[1][0].toUpperCase();
                nameStatus =
                  nameStatus +
                  status?.name.split(" ")[1].split("").splice(1).join("");
              } else {
                nameStatus = status?.name;
              }

              return (
                <div className="statusStyle" key={index}>
                  <StatusStyle
                    status_id={status.id}
                    onClick={(e: React.MouseEvent) => {
                      e.stopPropagation();
                      if (taskId && !customStatusList) {
                        if (closeDayFlag) {
                          // контекст исполнения - окно "закрытие дня" в тасках.
                          LocalTimeSheetRecord(
                            "add",
                            taskId,
                            undefined,
                            undefined,
                            status.id
                          );
                        } else {
                          // контекст - таски
                          let found_task = tasks.find(
                            (item) => item.id === taskId
                          );
                          let item_status_id = found_task?.status_id;
                          if (
                            item_status_id === 10 && // статус был
                            status.id === 12 && // статус стал
                            found_task?.executor_id === current_user
                          )
                            launchProcedureFinishTask(
                              true,
                              taskId,
                              false,
                              found_task?.task_load_sum
                            );
                          else
                            updateTask(taskId, { status_id: status.id }, false);
                        }
                      } else {
                        if (passHigher) passHigher(status.id);
                      }
                      setShow(false); //  закрыть выпадающий список со статусами
                    }}
                  >
                    {nameStatus === "В работе" && <img src={status_work} />}
                    {nameStatus === "Просрочена" && (
                      <img src={status_overdue} />
                    )}
                    {nameStatus === "Выполнено" && (
                      <img src={status_completed} />
                    )}
                    {nameStatus === "Завершено" && (
                      <img src={status_finished} />
                    )}
                    {nameStatus === "Возвращена" && (
                      <img src={status_returned} />
                    )}
                    {nameStatus === "Отменена" && (
                      <img src={status_cancelled} />
                    )}
                    {nameStatus === "Черновик" && <img src={status_draft} />}
                    {nameStatus === "В архиве" && <img src={status_arhiv} />}
                    {nameStatus === "Автору" && <img src={status_author} />}
                    {nameStatus === "Исполнителю" && (
                      <img src={status_executor} />
                    )}
                    {nameStatus !== "В работе" &&
                      nameStatus !== "Просрочена" &&
                      nameStatus !== "Завершено" &&
                      nameStatus !== "Возвращена" &&
                      nameStatus !== "Отменена" &&
                      nameStatus !== "Выполнено" &&
                      nameStatus !== "Черновик" &&
                      nameStatus !== "В архиве" &&
                      nameStatus !== "Автору" &&
                      nameStatus !== "Исполнителю" &&
                      nameStatus}
                  </StatusStyle>
                </div>
              );
            }
          })}
        </StatusListStyle>
      )}
    </WraperTaskStatus>
  );
};

const mapStateToProps = (state: State) => {
  return {
    statusWindow: state.tasksObjects.statusWindow, // 571 задача redmine *********************************
    current_user: state.commonInfo.current_user,
    tasks: state.tasksObjects.tasks,
    statuses: state.commonInfo.statuses,
    selectedStatuses: state.commonInfo.filters.current_set.statuses,
  };
};

const mapDispatchToProps = {
  updateStatusWindowTrue, // 571 задача redmine *********************************
  updateStatusWindowFalse, // 571 задача redmine *********************************
  updateTask,
  launchProcedureFinishTask,
  timesheetCacheRecord,
  LocalTimeSheetRecord,
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskStatus);
