import React, { FC, useEffect, useState } from "react";
import { priorityNames } from "../../../../pages/ObjectsPage/constants";

import { ReactComponent as UrgentIcon } from "../../../../common/components/Icon/imgs/priority_urgent.svg";
import { ReactComponent as HighIcon } from "../../../../common/components/Icon/imgs/priority_high.svg";
import { ReactComponent as MediumIcon } from "../../../../common/components/Icon/imgs/priority_medium.svg";
import { ReactComponent as LowIcon } from "../../../../common/components/Icon/imgs/priority_low.svg";

import './PriorityCustom.scss';

const PriorityCustom: FC<{
  priorityName: string
}> = ({
  priorityName
}) => {
  const [icon, setIcon] = useState(<LowIcon />);
  const priorityValues = {
    [priorityNames.LOW]: <LowIcon />,
    [priorityNames.MEDIUM]: <MediumIcon />,
    [priorityNames.HIGH]: <HighIcon />,
    [priorityNames.URGENT]: <UrgentIcon />
  };

  useEffect(() => {
    if(priorityName) {
      setIcon(priorityValues[priorityName]);
    }
  }, [priorityName]);
  
  return (
    <div className='priority-custom'>
      {icon && (
        <div className="priority-custom__left">
          {icon}
        </div>
      )}
      
      <div className="priority-custom__right">
        {priorityName}
      </div>
    </div>
  )
}

export default PriorityCustom;
