import React, { useCallback, useState } from "react";
import Input from "../../../common/components/Inputs/Input";
import { validatePassword } from "../../../utils/validPatterns";
import { validateEmail } from "../../../utils/validPatterns";
import { fetchToken } from "../actions";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import CustomBlock from "./CustomBlock";
import Button from "../../../common/components/newComponent/Buttons/Button";
import { useEffect } from "react";
import { cookieMaster } from "../../../utils/CookieMaster";
import { fetchData } from "../../../utils/fetchData";
import { CreateNotif } from "../../../utils/createNotification";
import axios from "axios";

const FormStyle = styled.form`
  display: flex;
  flex-direction: column;

  div {
    width: 100%;
    margin-bottom: 8px;
  }
  a {
    text-decoration: none;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.3);
    transition: color 0.2s ease;
    &:hover {
      color: #707070;
    }
  }
`;

let token;
let company_id;
let user_id;
let isAdmin;

const Login = ({ fetchToken }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    localStorage.clear();
  }, []);

  let history = useHistory();

  const formSubmitHandler = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      let emailReg = localStorage.getItem("regEmail");
      if (emailReg && emailReg == email) {
        CreateNotif(
          "Вы не завершили 2 шаг регистрации email! Начните, пожалуйста, процесс регистрации заново!",
          "warning"
        );
        localStorage.removeItem("regEmail");
      }

      e.preventDefault();

      // Получение 1 токена для программы Easy Task. Функция из saga.ts
      // Полученные в результате token, company_id  записывются в куки и localstorage

      fetchToken(email, password);
    },
    [email, fetchToken, password]
  );

  return (
    <CustomBlock title="Войти">
      <FormStyle onSubmit={formSubmitHandler}>
        <Input
          inputType="text"
          placeholder="Email"
          title=""
          withIcon={false}
          value={email}
          isValid={email ? validateEmail(email) : true}
          changeHandler={(value) => setEmail(value.trim())}
          validText="Введите, пожалуйста, email в этом поле"
        />
        <Input
          inputType="password"
          placeholder="Пароль"
          withIcon={true}
          value={password}
          title=""
          changeHandler={(value) => setPassword(value)}
          isValid={password ? validatePassword(password) : true}
          validText="Пароль должен состоять из 6-30 символов и включать в себя хотя бы одно число и букву в верхнем регистре"
        />

        <Link to="/forgot-password" style={{ zIndex: 10000 }}>
          Забыли пароль?
        </Link>
        <Button
          disabled={
            (validateEmail(email) && validatePassword(password)) ||
            email === "" ||
            password === ""
              ? false
              : true
          }
          title="Войти"
          big
          style={{ width: "100%", marginTop: "32px", zIndex: 10000 }}
        />
      </FormStyle>
    </CustomBlock>
  );
};

const mapDispatchToProps = {
  fetchToken,
};

export default connect(null, mapDispatchToProps)(Login);
