import React, { useCallback, useState, useEffect } from "react";
import NameWithInput from "../../../common/components/newComponent/Inputs/NameWithInput";
import Toggler from "../../../common/components/Togglers/Toggler";
import Checkbox from "../../../common/components/CheckboxItem/Checkbox";
import styled from "styled-components";
import SelectWithSecondaryBtn from "../../../common/components/Selects/SelectWithSecondaryBtn";

import { useHistory } from "react-router-dom";

const StyleNotificationWrap = styled.div`
  margin-bottom: 16px;
`;

const StyleSelect = styled.div`
  margin-bottom: 16px;
`;

const StyleToggle = styled.div`
  margin-bottom: 24px;
`;

const StyleTitleNotification = styled.div`
  padding: 8px;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`;

const StyleCheckbox = styled.div`
  display: flex;
  padding: 8px;
  box-sizing: border-box;
  cursor: pointer;
`;

const StyleCheckboxTitle = styled.div`
  margin-left: 8px;
  font-size: 14px;
`;

const StyleBlockHint = styled.div`
  margin-bottom: 8px;
  color: #707070;
  font-size: 13px;
  padding: 8px;
`;

const options = [
  { name: "Приложение", value: 3 },
  { name: "Email", value: 1 },
  { name: "Telegram", value: 2 },
];
const notifications = [
  { name: "Задача создана", value: 1 },
  { name: "Задача изменена", value: 2 },
  { name: "У задачи поменялся статус", value: 3 },
  { name: "Задача отменена", value: 4 },
  { name: "Добавлен комментарий", value: 5 },
  { name: "У задачи близится дедлайн", value: 6 },
];

const rolesList = [
  { text: "Автор", value: "author" },
  { text: "Исполнитель", value: "executor" },
];

const Notifications = ({
  notificationOptions,
  setNotificationOptions,
  setNotificationSuccess,
  verificationTelegram,
  telegramCode,
  currentUser,
}) => {
  // console.log(notificationOptions);

  const history = useHistory();
  const [selectedRole, setSelectedRole] = useState({
    text: "Автор",
    value: "author",
  });

  const [selectedNotification, setSelectedNotification] = useState({
    name: "Приложение",
    value: 3,
  });

  const checkTgVerification = useCallback(() => {
    return (
      selectedNotification.value ===
        options.find((item) => item.name === "Telegram")?.value &&
      !verificationTelegram
    );
  }, [selectedNotification.value, verificationTelegram]);

  const checkedNotification = useCallback(
    (value, selectedRole) => {
      if (checkTgVerification()) {
        return false;
      }

      const findOptions = notificationOptions.find(
        (el) => el.role === selectedRole.value
      );

      if (findOptions) {
        return (
          findOptions.settings[value].indexOf(selectedNotification.value) !== -1
        );
      }

      return false;
    },
    [notificationOptions, selectedNotification, checkTgVerification]
  );

  const handlerOnChangeSelect = (role) => {
    const findRole = rolesList.find((el) => el.value === role);
    if (findRole) setSelectedRole(findRole);
  };

  const handlerOnChangeNotification = (value) => {
    const optionsValue = options.find((el) => el.value === value);
    if (optionsValue) setSelectedNotification(optionsValue);
  };

  const titleToggleValue = () => {
    if (checkTgVerification()) return false;

    const findObject = notificationOptions.find(
      (el) => el.role === selectedRole.value
    );
    if (findObject) {
      const notificationsList = Object.values(findObject.settings);

      for (let i = 0; i < notificationsList.length; i++) {
        // @ts-ignore
        if (notificationsList[i].indexOf(selectedNotification.value) === -1) {
          return false;
        }
      }

      return true;
    }
  };

  const handlerToggleNotification = useCallback(
    (toggleValue) => {
      if (checkTgVerification()) {
        return;
      }

      const findObject = notificationOptions.find(
        (el) => el.role === selectedRole.value
      );

      if (findObject) {
        setNotificationSuccess(true);

        if (toggleValue) {
          setNotificationOptions(
            notificationOptions.map((el) => {
              if (el.role === selectedRole.value) {
                for (let key in el.settings) {
                  if (
                    el.settings[key].indexOf(selectedNotification.value) !== -1
                  ) {
                    el.settings[key] = el.settings[key].filter(
                      (element) => element !== selectedNotification.value
                    );
                  }
                }

                return el;
              } else {
                return el;
              }
            })
          );
        } else {
          setNotificationOptions(
            notificationOptions.map((el) => {
              if (el.role === selectedRole.value) {
                for (let key in el.settings) {
                  if (
                    el.settings[key].indexOf(selectedNotification.value) === -1
                  ) {
                    el.settings[key] = [
                      ...el.settings[key],
                      selectedNotification.value,
                    ];
                  }
                }

                return el;
              } else {
                return el;
              }
            })
          );
        }
      }
    },
    [
      checkTgVerification,
      setNotificationSuccess,
      notificationOptions,
      setNotificationOptions,
      selectedNotification.value,
      selectedRole.value,
    ]
  );

  const handlerOnChangeNotificationValue = useCallback(
    (value, selectedRole) => {
      if (checkTgVerification()) {
        return;
      }

      setNotificationSuccess(true);
      const findOptions = notificationOptions.find(
        (el) => el.role === selectedRole.value
      );

      if (findOptions) {
        const check =
          findOptions.settings[value].indexOf(selectedNotification.value) !==
          -1;

        if (check) {
          setNotificationOptions(
            notificationOptions.map((el) => {
              if (el.role === selectedRole.value) {
                return {
                  ...el,
                  settings: {
                    ...el.settings,
                    [value]: el.settings[value].filter(
                      (el) => el !== selectedNotification.value
                    ),
                  },
                };
              } else {
                return el;
              }
            })
          );
        } else {
          setNotificationOptions(
            notificationOptions.map((el) => {
              if (el.role === selectedRole.value) {
                return {
                  ...el,
                  settings: {
                    ...el.settings,
                    [value]: [
                      ...el.settings[value],
                      selectedNotification.value,
                    ],
                  },
                };
              } else {
                return el;
              }
            })
          );
        }
      }
    },
    [
      notificationOptions,
      selectedNotification.value,
      checkTgVerification,
      setNotificationOptions,
      setNotificationSuccess,
    ]
  );

  useEffect(() => {
    let hash = history.location.hash.slice(1).split("&");
    if (hash.length === 2) {
      if (hash[1].split("=")[1] === "email") {
        setSelectedNotification({ name: "Email", value: 2 });
      }
      if (hash[1].split("=")[1] === "telegram") {
        setSelectedNotification({ name: "Telegram", value: 3 });
      }
    }
  }, []);

  const disabledCheckBox = () => {
    return checkTgVerification();
  };

  return (
    <StyleNotificationWrap className='user-notifications'>
      <NameWithInput title="Роль" iconName="people">
        <StyleSelect>
          <SelectWithSecondaryBtn
            list={rolesList}
            selectHandler={handlerOnChangeSelect}
            selected={selectedRole}
          />
        </StyleSelect>
        <StyleToggle>
          <Toggler
            activeValue={selectedNotification.value}
            selectHandler={handlerOnChangeNotification}
            elemsList={options}
            styles={{ display: "inline-flex" }}
          />
        </StyleToggle>
        <StyleTitleNotification
          onClick={() => handlerToggleNotification(titleToggleValue())}
        >
          {selectedNotification.name}
          <Checkbox
            checked={titleToggleValue()}
            onClick={(e) => e.stopPropagation()}
            onChange={() => {}}
            disabled={disabledCheckBox()}
          />
        </StyleTitleNotification>
        {checkTgVerification() && (
          <StyleBlockHint>
            {currentUser ? (
              <>
                Подключите телеграм-бота, для удобного использования системы.{" "}
                <br />
                Нажмите start. И отправьте сообщение: <br />
                subscribe_to_notifications:{telegramCode} <br />
                Ссылка на бота:
                <a
                  target="_blank"
                  href="https://t.me/easy_task_work_bot"
                  rel="noopener noreferrer"
                >
                  t.me/easy_task_work_bot
                </a>
              </>
            ) : (
              <>Пользователь еще не подключил Telegram</>
            )}
          </StyleBlockHint>
        )}
        
        <div className='user-notifications__chk-items'>
          {notifications.map((el) => (
            <StyleCheckbox
              className='user-notifications__chk-item'
              key={`${el.value}${selectedRole.value}`}
              onClick={() =>
                handlerOnChangeNotificationValue(el.value, selectedRole)
              }
            >
              <Checkbox
                checked={checkedNotification(el.value, selectedRole)}
                onClick={(e) => e.stopPropagation()}
                onChange={() => {}}
                disabled={disabledCheckBox()}
              />
              <StyleCheckboxTitle>{el.name}</StyleCheckboxTitle>
            </StyleCheckbox>
          ))}
        </div>
      </NameWithInput>
    </StyleNotificationWrap>
  );
};

export default Notifications;
