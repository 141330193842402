import React, { FC, useEffect } from "react";
import { connect } from "react-redux";
import { State } from "../../rootReducer";
import { useHistory } from "react-router-dom";
import { User } from "../../common/types";
import FormBP from "./components/FormBP/FormBP";
import BusinessProcessList from "./components/BusinessProcessList/BusinessProcessList";
import { getBusinessProcess, setCurrentPage } from "./actions";
import ModalTaskConnectBp from "./components/FormBP/ui/ModalTaskConnectBp/ModalTaskConnectBp";
import ModalTaskDeleteBp from "./components/FormBP/ui/ModalTaskDeleteBp/ModalTaskDeleteBp";

const BusinessPage: FC<{
  currentUserInfo: User|null,
  getBusinessProcess: (params?: object) => void;
  setCurrentPage: (mode: number) => void;
}> = ({
  currentUserInfo,
  getBusinessProcess,
  setCurrentPage
}) => {
  const routerHistory = useHistory();

  useEffect(() => {
   setCurrentPage(1)
   getBusinessProcess()
  }, []);

  useEffect(() => {
    if(currentUserInfo && !currentUserInfo.isAdmin) {
      routerHistory.push('/');
    }
  }, [currentUserInfo]);
  
  return (
    <div className='business-page' style={{ 
      position: 'relative', 
      width: '100%', 
      height: '100%',
      overflow: 'hidden'
    }}>
      <BusinessProcessList/>
      
      <FormBP />
      
      <ModalTaskConnectBp />
      <ModalTaskDeleteBp />
    </div>
  );
};

const mapStateToProps = (state: State) => ({
  currentUserInfo: state.commonInfo.currentUserInfo
});

const mapDispatchToProps = {
  getBusinessProcess,
  setCurrentPage
};

export default connect(mapStateToProps, mapDispatchToProps)(BusinessPage);
