import React, { FC, useEffect } from 'react';
import MainTypicalTasks from './components/TypicalTasks/TypicalTasks';
import FormTasks from "./components/FormTasks/FormTasks";
import {connect, useDispatch} from "react-redux";
import { State } from "../../rootReducer";
import {getTypicalTasks, setCurrentPage, setSortCondition} from "./actions";
import { useHistory } from "react-router-dom";
import { User } from "../../common/types";

const TypicalTasksPage: FC<{
  getTypicalTasks: (params?: object) => void;
  setCurrentPage: (pageNum: number) => void;
  currentUserInfo: User|null
}> = ({
  getTypicalTasks,
  setCurrentPage,
  currentUserInfo
}) => {
  const routerHistory = useHistory();
  const dispatch = useDispatch()

  useEffect(() => {
    if(currentUserInfo && !currentUserInfo.isAdmin) {
      routerHistory.push('/');
    }

  }, [currentUserInfo]);

  useEffect(() => {
    setCurrentPage(1);
    dispatch(setSortCondition({columnId: 'created_at', value: 0}))
    getTypicalTasks();
  }, []);
    
  return (
    <div className='typical-tasks-page' style={{ 
      position: 'relative', 
      width: '100%', 
      height: '100%',
      overflow: 'hidden'
    }}>
      <MainTypicalTasks/>
      
      <FormTasks />
    </div>
  );
};

const mapStateToProps = (state: State) => ({
  currentUserInfo: state.commonInfo.currentUserInfo
});

const mapDispatchToProps = {
  getTypicalTasks,
  setCurrentPage
};

export default connect(mapStateToProps, mapDispatchToProps)(TypicalTasksPage);
