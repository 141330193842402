import React, { useEffect, useState } from "react";
import { State } from "../../../../../../../rootReducer";
import { connect } from "react-redux";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import { makeStyles } from "@material-ui/core/styles";
import CheckboxMui from "../../../../../../../common/components/ui/CheckboxMui/CheckboxMui";

import "./ItemsTreaties.scss";
import { AccordionSummary } from "@material-ui/core";
import { AccordionIcon2 } from "../../../../../../../img/projectIcons";
import SelectActualStage from "../SelectActualStage/SelectActualStage";
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { ReactComponent as BurgerIcon } from "../../../../../../../img/TrueIcons/burger.svg";
import TaskTreaties from "../TaskTreaties/TaskTreaties";
import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { ReactComponent as CloseIcon } from "../../../../../../../img/TrueIcons/close.svg";
import { setDeleteItemStageTreatyModal } from "../../../../../actions";
import { deleteStageStatuses } from "../../../../../constants";
import { IDeleteStageItemTreatiesModal } from "../../../../../interfaces";
import { getDuration, isPositiveDuration } from "../../../../../../BusinessPage/helpers/helpersCommon";

const ItemsTreaties: React.FC<{ item: any; stageId: number | string, 
  setDeleteItemStageTreatyModal: (mode: IDeleteStageItemTreatiesModal) => void, main?: boolean }> = ({
  item,
  stageId,
  setDeleteItemStageTreatyModal,
  main
}) => {
  const [openAccord, setOpenAccord] = useState<boolean>(false);
  const [checkbox, setCheckbox] = useState<boolean>(false);
  const [tasksData, setTasksData] = useState<any[]>([]);

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: item.id,
    });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const onChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckbox(event.target.checked);
  };

  const useStyles = makeStyles((theme) => ({
    MuiAccordionroot: {
      "&.MuiAccordion-root": {
        margin: "10px 0",
      },
    },
  }));
  const classes = useStyles();
  const elementWithClass = document.querySelector(".PrivateSwitchBase-input-7");
  const onChangeAccordion = (expanded: boolean) => {
    setOpenAccord(expanded);

    if (expanded) {
      //   getObjectSection(object.id);
    }
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleOnDragEnd = (event: any) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      setTasksData((prev: any[]) => {
        const activeIndex = prev.findIndex(
          (item: any) => item.id === active.id
        );
        const overIndex = prev.findIndex((item: any) => item.id === over.id);

        return arrayMove(prev, activeIndex, overIndex);
      });
    }
  };

  useEffect(() => {
    if (item?.typical_tasks) {
      setTasksData(item.typical_tasks);
    }
  }, [item?.typical_tasks]);

  const hadleDeleteItemStage = (itemId, e) => {
      e.stopPropagation();
      setDeleteItemStageTreatyModal ({
      stageId: stageId,
      itemId: itemId,
      isDeleteModalOpen: true,
      statusFromServer: {
        constraints: [deleteStageStatuses.DELETE_STAGE_ALLOWED as string],
      },
    });
  };

  return (
    <div className="item-treaty">
      <div
        className="item-stage__content"
        ref={setNodeRef}
        {...attributes}
        style={style}
      >
       {main && <BurgerIcon className="item-stage__burger" {...listeners} />}
        {item.bp ? (
          <Accordion
            className="accordion_item_stage"
            expanded={openAccord}
            onChange={(e, expanded) => onChangeAccordion(expanded)}
            classes={{ root: classes.MuiAccordionroot }}
          >
            <AccordionSummary>
              <div
                className={
                  openAccord
                    ? "accordion_item_stage__vector open"
                    : "accordion_item_stage__vector"
                }
              >
                <AccordionIcon2 />
              </div>
              <div className="accordion_item_stage__name">{item.name}</div>
              <div className="accordion_item_stage__count_tasks">
                {item.count_task}
              </div>
              <div className="accordion_item_stage__durartion">
                {isPositiveDuration(item.duration) ? getDuration(item.duration) : <span>&mdash;</span>}
              </div>
             {main && <button
                className="accordion_item_stage__btn accordion_item_stage__btn--delete"
                onClick={(e) => hadleDeleteItemStage(item.id, e)}
              >
                <CloseIcon />
              </button>
}
            </AccordionSummary>
            <AccordionDetails style={{ display: "block" }}>
              <DndContext
                sensors={sensors}
                onDragEnd={handleOnDragEnd}
                collisionDetection={closestCenter}
              >
                <SortableContext
                  items={tasksData as []}
                  strategy={verticalListSortingStrategy}
                >
                  {tasksData.map((task: any, index) => (
                    <TaskTreaties key={task.id} task={task} index={index} />
                  ))}
                </SortableContext>
              </DndContext>
            </AccordionDetails>
          </Accordion>
        ) : (
          <div className="task_item_stage">
            <div className="task_item_stage__name">{item.name}</div>
            <div className="task_item_stage__duration">
              {isPositiveDuration(item.duration) ? getDuration(item.duration) : <span>&mdash;</span>}
            </div>
            {main && <button
              className="task_item_stage__btn task_item_stage__btn--delete"
              onClick={(e) => hadleDeleteItemStage(item.id, e)}
            >
              <CloseIcon />
            </button>
}
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: State) => {
  return {
    main: state.typicalTreatyPage.normalForm.main,
  };
};

const mapDispatchToProps = {
  setDeleteItemStageTreatyModal
};

export default connect(mapStateToProps, mapDispatchToProps)(ItemsTreaties);
