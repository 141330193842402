// Тут заводится и рассчитывается дата начала задачи   

import React, { useCallback, useEffect, useState } from 'react';
import TaskDateTime from '../../../common/components/DatePickers/DateTimePikerTask';
import { connect } from 'react-redux'; // Добавлено
import DateMaster from '../../../utils/DateMaster';
import {
  recalculateTaskLoad,
  selectTask,
  sendReqToChangeTemplRepTask,
  setCyclicTaskToChange,
  setTaskBegin,
  setTaskEnd      // ***********  добавлено
} from '../actions';
import { State } from '../../../rootReducer';
import { updateTask } from '../../../common/actions';
import moment from 'moment';
import { datePicker } from "../../../common/constants";

type Props = {
  checkedPersonalTask?:boolean;
  checkedTaskBegin?:boolean;
  disabled?: boolean;
  begin: string;
  end: string;
  maxDate: string;
  delegated: boolean;
  setTaskBegin: (begin: string) => void;
  setTaskEnd: (end: string) => void;    // ***********  добавлено
  recalculateTaskLoad: ({}) => void;
  selectedTask: any;
  updateTask: (id: number, params: any, withNotif?: boolean) => void;
  flagForActionCyclycTask: string;
  setCyclicTaskToChange: (obj: {}) => void;
  sendReqToChangeTemplRepTask: (obj: {}) => void;
  cellData: any;
  setTimeValid: (mode: boolean) => void;
};

const TaskBegin: React.FC<Props> = ({
  checkedPersonalTask,
  checkedTaskBegin,
  disabled,
  begin,
  end,
  maxDate,
  delegated,
  setTaskBegin,
  setTaskEnd,   // Добавлено
  recalculateTaskLoad,
  selectedTask,
  updateTask,
  flagForActionCyclycTask,
  setCyclicTaskToChange,
  sendReqToChangeTemplRepTask,
  cellData,
  setTimeValid
}) => {
  const toFormatUtsFullTime = (date: string) => {
    let templateFullDate: string[] = [];
    templateFullDate.push(date.split(' ')[1]);
    let templateDate = date.split(' ')[0].split('-');
    templateDate[0] = date.split(' ')[0].split('-')[1];
    templateDate[1] = date.split(' ')[0].split('-')[0];
    templateFullDate.unshift(templateDate.join('-'));
    return {
      ru: date,
      uts: templateFullDate.join(' '),
    };
  };

  // begin и end- переменные, поступают из state в props-ах в качестве параметров
  let beginTask = begin //useSelector((state: State) => state.taskInfoPlate.begin); ///*** изменено
  let endTask = end //useSelector((state: State) => state.taskInfoPlate.end); ///*** изменено

  const acceptHandler = useCallback(
    function (e): any {

      // Добавлено. Задача 544 ******************************************* */

      let beginTaskOldStr =  beginTask; // старая дата начала задачи в строковом виде  
      let beginTaskNewStr =  e;  // новая дата начала задачи в строковом виде         
      let endTaskStr =  endTask; // дата окончания задачи в строковом виде             
  
  
      let beginTaskOld = new Date(toFormatUtsFullTime(beginTaskOldStr).uts).getTime(); 
      let beginTaskNew = new Date(toFormatUtsFullTime(beginTaskNewStr).uts ).getTime();  
     
      let endTaskDate = new Date(toFormatUtsFullTime(endTaskStr).uts).getTime();  
      let differenceTime = endTaskDate - beginTaskOld;
 
      if (beginTaskNew > endTaskDate) {  

      let newEnd = beginTaskNew + differenceTime;  
      let dateEnd = new DateMaster(new Date(newEnd)).fullDate;
 
      endTaskStr = `${dateEnd.toLocaleString().split(',')[0].split('.').join('-')}${
            dateEnd.toLocaleString().split(',')[1]
       }`;        
        }

        var dateObj = moment(e, "DD-MM-YYYY HH:mm:ss").add(1, "day")
     // Тут изменяется state. Но реальный запрос на сервер пока не делается 
     setTaskEnd(cellData? dateObj.format("DD-MM-YYYY HH:mm:ss"):endTaskStr); // Тут вызывается из state action, который изменяет дату окончания, 
      // если пользователь выбрал другую дату начала, большую, чем дата окончания (при новой задаче)
      // (новая дата окончания= новая дата начала + differenceTime (разницу между 
      // старой датой начала и старой датой окончания)
      setTaskBegin(e); // Тут вызывается из state action, который изменяет дату начала, 
      // если пользователь выбрал другую дату 

      if (selectedTask) {
        let end = selectedTask.end;

        if(!moment(end, datePicker.DATE_FORMAT).isBefore(datePicker.YEAR_MAX_DEFAULT, 'year')) {
          end = datePicker.DATE_MAX_DEFAULT;
        }

        let templateBegin = new Date(
          toFormatUtsFullTime(selectedTask.begin).uts
        ).getTime();
        let templateEnd = new Date(
          toFormatUtsFullTime(selectedTask.end).uts
        ).getTime();
        let newBegin = new Date(toFormatUtsFullTime(e).uts).getTime();
        let differenceTime = templateEnd - templateBegin;

        if (newBegin > templateEnd) {
          let newEnd = newBegin + differenceTime;
          let date = new DateMaster(new Date(newEnd)).fullDate;
          // end = `${date.toLocaleString().split(',')[0].split('.').join('-')}${
          //   date.toLocaleString().split(',')[1]
          // }`;
        }

        // реальный запрос на сервер и пересчет данных задачи
        recalculateTaskLoad({
          task_load: selectedTask.task_load,
          begin: e,
          end,
        });
        if ([0, null].indexOf(selectedTask.cyclic_task_id) === -1) {
          // сравнение одного с несколькими
          // значит циклическая
          if (flagForActionCyclycTask === '') {
            setCyclicTaskToChange({ begin: e, end }); // заношу значения изменяемых параметров в временное хранилище, пока выводится окно с вопросом
          } else if (flagForActionCyclycTask === 'task') {
            updateTask(selectedTask!.id, { begin: e, end, private: checkedPersonalTask? true: false}); 
          } else {
            updateTask(selectedTask!.id, { begin: e, end, private: checkedPersonalTask? true: false });
            sendReqToChangeTemplRepTask({ begin: e, end });
          }
        } else {
          updateTask(selectedTask!.id, { begin: e, end , private: checkedPersonalTask? true: false });
        }
      }
    },
    [beginTask,endTask,selectedTask] ///*** изменено
  );
  let [date, time] = selectedTask
    ? selectedTask.end.split(' ')
    : maxDate.split(' ');

  // expected format is dd-mm-yyyy
  const [cellStart, setCellStart]=useState<any>(new Date())
  begin = begin.replace(/\//g, '-');
    useEffect(()=>{
      if(cellData){
        setCellStart(cellData.start_at)
      }
    },[cellData])

    useEffect(()=>{
      acceptHandler(moment(cellStart).format('DD-MM-YYYY HH:mm:ss'))
    },[cellStart])

  return (
    <TaskDateTime
      setTimeValid={setTimeValid}
      disabled={disabled || delegated}
      acceptHandler={cellData?(e)=>{ setCellStart(e)}:acceptHandler}
      date={cellData?cellStart:checkedTaskBegin?new Date(): selectedTask ? selectedTask.begin : begin}
    />
  );
};

const mapStateToProps = (state: State) => {
  return {
    selectedTask: state.taskInfoPlate.selectedTask,
    begin: state.taskInfoPlate.begin,
    end: state.taskInfoPlate.end,
    maxDate: state.taskInfoPlate.end,
    delegated: state.taskInfoPlate.delegated,
    flagForActionCyclycTask: state.taskInfoPlate.flagForActionCyclycTask,
  };
};

const mapDispatchToProps = {
  updateTask,
  setTaskBegin,
  setTaskEnd,    // *********** добавлено
  recalculateTaskLoad,
  setCyclicTaskToChange,
  sendReqToChangeTemplRepTask,
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskBegin);
