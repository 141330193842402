// В этом модуле показывается содержимое аккардеона с комментариями
// для совещания в списке совещаний.
// Это чат по работе с комментариями- отправка, удаление, список
//@ts-nocheck
// *****************************************************************

// Пример структуры списка совещаний:

 /* setFetchComments(
         [ {
          id: 1,
          text: "Первый комментарий",
          user_id: 1,
          comment_id: null,
          meeting_id: 1,
          is_log: false,
          created_at: "2023-03-23T22:22:49.000000Z",
          updated_at: "2023-03-23T22:22:49.000000Z",
          is_read: 1,
          files: {
          by_tag: [ ],
          list: [ ]
          }},

          { id: 2,
            text: "Второй комментарий",
            user_id: 1,
            comment_id: null,
            meeting_id: 1,
            is_log: false,
            created_at: "2023-03-23T22:22:49.000000Z",
            updated_at: "2023-03-23T22:22:49.000000Z",
            is_read: 1,
            files: {
            by_tag: [ ],
            list: [ ]
            }},
            { id: 3,
              text: "Третий комментарий",
              user_id: 1,
              comment_id: null,
              meeting_id: 1,
              is_log: false,
              created_at: "2023-03-23T22:22:49.000000Z",
              updated_at: "2023-03-23T22:22:49.000000Z",
              is_read: 1,
              files: {
              by_tag: [ ],
              list: [ ]
              }},  ])  */
  /************************************************************************* */    

import axios from "axios";
import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { useDispatch, useSelector } from "react-redux";
//import { StatusContext } from "../../../../../Bp/src/context/status";
import "./ChatMeeting.scss";
import { nanoid } from "nanoid";
import DateMaster from "../../../../../../utils/DateMaster";
import { State } from "../../../../../../rootReducer";
import { cookieMaster } from "../../../../../../utils/CookieMaster";
import ReplyIcon from "@material-ui/icons/Reply";
import CloseIcon from "@material-ui/icons/Close";
import { throttle } from  "../../../../../../utils/throttle";
import { TextareaAutosize } from "@material-ui/core";


let fileTranslate;
let periodUpdate = 5000;

let REACT_APP_PUBLIC_MEETING   = `${process.env.REACT_APP_PUBLIC_MEETING}`;


const ChatMeeting = ({
  meetingId,
  meetingData,
  openAccord
  }) => {
 /* const {
    bearer,
    userId,
    company_id,
    users,
  } = useContext(StatusContext);*/

  const { currentUserInfo: currentUser } = useSelector(
    (state) => state.commonInfo
  );

  const { users } = useSelector(
    (state) => state.commonInfo
  );

  // Токен easy task
  let token = cookieMaster.getCookie("access_token");

  // company id
  let company_id = localStorage.getItem("company_id") || "";

  // Текущий пользователь- админ или нет
  let isAdmin = currentUser ? currentUser.isAdmin : false;

  let userId = currentUser ? currentUser.id : null;

  const refInputCheck = useRef(null);
  const ref = useRef(null);

  const [message, setMessage] = useState("");
  const [sendMessageExecute, setSendMessageExecute] = useState(false);
  const [allFiles, setAllFiles] = useState([]);
  const [fetchComments, setFetchComments] = useState([]);
  const [timerFetchData, setTimerFetchData] = useState(0);
  const [checkOtvet, setCheckOtvet] = useState(false);
  const [checkOtvetId, setCheckOtvetId] = useState(null);
  const [checkOtvetText, setCheckOtvetText] = useState({
    name: "",
    surname: "",
    text: "",
  });
  const [pageNum, setPageNum] = useState(1);
  const [pageNumNew, setPageNumNew] = useState(1);
  const [appealWindow, setAppealWindow] = useState(false);

  const user = currentUser;

  const changeInputValue = (InputValue) => {
    setMessage(InputValue);
  };

  const clickFormButton = () => {
    sendMessage();
  };

  //таймер для запросов
  useEffect(() => {
    setInterval(() => {
      setTimerFetchData((timerFetchData) => timerFetchData + 1);
    }, periodUpdate);
  }, []);


  const getAllComments =async () => {
    try {
      axios
      .get(
        `${REACT_APP_PUBLIC_MEETING}/api/v1/meetings/${meetingId}/comments`,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((result) => {

     // console.log("result.data",result.data);
      
      setFetchComments(result.data.data)
      
      
      })
      .then(() => {
        ref.current.scrollTo(0, ref.current.scrollHeight);
      })
      .catch((e) => {
        console.log(e);
      });

    } catch (err) {
      console.log(err);
    }
  };

  //получение всех комментов
  useEffect(() => {

  if (openAccord)  getAllComments();
  
  }, [meetingId, openAccord]);

  //ответ на сообщение
  const replyMessage = (id, autorId, text) => {
     let infoUser = {
      method: "get",
      url: `${process.env.REACT_APP_PUBLIC_API}/users/${autorId}`,
      headers: {
        Accept: "application/json",
        Authorization: token,
        "company-id": company_id,
      },
    };

    axios(infoUser)
      .then((res) =>
        setCheckOtvetText({
          ...checkOtvetText,
          name: res.data.data.name,
          surname: res.data.data.surname,
          text: text,
        })
      )

      .catch((e) => {
        console.log(e);
      });

    setCheckOtvet(true);
    setCheckOtvetId(id);
    refInputCheck.current.focus();
  };

  //отмена 'ответить на сообщение'
  const closeOtvetInInput = () => {
    setCheckOtvetId("");
    setCheckOtvet(false);
  };

  // tasks/9/comments/40
  const deletedMessage = (id) => {
    let deletedCommentConfig = {
      method: "delete",
      url:  `${REACT_APP_PUBLIC_MEETING}/api/v1/comments/${id}`,
      headers: {
        Authorization: token,
      },
    };
    axios(deletedCommentConfig)
      .then((res) => console.log(res))
      .catch((e) => {
        console.log(e);
      });


    setFetchComments(fetchComments.filter((item) => item.id !== id));
    setCheckOtvet(false);
    setCheckOtvetId("");
  };

  const sendRequest = async (body) => {
   let sendCommentAndOtvetConfig = {
      method: "POST",
      url:  `${REACT_APP_PUBLIC_MEETING}/api/v1/meetings/${meetingId}/comments`,
      data: body,
      headers: {
        Authorization: token,
      },
    };
   await axios(sendCommentAndOtvetConfig);
   await getAllComments()
     

    setAllFiles([]);
    setMessage("");
    setCheckOtvetId("");
    setCheckOtvet(false);
  };

  const sendMessage = () => {

    console.log("meetingData",meetingData);

    //если есть файл то отправь с файлом
    if (allFiles.length && !message.length) {
      if (!checkOtvet) {
        //просто отправить сообщение
        const body = {
          // text: message,
          author_id: userId,
          meeting_id: meetingId,
          // comment_id: 0,
          files: allFiles,
        };
        sendRequest(body);
      } else {
        //ответ на сообщение
        const body = {
          text: message,
          author_id: userId,
          meeting_id: meetingId,
          comment_id: checkOtvetId,
          files: allFiles,
        };
        sendRequest(body);
      }
    } //если нет файла то отправь без него
    else {
      if (!checkOtvet) {
        //просто отправить сообщение
        const body = {
          text: message,
          author_id: userId,
          meeting_id: meetingId,
          // comment_id: 0,
          files: allFiles,
        };
        sendRequest(body);
      } else {
        //ответ на сообщение
        const body = {
          text: message,
          author_id: userId,
          meeting_id: meetingId,
          comment_id: checkOtvetId,
          files: allFiles,
        };
        sendRequest(body);
      }
    }

  

 /*************************************** */
   /* setFetchComments([
       ...fetchComments,{
       id: nanoid(),
       text:message,
       user_id: userId,
       comment_id: checkOtvetId ? checkOtvetId : null,
       meeting_id: meetingId,
       is_log: false,
       created_at: new Date(Date.now()),
       updated_at: new Date(Date.now()),
       is_read: 1,
       files: {
       by_tag: [ ],
       list: [ ]
       }}])*/
 /**************************************** */      
  };

  //добавление файлов
  function translateFile(file) {
    let reader = new FileReader();

    reader.onload = function () {
      let result = [...new Uint8Array(reader.result)]
        .map((item) => item.toString(16).padStart(2, "0"))
        .join("");
      fileTranslate = result;
      return result;
    };
    reader.readAsArrayBuffer(file);
  }

  //Добавляет файлы
  const sendFile = (e) => {
    let file = e.target.files[0];
    translateFile(file);
    setTimeout(() => {
      setAllFiles([
        ...allFiles,
        {
          data: fileTranslate,
          id: nanoid(),
          name: file.name.match(/(.*)\.[^.]+$/)[1],
          ext: (file.name.match(/\.[0-9a-z]+$/i)[0] || "").substring(1),
          tag: "meetingComment",
        },
      ]);
    }, [500]);
  };

  // удаляет файлы
  const onDeletedFile = (keyId) => {
    setAllFiles(allFiles.filter((file) => file.id !== keyId));
  };

  const scrollHandler = useCallback(
    (e) => {
      const { scrollHeight, scrollTop, clientHeight } = e.currentTarget;
      if (
        scrollHeight - 5 <= scrollTop + clientHeight &&
        fetchComments.length === 20 * pageNumNew
      ) {
        // throttle - чтобы вызывать функцию не чаще, чем через заданный минимальный интервал.
        let f1000 = throttle(() => setPageNumNew(pageNumNew + 1), 1500);
        f1000();
      }
      if (scrollTop === 0 && pageNum) {
        // throttle - чтобы вызывать функцию не чаще, чем через заданный минимальный интервал.
        let f1000 = throttle(() => setPageNum(pageNum + 1), 1500);
        f1000();
      }
      // scrollTop = scrollHeight;
    },
    [pageNum, fetchComments, pageNumNew]
  );

  // на подгрузку старых комментов
  useEffect(() => {
    if (fetchComments.length > 10) {
  /*    axios
        .get(
          `${
            process.env.REACT_APP_BACKEND
          }/api/v1/meeting/${meetingId}/comments/oldfrom/${
            fetchComments.length >= 5 && pageNum && fetchComments[0]?.id
          }`,
          {
            headers: {
              // Accept: "application/json",
              "secret-token": document.cookie.replace(
                /(?:(?:^|.*;\s*)access_token_jwt\s*\=\s*([^;]*).*$)|^.*$/,
                "$1"
              ),
              "company-id": company_id,
              Authorization: bearer,
            },
          }
        )
        .then((r) => setFetchComments([...r.data.data, ...fetchComments]))
        .catch((e) => {
          console.log(e);
        }); */
    }
  }, [pageNum, meetingId]);

  const newLoad = () => {
  /*  axios
      .get(
        `${
          process.env.REACT_APP_BACKEND
        }/api/v1/meeting/${meetingId}/comments/newfrom/${
          fetchComments.length && fetchComments[fetchComments.length - 1]?.id
        }`,
        {
          headers: {
            // Accept: "application/json",
            "secret-token": document.cookie.replace(
              /(?:(?:^|.*;\s*)access_token_jwt\s*\=\s*([^;]*).*$)|^.*$/,
              "$1"
            ),
            "company-id": company_id,
            Authorization: bearer,
          },
        }
      )
      .then((r) => setFetchComments([...fetchComments, ...r.data.data]))
      .then(() => {
        ref.current.scrollTo(0, ref.current.scrollHeight);
      })
      .catch((e) => {
        console.log(e);
      });  */
  };

  // для подгрузки новых
  useEffect(() => {
  /*  axios
      .get(
        `${
          process.env.REACT_APP_BACKEND
        }/api/v1/meeting/${meetingId}/comments/newfrom/${
          fetchComments?.length && fetchComments[fetchComments.length - 1]?.id
        }`,
        {
          headers: {
            // Accept: "application/json",
            "secret-token": document.cookie.replace(
              /(?:(?:^|.*;\s*)access_token_jwt\s*\=\s*([^;]*).*$)|^.*$/,
              "$1"
            ),
            "company-id": company_id,
            Authorization: bearer,
          },
        }
      )
      .then((r) => setFetchComments([...fetchComments, ...r.data.data]))
      // .then(() => console.log("комментраий получен по пагинации вниз"))
      .then(() => {
        // ref.current.scrollTo(0, ref.current.scrollHeight);
      })
      .then(() => console.log(fetchComments))
      .catch((e) => {
        console.log(e);
      });*/
  }, [pageNumNew, timerFetchData, meetingId]);

  const ast = message.split(" ");

  useEffect(() => {
    ast.forEach((item) => {
      if (item === "@") {
        setAppealWindow(true);
      } else {
        setAppealWindow(false);
      }
    });
  }, [message]);

  const autoAppeal = (item) => {
    return setMessage(message + item.name);
  };

  return (
    <div id="chatMeet">      
      {/*  <span style={{ marginBottom: "5px" }}>
          Комментарии для совещания: {meetingData.name}
        </span> */}
      <div className="chat-contentMeet" onScroll={scrollHandler} ref={ref}>
        <div className="chat-content-scrollMeet">
          {fetchComments?.map((comment) => (
            <>
              {comment.is_log && (
                <div
                  key={comment.id}
                  className="chat-messageMeet chat-messageMeet_text chat-message-systemMeet"
                >
                  <p style={{ whiteSpace: "pre-wrap" }}>{comment.text}</p>
                  <span
                    style={{
                      color: "gray",
                      fontSize: "11px",
                      paddingLeft: "5px",
                    }}
                  >
                    {new Date(comment.created_at).toLocaleTimeString([], {
                      hour: "2-digit",
                      minute: "2-digit",
                    })}{" "}
                    <span> </span>
                    {new Date(comment.created_at).toLocaleDateString()}
                  </span>
                </div>
              )}

              {comment.user_id === userId && (
                <div
                  key={comment.id}
                  className=" chat-messageMeet chat-messageMeet_text chat-message-myMeet"
                >
                  <h4 className="head_meassageMeet">
                    {user?.name + " " + user?.surname}
                 {/*  <span
                      onClick={() =>
                        replyMessage(comment.id, comment.user_id, comment.text)
                      }
                      className="chat-messageMeet__answer"
                    >
                      Ответить
                    </span> */}
                    <span
                      style={{marginLeft:"20px"}}
                      onClick={() => deletedMessage(comment.id)}
                      className="chat-messageMeet__deleted"
                    >
                      удалить
                    </span>
                  </h4>
                  <span className="chat-messageMeet__spanData">
                    {(() => {
                      const date = new Date(comment.created_at);
                      const dateMaster = new DateMaster(date);
                      return dateMaster.commentDate;
                    })()}
                  </span>
                  {!!comment.comment_id && (
                    <div className="chat-messageMeet__div-Otev">
                      {fetchComments?.map((com) => {
                        if (comment.comment_id === com.id) {
                          return (
                            <p
                              style={{ whiteSpace: "pre-wrap" }}
                              key={comment.id}
                            >
                              {com.text}
                            </p>
                          );
                        }
                      })}
                    </div>
                  )}
                  <p style={{ whiteSpace: "pre-wrap" }}>{comment.text}</p>
                  {comment.files.list?.map((file) => (
                    <a
                      href={`${REACT_APP_PUBLIC_MEETING}${file.url}`}
                      target="_blank" rel="noreferrer"
                    >
                      <div
                        key={file.id}
                        className="chat-message__file-downloadMeet chat-message__file-downloadMeet_in-message"
                      >
                        <img
                          style={{ height: "22px" }}
                          src={`${process.env.REACT_APP_PUBLIC_URL}/assets/FilePlus.png`}
                          alt="#"
                        />
                        <span style={{ color: "black" }}>{file.name}</span>
                      </div>
                    </a>
                  ))}
                </div>
              )}
              {comment.user_id !== userId && !comment.is_log && (
                <div className="chat-messageMeet ">
                  <div className="chat-messageMeet_img">
                    {(() => {
                      const userAcc = users.find(
                        (user) => user.id === comment.user_id
                      );
                      return userAcc?.name[0] + userAcc?.surname[0];
                    })()}
                  </div>
                  <div className="chat-messageMeet_text">
                    <h4>
                      {(() => {
                        const userAcc = users.find(
                          (user) => user.id === comment.user_id
                        );
                        if (userAcc ) return userAcc.name + " " + userAcc.surname;
                        else return "";
                      })()}
                   {/*   <span
                        className="chat-messageMeet__answer"
                        onClick={() =>
                          replyMessage(
                            comment.id,
                            comment.user_id,
                            comment.text
                          )
                        }
                      >
                        Ответить
                      </span> */}
                    </h4>
                    <span className="chat-messageMeet__spanData">
                      {(() => {
                        const date = new Date(comment.created_at);
                        const dateMaster = new DateMaster(date);
                        return dateMaster.commentDate;
                      })()}
                    </span>
                    {!!comment.comment_id && (
                      <div className="chat-messageMeet__div-Otev">
                        {fetchComments?.map((com) => {
                          if (comment.comment_id === com.id) {
                            return (
                              <p
                                style={{ whiteSpace: "pre-wrap" }}
                                key={comment.id}
                              >
                                {com.text}
                              </p>
                            );
                          }
                        })}
                      </div>
                    )}
                    <p style={{ whiteSpace: "pre-wrap" }}>{comment.text}</p>
                    {comment.files.list?.map((file) => (
                      <a
                        href={`${ REACT_APP_PUBLIC_MEETING}${file.url}`}
                        target="_blank" rel="noreferrer"
                      >
                        <div
                          key={file.id}
                          className="chat-message__file-downloadMeet  chat-message__file-downloadMeet_in-message"
                        >
                          <img
                            style={{ height: "22px" }}
                            src={`${process.env.REACT_APP_PUBLIC_URL}/assets/FilePlus.png`}
                            alt="#"
                          />
                          <span style={{ color: "black" }}>{file.name}</span>
                        </div>
                      </a>
                    ))}
                  </div>
                </div>
              )}
            </>
          ))}
        </div>
      </div>
   {meetingData.initiator_id === userId ||
    meetingData.chairman.user_id === userId ||
    meetingData.secretary.user_id === userId ||
    meetingData.members.find(item => item.user_id === userId)
    ? (  
      <div className="chat-bottom" style={{zIndex:"15"}} >
        {!!allFiles.length && (
          <div className="chat-bottom__files-wrapper">
            {allFiles?.map((file) => (
              <div key={file.id} className="chat-message__file-downloadMeet">
                <span
                  onClick={() => onDeletedFile(file.id)}
                  className="chat-message__file-downloadMeet_deletedBtn"
                >
                  ×
                </span>
                <img
                  style={{ height: "22px" }}
                  src={`${process.env.REACT_APP_PUBLIC_URL}/assets/FilePlus.png`}
                  alt="#"
                />

                <p>{file?.name}</p>
              </div>
            ))}
          </div>
        )}
        <input id={meetingId} type="file" onChange={sendFile} />
        <button
          className={`chat-btn ${
            allFiles.length > 0 ? "chat-btn__file-action" : ""
          } `}
          style={{
            marginRight: "8px",
          }}
        >
          <label htmlFor={meetingId} className="chat-bottomMeet_label">
            <img
              src={`${process.env.REACT_APP_PUBLIC_URL}/assets/input/Vector.svg`}
            />
          </label>
        </button>
        {/* <button className="chat-btn">
          <img src={`${process.env.REACT_APP_PUBLIC_URL}/assets/@.svg`} />
        </button> */}
        {checkOtvet && (
          <div className=" chat-bottomMeet__input_otvet">
            <div className=" chat-bottomMeet__input_otvet_icon">
              <ReplyIcon />
            </div>
            <div className=" chat-bottomMeet__input_otvet_text">
              <h5>{`${checkOtvetText.name}  ${checkOtvetText.surname}`}</h5>
              <p>{checkOtvetText.text}</p>
            </div>
            <div
              onClick={closeOtvetInInput}
              className=" chat-bottomMeet__input_otvet_iconClose"
            >
              <CloseIcon />
            </div>
          </div>
        )}{" "}
        { appealWindow && (
          <div className="window_appealMeet">
            {/*arr
              .map((item) => (
                <div className="item_appeal" onClick={() => autoAppeal(item)}>
                  {item.name + " " + item.surname}
                </div>
              ))*/}
          </div>
              )}
        <TextareaAutosize
          aria-label=""
          ref={refInputCheck}
          className="input-form"
          id={"rightPlate_name_textarea"}
          style={{
            width: "70%",
            marginLeft: "17px",
            marginRight: "25px",
            lineHeight: "20px",
          }}
          placeholder="Введите текст"
          value={message}
          onChange={(e) => changeInputValue(e.currentTarget.value)}
        />
        {!message.length && !allFiles.length ? (
          <button
            style={{ background: "#ececec", cursor: "default" }}
            className="chat-btn"
            id="send-messageMeet"
            onClick={clickFormButton}
          >
            <img
              src={`${process.env.REACT_APP_PUBLIC_URL}/assets/send-message.svg`}
            />
          </button>
        ) : (
          <button
            className="chat-btn"
            id="send-messageMeet"
            onClick={clickFormButton}
          >
            <img
              src={`${process.env.REACT_APP_PUBLIC_URL}/assets/send-message.svg`}
            />
          </button>
        )}
      </div>
      ) : null}
    </div>
  );
};

export default ChatMeeting;
