import { Action } from "redux";
import { IObjectsFormEditTaskData, IObjectsFormEditTaskDataSelect } from "./interfaces";
import {
  IExtraArrayValuesSelected,
  IFormAddTaskAdditionalFields,
  IObjectsFormAddTaskDataSelect
} from "../FormAddTask/interfaces";
import { IEditingObjectsFile } from "../../interfaces";

export enum FormEditTaskActionTypes {
  SET_DEFAULT_VALUES = "@@OBJECTS_FORM_EDIT_TASK/SET_DEFAULT_VALUES",
  SET_LOADING_TASK = "@@OBJECTS_FORM_EDIT_TASK/SET_LOADING_TASK",
  GET_TASK = "@@OBJECTS_FORM_EDIT_TASK/GET_TASK",
  GET_CHECKLIST = "@@OBJECTS_FORM_EDIT_TASK/GET_CHECKLIST",
  SET_CHECKLIST = "@@OBJECTS_FORM_EDIT_TASK/SET_CHECKLIST",
  GET_STAGES = "@@OBJECTS_FORM_EDIT_TASK/GET_STAGES",
  SET_STAGES = "@@OBJECTS_FORM_EDIT_TASK/SET_STAGES",
  GET_OBJECTS = "@@OBJECTS_FORM_EDIT_TASK/GET_OBJECTS",
  SET_OBJECTS = "@@OBJECTS_FORM_EDIT_TASK/SET_OBJECTS",
  SET_TASK_FORM_DATA = "@@OBJECTS_FORM_EDIT_TASK/SET_TASK_FORM_DATA",
  SET_FORM_OPEN = "@@OBJECTS_FORM_EDIT_TASK/SET_FORM_OPEN",
  SET_CHECK_TRIGGER = "@@OBJECTS_FORM_EDIT_TASK/SET_CHECK_TRIGGER",
  SET_SELECT_VALUES = "@@OBJECTS_FORM_EDIT_TASK/SET_SELECT_VALUES",
  SET_SELECT_SELECTED_VALUE = "@@OBJECTS_FORM_EDIT_TASK/SET_SELECT_SELECTED_VALUE",
  SET_CHECKBOX = "@@OBJECTS_FORM_EDIT_TASK/SET_CHECKBOX",
  SET_INPUT = "@@OBJECTS_FORM_EDIT_TASK/SET_INPUT",
  SET_OBSERVERS = "@@OBJECTS_FORM_EDIT_TASK/SET_OBSERVERS",
  UPDATE_TASK = "@@OBJECTS_FORM_EDIT_TASK/UPDATE_TASK",
  SET_CREATING_TASK = "@@OBJECTS_FORM_EDIT_TASK/SET_CREATING_TASK",
  SET_ACTIVE_TAB_INDEX = "@@OBJECTS_FORM_EDIT_TASK/SET_ACTIVE_TAB_INDEX",
  SET_STATUS = "@@OBJECTS_FORM_EDIT_TASK/SET_STATUS",
  SET_EXTRA_FILE_VALUE = "@@OBJECTS_FORM_EDIT_TASK/SET_EXTRA_FILE_VALUE",
  SET_EXTRA_INPUT_VALUE = "@@OBJECTS_FORM_EDIT_TASK/SET_EXTRA_INPUT_VALUE",
  SET_EXTRA_SELECT_SELECTED_VALUE = "@@OBJECTS_FORM_EDIT_TASK/SET_EXTRA_SELECT_SELECTED_VALUE",
  SET_EXTRA_SELECT_VALUES = "@@OBJECTS_FORM_EDIT_TASK/SET_EXTRA_SELECT_VALUES",
  SET_EXTRA_RADIO = "@@OBJECTS_FORM_EDIT_TASK/SET_EXTRA_RADIO",
  SET_EXTRA_SELECT_CHECKLIST_VALUES = "@@OBJECTS_FORM_EDIT_TASK/SET_EXTRA_SELECT_CHECKLIST_VALUES",
  SET_EXTRA_SELECTED_VALUE = "@@OBJECTS_FORM_EDIT_TASK/SET_EXTRA_SELECTED_VALUE",
  SET_CHECK_RULES = "@@OBJECTS_FORM_EDIT_TASK/SET_CHECK_RULES"
}

export interface setDefaultValuesAction
  extends Action<FormEditTaskActionTypes.SET_DEFAULT_VALUES> {
}

export interface setExtraFileValueAction
  extends Action<FormEditTaskActionTypes.SET_EXTRA_FILE_VALUE> {
  value: IEditingObjectsFile;
}

export interface setExtraInputValueAction
  extends Action<FormEditTaskActionTypes.SET_EXTRA_INPUT_VALUE> {
  value: string;
  serverId: string
}

export interface setExtraSelectSelectedValueAction
  extends Action<FormEditTaskActionTypes.SET_EXTRA_SELECT_SELECTED_VALUE> {
  value: IObjectsFormAddTaskDataSelect;
}

export interface setExtraSelectValuesAction
  extends Action<FormEditTaskActionTypes.SET_EXTRA_SELECT_VALUES> {
  values: IObjectsFormAddTaskDataSelect[];
}

export interface setExtraRadioAction
  extends Action<FormEditTaskActionTypes.SET_EXTRA_RADIO> {
  value: string;
  serverId: string
}

export interface setTaskFormDataAction
  extends Action<FormEditTaskActionTypes.SET_TASK_FORM_DATA> {
  taskFormData: IObjectsFormEditTaskData|null;
  additionalFields: any
}

export interface getStagesAction
  extends Action<FormEditTaskActionTypes.GET_STAGES> {
}

export interface setObjectsAction
  extends Action<FormEditTaskActionTypes.SET_OBJECTS> {
  objects: any;
}

export interface getObjectsAction
  extends Action<FormEditTaskActionTypes.GET_OBJECTS> {
}

export interface setChecklistAction
  extends Action<FormEditTaskActionTypes.SET_CHECKLIST> {
  data: any;
}

export interface getChecklistAction
  extends Action<FormEditTaskActionTypes.GET_CHECKLIST> {
  taskId: string|number
}

export interface setStagesAction
  extends Action<FormEditTaskActionTypes.SET_STAGES> {
  stages: IObjectsFormEditTaskDataSelect[];
}

export interface setLoadingTaskAction
  extends Action<FormEditTaskActionTypes.SET_LOADING_TASK> {
  isLoadingTask: boolean;
}

export interface getTaskAction
  extends Action<FormEditTaskActionTypes.GET_TASK> {
  id: string|number;
}

export interface setActiveTabIndexAction
  extends Action<FormEditTaskActionTypes.SET_ACTIVE_TAB_INDEX> {
  activeTabIndex: number;
}

export interface setCreatingTaskAction
  extends Action<FormEditTaskActionTypes.SET_CREATING_TASK> {
  isCreatingTask: boolean
}

export interface updateTaskAction
  extends Action<FormEditTaskActionTypes.UPDATE_TASK> {
  data: object
}

export interface setFormOpenAction
  extends Action<FormEditTaskActionTypes.SET_FORM_OPEN> {
  isFormOpen: boolean
}

export interface setCheckTriggerAction
  extends Action<FormEditTaskActionTypes.SET_CHECK_TRIGGER> {
  checkTrigger: string
}

export interface setSelectObserversAction
  extends Action<FormEditTaskActionTypes.SET_OBSERVERS> {
  data: number[]
}

export interface setSelectValuesAction
  extends Action<FormEditTaskActionTypes.SET_SELECT_VALUES> {
  data: IObjectsFormAddTaskDataSelect[]
  name: keyof IObjectsFormEditTaskData
}

export interface setSelectSelectedValueAction
  extends Action<FormEditTaskActionTypes.SET_SELECT_SELECTED_VALUE> {
  data: IObjectsFormAddTaskDataSelect
  name: keyof IObjectsFormEditTaskData
}

export interface setExtraSelectChecklistValuesAction
  extends Action<FormEditTaskActionTypes.SET_EXTRA_SELECT_CHECKLIST_VALUES> {
  data: IExtraArrayValuesSelected|IExtraArrayValuesSelected[]
}

export interface setExtraSelectedValueAction
  extends Action<FormEditTaskActionTypes.SET_EXTRA_SELECTED_VALUE> {
  name: keyof IFormAddTaskAdditionalFields,
  data: IExtraArrayValuesSelected|IExtraArrayValuesSelected[],
}

export interface setCheckboxAction
  extends Action<FormEditTaskActionTypes.SET_CHECKBOX> {
  value: boolean
  name: keyof IObjectsFormEditTaskData
}

export interface setInputAction
  extends Action<FormEditTaskActionTypes.SET_INPUT> {
  value: string|Date
  name: keyof IObjectsFormEditTaskData
}

export interface setStatusAction
  extends Action<FormEditTaskActionTypes.SET_STATUS> {
  status_id: number
}

export interface setCheckRulesAction
  extends Action<FormEditTaskActionTypes.SET_CHECK_RULES> {
  rules: any
}

export type FormEditTaskAction =
  | setCheckRulesAction
  | setChecklistAction
  | getChecklistAction
  | setObjectsAction
  | getObjectsAction
  | setDefaultValuesAction
  | setStatusAction
  | setExtraFileValueAction
  | setExtraInputValueAction
  | setExtraSelectSelectedValueAction
  | setExtraSelectValuesAction
  | setExtraRadioAction
  | setExtraSelectChecklistValuesAction
  | setExtraSelectedValueAction
  | setStagesAction
  | getStagesAction
  | setTaskFormDataAction
  | setLoadingTaskAction
  | getTaskAction
  | setActiveTabIndexAction
  | setCreatingTaskAction
  | updateTaskAction
  | setCheckTriggerAction
  | setFormOpenAction
  | setSelectObserversAction
  | setSelectValuesAction
  | setSelectSelectedValueAction
  | setInputAction
  | setCheckboxAction;
