import React, { useState } from "react";
import { connect } from "react-redux";
import { State } from "../../../../../rootReducer";
import PreloaderLocal from "../../../../../common/components/PreloaderLocal/PreloaderLocal";
import { ReactComponent as CloseIcon } from "../../../../../img/TrueIcons/close.svg";
import {
  createBP,
  setBPFormOpen,
  setBPFullFormOpen,
  setExtraFormBPDefaultOpen,
  setNormalFormBp, 
  setNormalFormDefaultValuesBp,
  updateBP
} from "../../../actions";
import TypicalTasksBp from "./components/TypicalTasksBp/TypicalTasksBp";
import { TextareaAutosize } from "@material-ui/core";
import { INormalFormBPData, ITaskBP } from "../../../interfaces";
import { errorValidationMessages } from "../../../constants";
import { TypeBPFilterActivity } from "../../../action_types";
import OtherBP from "./components/OtherBP/OtherBP";

import "./NormalFormBP.scss";

const NormalFormBP: React.FC<{
  idBPEditing?: string|number,
  isCreatingBP: boolean;
  isEditForm: boolean;
  isFormTasksOpen: boolean;
  isFullFormBPOpen: boolean;
  setTaskFormOpen: (mode: boolean) => void;
  setTaskFullFormOpen: (mode: boolean) => void;
  name?: string;
  setNormalFormBp: (mode: INormalFormBPData) => void;
  createBP: (data: object) => void;
  tasksItems: ITaskBP[],
  bp: any;
  activity?: TypeBPFilterActivity;
  isExtraFormBPDefaultOpen: boolean;
  setExtraFormBPDefaultOpen: (mode: boolean) => void;
  updateBP: (data: object) => void,
  setNormalFormDefaultValuesBp: () => void
}> = ({
  idBPEditing,
  isCreatingBP,
  isEditForm,
  isFormTasksOpen,
  setTaskFormOpen,
  setTaskFullFormOpen,
  name,
  setNormalFormBp,
  createBP,
  tasksItems,
  bp,
  activity,
  isFullFormBPOpen,
  isExtraFormBPDefaultOpen,
  setExtraFormBPDefaultOpen,
  updateBP,
  setNormalFormDefaultValuesBp
}) => {
  const errorMessages = {
    required: errorValidationMessages.REQUIRED,
  };
  const [errorState, setErrorState] = useState<any>({
    name: '',
    timeValue: '',
    executor: ''
  });
  const [isFormValid, setIsFormValid] = useState<boolean>(false);

  const setErrorHelper = (field, errorType) => {
    setErrorState(state => {
      const newState = {...state};
      if(field) {
        newState[field] = errorMessages[errorType];
      }
      return newState;
    });
  }
  const onChangeNameHandler = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    checkHelper('name', e.target.value);
    setNormalFormBp({name: e.target.value, activity: activity});
  }

  const checkHelper = (type?: string, string?: string): boolean => {
    let isValid = true;
    //on change
    if(type === 'name' && !string?.length) {
      setErrorHelper('name', 'required')
      isValid = false;
    }

    // On submit
    if(!type && !name?.length) {
      setErrorHelper('name', 'required')
      isValid = false;
    }
    
    if(isValid) {
      setErrorState(state => {
        let newState = {...state};
        
        if(type) {
          return {
            ...newState,
            [type]: '' 
          };
        }
        
        return state;
      });
    }
    
    setIsFormValid(isValid);
    
    return isValid;
  };
  
  const collectData = (): object => {
    let dataObject = {
        name,
        idBPEditing,
        activity,
        tasksItems
      };

    return dataObject;
  };
  
  const onSubmitHandler = () => {
    const data = collectData();
    if(isEditForm) updateBP(data);
    else createBP(data); 
  };
  
  const onCloseHandler = () => {
    setTaskFormOpen(!isFormTasksOpen);
    setTaskFullFormOpen(false);

    setNormalFormDefaultValuesBp();
  };

  const onOpenExtraForm = () => {
    setExtraFormBPDefaultOpen(false);
    setTaskFullFormOpen(true)
  }
  
  return (
    <div className="normal-form-bp">
      <div className="normal-form-bp__content">
        <div className="normal-form-bp__title-wr">
          {!isEditForm && (
            <h2 className="normal-form-bp__title">Добавляем бизнес-процесс</h2>
          )}
          
          {isEditForm && (
            <h2 className="normal-form-bp__title">
              Редактируем бизнес-процесс <span>"{name}"</span>
            </h2>
          )}

          <button className="normal-form-bp__close"
                  onClick={onCloseHandler}
          >
            <CloseIcon />
          </button>
        </div>
      </div>

      <h3 className="title_options">Базовые параметры</h3>
        
        <TextareaAutosize
          className={"name-typical-task" +
            (errorState.name?.length > 0 ? ' normal-form__input--error' : '')
          }
          placeholder="* Наименование бизнес-процесса"
          onChange={(e) => {
            onChangeNameHandler(e);
          }}
          value={name}
        />

        {errorState.name?.length > 0 && (
          <div className="normal-form__error-message">
            {errorMessages.required}
          </div>
        )}
      
        <div className="title_options">Состав типовых задач</div>

        <div className={'normal-form-bp__typical-tasks' +
                      (!isFullFormBPOpen ? ' normal-form-bp__typical-tasks--disabled' : '')
        }>
          {tasksItems?.length ? 
            <TypicalTasksBp /> : 
            "В бизнес-процесс пока не добавлено ни одной типовой задачи"
          }
        </div>
      
        {isFullFormBPOpen && !isExtraFormBPDefaultOpen ?
          <div className="normal-form-bp__other-full">Работайте с формой справа</div> :

          <div onClick={() => onOpenExtraForm()} className="normal-form-bp__other">
            {isFullFormBPOpen ? "Добавить типовую задачу" : "Настроить состав типовых задач"}
          </div>
        }

      {isEditForm && (
        <div className="normal-form__other">
          <OtherBP id={bp.id}/>
        </div>
      )}

      <div className="normal-form-bp__btns">
        <button className={"normal-form-bp__btn normal-form-bp__btn--submit" +
               (isCreatingBP ? " normal-form-bp__btn--show-preloader" : "")
              }
              onClick={onSubmitHandler}
              disabled={!name}
        >
          <span className="normal-form-bp__btn-title">
            {isEditForm && (
              <>Сохранить бизнес-процесс</>
            )}

            {!isEditForm && (
              <>Добавить бизнес-процесс</>
            )}
          </span>
          <span className="normal-form-bp__btn-preloader">
            <PreloaderLocal />
          </span>
        </button>

        <button className="normal-form-bp__btn normal-form-bp__btn--cancel"
          onClick={onCloseHandler}
        >
          <span className="normal-form-bp__btn-title">Отменить</span>
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = (state: State) => {
  return {
    isCreatingBP: state.businessPage.isCreatingBP,
    isFormTasksOpen: state.businessPage.isFormBPOpen,
    isEditForm: state.businessPage.isEditForm,
    idBPEditing: state.businessPage.normalFormBP.idBPEditing,
    name: state.businessPage.normalFormBP.name,
    tasksItems: state.businessPage.normalFormBP.tasksItems,
    bp: state.businessPage.businessProcess,
    activity: state.businessPage.normalFormBP.activity,
    isFullFormBPOpen: state.businessPage.isFullFormBPOpen,
    isExtraFormBPDefaultOpen: state.businessPage.extraFormBP.isExtraFormBPDefaultOpen
  };
};

const mapDispatchToProps = {
  setTaskFullFormOpen: setBPFullFormOpen,
  setTaskFormOpen: setBPFormOpen,
  setNormalFormBp,
  createBP,
  setExtraFormBPDefaultOpen,
  updateBP,
  setNormalFormDefaultValuesBp
};

export default connect(mapStateToProps, mapDispatchToProps)(NormalFormBP);
