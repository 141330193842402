import { Reducer } from "redux";
import { FormAddTaskAction, FormAddTaskActionTypes } from "./action_types";
import { IObjectsFormAddTaskState } from "./interfaces";
import { priorityNames, priorityValues } from "../../constants";

const initialState: IObjectsFormAddTaskState = {
  isCreatingTask: false,
  isFormOpen: false,
  // isFormOpen: true,
  checkTrigger: '',
  data: {
    objectId: '',
    objectSectionData: [],
    private: false, 
    objects: {
      isLoading: false,
      selectedValue: {value: '', label: 'Введите значение...'},
      data: []
    },
    stages: {
      isLoading: false,
      selectedValue: {value: '1', label: 'ПИР'}, // Для первой реализации значение всегда будет ПИР
      data: [
        {value: '11', label: 'ПИР'}, // Для первой реализации значение всегда будет ПИР
      ]
    },
    typicalTasks: {
      isLoading: false,
      selectedValue: {value: '', label: 'Введите значение...'},
      data: []
    },
    title: '',
    priorities: {
      isLoading: false,
      selectedValue: {value: priorityValues.MEDIUM, label: priorityNames.MEDIUM},
      data: [
        {value: priorityValues.LOW, label: priorityNames.LOW},
        {value: priorityValues.MEDIUM, label: priorityNames.MEDIUM},
        {value: priorityValues.HIGH, label: priorityNames.HIGH},
        {value: priorityValues.URGENT, label: priorityNames.URGENT},
      ]
    },
    executor: {
      isLoading: false,
      selectedValue: {value: '', label: 'Введите значение...'},
      data: []
    },
    delegation: false,
    spectators: {
      usersData: [],
      usersDataIsLoading: false,
      usersIds: []
    },
    startDate: '',
    endDate: '',
    file: {
      id: '',
      name: '',
      link: '',
      type: '',
      size: ''
    },
    description: '',
    fullForm: {
      checklist: [],
      extra: []
    }
  }
}

export type FormAddTaskState = typeof initialState;

const FormAddTaskReducer: Reducer<FormAddTaskState, FormAddTaskAction> = (
  state = initialState,
  action
) => {
  switch(action.type) {
    case FormAddTaskActionTypes.SET_FORM_OPEN:
      return {
        ...state,
        isFormOpen: action.isFormOpen
      }
      
    case FormAddTaskActionTypes.SET_OBJECT_ID:
      return {
        ...state,
        data: {
          ...state.data,
          objectId: action.id
        }
      }
      
    case FormAddTaskActionTypes.SET_OBJECT_SECTION_DATA:
      return {
        ...state,
        data: {
          ...state.data,
          objectSectionData: action.objectData
        }
      }
      
    case FormAddTaskActionTypes.SET_FORM_DEFAULT_VALUES:
      return {
        ...initialState
      }
      
    case FormAddTaskActionTypes.SET_FILE:
      return {
        ...state,
        data: {
          ...state.data,
          file: action.file
        }
      }
      
    case FormAddTaskActionTypes.SET_CREATING_TASK:
      return {
        ...state,
        isCreatingTask: action.isCreatingTask
      }
      
    case FormAddTaskActionTypes.SET_CHECK_TRIGGER:
      return {
        ...state,
        checkTrigger: action.checkTrigger
      }
      
    case FormAddTaskActionTypes.SET_OBSERVERS:
      return {
        ...state,
        data: {
          ...state.data,
          spectators: {
            ...state.data.spectators,
            usersIds: action.data
          }
        }
      }
      
    case FormAddTaskActionTypes.SET_SELECT_VALUES:
      return {
        ...state,
        data: {
          ...state.data,
          [action.name]: {
            // @ts-ignore
            ...state.data[action.name],
            data: action.data
          }
        }
      }

    case FormAddTaskActionTypes.SET_SELECT_SELECTED_VALUE:
      return {
        ...state,
        data: {
          ...state.data,
          [action.name]: {
            // @ts-ignore
            ...state.data[action.name],
            selectedValue: action.data
          }
        }
      }

    case FormAddTaskActionTypes.SET_CHECKBOX:
      return {
        ...state,
        data: {
          ...state.data,
          [action.name]: action.value
        }
      }


    case FormAddTaskActionTypes.SET_INPUT:
      return {
        ...state,
        data: {
          ...state.data,
          [action.name]: action.value
        }
      }

    default:
      return state;
  }
}

export default FormAddTaskReducer;
