import React, { FC, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { State } from "../../../../rootReducer";
import { IObjectsFormEditTaskData } from "./interfaces";
import { nanoid } from "nanoid";
import { ReactComponent as CloseIcon } from "../../../../img/TrueIcons/close.svg";
import PreloaderLocal from "../../../../common/components/PreloaderLocal/PreloaderLocal";
import {
  setCheckTrigger,
  setFormOpen,
  setActiveTabIndex,
  getStages,
  updateTask,
  getObjects
} from "./actions";
import Fade from "@material-ui/core/Fade";
import { AppBar, Box, Tab, Tabs, Typography } from "@material-ui/core";
import DescriptionTab from "./components/DescriptionTab/DescriptionTab";
import { TabIdsObjectEditTaskForm } from "./constants";
import ExecutionTab from "./components/ExecutionTab/ExecutionTab";
import { IFormAddTaskAdditionalFields } from "../FormAddTask/interfaces";
import CommentsTab from "./components/CommentsTab/CommentsTab";
import useCheckRolesMeridian from "../../../../utils/hooks/useCheckRolesMeridian";
import { CheckContextsObjects } from "../../constants";

import './FormEditTask.scss';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Fade in={value === index}>
      <div
        hidden={value !== index}
        id={`extra-form-tabpanel-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            <Typography component='div'>{children}</Typography>
          </Box>
        )}
      </div>
    </Fade>
  );
}

const FormEditTask: FC<{
  isLoadingTask: boolean,
  isCreatingTask: boolean,
  isFormOpen: boolean,
  activeTabIndex: number,
  setActiveTabIndex: (activeTabIndex: number) => void,
  formData: IObjectsFormEditTaskData,
  setFormOpen: (isFormOpen: boolean) => void,
  setCheckTrigger: (checkTrigger: string) => void,
  updateTask: (data: object) => void,
  getStages: () => void,
  additionalFields: IFormAddTaskAdditionalFields,
  getObjects: () => void,
  currentUser: any
}> = ({
  isLoadingTask,
  isCreatingTask,
  isFormOpen,
  activeTabIndex,
  setActiveTabIndex,
  formData,
  setFormOpen,
  setCheckTrigger,
  updateTask,
  getStages,
  getObjects,
  additionalFields,
  currentUser
}) => {
  const formTasksRef = useRef<HTMLDivElement | null>(null);
  const [extraFilesToUpload, setExtraFilesToUpload] = useState<any>();
  const [oldFormData, setOldFormData] = useState<IObjectsFormEditTaskData|null>(null);

  const { isUserCanEditTask } = useCheckRolesMeridian(CheckContextsObjects.IS_USER_CAN_EDIT_TASK, {
    authorId: formData?.author.id,
  });


  const onCloseHandler = () => {
    setFormOpen(false);
  };
  
  const collectData = (): object => {
    const dataObject: any = {
      taskId: formData.taskId,
      extra: {
        checklist: additionalFields.checklist,
        input: additionalFields.inputSelected,
        select: additionalFields.selectSelectedValue,
        radio: additionalFields.radioSelected,
        checkboxes: additionalFields.checkboxesSelected,
        filesToUpload: extraFilesToUpload
      }
    };
    
    dataObject.project_section_id = formData.project_section_id;
    dataObject.project_id = formData.project_id;
    
    if(formData.status_id !== oldFormData?.status_id) {
      dataObject.status_id = formData.status_id;
    }
    
    if(formData.startDate !== oldFormData?.startDate) {
      dataObject.startDate = formData.startDate;
    }
    
    if(formData.endDate !== oldFormData?.endDate) {
      dataObject.endDate = formData.endDate;
    }
    
    if(formData.spectators.usersIds !== oldFormData?.spectators.usersIds) {
      dataObject.spectators = formData.spectators.usersIds;
    }
    
    if(formData.executor.selectedValue.value !== oldFormData?.executor.selectedValue.value) {
      dataObject.executor = formData.executor.selectedValue.value;
    }
    
    if(formData.priorities.selectedValue.value !== oldFormData?.priorities.selectedValue.value) {
      dataObject.priority = formData.priorities.selectedValue.value;
    }
    
    if(formData.title !== oldFormData?.title) {
      dataObject.title = formData.title;
    }
    
    if(formData.description !== oldFormData?.description) {
      dataObject.description = formData.description;
    }
    return dataObject;
  };
  
  const onSubmitHandler = () => {
    setCheckTrigger(nanoid(6));

    let isFormHaveError = true;
    let data: any = {};
    
    setTimeout(() => {
      // TODO удалить, для тестирования
      // data = collectData();
      // updateTask(data);
      // return;
      
      isFormHaveError = !!(formTasksRef.current?.querySelector('[class*="error"]'));
      
      if(!isFormHaveError) {
        data = collectData();
        updateTask(data);
      }
    });
  };

  const onChangeTabHandler = (event: React.ChangeEvent<{}>, newValue: number) => {
    setActiveTabIndex(newValue);
  };

  const onSetExtraFilesHandler = (files: FileList) => {
    setExtraFilesToUpload(files);
  };
  
  useEffect(() => {
    if(isFormOpen) {
      // getStages(); // В первом релизе не будет
      
      getObjects();
    }
    else {
      setOldFormData(null);
    }
  }, [isFormOpen, formData.project_id]);

  useEffect(() => {
    if(formData) {
      if(!oldFormData?.taskId.toString().length) setOldFormData({ ...formData });
    }
  }, [formData]);

  useEffect(() => {
    if(currentUser?.id === formData.executor?.selectedValue?.value){
      setActiveTabIndex(1);
    }
  },[isFormOpen, formData.executor?.selectedValue?.value])


  return (
    <div className={
      "form-edit-task-objects-page" +
      (isFormOpen ? " form-edit-task-objects-page--open" : "") +
      (isLoadingTask ? " form-edit-task-objects-page--show-preloader" : "")
    }
         ref={formTasksRef}
    >
      <div className="form-edit-task-objects-page__content">
        <div className="form-edit-task-objects-page__preloader">
          <PreloaderLocal size="big" />
        </div>
        
        <div className="form-edit-task-objects-page__title-wr">
          <h2 className="form-edit-task-objects-page__title">
            <div className='form-edit-task-objects-page__title-text'>{formData?.title}</div>
          </h2>

          <button className="form-edit-task-objects-page__close"
                  onClick={onCloseHandler}
          >
            <CloseIcon />
          </button>
        </div>

        <div className="form-edit-task-objects-page__tabs">
          <AppBar className="form-edit-task-objects-page__tabs-header" position="static">
            <Tabs value={activeTabIndex} onChange={onChangeTabHandler}>
              <Tab label="Описание" />
              <Tab label="Исполнение" />
              <Tab label={
                "Комментарии" + (formData.commentsCount ? ' (' + formData.commentsCount + ')' : '')
              }/>
              <Tab label="История" disabled />
              <Tab label="Связи" disabled />
            </Tabs>
          </AppBar>
          
          <div className="form-edit-task-objects-page__tab-content">
            <TabPanel value={activeTabIndex} index={TabIdsObjectEditTaskForm.DESCRIPTION_TAB}>
              <DescriptionTab isUserCanEdit={isUserCanEditTask} />
            </TabPanel>

            <TabPanel value={activeTabIndex} index={TabIdsObjectEditTaskForm.EXECUTION_TAB}>
              <ExecutionTab isUserCanEdit={isUserCanEditTask} onSetExtraFiles={onSetExtraFilesHandler} />
            </TabPanel>

            <TabPanel value={activeTabIndex} index={TabIdsObjectEditTaskForm.COMMENTS_TAB}>
              <CommentsTab isUserCanEdit={isUserCanEditTask} />
            </TabPanel>

            <TabPanel value={activeTabIndex} index={TabIdsObjectEditTaskForm.HISTORY_TAB}>
              История
            </TabPanel>

            <TabPanel value={activeTabIndex} index={TabIdsObjectEditTaskForm.CONNECTIONS_TAB}>
              Связи
            </TabPanel>
          </div>
        </div>

        {(activeTabIndex === TabIdsObjectEditTaskForm.DESCRIPTION_TAB ||
          activeTabIndex === TabIdsObjectEditTaskForm.EXECUTION_TAB) && (
          <div className="form-edit-task-objects-page__btns">
            <button className={"form-edit-task-objects-page__btn form-edit-task-objects-page__btn--submit" +
              (isCreatingTask ? " form-edit-task-objects-page__btn--show-preloader" : "")
            }
                    onClick={onSubmitHandler}
                    disabled={!isUserCanEditTask}
            >
              <span className="form-edit-task-objects-page__btn-title">Сохранить задачу</span>
              <span className="form-edit-task-objects-page__btn-preloader">
              <PreloaderLocal />
            </span>
            </button>

            <button className="form-edit-task-objects-page__btn form-edit-task-objects-page__btn--cancel"
                    onClick={onCloseHandler}
            >
              <span className="form-edit-task-objects-page__btn-title">Отменить</span>
            </button>
          </div>
          )}
      </div>
    </div>
  )
}

const mapStateToProps = (state: State) => ({
  isLoadingTask: state.objectsFormEditTask.isLoadingTask,
  isCreatingTask: state.objectsFormEditTask.isCreatingTask,
  isFormOpen: state.objectsFormEditTask.isFormOpen,
  activeTabIndex: state.objectsFormEditTask.activeTabIndex,
  formData: state.objectsFormEditTask.data,
  additionalFields: state.objectsFormEditTask.additionalFields,
  currentUser: state.commonInfo.currentUserInfo
});

const mapDispatchToProps = {
  setActiveTabIndex,
  setFormOpen,
  setCheckTrigger,
  updateTask,
  getObjects,
  getStages
}

export default connect(mapStateToProps, mapDispatchToProps)(FormEditTask);
