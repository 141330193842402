import { File, IMeta, User } from "../../common/types";
import {
  LocalTimeSheetRecordAction,
  TasksActionTypes,
  checkDeleteTaskAction,
  deleteTaskAction,
  deleteTaskFromStateAction,
  getTasksAction,
  procedureFinishTask,
  setCurrentPageAction,
  setDeleteModalOpenAction,
  setLoadingTasksAction,
  setSearchTasksAction,
  setSelectedUserIdAction,
  setSortConditionAction,
  setStatusFilterAction,
  setTasksListAction,
  setTasksMetaAction,
  timesheetCacheRecordAction,
  updateStatusActionFalse,
  updateStatusActionTrue,
  updateTaskAction,
  updateTaskStoreAction,
} from "./action_types";
import { IDeleteTaskModal, ISortConditions } from "./interfaces";

export const getTasks = (params?: {}, action?: string): getTasksAction => ({
  type: TasksActionTypes.GET_TASKS,
  params,
  action
});

export const setTasksList = (tasks: any[], action?: string): setTasksListAction => ({
  type: TasksActionTypes.SET_TASKS_LIST,
  tasks,
  action
});

export const setSortCondition = (
  sortCondition: ISortConditions
): setSortConditionAction => ({
  type: TasksActionTypes.SET_SORT_CONDITION,
  sortCondition,
});

export const setCurrentPage = (currentPage: number): setCurrentPageAction => ({
  type: TasksActionTypes.SET_CURRENT_PAGE,
  currentPage,
});

export const deleteTaskFromState = (
  id: string | number
): deleteTaskFromStateAction => ({
  type: TasksActionTypes.DELETE_TASK_FROM_STATE,
  id,
});

export const deleteTask = (id: string | number): deleteTaskAction => ({
  type: TasksActionTypes.DELETE_TASK,
  id,
});

export const checkDeleteTask = (
  id: string | number
): checkDeleteTaskAction => ({
  type: TasksActionTypes.CHECK_DELETE_TASK,
  id,
});

export const deleteTaskFromTasksState = (
  id: string | number
): deleteTaskFromStateAction => ({
  type: TasksActionTypes.DELETE_TASK_FROM_STATE,
  id,
});

export const setFiltersTasks = (
  filterObject: Object
): setStatusFilterAction => ({
  type: TasksActionTypes.SET_FILTERS,
  filterObject,
});

export const setDeleteTaskModalOpen = ({
  taskId,
  isDeleteModalOpen,
  isShowPreloader,
  isShowMenuPreloader,
  statusFromServer,
}: IDeleteTaskModal): setDeleteModalOpenAction => ({
  type: TasksActionTypes.SET_DELETE_MODAL_DATA,
  taskId,
  isDeleteModalOpen,
  isShowPreloader,
  isShowMenuPreloader,
  statusFromServer,
});

export const setLoadingTasks = (isLoading: boolean): setLoadingTasksAction => ({
  type: TasksActionTypes.SET_LOADING_TASK,
  isLoading,
});

export const setSearchTasks = (
  searchTasks: string | null
): setSearchTasksAction => ({
  type: TasksActionTypes.SET_SEARCH_TASKS,
  searchTasks,
});

export const setSelectedUserId = (id: number): setSelectedUserIdAction => ({
  type: TasksActionTypes.SET_SELECTED_USER_ID,
  id,
});

export const setTasksMeta = (tasksMeta: IMeta): setTasksMetaAction => ({
  type: TasksActionTypes.SET_TASKS_META,
  tasksMeta,
});

export const updateStatusWindowTrue = (
  statusWindow: boolean
): updateStatusActionTrue => ({
  type: TasksActionTypes.UPDATE_STATUS_WINDOW_TRUE,
  statusWindow,
});

export const updateStatusWindowFalse = (
  statusWindow: boolean
): updateStatusActionFalse => ({
  type: TasksActionTypes.UPDATE_STATUS_WINDOW_FALSE,
  statusWindow,
});

export const LocalTimeSheetRecord = (
  actionType: string,
  taskId: number,
  task_load_sum?: number | null,
  timesheetId?: number | null,
  status_id?: number | null,
  commentText?: string,
  commentFiles?: File[]
): LocalTimeSheetRecordAction => ({
  type: TasksActionTypes.LOCAL_TIMESHEET_RECORD,
  actionType, // add, clear_all
  taskId, //  number
  task_load_sum, // number
  status_id, //number

  commentText, // str
  commentFiles, // File

  timesheetId, // number
});

export const launchProcedureFinishTask = (
  show: boolean,
  taskId: number,
  short_version: boolean
): procedureFinishTask => ({
  type: TasksActionTypes.TASK_DONE_CONFIRMATION,
  show,
  taskId,
  short_version,
});

export const timesheetCacheRecord = (
  actionType: string,
  task_id?: number,
  task_load_sum?: number,
  status_id?: number
): timesheetCacheRecordAction => ({
  type: TasksActionTypes.TIMESHEET_CACHE_RECORD,
  actionType,
  task_id,
  task_load_sum,
  status_id,
});

export const updateTask = (
  taskId: number | string,
  data: object
): updateTaskAction => ({
  type: TasksActionTypes.UPDATE_TASK,
  taskId,
  data,
});

export const taskPageUpdateTaskStore = (task: any): updateTaskStoreAction => ({
  type: TasksActionTypes.UPDATE_TASK_STORE,
  task,
});
