import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import Draggable, { ControlPosition } from 'react-draggable';
import {
  getAllElementsPositions,
  getCurrentElementsPosition,
  getIndexUnderElement
} from "../../../../helpers/helpersCore";
import { taskBPPositions } from "../../../../constants";
import {ReactComponent as CloseIcon} from '../../../../../../img/TrueIcons/close.svg';
import {ReactComponent as BombIcon} from '../../../../../../img/TrueIcons/bomb.svg';
import { ITaskFlattenBP } from "../../../../interfaces";
import { getDuration } from "../../../../helpers/helpersCommon";

import './TypicalTaskBp.scss';

const TypicalTaskBp: React.FC<{
  name: string,
  className?: string,
  currentIndex: number,
  isParent: boolean,
  isLastParent: boolean,
  controlledPosition?: ControlPosition|undefined,
  connectionAmount: number,
  data: ITaskFlattenBP,
  onStart: (currentIndex: number) => void,
  onStop: (currentIndex: number) => void,
  onDropBetween: (currentIndex: number, overIndex: number) => void,
  onMoveBetween: (currentIndex: number, overIndex: number, partPosition: number) => void,
  onDropCenter: (currentIndex: number, overIndex: number) => void,
  onMoveCenter: (currentIndex: number, overIndex: number, action?: string) => void,
  onDelete: (currentIndex: number, action: string) => void,
}> = ({
  name,
  className,
  currentIndex,
  isParent,
  isLastParent,
  controlledPosition = undefined,
  connectionAmount,
  data,
  onStart,
  onStop,
  onDropBetween,
  onMoveBetween,
  onDropCenter,
  onMoveCenter,
  onDelete
}) => {
  const CHILDREN_OFFSET_X = 50;
  const MARGIN_BOTTOM = 20;
  const LINE_TOP_OFFSET = 44;
  const CONNECTION_HEIGHT = '100%';
  
  const nodeRef = useRef<HTMLDivElement>(null);
  const [connectionHeight, setConnectionHeight] = useState<string>(CONNECTION_HEIGHT);
  
  const [positionLocal, setPositionLocal] = useState<ControlPosition|undefined>({
    x: 0, 
    y: 0
  });
  const [defaultPosition, setDefaultPosition] = useState<ControlPosition|undefined>({
    x: isParent ? 0 : CHILDREN_OFFSET_X,
    y: 0
  });
  
  const onStartHandler = (e, data) => {
    onStart(currentIndex);
  }
  
  const onDragHandler = (e, data) => {
    const allElementsPositionsLocal = getAllElementsPositions('.typical-task-bp');
    
    if(isParent) {
      setPositionLocal({x: 0, y: data.y});
    }
    else {
      setPositionLocal({x: CHILDREN_OFFSET_X, y: data.y});
    }
    
    const currentElementPosition = getCurrentElementsPosition(nodeRef);
    const overIndex = getIndexUnderElement(currentIndex, currentElementPosition, allElementsPositionsLocal);

    const overElPosition = allElementsPositionsLocal[overIndex];

    if(isBetweenPosition(currentElementPosition, overElPosition)) {
      const upElPosition = allElementsPositionsLocal[overIndex - 1];

      const partPosition = getBetweenPartPosition(currentElementPosition, overElPosition, upElPosition);

      onMoveBetween(currentIndex, overIndex, partPosition);
    }
    
    if(isCenterPosition(currentElementPosition, overElPosition)) {
      onMoveCenter(currentIndex, overIndex);
    }
    else {
      onMoveCenter(currentIndex, overIndex, 'reset');
    }
  }

  const onStopHandler = (e, data) => {
    const allElementsPositionsLocal = getAllElementsPositions('.typical-task-bp');

    const currentElementPosition = getCurrentElementsPosition(nodeRef);
    const overIndex = getIndexUnderElement(currentIndex, currentElementPosition, allElementsPositionsLocal);

    const overElPosition = allElementsPositionsLocal[overIndex];
    
    // Переместили между
    if(isBetweenPosition(currentElementPosition, overElPosition)) {
      onDropBetween(currentIndex, overIndex);
      
      return;
    }

    // Переместили на задачу
    if(isCenterPosition(currentElementPosition, overElPosition)) {
      onDropCenter(currentIndex, overIndex);
      onStop(currentIndex);

      return;
    }
    
    onStop(currentIndex);
    setPositionLocal({x: positionLocal?.x ?? 0, y: 0});
  }

  const isBetweenPosition = (
    currentElementPosition: DOMRect, 
    overElPosition: DOMRect
  ): boolean => {
    return overElPosition && (currentElementPosition.top <= overElPosition.top);
  }
  
  const getBetweenPartPosition = (
    currentElementPosition: DOMRect,
    overElPosition: DOMRect,
    upElPosition?: DOMRect
  ): number => {
    if(!upElPosition) return taskBPPositions.HIDDEN;
    
    const NUMBER_PARTS = 2;
    const gapBetweenTwoElements = +(overElPosition.top - upElPosition.bottom).toFixed();
    const delta = currentElementPosition.top - upElPosition.bottom;

    const result = (gapBetweenTwoElements - delta) < (gapBetweenTwoElements / NUMBER_PARTS) 
                    ? taskBPPositions.PARENT 
                    : taskBPPositions.CHILD;

    return result;
  }
  
  const isCenterPosition = (currentElementPosition: DOMRect, overElPosition: DOMRect) => {
    return overElPosition && (currentElementPosition.top > overElPosition.top);
  }
  
  useEffect(() => {
    if(typeof controlledPosition !== undefined) {
      if(isParent) {
        setPositionLocal(controlledPosition);
        setDefaultPosition({
          x: controlledPosition?.x ?? 0, 
          y: controlledPosition?.y ?? 0
        });
      }
      else {
        setPositionLocal({x: CHILDREN_OFFSET_X, y: 0});
      }
    }
  }, [controlledPosition]);

  useEffect(() => {
    if(!isParent) {
      setDefaultPosition({x: CHILDREN_OFFSET_X, y: 0});
      setConnectionHeight(100 + '%');
    }
  }, [isParent]);
  
  useLayoutEffect(() => {
    if(isParent && nodeRef.current) {
      let res = (nodeRef.current.offsetHeight + MARGIN_BOTTOM) * (connectionAmount + 1) - LINE_TOP_OFFSET;
      
      if(isLastParent) res -= MARGIN_BOTTOM;

      setConnectionHeight(res + 'px');
    }
    else {
      setConnectionHeight(100 + '%');
    }
  }, [connectionAmount, isLastParent]);
  
  return (
     <Draggable
      axis="y"
      handle=".typical-task-bp__content"
      defaultPosition={defaultPosition}
      position={positionLocal}
      onStart={onStartHandler}
      onDrag={onDragHandler}
      onStop={onStopHandler}
      nodeRef={nodeRef}
    >
      <div className={'typical-task-bp' + ' ' + className} ref={nodeRef}>
        <div className="typical-task-bp__position">
          <div className="typical-task-bp__position-line"></div>
        </div>
        
        <div className="typical-task-bp__connect-wr"
              style={{height: connectionHeight}}
        >
          <div className="typical-task-bp__connect typical-task-bp__connect--serial">
            <div></div>
          </div>
          <div className="typical-task-bp__connect typical-task-bp__connect--parallel">
            <div></div><div></div>
            
            <button className="typical-task-bp__connect-break"
                    onClick={() => onDelete(currentIndex, 'break')}
                    title='Разорвать связь'
            >
              <BombIcon />
            </button>
          </div>
          <div className="typical-task-bp__connect typical-task-bp__connect--parent-child-parallel">
            <div></div><div></div>
          </div>
        </div>
        
        <div className='typical-task-bp__content'>
          <div className="typical-task-bp__content-col">
            {data.id}
          </div>
          
          <div className="typical-task-bp__content-col">
            {data.title}
          </div>
          
          <div className="typical-task-bp__content-col">
            {data.time?.length ? getDuration(data.time) : <span>&mdash;</span>}
          </div>
          
          <div className="typical-task-bp__content-col">
            <button className="typical-task-bp__content-btn typical-task-bp__content-btn--break"
                    onClick={() => onDelete(currentIndex, 'break')}
                    title='Разорвать связь'
            >
              <BombIcon />
            </button>
            
            <button className="typical-task-bp__content-btn typical-task-bp__content-btn--close"
                    title='Удалить задачу'
                    onClick={() => onDelete(currentIndex, 'delete')}
            >
              <CloseIcon />
            </button>
          </div>
        </div>
      </div>
    </Draggable>
  )
}

export default TypicalTaskBp;
