import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { State } from "../../../../../../../rootReducer";
import { TypicalTreatyActual } from "../../../../../interfaces";
import { updateStageActual } from "../../../../../actions";
import Select from "react-select";

type Props = {
  disabled?: boolean;
  isSingleSelect?: boolean;
  stageId: number;
  updateStageActual: (stageId: number, status: number) => void;
  stage: any;
};

const ActualSelectStage: React.FC<Props> = ({
  disabled,
  isSingleSelect,
  stageId,
  updateStageActual,
  stage,
}) => {
  const [placeholder, setPlaceholder] = useState<any>({
    label: TypicalTreatyActual.NOT_ACTUAL_TEXT,
    value: TypicalTreatyActual.NOT_ACTUAL_STATUS,
  });

  const defaultValues = [
    {
      label: TypicalTreatyActual.ACTUAL_TEXT,
      value: TypicalTreatyActual.ACTUAL_STATUS,
    },
    {
      label: TypicalTreatyActual.NOT_ACTUAL_TEXT,
      value: TypicalTreatyActual.NOT_ACTUAL_STATUS,
    },
  ];

  useEffect(() => {
    setPlaceholder(() =>
      stage.status
        ? defaultValues[0]
        : defaultValues[1]
    );
  }, []);

  const addHandler = (value) => {
    setPlaceholder(defaultValues[value]?.label);
    updateStageActual(stageId, value.value);
  };

  return (
    <>
      <Select
        type="text"
        value={placeholder}
        options={defaultValues}
        className="react-select"
        classNamePrefix="react-select"
        onChange={(value) => {
          addHandler(value);
        }}
      />
    </>
  );
};

const mapStateToProps = (state: State) => {
  return {};
};

const mapDispatchToProps = {
  updateStageActual,
};

export default connect(mapStateToProps, mapDispatchToProps)(ActualSelectStage);
