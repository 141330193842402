import React from "react";
import { State } from "../../../../rootReducer";
import { connect } from "react-redux";
import { nanoid } from "nanoid";
import TermSelectObjects from "../ui/selectors/TermSelectObjects";
import ResponsibleSelectObjects from "../ui/selectors/ResponsibleSelectObjects";
import PersonalToggleObjects from "../ui/toggle/PersonalToggleObjects";
import { 
  setIsEditObjectForm, 
  setObjectsEditFormDefaultValues, 
  setObjectsEditingFormOpen 
} from "../../actions";
import { CheckContextsObjects } from "../../constants";
import useCheckRolesMeridian from "../../../../utils/hooks/useCheckRolesMeridian";

import "./FilterObjects.scss";

const FiltersObjects: React.FC<{
  setObjectsEditingFormOpen: (isEditingObjectsFormOpen: boolean) => void,
  setObjectsEditFormDefaultValues: (trigger: string) => void,
  setIsEditObjectForm: (isEditObjectsForm: boolean) => void
}> = ({
  setObjectsEditingFormOpen,
  setObjectsEditFormDefaultValues,
  setIsEditObjectForm
}
) => {
  const { isUserCanAddObject } = useCheckRolesMeridian(CheckContextsObjects.IS_USER_CAN_ADD_OBJECT);

  const formOpenHandler = () => {
    setObjectsEditFormDefaultValues(nanoid(6));
    setIsEditObjectForm(false);
    setObjectsEditingFormOpen(true);
  };

  return (
    <div className="filters-objects">
      <div className="filters-objects__left">
        <div className="filters-objects__filters-selectors">
          <div className="filters-objects__filters-selector-item">
            <div className="filters-objects__filters-selector-item-title">
              Остаток дней
            </div>
            <div className="filters-objects__filters-selector-item-element">
              <TermSelectObjects />
            </div>
          </div>

          <div className="filters-objects__filters-selector-item">
            <div className="filters-objects__filters-selector-item-title">
            Ответственный РП
            </div>
            <div className="filters-objects__filters-selector-item-element">
              <ResponsibleSelectObjects/>
            </div>
          </div>
        </div>

        <div className="filters-objects__filters-toggle">
          <PersonalToggleObjects />
        </div>
      </div>

      {isUserCanAddObject && (
        <div className="filters-objects__right">
          <button
            className="filters-objects__add-btn"
            type="button"
            onClick={formOpenHandler}
          >
            + Добавить объект
          </button>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: State) => {
  return {};
};

const mapDispatchToProps = {
  setObjectsEditingFormOpen,
  setObjectsEditFormDefaultValues,
  setIsEditObjectForm
};

export default connect(mapStateToProps, mapDispatchToProps)(FiltersObjects);
