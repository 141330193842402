import React, { ChangeEvent, FC, useState } from "react";
import { connect } from "react-redux";
import { State } from "../../../../../../rootReducer";
import InputObjects from "../../../ui/InputObjects/InputObjects";
import DatePickerTrue from "../../../ui/DatePickerTrue/DatePickerTrue";
import moment from "moment";
import TextAreaObjects from "../../../ui/TextAreaObjects/TextAreaObjects";
import { IEditingObjectsFile, IEditingObjectsFormData } from "../../../../interfaces";
import FileItem from "../../../ui/FileItem/FileItem";
import { setObjectEditingFormData } from "../../../../actions";
import { nanoid } from "nanoid";
import { datePickerFormat, datePickerText } from "../../../ui/DatePickerTrue/constants";
import ResponsibilitySelect from "./components/ResponsibilitySelect/ResponsibilitySelect";
import { checkDecimals } from "../../../../helpers";
import { getImportantDocuments } from "../../../../selectors";

import './DescriptionTab.scss';

const DescriptionTab: FC<{
  isEditObjectsForm: boolean,
  formData: IEditingObjectsFormData,
  setObjectEditingFormData: (editingFormData: IEditingObjectsFormData) => void
  formCommonFileHandler: (files: FileList) => void,
  onDeleteFile?: (id: string|number, name: string) => void
  isUserCanEdit: boolean,
  importantDocuments: IEditingObjectsFile[]
}> = ({
  isEditObjectsForm,
  formData,
  setObjectEditingFormData,
  formCommonFileHandler,
  onDeleteFile,
  isUserCanEdit,
  importantDocuments
}) => {
  const [dateErrorMessages, setDateErrorMessages] = useState<any>({
    startDate: [],
    endDate: []
  });
  
  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>, name: keyof IEditingObjectsFormData) => {
    let value = e.target.value;
    
    if(name === 'cost') {
      value = checkDecimals(e.target.value);
    }
    
    setObjectEditingFormData({[name]: value});
  };

  const onChangeTextArea = (e: React.ChangeEvent<HTMLTextAreaElement>, name: keyof IEditingObjectsFormData) => {
    setObjectEditingFormData({[name]: e.target.value});
  };
  
  const onChangeDate = (date: Date|null, name: keyof IEditingObjectsFormData) => {
    checkDateHelper(date, name);
    setObjectEditingFormData({[name]: date});
  }
  
  const checkDateHelper = (date: Date|null, name: keyof IEditingObjectsFormData) => {
    setDateErrorMessages({startDate: [], endDate: []});

    let isStartDateLessEndDate = false;
    let isEndDateLessStartDate = false;

    if(name === 'startDate') {
      isStartDateLessEndDate = moment(formData.endDate, datePickerFormat.STANDARD_DATE).isBefore(date, 'date');
    }
    if(name === 'endDate') {
      isEndDateLessStartDate = moment(formData.startDate, datePickerFormat.STANDARD_DATE).isAfter(date, 'date');
    }

    if(isStartDateLessEndDate) {
      setDateErrorMessages((state) => {
        return {
          ...state,
          startDate: [
            ...state.startDate,
            'Дата начала не может быть больше даты окончания'
          ]
        }
      });
    }

    if(isEndDateLessStartDate) {
      setDateErrorMessages((state) => {
        return {
          ...state,
          endDate: [
            ...state.endDate,
            'Дата окончания не может быть меньше даты начала'
          ]
        }
      });
    }
  };
  
  const onChangeFile = (e: ChangeEvent<HTMLInputElement>) => {
    const { files } = e.currentTarget;
    let filesDataArr: IEditingObjectsFile[] = [];

    if(files) {
      for(let i = 0; i < files.length; ++i) {
        filesDataArr.push({
          id: nanoid(6),
          name: files[i].name,
          type: files[i].type,
          size: Math.trunc(files[i].size / 1024).toLocaleString()
        });
      }

      formCommonFileHandler(files)

      setObjectEditingFormData({
        entryDocuments: filesDataArr
      });
    }
  }

  return (
    <div className='description-tab'>
      {!isUserCanEdit && (
        <div className="description-tab--disabled"></div>
      )}

      {isEditObjectsForm && (
        <div className="description-tab__info">
          <div className="description-tab__info-row">
            <div className="description-tab__info-left">Автор</div>
            <div className="description-tab__info-right">
              {formData.author?.surname + ' ' + formData.author?.name}
            </div>
          </div>

          {formData?.createdAt && (
            <div className="description-tab__info-row">
              <div className="description-tab__info-left">Дата создания</div>
              <div className="description-tab__info-right">{moment(formData.createdAt).format("DD.MM.YYYY")}</div>
            </div>
          )}
        </div>
      )}
      
      <div className="description-tab__blocks">
        <div className="description-tab__block">
          <h3 className="description-tab__block-title">Объект</h3>

          <div className="description-tab__block-items">
            <div className="description-tab__block-item">
              <div className="description-tab__block-item-left">* Наименование объекта</div>
              <div className="description-tab__block-item-right">
                <InputObjects
                  name='name'
                  onChange={(e) => onChangeInput(e, 'name')}
                  value={formData.name}
                />
              </div>
            </div>
            
            <div className="description-tab__block-item">
              <div className="description-tab__block-item-left">* Заявитель</div>
              <div className="description-tab__block-item-right">
                <InputObjects
                  name='declarant'
                  onChange={(e) => onChangeInput(e, 'declarant')}
                  value={formData.declarant}
                />
              </div>
            </div>
            
            <div className="description-tab__block-item">
              <div className="description-tab__block-item-left">* Титул</div>
              <div className="description-tab__block-item-right">
                <TextAreaObjects
                  name='title'
                  onChange={(e) => onChangeTextArea(e, 'title')}
                  value={formData.title}
                />
              </div>
            </div>
            
            <div className="description-tab__block-item">
              <div className="description-tab__block-item-left">* Номер SAP</div>
              <div className="description-tab__block-item-right">
                <InputObjects
                  name='numberSap'
                  onChange={(e) => onChangeInput(e, 'numberSap')}
                  value={formData.numberSap?.toString()}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="description-tab__block">
          <h3 className="description-tab__block-title">Договор</h3>

          <div className="description-tab__block-items">
            <div className="description-tab__block-item">
              
              <div className="description-tab__block-item-left">* Ответственный РП</div>
              <div className="description-tab__block-item-right">
                <div className="description-tab__block-item-select">
                  <ResponsibilitySelect name='responsible' />
                </div>
              </div>
            </div>
            
            <div className="description-tab__block-item">
              <div className="description-tab__block-item-left">Номер договора</div>
              <div className="description-tab__block-item-right">
                <InputObjects
                  name='contractNumber'
                  onChange={(e) => onChangeInput(e, 'contractNumber')}
                  value={formData.contractNumber?.toString()}
                  placeholder='Без договора...'
                />
              </div>
            </div>
            
            <div className="description-tab__block-item">
              <div className="description-tab__block-item-left">Дата начала <br/> договора</div>
              <div className="description-tab__block-item-right">
                <div className="description-tab__datepicker-wr">
                  <DatePickerTrue
                    format={datePickerFormat.STANDARD_DATE}
                    minDateMessage={datePickerText.MIN_DATE_MESSAGE}
                    maxDateMessage={datePickerText.MAX_DATE_MESSAGE}
                    errorMessages={dateErrorMessages.startDate}
                    date={moment(formData.startDate)}
                    onChangeDate={(date) => onChangeDate(date, 'startDate')}
                  />
                </div>
              </div>
            </div>

            <div className="description-tab__block-item">
              <div className="description-tab__block-item-left">Дата окончания <br/> договора</div>
              <div className="description-tab__block-item-right">
                <div className="description-tab__datepicker-wr">
                  <DatePickerTrue
                    format={datePickerFormat.STANDARD_DATE}
                    minDateMessage={datePickerText.MIN_DATE_MESSAGE}
                    maxDateMessage={datePickerText.MAX_DATE_MESSAGE}
                    errorMessages={dateErrorMessages.endDate}
                    date={moment(formData.endDate)}
                    onChangeDate={(date) => onChangeDate(date, 'endDate')}
                  />
                </div>
              </div>
            </div>

            <div className="description-tab__block-item">
              <div className="description-tab__block-item-left">Стоимость договора <br/> без НДС</div>
              <div className="description-tab__block-item-right">
                <InputObjects
                  inputType='number'
                  name='cost'
                  onChange={(e) => onChangeInput(e, 'cost')}
                  value={formData.cost?.toString()}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="description-tab__block">
          <h3 className="description-tab__block-title">Входные документы</h3>

          {(formData.entryDocuments && formData.entryDocuments?.length > 0) && (
            <div className="description-tab__block-items">
              {formData.entryDocuments.map(document => (
                <div className="description-tab__block-item-file" key={document.id}>
                  <FileItem 
                    data={document}
                    onDelete={onDeleteFile}
                  />
                </div>
              ))}
            </div>
          )}
          
          {!formData.entryDocuments?.length && (
            <div className="description-tab__block-no-docs">Документов нет...</div>
          )}
          
          <div className="description-tab__block-item-file-add-btn-wr">
            <button className="description-tab__block-item-file-add-btn">
              <input className="description-tab__block-item-file-add-btn-input"
                     type="file"
                     multiple={true}
                     onClick={(e) => e.currentTarget.value = ''}
                     onChange={onChangeFile}
              />
              <span className="description-tab__block-item-file-add-btn-title">Прикрепить еще</span>
            </button>
          </div>
        </div>
        
        {(importantDocuments?.length > 0) && (
          <div className="description-tab__block">
            <h3 className="description-tab__block-title">Важные документы</h3>

            <div className="description-tab__block-items">
              <div className="description-tab__block-item-file">
                {importantDocuments.map(document => (
                  <div className="description-tab__block-item-file" key={document.id}>
                    <FileItem data={document} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>

      {(formData.importantInfo && formData.importantInfo?.length > 0) && (
        <div className="description-tab__info description-tab__info--margin description-tab__info--style">
          <div className="description-tab__info-title">Важная информация</div>

          <div className="description-tab__block-item-file">
            {formData.importantInfo.map(info => (
              <div className="description-tab__info-row" key={info.id}>
                <div className="description-tab__info-left">{info?.name}</div>
                <div className="description-tab__info-right">{info?.value}</div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

const mapStateToProps = (state: State) => {
  return {
    isEditObjectsForm: state.objectsPage.isEditObjectsForm,
    formData: state.objectsPage.editingObjectsForm.data,
    importantDocuments: getImportantDocuments(state)
  }
};

const mapDispatchToProps = {
  setObjectEditingFormData
}

export default connect(mapStateToProps, mapDispatchToProps)(DescriptionTab);
