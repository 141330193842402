import React, { FC } from "react";
import { connect } from "react-redux";
import { State } from "../../../../../../rootReducer";
import { IEditingObjectsFormData } from "../../../../interfaces";
import FileItem from "../../../ui/FileItem/FileItem";

import './DocumentsTab.scss';

const DocumentsTab: FC<{
  formData: IEditingObjectsFormData
}> = ({
  formData
}) => {
  return (
    <div className='documents-tab'>
      <div className="documents-tab__blocks">
        {(formData.entryDocuments && formData?.entryDocuments?.length > 0) && (
          <div className="documents-tab__block">
            <div className="documents-tab__block-title">Входные документы</div>

            <div className="documents-tab__block-items">
              {formData.entryDocuments.map(document => (
                <div className="documents-tab__block-item" key={document.id}>
                  <FileItem data={document} />
                </div>
              ))}
            </div>
          </div>
        )}

        {(formData.documents && formData.documents?.length > 0) && (
          <>
            {formData.documents.map(document => (
              <div className="documents-tab__block" key={document.id}>
                <div className="documents-tab__block-title">{document.title}</div>

                <div className="documents-tab__sub-blocks">
                  <div className="documents-tab__sub-block">

                    {document.subtitle && (
                      <div className="documents-tab__sub-title">{document.subtitle}</div>
                    )}

                    {(document.documentChildrens.length > 0) && (
                      <div className="documents-tab__block-items">
                        {document.documentChildrens.map(documentChildren => (
                          <div className="documents-tab__block-item" key={documentChildren.id}>
                            <FileItem data={documentChildren} />
                          </div>
                        ))}
                      </div>
                    )}

                    {!(document.documentChildrens.length > 0) && (
                      <div className="documents-tab__defaults">Нет документов...</div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </>
        )}

        {!formData.entryDocuments?.length && !formData.documents?.length && (
          <div className="documents-tab__defaults">Нет документов...</div>        
        )}
      </div>
    </div>
  )
}

const mapStateToProps = (state: State) => {
  return {
    formData: state.objectsPage.editingObjectsForm.data
  }
};

const mapDispatchToProps = {
  
}

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsTab);
