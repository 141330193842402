import React, { FC } from "react";
import InputTypicalTask from "../../ui/InputTypicalTask/InputTypicalTask";
import ArrayTypicalTask from "../../ui/ArrayTypicalTask/ArrayTypicalTask";
import { connect } from "react-redux";
import { State } from "../../../../../../rootReducer";
import { IExtraFormCheckboxTabData, TArrayValues } from "../../../../action_types";
import { setExtraFormCheckboxTabData } from "../../../../actions";
import { SelectedTabNameExtraForm } from "../../../../constants";

import './CheckboxTabTypicalTask.scss';

const CheckboxTabTypicalTask: FC<{
  title: string,
  arrayValues: TArrayValues[],
  setExtraFormCheckboxTabData: (checkboxTabData: IExtraFormCheckboxTabData) => void
}> = ({
  title,
  arrayValues,
  setExtraFormCheckboxTabData
}) => {
  const validationState = {
    input: false,
    array: false
  };
  
  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setExtraFormCheckboxTabData({title: e.target.value});
  };

  const onCheckHandler = ( isValid: boolean, type: string ) => {
    let isValidLocal = false;

    validationState[type] = isValid

    if(validationState.input && validationState.array) {
      isValidLocal = true;
    }

    setExtraFormCheckboxTabData({isValid: isValidLocal});
  }
  
  return (
    <div className='checkbox-tab-typical-task'>
      <div className="checkbox-tab-typical-task__input">
        <InputTypicalTask
          onCheck={onCheckHandler}
          onChange={onChangeInput}
          value={title}
        />
      </div>
      
      <div className="checkbox-tab-typical-task__array">
        <ArrayTypicalTask
          onCheck={onCheckHandler}
          arrayValues={arrayValues}
          selectedTabName={SelectedTabNameExtraForm.CHECKBOX_TAB} 
        />
      </div>
    </div>
  )
}

const mapStateToProps = (state: State) => {
  return {
    arrayValues: state.typicalTasksPage.extraForm.checkboxTab.arrayValues as TArrayValues[],
    title: state.typicalTasksPage.extraForm.checkboxTab.title as string
  }
};

const mapDispatchToProps = {
  setExtraFormCheckboxTabData
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckboxTabTypicalTask);
