import React, { useState, useRef, useCallback, useEffect } from "react";
import FileElem from "../../../common/components/FileElem/FileElem";
import { State } from "../../../rootReducer";
import { connect } from "react-redux";
import { Comment, Task, File as FileType, IMeta } from "../../../common/types";
import CommentElem from "./CommentElem";
import {
  deleteComment,
  uploadFile,
  deleteFile,
  setCommentText,
  sendComment,
  setParentComment,
  addCommentsList,
  getCommentsList,
  setComentList,
  setCommentFiles,
} from "../actions";
import ParentComment from "./ParentComment";
import { fetchData } from "../../../utils/fetchData";
import SendIcon from "@material-ui/icons/Send";
import { TextareaAutosize } from "@material-ui/core";
import { nanoid } from "nanoid";
import { translateFile } from "../../../utils/helpers/helpers";
import { IFileDataToServer } from "../../ObjectsPage/interfaces";
import { useInView } from "react-intersection-observer";

type Props = {
  commentList: Comment[];
  selectedTask: Task | null;
  commentFiles: FileType[];
  setCommentFiles: (files: FileType[]) => void;
  text: string;
  isSending: boolean;
  parentComment: Comment | null;
  deleteComment: (taskId: number, commentId: number) => void;
  uploadFile: (file: File) => void;
  deleteFile: (id: number | string) => void;
  setCommentText: (text: string) => void;
  sendComment: (
    task_Id?: number,
    commentText?: string,
    idRequestFrom?: number | string,
    requestFrom?: string,
    files?: IFileDataToServer[]
  ) => void;
  setParentComment: (parent: Comment | null) => void;
  addCommentsList: (commentList: Comment[]) => void;
  pageNumber: number;
  getCommentsList: (num: number) => void;
  setComentList: (list) => void;
  idRequestFrom?: number | string;
  requestFrom?: string;
  isFormOpen: boolean;
  commentsMeta: IMeta;
};

const CommentTab: React.FC<Props> = (props) => {
  const {
    commentList,
    text,
    isSending,
    commentFiles,
    selectedTask,
    parentComment,
    deleteComment,
    uploadFile,
    deleteFile,
    setCommentText,
    sendComment,
    setParentComment,
    addCommentsList,
    pageNumber,
    getCommentsList,
    setComentList,
    idRequestFrom,
    requestFrom,
    setCommentFiles,
    isFormOpen,
    commentsMeta,
  } = props;
  const [comments, setComments] = useState<any[]>([]);
  const [stopLoadingNewComments, setStopLoadingNewComments] =
    useState<boolean>(false);
  const [files, setFiles] = useState<IFileDataToServer[]>([]);

  useEffect(() => {
    getCommentsList(1);
  }, [isFormOpen]);

  useEffect(() => {
    if (requestFrom === "projects") {
      if (files?.length) {
        let filesData: FileType[] = [];

        files.forEach((file) => {
          filesData.push({
            id: nanoid(6),
            name: file.name,
            url: "#",
          });
        });

        setCommentFiles(filesData);
      }
    }
  }, [files]);

  const onChangeFile = (e) => {
    let files = e.currentTarget.files;

    if (requestFrom === "projects") {
      translateFile(files[0], setFiles);
    } else {
      if (!files) return;
      for (let i = 0; i < files?.length; i++) uploadFile(files[i]);
    }
  };

  // sendComment(undefined, undefined, idRequestFrom, requestFrom)
  const onChangeComment = (
    task_Id,
    commentText,
    idRequestFrom,
    requestFrom
  ) => {
    sendComment(undefined, undefined, idRequestFrom, requestFrom, files);
  };

  const onDeleteFile = (id: number | string) => {
    if (requestFrom === "projects") {
      if (commentFiles?.length) {
        const fileObj: any = {
          ...commentFiles.filter((file) => file.id === id),
        };
        const newFiles = [
          ...files.filter((file) => file.name === fileObj?.name),
        ];
        const newCommentFiles = [
          ...commentFiles.filter((file) => file.id !== id),
        ];

        setCommentFiles(newCommentFiles);
        setFiles(newFiles);
      }
    } else {
      deleteFile(id);
    }
  };

  useEffect(() => {
    (async () => {
      // if (pageNumber === 1) { // очищать лист если идет запрос на первую старницу комментариев
      //     let comment_list_node = document.getElementsByClassName('comment_list')[0]
      //     while (comment_list_node.firstChild) {
      //         comment_list_node.removeChild(comment_list_node.firstChild);
      //     }
      // }

      let url = "tasks";
      let taskId: string | number | undefined = selectedTask?.id;

      if (requestFrom === "projects") {
        url = "projects";
        taskId = idRequestFrom;
      }

      if (requestFrom === "taskMeridian") {
        url = "tasks";
        taskId = idRequestFrom;
      }

      if (!taskId) return;

      if (!isSending) {
        const commentListResponse = await fetchData.get(
          `/api/v1/${url}/${taskId}/comments?order=created_at&page=${pageNumber}&orderType=desc`
        );

        if (commentsMeta.last_page > pageNumber)
          setStopLoadingNewComments(true);

        if (commentListResponse) {
          if (pageNumber === 1) {
            setComments(commentListResponse);
            setComentList(commentListResponse);
          } else {
            setComments([...comments, ...commentListResponse]);
            setComentList([...commentList, ...commentListResponse]);
          }
        }
      }
    })();
  }, [pageNumber, selectedTask, isSending, commentFiles, idRequestFrom]);

  useEffect(() => {
    if (commentFiles.length) {
      let textarea_files = document.getElementsByClassName("textarea_files")[0]
        .parentNode as Element;
      for (let i = 0; i < textarea_files.classList.length; i++) {
        if (textarea_files.classList[i] === "empty") {
          textarea_files.classList.remove("empty");
        }
      }
    }
  }, [commentFiles]);

  let textarea_parent_ref = useRef<HTMLDivElement>(null);

  const { ref, inView, entry } = useInView({
    threshold: 0,
  });

  useEffect(() => {
    if (inView && pageNumber < commentsMeta.last_page) {
      getCommentsList(pageNumber + 1);
    }
  }, [inView]);

  return (
    <>
      <div className={"add_new_comment"}>
        <div className={"main_part empty"} ref={textarea_parent_ref}>
          <div className={"textarea_files"}>
            <TextareaAutosize
              aria-label="comment_textarea"
              placeholder="Введите комментарий"
              autoFocus
              onChange={(e) => {
                // @ts-ignore
                let parNode = e.currentTarget.parentNode.parentNode as Element;
                if (!e.currentTarget.value.length)
                  parNode.classList.add("empty");
                else parNode.classList.remove("empty");

                setCommentText(e.currentTarget.value);
              }}
              value={text}
            />

            {commentFiles.length > 0 && (
              <div className={"attachments"}>
                {commentFiles?.map((elem) => (
                  <FileElem
                    url={elem.url}
                    key={nanoid()}
                    id={+elem.id}
                    name={elem.name}
                    deleteHandler={onDeleteFile}
                  />
                ))}
              </div>
            )}
          </div>
          <div
            className={"send_button"}
            onClick={() => {
              getCommentsList(1);
              onChangeComment(undefined, undefined, idRequestFrom, requestFrom);
            }}
          >
            <SendIcon />
          </div>

          <label htmlFor="comment_files" className={"attach_file"}>
            <div className={"attachment_icon"} />
            <input
              onChange={(e) => onChangeFile(e)}
              type="file"
              id="comment_files"
              multiple={true}
            />
          </label>
        </div>
      </div>

      {parentComment && (
        <div className="answer_to">
          <ParentComment parentComment={parentComment} />
          <div onClick={() => setParentComment(null)} className="close_btn" />
        </div>
      )}
      <div className="comment_list">
        {comments.map((comment) => (
          <CommentElem
            key={comment.id}
            comment={comment}
            deleteHandler={() =>
              deleteComment(Number(idRequestFrom), comment.id)
            }
          />
        ))}
        <div ref={ref} style={{ height: "5px" }} />
      </div>
    </>
  );
};

const mapStateToProps = (state: State) => {
  return {
    pageNumber: state.taskInfoPlate.pageNum,
    commentList: state.taskInfoPlate.commentList,
    commentFiles: state.taskInfoPlate.commentFiles,
    selectedTask: state.taskInfoPlate.selectedTask,
    text: state.taskInfoPlate.text,
    isSending: state.taskInfoPlate.isSendingComment,
    parentComment: state.taskInfoPlate.parentComment,
    isFormOpen: state.objectsFormEditTask.isFormOpen,
    commentsMeta: state.taskInfoPlate.commentsMeta,
  };
};

const mapDispatchToProps = {
  deleteComment,
  uploadFile,
  deleteFile,
  setCommentText,
  sendComment,
  setParentComment,
  addCommentsList,
  getCommentsList,
  setComentList,
  setCommentFiles,
};

export default connect(mapStateToProps, mapDispatchToProps)(CommentTab);
