import React, { useEffect } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import styled from "styled-components";
import Trash from "../../../../../img/projectIcons/Trash";
import { connect } from "react-redux";
import PreloaderLocal from "../../../../../common/components/PreloaderLocal/PreloaderLocal";
import { State } from "../../../../../rootReducer";

import "./styles.scss";

interface IObjectMenuProps {
  id: number | string;
  anchorEl: HTMLElement | null;
  setAnchorEl: (arg0: React.MouseEvent<HTMLButtonElement> | null) => void;
  isShowMenuPreloader: boolean;
  isDeleteModalOpen?: boolean;
}

const MenuStyle = {
  marginTop: "45px",
};

const MenuItemStyle = {
  display: "flex",
  alignItems: "center",
  marginBottom: "5px",
  fontSize: "14px",
};

const MenuIcon = styled.div`
  margin-right: 10px;
  display: flex;
  align-items: center;

  > svg {
    stroke: #292a34;
  }
`;

const MenuTitle = styled.div``;

const ObjectMenu = ({
  id,
  anchorEl,
  setAnchorEl,
  isShowMenuPreloader,
  isDeleteModalOpen,
}: IObjectMenuProps) => {
  const clickTaskActionsCloseHandler = (e) => {
    setAnchorEl(null);
    e.stopPropagation();
  };

  const clickDeleteHandler = (id: string | number, e: any) => {
    e.stopPropagation();
  };

  return (
    <Menu
      className="task-menu-typical-task"
      anchorEl={anchorEl}
      open={!!anchorEl}
      onClose={(e) => clickTaskActionsCloseHandler(e)}
      style={MenuStyle}
    >
      {/* Секция не должна удаляться, только объекты и задачи */}
      {/*<MenuItem
        style={{ ...MenuItemStyle, marginBottom: 0 }}
        onClick={(e) => clickDeleteHandler(id, e)}
      >
        <MenuTitle
          className={
            "task-menu-typical-task__title-wr" +
            (isShowMenuPreloader
              ? " task-menu-typical-task__title-wr--show-preloader"
              : "")
          }
        >
          <div className="task-menu-typical-task__title">Удалить секцию</div>
          <div className="task-menu-typical-task__preloader">
            <PreloaderLocal />
          </div>
        </MenuTitle>
      </MenuItem>*/}
    </Menu>
  );
};

const mapStateToProps = (state: State) => {
  return {
    isShowMenuPreloader: state.objectsPage.isShowMenuPreloader,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ObjectMenu);
