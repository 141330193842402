/* Сложный фильтр по задачас в меню "Задачи"  */
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { State } from "../../../../../rootReducer";
import styled from "styled-components";
import {
  FilterTemplates,
  setFilters,
  ShowFiltersWindow,
} from "../../../../../common/actions";
import {
  convertFromStringToUSdateString,
  dateFormatUTC,
} from "../../../../../pages/TaskInfoPlate/reducer";
import MultySelect from "../../../../../common/components/Selects/MultySelect";
import { User } from "../../../../../common/types";
import TaskStatus from "../../../../../common/components/TaskStatus/TaskStatus";
import Button from "../../../../../common/components/newComponent/Buttons/Button";
import Icon from "../../../../../common/components/Icon";
import { nanoid } from "nanoid";
import Input from "../../../../../common/components/Inputs/Input";
import { useHistory, useLocation } from "react-router-dom";
import { useContext } from "react";
import { useStateContext } from "../../../../../../src/useStateContext";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import CustomDatePicker from "../../../../../common/components/DatePickers/DatePicker";
import {
  yellowStatus2,
  blueStatus,
  grayStatus,
  greenStatus,
  purpleStatus,
  redStatus,
} from "../../../../../common/components/TaskStatus/TaskStatus";
import { setCurrentPage } from "../../../actions";

type Props = {
  context: string;
  filters: any;
  current_filters: any;
  users: User[];
  statusList: any[];
  setFilters: (
    data_from?: string,
    date_to?: string,
    executors?: number[],
    authors?: number[],
    statuses?: number[],
    actual?: boolean
  ) => void;
  FilterTemplates: (data: {}) => void;
  ShowFiltersWindow: () => void;
  current_user: number;
  setCurrentPage: (mode: number) => void;
};

interface ITypeState {
  openPageTasks: boolean;
  setOpenPageTasks: (mode: boolean) => void;
  allEmployee: any;
  setAllEmployee: (mode: any) => void;
}

const LeftColItem = styled.div`
  display: flex;
  width: 152px;
  height: 32px;
  padding: 8px;
  border-radius: 8px;
  line-height: 17px;
  cursor: pointer;
  & > img[title="cross"] {
    display: none;
  }
  &:hover {
    transition: all 0.2s ease-out;
    background-color: #f3fafe;
    & > div {
      max-width: 118px;
    }
    & > img[title="cross"] {
      display: block;
    }
  }
  color: ${({ active }) => (active ? "#45BAF2" : "black")};
`;

const LeftColItemText = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 131px;
`;

const NewRow = styled.div`
  display: flex;
  color: black;
`;

const TitleForField = styled.div`
  width: 120px;
  height: 40px;
  padding: 12px 0 12px 8px;
  margin-right: 20px;
  margin-bottom: 9px;
  & > img {
    margin-right: 4px;
  }
`;

const MainWindow = styled.div`
  position: absolute;
  width: 560px;
  display: flex;
  flex-direction: column;
  top: ${({ context }) => (context === "tasks" ? "150px;" : "152px;")};
  left: ${({ context }) => (context === "tasks" ? "70px;" : "335px;")};
  z-index: 100;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgb(0 0 0 / 9%);
  padding: 16px;
  background-color: white;
`;

const FiltersTasksWindow: React.FC<Props> = ({
  context,
  filters,
  setFilters,
  users,
  statusList,
  FilterTemplates,
  ShowFiltersWindow,
  current_user,
  current_filters,
  setCurrentPage,
}) => {
  const {
    openPageTasks,
    setOpenPageTasks, // индикатор - если меняется - (не важно - true или false) - заходим на страницу tasks
    allEmployee,
    setAllEmployee, // Выбранны или нет все сотрудники в фильтре
  }: ITypeState = useContext(useStateContext);
  // self-close for window
  let plate = useRef<HTMLDivElement>(null);
  let history = useHistory();

  const [AddTemplate, setAddTemplate] = useState<boolean>(false);
  const [TemplateName, setTemplateName] = useState<string>("");

  function getStylesOfButton(status_id) {
    switch (status_id) {
      case 12:
      case 13:
        return greenStatus;
      case 10:
        return blueStatus;
      case 14:
        return redStatus;
      case 6:
      case 17:
        return yellowStatus2;
      case 2:
      case 3:
      case 4:
      case 5:
        return purpleStatus;
      case 15:
      case 11:
      case 1:
      case 9:
        return grayStatus;
      default:
        return grayStatus;
    }
  }

  function getObjStylesForStatus(status_id) {
    // получить стили кнопки из модуля со статусами
    let str = getStylesOfButton(status_id)[0].trim();
    let pos = 0;
    let returnObj = {
      marginBottom: "8px",
      width: "120px",
    };
    while (true) {
      let endOfKey = str.indexOf(":", pos);
      if (endOfKey === -1) break;
      let endOfValue = str.indexOf(";", endOfKey);

      returnObj[str.substr(pos, endOfKey - pos).trim()] = str
        .substr(endOfKey + 1, endOfValue - (endOfKey + 1))
        .trim();

      if (str.length > endOfValue + 1) pos = endOfValue + 1;
      else break;
    }
    return returnObj;
  }

  let set_some_filters = Boolean(
    current_filters.authors.length ||
      current_filters.executors.length ||
      current_filters.statuses.length ||
      current_filters.date_from !== "" ||
      current_filters.date_to !== ""
  );

  let order_default_templates = [
    "Все",
    "Исполнитель",
    "Автор",
    "Просроченные",
    "Архивные",
  ];
  let LS_filters_obj = filters.templates;

  // кнопка Сохранить у нового фильтра не должна быть доступна, если фильтр с таким набором параметров уже есть, или если нету имени
  let saveBtnAvailable = true;
  if (Object.keys(filters).length) {
    Object.keys(filters.templates).forEach((item) => {
      if (filters.templates[item].active) saveBtnAvailable = false;
    });
    if (saveBtnAvailable) {
      Object.keys(filters.default_templates).forEach((item) => {
        if (filters.default_templates[item].active) saveBtnAvailable = false;
      });
    }
  }

  useEffect(() => {
    if (allEmployee) {
      setFilters("", "", [], [], [], false);
      history.replace(`${current_user}`);
    }
  }, [openPageTasks]);

  const handleClickAway = () => {
    ShowFiltersWindow();
    setCurrentPage(1);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <MainWindow
        ref={plate}
        className="filter_plate filter-tasks-page"
        context={context}
      >
        {/*ЗАГОЛОВОК*/}
        <NewRow style={{ justifyContent: "space-between", height: "50px" }}>
          <div
            style={{ fontWeight: 500, fontSize: "16px", padding: "8px 0px" }}
          >
            Фильтр
          </div>
          <div
            style={{
              width: "94px",
              padding: "12px 16px",
              color: set_some_filters ? "black" : "#A8A8A8",
              cursor: set_some_filters ? "pointer" : "default",
            }}
            onClick={() => {
              if (set_some_filters) setFilters("", "", [], [], [], false);
              if (context === "tasks") history.replace(`${current_user}`);
              setCurrentPage(1);
            }}
          >
            Очистить
          </div>
          <div
            style={{
              width: "94px",
              padding: "12px 16px",
              color: "#00c",
              cursor: set_some_filters ? "pointer" : "default",
            }}
            onClick={() => {
              ShowFiltersWindow();
              setCurrentPage(1);
            }}
          >
            Закрыть
          </div>
        </NewRow>

        {/*ЛИСТ*/}
        <NewRow>
          <div
            style={{
              padding: "4px",
              width: "168px",
              borderRight: "1px solid #E0E0E0",
              marginRight: "8px",
            }}
          >
            {Object.keys(filters.default_templates).length &&
              order_default_templates.map((item) => {
                let def_temp = filters.default_templates;
                return (
                  <LeftColItem
                    key={nanoid()}
                    active={def_temp[item].active}
                    onClick={() => {
                      if (item === "Исполнитель" || item === "Автор") {
                        if (filters.default_templates[item].executors[0]) {
                          history.replace(
                            `${filters.default_templates[item].executors[0]}`
                          );
                        }
                        if (filters.default_templates[item].authors[0]) {
                          history.replace(
                            `${filters.default_templates[item].authors[0]}`
                          );
                        }
                      }

                      setFilters(
                        def_temp[item]["date_from"],
                        def_temp[item]["date_to"],
                        def_temp[item]["executors"],
                        def_temp[item]["authors"],
                        def_temp[item]["statuses"],
                        def_temp[item]["actual"]
                      );
                      setCurrentPage(1);
                    }}
                    style={{ justifyContent: "space-between" }}
                  >
                    <div>{item}</div>
                  </LeftColItem>
                );
              })}

            {Object.keys(filters.templates).map((item) => {
              return (
                <LeftColItem
                  key={nanoid()}
                  active={filters.templates[item].active}
                  onClick={() => {
                    let copied_temps = { ...filters.templates };
                    copied_temps[item]["active"] = true;
                    FilterTemplates(copied_temps);
                    setFilters(
                      LS_filters_obj[item]["date_from"],
                      LS_filters_obj[item]["date_to"],
                      LS_filters_obj[item]["executors"],
                      LS_filters_obj[item]["authors"],
                      LS_filters_obj[item]["statuses"]
                    );
                    setCurrentPage(1);
                  }}
                  style={{ justifyContent: "space-between" }}
                >
                  <LeftColItemText title={item}>{item}</LeftColItemText>
                  <Icon
                    title={"cross"}
                    name={"cross"}
                    onClick={(event) => {
                      event.stopPropagation();

                      let LSfilters = localStorage.getItem("filters");
                      if (LSfilters) {
                        let parsedLS = JSON.parse(LSfilters);
                        delete parsedLS.users_filters[item];
                        localStorage.setItem(
                          "filters",
                          JSON.stringify(parsedLS)
                        );

                        delete filters.templates[item];
                        FilterTemplates(filters.templates);
                      }
                    setCurrentPage(1);

                    }}
                  />
                </LeftColItem>
              );
            })}

            {!AddTemplate && (
              <LeftColItem
                active={false}
                onClick={() => {
                  setAddTemplate(!AddTemplate);
                  setCurrentPage(1);

                }}
              >
                <Icon name={"circle-plus"} style={{ marginRight: "4px" }} />
                <div>Добавить</div>
              </LeftColItem>
            )}
          </div>

          <div>
            {AddTemplate && (
              <NewRow>
                <TitleForField>
                  <Icon name={"filter"} />
                  Название
                </TitleForField>
                <Input
                  inputType={"text"}
                  placeholder={"Новый фильтр"}
                  value={TemplateName}
                  styleInput={{ height: "38px", width: "202px" }}
                  styleInputDiv={{ height: "40px" }}
                  title={"new name"}
                  changeHandler={(arg) => {
                    setTemplateName(arg);
                  }}
                />
              </NewRow>
            )}

            {/*ДАТА*/}
            <NewRow>
              <TitleForField>
                <Icon name={"calendar-black"} /> Дата
              </TitleForField>
              <NewRow>
                <CustomDatePicker
                  styles={{ width: "98.5px", marginRight: "8px" }}
                  date={
                    current_filters.date_from !== ""
                      ? current_filters.date_from
                      : undefined
                  }
                  placeholder={"C"}
                  maxDate={
                    current_filters.date_to !== ""
                      ? new Date(
                          convertFromStringToUSdateString(
                            current_filters.date_to
                          ) as string
                        )
                      : new Date("2100-01-01")
                  }
                  acceptHandler={(from) => {
                    let formatted = dateFormatUTC(from);
                    setFilters(
                      formatted.substr(0, formatted.length - 8) + "00:00:00"
                    );
                  }}
                />
                <CustomDatePicker
                  styles={{ width: "98.5px" }}
                  date={current_filters.date_to}
                  placeholder={"По"}
                  minDate={
                    current_filters.date_from !== ""
                      ? new Date(
                          convertFromStringToUSdateString(
                            current_filters.date_from
                          ) as string
                        )
                      : new Date("1900-01-01")
                  }
                  acceptHandler={(to) => {
                    let formatted = dateFormatUTC(to);
                    setFilters(
                      undefined,
                      formatted.substr(0, formatted.length - 8) + "23:59:59"
                    );
                  }}
                />
              </NewRow>
            </NewRow>

            <NewRow>
              <TitleForField>
                <Icon name={"people"} />
                Исполнитель
              </TitleForField>
              <MultySelect
                length_block={205}
                classname={"executor_select"}
                itemsList={users.map((item) => {
                  return {
                    key: item.id,
                    text: item.name + " " + item.surname,
                    value: item.id,
                    urlImage: item.image?.url,
                  };
                })}
                selectedItems={current_filters.executors.map((exec_id) => {
                  let user_obj = users.find((user) => exec_id === user.id);
                  if (user_obj) {
                    return {
                      key: user_obj.id,
                      text: user_obj.name + " " + user_obj.surname,
                      value: user_obj.id,
                    };
                  } else return [];
                })}
                addHandler={(arg) => {
                  // добавляется элемент в массив уже показываемых в чипсах пользователей
                  setFilters(undefined, undefined, [
                    ...current_filters.executors,
                    arg,
                  ]);
                  if (context === "tasks") history.replace(`${arg}`);
                }}
                removeHandler={(arg) => {
                  let executors = current_filters.executors.filter(
                    (item) => item !== arg
                  );
                  if (!executors.length && !current_filters.authors.length)
                    setFilters(
                      undefined,
                      undefined,
                      undefined,
                      executors,
                      undefined,
                      false
                    );
                  setFilters(undefined, undefined, executors);

                  if (context === "tasks") {
                    if (
                      !current_filters.authors.length &&
                      executors.length === 1
                    ) {
                      // один исполнитель
                      history.replace(`${executors[0]}`);
                    }
                    if (
                      current_filters.authors.length === 1 &&
                      !executors.length
                    ) {
                      // один автор
                      history.replace(`${current_filters.authors[0]}`);
                    }
                  }
                }}
              />
            </NewRow>

            <NewRow>
              <TitleForField>
                <Icon name={"people"} />
                Автор
              </TitleForField>
              <MultySelect
                length_block={205}
                classname={"author_select"}
                itemsList={users.map((item) => {
                  return {
                    key: nanoid(),
                    text: item.name + " " + item.surname,
                    value: item.id,
                    urlImage: item.image?.url,
                  };
                })}
                selectedItems={current_filters.authors.map((exec_id) => {
                  let user_obj = users.find((user) => exec_id === user.id);
                  if (user_obj) {
                    return {
                      key: nanoid(),
                      text: user_obj.name + " " + user_obj.surname,
                      value: user_obj.id,
                    };
                  } else return [];
                })}
                addHandler={(arg) => {
                  // добавляется элемент в массив уже показываемых в чипсах пользователей
                  setFilters(undefined, undefined, undefined, [
                    ...current_filters.authors,
                    arg,
                  ]);

                  if (history.location.pathname.includes("tasks")) {
                    history.replace(`${arg}`);
                  }
                }}
                removeHandler={(arg) => {
                  let authors = current_filters.authors.filter(
                    (item) => item !== arg
                  );
                  if (!authors.length && !current_filters.executors.length)
                    setFilters(
                      undefined,
                      undefined,
                      undefined,
                      authors,
                      undefined,
                      false
                    );
                  else setFilters(undefined, undefined, undefined, authors);
                  if (history.location.pathname.includes("tasks")) {
                    if (
                      !current_filters.executors.length &&
                      authors.length === 1
                    ) {
                      // один исполнитель
                      history.replace(`${authors[0]}`);
                    }
                    if (
                      current_filters.executors.length === 1 &&
                      !authors.length
                    ) {
                      // один автор
                      history.replace(`${current_filters.executors[0]}`);
                    }
                  }
                }}
              />
            </NewRow>
            {/* Тут добавляется статус */}
            <NewRow>
              <TitleForField>
                <Icon name={"burger-right"} />
                Статус
              </TitleForField>
              <div>
                {/* Выбранные статусы */}
                <div>
                  {/*уже выбранные фильтры*/}
                  {current_filters.statuses.map((item) => {
                    // если начало названия статуса "Отправлено", то его отрезаем

                    let status = statusList.find(
                      (statusObj) => statusObj.id === item
                    );

                    let name;
                    if (status) {
                      name = status.name;
                      let cutted = name.substr(10, name.length - 10).trim();
                      let firstLetter = cutted.substr(0, 1).toUpperCase();
                      cutted =
                        firstLetter + cutted.substr(1, cutted.length - 1);

                      return (
                        <NewRow key={nanoid()}>
                          <Button
                            key={item}
                            style={getObjStylesForStatus(item)}
                          >
                            {name.substr(0, 10) === "Отправлено"
                              ? cutted
                              : name}
                          </Button>
                          <Icon
                            name={"cross"}
                            style={{
                              width: "15px",
                              height: "15px",
                              margin: "10px",
                            }}
                            onClick={() => {
                              let statuses_copy = [...current_filters.statuses];
                              setFilters(
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                statuses_copy.filter(
                                  (StatusItem) => StatusItem !== item
                                )
                              );
                    setCurrentPage(1);

                            }}
                          />
                        </NewRow>
                      );
                    }
                  })}
                </div>
                {/* Добавление нового статуса */}
                <TaskStatus
                  id={""}
                  disabled={false}
                  buttonWrapperSize={93}
                  showStatusIcon={true}
                  passHigher={(statusId) => {
                    setFilters(undefined, undefined, undefined, undefined, [
                      ...current_filters.statuses,
                      statusId,
                    ]);
                  }}
                />
              </div>
            </NewRow>

            {AddTemplate && (
              <NewRow>
                <Button
                  onClick={() => {
                    setAddTemplate(!AddTemplate);
                    setTemplateName("");
                    setCurrentPage(1);
                  }}
                  design={"secondary"}
                  style={{ marginRight: "4px", width: "116px" }}
                >
                  Отмена
                </Button>
                <Button
                  disabled={!saveBtnAvailable || TemplateName === ""}
                  style={{ width: "116px" }}
                  onClick={() => {
                    if (TemplateName !== "") {
                      let addObj = {};
                      addObj[TemplateName] = {
                        date_from: current_filters.date_from,
                        date_to: current_filters.date_to,
                        executors: current_filters.executors,
                        authors: current_filters.authors,
                        statuses: current_filters.statuses,
                      };
                      FilterTemplates({ ...filters.templates, ...addObj });
                      setCurrentPage(1);

                      let newLSresult;
                      let bank;
                      let LSfilters = localStorage.getItem("filters");
                      if (LSfilters) {
                        let parsedLS = JSON.parse(LSfilters);
                        bank = { ...parsedLS["users_filters"], ...addObj };
                        parsedLS["users_filters"] = bank;
                        newLSresult = parsedLS;
                      } else {
                        bank = { ...addObj };
                        newLSresult = {
                          users_filters: bank,
                          last_active: {},
                        };
                      }

                      localStorage.setItem(
                        "filters",
                        JSON.stringify(newLSresult)
                      );

                      setAddTemplate(!AddTemplate);
                      setTemplateName("");
                    }
                  }}
                >
                  Добавить
                </Button>
              </NewRow>
            )}
          </div>
        </NewRow>
      </MainWindow>
    </ClickAwayListener>
  );
};

const mapStateToProps = (state: State) => {
  return {
    filters: state.commonInfo.filters,
    current_filters: state.commonInfo.filters.current_set,
    users: state.commonInfo.users,
    statusList: state.commonInfo.statuses,
    current_user: state.commonInfo.current_user,
    selectedProjectId: state.projectsPage.selectedProject?.id,
  };
};

const mapDispatchToProps = {
  setFilters,
  ShowFiltersWindow,
  FilterTemplates,
  setCurrentPage,
};

export default connect(mapStateToProps, mapDispatchToProps)(FiltersTasksWindow);
