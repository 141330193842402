import React, { FC, useEffect, useState } from "react";
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ruLocale from "date-fns/locale/ru";
import { datePickerText } from "./constants";
import moment, { Moment } from "moment";

import './DatePickerTrue.scss';

const DatePickerTrue: FC<{ 
  date: Moment
  minDateMessage?: string,
  maxDateMessage?: string,
  onChangeDate: (date: Date|null) => void,
  errorMessages?: string[],
  format: string
}> = ({
  date,
  onChangeDate,
  errorMessages,
  minDateMessage = '',
  maxDateMessage = '',
  format
}) => {
  const [minDate, setMinDate] = useState<Moment>(moment().subtract(1, 'year'));
  const [maxDate, setMaxDate] = useState<Moment>(moment().add(1, 'year'));
  
  return ( 
    <div className={'date-picker-true date-picker-true--input' +
                  (errorMessages?.length ? ' date-picker-true--error' : '')
    }>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
        <KeyboardDateTimePicker
          format={format}
          value={date.isValid() ? date : null}
          onChange={onChangeDate}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          showTodayButton={true}
          cancelLabel={datePickerText.CANCEL_LABEL}
          todayLabel={datePickerText.TODAY_LABEL}
          invalidDateMessage={!moment(date).isValid() && datePickerText.INVALID_DATE}
          invalidLabel={datePickerText.INVALID_LABEL}
          minDate={minDate}
          maxDate={maxDate}
          minDateMessage={minDateMessage}
          maxDateMessage={maxDateMessage}
          ampm={false}
        />
      </MuiPickersUtilsProvider>

      {(errorMessages && errorMessages.length > 0) && (
        <div className="date-picker-true__error-messages">
          {errorMessages.map((error, i) => (
            <div className="date-picker-true__error-message" key={i}>
              {error}
            </div>
          ))}
        </div>
      )}
    </div>
  )  
}

export default DatePickerTrue;
