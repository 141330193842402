// Классическое закрытие задачи при простановке статуса "Выполнено"

import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  addFileToTimesheet,
  changeTaskList,
  deleteFile,
  fetchTasks,
  launchProcedureFinishTask,
  loadSpecificTask,
  LocalTimeSheetRecord,
  sendComment,
  setSelectedUserId,
  timeSheetActions,
} from "../actions";
import {
  File,
  File as FileType,
  Task,
  TimeSheetCache,
} from "../../../common/types";
import { State } from "../../../rootReducer";
import { selectTask, setShownTaskInfoPlate } from "../../TaskInfoPlate/actions";
import styled from "styled-components";
import Input from "../../../common/components/Inputs/Input";
import Button from "../../../common/components/newComponent/Buttons/Button";
import FileElem from "../../../common/components/FileElem/FileElem";
import { nanoid } from "nanoid";
import { updateTask } from "../../../common/actions";

type Props = {
  shortVersion?: boolean;
  tasks: Task[];
  isLoading: boolean;
  currentId: number;
  selectedUserId: number;
  changeTaskList: (tasks: Task[]) => void;
  fetchTasks: (pageNum: number) => void;
  setSelectedUserId: (id: number) => void;
  activeColumns: string[];
  selectTask: (val: Task) => void;
  setShownTaskInfoPlate: (val: boolean) => void;
  loadSpecificTask: (val: number) => void;
  isShownTaskInfoPlate: boolean;
  selectedTask: Task | null;
  launchProcedureFinishTask: (val, taskId, short_version) => void;
  commentFiles: FileType[];
  sendComment: (task_Id?: number, commentText?: string) => void;
  taskDoneConfirmation: any;
  updateTask: (id: number, params: any, withNotif?: boolean) => void;
  timeSheetActions: (actionType: string, taskId: number, value: number) => void;
  timeSheet: any;
  TimeSheetCache: TimeSheetCache[];
  addFileToTimesheet: (file: any) => void;
  visualisation: any;
  deleteFile: (attachmentId: number, taskId?: number) => void;
  LocalTimeSheetRecord: (
    actionType: string,
    taskId: number,
    task_load_sum?: number | null,
    timesheetId?: number | null,
    status_id?: number | null,
    commentText?: string,
    commentFiles?: File[]
  ) => void;
};

const StyledTextarea = styled.textarea`
  font-family: "Roboto", "sans-serif";
  padding: 12px;
  height: 96px;
  border: 1px solid;
  border-radius: 6px;
  width: 100%;
  resize: none;
  border-color: #e0e0e0;
  &:focus {
    border-color: black;
  }
`;

const NewRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const GreyLabels = styled.div`
  font-size: 14px;
  margin-right: 8px;
  line-height: 40px;
  color: #707070;
`;

const BottomRowStyle = {
  marginTop: "16px",
};

const TasksTable: React.FC<Props> = ({
  activeColumns,
  selectedTask,
  isShownTaskInfoPlate,
  loadSpecificTask,
  selectTask,
  setShownTaskInfoPlate,
  tasks,
  isLoading,
  currentId,
  selectedUserId,
  changeTaskList,
  fetchTasks,
  setSelectedUserId,
  launchProcedureFinishTask,
  commentFiles,
  TimeSheetCache,
  sendComment,
  updateTask,
  taskDoneConfirmation,
  timeSheet,
  timeSheetActions,
  addFileToTimesheet,
  visualisation,
  deleteFile,
  LocalTimeSheetRecord,
}) => {
  let task = tasks.find((task) => task.id === taskDoneConfirmation.taskId);
  let tl = task?.task_load;
  let tls = task?.task_load_sum;

  let result;
  if (tl !== undefined && tls !== undefined) result = tl ? tl : 1;
  else result = 0;

  const [task_load_sum, set_task_load_sum] = useState<number | null>(result);

  const [commentText, setCommentText] = useState<string>(
    timeSheet.find((item) => item.task_id === taskDoneConfirmation.taskId)
      ?.comment_text
      ? timeSheet.find((item) => item.task_id === taskDoneConfirmation.taskId)
          ?.comment_text
      : ""
  );

  const CancelAction = () => {
    if (commentText === "") {
      if (Boolean(timeSheet.length)) {
        // аннулировать загрузку файлов
        let comment_files = timeSheet.find(
          (record) => record.task_id === taskDoneConfirmation.taskId
        )?.comment_files;
        if (comment_files && comment_files.length) {
          comment_files.forEach((item) => {
            deleteFile(item.id, taskDoneConfirmation.taskId);
          });
        }
      }
    }
    launchProcedureFinishTask(false, 0, false);
  };
  
  const outsideClickHandler = useCallback(
    (e: Event) => {
      let element = e.target as Element;
      if (element.classList.contains("add_pannel_wrapper")) {
        CancelAction();
      }
    },
    [timeSheet]
  );

  useEffect(() => {
    document.addEventListener("click", outsideClickHandler);
    return () => document.removeEventListener("click", outsideClickHandler);
  }, [outsideClickHandler]);

  return (
    <div className="add_pannel_wrapper">
      <div className={`repeat_task_window`}>
        <NewRow>
          <div
            style={{ fontSize: "16px", lineHeight: "24px", fontWeight: 500 }}
          >
            {taskDoneConfirmation.short_version
              ? "Формирование анализа по конец ..."
              : "Задача выполнена"}
          </div>
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              CancelAction();
            }}
          >
            <img src={require("../../../img/x-black.svg").default} />
          </div>
        </NewRow>
        <NewRow style={{ marginTop: "16px" }}>
          <div style={{ display: "flex" }}>
            {!taskDoneConfirmation.short_version && (
              <div style={{ display: "flex", marginRight: "8px" }}>
                <GreyLabels>Затрачено</GreyLabels>
                <Input
                  inputType="number"
                  placeholder="Введите"
                  value={task_load_sum?.toString()}
                  title={"количество часов"}
                  changeHandler={(value) => {
                    // let result = value.match(/(?:![-.])\b[0-9]+\b(?!\.[0-9])/g);
                    if (value) set_task_load_sum(parseInt(value)); // изменяем переменную из лок. state task_load_sum
                    if (value === "") set_task_load_sum(0); // при введении данных о затраченном времени
                  }} // если данные не введены- 0
                  blurHandler={(value) => {
                    if (value === "") set_task_load_sum(0);
                  }}
                />
              </div>
            )}

            <label id={"upload_file1"} htmlFor="comment_files_task_done">
              <Button
                icon={"attach"}
                design={"outline"}
                title={"Загрузите файл"}
                style={{
                  height: "40px",
                  width: "132px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "0",
                }}
                onClick={() => {
                  document.getElementById("upload_file1")?.click();
                }}
              />
              <input
                style={{ display: "none" }}
                onChange={(e) => {
                  let files = e.currentTarget.files;
                  if (!files) return;
                  for (let i = 0; i < files?.length; i++)
                    addFileToTimesheet(files[i]);
                }}
                type="file"
                id="comment_files_task_done"
                multiple={true}
              />
            </label>
          </div>
        </NewRow>
        <NewRow>
          <GreyLabels>Комментарий</GreyLabels>
        </NewRow>
        <NewRow>
          <StyledTextarea
            value={commentText}
            onChange={(e) => {
              setCommentText(e.currentTarget.value);
            }}
          />
        </NewRow>

        {Boolean(timeSheet.length) &&
          timeSheet.find(
            (record) => record.task_id === taskDoneConfirmation.taskId
          )?.comment_files && (
            <div
              className={"attachments"}
              style={{ display: "flex", flexWrap: "wrap", marginTop: "8px" }}
            >
              {timeSheet
                .find(
                  (record) => record.task_id === taskDoneConfirmation.taskId
                )
                .comment_files.map((elem) => (
                  <FileElem
                    url={elem.url}
                    key={nanoid()}
                    id={elem.id}
                    name={elem.name}
                    width={156}
                    deleteHandler={(attachementId) => {
                      deleteFile(attachementId, taskDoneConfirmation.taskId);
                    }}
                  />
                ))}
            </div>
          )}

        <NewRow style={BottomRowStyle}>
          <Button
            style={{ width: "100%" }}
            title={"Отмена"}
            design={"secondary"}
            onClick={() => {
              CancelAction();
            }}
          />
          <Button
            style={{ width: "100%", marginLeft: "8px" }}
            title={"Готово"}
            onClick={() => {
              if (taskDoneConfirmation.short_version) {
                // закрытия дня
                // remember
                if (commentText !== "") {
                  LocalTimeSheetRecord(
                    "add",
                    taskDoneConfirmation.taskId,
                    null,
                    null,
                    null,
                    commentText
                  );
                }
                // else { // если равняется пустоте, значит нужно стереть и коммент и файлы
                //     CancelAction();
                // }
              } else {
                // dispatch action
                updateTask(taskDoneConfirmation.taskId, { status_id: 12 });
                if (commentText !== "")
                  sendComment(taskDoneConfirmation.taskId, commentText);
                if ((task_load_sum as number) > 0)
                  timeSheetActions(
                    "post",
                    taskDoneConfirmation.taskId,
                    task_load_sum as number
                  );
                updateTask(taskDoneConfirmation.taskId, {
                  task_load: Number(task_load_sum),
                }, false);
              }
              setShownTaskInfoPlate(false);
              launchProcedureFinishTask(false, 0, false);
            }}
          />
        </NewRow>
      </div>
    </div>
  );
};

const mapStateToProps = (state: State) => {
  return {
    selectedTask: state.taskInfoPlate.selectedTask,
    isShownTaskInfoPlate: state.taskInfoPlate.isShownTaskInfoPlate,
    activeColumns: state.tasksPage.activeColumns,
    tasks: state.tasksPage.tasks,
    isLoading: state.tasksPage.isLoading,
    currentId: state.commonInfo.current_user,
    selectedUserId: state.tasksPage.selectedUserId,
    commentFiles: state.taskInfoPlate.commentFiles,
    taskDoneConfirmation: state.tasksPage.taskDoneConfirmation,
    timeSheet: state.tasksPage.timeSheet,
    TimeSheetCache: state.tasksPage.TimeSheetCache,
    visualisation: state.tasksPage.visualizationData,
  };
};

const mapDispatchToProps = {
  setShownTaskInfoPlate,
  selectTask,
  changeTaskList,
  fetchTasks,
  setSelectedUserId,
  loadSpecificTask,
  launchProcedureFinishTask,

  addFileToTimesheet,
  deleteFile,

  LocalTimeSheetRecord,

  sendComment,
  updateTask,
  timeSheetActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(TasksTable);
