import React, { FC, useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { State } from "../../../../../../rootReducer";
import AdditionalBlock from "../../../AdditionalBlock/AdditionalBlock";
import { IFormAddTaskAdditionalFields } from "../../../FormAddTask/interfaces";
import { datePickerFormat, datePickerText } from "../../../ui/DatePickerTrue/constants";
import moment from "moment/moment";
import DatePickerTrue from "../../../ui/DatePickerTrue/DatePickerTrue";
import TextAreaSimple from "../../../../../../common/components/ui/TextAreaSimple/TextAreaSimple";
import { IObjectsFormEditTaskData } from "../../interfaces";
import { setSimpleDataFormEditTask, setStatus } from "../../actions";
import { checkTaskStatusOverdue, getDeadline } from "../../../../helpers";
import TaskStatus from "../../../../../../common/components/TaskStatus/TaskStatus";

import './ExecutionTab.scss';
import { User } from "../../../../../../common/types";

const ExecutionTab: FC<{
  formData: IObjectsFormEditTaskData,
  isUserCanEdit: boolean,
  additionalFields: IFormAddTaskAdditionalFields,
  setInput: (value: string|Date, name: keyof IObjectsFormEditTaskData) => void,
  checkTrigger: string,
  setStatus: (status_id: number) => void,
  onSetExtraFiles: (files: any) => void
}> = ({
  formData,
  isUserCanEdit,
  additionalFields,
  setInput,
  checkTrigger,
  setStatus,
  onSetExtraFiles
}) => {
  const currentUser = useSelector<State, User|null>((state) => state.commonInfo.currentUserInfo);

  const [dateErrorMessages, setDateErrorMessages] = useState<any>({
    startDate: [],
    endDate: []
  });
  
  const onChangeTextArea = (e: React.ChangeEvent<HTMLTextAreaElement>, name: keyof IObjectsFormEditTaskData) => {
    setInput(e.target.value, name);
  };
  
  const onChangeDate = (date: Date|null, name: keyof IObjectsFormEditTaskData) => {
    const dataHelpers = getDeadline(formData.duration, date, name, formData.startDate ?? '', formData.endDate ?? '');

    setDateErrorMessages(dataHelpers.datesMessages);
    setInput(dataHelpers.deadlineTime, 'endDate');

    if(date) {
      setInput(date, name);
    }
  }
  
  const onChangeStatus = (status: number) => {
    setStatus(status);
  }; 
  
  return (
    <>
    <div className='execution-tab-objects-task-edit'>
      {!isUserCanEdit && (
        <div className="execution-tab-objects-task-edit--disabled"></div>
      )}

      <div className="execution-tab-objects-task-edit__content">
        <div className="execution-tab-objects-task-edit__content-items">
          <div className="description-tab-objects-task-edit__block-item">
            <div className="description-tab-objects-task-edit__block-item-left">
              <div className="description-tab-objects-task-edit__block-item-title">
                * Дедлайн
              </div>
            </div>
            
            <div className="description-tab-objects-task-edit__block-item-right">
              <div className="description-tab-objects-task-edit__block-item-input">
                <DatePickerTrue
                  format={datePickerFormat.STANDARD_DATE_TIME}
                  minDateMessage={datePickerText.MIN_DATE_MESSAGE}
                  maxDateMessage={datePickerText.MAX_DATE_MESSAGE}
                  errorMessages={dateErrorMessages.endDate}
                  date={moment(formData.endDate)}
                  onChangeDate={(date) => onChangeDate(date, 'endDate')}
                />
              </div>
            </div>
          </div>

          <div className="description-tab-objects-task-edit__block-item">
            <div className="description-tab-objects-task-edit__block-item-left">
              <div className="description-tab-objects-task-edit__block-item-title">
                Описание задачи
              </div>
            </div>

            <div className="description-tab-objects-task-edit__block-item-right">
              <div className="description-tab-objects-task-edit__block-item-input">
                <TextAreaSimple
                  name='description'
                  onChange={(e) => onChangeTextArea(e, 'description')}
                  value={formData.description}
                  checkTriggerExternal={checkTrigger}
                />
              </div>
            </div>
          </div>
        </div>
        
        <div className="execution-tab-objects-task-edit__additional-block">
          <div className="execution-tab-objects-task-edit__additional-block-title">Исполнение задачи</div>

          <div className="execution-tab-objects-task-edit__additional-block-content">
            <AdditionalBlock
              data={additionalFields}
              from='executionTab'
              onSetExtraFiles={onSetExtraFiles}
            />
          </div>
        </div>
        <div className="execution-tab-objects-task-edit__additional-block">
          <div className="execution-tab-objects-task-edit__additional-block-title">Статус задачи</div>
        </div>
      </div>
    </div>
     <div className="execution-tab-objects-task-edit__additional-block-content">
      {!isUserCanEdit && currentUser?.id !== formData?.executor?.selectedValue.value && (
        <div className="execution-tab-objects-task-edit__additional-block-content--disabled"></div>
      )}
     <div className="execution-tab-objects-task-edit__additional-block-status">
       <TaskStatus
         id={checkTaskStatusOverdue({ status_id: formData.status_id, end: formData.endDate })}
         passHigher={(arg) => onChangeStatus(arg)}
         showStatusIcon={true}
       />
     </div>
   </div>
   </>
  )
};

const mapStateToProps = (state: State) => ({
  additionalFields: state.objectsFormEditTask.additionalFields,
  formData: state.objectsFormEditTask.data,
  checkTrigger: state.objectsFormEditTask.checkTrigger
});

const mapDispatchToProps = {
  setInput: setSimpleDataFormEditTask,
  setStatus
};

export default connect(mapStateToProps, mapDispatchToProps)(ExecutionTab);
