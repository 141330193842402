import React, { FC } from "react";
import InputTypicalTask from "../../ui/InputTypicalTask/InputTypicalTask";
import ArrayTypicalTask from "../../ui/ArrayTypicalTask/ArrayTypicalTask";
import { connect } from "react-redux";
import { State } from "../../../../../../rootReducer";
import { IExtraFormRadioTabData, TArrayValues } from "../../../../action_types";
import { setExtraFormRadioTabData } from "../../../../actions";
import { SelectedTabNameExtraForm } from "../../../../constants";

import './RadioTabTypicalTask.scss';

const RadioTabTypicalTask: FC<{
  title: string,
  arrayValues: TArrayValues[],
  setExtraFormRadioTabData: (radioTabData: IExtraFormRadioTabData) => void
}> = ({
  title,
  arrayValues,
  setExtraFormRadioTabData
}) => {
  const validationState = {
    input: false,
    array: false
  };
  
  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setExtraFormRadioTabData({title: e.target.value});
  };

  const onCheckHandler = ( isValid: boolean, type: string ) => {
    let isValidLocal = false;

    validationState[type] = isValid

    if(validationState.input && validationState.array) {
      isValidLocal = true;
    }

    setExtraFormRadioTabData({isValid: isValidLocal});
  }
  
  return (
    <div className='radio-tab-typical-task'>
      <div className="radio-tab-typical-task__input">
        <InputTypicalTask
          onCheck={onCheckHandler}
          onChange={onChangeInput}
          value={title}
        />
      </div>
      
      <div className="radio-tab-typical-task__array">
        <ArrayTypicalTask
          onCheck={onCheckHandler}
          arrayValues={arrayValues}
          selectedTabName={SelectedTabNameExtraForm.RADIO_TAB} 
        />
      </div>
    </div>
  )
}

const mapStateToProps = (state: State) => {
  return {
    arrayValues: state.typicalTasksPage.extraForm.radioTab.arrayValues as TArrayValues[],
    title: state.typicalTasksPage.extraForm.radioTab.title as string
  }
};

const mapDispatchToProps = {
  setExtraFormRadioTabData
}

export default connect(mapStateToProps, mapDispatchToProps)(RadioTabTypicalTask);
