export enum sortConditions {
    ID = 'id',
    NAME = 'name',
    AUTHOR = 'author_id',
    EXECUTOR = 'executor_id',
    STATUS = 'status_id',
    END = 'end',
    OBJECT_NAME= 'project_name',
    PRIORITY = 'priority_id'
  }

  export enum sortOrders {
    ASC,
    DESC
  }

  export enum deleteTaskStatuses {
    DELETE_TASK = 0,
    DELETE_TASK_ALLOWED = 'allowed',
    DELETE_TASK_ERROR = 'error'
  }

  export enum statusTask {
    IN_WORK_STATUS = 10, //в работе
    COMPLETED_STATUS = 13, //13 - завершено
    MADE_STATUS = 12, //12 - выполнено
    DRAFT_STATUS = 18, //18 - черновик
    CANCELED_STATUS = 11, //11 - отменено
    OVERDUE_STATUS = 14 //просрочено
  }

  export enum priorityNames {
    LOW = 'Низкий',
    MEDIUM = 'Средний',
    HIGH = 'Высокий',
    URGENT = 'Срочный'
  }
  
  export enum priorityValues {
    LOW = 1,
    MEDIUM = 2,
    HIGH = 3,
    URGENT = 4
  }

  export enum messagesTask {
    DELETE_TASK_ERROR = 'Произошла ошибка при удалении задачи...',
    DELETE_TASK_SUCCESS = 'Задача удалена',
    CREATE_TASK_SUCCESS = 'Задача успешно создана',
    UPDATE_TASK_SUCCESS = 'Задача успешно обновлена'
  }