import React, { useEffect } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import styled from "styled-components";
import Trash from "../../../../../img/projectIcons/Trash";
import { connect } from "react-redux";
import PreloaderLocal from "../../../../../common/components/PreloaderLocal/PreloaderLocal";
import { State } from "../../../../../rootReducer";
import { IDeleteTaskModal } from "../../../interfaces";
import { checkDeleteTask, setDeleteTaskModalData } from "../../../actions";
import { CheckContextsObjects } from "../../../constants";
import useCheckRolesMeridian from "../../../../../utils/hooks/useCheckRolesMeridian";

import "./styles.scss";

interface ITaskObjectMenuProps {
  id: number | string;
  anchorEl: HTMLElement | null;
  setAnchorEl: (arg0: React.MouseEvent<HTMLButtonElement> | null) => void;
  isShowMenuPreloader: boolean;
  deleteTaskModalOpen?: IDeleteTaskModal;
  setDeleteTaskModalData: (data: IDeleteTaskModal) => void;
  checkDeleteTask: (id: number) => void;
}

const MenuStyle = {
  marginTop: "45px",
};

const MenuItemStyle = {
  display: "flex",
  alignItems: "center",
  marginBottom: "5px",
  fontSize: "14px",
};

const MenuIcon = styled.div`
  margin-right: 10px;
  display: flex;
  align-items: center;

  > svg {
    stroke: #292a34;
  }
`;

const MenuTitle = styled.div``;

const TaskMenuObject = ({
  id,
  anchorEl,
  setAnchorEl,
  isShowMenuPreloader,
  deleteTaskModalOpen,
  setDeleteTaskModalData,
  checkDeleteTask
}: ITaskObjectMenuProps) => {
  const { isUserCanDeleteTask } = useCheckRolesMeridian(CheckContextsObjects.IS_USER_CAN_DELETE_TASK);

  const clickTaskActionsCloseHandler = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (deleteTaskModalOpen) clickTaskActionsCloseHandler();
  }, [deleteTaskModalOpen]);

  const clickDeleteHandler = (id: number, e: any) => {
    checkDeleteTask(id)
    e.stopPropagation();
  };

  useEffect(() => {
    if (deleteTaskModalOpen) clickTaskActionsCloseHandler();
  }, [deleteTaskModalOpen]);

  return (
    <Menu
      className="task-menu-typical-task"
      anchorEl={anchorEl}
      open={!!anchorEl}
      onClose={() => clickTaskActionsCloseHandler()}
      style={MenuStyle}
    >
      {isUserCanDeleteTask && (
        <MenuItem
          style={{ ...MenuItemStyle, marginBottom: 0 }}
          onClick={(e) => clickDeleteHandler(id as number, e)}
        >
          <MenuIcon>
            <Trash />
          </MenuIcon>
          <MenuTitle
            className={
              "task-menu-typical-task__title-wr" +
              (isShowMenuPreloader
                ? " task-menu-typical-task__title-wr--show-preloader"
                : "")
            }
          >
            <div className="task-menu-typical-task__title">Удалить задачу</div>
            <div className="task-menu-typical-task__preloader">
              <PreloaderLocal />
            </div>
          </MenuTitle>
        </MenuItem>
      )}
    </Menu>
  );
};

const mapStateToProps = (state: State) => {
  return {
    isShowMenuPreloader: state.objectsPage.isShowMenuPreloader,
    deleteTaskModalOpen: state.objectsPage.deleteTaskModal,
  };
};

const mapDispatchToProps = {
  setDeleteTaskModalData,
  checkDeleteTask
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskMenuObject);
