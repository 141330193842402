import React, { useEffect, useMemo, useState } from "react";
import DeadLineCell from "../../../../../../common/components/TableCells/DeadLineCell";
import ExecutorCell from "../../../../../../common/components/TableCells/ExecutorCell";
import PriorityCell from "../../../../../../common/components/TableCells/PriorityCell";
import TaskNameCell from "../../../../../../common/components/TableCells/TaskNameCell";
import TaskStatus from "../../../../../../common/components/TaskStatus/TaskStatus";
import { Vector3 } from "../../../../../../img/Task";
import { connect } from "react-redux";
import DateMaster from "../../../../../../utils/DateMaster";
import { urlApp } from "../../../../../../App";
import { State } from "../../../../../../rootReducer";
import "./styles.scss";
import TaskMenuObject from "../../../ui/TaskMenu/TaskMenuObject";
import { ReactComponent as DotsIcon } from "../../../../../../img/TrueIcons/dots.svg";
import moment from "moment/moment";
import { getTask, setDefaultValues, setFormOpen } from "../../../FormEditTask/actions";
import { statusTask } from "../../../../../TasksPage/constants";
import { checkTaskStatusOverdue } from "../../../../helpers";

const ProjectAllSectionTasks: React.FC<{
  task: any;
  users: any;
  setFormOpen: (isFormOpen: boolean) => void,
  setDefaultValues: () => void,
  getTask: (taskId: string|number) => void,
}> = ({ 
  task, 
  users,
  setFormOpen,
  setDefaultValues,
  getTask
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const executor = users?.find((user) => user.id === task?.executor_id);

  const clickTaskActionsOpenHandler = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSetAnchorEl = (value) => {
    setAnchorEl(value);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const clickHandler = (e: React.MouseEvent) => {
    e.stopPropagation();
    const target = e.target as HTMLElement;
  };
  
  const dateMaster = useMemo(() => {
    return new DateMaster(
      moment(task.end, "YYYY-MM-DDTHH:mm:ss[Z]", true).isValid()
        ? moment(task.end).format("DD-MM-YYYY HH:mm:ss")
        : task.end
    );
  }, [task?.end]);

  // const trClickHandler = (e: React.MouseEvent) => {
  //   handleSetAnchorEl(null); // Скрываем меню три точки
  //   e.stopPropagation();
  //   if (!(e.target as HTMLElement).closest(".task_status_wrapper"))
  //     setShownTaskInfoPlate(true);
  // };
  
  const onOpenTaskEdit = (taskId: string|number) => {
    setDefaultValues();

    getTask(taskId);
    setFormOpen(true);
  };

  return (
    <>
      <div
        className="task_content"
        onClick={() => onOpenTaskEdit(task.id)}
      >
        <div className="left_box">
          <div className="id_task">{task.id}</div>

          <div style={{ display: "flex", alignItems: "center " }}>
            <Vector3 />
          </div>
          <span
            style={{
              margin: "0px 12px 0px 5px",
              display: "flex",
              alignItems: "center ",
              width: "13px",
              color: "#94959A",
            }}
          >
            {task.comments_count}
          </span>
          <div className="task_name_objects">
            <TaskNameCell
              id={task.id}
              name={task.name}
              desc={task.description}
              width={true}
            />
          </div>
        </div>
        <div className="right_box">
          <div className="executor_objects_task">
            <ExecutorCell
              url={
                executor?.image
                  ? urlApp + `/files/${executor.image.url}/download`
                  : null
              }
              executorName={
                executor ? `${executor.surname} ${executor.name}` : "Неизвестен"
              }
            />
            <div className="executor-name-objects-task">
              {executor?.surname} {executor?.name}
            </div>
          </div>
          <div className="task_status_objects">
            <TaskStatus id={checkTaskStatusOverdue(task)}
                        showStatusIcon={true}
            />
          </div>
          <div className="deadline_task_objects">
            <DeadLineCell
              end={task?.end}
              deadline={dateMaster.deadlineMini}
              hot={task.hot}
              style={{ alignItems: "flex-start" }}
            />
          </div>
          <div className="priority-task-objects">
            <PriorityCell
              style={{ backgroundColor: "transparent", width: "100px" }}
              priorityId={task.priority_id}
            />
          </div>

          {/* {currentUser?.isAdmin && ( */}
          <div className="task_content__actions">
            <button
              className="task_content__actions-btn"
              onClick={(e) => handleClick(e)}
            >
              <DotsIcon />
            </button>
          </div>
        </div>
        <TaskMenuObject
          id={task?.id}
          anchorEl={anchorEl}
          setAnchorEl={handleSetAnchorEl}
        />
      </div>
    </>
  );
};
const mapStateToProps = (state: State) => {
  return {
    users: state.commonInfo.users,
  };
};

const mapDispatchToProps = {
  setFormOpen,
  setDefaultValues,
  getTask
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectAllSectionTasks);
