import { sortConditions } from "./constants";

export const columnsOfTablesTypicalTasks = [
  {
    columnId: sortConditions.ID,
    showingText: "№",
    style: { minWidth: "40px" },
  },
  {
    columnId: sortConditions.DAYS,
    showingText: "Дни",
    style: { minWidth: "60px", maxWidth: "700px" },
  },
  {
    columnId: sortConditions.NAME,
    showingText: "Объект",
    style: { minWidth: "155px", maxWidth: "700px" },
  },
  {
    columnId: sortConditions.TREATY,
    showingText: "Договор",
    style: { width: "155px" },
  },
 
  {
    columnId: sortConditions.EXECUTOR,
    showingText: "Ответственный РП",
    style: { width: "175px" },
  },
  {
    columnId: sortConditions.PROGRESS,
    showingText: "Прогресс",
    style: { width: "155px", },
  },
  {
    columnId: sortConditions.IN_WORK,
    showingText: "В работе",
    style: { minWidth: "230px", maxWidth: "500px" },
  },
  {
    columnId: sortConditions.COMPLETED,
    showingText: "Завершено",
    style: { width: "135px" },
  },
  {
    columnId: sortConditions.OVERDUE,
    showingText: "Просрочено",
    style: { width: "135px" },
  },
  {
    columnId: sortConditions.STATUS,
    showingText: "Статус",
    style: { width: "135px" },
  },
];