import React, { FC } from "react";

import { State } from "../../../../rootReducer";
import { connect } from "react-redux";
import { columnsOfTableslTasks } from "../../sharedData";
import "./TypicalTreatiesTableHeader.scss";
import SortIcon from "../../../../common/components/SortIcon";
import { getTypicalTreaties, setCurrentPage, setSortCondition } from "../../actions";
import { ISortConditions } from "../../interfaces";
import { sortConditions } from "../../constants";

const TypicalTreatiesTableHeader: FC<{
  sortCondition: ISortConditions;
  setSortCondition: (sortCondition: ISortConditions) => void;
  getTypicalTreaties: (params?: object) => void;
  setCurrentPage: (currentPage: number) => void;
}> = ({ 
  sortCondition, 
  setSortCondition, 
  getTypicalTreaties, 
  setCurrentPage, 
}) => {

  const onClickCondition = (columnId, value) => {
    setCurrentPage(1);
    setSortCondition({ columnId, value });
    getTypicalTreaties({ columnId, value });
  }

  return (
    <div className="typical_treaties_table_header">
      <div className="block_table_head">
        {Object.keys(columnsOfTableslTasks).map((key) => {
          return columnsOfTableslTasks[key].columnId === 
            sortConditions.ID ||
            columnsOfTableslTasks[key].columnId ===
              sortConditions.NAME || 
            columnsOfTableslTasks[key].columnId ===
              sortConditions.ALL_STAGE ||
            columnsOfTableslTasks[key].columnId ===
              sortConditions.ALL_BUSINESS_PROCESS ||
            columnsOfTableslTasks[key].columnId === 
              sortConditions.ALL_TASKS || 
            columnsOfTableslTasks[key].columnId === 
              sortConditions.DURATION ||
            columnsOfTableslTasks[key].columnId === 
              sortConditions.AUTHOR ||
            columnsOfTableslTasks[key].columnId === 
              sortConditions.CREATED_AT ||
            columnsOfTableslTasks[key].columnId ===
              sortConditions.STATUS ? (
            <div
              className={`header-typical-treaties__${columnsOfTableslTasks[key].columnId}`}
              key={columnsOfTableslTasks[key].columnId}
            >
              <span
                className={`header_items_typical-treaties ${columnsOfTableslTasks[key].columnId}`}
              >
                <span
                  onClick={() => {
                    const value =
                      sortCondition.columnId ===
                      columnsOfTableslTasks[key].columnId
                        ? sortCondition.value === 2
                          ? 1
                          : 2
                        : 1;
                      
                    onClickCondition(
                      columnsOfTableslTasks[key].columnId,
                      value
                    )
                  }}
                  className={`header_items_typical-treaties ${columnsOfTableslTasks[key].columnId}`}
                >
                  <span className="text_header">
                    {columnsOfTableslTasks[key].showingText}
                  </span>
                </span>
                <SortIcon
                  active={
                    sortCondition.columnId ===
                    columnsOfTableslTasks[key].columnId
                  }
                  activeSort={
                    sortCondition.columnId ===
                      columnsOfTableslTasks[key].columnId &&
                    sortCondition.value === 1
                      ? "top"
                      : "bottom"
                }
                />
              </span>
            </div>
          ) : null;
        })}
      </div>
    </div>
  );
};

const mapStateToProps = (state: State) => ({
  sortCondition: state.typicalTreatyPage.sortCondition,
});

const mapDispatchToProps = {
  getTypicalTreaties,
  setCurrentPage,
  setSortCondition,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TypicalTreatiesTableHeader);
