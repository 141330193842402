import React from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { State } from "../../../../../../../rootReducer";
import { setCurrentPageBpListTreaty, setTreatyForFilterExtra } from "../../../../../actions";
import { ITreatyFilterFormExtra, TabIdsExtraFormTreaty } from "../../../../../interfaces";
import { setCurrentPageTypicalTasksBp, setTreatyForFilterExtraTasks } from "../../../../../../BusinessPage/actions";
import { IBPFilterFormExtra } from "../../../../../../BusinessPage/interfaces";

const FilterItemsExtraForm: React.FC<{
  treaties: any;
  treatyForFilter: ITreatyFilterFormExtra
  setTreatyForFilterExtra: (mode: ITreatyFilterFormExtra) => void;
  setTreatyForFilterExtraTasks: (mode: IBPFilterFormExtra) => void;
  activeTabIndex: number;
  treatyForFilterTasks: IBPFilterFormExtra;
  setCurrentPageBpListTreaty: (mode: number) => void;
  setCurrentPageTypicalTasksBp: (mode: number) => void;
}> = ({ treaties, treatyForFilter, setTreatyForFilterExtra, activeTabIndex, 
  setTreatyForFilterExtraTasks, treatyForFilterTasks, setCurrentPageBpListTreaty, setCurrentPageTypicalTasksBp }) => {
  const treatyForSelect = treaties
    ?.filter((item) => item.status)
    .map((item) => {
      return {
        value: item.id,
        label: item.name,
        key: item.id
      };
    });

  let filters = [
    { value: "all", label: "Не важно" },
    { value: "notActive", label: "Не используется" },
  ].concat(treatyForSelect as any);

  const onChangeBPHandler = (value: ITreatyFilterFormExtra) => {
    if(activeTabIndex === TabIdsExtraFormTreaty.TASKS_TAB){
      setCurrentPageTypicalTasksBp(1);
      setTreatyForFilterExtraTasks(value);
    } else{
      setCurrentPageBpListTreaty(1);
      setTreatyForFilterExtra(value);    
    }
  }
  
  return (
    <>
      <Select
        type="text"
        value={activeTabIndex === TabIdsExtraFormTreaty.TASKS_TAB ? treatyForFilterTasks : treatyForFilter}
        options={filters}
        className="react-select"
        classNamePrefix="react-select"
        onChange={(value) => {onChangeBPHandler(value)}}
      />
    </>
  );
};

const mapStateToProps = (state: State) => {
  return {
    treaties: state.typicalTreatyPage.treaties,
    treatyForFilter: state.typicalTreatyPage.extraForm.treatyForFilter,
    treatyForFilterTasks: state.businessPage.extraFormBP.treatyForFilterTasks
  };
};

const mapDispatchToProps = {
    setTreatyForFilterExtra,
    setTreatyForFilterExtraTasks,
    setCurrentPageBpListTreaty,
    setCurrentPageTypicalTasksBp
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterItemsExtraForm);
