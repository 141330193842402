import React, { FC, useEffect, useState } from 'react'
import { State } from '../../../../../rootReducer'
import {ReactComponent as CloseIcon} from '../../../../../img/TrueIcons/close.svg';
import { connect } from 'react-redux'

import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import { deleteTreatyStageStore, setDeleteStageTreatyModal } from '../../../actions';
import { IDeleteStageTreatiesModal, IDeleteTypicalTreatiesModal } from '../../../interfaces';
import { deleteTypicalTreatiesStatuses } from '../../../constants';
import Fade from '@material-ui/core/Fade';
import PreloaderLocal from '../../../../../common/components/PreloaderLocal/PreloaderLocal';


const ModalDeleteStageTreaty: FC<{
  isDeleteModalOpen?: boolean,
  deleteStageTreatyModal: IDeleteStageTreatiesModal,
  setDeleteStageTreatyModal: (data: IDeleteTypicalTreatiesModal) => void,
  deleteTreatyStage?: (id: string|number) => void;
  deleteTreatyStageStore: (id: number | string) => void;
}> = ({
  isDeleteModalOpen,
  deleteStageTreatyModal,
  setDeleteStageTreatyModal,
  deleteTreatyStageStore
}) => {

  const textObj = {
    [deleteTypicalTreatiesStatuses.DELETE_TREATY_ALLOWED]:
      {title: 'Удаление этапа договора', text: 'Этап договора будет удален. Больше его никто не увидит.<br/>Действие необратимо.', btnDeleteText: 'Удалить'},
    [deleteTypicalTreatiesStatuses.DELETE_TREATY_ERROR]:
      {title: 'Удаление этапа договора', text: 'Произошла ошибка при удалении этапа договора...'}
  }

  const [text, setText] = useState<{
    title: string,
    text: string,
    btnDeleteText?: string
  }>(textObj[deleteTypicalTreatiesStatuses.DELETE_TREATY_ERROR])

  useEffect(() => {
    if (deleteStageTreatyModal.statusFromServer?.constraints.length) {
      const { constraints } = deleteStageTreatyModal.statusFromServer;
      const result = textObj[constraints[0]];

      if (result) setText(result);
      else setText(textObj[deleteTypicalTreatiesStatuses.DELETE_TREATY_ERROR]);
    }
  }, [deleteStageTreatyModal.isDeleteModalOpen])

  const handleClose = () => {
    setDeleteStageTreatyModal({isDeleteModalOpen: false});
  }

  const deleteIsTypicalTreaty = () => {
    deleteTreatyStageStore(deleteStageTreatyModal.stageId ?? '');
    setDeleteStageTreatyModal({isDeleteModalOpen: false});
  }


  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={'modal-delete-object'}
        open={isDeleteModalOpen ?? false}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={isDeleteModalOpen}>
          <div className={'modal-delete-treaty__content-wr'}>
            <div className="modal-delete-treaty__content">
              <div className="modal-delete-treaty__content-item">
                <div className={"modal-delete-treaty__title-wr"}>
                  <div className='modal-delete-treaty__title'>{text?.title}</div>
                  <div 
                    className='modal-delete-treaty__delete-ico-wr'
                    onClick={handleClose}
                  >
                    <CloseIcon />
                  </div>
                </div>
                <div>
                  <div 
                    className='modal-delete-treaty__text' 
                    dangerouslySetInnerHTML={{__html: text?.text}}
                  />
                </div>
              </div>

              <div className='modal-delete-treaty__btns'>
                {text?.btnDeleteText && (
                  <button className={
                    'modal-delete-treaty__btn modal-delete-treaty__btn-delete'
                    + (deleteStageTreatyModal.isShowPreloader 
                      ? ' modal-delete-treaty__btn--show-preloader' 
                      : '')}
                  >
                    <span 
                      className="modal-delete-treaty__btn-title"
                      onClick={deleteIsTypicalTreaty}
                    >
                      {text?.btnDeleteText}
                    </span>
                    <span
                      className="modal-delete-treaty__btn-preloader"
                    >
                      <PreloaderLocal />
                    </span>
                  </button>
                )}

                <button 
                  className="modal-delete-treaty__btn modal-delete-treaty__btn-cancel"
                  onClick={handleClose}
                >
                  <span className="modal-delete-treaty__btn-title">Отмена</span>
                </button>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  )
}

const mapStateToProps = (state: State) => {
  return {
    isDeleteModalOpen: state.typicalTreatyPage.deleteStageTreatyModal.isDeleteModalOpen,
    deleteStageTreatyModal: state.typicalTreatyPage.deleteStageTreatyModal
  }
}

const mapDispatchToProps = {
  deleteTreatyStageStore,
  setDeleteStageTreatyModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalDeleteStageTreaty)