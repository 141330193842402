import React, { FC, useEffect, useState } from 'react';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { connect } from "react-redux";
import { State } from "../../../../../../rootReducer";
import { ReactComponent as CloseIcon } from '../../../../../../img/TrueIcons/close.svg';
import { setActionConnectIdTrigger, setTaskConnectModalBp } from "../../../../actions";
import { ITaskConnectModal, ITaskFlattenBP } from "../../../../interfaces";
import { taskConnectActions, taskConnectStatuses } from "../../../../constants";
import { modalConnectionData } from "../../../../sharedData";

import './ModalTaskConnectBp.scss';

interface IModalText {
  title: string,
  btns: {actionId: taskConnectActions, title: string}[]
}

const ModalTaskConnectBp: FC<{
  isModalOpen?: boolean,
  connectionStatusTrigger?: number|null,
  setTaskConnectModalBp: (data: ITaskConnectModal) => void,
  setActionConnectIdTrigger: (idTrigger: number) => void,
  currentTask?: ITaskFlattenBP|null,
  overTask?: ITaskFlattenBP|null
}> = ({
  isModalOpen,
  connectionStatusTrigger,
  setTaskConnectModalBp,
  setActionConnectIdTrigger,
  currentTask,
  overTask
}) => {
  const textObj = modalConnectionData;
  
  const [text, setText] = useState<IModalText>(
    textObj[taskConnectStatuses.STATUS_ERROR as number]
  );
  
  const onCloseHandle = () => {
    setTaskConnectModalBp({isModalOpen: false, connectionStatusTrigger: null});
  };
  
  const onButtonClickHandle = (actionId: taskConnectActions) => {
    if(connectionStatusTrigger && connectionStatusTrigger < 1000) setActionConnectIdTrigger(actionId);
    
    onCloseHandle();
  };

  useEffect(() => {
    if(typeof connectionStatusTrigger === 'number') {
      let textObjLocal: IModalText|null = null;
      const textObjTemp: IModalText = textObj[connectionStatusTrigger];
      
      if(
        connectionStatusTrigger === taskConnectStatuses.STATUS_RESTRICTED ||
        connectionStatusTrigger === taskConnectStatuses.STATUS_ERROR
      ) {
        textObjLocal = {
          title: textObjTemp?.title ?? 'Создать связь для задачи',
          btns: []
        }
      }
      else {
        // btns[0] элемент массива - текст для параллельной задачи
        // btns[1] элемент массива - текст для подзадачи
        textObjLocal = {
          title: currentTask?.title ?? 'Создать связь для задачи',
          btns: []
        };
        
        if(textObjTemp.btns[0]) {
          textObjLocal.btns.push({
            actionId: textObjTemp.btns[0].actionId,
            title: textObjTemp.btns[0].title.replace('%%', '«' + (overTask?.title ?? '') + '»')
          });
        }
        
        if(textObjTemp.btns[1]) {
          textObjLocal.btns.push({
            actionId: textObjTemp.btns[1].actionId,
            title: textObjTemp.btns[1].title.replace('%%', '«' + (overTask?.title ?? '') + '»')
          });
        }
      }
      
      setText(textObjLocal);
      setTaskConnectModalBp({isModalOpen: true});
    }
  }, [connectionStatusTrigger]);
  
  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={'modal-task-connect'}
        open={isModalOpen ?? false}
        onClose={onCloseHandle}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={isModalOpen}>
          <div className={'modal-task-connect__content-wr'}>
            <div className="modal-task-connect__content">
              <div className="modal-task-connect__content-item">
                <div className={'modal-task-connect__title-wr'}>
                  <div className="modal-task-connect__title">
                    {!!text.btns.length &&
                      <>Задача «{text?.title}»</>
                    }
                    {!text.btns.length &&
                      <>{text?.title}</>
                    }
                  </div>
                  <div className="modal-task-connect__delete-ico-wr"
                       onClick={() => onButtonClickHandle(taskConnectActions.ACTION_CANCEL)}
                  >
                    <CloseIcon />
                  </div>
                </div>
              </div>
            </div>
            
            <div className="modal-task-connect__btns">
              {!!text.btns.length && (
                <div className="modal-task-connect__btns-left">
                  {text.btns.map((btn, i) => (
                    <button className="modal-task-connect__btn"
                            key={i}
                            onClick={() => onButtonClickHandle(btn.actionId)}
                    >
                      <span className="modal-task-connect__btn-title">{btn.title}</span>
                    </button>
                  ))}
                </div>
              )}
              
              <div className="modal-task-connect__btns-right">
                <button className="modal-task-connect__btn modal-task-connect__btn--cancel"
                        onClick={() => onButtonClickHandle(taskConnectActions.ACTION_CANCEL)}
                >
                  <span className="modal-task-connect__btn-title">Отмена</span>
                </button>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
}

const mapStateToProps = (state: State) => {
  return {
    isModalOpen: state.businessPage.taskConnectModal.isModalOpen,
    connectionStatusTrigger: state.businessPage.taskConnectModal.connectionStatusTrigger,
    currentTask: state.businessPage.taskConnectModal.currentTask,
    overTask: state.businessPage.taskConnectModal.overTask
  };
};

const mapDispatchToProps = {
  setTaskConnectModalBp,
  setActionConnectIdTrigger
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalTaskConnectBp);
