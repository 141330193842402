import { State } from "../../rootReducer";
import { createSelector } from "reselect";
import { User } from "../../common/types";
import { getPositions, getUsers } from "../../common/selectors";
import { ObjectsTerms } from "../../common/constants";
import { IEditingObjectsFile } from "./interfaces";
import { TabIdsExtraForm } from "../TypicalTasks/constants";

export const getUsersResponsible = (state: State): User[] =>
  state.objectsPage.editingObjectsForm.data.responsible?.usersData ?? [];
export const getObjectSections = (state: State): any[] =>
  state.objectsPage.editingObjectsForm.data.sections ?? [];

export const getUsersSpectatorsIds = (state: State): number[] =>
  state.objectsFormAddTask.data.spectators.usersIds;
export const getTermsFilterIds = (state: State): number[] =>
  state.objectsPage.filters.terms;
export const getUsersResponsibleForFilter = (state: State): User[] =>
  state.objectsPage.filters.usersDataForFilter ;
export const getUsersResponsibleFilterIds = (state: State): number[] =>
  state.objectsPage.filters.usersIds;
const getCurrentUser = (state: State) => state.commonInfo.currentUserInfo;
  
const defaultTermsValues: any = () => {
  return [
    {
      text: ObjectsTerms.MORE_HUNDRED_TEXT,
      value: ObjectsTerms.MORE_HUNDRED_STATUS,
    },
    {
      text: ObjectsTerms.MORE_SIXTY_TEXT,
      value: ObjectsTerms.MORE_SIXTY_STATUS,
    },
    {
      text: ObjectsTerms.MORE_THREETY_TEXT,
      value: ObjectsTerms.MORE_THREETY_STATUS,
    },
    {
      text: ObjectsTerms.LESS_THREETY_TEXT,
      value: ObjectsTerms.LESS_THREETY_STATUS,
    },
    { text: ObjectsTerms.OVERDUE_TEXT, value: ObjectsTerms.OVERDUE_STATUS },
  ];
};

export const getUserForSelect = createSelector(
  [getUsersResponsible],
  (users) => {
    return users?.map((user) => {
      return {
        value: user.id,
        label: user.surname + " " + user.name,
      };
    });
  }
);

export const getUsersSpectatorsData = createSelector(
  [getUsers, getUsersSpectatorsIds, getPositions, getCurrentUser],
  (users, usersFilterIds, positions, currentUser) => {
    let selected: any = [];
    let list = users
      .filter(user => {
        // Тут возможно нужно будет доработать условия исключения юзеров из выборки
        if(currentUser?.isAdmin && (user.id === currentUser.id)) return false;
        
        return true;
      })
      .map((user) => {
        const item = {
          urlImage: user.image?.url || false,
          text: `${user.surname} ${user.name}`,
          position: positions?.find(
            (position) => position?.id === user?.positions[0]
          )?.name,
          value: user.id,
        };
  
        if (usersFilterIds.includes(user.id)) {
          selected.push(item);
        }
  
        return item;
    });

    return { list, selected };
  }
);

export const getTermsFilterData = createSelector(
  [defaultTermsValues, getTermsFilterIds],
  (terms, termsFilterIds) => {
    let selected: any = [];
    // @ts-ignore
    let list = terms.map((term) => {
      const item = {
        text: term.text,
        value: term.value,
      };

      if (termsFilterIds.includes(term.value)) {
        selected.push(item);
      }

      return item;
    });

    return { list, selected };
  }
);

export const getResponsiblesForFilterData = createSelector(
  [getUsersResponsibleForFilter, getUsersResponsibleFilterIds, getPositions],
  (users, usersFilterIds, positions) => {
    let selected: any = [];
    
    const allOption = [{ text: "Все", value: 0, position: undefined, urlImage: false }];
    let list = users?.map((user) => {
      const item = {
        urlImage: user.image?.url || false,
        text: `${user.surname} ${user.name}`,
        position: positions?.find(
          (position) => position?.id === user?.positions[0]
        )?.name,
        value: user.id,
      };
      if (usersFilterIds?.includes(0)) {
        selected = []
      }else if (usersFilterIds?.includes(user.id)) {
        selected.push(item);
      }
      return item;
    });

    return { list: allOption.concat(list as any), selected };
  }
);

export const getImportantDocuments = createSelector(
  [getObjectSections],
  (objectSections) => {
    let result: IEditingObjectsFile[] = [];
    
    if(objectSections?.length) {
      objectSections.forEach(section => {
        if(section.extraFields?.length) {
          section.extraFields.forEach(field => {
            if(field.typical_task_data.id === TabIdsExtraForm.DOCUMENT_TAB) {
              // Если выбрано в Типовой задаче передавать в объект и файл был загружен на сервер  
              if(field.typical_task_data.passToObject && field.value?.url) {
                result.push({
                  id: field.value.id,
                  name: field.value.name,
                  link: field.value.url
                });
              }
            }
          })
        }
      });
    }
    
    return result;
  }
);
