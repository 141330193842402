import { getPositions } from "../../common/selectors";
import { State } from "../../rootReducer";
import { createSelector } from "reselect";
import { User } from "../../common/types";

export const getUsers = (state: State): User[] => state.commonInfo.users;
// Здесь должна быть выборка авторов типовых задач, т.к на бэке это не сделано, берутся все юзеры, которые есть в приложении
// export const getUsers = (state: State): User[] => state.typicalTasksPage.filters.usersDataForFilter;
export const getUsersFilterIds = (state: State): number[] => state.typicalTasksPage.filters.usersIds;

export const getUsersFilterData = createSelector(
  [getUsers, getUsersFilterIds, getPositions],
  (users, usersFilterIds, positions) => 
  {
    let selected: any = [];
    let list = users
      .map((user) => {
        const item = {
          urlImage: user.image?.url || false,
          text: `${user.surname} ${user.name}`,
          position: positions?.find(
            (position) => position?.id === user?.positions[0]
          )?.name,
          value: user.id,
        };

        if(usersFilterIds.includes(user.id)) {
          selected.push(item)
        }
        
        return item;
      });

    return {list, selected};
  }
);
