import { sortConditions } from "./constants";

export const columnsOfTableslTasks = [
    {
        columnId: sortConditions.ID,
        showingText: "id",
        //style: { minWidth: "50px"},
    },
    {
        columnId: sortConditions.NAME,
        showingText: "Типовой проект",
        //style: { minWidth: "220px", maxWidth: "750px" },
    },
    {
        columnId: sortConditions.ALL_STAGE,
        showingText: "Всего этапов",
        //style: { minWidth: "93px" },
    },
    {
        columnId: sortConditions.ALL_BUSINESS_PROCESS,
        showingText: "Всего БП",
        //style: { minWidth: "64px" },
    },
    {
        columnId: sortConditions.ALL_TASKS,
        showingText: "Всего задач",
        //style: { minWidth: "93px" },
    },
    {
        columnId: sortConditions.DURATION,
        showingText: "Срок исполнения",
        //style: { minWidth: "121px" },
    },
    {
        columnId: sortConditions.AUTHOR,
        showingText: "Автор",
        //style: { minWidth: "220px" },
    },
    {
        columnId: sortConditions.CREATED_AT,
        showingText: "Дата создания",
        //style: { minWidth: "121px" },
    },
    {
        columnId: sortConditions.STATUS,
        showingText: "Состояние",
        //style: { minWidth: "161px", paddingRight: "5px" },
    }
    
]