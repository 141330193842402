export enum errorMessagesConstants {
  GET_ONE_PROJECT = 'Произошла ошибка при загрузки проекта...',
  GET_RELOADED_PROJECTS = 'Произошла ошибка при перезагрузки проектов...',
  FIND_PROJECTS = 'Произошла ошибка при поиске проектов...',
  GET_TASK_STATISTICS = 'Произошла ошибка при загрузки статистики...'
}

export enum messagesTaskConstants {
  DELETE_TASK_ERROR = 'Произошла ошибка при удалении задачи...',
  DELETE_TASK_SUCCESS = 'Задача удалена',
  DELETE_TASK_STACK_SUCCESS = 'Стек связанных задач удален',
  UPDATE_TASK_SUCCESS = 'Задача успешно обновлена',
  UPDATE_TASK_ERROR = 'Произошла ошибка при обновлении задачи...'
}

export enum messagesProjectConstants {
  DELETE_PROJECT_ERROR = 'Произошла ошибка при удалении проекта...',
  DELETE_PROJECT_SUCCESS = 'Проект удален',
}

export enum tooltipsTitle {
  DOWNLOAD_EXCEL_FILE = 'Скачать excel-файл со списком задач',
  SHOW_CALENDAR = 'Показать календарь',
  SELECT_COLUMNS = 'Выбрать колонки',
  BP_IN_WORK = 'Бизнес-процессы в работе',
  BP_DRAFT = 'Черновики бизнес-процессов',
  ADDITIONAL_ACTIONS = 'Дополнительные действия...',
  UPLOAD_FORM_FILE = 'Загрузить заполненную форму группового добавления сотрудников',
  DOWNLOAD_FORM_FILE = 'Скачать форму для группового добавления сотрудников'
}

export enum datePicker {
  DATE_FORMAT = 'DD-MM-YYYY HH:mm:ss',
  DATE_OPEN_FORMAT = 'MM/DD/YYYY HH:mm:ss',
  DATE_MIN_DEFAULT = '01/01/1950 00:00:00',
  DATE_MAX_DEFAULT = '01/01/2100 00:00:00',
  YEAR_MIN_DEFAULT = '1950',
  YEAR_MAX_DEFAULT = '2100',
}

export enum deleteTaskStatuses {
  DELETE_TASK = 0,
  DELETE_TASK_ALLOWED = 'allowed',
  DELETE_TASK_ERROR = 'error'
}

export enum deleteProjectStatuses {
  DELETE_PROJECT_TASK = 0,
  DELETE_PROJECT_BP = 1,
  DELETE_PROJECT_TASK_AND_BP = 3,
  DELETE_PROJECT_ERROR = 'error'
}


export enum pagesName {
  PROJECTS_PAGE = '/projects'
}

export enum taskStatuses {
  IN_WORK = 10,
  COMPLETED = 13,
  OVERDUE = 14
}

export enum Roles {
  ADMIN = 1,
  DIRECTOR = 2,
  RP = 111 // TODO изменить, когда будет эта роль
}

export const roleDirector = 2;

export enum CheckContexts {
  IS_USER_CAN_EDIT_AND_SAVE_PROJECT = 'IS_USER_CAN_EDIT_AND_SAVE_PROJECT',
  IS_USER_CAN_SET_PROJECT_OBSERVERS = 'IS_USER_CAN_SET_PROJECT_OBSERVERS',
  IS_USER_CAN_SET_TASK_OBSERVERS = 'IS_USER_CAN_SET_TASK_OBSERVERS'
}

export enum TypicalTasksActivity {
  ALL_TEXT = 'Все',
  ACTIVE_TEXT = 'Активные',
  ACTIVE_TEXT_FORM = 'Активная',
  NOT_ACTIVE_TEXT_FORM = 'Неактивная',
  NOT_ACTIVE_TEXT = 'Неактивные',
  ALL_STATUS = 0,
  ACTIVE_STATUS = 1,
  NOT_ACTIVE_STATUS = 2
}

export enum ObjectsTerms {
  ALL_TEXT = 'Все',
  MORE_HUNDRED_TEXT = 'Больше 100 дней',
  MORE_SIXTY_TEXT = 'Больше 60 дней',
  MORE_THREETY_TEXT = 'Больше 30 дней',
  LESS_THREETY_TEXT = 'Меньше 30 дней',
  OVERDUE_TEXT = 'Просрочен',
  ALL_STATUS = 0,
  MORE_HUNDRED_STATUS = 1,
  MORE_SIXTY_STATUS = 2,
  MORE_THREETY_STATUS = 3,
  LESS_THREETY_STATUS = 4,
  OVERDUE_STATUS = 5,
}

export enum TypicalTasksPersonal {
  NOT_PERSONAL_STATUS = 0,
  PERSONAL_STATUS = 1,
}
export enum OjectsPersonal {
  NOT_PERSONAL_STATUS = 0,
  PERSONAL_STATUS = 1,
}

export enum TypicalTasksTooltips {
  ADD_FIELD = 'Добавляем поле в форму исполнения задачи',
  CHECKBOX_EXECUTORS = 'Исполнители смогут единожды добавить новых исполнителей по задаче'
}

export enum TypicalTasksTime {
  DAY_FORMAT = 0,
  HOUR_FORMAT = 1
}
export const defaultHeaders = {
  FROM_WEB: {TITLE: 'FromWeb', VALUE: 'true'}
}
