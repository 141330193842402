import React from "react";
import { State } from "../../../../rootReducer";
import { connect } from "react-redux";
import NormalForm from "./NormalForm/NormalForm";
import ExtraForm from "./ExtraForm/ExtraForm";
import { setTaskFormOpen, setTaskFullFormOpen } from "../../actions";
import PreloaderLocal from "../../../../common/components/PreloaderLocal/PreloaderLocal";

import "./FormTasks.scss";

const FormTasks: React.FC<{
  isFormTasksOpen: boolean;
  isFullFormTasksOpen: boolean;
  isLoadingTypicalTask: boolean;
  setTaskFormOpen: (mode: boolean) => void;
  setTaskFullFormOpen: (mode: boolean) => void;
}> = ({
  isFormTasksOpen,
  isFullFormTasksOpen,
  isLoadingTypicalTask
}) => {

  return (
    <div
      className={
        "form-tasks" +
        (isFormTasksOpen ? " form-tasks--open" : "") +
        (isFullFormTasksOpen ? " form-tasks--open-full" : "") +
        (isLoadingTypicalTask ? " form-tasks--show-preloader" : "")
      }>
      <div className="form-tasks__content">
        <div className="form-tasks__left">
          <div className="form-tasks__preloader">
            <PreloaderLocal size="big" />
          </div>
          
          <NormalForm />
        </div>

        <div className="form-tasks__right">
          <div className="form-tasks__preloader">
            <PreloaderLocal />
          </div>
          
          <ExtraForm />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: State) => {
  return {
    isFormTasksOpen: state.typicalTasksPage.isFormTasksOpen,
    isFullFormTasksOpen: state.typicalTasksPage.isFullFormTasksOpen,
    isLoadingTypicalTask: state.typicalTasksPage.isLoadingTypicalTask
  };
};

const mapDispatchToProps = {
  setTaskFormOpen,
  setTaskFullFormOpen,
};

export default connect(mapStateToProps, mapDispatchToProps)(FormTasks);
