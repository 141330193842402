import { Action } from "redux";
import { 
  IExtraFormData, 
  ISortConditions, 
  INormalFormTreaty, 
  TypicalTreatyActual, 
  TypicalTreatiesActivity, 
  TypicalTreatiesPersonal, 
  IDeleteTypicalTreatiesModal, 
  IEditStage, 
  ITypicalTreatiesServerData, 
  IMainTreatyServerData, 
  IDeleteStageTreatiesModal,
  IDeleteStageItemTreatiesModal,
  ITreatyFilterFormExtra
 } from "./interfaces";
import { IMeta } from "../../common/types";
import { ITypicalTaskServerData } from "../TypicalTasks/interfaces";
import { ITaskBP } from "../BusinessPage/interfaces";


export type TypeTreatyActual =
  | TypicalTreatyActual.ACTUAL_STATUS
  | TypicalTreatyActual.NOT_ACTUAL_STATUS;

export type TypeTypicalTreatiesActivity = 
  | TypicalTreatiesActivity.ACTIVE_STATUS
  | TypicalTreatiesActivity.NOT_ACTIVE_STATUS;

export type TypeTypicalTreatiesPersonal =
  | TypicalTreatiesPersonal.PERSONAL_STATUS
  | TypicalTreatiesPersonal.NOT_PERSONAL_STATUS;

export interface ITreatyPageState {
  mainTreaty: IMainTreatyServerData[],
  treaties: ITypicalTreatiesServerData[],
  normalForm: INormalFormTreaty,
  isEditForm: boolean;
  isFormTreatyOpen: boolean;
  isFullFormTreatyOpen: boolean;
  extraForm: IExtraFormData;
  deleteStageTreatyModal: IDeleteStageTreatiesModal;
  deleteStageItemTreatyModal: IDeleteStageItemTreatiesModal;
  deleteTypicalTreatiesModal: IDeleteTypicalTreatiesModal;
  isLoadingTreaty: boolean;
  isLoadingTreatyCard: boolean;
  currentPage: number;
  filters: {
    activity: TypeTypicalTreatiesActivity;
    personal: TypeTypicalTreatiesPersonal;
  },
  sortCondition: ISortConditions;
  searchTt: string | null;
  typicalTreatiesMeta: IMeta | null;
  isSavingTreaty: boolean,
}

export enum TreatyPageActionTypes  {
  GET_MAIN_TREATIES = "@@TREATY_PAGE/GET_MAIN_TREATIES",
  SET_MAIN_TREATIES = "@@TREATY_PAGE/SET_MAIN_TREATIES",
  SET_TREATIES = "@@TREATY_PAGE/SET_TREATIES",
  SET_NORMAL_FORM_DATA = "@@TREATY_PAGE/SET_NORMAL_FORM_DATA",
  SET_IS_EDIT_FORM_TREATY = "@@TREATY_PAGE/SET_IS_EDIT_FORM_TREATY",
  SET_IS_FORM_TREATY = "@@TREATY_PAGE/SET_IS_FORM_TREATY",
  SET_IS_FULL_FORM_TREATY = "@@TREATY_PAGE/SET_IS_FULL_FORM_TREATY",
  SET_LOADING_TREATY = "@@TREATY_PAGE/SET_LOADING_TREATY",
  SET_FORM_DEFAULT_VALUES = "@@TREATY_PAGE/SET_FORM_DEFAULT_VALUES",
  SET_EXTRA_FORM_TREATY_DEFAULT_OPEN =  "@@TREATY_PAGE/SET_EXTRA_FORM_TREATY_DEFAULT_OPEN",
  SET_TYPICAL_TREATY_ACTUAL = "@@TREATY_PAGE/SET_TYPICAL_TREATY_ACTUAL",
  GET_TYPICAL_TRATIES = "@@TREATY_PAGE/GET_TYPICAL_TRATIES",
  SET_CURRENT_PAGE = "@@TREATY_PAGE/SET_CURRENT_PAGE",
  SET_TYPICAL_TREATIES_FILTER_ACTIVITY = "@@TREATY_PAGE/SET_TYPICAL_TREATIES_FILTER_ACTIVITY",
  SET_TYPICAL_TREATIES_FILTER_PERSONAL = "@@TREATY_PAGE/SET_TYPICAL_TREATIES_FILTER_PERSONAL",
  SET_SORT_CONDITION = "@@TREATY_PAGE/SET_SORT_CONDITION",
  SET_SEARCH_TRATIES = "@@TREATY_PAGE/SET_SEARCH_TRATIES",
  GET_TYPICAL_TREATY = "@@TREATY_PAGE/GET_TYPICAL_TREATY",
  SET_LOADING_TREATY_CARD = "@@TREATY_PAGE/SET_LOADING_TREATY_CARD",
  SET_EXTRA_FORM_STAGE_DATA = "@@TREATY_PAGE/SET_EXTRA_FORM_STAGE_DATA",
  SET_EXTRA_FORM_OPEN_STAGE_EDIT_FORM =  "@@TREATY_PAGE/SET_EXTRA_FORM_OPEN_STAGE_EDIT_FORM",
  SET_TT_META = "@@TREATY_PAGE/SET_TT_META",
  DELETE_TYPICAL_TREATIES = "@@TREATY_PAGE/DELETE_TYPICAL_TREATIES",
  SET_DELETE_MODAL_TREATY = "@@TREATY_PAGE/SET_DELETE_MODAL_TREATY",
  DELETE_TYPICAL_TREATIES_FROM_STATE = "@@TREATY_PAGE/DELETE_TYPICAL_TREATIES_FROM_STATE",
  CHECK_DELETE_TREATY = "@@TREATY_PAGE/CHECK_DELETE_TREATY",
  SET_EXTRA_FORM_OPEN_STAGE_CREATE_FORM =  "@@TREATY_PAGE/SET_EXTRA_FORM_OPEN_STAGE_CREATE_FORM",
  UPDATE_TREATY = "@@TREATY_PAGE/UPDATE_TREATY",
  UPDATE_TREATY_STORE = "@@TREATY_PAGE/UPDATE_TREATY_STORE",
  UPDATE_TYPICAL_TREATY = "@@TREATY_PAGE/UPDATE_TYPICAL_TREATY",
  UPDATE_TYPICAL_TREATY_STORE = "@@TREATY_PAGE/UPDATE_TYPICAL_TREATY_STORE",
  CREATE_TREATY = "@@TREATY_PAGE/CREATE_TREATY",
  UPDATE_TREATY_STAGE = "@@TREATY_PAGE/UPDATE_TREATY_STAGE",
  DELETE_STAGE = "@@TREATY_PAGE/DELETE_STAGE",
  DELETE_STAGE_STORE = "@@TREATY_PAGE/DELETE_STAGE_STORE",
  SET_DELETE_STAGE_MODAL_TREATY = "@@TREATY_PAGE/SET_DELETE_STAGE_MODAL_TREATY",
  ADD_STAGE = "@@TREATY_PAGE/ADD_STAGE",
  SET_EXTRA_FORM_ACTIVE_TAB_INDEX = "@@TREATY_PAGE/SET_EXTRA_FORM_ACTIVE_TAB_INDEX",
  SET_TYPICAL_TASKS_TREATY = "@@TREATY_PAGE/SET_TYPICAL_TASKS_TREATY",
  GET_TYPICAL_TASKS_TREATY = "@@TREATY_PAGE/GET_TYPICAL_TASKS_TREATY",
  SET_CURRENT_PAGE_TYPICAL_TASKS_TREATY = "@@TREATY_PAGE/SET_CURRENT_PAGE_TYPICAL_TASKS_TREATY",
  SET_LOADING_EXTRA_FORM_TREATY =  "@@TREATY_PAGE/SET_LOADING_EXTRA_FORM_TREATY",
  SET_TASK_ITEMS_STAGE_TREATY = "@@TREATY_PAGE/SET_TASK_ITEMS_STAGE_TREATY",
  SET_EXTRA_FORM_OPEN_TASKS_BP_FORM = "@@TREATY_PAGE/SET_EXTRA_FORM_OPEN_TASKS_BP_FORM",
  SET_BP_LIST_TREATY = "@@TREATY_PAGE/SET_BP_LIST_TREATY",
  GET_BP_LIST_TREATY = "@@TREATY_PAGE/GET_BP_LIST_TREATY",
  SET_CURRENT_PAGE_BP_LIST_TREATY = "@@TREATY_PAGE/SET_CURRENT_PAGE_BP_LIST_TREATY",
  SET_STAGE_ID_TREATY = "@@TREATY_PAGE/SET_STAGE_ID_TREATY",
  SET_SEARCH_BPS = "@@TREATY_PAGE/SET_SEARCH_BPS",
  SET_CURRENT_PAGE_BPS = "@@TREATY_PAGE/SET_CURRENT_PAGE_BPS",
  DELETE_ITEM_FROM_STAGES = "@@TREATY_PAGE/DELETE_ITEM_FROM_STAGES",
  SET_DELETE_ITEM_STAGE_MODAL_TREATY = "@@TREATY_PAGE/SET_DELETE_ITEM_STAGE_MODAL_TREATY",
  SET_MAIN_BOOL = "@@TREATY_PAGE/SET_MAIN_BOOL",
  SET_TREATY_FORM_FILTER = "@@TREATY_PAGE/SET_TREATY_FORM_FILTER",
  SET_SAVING_TREATY = "@@TREATY_PAGE/SET_SAVING_TREATY",
}

export interface getMainTreatiesAction
  extends Action<TreatyPageActionTypes.GET_MAIN_TREATIES> {
    params?: object;
    action?: string;
  }

export interface setMainTreatiesAction
  extends Action<TreatyPageActionTypes.SET_MAIN_TREATIES> {
    mainTreaty: any;
    action?: string;
}

export interface setTreatiesAction
  extends Action<TreatyPageActionTypes.SET_TREATIES> {
  treaties: any;
  action?: string
}

export interface setNormalFormDataAction
  extends Action<TreatyPageActionTypes.SET_NORMAL_FORM_DATA> {
  normalFormData: any;
}

export interface setIsEditFormAction
  extends Action<TreatyPageActionTypes.SET_IS_EDIT_FORM_TREATY> {
  isEditForm: boolean;
}

export interface setIsTreatyFormOpenAction
  extends Action<TreatyPageActionTypes.SET_IS_FORM_TREATY> {
    isFormTreatyOpen: boolean;
}

export interface setIsTreatyFullFormOpenAction
  extends Action<TreatyPageActionTypes.SET_IS_FULL_FORM_TREATY> {
    isFullFormTreatyOpen: boolean;
}

export interface setIsLoadingTreatyAction
  extends Action<TreatyPageActionTypes.SET_LOADING_TREATY> {
  isLoadingTreaty: boolean;
}

export interface setFormDefaultValuesAction
  extends Action<TreatyPageActionTypes.SET_FORM_DEFAULT_VALUES> {
}

export interface setExtraFormTreatyDefaultOpenAction
  extends Action<TreatyPageActionTypes.SET_EXTRA_FORM_TREATY_DEFAULT_OPEN> {
  isExtraFormTreatyDefaultOpen: boolean;
  }

export interface getTypicalTreatiesAction
  extends Action<TreatyPageActionTypes.GET_TYPICAL_TRATIES> {
  params?: object;
  action?: string;
}

export interface setCurrentPageAction
  extends Action<TreatyPageActionTypes.SET_CURRENT_PAGE> {
  currentPage: number;
}

export interface setTypicalTreatiesFilterActivityAction
  extends Action<TreatyPageActionTypes.SET_TYPICAL_TREATIES_FILTER_ACTIVITY> {
  activity: TypeTypicalTreatiesActivity;
}

export interface setTypicalTreatiesFilterPersonalAction
  extends Action<TreatyPageActionTypes.SET_TYPICAL_TREATIES_FILTER_PERSONAL> {
  personal: TypeTypicalTreatiesPersonal;
}

export interface setSortConditionsAction
  extends Action<TreatyPageActionTypes.SET_SORT_CONDITION> {
  sortCondition: ISortConditions;
}

export interface setSearchTratiesAction
  extends Action<TreatyPageActionTypes.SET_SEARCH_TRATIES> {
  searchTt: string | null;
}

export interface setTtMetaAction
  extends Action<TreatyPageActionTypes.SET_TT_META> {
  typicalTreatiesMeta: IMeta | null;
}

export interface deleteTypicalTreatiesAction
  extends Action<TreatyPageActionTypes.DELETE_TYPICAL_TREATIES> {
  id: string | number;
}

export interface setDeleteModalTreatyAction
  extends Action<TreatyPageActionTypes.SET_DELETE_MODAL_TREATY> {
  data: IDeleteTypicalTreatiesModal;
}

export interface deleteTypicalTreatiesFromStateAction
  extends Action<TreatyPageActionTypes.DELETE_TYPICAL_TREATIES_FROM_STATE> {
  id: string | number;
}

export interface checkDeleteTreatyAction
  extends Action<TreatyPageActionTypes.CHECK_DELETE_TREATY> {
  id: string | number;
}

export interface getTypicalTreatyAction
  extends Action<TreatyPageActionTypes.GET_TYPICAL_TREATY> {
  id?: number | string;
}

export interface setIsLoadingTreatyCardAction
  extends Action<TreatyPageActionTypes.SET_LOADING_TREATY_CARD> {
  isLoadingTreatyCard: boolean;
}

export interface setExtraFormTreatyStageDatatAction
extends Action<TreatyPageActionTypes.SET_EXTRA_FORM_STAGE_DATA> {
stageData: IEditStage;
}

export interface setExtraFormTreatyEditFormOpenAction
  extends Action<TreatyPageActionTypes.SET_EXTRA_FORM_OPEN_STAGE_EDIT_FORM> {
    isEditStageOpen: boolean;
  }

  export interface setExtraFormTreatyCreateFormOpenAction
  extends Action<TreatyPageActionTypes.SET_EXTRA_FORM_OPEN_STAGE_CREATE_FORM> {
    isCreateStageOpen: boolean;
  }

export interface updateTreatyStageStoreAction
extends Action<TreatyPageActionTypes.UPDATE_TREATY_STAGE> {
  id: number | string;
  name: string
}

export interface deleteTreatyStageStoreAction
extends Action<TreatyPageActionTypes.DELETE_STAGE_STORE> {
  id: number | string;
}

export interface deleteTreatyStageAction
  extends Action<TreatyPageActionTypes.DELETE_STAGE> {
  id: number | string;
}


export interface updateTreatyAction
  extends Action<TreatyPageActionTypes.UPDATE_TREATY> {
  data: object;
}

export interface updateTreatyStoreAction
  extends Action<TreatyPageActionTypes.UPDATE_TREATY_STORE> {
  data: IMainTreatyServerData;
}

export interface updateTypicalTreatyAction
  extends Action<TreatyPageActionTypes.UPDATE_TYPICAL_TREATY> {
  data: object;
}

export interface updateTypicalTreatyStoreAction
  extends Action<TreatyPageActionTypes.UPDATE_TYPICAL_TREATY_STORE> {
  data: any;
}

export interface createTreatyAction
  extends Action<TreatyPageActionTypes.CREATE_TREATY> {
  data: object;
}

export interface setDeleteStageTreatyModalAction
  extends Action<TreatyPageActionTypes.SET_DELETE_STAGE_MODAL_TREATY> {
  data: IDeleteStageTreatiesModal;
}

export interface addStageTreatyAction
  extends Action<TreatyPageActionTypes.ADD_STAGE> {
    data: any;
  }

  export interface setItemsStageTreatyExtraAction
  extends Action<TreatyPageActionTypes.SET_TASK_ITEMS_STAGE_TREATY> {
  itemAdd?: any
}

  export interface setStageIdTreatyExtraAction
  extends Action<TreatyPageActionTypes.SET_STAGE_ID_TREATY> {
  id?: number | string;
}

  export interface setExtraFormActiveTabIndexAction
  extends Action<TreatyPageActionTypes.SET_EXTRA_FORM_ACTIVE_TAB_INDEX> {
  activeTabIndex: number;
}
export interface setLoadingExtraFormTreatyAction
  extends Action<TreatyPageActionTypes.SET_LOADING_EXTRA_FORM_TREATY> {
  isLoading: boolean;
}

export interface setExtraFormTreatyTasksAndBPFormOpenAction
extends Action<TreatyPageActionTypes.SET_EXTRA_FORM_OPEN_TASKS_BP_FORM> {
  isTasksAndBpOpen: boolean;
}

export interface setBpListTreatyExtraFormAction
  extends Action<TreatyPageActionTypes.SET_BP_LIST_TREATY> {
  bpList: any;
  action?: string; 
}

export interface getBpListTreatyExtraFormAction
  extends Action<TreatyPageActionTypes.GET_BP_LIST_TREATY> {
  params?: object;
  action?: string;
}

export interface setCurrentPageBpListTreatyAction
  extends Action<TreatyPageActionTypes.SET_CURRENT_PAGE_BP_LIST_TREATY> {
  currentPageBpList: number;
}

export interface setSearchTypicalBpsAction extends Action<TreatyPageActionTypes.SET_SEARCH_BPS> {
  searchTypicalBps: string|null;
}
export interface deleteItemFromStagesAction
extends Action<TreatyPageActionTypes.DELETE_ITEM_FROM_STAGES> {
  stageId: number | string;
  itemId: number | string;
}

export interface setDeleteItemStageTreatyModalAction
  extends Action<TreatyPageActionTypes.SET_DELETE_ITEM_STAGE_MODAL_TREATY> {
  data: IDeleteStageItemTreatiesModal;
}

export interface setMainBoolAction
  extends Action<TreatyPageActionTypes.SET_MAIN_BOOL> {
  isMain: boolean;
}

export interface updateStageActualAction
extends Action<TreatyPageActionTypes.SET_TYPICAL_TREATY_ACTUAL> {
  stageId: number | string;
  isActual: number
}

export interface setTreatyForFilterExtraAction
  extends Action<TreatyPageActionTypes.SET_TREATY_FORM_FILTER> {
  treatyForFilter: ITreatyFilterFormExtra;
}

export interface setIsSavingTreatyTaskAction
  extends Action<TreatyPageActionTypes.SET_SAVING_TREATY> {
    isSavingTreaty: boolean;
}

export type TreatyPageActions =
  | getMainTreatiesAction
  | setMainTreatiesAction
  | setTreatiesAction
  | setNormalFormDataAction
  | setIsEditFormAction
  | setIsTreatyFormOpenAction
  | setIsTreatyFullFormOpenAction
  | setIsLoadingTreatyAction
  | setFormDefaultValuesAction
  | setExtraFormTreatyDefaultOpenAction
  | getTypicalTreatiesAction
  | setCurrentPageAction
  | setTypicalTreatiesFilterActivityAction
  | setTypicalTreatiesFilterPersonalAction
  | setSortConditionsAction
  | setSearchTratiesAction
  | getTypicalTreatyAction
  | setIsLoadingTreatyCardAction
  | setExtraFormTreatyStageDatatAction
  | setExtraFormTreatyEditFormOpenAction
  | setSearchTratiesAction
  | setTtMetaAction
  | deleteTypicalTreatiesAction
  | setDeleteModalTreatyAction
  | deleteTypicalTreatiesFromStateAction
  | checkDeleteTreatyAction
  | setExtraFormTreatyCreateFormOpenAction
  | updateTreatyStageStoreAction
  | updateTreatyAction
  | updateTypicalTreatyAction
  | deleteTreatyStageStoreAction
  | deleteTreatyStageAction
  | createTreatyAction
  | setDeleteStageTreatyModalAction
  | addStageTreatyAction
  | setItemsStageTreatyExtraAction
  | setExtraFormActiveTabIndexAction
  | setLoadingExtraFormTreatyAction
  | setExtraFormTreatyTasksAndBPFormOpenAction
  | setBpListTreatyExtraFormAction
  | getBpListTreatyExtraFormAction
  | setCurrentPageBpListTreatyAction
  | setStageIdTreatyExtraAction
  | setSearchTypicalBpsAction
  | deleteItemFromStagesAction
  | setDeleteItemStageTreatyModalAction
  | updateTreatyStoreAction
  | updateTypicalTreatyStoreAction
  | setMainBoolAction
  | updateStageActualAction
  | setTreatyForFilterExtraAction
  | setIsSavingTreatyTaskAction;


  
