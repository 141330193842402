import React, { FC } from "react";
import { connect } from "react-redux";
import { State } from "../../../../rootReducer";

import "./TypicalTreatiesList.scss";
import FilterTypicalTreaties from "../FilterTypicalTreaties/FilterTypicalTreaties";
import TypicalTreatiesTableHeader from "../TypicalTreatiesTableHeader/TypicalTreatiesTableHeader";
import PreloaderLocal from "../../../../common/components/PreloaderLocal/PreloaderLocal";
import TypicalTreatiesComponent from "../TypicalTreatiesComponent/TypicalTreatiesComponent";
import { IMainTreatyServerData, ITypicalTreatiesServerData } from "../../interfaces";
import { IMeta } from "../../../../common/types";
import { getTypicalTreaties, setCurrentPage, setIsTreatyFormOpen } from "../../actions";


const TypicalTreatiesList: FC<{
  isLoadingTreaty: boolean;
  mainTreaty: IMainTreatyServerData[];
  typicalTreatiesMeta: IMeta | null;
  getTypicalTreaties: (params?: object, action?: string) => void;
  setCurrentPage: (currentPage: number) => void;
  currentPage: number;
  setIsTreatyFormOpen: (mode: boolean) => void;
}> = ({
  isLoadingTreaty,
  mainTreaty,
  typicalTreatiesMeta,
  getTypicalTreaties,
  setCurrentPage,
  currentPage,
  setIsTreatyFormOpen
}) => {
  const scrollHandler = (e: React.UIEvent<HTMLDivElement>) => {
    if (!typicalTreatiesMeta) return;

    const { scrollHeight, scrollTop, clientHeight } = e.currentTarget;

    if (
      scrollHeight <= scrollTop + clientHeight &&
      currentPage < typicalTreatiesMeta.last_page
    ) {
      setCurrentPage(currentPage + 1);
      getTypicalTreaties({}, "add");
    }
  };

  return (
    <div className="main-typical_treaties">
      <h1 className="main-typical_treaties__title">Типовой договор</h1>

      <div className="main-typical_treaties__filters">
        <FilterTypicalTreaties />
      </div>

      <div className="main-typical_treaties__content">
        {mainTreaty?.length > 0 && <TypicalTreatiesTableHeader />}
        <div className="main-typical_treaties__table">
          <div
            className={`main-typical_treaties__preloader-wr 
                          ${
                            isLoadingTreaty
                              ? " main-typical_treaties__preloader-wr--show"
                              : ""
                          }`}
          >
            <div className="main-typical_treaties__preloader">
              <PreloaderLocal />
            </div>
          </div>

          <div
            className="main-typical_treaties__table-items"
            onScroll={scrollHandler}
          >
            {/* map main договоров */}
            {mainTreaty?.map((item) => (
              <TypicalTreatiesComponent mainTreaty={item} key={item?.id} />
            ))}

            {!mainTreaty.length && (
              <div className="info_none_typical_treaties">
                Список типовых договоров пока пуст, вы можете
                <span
                  className="add_typical_treaties_link"
                  onClick={() => setIsTreatyFormOpen(true)}
                >
                  добавить новый
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: State) => ({
  isLoadingTreaty: state.typicalTreatyPage.isLoadingTreaty,
  mainTreaty: state.typicalTreatyPage.mainTreaty,
  typicalTreatiesMeta: state.typicalTreatyPage.typicalTreatiesMeta,
  currentPage: state.typicalTreatyPage.currentPage,
});

const mapDispatchToProps = {
  getTypicalTreaties,
  setCurrentPage,
  setIsTreatyFormOpen
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TypicalTreatiesList);
