import moment, { Moment } from "moment";
import { datePickerFormat } from "./components/ui/DatePickerTrue/constants";
import { IDateMessages } from "./components/FormEditTask/interfaces";
import { statusTask } from "../TasksPage/constants";

export const getDeadline = (
  duration: string, 
  date, 
  name: string, 
  startDate: string|Moment, 
  endDate: string|Moment
): {
  deadlineTime: string,
  datesMessages: IDateMessages
} => {
  let deadlineTime = '';

  let timeValue = moment.duration(duration).days();
  let timeType = 'days';

  if(timeValue === 0) {
    timeValue = moment.duration(duration).hours();
    timeType = 'hours';
  }

  // @ts-ignore
  deadlineTime = moment(date).add(timeValue, timeType);

  checkDates(date, name, startDate, endDate);
  
  return { deadlineTime, datesMessages: checkDates(date, 'endDate', startDate, endDate) };
}

export const checkDates = (
  date: null|string|Date|Moment, 
  name: string, 
  startDate: string|Moment, 
  endDate: string|Moment
): IDateMessages => {
  
  let dateErrorMessages: IDateMessages = {
    startDate: [],
    endDate: []
  };

  let isStartDateLessEndDate = false;
  let isEndDateLessStartDate = false;

  if(name === 'startDate' && !moment(date).isValid()) {
    dateErrorMessages.startDate.push('Поле обязательно для заполнения');
  }

  if(name === 'endDate' && !moment(date).isValid()) {
    dateErrorMessages.endDate.push('Поле обязательно для заполнения');
  }

  if(name === 'startDate') {
    isStartDateLessEndDate = moment(endDate, datePickerFormat.STANDARD_DATE_TIME).isBefore(date, 'date');
  }
  if(name === 'endDate') {
    isEndDateLessStartDate = moment(startDate, datePickerFormat.STANDARD_DATE_TIME).isAfter(date, 'minutes');
  }

  if(isStartDateLessEndDate) {
    dateErrorMessages.startDate.push('Дата начала не может быть больше даты окончания');
  }

  if(isEndDateLessStartDate) {
    dateErrorMessages.endDate.push('Дата окончания не может быть меньше даты начала');
  }

  return dateErrorMessages;
}

export const checkTaskStatusOverdue = (task: any): number => {
  return (task.status_id === statusTask.IN_WORK_STATUS && moment(task?.end).isBefore(moment()))
          ? statusTask.OVERDUE_STATUS
          : task?.status_id
}

export const checkDecimals = (value: string): string => {
  let fixedValue = value;
  const valuesArr = value.split('.');

  // .toFixed(2) не позволяет ввести такие значение, как 1,0
  // Поэтому, приводим к toFixed(2), когда знаков после запятой больше 2
  if(valuesArr[1]?.length >= 2) {
    fixedValue = Number(value).toFixed(2);
  }
  
  return fixedValue;
}
