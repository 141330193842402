import React from "react";
import styled from "styled-components";
import palette from "../../palette";
import { urlApp } from "../../../../App";
import Avatar from "../../Avatar/Avatar";
import Icon from "../../Icon";

type Props = {
  fontSize?: string,
  width?: string;
  disabled?: boolean;
  placeholder?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClick: (e: React.MouseEvent) => void;
  classname?: string;
  value: string;
  single_choice?: boolean;
  border?: string;
  selectedItemImage?: string;
  showAvatar?: boolean;
  IconName?: string;
};

const IconBox = styled.div`
  width: 40px;
  height: 32px;
  line-height: 32px;
  margin-left: 8px;
  text-align: center;
  background-color: #f3fafe;
  border-radius: 15px;
  
  & > img {
    border-radius: 0px;
  }
`;

const StyledInput = styled.input`
  background: ${({ disabled }) =>
    disabled ? "rgba(0, 0, 0, 0.02)" : palette.white};
  border: ${({ border }) =>
    border ? border : `1px solid ${palette.lightGray}`};
  box-sizing: border-box;
  border-radius: 6px;
  max-height: 2.5rem;
  height: 100%;
  width: ${({ width }) => width};
  padding: 0.75rem;
  padding-right: 4px;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: ${({fontSize})=>fontSize?fontSize:"16px"};
  line-height: 16px;
  color: ${palette.textBlack};
`;

const SearchInput: React.FC<Props> = ({
  fontSize,
  width = "100%",
  disabled,
  onChange,
  placeholder = "Поиск",
  onClick,
  classname,
  value,
  single_choice = false,
  border,
  selectedItemImage,
  showAvatar = false,
  IconName,
}) => {
  return (
    <div
      className='search-input'
      style={{
        display: "flex",
        border: `1px solid ${palette.lightGray}`,
        borderRadius: 6,
        height: `${fontSize?"42px":"50px"}`,
        marginBottom: "5px",
        alignItems: "center",
      }}
    >
      {showAvatar && (
        <div>
          <Avatar
            style={{
              width: "32px",
              display: `${IconName ? "none" : "block"}`,
              height: "32px",
              marginTop: "4px",
              marginLeft: "4px",
            }}
            name={value}
            url={
              selectedItemImage
                ? urlApp + `/files/${selectedItemImage}/download`
                : null
            }
          />
        </div>
      )}
      {IconName && (
        <IconBox>
          <Icon name={IconName} />
        </IconBox>
      )}

      <StyledInput
        fontSize={fontSize}
        border={"none"}
        type={"text"}
        value={value}
        className={classname}
        disabled={disabled}
        placeholder={value ? value : placeholder}
        width={width}
        onChange={onChange}
        onClick={onClick}
      />
      {single_choice && (
        <img
          onClick={onClick}
          src={require("../../../../img/down_grey.svg").default}
          style={{ paddingRight: "12px", width: "25px" }}
        />
      )}
    </div>
  );
};

export default SearchInput;
