import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { State } from '../../../rootReducer';
import { Comment, User, Role, Position } from '../../../common/types';
import FileElem from '../../../common/components/FileElem/FileElem';
import DateMaster from '../../../utils/DateMaster';
import { setParentComment } from '../actions';
import ParentComment from './ParentComment';
import Avatar from '../../../common/components/Avatar/Avatar';
import { urlApp } from '../../../App';
import moment from "moment";
import { taskDate } from "../constants";

type Props = {
  comment: Comment;
  users: User[];
  roles: Role[];
  positions: Position[];
  currentId: number;
  commentList: Comment[];
  deleteHandler: () => void;
  setParentComment: (parent: Comment) => void;
};

const CommentElem: React.FC<Props> = ({
  comment,
  users,
  roles,
  positions,
  currentId,
  commentList,
  deleteHandler,
  setParentComment,
}) => {
  const authorInfo = useMemo(() => {
    const authorId = comment.author_id ? comment.author_id : comment.user_id;
    let author: any = users.find((user) => user.id === authorId);

    if (!author) return { authorName: null, authorRole: null };
    let authorRole = roles.find((role) => role.id === author?.roles[0]);
    let authorPosition = positions.find(
      (position) => position.id === author?.positions[0]
    );

    return {
      authorName: `${author.surname} ${author.name}`,
      authorRole: authorRole?.name,
      authorPosition: authorPosition?.name,
      first_l_name: author.name.substr(0, 1),
      first_l_surname: author.surname.substr(0, 1),
      author: author,
    };
  }, [comment.author_id, roles, users]);

  const parentComment = useMemo(() => {
    let parentComment: Comment | null = null;
    if (comment.parent_id !== 0) {
      parentComment =
        commentList.find((com) => com.id === comment.parent_id) || null;
    }
    return parentComment;
  }, [comment.parent_id, commentList]);

  const commentDate = useMemo(() => {
    if (moment(comment.created_at, moment.ISO_8601, true).isValid()) {
      const formattedDate = new Date(comment.created_at);
      const dateMaster = new DateMaster(formattedDate);

      return dateMaster.commentDate;
    } else {
      const date = moment(comment.created_at, taskDate.DEFAULT_FORMAT)
        .utc(true)
        .toDate();
      const dateMaster = new DateMaster(date);
      return dateMaster.commentDate;
    }
  }, [comment.created_at]);

  let name: string = `${authorInfo.first_l_surname}
  ${authorInfo.first_l_name}`;

  return (
    <div
      className={
        `comment${comment.author_id === currentId ? " my_comment" : ""}` +
        (comment.is_system === 1 ? " comment--system" : "")
      }
    >
      <div className="comment_head">
        <Avatar
          style={{ width: "40px", height: "40px" }}
          url={
            authorInfo?.author?.image
              ? urlApp + `/files/${authorInfo?.author?.image.url}/download`
              : null
          }
          name={name && name}
        />

        <div className="comment_author">
          <div className="comment_author_name">{authorInfo.authorName}</div>
          <div className="comment_author_role">{authorInfo.authorPosition}</div>
        </div>
        <div
          onClick={() => setParentComment(comment)}
          className="answer_comment"
        >
          Ответить
        </div>
        
        <div className="comment_date_delete_block">
          <div className="comment_date">{commentDate}</div>
          {comment.author_id === currentId && (
            <div onClick={deleteHandler} className="comment_delete">
              Удалить
            </div>
          )}
        </div>
      </div>
      <div className="comment_body">
        {parentComment && <ParentComment parentComment={parentComment} />}
        <div className="comment_text">{`${comment.text}`}</div>
        <div className={"attachments"}>
          {comment.files &&
            comment.files.map((file) => (
              <FileElem
                url={file.url}
                key={file.id}
                id={+file.id}
                name={file.name}
                canBeDeleted={false}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: State) => ({
  users: state.commonInfo.users,
  roles: state.commonInfo.roles,
  positions: state.commonInfo.positions,
  currentId: state.commonInfo.current_user,
  commentList: state.taskInfoPlate.commentList,
});

const mapDispatchToProps = {
  setParentComment,
};

export default connect(mapStateToProps, mapDispatchToProps)(CommentElem);
