import { useEffect, useState } from "react";
import { Roles } from "../../common/constants";
import { useSelector } from "react-redux";
import { State } from "../../rootReducer";
import { User } from "../../common/types";
import { CheckContextsObjects } from "../../pages/ObjectsPage/constants";

export default function(context: string, conditions?:any) {
  const [isUserCanEditTask, setIsUserCanEditTask] = useState<boolean>(false);
  const [isUserCanDeleteObject, setIsUserCanDeleteObject] = useState<boolean>(false);
  const [isUserCanDeleteTask, setIsUserCanDeleteTask] = useState<boolean>(false);
  const [isUserCanAddTask, setIsUserCanAddTask] = useState<boolean>(false);
  const [isUserCanAddObject, setIsUserCanAddObject] = useState<boolean>(false);
  const [isUserCanEditObject, setIsUserCanEditObject] = useState<boolean>(false);
  
  const currentUser = useSelector<State, User|null>((state) => state.commonInfo.currentUserInfo);

  const responsible = useSelector<State, any>((state) => state.objectsPage.editingObjectsForm.data.responsible?.userSelected);
  const checkFormEditTaskRules = useSelector<State, any>((state) => state.objectsFormEditTask.check);
  const authorIdTask = useSelector<State, any>((state) => state.objectsFormEditTask.data.author.id);
  
  useEffect(() => {
    setIsUserCanEditTask(false);
    setIsUserCanDeleteObject(false);
    setIsUserCanDeleteTask(false);
    setIsUserCanAddTask(false);
    setIsUserCanAddObject(false);
    setIsUserCanEditObject(false);
    
    if(currentUser) check();
  }, [currentUser, responsible, checkFormEditTaskRules, authorIdTask]);
  
  const check = () => {
    if(context === CheckContextsObjects.IS_USER_CAN_EDIT_OBJECT) {
      const allowedRoles = [Roles.ADMIN, Roles.DIRECTOR];
      
      currentUser?.roles.forEach(role => {
        if(
          (conditions?.authorId === currentUser.id) ||
          (responsible?.value === currentUser.id) ||
           allowedRoles.includes(role)
        ) {
          setIsUserCanEditObject(true);
        }
      });
    }
    
    if(context === CheckContextsObjects.IS_USER_CAN_EDIT_TASK) {
      const allowedRoles = [Roles.ADMIN, Roles.DIRECTOR];

      currentUser?.roles.forEach(role => {
        if(
          (conditions?.authorId === currentUser.id) || // Автор задачи, назначается в задаче
          (checkFormEditTaskRules.responsibilityIdObject === currentUser.id) || // Руководитель объекта, назначается в объекте
          (checkFormEditTaskRules.authorIdObject === currentUser.id) || // Автор объекта, назначается в объекте
           allowedRoles.includes(role)
        ) {
          setIsUserCanEditTask(true);
        }
      });
    }

    if(context === CheckContextsObjects.IS_USER_CAN_ADD_OBJECT) {
      const allowedRoles = [Roles.ADMIN, Roles.DIRECTOR];

      currentUser?.roles.forEach(role => {
        if(
           allowedRoles.includes(role)
        ) {
          setIsUserCanAddObject(true);
        }
      });
    }

    if(context === CheckContextsObjects.IS_USER_CAN_ADD_TASK) {
      const allowedRoles = [Roles.ADMIN, Roles.DIRECTOR];
      
      currentUser?.roles.forEach(role => {
        if(
           allowedRoles.includes(role)
        ) {
          setIsUserCanAddTask(true);
        }
      });
    }

    if(context === CheckContextsObjects.IS_USER_CAN_DELETE_TASK) {
      const allowedRoles = [Roles.ADMIN, Roles.DIRECTOR];
      
      currentUser?.roles.forEach(role => {
        if(
           allowedRoles.includes(role)
        ) {
          setIsUserCanDeleteTask(true);
        }
      });
    }

    if(context === CheckContextsObjects.IS_USER_CAN_DELETE_OBJECT) {
      const allowedRoles = [Roles.ADMIN, Roles.DIRECTOR];
      
      currentUser?.roles.forEach(role => {
        if(
           allowedRoles.includes(role)
        ) {
          setIsUserCanDeleteObject(true);
        }
      });
    }
  }
  
  return { 
    isUserCanEditTask,
    isUserCanDeleteObject,
    isUserCanDeleteTask,
    isUserCanAddTask,
    isUserCanAddObject,
    isUserCanEditObject
  };
}
