export enum sortConditions {
  ID = "id",
  NAME = "name",
  TREATY = "treaty",
  ALL_TASKS = "all_tasks",
  DURATION = "duration",
  AUTHOR = "author",
  CREATED_AT = "created_at",
  STATUS = "status",
}

export enum sortOrders {
  ASC,
  DESC,
}

export enum deleteBusinessProcessStatuses {
  DELETE_BUSINESS_PROCESS = 0,
  DELETE_BUSINESS_PROCESS_ALLOWED = 'allowed',
  DELETE_BUSINESS_PROCESS_ERROR = 'error'
}

export enum activeStateBusinessProcessStatuses {
  ACTIVE_BUSINESS_PROCESS = 0,
  ACTIVE_BUSINESS_PROCESS_ALLOWED = 'allowed',
  ACTIVE_BUSINESS_PROCESS_ERROR = 'error'
}

export enum messagesBusinessProcess {
  DELETE_BUSINESS_PROCESS_ERROR = 'Произошла ошибка при удалении бизнес-процесса...',
  DELETE_BUSINESS_PROCESS_SUCCESS = 'Бизнес-процесс удален',
  CREATE_BUSINESS_PROCESS_SUCCESS = 'Бизнес-процесс успешно создан',
  UPDATE_BUSINESS_PROCESS_SUCCESS = 'Бизнес-процесс успешно изменен'
}

export enum errorValidationMessages {
  REQUIRED = 'Поле обязательно для заполнения'
}

export enum taskConnectStatuses {
  STATUS_ERROR = 0,
  STATUS_CANCEL = -1,
  STATUS_RESTRICTED = 1000,

  // Номера статусов соответствуют номерам общих случаев из ТЗ
  STATUS_1 = 1, // Перемещение позиции на задачу первого уровня, у которой Сверху «|» Снизу «|»
  STATUS_2 = 2, // Перемещение на задачу первого уровня: Сверху «|» Снизу «||»
  STATUS_3 = 3, // Перемещение на задачу первого уровня: Сверху «||» Снизу «|»
  STATUS_4 = 4, // Перемещение на задачу первого уровня: Сверху «||» Снизу «||»
  
  STATUS_5 = 5, // Перемещение на задачу второго уровня, у которой Сверху «|» Снизу «|»
  STATUS_6 = 6, // Перемещение на задачу второго уровня: Сверху «|» Снизу «||»
  STATUS_7 = 7, // Перемещение на задачу второго уровня: Сверху «||» Снизу «|»
  STATUS_8 = 8, // Перемещение на задачу второго уровня: Сверху «||» Снизу «||»
  
  STATUS_9 = 9, // Перемещение на задачу первого уровня, у которой Сверху «-» Снизу «|»
  STATUS_10 = 10, // Перемещение на задачу первого уровня: Сверху «-» Снизу «||»
  STATUS_11 = 11, // Перемещение на задачу первого уровня: Сверху «|» Снизу «-»
  STATUS_12 = 12, // Перемещение на задачу первого уровня: Сверху «||» Снизу «-»
  
  STATUS_13 = 13, // Перемещение на задачу второго уровня, у которой Сверху «|» Снизу «-»
  STATUS_14 = 14 // Перемещение на задачу второго уровня, у которой: Сверху «||» Снизу «-»
}

// Реакция системы
export enum taskConnectActions {
  ACTION_ERROR = 0,
  ACTION_CANCEL = -1,
  ACTION_RESTRICTED = 1000,
  
  ACTION_1 = 1, // Сделать параллельной с задачей «В», status = 1
  ACTION_2 = 2, // Сделать подзадачей для задачи «В», status = 1
  ACTION_3 = 3, // Добавить в группу параллельных задач, status = 2
  ACTION_4 = 4, // Сделать подзадачей для задачи «В», status = 2
  ACTION_5 = 5, // Добавить в группу параллельных задач, status = 3
  ACTION_6 = 6, // Сделать подзадачей для задачи «В», status = 3
  ACTION_7 = 7, // Добавить в группу параллельных задач, status = 4
  ACTION_8 = 8, // Сделать подзадачей для задачи «В», status = 4
  ACTION_9 = 9, // Сделать параллельной с задачей «В», status = 5
  ACTION_10 = 10, // Добавить в группу параллельных задач, status = 6
  ACTION_11 = 11, // Добавить в группу параллельных задач, status = 7
  ACTION_12 = 12, // Добавить в группу параллельных задач, status = 8
  ACTION_13 = 13, // Сделать параллельной с задачей «В», status = 9
  ACTION_14 = 14, // Сделать подзадачей для задачи «В», status = 9
  ACTION_15 = 15, // Добавить в группу параллельных задач, status = 10
  ACTION_16 = 16, // Сделать подзадачей для задачи «В», status = 10
  ACTION_17 = 17, // Сделать параллельной с задачей «В», status = 11
  ACTION_18 = 18, // Сделать подзадачей для задачи «В», status = 11
  ACTION_19 = 19, // Добавить в группу параллельных задач, status = 12
  ACTION_20 = 20, // Сделать подзадачей для задачи «В», status = 12
  ACTION_21 = 21, // Сделать параллельной с задачей «В», status = 13
  ACTION_22 = 22, // Добавить в группу параллельных задач, status = 14
}

export enum taskConnectStatusesBetween {
  STATUS_ERROR = 0,
  STATUS_CANCEL = -1,
  // Номера статусов соответствуют номерам общих случаев из ТЗ
  STATUS_1 = 1, // Перемещение позиции внутрь группы задач. Позиция встает в новое положение в такой же связи, которая актуальная для этой группы задач. Без подтверждения действия
  STATUS_2 = 2, // Перемещение позиции на границу между группами задач. Позиция встает в новое положение, получая последовательные связи с задачами выше и ниже
  STATUS_3 = 3, // Перемещение позиции внутрь группы подзадач. Позиция встает в новое положение в такой же связи, которая актуальная для этой группы подзадач. Без подтверждения действия
  STATUS_4 = 4, // Перемещение позиции на границу между группами подзадач. Позиция встает в новое положение, получая последовательные связи с задачами выше и ниже
  STATUS_5 = 5,
  /*Перемещение позиции на границу между группой подзадач и группой задач. Такая ситуация возможна только в ситуации, когда сверху находится группа подзадач, а снизу группа задач.

  Производя такое действие, пользователь должен видеть управлять (движением мыши) – будет позиция помещена на первый или на второй уровень вложенности.

  При перемещении на первый уровень – реализуется случай (1).
  При перемещении на второй уровень – реализуется случай (3).*/
}

export enum taskBPTypes {
  SERIAL = 1,
  PARALLEL = 2
}

export enum taskBPPositions {
  HIDDEN = 0,
  PARENT = 1,
  CHILD = 2
}

export enum taskDeleteConnectionStatuses {
  STATUS_ERROR_DELETE = 1001,
  STATUS_ERROR_CONNECTION = 1002,
  STATUS_CANCEL = -1,
  STATUS_RESTRICTED = 1000,

  /* Удаление задач */
  
  STATUS_1 = 1, // У задачи нет детей
  STATUS_2 = 2, // У задачи есть дети

  /* Разрыв связей */
  
  STATUS_3 = 3, // Разрываем параллельную связь у двух родителей без детей или у двух детей
  STATUS_4 = 4, // Разрываем связь родительской задачи со стеком ее подзадач, когда родительская задача имеет последовательную связь снизу
  STATUS_5 = 5, // Разрыв связи родительской задачи со стеком ее подзадач, когда родительская задача имеет параллельную связь снизу
}

export enum taskDeleteConnectionActions {
  ACTION_ERROR_DELETE = 1001,
  ACTION_ERROR_CONNECTION = 1002,
  ACTION_CANCEL = -1,
  ACTION_RESTRICTED = 1000,

  /* Удаление задач */
  
  ACTION_1 = 1, // У задачи нет детей, status = 1
  ACTION_2 = 2, // У задачи есть дети, status = 2

  /* Разрыв связей */

  ACTION_3 = 3, // status = 3
  ACTION_4 = 4, // status = 4
  ACTION_5 = 5, // status = 5
  ACTION_6 = 6, // status = 5
}
