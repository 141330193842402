import React, { FC, useEffect, useState } from "react";
import { connect } from "react-redux";
import { State } from "../../../../../rootReducer";
import { IEditingObjectsFormData } from "../../../interfaces";
import { errorValidationMessages } from "../../../constants";

import './InputObjects.scss';

const InputObjects: FC<{
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
  value?: string,
  checkTrigger?: string
  name: keyof IEditingObjectsFormData,
  placeholder?: string,
  inputType?: string
}> = ({
  onChange,
  value,
  checkTrigger,
  name,
  placeholder = 'Введите значение...',
  inputType = 'text'
}) => {
  const errorMessages = {
    max100: errorValidationMessages.MAX100,
    max200: errorValidationMessages.MAX200,
    required: errorValidationMessages.REQUIRED
  };
  
  const rules = {
    name: [{required: true}, {max: 200}],
    declarant: [{required: true}, {max: 200}],
    numberSap: [{required: true}, {max: 200}],
    contractNumber: [{max: 100}],
    cost: [{max: 200}],
  };
  
  const [skipCheck, setSkipCheck] = useState<boolean>(true);
  const [errorState, setErrorState] = useState<string[]>([]);
  
  // В процессе улучшения
  const checkHelper = () => {
    if(skipCheck) {
      setSkipCheck(false);
      return;
    }

    setErrorState([]);

    if(name) {
      if(rules[name]) {
        rules[name].forEach(rule => {
          if(rule['required'] && !value?.length) {
            setErrorState((state) => ([ ...state, errorMessages[ 'required' ]]));
          }
          
          if(rule['max']) {
            if(value && (value?.length > rule.max)) {
              setErrorState((state) => ([...state, errorMessages[ 'max' + rule.max ]]));
            }
          }
        });
      }
    }
  };
  
  const onChangeLocal = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e);
  }
  
  useEffect(() => {
    checkHelper();
  }, [checkTrigger, value]);
  
  return ( 
    <div className={'input-objects' +
                    (errorState.length ? ' input-objects--error' : '')
    }>
      
      {name === 'cost' ? (
        <input type="number"
               className="input-objects__input"
               placeholder={placeholder}
               value={value}
               onChange={onChangeLocal}
               step="0.01"
               min={0}
        />
      ): (
        <input type={inputType}
               className="input-objects__input"
               placeholder={placeholder}
               value={value}
               onChange={onChangeLocal}
        />
      )}
      
      {errorState.length > 0 && (
        <div className="input-objects__error-messages">
          {errorState.map((error, i) => (
            <div className="input-objects__error-message" key={i}>
              {error}
            </div>
          ))}
        </div>
      )}
    </div>
  )  
}
const mapStateToProps = (state: State) => {
  return {
    checkTrigger: state.objectsPage.editingObjectsForm.checkTrigger
  }
}

const mapDispatchToProps = {
  
}

export default connect(mapStateToProps, mapDispatchToProps)(InputObjects);
