import React, { useEffect, useState } from "react";
import ruLocale from "date-fns/locale/ru";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  DateTimePicker,
  KeyboardDateTimePicker,
} from "@material-ui/pickers";
import DateMaster from "../../../utils/DateMaster";
import { CreateNotif } from "../../../utils/createNotification";
// import { RootRef } from '@material-ui/core';
import styled from "styled-components";
import palette from "../palette";
import { connect } from "react-redux";
import { State } from "../../../rootReducer";
import { updateTask } from "../../actions";
import {
  recalculateTaskLoad, 
  setTaskBegin,
  setTaskEnd,
} from "../../../pages/TaskInfoPlate/actions";
import moment from "moment";
import { datePicker } from "../../constants";

type OpenTo = "date" | "year" | "month" | "hours" | "minutes" | undefined;

type Props = {
  date: string;
  disabled?: boolean;
  minDate?: Date;
  maxDate?: Date;
  acceptHandler: (isoDate: string) => void;
  // stop: number,
  hideTimeBlock?: boolean;
  setTimeValid: (mode: boolean) => void;
};
const WrapperDataTime = styled.div`
  height: 40px;
  display: flex;
  line-height: 40px;
  text-align: center;
  font-size: 15px;
  color: #212121;
  ${({ disabled }) =>
    disabled &&
    `
    background: #f5f5f5;
    pointer-events: none;
    color: ${palette.textBlack};
  `};
`;
const DataBlock = styled.div`
  width: 152px;
  border: 1px solid ${palette.ultraBlueLighter};
  border-radius: 6px;
`;
const TimeBlock = styled.div`
  width: 72px;
  margin-left: 8px;
  border: 1px solid ${palette.ultraBlueLighter};
  border-radius: 6px;
`;
const DateTimeComponent = ({
  date,
  setOpen,
  setOpenTo,
  disabled,
  hideTimeBlock,
}) => {
  const dateMaster = new DateMaster(date);
  return (
    <WrapperDataTime
      onClick={() => !disabled && setOpen(true)}
      disabled={disabled}
    >
      <DataBlock onClick={() => setOpenTo("date")} className="date">
        {dateMaster.getDate("name")}
      </DataBlock>
      {!hideTimeBlock && (
        <TimeBlock onClick={() => setOpenTo("hours")} className="time">
          {dateMaster.time}
        </TimeBlock>
      )}
    </WrapperDataTime>
  );
};

const TaskDateTime: React.FC<Props> = ({
  date,
  acceptHandler,
  minDate,
  maxDate,
  disabled,
  hideTimeBlock = false,
  setTimeValid,
}) => {
  const [isOpen, setOpen] = useState<boolean>(false);
  const [openTo, setOpenTo] = useState<OpenTo>("date");

  const [resultDate, setResultDate] = useState("");
  const [dateInput, setDateInput] = useState(new Date());
  
  const [openDate, setOpenDate] = useState('');
  const [isValid, setIsValid] = useState(true);

  const [selectedDate, setSelectedDate] = React.useState(
    new Date("2014-08-18T21:11:54")
  );

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setSelectedDate(date);
    setOpenTo(date);
  };
  
  const minMaxDate = date => {
    // date > DATE_MIN_DEFAULT
    if(!moment(date, datePicker.DATE_FORMAT)
      .isAfter(moment(datePicker.DATE_MIN_DEFAULT, datePicker.DATE_FORMAT), 'date')
    ) {
      return datePicker.DATE_MIN_DEFAULT;
    }
    
    if(!moment(date, datePicker.DATE_FORMAT)
      .isBefore(moment(datePicker.DATE_MAX_DEFAULT, datePicker.DATE_FORMAT), 'date')
    ) {
      return datePicker.DATE_MAX_DEFAULT;
    }
    
    return date
  };

  useEffect(() => {
    if(typeof date !== 'object') {
      const newDate = minMaxDate(date);

      const day = newDate.substr(0, 2);
      const month = newDate.substr(3, 2);
      const tail = newDate.substr(6, newDate.length);

      setOpenDate(month + "/" + day + "/" + tail);
    } else {
      setOpenDate(date);
    }
  }, [date, minDate, maxDate]);

  useEffect(() => {    
  },[maxDate, minDate])

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
      <KeyboardDateTimePicker
        invalidDateMessage="Введите дату и время пожалуйста!"
        invalidLabel="Введите дату и время пожалуйста!"
        cancelLabel="Отменить"
        showTodayButton={true}
        todayLabel="Сейчас"
        ampm={false}
        minDate={new Date(datePicker.DATE_MIN_DEFAULT)}
        minDateMessage={"Введите дату и время пожалуйста!"}
        maxDate={new Date(datePicker.DATE_MAX_DEFAULT)}
        maxDateMessage={"Введите дату и время пожалуйста!"}
        allowKeyboardControl
        disabled={disabled}
        format="dd-MM-yyyy HH:mm"
        value={openDate}
        onChange={(date) => {
          if(!moment(date).isValid()) {
            setIsValid(false);
            return;
          }
          else {
            setIsValid(true);
          }
          
          if (date) {
            if (date.getFullYear() >= +datePicker.YEAR_MAX_DEFAULT || date.getFullYear() < +datePicker.YEAR_MIN_DEFAULT) {
              CreateNotif(
                "Ошибка в дате. Введите, пожалуйста, правильную дату!",
                "warning"
              );
            }
            setDateInput(date);
            // в разных браузерах получение нужного формата времени ToLocaleString дает разный формат времени, поэтому делаю ее вручную
            let result = "";
            result +=
              (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) +
              "-";
            result +=
              (date.getMonth() + 1 < 10
                ? "0" + (date.getMonth() + 1)
                : date.getMonth() + 1) + "-";
            
            if (date.getFullYear() >= +datePicker.YEAR_MAX_DEFAULT || date.getFullYear() < +datePicker.YEAR_MIN_DEFAULT) {
              setIsValid(false);
              
              setTimeValid(false);
              result += new Date().getFullYear() + " ";
            } else {
              setIsValid(true);
              
              result += date.getFullYear() + " ";
              setTimeValid(true);
            }
            result +=
              (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) +
              ":";
            result +=
              (date.getMinutes() < 10
                ? "0" + date.getMinutes()
                : date.getMinutes()) + ":";
            result +=
              date.getSeconds() < 10
                ? "0" + date.getSeconds()
                : date.getSeconds();

            if (result) setResultDate(result);
          }
        }}
        onBlur={() => {
          if(!isValid) {
            const newDate = minMaxDate(date);
              
            setOpenDate(
              moment(newDate, datePicker.DATE_FORMAT)
              .add(Math.floor(Math.random() * 19) - 1, 'seconds')
              .format(datePicker.DATE_OPEN_FORMAT)
            );
            
            setResultDate('');
            return;
          }
          
          if (resultDate) acceptHandler(resultDate);

          setResultDate('');
          return; // Дальше логика работает с багами, может быть потом поправить

          // if (maxDate && maxDate.valueOf() < dateInput.valueOf()) {
          if (maxDate && moment(maxDate, datePicker.DATE_FORMAT).unix() < moment(dateInput).unix()) {
            CreateNotif(
              "Дата начала задачи не может быть позже даты окончания"
            );
            return;
          }
          if (resultDate && resultDate.slice(0, 3) === "NaN") {
            CreateNotif(
              "Ошибка в дате. Введите, пожалуйста, правильную дату!",
              "warning"
            );
          } else {
            if (resultDate) acceptHandler(resultDate);
            setResultDate('');
          }
        }}
        onAccept={(date) => {
          if(!isValid) {
            const newDate = minMaxDate(resultDate);

            setOpenDate(
              moment(newDate, datePicker.DATE_FORMAT)
              .add(1, 'seconds')
              .format(datePicker.DATE_OPEN_FORMAT)
            );

            return;
          }
          
          if (date) {
            if (minDate && minDate.valueOf() > dateInput.valueOf()) {
              CreateNotif(
                "Дата окончания задачи не может быть раньше даты начала"
              );
              return;
            }

            if (maxDate && moment(maxDate, datePicker.DATE_FORMAT).unix() < moment(dateInput).unix()) {
              CreateNotif(
                "Дата начала задачи не может быть позже даты окончания"
              );

              return;
            }
            if (date.getFullYear() > +datePicker.YEAR_MAX_DEFAULT || date.getFullYear() < +datePicker.YEAR_MIN_DEFAULT) {
              CreateNotif(
                "Ошибка в дате. Введите, пожалуйста, правильную дату!",
                "warning"
              );
            }
            // в разных браузерах получение нужного формата времени ToLocaleString дает разный формат времени, поэтому делаю ее вручную
            let result = "";
            result +=
              (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) +
              "-";
            result +=
              (date.getMonth() + 1 < 10
                ? "0" + (date.getMonth() + 1)
                : date.getMonth() + 1) + "-";
            if (date.getFullYear() > +datePicker.YEAR_MAX_DEFAULT || date.getFullYear() < +datePicker.YEAR_MIN_DEFAULT) {
              setTimeValid(false);
              result += new Date().getFullYear() + " ";
            } else {
              setTimeValid(true);

              result += date.getFullYear() + " ";
            }
            result +=
              (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) +
              ":";
            result +=
              (date.getMinutes() < 10
                ? "0" + date.getMinutes()
                : date.getMinutes()) + ":";
            result +=
              date.getSeconds() < 10
                ? "0" + date.getSeconds()
                : date.getSeconds();

            if (result) acceptHandler(result);
          }
        }}
        KeyboardButtonProps={{
          "aria-label": "change date",
        }}
      />
    </MuiPickersUtilsProvider>
  );
};

const mapStateToProps = (state: State) => {
  return {
    minDate: state.taskInfoPlate.begin,
    maxDate: state.taskInfoPlate.end,
  };
};

const mapDispatchToProps = {
  updateTask,
  setTaskEnd,
  recalculateTaskLoad,
};

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(TaskDateTime);
