import React, { useState } from "react";
import MultiSelectVanilla from "../../../../../common/components/ui/MultiSelectVanilla/MultiSelectVanilla";
import { State } from "../../../../../rootReducer";
import { connect } from "react-redux";
import { TypicalTreatiesActivity } from "../../../interfaces";
import { getTypicalTreaties, setCurrentPage, setTypicalTreatiesFilterActivity } from "../../../actions";
import { TypeTypicalTreatiesActivity } from "../../../action_types";

type Props = {
  activeFilterId: TypeTypicalTreatiesActivity;
  setTypicalTreatiesFilterActivity: (id: TypeTypicalTreatiesActivity) => void;
  disabled?: boolean;
  isSingleSelect?: boolean;
  getTypicalTreaties: (params?: object) => void;
  setCurrentPage: (currentPage: number) => void;
};

const ActivitySelectTypicalTreaties: React.FC<Props> = ({
  setTypicalTreatiesFilterActivity,
  getTypicalTreaties,
  setCurrentPage, 
  isSingleSelect,
  disabled 
}) => {

  const [placeholder, setPlaceholder] = useState<any>(TypicalTreatiesActivity.ACTIVE_TEXT)

  const defaultValues = [
    {
      text: TypicalTreatiesActivity.ALL_TEXT,
      value: TypicalTreatiesActivity.ALL_STATUS,
    },
    {
      text: TypicalTreatiesActivity.ACTIVE_TEXT,
      value: TypicalTreatiesActivity.ACTIVE_STATUS
    },
    {
      text: TypicalTreatiesActivity.NOT_ACTIVE_TEXT,
      value: TypicalTreatiesActivity.NOT_ACTIVE_STATUS,
    }
  ]

  const addHandler = (value) => {
    setCurrentPage(1);
    setPlaceholder(defaultValues[value]?.text);
    setTypicalTreatiesFilterActivity(value)
    getTypicalTreaties();
  };

  return (
    <MultiSelectVanilla
      placeholder={placeholder}
      withImage={false}
      showSearchField={false} 
      isSingleSelect={isSingleSelect}
      disabled={disabled}
      itemsList={defaultValues}
      selectedItems={[]}
      addHandler={(value) => addHandler(value)} 
      removeHandler={() => {}}
    />
  );
};

const mapStateToProps = (state: State) => {
  return {
    activeFilterId: state.typicalTreatyPage.filters.activity
  }
}

const mapDispatchToProps = {
  setTypicalTreatiesFilterActivity,
  setCurrentPage,
  getTypicalTreaties,
}

export default connect(mapStateToProps, mapDispatchToProps)(ActivitySelectTypicalTreaties);
