import React from "react";

import "./PersonalToggle.scss";
import { connect } from "react-redux";
import { State } from "../../../../../rootReducer";
import { setNormalFormData } from "../../../actions";
import { TypicalTasksTime } from "../../../../../common/constants";
import { TypeTimeTask } from "../../../action_types";
import { INormalFormData } from "../../../interfaces";

const TimeTaskToggle: React.FC<{
  timeType?: TypeTimeTask;
  setNormalFormData: (normalFormData: INormalFormData) => void
}> = ({
  timeType, 
  setNormalFormData
}) => {
  const defaultValues = [
    { text: "Дни", value: TypicalTasksTime.DAY_FORMAT },
    { text: "Часы", value: TypicalTasksTime.HOUR_FORMAT},
  ];

  const setValueHandler = (value) => {
    setNormalFormData({timeType: value});
  };

  return (
    <div className="personal-toggle">
      {defaultValues.map((item, index) => (
        <div
          key={index}
          className={
            "personal-toggle__item" +
            (timeType === item.value ? " personal-toggle__item--active" : "")
          }
          onClick={() => setValueHandler(item.value)}
        >
          {item.text}
        </div>
      ))}
    </div>
  );
};

const mapStateToProps = (state: State) => {
  return {
    timeType: state.typicalTasksPage.normalForm.timeType
  };
};

const mapDispatchToProps = {
  setNormalFormData
};

export default connect(mapStateToProps, mapDispatchToProps)(TimeTaskToggle);
