import { State } from "../../../../rootReducer";
import { createSelector } from "reselect";
import { getPositions, getUsers } from "../../../../common/selectors";
import { IExtraArrayValuesSelected, IExtraField, IObjectsFormAddTaskDataSelect } from "../FormAddTask/interfaces";
import { nanoid } from "nanoid";
import { TabIdsExtraForm } from "../../../TypicalTasks/constants";
import { IEditingObjectsFile, IObjectsServerData } from "../../interfaces";
import { IChecklistTask } from "./interfaces";

export const getUsersSpectatorsIds = (state: State): number[] => state.objectsFormEditTask.data.spectators.usersIds;
export const getChecklistData = (state: State): IChecklistTask[] => state.objectsFormEditTask.additionalFields.checklist;
export const getExtraData = (state: State): IExtraField[] => state.objectsFormEditTask.additionalFields
  .extra;
const getObjects = (state: State): IObjectsServerData[] => state.objectsFormEditTask.objects;
const getObjectSelectedId = (state: State): string|number => state.objectsFormEditTask.data.project_id;
const getCurrentUser = (state: State) => state.commonInfo.currentUserInfo;

export const getUsersSpectatorsData = createSelector(
  [getUsers, getUsersSpectatorsIds, getPositions, getCurrentUser],
  (users, usersFilterIds, positions, currentUser) =>
  {
    let selected: any = [];
    let list = users
      .filter(user => {
        // Тут возможно нужно будет доработать условия исключения юзеров из выборки
        if(currentUser?.isAdmin && (user.id === currentUser.id)) return false;

        return true;
      })
      .map((user) => {
        const item = {
          urlImage: user.image?.url || false,
          text: `${user.surname} ${user.name}`,
          position: positions?.find(
            (position) => position?.id === user?.positions[0]
          )?.name,
          value: user.id,
        };

        if(usersFilterIds.includes(user.id)) {
          selected.push(item)
        }

        return item;
      });

    return {list, selected};
  }
);

export const getChecklist = createSelector(
  [getChecklistData],
  (checklistData) =>
  {
    let result: any = [];

    if(checklistData.length) {
      result = checklistData.map(checklist => ({
        id: nanoid(6),
        value: checklist,
        checked: false
      }));
    }

    return result;
  }
);

export const getCheckboxes = createSelector(
  [getExtraData],
  (extraFields) =>
  {
    let result: IExtraArrayValuesSelected[] = [];

    extraFields.forEach(field => {
      if(field.id === TabIdsExtraForm.CHECKBOX_TAB) {
        if(field?.data && field.data.list?.length) {
          result = field.data.list.map(value => {
            return { 
              id: nanoid(6),
              value: value,
              checked: field.serverData?.includes(value) ?? false,
              serverId: field.serverId ?? ''
            }})
        }
      }
    });

    return result;
  }
);

export const getSelect = createSelector(
  [getExtraData],
  (extraFields) =>
  {
    let result: IObjectsFormAddTaskDataSelect[] = [{value: '', label: 'Введите значение...'}];

    extraFields.forEach(field => {
      if(field.id === TabIdsExtraForm.SELECT_TAB) {
        if(field?.data && field.data.list?.length) {
          result = field.data.list.map(value => ({
            value: nanoid(6),
            label: value
          }))
        }
      }
    })

    return result;
  }
);

export const getRadioServerSelected = createSelector(
  [getExtraData],
  (extraFields) =>
  {
    let result: { value: any, serverId: string } = { value: '', serverId: ''};

    extraFields.forEach(field => {
      if(field.id === TabIdsExtraForm.RADIO_TAB) {
        result = { value: field.serverData, serverId: field.serverId ?? ''};
      }
    })

    return result;
  }
);

export const getFileServerSelected = createSelector(
  [getExtraData],
  (extraFields) =>
  {
    let result: IEditingObjectsFile = { id: "", name: "" };

    extraFields.forEach(field => {
      if(field.id === TabIdsExtraForm.DOCUMENT_TAB) {
        result = { name: field.serverData?.name, id: field.serverData?.id, link: field.serverData?.url};
      }
    })

    return result;
  }
);

export const getSelectServerSelected = createSelector(
  [getExtraData],
  (extraFields) =>
  {
    let result: { value: any, serverId: string } = { value: '', serverId: ''};

    extraFields.forEach(field => {
      if(field.id === TabIdsExtraForm.SELECT_TAB) {
        result = { value: field.serverData, serverId: field.serverId ?? ''};
      }
    })

    return result;
  }
);

export const getInputServerSelected = createSelector(
  [getExtraData],
  (extraFields) =>
  {
    let result: { value: any, serverId: string } = { value: '', serverId: ''};

    extraFields.forEach(field => {
      if(field.id === TabIdsExtraForm.INPUT_TAB) {
        result = { value: field.serverData, serverId: field.serverId ?? ''};
      }
    })

    return result;
  }
);

export const getObjectsSelector = createSelector(
  [getObjects],
  (objects) =>
  {
    let result: IObjectsFormAddTaskDataSelect[] = [{value: '', label: 'Введите значение...'}];

    result = objects.map(object => ({
      value: object.id,
      label: object.name
    }));

    return result;
  }
);

export const getObjectSelectedSelector = createSelector(
  [getObjects, getObjectSelectedId],
  (objects, objectSelectedId) =>
  {
    let result: IObjectsFormAddTaskDataSelect = {value: '', label: 'Введите значение...'};

    if(objects.length) {
      objects.forEach(object => {
       if(object.id === objectSelectedId) {
         result = {
           value: object.id, label: object.name
         }
       }
      });
   }

    return result;
  }
);
