import React, { FC, useEffect, useState } from "react";
import { State } from "../../../../rootReducer";
import { connect } from "react-redux";
import "./TypicalTreatiesComponent.scss";
import TypicalTreatiesComponentItem from "../TypicalTreatiesComponentItem/TypicalTreatiesComponentItem";
import {
  IMainTreatyServerData,
  ITypicalTreatiesServerData,
  TypicalTreatiesActivity,
} from "../../interfaces";
import moment from "moment";
import {getMainTreaties, setIsEditForm, setIsTreatyFormOpen, setMainBool, setNormalForm} from "../../actions"
import { getDuration, isPositiveDuration } from "../../../BusinessPage/helpers/helpersCommon";

const TypicalTreatiesComponent: FC<{
  mainTreaty: IMainTreatyServerData;
  treaties: ITypicalTreatiesServerData[];
  setIsTreatyFormOpen: (mode: boolean) => void;
  setIsEditForm: (mode: boolean) => void;
  setNormalForm: ( data: any) => void;
  getMainTreaties: (params?:object, action?: string,) => void;
  setMainBool: (mode: boolean) => void;
}> = ({ 
  treaties,
  mainTreaty,
  setIsTreatyFormOpen, 
  setIsEditForm,
  setNormalForm,
  getMainTreaties,
  setMainBool
 }) => {
  const formatDate = (date) => moment(date).format("DD.MM.YYYY");
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const [authorName, setAuthorName] = useState<string>(
    mainTreaty?.author
  );

  useEffect(() => {
    setAuthorName(
      mainTreaty?.author 
    );
  }, [mainTreaty?.author]);

  const clickActionsOpenHandler = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const openEditHandler = (id: string | number,) => {
    getMainTreaties({}, "form")
    setIsTreatyFormOpen(true);
    setIsEditForm(true);
    setMainBool(true)
  };

  return (
    <div onClick ={() => openEditHandler(mainTreaty?.id)} className="typical-treaties-component_wrapper">
      <div className="typical-treaties-component">
        <div className="typical-treaties-component_id">
          {mainTreaty.id}
        </div>
        <div className="typical-treaties-component_name" title={mainTreaty.name}>
          {mainTreaty.name ? mainTreaty.name : <span>&mdash;</span>}
        </div>
        <div className="typical-treaties-component_all_stage">
          {mainTreaty?.stage_count ?? 0}
        </div>
        <div className="typical-treaties-component_all_business_process">
          {mainTreaty?.bp_count ?? 0}
        </div>
        <div className="typical-treaties-component_all_tasks">
          {mainTreaty?.task_count ?? 0}
        </div>
        <div className="typical-treaties-component_duration">
          {isPositiveDuration(mainTreaty.duration) ? getDuration(mainTreaty.duration) : 0}
        </div>
        <div className="typical-treaties-component_author">
          {mainTreaty?.author ? authorName : "System"}
        </div>
        <div className="typical-treaties-component_created_at">
          {formatDate(mainTreaty?.created_at)}
        </div>
        <div className="typical-treaties-component_status">
          {TypicalTreatiesActivity.ACTIVE_TEXT}
        </div>
      </div>

      {/* map подпунктов типового договора */}
      {treaties?.map((item) => (
        <TypicalTreatiesComponentItem key={item.id} treaties={item} mainId={mainTreaty.id} />
      ))}
    </div>
  );
};

const mapStateToProps = (state: State) => ({
  currentPage: state.typicalTreatyPage.currentPage,
  treaties: state.typicalTreatyPage.treaties,
});

const mapDispatchToProps = {
  setIsTreatyFormOpen,
  setIsEditForm,
  setNormalForm,
  getMainTreaties,
  setMainBool
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TypicalTreatiesComponent);
