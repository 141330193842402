import React, { useEffect } from "react";
import { connect } from "react-redux";
import { State } from "../../../.. /../../../../../rootReducer";
import {
  getBpListTreatyExtraForm,
  setCurrentPageBpListTreaty,
  setTaskItemsStageTreaty,
} from "../../../../../actions";
import { IMeta } from "../../../../../../../common/types";
import PreloaderLocal from "../../../../../../../common/components/PreloaderLocal/PreloaderLocal";
import { ITypicalTaskServerData } from "../../../../../../TypicalTasks/interfaces";
import {
  scrollToBottom,
  getDuration,
} from "../../../../../../BusinessPage/helpers/helpersCommon";

import "./BpsMainTreaty.scss"


const BpsMainTreaty: React.FC<{
  isLoading: boolean;
  bps: ITypicalTaskServerData[];
  currentPageBpListTreaty: number;
  bpMeta: IMeta | null;
  bpsItems?: any;
  getBpListTreatyExtraForm: (params?: object, action?: string) => void;
  setCurrentPageBpListTreaty: (mmode: number) => void;
  setTaskItemsStageTreaty: (itemAdd?: any, id?: number | string) => void;
  idStage?: number | string;
}> = ({
  isLoading,
  bps,
  currentPageBpListTreaty,
  bpMeta,
  bpsItems,
  getBpListTreatyExtraForm,
  setCurrentPageBpListTreaty,
  setTaskItemsStageTreaty,
  idStage,
}) => {
  useEffect(() => {
    setCurrentPageBpListTreaty(1);
    getBpListTreatyExtraForm();
  }, []);

  const scrollHandler = (e: React.UIEvent<HTMLDivElement>) => {
    if (!bpMeta) return;

    const { scrollHeight, scrollTop, clientHeight } = e.currentTarget;

    if (
      scrollHeight <= scrollTop + clientHeight + 10 &&
      currentPageBpListTreaty < bpMeta.last_page
    ) {
      setCurrentPageBpListTreaty(currentPageBpListTreaty + 1);
      getBpListTreatyExtraForm({}, "add");
    }
  };

  const handleAddTaskToBusiness = (data) => {
    const updatedData = {
      id: data.id,
      name: data.name,
      bp: true,
      typical_tasks: data?.typical_tasks,
      count_task: data?.count_task,
      duration: data?.duration
    };

    setTaskItemsStageTreaty(updatedData);

    scrollToBottom(".normal-form-bp");
  };

  const findItem = (item) => {
   return bpsItems?.map((stage) => {
      if (stage.id === idStage) {
        return stage.item_list?.find((stageItem) => {
          if (stageItem.id == item.id) {
            return true;
          }
        }) ? (
          <span key={stage.id}>Добавлен</span>
        ) : (
          <button
            key={stage.id}
            onClick={() => handleAddTaskToBusiness(item)}
            className="extra-form-typical-tasks__content__typical-task__add"
          >
            Добавить
          </button>
        );
      }
    });
  };

  return (
    <>
      {bps.length > 0 ? (
        <div className="extra-form-typical-bp__table-header">
          <div className="extra-form-typical-bp__table-header__id">№</div>
          <div className="extra-form-typical-bp__table-header__name">
            Бизнес-процесс
          </div>
          <div className="extra-form-typical-bp__table-header__term">
            Срок
          </div>
        </div>
      ) : (
        "Типовые бизнес-процессы не найдены"
      )}
      <div className="extra-form-typical-bp__content-typical-tasks">
        {isLoading && (
          <div className="extra-form-typical-bp__content-typical-tasks__preloader-wr">
            <div className="extra-form-typical-bp__content-typical-tasks__preloader">
              <PreloaderLocal />
            </div>
          </div>
        )}
        <div
          className="extra-form-typical-bp__content-typical-tasks__inner"
          onScroll={scrollHandler}
        >
          {bps.length > 0
            ? bps?.map((item) => {
                return (
                  <div
                    className="extra-form-typical-bp__content__typical-tasks"
                    key={item.id}
                  >
                    <div className="extra-form-typical-bp__content__typical-task">
                      <div className="extra-form-typical-bp__content__typical-task__id">
                        {item.id}
                      </div>
                      <div className="extra-form-typical-bp__content__typical-task__name">
                        {item.name}
                      </div>

                      <div className="extra-form-typical-bp__content__typical-task__term">
                        {item.duration ? (
                          getDuration(item.duration)
                        ) : (
                          <span>&mdash;</span>
                        )}
                      </div>
                      {findItem(item)}
                    </div>
                  </div>
                );
              })
            : ""}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: State) => {
  return {
    isLoading: state.typicalTreatyPage.extraForm.isLoading,
    bps: state.typicalTreatyPage.extraForm.treatyBp,
    bpsItems: state.typicalTreatyPage.normalForm.stages,
    currentPageBpListTreaty:
      state.typicalTreatyPage.extraForm.currentPageBpListTreaty,
    bpMeta: state.businessPage.businessProcessMeta,
    idStage: state.typicalTreatyPage.normalForm.selectedItemId,
  };
};

const mapDispatchToProps = {
  setCurrentPageBpListTreaty,
  getBpListTreatyExtraForm,
  setTaskItemsStageTreaty,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BpsMainTreaty);
