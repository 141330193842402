import { IMeta } from "../../common/types";
import { ITypicalTaskServerData } from "../TypicalTasks/interfaces";
import {
  BusinessPageActionTypes,
  getBusinessProcessAction,
  setBusinessProcessAction,
  setCurrentPageAction,
  setLoadingBusinessProcessAction,
  setSortConditionAction,
  setFormBPOpenAction,
  setBPFullFormOpenAction,
  setBPFilterActivityAction,
  setBPFilterPersonalAction,
  TypeBPFilterActivity,
  TypeBPFilterPersonal,
  setDeleteModalBpAction,
  deleteBpAction,
  checkDeleteBpAction,
  deleteBpFromStateAction,
  setSearchBPAction,
  setNormalFormBPAction,
  setTaskConnectModalBpAction,
  setActionConnectIdTriggerAction,
  createBPAction,
  setLoadingTypicalTasksBpAction,
  setTypicalTasksBPExtraFormAction,
  getTypicalTasksBPExtraFormAction,
  setCurrentPageTypicalTasksBpAction,
  setSearchTypicalTasksBpAction,
  setBPMetaAction,
  setExtraFormBPDefaultOpenAction,
  setBPForFilterExtraAction,
  setIsEditFormAction,
  getOneBPAction,
  updateBPStoreAction,
  updateBPAction,
  setLoadingBPCardAction,
  setChangeActiveStateBPModalBpAction,
  checkActiveStateBpAction,
  setTaskDeleteConnectionModalBpAction,
  setNormalFormDefaultValuesBPAction, 
  setIsCreatingBpAction,
  setTreatyForFilterExtraTasksAction
} from "./action_types";
import {
  IActiveBusinessProcessModal,
  IBPFilterFormExtra,
  IBusinessProcessServerData,
  IDeleteBusinessProcessModal,
  INormalFormBPData,
  ISortConditions,
  ITaskBP,
  ITaskConnectModal,
  ITaskDeleteConnectionModal
} from "./interfaces";

export const setBPFormOpen = (isFormBPOpen: boolean): setFormBPOpenAction => ({
  type: BusinessPageActionTypes.SET_BP_FORM_OPEN,
  isFormBPOpen
});

export const setIsCreatingBp = (isCreatingBP: boolean): setIsCreatingBpAction=> ({
  type: BusinessPageActionTypes.SET_IS_CREATING_BP,
  isCreatingBP
});

export const getBusinessProcess = (
  params?: {},
  action?: string
): getBusinessProcessAction => ({
  type: BusinessPageActionTypes.GET_BUSINESS_PROCESS,
  params,
  action,
});

export const setBusinessProcess = (
  businessProcess: IBusinessProcessServerData | IBusinessProcessServerData[],
  action?: string
): setBusinessProcessAction => ({
  type: BusinessPageActionTypes.SET_BUSINESS_PROCESS,
  businessProcess,
  action,
});

export const setCurrentPage = (currentPage: number): setCurrentPageAction => ({
  type: BusinessPageActionTypes.SET_CURRENT_PAGE,
  currentPage,
});

export const setSortCondition = (
  sortCondition: ISortConditions
): setSortConditionAction => ({
  type: BusinessPageActionTypes.SET_SORT_CONDITION,
  sortCondition,
});

export const setLoadingBusinessProcess = (
  isLoadingBp: boolean
): setLoadingBusinessProcessAction => ({
  type: BusinessPageActionTypes.SET_LOADING_BUSINESS_PROCESS,
  isLoadingBp,
});

export const setLoadingBPCard = (
  isLoadingBpCard: boolean
): setLoadingBPCardAction => ({
  type: BusinessPageActionTypes.SET_LOADING_BPS,
  isLoadingBpCard,
});

export const setBPFullFormOpen = (isFullFormBPOpen: boolean): setBPFullFormOpenAction => ({
  type: BusinessPageActionTypes.SET_BP_FULL_FORM_OPEN,
  isFullFormBPOpen
});

export const setBPFilterActivity = (activity: TypeBPFilterActivity): setBPFilterActivityAction => ({
  type: BusinessPageActionTypes.SET_BUSINESS_PROCESS_FILTER_ACTIVITY,
  activity
});

export const setBPFilterPersonal = (personal: TypeBPFilterPersonal): setBPFilterPersonalAction => ({
  type: BusinessPageActionTypes.SET_BUSINESS_PROCESS_FILTER_PERSONAL,
  personal
})
export const setDeleteModalBp = (data: IDeleteBusinessProcessModal): setDeleteModalBpAction => ({
  type: BusinessPageActionTypes.SET_DELETE_MODAL_BP,
  data
})

export const deleteBp = (id: string|number): deleteBpAction => ({
  type: BusinessPageActionTypes.DELETE_BUSINESS_PROCESS,
  id
});

export const deleteBpFromState = (id: string|number): deleteBpFromStateAction => ({
  type: BusinessPageActionTypes.DELETE_BUSINESS_PROCESS_FROM_STATE,
  id
});

export const checkDeleteBp = (
  id: string | number
): checkDeleteBpAction => ({
  type: BusinessPageActionTypes.CHECK_DELETE_BUSINESS_PROCESS,
  id,
});

export const setTaskConnectModalBp = (data: ITaskConnectModal): setTaskConnectModalBpAction => ({
  type: BusinessPageActionTypes.SET_TASK_CONNECT_MODAL_BP,
  data
});

export const setActionConnectIdTrigger = (idTrigger: number|null): setActionConnectIdTriggerAction => ({
  type: BusinessPageActionTypes.SET_ACTION_CONNECT_ID_TRIGGER,
  idTrigger
});

export const setTaskDeleteConnectionModalBp = (data: ITaskDeleteConnectionModal): setTaskDeleteConnectionModalBpAction => ({
  type: BusinessPageActionTypes.SET_TASK_DELETE_CONNECTION_MODAL_BP,
  data
});

export const setSearchBP = (searchBP: string|null): setSearchBPAction => ({
  type: BusinessPageActionTypes.SET_SEARCH_BUSINESS_PROCESS,
  searchBP
});

export const setNormalFormBp = (normalFormBP: INormalFormBPData): setNormalFormBPAction => ({
  type: BusinessPageActionTypes.SET_NORMAL_FORM_BP,
  normalFormBP
});

export const setNormalFormDefaultValuesBp = (): setNormalFormDefaultValuesBPAction => ({
  type: BusinessPageActionTypes.SET_NORMAL_FORM_DEFAULT_VALUES_BP
});

export const createBP = (data: object): createBPAction => ({
  type: BusinessPageActionTypes.CREATE_BP,
  data
});

export const setLoadingTypicalTasksBp = (
  isLoading: boolean
): setLoadingTypicalTasksBpAction => ({
  type: BusinessPageActionTypes.SET_LOADING_TYPICAL_TASKS_BP,
  isLoading,
});

export const getTypicalTasksBp = (
  params?: {},
  action?: string
): getTypicalTasksBPExtraFormAction => ({
  type: BusinessPageActionTypes.GET_TYPICAL_TASKS_BP,
  params,
  action,
});

export const setTypicalTasksBp = (
  typicalTasksBp: ITypicalTaskServerData | ITypicalTaskServerData[],
  action?: string
): setTypicalTasksBPExtraFormAction => ({
  type: BusinessPageActionTypes.SET_TYPICAL_TASKS_BP,
  typicalTasksBp,
  action
});

export const setCurrentPageTypicalTasksBp = (currentPageTypicalTasks: number): setCurrentPageTypicalTasksBpAction => ({
  type: BusinessPageActionTypes.SET_CURRENT_PAGE_TYPICAL_TASKS_BP,
  currentPageTypicalTasks,
});

export const setSearchTypicalTasksBp = (searchTypicalTasksBp: string|null): setSearchTypicalTasksBpAction => ({
  type: BusinessPageActionTypes.SET_SEARCH_TYPICAL_TASKS_BP,
  searchTypicalTasksBp
});

export const setBpMeta = (businessProcessMeta: IMeta): setBPMetaAction => ({
  type: BusinessPageActionTypes.SET_BP_META,
  businessProcessMeta
});

export const setExtraFormBPDefaultOpen = (isExtraFormBPDefaultOpen: boolean): setExtraFormBPDefaultOpenAction => ({
  type: BusinessPageActionTypes.SET_EXTRA_FORM_BP_DEFAULT_OPEN,
  isExtraFormBPDefaultOpen
});

export const setBPForFilterExtra = (bpForFilter: IBPFilterFormExtra): setBPForFilterExtraAction => ({
  type: BusinessPageActionTypes.SET_BP_FORM_FILTER,
  bpForFilter
});

export const setTaskItemsBp = (taskItems: ITaskBP | ITaskBP[], updateTrigger: string) => ({
  type: BusinessPageActionTypes.SET_TASK_ITEMS_BP,
  taskItems,
  updateTrigger
})

export const setIsEditForm = (isEditForm: boolean): setIsEditFormAction => ({
  type: BusinessPageActionTypes.SET_IS_EDIT_FORM_BP,
  isEditForm
});

export const getOneBP = (id: number|string): getOneBPAction => ({
  type: BusinessPageActionTypes.GET_ONE_BP,
  id
});

export const updateBPStore = (businessProcess: IBusinessProcessServerData): updateBPStoreAction => ({
  type: BusinessPageActionTypes.UPDATE_BP_STORE,
  businessProcess
});

export const updateBP = (data: object): updateBPAction => ({
  type: BusinessPageActionTypes.UPDATE_BP,
  data
});

export const setChangeActiveStateModalBp = (data: IActiveBusinessProcessModal): setChangeActiveStateBPModalBpAction => ({
  type: BusinessPageActionTypes.SET_ACTIVE_STATE_MODAL_BP,
  data
})

export const checkActiveStateBp = (
  id: string | number,
  value: any
): checkActiveStateBpAction => ({
  type: BusinessPageActionTypes.CHECK_ACTIVE_STATE_BP,
  id,
  value
});

export const setTreatyForFilterExtraTasks = (treatyForFilterTasks: IBPFilterFormExtra): setTreatyForFilterExtraTasksAction => ({
  type: BusinessPageActionTypes.SET_TREATY_FORM_FILTER_TASKS,
  treatyForFilterTasks
});