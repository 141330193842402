import React, { FC } from "react";
import InputTypicalTask from "../../ui/InputTypicalTask/InputTypicalTask";
import SelectTypicalTask from "../../ui/SelectTypicalTask/SelectTypicalTask";
import { DataTypes, FieldSizes } from "../../../../constants";
import { connect } from "react-redux";
import { State } from "../../../../../../rootReducer";
import { setExtraFormInputTabData } from "../../../../actions";
import { IExtraFormInputTabData } from "../../../../action_types";

import './InputTabTypicalTask.scss';

const InputTabTypicalTask: FC<{
  setExtraFormInputTabData: (inputTabData: IExtraFormInputTabData) => void,
  title: string,
  dataType: any,
  fieldSize: any
}> = ({
  setExtraFormInputTabData, 
  title,
  dataType,
  fieldSize
}) => {
  const dataTypesValues = [
    {text: DataTypes.TEXT_ANY as string, value: DataTypes.VALUE_ANY},
    {text: DataTypes.TEXT_NUMBERS as string, value: DataTypes.VALUE_NUMBERS}
  ];
  
  const fieldSizesValues = [
    {text: FieldSizes.TEXT_STANDARD as string, value: FieldSizes.VALUE_STANDARD},
    {text: FieldSizes.TEXT_BIG as string, value: FieldSizes.VALUE_BIG}
  ];

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setExtraFormInputTabData({title: e.target.value});
  }

  const onCheckHandler = (isValid: boolean) => {
    setExtraFormInputTabData({isValid});
  }
  
  const onChangeSelect = (action: 'dataType'|'fieldSize', value: any) => {
    let data: Pick<IExtraFormInputTabData, 'dataType'|'fieldSize'> = {};

    if(action === 'dataType') {
      data = {dataType: value};
    }
    
    if(action === 'fieldSize') {
      data = {fieldSize: value};
    }
    
    setExtraFormInputTabData(data);
  }

  return (
    <div className='input-tab-typical-task'>
      <div className="input-tab-typical-task__items">
        <div className="input-tab-typical-task__item">
          <InputTypicalTask
            onCheck={onCheckHandler}
            onChange={onChangeInput}
            value={title}
          />
        </div>
        
        <div className="input-tab-typical-task__item">
          <SelectTypicalTask
            title='Тип данных'
            itemsList={dataTypesValues}
            onChangeSelect={(value) => onChangeSelect('dataType', value)}
            selectedItem={dataType}
          />
        </div>
        
        <div className="input-tab-typical-task__item">
          <SelectTypicalTask
            title='Размер поля'
            itemsList={fieldSizesValues}
            onChangeSelect={(value) => onChangeSelect('fieldSize', value)}
            selectedItem={fieldSize}
          />
        </div>
      </div>
    </div>
  )
};

const mapStateToProps = (state: State) => {
  return {
    title: state.typicalTasksPage.extraForm.inputTab.title as string,
    dataType: state.typicalTasksPage.extraForm.inputTab.dataType,
    fieldSize: state.typicalTasksPage.extraForm.inputTab.fieldSize
  }
}

const mapDispatchToProps = {
  setExtraFormInputTabData
}

export default connect(mapStateToProps, mapDispatchToProps)(InputTabTypicalTask);
