import React, { FC, useEffect, useState } from 'react';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { connect } from "react-redux";
import { State } from "../../../../../rootReducer";
import {ReactComponent as CloseIcon} from '../../../../../img/TrueIcons/close.svg';
import PreloaderLocal from "../../../../../common/components/PreloaderLocal/PreloaderLocal";
import { deleteTypicalTaskStatuses } from "../../../constants";
import { deleteTypicalTask, setDeleteTypicalTaskModalData } from "../../../actions";
import { IDeleteTypicalTaskModal } from "../../../interfaces";

import './ModalDeleteTypicalTask.scss';

const ModalDeleteTypicalTask: FC<{
  isDeleteModalOpen?: boolean,
  deleteTypicalTaskModal: IDeleteTypicalTaskModal,
  setDeleteTypicalTaskModalData: (data: IDeleteTypicalTaskModal) => void,
  deleteTypicalTask: (id: string|number) => void
}> = ({
  isDeleteModalOpen,
  deleteTypicalTaskModal,
  setDeleteTypicalTaskModalData,
  deleteTypicalTask
}) => {
  const textObj = {
    // [deleteTypicalTaskStatuses.DELETE_TASK]:
    //   {title: 'Удаление типовой задачи', text: '', btnDeleteText: 'Удалить'},
    [deleteTypicalTaskStatuses.DELETE_TASK_ALLOWED]:
      {title: 'Удаление типовой задачи', text: 'Задача будет удалена. Больше её никто не увидит.<br/>Действие необратимо.', btnDeleteText: 'Удалить'},
    [deleteTypicalTaskStatuses.DELETE_TASK_ERROR]:
      {title: 'Удаление типовой задачи', text: 'Произошла ошибка при удалении задачи...'}
  };
  
  const [text, setText] = useState<{
    title: string,
    text: string,
    btnDeleteText?: string
  }>(textObj[deleteTypicalTaskStatuses.DELETE_TASK_ERROR]);

  useEffect(() => {
    if(deleteTypicalTaskModal.statusFromServer?.constraints.length) {
      const {constraints} = deleteTypicalTaskModal.statusFromServer;
      const result = textObj[constraints[0]];

      if(result) setText(result);
      else setText(textObj[deleteTypicalTaskStatuses.DELETE_TASK_ERROR]);
    }
  }, [deleteTypicalTaskModal]);

  const handleClose = () => {
    setDeleteTypicalTaskModalData({isDeleteModalOpen: false});
  };

  const deleteTaskHandler = () => {
    deleteTypicalTask(deleteTypicalTaskModal.taskId ?? '');
  };
  
  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={'modal-delete-typical-task'}
        open={isDeleteModalOpen ?? false}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={isDeleteModalOpen}>
          <div className={'modal-delete-typical-task__content-wr'}>
            <div className="modal-delete-typical-task__content">

              <div className="modal-delete-typical-task__content-item">
                <div className={'modal-delete-typical-task__title-wr'}>
                  <div className="modal-delete-typical-task__title">{text?.title}</div>
                  <div className="modal-delete-typical-task__delete-ico-wr"
                       onClick={handleClose}
                  >
                    <CloseIcon />
                  </div>
                </div>

                <div className="modal-delete-typical-task__text"
                     dangerouslySetInnerHTML={{__html: text?.text}}
                >
                </div>
              </div>
            </div>
            
            <div className="modal-delete-typical-task__btns">
              {text?.btnDeleteText &&
                <button className={"modal-delete-typical-task__btn modal-delete-typical-task__btn--delete " + (deleteTypicalTaskModal.isShowPreloader ? ' modal-delete-typical-task__btn--show-preloader' : '')}>
                <span className="modal-delete-typical-task__btn-title"
                      onClick={deleteTaskHandler}
                >{text?.btnDeleteText}</span>
                  <span className="modal-delete-typical-task__btn-preloader">
                  <PreloaderLocal />
                </span>
                </button>
              }
              
              <button className="modal-delete-typical-task__btn modal-delete-typical-task__btn--cancel"
                      onClick={handleClose}
              >
                <span className="modal-delete-typical-task__btn-title">Отмена</span>
              </button>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
}

const mapStateToProps = (state: State) => {
  return {
    isDeleteModalOpen: state.typicalTasksPage.deleteTypicalTaskModal.isDeleteModalOpen,
    deleteTypicalTaskModal: state.typicalTasksPage.deleteTypicalTaskModal
  };
};

const mapDispatchToProps = {
  setDeleteTypicalTaskModalData,
  deleteTypicalTask
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalDeleteTypicalTask);
