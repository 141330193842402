import React, { FC } from "react";
import { Link } from "react-router-dom";

import './MobileRegulationsMenu.scss';

const MobileRegulationsMenu: FC<{
  
}> = ({
      
}) => {
  return (
    <div className='mobile-regulations'>
      <div className="mobile-regulations__items">
        <Link className="mobile-regulations__item" to='/regulations/typical-tasks'>Типовые задачи</Link>
        <Link className="mobile-regulations__item" to='/regulations/business-processes'>Бизнес-процессы</Link>
        <Link className="mobile-regulations__item" to='/regulations/typical-treaties'>Типовые договоры</Link>
      </div>
    </div>
  )
};

export default MobileRegulationsMenu;
