import React, { FC, useEffect, useState } from "react";
import { connect } from "react-redux";
import { State } from "../../../../rootReducer";
import { IObjectsFormAddTaskData } from "../../../../pages/ObjectsPage/components/FormAddTask/interfaces";
import { checkInputs } from "../../../../utils/helpers/helpers";

import './InputSimple.scss';

const InputSimple: FC<{
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
  value: string,
  name: keyof IObjectsFormAddTaskData,
  placeholder?: string,
  inputType?: string,
  checkTriggerExternal?: string,
  rules?: any
}> = ({
  onChange,
  value,
  name,
  placeholder = 'Введите значение...',
  inputType = 'text',
  checkTriggerExternal,
  rules
}) => {
  const [skipCheck, setSkipCheck] = useState<boolean>(true);
  const [errorState, setErrorState] = useState<string[]>([]);

  const checkHelper = () => {
    if(skipCheck) {
      setSkipCheck(false);
      return;
    }

    setErrorState([]);

    const checkResult = checkInputs(rules, value);

    if(Array.isArray(checkResult)) {
      setErrorState(checkResult);
    }
  };
  
  const onChangeLocal = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e);
  }
  
  useEffect(() => {
    checkHelper();
  }, [value, checkTriggerExternal]);
  
  return ( 
    <div className={'input-simple' +
                    (errorState.length ? ' input-simple--error' : '')
    }>
      <input type={inputType} 
             className="input-simple__input" 
             placeholder={placeholder}
             value={value}
             onChange={onChangeLocal}
      />
      
      {errorState.length > 0 && (
        <div className="input-simple__error-messages">
          {errorState.map((error, i) => (
            <div className="input-simple__error-message" key={i}>
              {error}
            </div>
          ))}
        </div>
      )}
    </div>
  )  
}
const mapStateToProps = (state: State) => {
  return {
    
  }
}

const mapDispatchToProps = {
  
}

export default connect(mapStateToProps, mapDispatchToProps)(InputSimple);
