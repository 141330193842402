import React, { useEffect, useState } from "react";
import "./TaskPriority.scss";

const TaskPriority = ({ priorityId, setPriorityId, blockChanges }) => {
  const priorities = [
    {
      id: 1,
      name: "Низкий",
      slug: "low",
      img_path: require("./imgs/priority_low.svg").default,
    },
    {
      id: 2,
      name: "Средний",
      slug: "medium",
      img_path: "assets/priority_medium.svg",
    },
    {
      id: 3,
      name: "Высокий",
      slug: "high",
      img_path: "assets/priority_high.svg",
    },
    {
      id: 4,
      name: "Срочный",
      slug: "urgent",
      img_path: "assets/priority_urgent.svg",
    },
  ];

  const [selectPriority, setSelectPriority] = useState(false);
  const [priority, setPriority] = useState(2);
  useEffect(() => {
    if (priorityId >= 0)
      setPriority(priorities.find((item) => item.id === priorityId));
  }, [priorityId]);

  return (
    <div
      className={
        !blockChanges ? "priority-button" : "priority-button blockChange"
      }
      onClick={() => {
        if (setPriorityId) setSelectPriority((prev) => !prev);
      }}
    >
      {priority && priority.img_path && (
        <img src={priority.img_path} alt={priority.name} />
      )}
      {setPriorityId && <span>{priority.name}</span>}
      {selectPriority && (
        <div className="priority-select">
          {priorities.map((item) => {
            return (
              <div
                id={item.id}
                className="priority-button in-table"
                onClick={(e) => {
                  setPriorityId(+e.currentTarget.id);
                }}
              >
                <img src={item.img_path} alt={item.name} />
                <span>{item.name}</span>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default TaskPriority;
