import {
  taskBPTypes,
  taskDeleteConnectionActions,
  taskDeleteConnectionStatuses
} from "../constants";
import { 
  deleteTaskBP, 
  flatten, 
  syncConnectAmount, 
  syncParentIndex, 
  unflatten 
} from "./helpersCore";
import { ITaskFlattenBP } from "../interfaces";

export const getTasksDeleteConnectionStatus = (currentIndex, items, action) => {
  return checkConnectionStatus(currentIndex, items, action) ?? taskDeleteConnectionStatuses.STATUS_ERROR_DELETE;
}

const checkConnectionStatus = (currentIndex, items, action) => {
  if(action === 'delete') {
    if(statusNoChilds(currentIndex, items)) return taskDeleteConnectionStatuses.STATUS_1;
    if(statusHasChilds(currentIndex, items)) return taskDeleteConnectionStatuses.STATUS_2;
  }

  if(action === 'break') {
    if(statusBreakParallel(currentIndex, items)) return taskDeleteConnectionStatuses.STATUS_3;
    if(statusBreakParentSerialChild(currentIndex, items)) return taskDeleteConnectionStatuses.STATUS_4;
    if(statusBreakParentParallelChild(currentIndex, items)) return taskDeleteConnectionStatuses.STATUS_5;
  }

  return taskDeleteConnectionStatuses.STATUS_ERROR_DELETE;
}

/* Удаление задач */

// У задачи нет детей, status = 1
const statusNoChilds = (currentIndex, items) => {
  const currentElement = items[currentIndex];
  let isParentHasChilds = false;
  
  if(currentElement.isParent && items[currentIndex + 1]) {
    isParentHasChilds = !items[currentIndex + 1].isParent;
  }
  
  return !isParentHasChilds;
}

// У задачи есть дети, status = 2
const statusHasChilds = (currentIndex, items) => {
  const currentElement = items[currentIndex];
  let isParentHasChilds = false;

  if(currentElement.isParent && items[currentIndex + 1]) {
    isParentHasChilds = !items[currentIndex + 1].isParent;
  }

  return isParentHasChilds;
}

/* Разрыв связей */

// Разрываем параллельную связь у двух родителей без детей или у двух детей, status = 3
const statusBreakParallel = (currentIndex, items) => {
  const currentElement = items[currentIndex];
  const isCurrentParallel = currentElement.taskType === taskBPTypes.PARALLEL;

  if(!items[currentIndex + 1]) return false;
  
  return isCurrentParallel && 
    (
      (currentElement.isParent && items[currentIndex + 1].isParent) ||
      (!currentElement.isParent && !items[currentIndex + 1].isParent)
    );
}

// Разрываем связь родительской задачи со стеком ее подзадач, когда родительская задача имеет последовательную связь снизу, status = 4
const statusBreakParentSerialChild = (currentIndex, items) => {
  const currentElement = items[currentIndex];
  const isCurrentSerial = currentElement.taskType === taskBPTypes.SERIAL;
  
  if(!items[currentIndex + 1]) return false;

  return isCurrentSerial && (currentElement.isParent && !items[currentIndex + 1].isParent);
}

// Разрыв связи родительской задачи со стеком ее подзадач, когда родительская задача имеет параллельную связь снизу, status = 5
const statusBreakParentParallelChild = (currentIndex, items) => {
  const currentElement = items[currentIndex];
  const isCurrentParallel = currentElement.taskType === taskBPTypes.PARALLEL;
  
  if(!items[currentIndex + 1]) return false;

  return isCurrentParallel && (currentElement.isParent && !items[currentIndex + 1].isParent);
}

export const getConnectionsDelete = (actionConnectId, currentIndex, items) => {
  let result: any = null;

  /*if(
    actionConnectId === taskDeleteConnectionActions.ACTION_ERROR_DELETE ||
    actionConnectId === taskDeleteConnectionActions.ACTION_ERROR_CONNECTION ||
    actionConnectId === taskDeleteConnectionActions.ACTION_CANCEL ||
    actionConnectId === taskDeleteConnectionActions.ACTION_RESTRICTED
  ) {
    // Если понадобится доп. логика для этих действий
  }*/
  
  if(
    actionConnectId === taskDeleteConnectionActions.ACTION_1 ||
    actionConnectId === taskDeleteConnectionActions.ACTION_2
  ) {
    result = deleteTaskBP(currentIndex, items);
  }
  
  if(actionConnectId === taskDeleteConnectionActions.ACTION_3) {
    result = breakParallelTaskBP(currentIndex, items);
  }
  
  if(
    actionConnectId === taskDeleteConnectionActions.ACTION_4 ||
    actionConnectId === taskDeleteConnectionActions.ACTION_5
  ) {
    result = breakParentParallelChild(currentIndex, items);
  }

  if(actionConnectId === taskDeleteConnectionActions.ACTION_6) {
    result = breakParentParallelChild(currentIndex, items, 'setParallel');
  }
  
  return result ?? items;
}

/* Local logic */

const breakParallelTaskBP = (currentIndex: number, items: ITaskFlattenBP[]) => {
  items[currentIndex].taskType = taskBPTypes.SERIAL;
  
  return items;
}

const breakParentParallelChild = (
  currentIndex: number, 
  items: ITaskFlattenBP[], 
  action?: string
): ITaskFlattenBP[] => {
  let result: any = null;
  let currentChild: any = null;
  const currentElement = items[currentIndex];
  
  result = unflatten(items);
  
  result.forEach((res, i) => {
    if(res.parentIndex === currentElement.parentIndex) {
      currentChild = [...res.children];

      if(currentChild.length) {
        currentChild.forEach((child, j) => {
          child.parentIndex = null;
          child.isParent = true;
          child.controlledPosition =  {x: 0, y: 0};
          
          if(action === 'setParallel') {
            child.taskType = taskBPTypes.PARALLEL;
          }

          result.splice((i + 1) + j, 0, child);
        });
      }
      
      res.children = [];
    }
  });
  
  result = syncParentIndex(result);
  result = flatten(result);
  result = syncConnectAmount(result);

  return result;
}
