import React, { useEffect } from "react";
import { connect } from "react-redux";
import { State } from "../../../../../../rootReducer";
import {
  getTypicalTasksBp,
  setCurrentPageTypicalTasksBp,
  setTaskItemsBp,
} from "../../../../actions";
import { IMeta } from "../../../../../../common/types";
import PreloaderLocal from "../../../../../../common/components/PreloaderLocal/PreloaderLocal";
import { ITypicalTaskServerData } from "../../../../../TypicalTasks/interfaces";
import { ITaskBP } from "../../../../interfaces";

import "./ExtraTypicalTasks.scss";
import { taskBPTypes } from "../../../../constants";
import { nanoid } from "nanoid";
import { getDuration, scrollToBottom } from "../../../../helpers/helpersCommon";
import { setTaskItemsStageTreaty } from "../../../../../TypicalTreaties/actions";

const ExtraTypicalTasks: React.FC<{
  isLoading: boolean;
  typicalTasksBp: ITypicalTaskServerData[];
  currentPageTypicalTasks: number;
  typicalTasksMeta: IMeta | null;
  tasksBp?: ITaskBP[];
  setTaskItemsBp: (mode: ITaskBP, updateTrigger: string) => void;
  getTypicalTasksBp: (params?: object, action?: string) => void;
  setCurrentPageTypicalTasksBp: (mmode: number) => void;
  treaty?: boolean;
  setTaskItemsStageTreaty: (data: any) => void;
  bpsItems?: any;
  idStage?: number | string;
}> = ({
  isLoading,
  typicalTasksBp,
  currentPageTypicalTasks,
  typicalTasksMeta,
  tasksBp,
  setTaskItemsBp,
  getTypicalTasksBp,
  setCurrentPageTypicalTasksBp,
  treaty,
  setTaskItemsStageTreaty,
  bpsItems,
  idStage,
}) => {
  useEffect(() => {
    setCurrentPageTypicalTasksBp(1);
    getTypicalTasksBp();
  }, []);

  const scrollHandler = (e: React.UIEvent<HTMLDivElement>) => {
    if (!typicalTasksMeta) return;

    const { scrollHeight, scrollTop, clientHeight } = e.currentTarget;

    if (
      scrollHeight <= scrollTop + clientHeight + 10 &&
      currentPageTypicalTasks < typicalTasksMeta.last_page
    ) {
      setCurrentPageTypicalTasksBp(currentPageTypicalTasks + 1);
      getTypicalTasksBp({}, "add");
    }
  };

  const handleAddTaskToBusiness = (data) => {
    const updatedData = {
      id: data.id,
      title: data.name,
      taskType: taskBPTypes.SERIAL,
      time: data.duration,
    };

    if (treaty) {
      const dataForTreaty = {
        id: data.id,
        name: data.name,
        time: data.duration,
        bp: false,
        tasks: data?.tasks,
        count_task: data?.count_task,
        duration: getDuration(data?.duration),
      };
      setTaskItemsStageTreaty(dataForTreaty);
    } else {
      setTaskItemsBp(updatedData, nanoid(6));
    }

    scrollToBottom(".normal-form-bp");
  };

  const findItemTreaty = (item) => {
    return bpsItems?.map((stage) => {
      if (stage.id === idStage) {
        return stage.item_list?.find((stageItem) => {
          if (stageItem.id == item.id) {
            return true;
          }
        }) ? (
          <span key={stage.id}>Добавлена</span>
        ) : (
          <button
            key={stage.id}
            onClick={() => handleAddTaskToBusiness(item)}
            className="extra-form-typical-tasks__content__typical-task__add"
          >
            Добавить
          </button>
        );
      }
    });
  };

  const findTaskInBp = (task: ITypicalTaskServerData) => {
    return tasksBp?.some(item => {
      if (item.id === task.id) {
        return true;
      }
      if (item?.children) {
        return item.children.some(child => child.id === task.id);
      }
      return false;
    });
  };


  return (
    <>
      {typicalTasksBp.length > 0 ? (
        <div className="extra-form-typical-tasks__table-header">
          <div className="extra-form-typical-tasks__table-header__id">№</div>
          <div className="extra-form-typical-tasks__table-header__name">
            Наименование шаблона задачи
          </div>
          <div className="extra-form-typical-tasks__table-header__term">
            Срок
          </div>
        </div>
      ) : (
        "Типовые задачи не найдены"
      )}
      <div className="extra-form-typical-tasks__content-typical-tasks">
        {isLoading && (
          <div className="extra-form-typical-tasks__content-typical-tasks__preloader-wr">
            <div className="extra-form-typical-tasks__content-typical-tasks__preloader">
              <PreloaderLocal />
            </div>
          </div>
        )}
        <div
          className="extra-form-typical-tasks__content-typical-tasks__inner"
          onScroll={scrollHandler}
        >
          {typicalTasksBp.length > 0
            ? typicalTasksBp?.map((item) => {
                const isTaskAdded = findTaskInBp(item);
                return (
                  <div
                    className="extra-form-typical-tasks__content__typical-tasks"
                    key={item.id}
                  >
                    <div className="extra-form-typical-tasks__content__typical-task">
                      <div className="extra-form-typical-tasks__content__typical-task__id">
                        {item.id}
                      </div>
                      <div className="extra-form-typical-tasks__content__typical-task__name">
                        {item.name}
                      </div>

                      <div className="extra-form-typical-tasks__content__typical-task__term">
                        {item.duration
                          ? getDuration(item.duration)
                          : "<span>&mdash;</span>"}
                      </div>

                      {treaty ? (
                        findItemTreaty(item)
                      ) : isTaskAdded ? (
                        <span className="extra-form-typical-tasks__content__typical-task__title">
                          Добавлена
                        </span>
                      ) : (
                        <button
                          onClick={() => handleAddTaskToBusiness(item)}
                          className="extra-form-typical-tasks__content__typical-task__add"
                        >
                          Добавить
                        </button>
                      )}
                    </div>
                  </div>
                );
              })
            : ""}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: State) => {
  return {
    isLoading: state.businessPage.extraFormBP.isLoading,
    typicalTasksBp: state.businessPage.extraFormBP.typicalTasksBp,
    tasksBp: state.businessPage.normalFormBP.tasksItems,
    currentPageTypicalTasks:
      state.businessPage.extraFormBP.currentPageTypicalTasks,
    typicalTasksMeta: state.typicalTasksPage.typicalTasksMeta,
    bpsItems: state.typicalTreatyPage.normalForm.stages,
    idStage: state.typicalTreatyPage.normalForm.selectedItemId,
  };
};

const mapDispatchToProps = {
  setCurrentPageTypicalTasksBp,
  getTypicalTasksBp,
  setTaskItemsBp,
  setTaskItemsStageTreaty,
};

export default connect(mapStateToProps, mapDispatchToProps)(ExtraTypicalTasks);