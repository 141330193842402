import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { State } from "../../../../../rootReducer";
import MultiSelectVanilla from "../../../../../common/components/ui/MultiSelectVanilla/MultiSelectVanilla";
import {
  getObjects,
  setCurrentPage,
  setObjectsFilterUsers,
} from "../../../actions";
import { ObjectsTerms } from "../../../../../common/constants";
import { ObjectsPageActionTypes } from "../../../action_types";
import { getResponsiblesForFilterData, getUsersResponsibleFilterIds } from "../../../selectors";

type Props = {
  responsibleFilterData: any;
  responsibleFilterId: number[];
  setObjectsFilterUsers: (terms: number[]) => void;
  disabled?: boolean;
  isSingleSelect?: boolean;
  getObjects: (params?: object) => void;
  setCurrentPage: (currentPage: number) => void;
  usersDataForFilterIsLoading?: boolean;
};

const ResponsibleSelectObjects: React.FC<Props> = ({
  responsibleFilterData,
  responsibleFilterId,
  usersDataForFilterIsLoading,
  setObjectsFilterUsers,
  disabled,
  isSingleSelect,
  getObjects,
  setCurrentPage
}) => {
  const dispatch = useDispatch();
  const [placeholder, setPlaceholder] = useState<any>(ObjectsTerms.ALL_TEXT);
  const [skipGetObjects, setSkipGetObjects] = useState<boolean>(true);
  
  const open = () => {
    dispatch({type: ObjectsPageActionTypes.GET_OBJECTS_USERS_FOR_FILTER});

    setSkipGetObjects(false);
  }

  useEffect(() => {
    if(!skipGetObjects) {
      getObjects();
    }

    if(responsibleFilterData.selected.length === 0) {
      setPlaceholder('Все');
    }
    else if(responsibleFilterData.selected.length < 2) {
      setPlaceholder(responsibleFilterData?.selected[0]?.text);
    }
    else {
      setPlaceholder(responsibleFilterData?.selected[0]?.text + ' и другие...');
    }
    if (responsibleFilterId.includes(0)) {
      setObjectsFilterUsers([]);
    }
  }, [responsibleFilterId]);

  return (
    <MultiSelectVanilla
      isLoading={usersDataForFilterIsLoading}
      placeholder={placeholder}
      disabled={disabled}
      itemsList={responsibleFilterData?.list}
      selectedItems={responsibleFilterData.selected}
      isValid={responsibleFilterData.selected.length > 0}
      openHandler={() => open()}
      addHandler={(value: number) => {
        setObjectsFilterUsers([...responsibleFilterId, value as number]);
        setCurrentPage(1);
      }}
      removeHandler={(value: number) => {
        setObjectsFilterUsers(responsibleFilterId.filter((id) => id !== value));
      }}
    />
  );
};

const mapStateToProps = (state: State) => {
  return {
    responsibleFilterId: getUsersResponsibleFilterIds(state),
    responsibleFilterData: getResponsiblesForFilterData(state),
    usersDataForFilterIsLoading: state.objectsPage.filters.usersDataForFilterIsLoading,
  };
};

const mapDispatchToProps = {
  setObjectsFilterUsers,
  getObjects,
  setCurrentPage,
};

export default connect(mapStateToProps, mapDispatchToProps)(ResponsibleSelectObjects);
