import React, { FC } from "react";
import InputTypicalTask from "../../ui/InputTypicalTask/InputTypicalTask";
import ArrayTypicalTask from "../../ui/ArrayTypicalTask/ArrayTypicalTask";
import { connect } from "react-redux";
import { State } from "../../../../../../rootReducer";
import { IExtraFormSelectTabData, TArrayValues } from "../../../../action_types";
import { setExtraFormSelectTabData } from "../../../../actions";
import { SelectedTabNameExtraForm } from "../../../../constants";

import './SelectTabTypicalTask.scss';

const SelectTabTypicalTask: FC<{
  title: string,
  arrayValues: TArrayValues[],
  setExtraFormSelectTabData: (selectTabData: IExtraFormSelectTabData) => void,
}> = ({
  title,
  arrayValues,
  setExtraFormSelectTabData,
}) => {
  const validationState = {
    input: false,
    array: false
  };
  
  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setExtraFormSelectTabData({title: e.target.value});
  };

  const onCheckHandler = ( isValid: boolean, type: string ) => {
    let isValidLocal = false;
    
    validationState[type] = isValid
    
    if(validationState.input && validationState.array) {
      isValidLocal = true;
    }
    
    setExtraFormSelectTabData({isValid: isValidLocal});
  }
  
  return (
    <div className='select-tab-typical-task'>
      <div className="select-tab-typical-task__input">
        <InputTypicalTask
          onCheck={onCheckHandler}
          onChange={onChangeInput}
          value={title}
        />
      </div>
      
      <div className="select-tab-typical-task__array">
        <ArrayTypicalTask
          onCheck={onCheckHandler}
          arrayValues={arrayValues}
          selectedTabName={SelectedTabNameExtraForm.SELECT_TAB} 
        />
      </div>
    </div>
  )
}

const mapStateToProps = (state: State) => {
  return {
    arrayValues: state.typicalTasksPage.extraForm.selectTab.arrayValues as TArrayValues[],
    title: state.typicalTasksPage.extraForm.selectTab.title as string
  }
};

const mapDispatchToProps = {
  setExtraFormSelectTabData
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectTabTypicalTask);
