import { call, put, takeEvery } from "redux-saga/effects";
import { CreateNotif } from "../../../../utils/createNotification";
import { FormEditTaskActionTypes } from "./action_types";
import {
  setCheckRulesEditTask,
  setCreatingTask,
  setLoadingTask,
  setObjects,
  setStages,
  setTaskFormData
} from "./actions";
import { messagesObjectTask, priorityNames, priorityValues } from "../../constants";
import { IChecklistTask, IObjectsFormEditTaskDataSelect } from "./interfaces";
import { fetchData } from "../../../../utils/fetchData";
import { IAdditionalFieldTypicalTask } from "../../../TypicalTasks/interfaces";
import { DataTypes, FieldSizes } from "../../../TypicalTasks/constants";
import { mapExtraFieldTypeHelper } from "../../../TypicalTasks/saga";
import { IObjectsServerData } from "../../interfaces";
import { formatToStringUTC } from "../../../../common/format";
import { updateObjectsStore, updateTaskStore } from "../../actions";
import { taskPageUpdateTaskStore } from "../../../TasksPage/actions";
import { convertObjectFromServerToFrontDataHelper, getObjectSectionsTasks } from "../../saga";

export function* watchGetTaskChecklist() {
  yield takeEvery(FormEditTaskActionTypes.GET_CHECKLIST, getChecklist);
}

export function* watchGetTaskObject() {
  yield takeEvery(FormEditTaskActionTypes.GET_TASK, getTask);
}

export function* watchGetStages() {
  yield takeEvery(FormEditTaskActionTypes.GET_STAGES, getStages);
}

export function* watchGetObjectsFormEditTask() {
  yield takeEvery(FormEditTaskActionTypes.GET_OBJECTS, getObjects);
}

export function* watchUpdateTaskObject() {
  yield takeEvery(FormEditTaskActionTypes.UPDATE_TASK, updateTask);
}

function* getChecklist(id: any) {
  const response: {data: IChecklistTask[]} = yield call(
    fetchData.get,
    `/api/v1/tasks/${id}/checklists`
  );
  
  return response;
}

function* getObjects() {
  const response: {data: IObjectsServerData[]} = yield call(
    fetchData.get,
    '/api/v1/projects?limit=1000'
  );

  if(response) {
    yield put(setObjects(response.data));
  }
}

function* getStages() {
  // Todo getStages saga
  // console.log('getStages saga');

  // yield put(setLoadingTask(true));
  
  // yield delay(1000); // TODO delete

  let response: any = {};
  /*let response: any = yield call(
    fetchData.get,
    `/api/v1/objects/stages/`
  );*/

  if(response) {
    // Формат данных нужно преобразовывать для селекта
    const convertedData: IObjectsFormEditTaskDataSelect[] = convertServerToFrontFORMDataHelper(response);

    yield put(setStages(convertedData));
  }

  yield put(setLoadingTask(false));
}

function* getTask({id}: any) {
  yield put(setLoadingTask(true));
  
  const response: any = yield call(
    fetchData.get,
    `/api/v1/tasks/${id}`
  );
  
  const responseChecklist = yield call(getChecklist, id);

  if(response) {
    const convertedData = yield call(convertServerToFrontFORMDataHelper, response);

    yield put(setTaskFormData(convertedData, convertedData.additionalFields));

    // Для проверки правил доступа для редактирования задачи
    let responseObject: any = yield call(
      fetchData.get,
      `/api/v1/projects/${response.project_id}`
    );

    if(responseObject) {
      yield put(setCheckRulesEditTask({
        responsibilityIdObject: responseObject.project_manager.id,
        authorIdObject: responseObject.author_id
      }));
    }
  }
  
  if(responseChecklist?.length) {
    yield put(setTaskFormData(null, {checklist: responseChecklist} ));
  }

  yield put(setLoadingTask(false));
}

function* updateTask({data}: any) {
  yield put(setCreatingTask(true));
  
  const convertedData = yield convertFrontToServerDataHelper(data);
  
  const response: any = yield call(
    fetchData.patch,
      `/api/v1/tasks/${data.taskId}`,
      JSON.stringify(convertedData)
  );
  
  if(data.extra.checklist?.length) {
    for(let chk of data.extra.checklist) {
      yield call(
        fetchData.patch,
        `/api/v1/tasks/${data.taskId}/checklists/${chk.id}`,
        JSON.stringify({
          name: chk.name,
          task_id: data.task_id,
          done: chk.done
        })
      );
    }
  }
  
  if(response) {
    yield put(updateTaskStore(response));
    yield put(taskPageUpdateTaskStore(response));
    
    yield call(getObjectSectionsTasks, { objectId: response.project_id });
    yield call(getObject, response.project_id);
    
    CreateNotif(messagesObjectTask.UPDATE_OBJECT_TASK_SUCCESS, 'success');
  }
  
  yield put(setCreatingTask(false));
}

function* getObject(objectId) {
  let response: any = yield call(
    fetchData.get,
    `/api/v1/projects/${objectId}`
  );

  if(response) {
    const convertedDataServer = convertObjectFromServerToFrontDataHelper([response]);
    
    if(convertedDataServer.length) {
      yield put(updateObjectsStore(convertedDataServer[0]));
    }
  }
}

function* convertServerToFrontFORMDataHelper(data: any): any {
  const priorityName = priorityNames[priorityValues[data.priority_id]] ?? priorityNames.MEDIUM;
  let extra: IAdditionalFieldTypicalTask[] = [];

  if(data.extra?.length) {
    extra = data?.extra.map(field => ({
      id: field.typical_task_data.id,
      serverId: field.id,
      serverData: field.value,
      type: mapExtraFieldTypeHelper(field.typical_task_data, field.typical_task_data.id),
      required: field.typical_task_data.required,
      isSendToProject: field.typical_task_data.passToObject,
      name: field.typical_task_data.name,
      data: {
        list: field?.typical_task_data?.data?.list ?? [],
        type: field.typical_task_data.data?.type ?? DataTypes.VALUE_ANY,
        size: field.typical_task_data.data?.size ?? FieldSizes.VALUE_STANDARD,
      }
    }));
  }
  
  return {
    taskId: data.id,
    author: data.author,
    commentsCount: data.comments_count,
    createdAt: data.created_at,
    startDate: data.begin,
    endDate: data.end,
    description: data.description,
    project_id: data.project_id,
    project_section_id: data.project_section_id,
    executor: {
      selectedValue: {
        value: data.executor.id, 
        label: data.executor.surname + ' ' + data.executor.name
      }
    },
    title: data.name,
    priorities: {
      selectedValue: {
        value: data.priority_id, 
        label: priorityName
      }
    },
    spectators: data.spectators,
    status_id: data.status_id, 
    additionalFields: {
      extra 
    }
  }
}

function* extraConvertHelper(extra) {
  let result: any = [];
  
  if(extra.filesToUpload?.length) {
    result.push({
      id: extra.filesToUpload[0].id,
      value: { 
        name: extra.filesToUpload[0].name,
        ext: extra.filesToUpload[0].ext,
        data: extra.filesToUpload[0].data
      }
    });
  }

  if(extra.input.value.length) {
    result.push({
      id: extra.input.serverId,
      value: extra.input.value
    });
  }
  
  if(extra.select.value.length) {
    result.push({
      id: extra.select.serverId,
      value: extra.select.value.length ? extra.select.label : ''
    });
  }

  if(extra.radio.value.length) {
    result.push({
      id: extra.radio.serverId,
      value: extra.radio.value
    });
  }
  
  if(extra.checkboxes) {
    let res: any = {};
    let values: any = [];
    
    extra.checkboxes.forEach(chk => {
      if(chk.checked) {
        res = {
          id: chk.serverId,
        };

        values.push(chk.value)
      }
    });
    
    res = {
      ...res,
      value: values
    };
    
    if(values.length) {
      result.push(res);
    }
  }

  return result;
}

function* convertFrontToServerDataHelper(data: any) {
  const extraToServer = yield call(extraConvertHelper, data.extra);
  
  let result: any = {
    extra: extraToServer
  };

  if(data.project_section_id) {
    result.project_section_id = data.project_section_id;
  }

  if(data.project_id) {
    result.project_id = data.project_id;
  }

  if(data.status_id) {
    result.status_id = data.status_id;
  }

  if(data.startDate) {
    result.begin = formatToStringUTC(data.startDate);
  }

  if(data.endDate) {
    result.end = formatToStringUTC(data.endDate);
  }

  if(data.spectators) {
    result.spectators = data.spectators;
  }

  if(data.executor) {
    result.executor_id = data.executor;
  }

  if(data.priority) {
    result.priority_id = data.priority;
  }

  if(data.title) {
    result.name = data.title;
  }

  if(data.description) {
    result.description = data.description;
  }
  
  return result;
}
