import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { State } from "../../../../../rootReducer";
import PreloaderLocal from "../../../../../common/components/PreloaderLocal/PreloaderLocal";
import { ReactComponent as CloseIcon } from "../../../../../img/TrueIcons/close.svg";
import {
  setNormalForm,
  setIsTreatyFormOpen,
  setIsTreatyFullFormOpen,
  setExtraFormTreatyDefaultOpen,
  updateMainTreaty,
  createTreaty,
  setExtraFormTreatyEditFormOpen,
  setExtraFormTreatyCreateFormOpen,
  setExtraFormTreatyTasksAndBPFormOpen,
  updateTypicalTreaty,
} from "../../../actions";
import { TextareaAutosize } from "@material-ui/core";

import "./NormalForm.scss";
import { INormalFormTreaty } from "../../../interfaces";
import { errorValidationMessages } from "../../../../ObjectsPage/constants";
import StagesTreaty from "./components/StagesTreaty/StagesTreaty";
import OtherTreatyCard from "./components/OtherTreatyCard/OtherTreatyCard";
import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { TypeTypicalTreatiesActivity } from "../../../action_types";

const NormalFormTreaty: React.FC<{
  name?: string;
  description?: string;
  setNormalForm: (mode: INormalFormTreaty) => void;
  isEditForm: boolean;
  isFormTreatyOpen: boolean;
  isFullFormTreatyOpen: boolean;
  setIsTreatyFormOpen: (mode: boolean) => void;
  setIsTreatyFullFormOpen: (mode: boolean) => void;
  setExtraFormTreatyDefaultOpen: (mode: boolean) => void;
  stages: any;
  author?: string;
  updateMainTreaty: (data: object) => void,
  updateTypicalTreaty: (data: object) => void,
  createTreaty: (data: object) => void;
  activity?: TypeTypicalTreatiesActivity;
  setExtraFormTreatyEditFormOpen: (mode: boolean) => void;
  setExtraFormTreatyCreateFormOpen: (mode: boolean) => void;
  setExtraFormTreatyTasksAndBPFormOpen: (mode: boolean) => void;
  id?: number | string;
  main?: boolean;
  isSavingTreaty?: boolean,
}> = ({
  name,
  description,
  setNormalForm,
  isEditForm,
  isFormTreatyOpen,
  isFullFormTreatyOpen,
  setIsTreatyFormOpen,
  setIsTreatyFullFormOpen,
  setExtraFormTreatyDefaultOpen,
  stages,
  author,
  updateMainTreaty,
  updateTypicalTreaty,
  createTreaty,
  activity,
  setExtraFormTreatyEditFormOpen,
  setExtraFormTreatyCreateFormOpen,
  setExtraFormTreatyTasksAndBPFormOpen,
  id,
  main,
  isSavingTreaty
}) => {
  const errorMessages = {
    required: errorValidationMessages.REQUIRED,
  };
  const [errorState, setErrorState] = useState<any>({
    name: "",
    description: "",
  });
  const [isFormValid, setIsFormValid] = useState<boolean>(false);

  const [stageData, setStageData] = useState<any>([]);

  const onChangeNameHandler = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNormalForm({ name: e.target.value,});
    checkHelper("name", e.target.value);
  };

  const onChangeDescriptionHandler = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setNormalForm({ description: e.target.value });
  };

  const collectData = () => {
    let dataObject = {
      id,
      name,
      description,
      stageData,
      activity
    };

    return dataObject;
  };

  const onSubmitHandler = () => {
    const data = collectData();
    if(isEditForm && main){
       updateMainTreaty(data)
    }else if(isEditForm && !main){
      updateTypicalTreaty(data)
    }
    else createTreaty(data); 
  };

  const onCloseHandler = () => {
    setIsTreatyFormOpen(false);
    setIsTreatyFullFormOpen(false);
    setNormalForm({name: "", description: ""});
  };

  const onOpenExtraForm = () => {
    setExtraFormTreatyDefaultOpen(true);
    setIsTreatyFullFormOpen(true);
  };

  const setErrorHelper = (field, errorType) => {
    setErrorState((state) => {
      const newState = { ...state };

      if (field) {
        newState[field] = errorMessages[errorType];
      }

      return newState;
    });
  };

  const checkHelper = (type?: string, string?: string): boolean => {
    let isValid = true;
    //on change
    if (type === "name" && !string?.length) {
      setErrorHelper("name", "required");
      isValid = false;
    }

    // On submit
    if (!type && !name?.length) {
      setErrorHelper("name", "required");
      isValid = false;
    }

    if (isValid) {
      setErrorState((state) => {
        let newState = { ...state };

        if (type) {
          return {
            ...newState,
            [type]: "",
          };
        }

        return state;
      });
    }

    setIsFormValid(isValid);

    return isValid;
  };
  

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active?.id !== over?.id) {
      setStageData((prev) => {
        const activeIndex = prev.findIndex(
          (item: any) => item.id === active?.id
        );
        const overIndex = prev.findIndex((item: any) => item.id === over?.id);

        return arrayMove(prev, activeIndex, overIndex);
      });
    }
  };

  useEffect(() => {
    if (stages?.length) {
      setStageData(stages);
    }
  }, [stages]);

  const onOpenEditStage = () => {
    setExtraFormTreatyEditFormOpen(false)
    setExtraFormTreatyCreateFormOpen(true)
    setExtraFormTreatyDefaultOpen(false)
    setExtraFormTreatyTasksAndBPFormOpen(false)
  }
  
  return (
    <div className="normal-form-treaty">
      <div className="normal-form-treaty__content">
        <div className="normal-form-treaty__title-wr">
          {!isEditForm && (
            <h2 className="normal-form-treaty__title">
              Добавляем типовой договор
            </h2>
          )}

          {isEditForm && (
            <h2 className="normal-form-treaty__title">
              Редактируем типовой договор <span>"{name}"</span>
            </h2>
          )}

          <button
            className="normal-form-treaty__close"
            onClick={onCloseHandler}
          >
            <CloseIcon />
          </button>
        </div>
      </div>

      <h3 className="normal-form-treaty__title_options">Базовые параметры</h3>

      <TextareaAutosize
        className={
          "name-typical-task" +
          (errorState.name?.length > 0
            ? " normal-form-treaty__input--error"
            : "")
        }
        placeholder="* Наименование типового договора"
        onChange={(e) => {
          onChangeNameHandler(e);
        }}
        value={name}
        disabled={main}
      />

      {errorState.name?.length > 0 && (
        <div className="normal-form-treaty__error-message">
          {errorState?.name}
        </div>
      )}

      <TextareaAutosize
        style={{ height: "60px" }}
        className={"normal-form-treaty__description"}
        placeholder="Описание"
        onChange={(e) => {
          onChangeDescriptionHandler(e);
        }}
        value={description}
        disabled={main}
      />
      {(isFullFormTreatyOpen || isEditForm) ? (
        <div className="normal-form-treaty__title_options">
          Состав разделов, бизнес-процессов и задач
        </div>
      ): ""}
      {isEditForm &&
        !isFullFormTreatyOpen &&
        (stageData?.filter((item) => {
          if (!main && item.status) {
            return item;
          } else if (main) {
            return item;
          }
        }).length > 0 ? (
          stageData
            ?.filter((item) => {
              if (!main && item.status) {
                return item;
              } else if (main) {
                return item;
              }
            })
            .map((stage) => (
              <ul key={stage.id} className="stage__wrapper">
                <li className="stage__title">{stage.name}</li>
                {stage?.item_list?.length > 0 ? (
                  stage?.item_list?.map((item) => (
                    <li key={item.id} className="stage__subtitle">
                      {item.bp ? "БП - " + item.name : "Задача - " + item.name}
                    </li>
                  ))
                ) : (
                  <div className="stage__subtitle">
                    Бизнес-процессы и задачи пока не добавлены
                  </div>
                )}
              </ul>
            ))
        ) : (
          <div className="stage__absence">Разделы, бизнес-процессы и задачи не добавлены</div>
        ))}
      {isFullFormTreatyOpen && stages?.length > 0 && (
        <>
          <DndContext
            collisionDetection={closestCenter}
            sensors={sensors}
            onDragEnd={handleDragEnd}
          >
            <div className="stage-treaty__header">
              <div className="stage-treaty__header__name_stage">Этап</div>
              <div className="stage-treaty__header__quantity_bps">Всего БП</div>
              <div className="stage-treaty__header__quantity_tasks">
                Всего задач
              </div>
              <div className="stage-treaty__header__duration_treaty">
                Срок исп
              </div>
              <div className="stage-treaty__header__actual">{main ? "Публикация" : "Актуальность"}</div>
            </div>
            <SortableContext
              items={stageData as []}
              strategy={verticalListSortingStrategy}
            >
              {stageData?.map((stage: any) => (
                <StagesTreaty key={stage.id} stage={stage} setStageData={setStageData}/>
              ))}
            </SortableContext>
          </DndContext>
        </>
      )}
       {isEditForm && isFullFormTreatyOpen && main ? (
        <div className="normal-form-treaty__other-full" onClick={() => {onOpenEditStage()}}>Добавить этап</div>
      ) : (
        <div
          onClick={() => onOpenExtraForm()}
          className="normal-form-treaty__other"
        >
          {isFormTreatyOpen &&
            !isFullFormTreatyOpen &&
            "Настроить состав этапов типового договора"}
        </div>
      )}

      {isEditForm && (
        <div className="normal-form__other">
          <OtherTreatyCard/>
        </div>
      )}

      <div className="normal-form-treaty__btns">
        {main ? (isFullFormTreatyOpen ? (
          <>
           <button
           className={"normal-form-treaty__btn normal-form-treaty__btn--submit" +
             (isSavingTreaty ? " normal-form-treaty__btn--show-preloader" : "")
            }
                  onClick={onSubmitHandler}
                  disabled={!name}
                >
                  <span className="normal-form-treaty__btn-title">
                    Сохранить
                  </span>
                  <span className="normal-form-treaty__btn-preloader">
                    <PreloaderLocal />
                  </span>
                </button>
          <button
            className="normal-form-treaty__btn normal-form-treaty__btn--submit"
            onClick={onCloseHandler}
          >
            <span className="normal-form-treaty__btn-title">Отменить</span>
          </button>
          </>
        ) : 
        <button
        className="normal-form-treaty__btn normal-form-treaty__btn--submit"
        onClick={onCloseHandler}
      >
        <span className="normal-form-treaty__btn-title">Закрыть</span>
      </button>
        ) : (
          <>
            <button
              className={"normal-form-treaty__btn normal-form-treaty__btn--submit" +
               (isSavingTreaty ? " normal-form-treaty__btn--show-preloader" : "")
              }
              onClick={onSubmitHandler}
              disabled={!name}
            >
              <span className="normal-form-treaty__btn-title">
                {isEditForm && <>Сохранить типовой договор</>}

                {!isEditForm && (
                  <>
                    Добавить типовой
                    {isFullFormTreatyOpen ? " проект" : " договор"}
                  </>
                )}
              </span>
              <span className="normal-form-treaty__btn-preloader">
                <PreloaderLocal />
              </span>
            </button>
            <button
              className="normal-form-treaty__btn normal-form-treaty__btn--cancel"
              onClick={onCloseHandler}
            >
              <span className="normal-form-treaty__btn-title">Отменить</span>
            </button>
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: State) => {
  return {
    id: state.typicalTreatyPage.normalForm.id,
    main: state.typicalTreatyPage.normalForm.main,
    name: state.typicalTreatyPage.normalForm.name,
    description: state.typicalTreatyPage.normalForm.description,
    author: state.typicalTreatyPage.normalForm.author,
    isEditForm: state.typicalTreatyPage.isEditForm,
    activity: state.typicalTreatyPage.normalForm.activity,
    isFormTreatyOpen: state.typicalTreatyPage.isFormTreatyOpen,
    isFullFormTreatyOpen: state.typicalTreatyPage.isFullFormTreatyOpen,
    stages: state.typicalTreatyPage.normalForm.stages,
    isSavingTreaty: state.typicalTreatyPage.isSavingTreaty
  };
};

const mapDispatchToProps = {
  setNormalForm,
  setIsTreatyFormOpen,
  setIsTreatyFullFormOpen,
  setExtraFormTreatyDefaultOpen,
  updateMainTreaty,
  updateTypicalTreaty,
  createTreaty,
  setExtraFormTreatyEditFormOpen,
  setExtraFormTreatyCreateFormOpen,
  setExtraFormTreatyTasksAndBPFormOpen,

};

export default connect(mapStateToProps, mapDispatchToProps)(NormalFormTreaty);
