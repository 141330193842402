import React, { useCallback, useState } from "react";
import Input from "../../../common/components/Inputs/Input";
import { Link, useHistory } from "react-router-dom";
import { fetchData } from "../../../utils/fetchData";
import CustomBlock from "./CustomBlock";
import styled from "styled-components";
import Button from "../../../common/components/newComponent/Buttons/Button";
const FormStyle = styled.form`
  display: flex;
  flex-direction: column;
`;
const SpanStyle = styled.span`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #707070;
  margin-bottom: ${({ isSended }) => (isSended ? "24px" : "8px")};
`;
const WrapperButton = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  width: 100%;
  button {
    width: 132px;
  }
`;
const EmailForm = () => {
  const [email, setEmail] = useState("");
  const [isSended, setIsSended] = useState(false);
  const [isSending, setIsSending] = useState(false);
  let history = useHistory();
  let validationEmail =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const formSubmitHandler = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      setIsSending(true);
      e.preventDefault();
      let result = await fetchData.post(
        "/api/v1/password/reset",
        JSON.stringify({ email })
      );
      if (typeof result !== "undefined") {
        setIsSended(true);
      }
      setIsSending(false);
    },
    [email]
  );

  return (
    <CustomBlock title={isSended ? "Спасибо!" : "Восстановление пароля"}>
      <FormStyle onSubmit={formSubmitHandler}>
        <SpanStyle isSended={isSended}>
          {isSended
            ? "Мы отправили инструкции по изменению пароля на ваш адрес электронной почты. Если в течение десяти минут электронное письмо не получено, проверьте правильность отправленного адреса."
            : "Введите адрес электронной почты, который вы используете для своей учетной записи"}
        </SpanStyle>
        {!isSended ? (
          <div style={{ zIndex: 10000 }}>
            <Input
              inputType="text"
              placeholder="Email"
              title=""
              withIcon={false}
              value={email}
              changeHandler={(value) => setEmail(value)}
            />
            <WrapperButton>
              <Button
                big
                title="Продолжить"
                disabled={!validationEmail.test(email)}
                isSending={isSending}
              />
              <Button
                big
                title="Назад"
                design="outline"
                onClick={(e) => {
                  e.preventDefault();
                  history.push("/login");
                }}
              />
            </WrapperButton>
          </div>
        ) : (
          <Button
            big
            title="Готово"
            onClick={(e) => {
              e.preventDefault();
              history.push("/login");
            }}
          />
        )}
      </FormStyle>
    </CustomBlock>
  );
};

export default EmailForm;
