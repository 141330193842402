import React, { useEffect } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import styled from "styled-components";
import Trash from "../../../../../img/projectIcons/Trash";
import { connect } from "react-redux";
import PreloaderLocal from "../../../../../common/components/PreloaderLocal/PreloaderLocal";
import { State } from "../../../../../rootReducer";
import {
  checkDeleteObject, 
  getObject,
  setDeleteObjectModalData, 
  setIsEditObjectForm, 
  setObjectEditingId,
  setObjectsEditFormDefaultValues, 
  setObjectsEditingFormActiveTabIndex,
  setObjectsEditingFormOpen,
} from "../../../actions";

import "./styles.scss";
import { IDeleteObjectModal } from "../../../interfaces";
import { CheckContextsObjects, TabIdsObjectEditingForm } from "../../../constants";
import { nanoid } from "nanoid";
import { setCreatingTask, setFormDefaultValues, setFormOpen, setObjectId } from "../../FormAddTask/actions";
import useCheckRolesMeridian from "../../../../../utils/hooks/useCheckRolesMeridian";

interface IObjectMenuProps {
  id: number | string;
  anchorEl: HTMLElement | null;
  checkDeleteObject: (id: string | number) => void;
  setAnchorEl: (arg0: React.MouseEvent<HTMLButtonElement> | null) => void;
  isShowMenuPreloader: boolean;
  isDeleteModalOpen?: IDeleteObjectModal;
  setDeleteObjectModalData: (mode: any) => void;
  setObjectsEditingFormOpen: (isEditingObjectsFormOpen: boolean) => void,
  setObjectsEditFormDefaultValues: (trigger: string) => void,
  setIsEditObjectForm: (isEditObjectsForm: boolean) => void,
  setObjectsEditingFormActiveTabIndex: (tabIndex: number) => void,
  setObjectEditingId: (idObjectEdited: string|number) => void,
  getObject: (id: string|number) => void,
  
  setFormOpen: (isFormOpen: boolean) => void,
  setCreatingTask: (isCreatingTask: boolean) => void,
  setObjectId: (id: string|number) => void,
  setFormDefaultValues: () => void
}

const MenuStyle = {
  marginTop: "45px",
};

const MenuItemStyle = {
  display: "flex",
  alignItems: "center",
  marginBottom: "5px",
  fontSize: "14px",
};

const MenuIcon = styled.div`
  margin-right: 10px;
  display: flex;
  align-items: center;

  > svg {
    stroke: #292a34;
  }
`;

const MenuTitle = styled.div``;

const ObjectMenu = ({
  id,
  anchorEl,
  setAnchorEl,
  checkDeleteObject,
  isShowMenuPreloader,
  isDeleteModalOpen,
  setDeleteObjectModalData,
  setObjectsEditingFormOpen,
  setObjectsEditFormDefaultValues,
  setIsEditObjectForm,
  setObjectsEditingFormActiveTabIndex,
  setObjectEditingId,
  getObject,
  
  setFormOpen,
  setCreatingTask,
  setObjectId,
  setFormDefaultValues
}: IObjectMenuProps) => {
  const { isUserCanAddTask } = useCheckRolesMeridian(CheckContextsObjects.IS_USER_CAN_ADD_TASK);
  const { isUserCanDeleteObject } = useCheckRolesMeridian(CheckContextsObjects.IS_USER_CAN_DELETE_OBJECT);

  const clickObjectCloseHandler = () => {
    setAnchorEl(null);
  };

  const clickDeleteHandler = (id: string | number, e: any) => {
    e.stopPropagation();
    checkDeleteObject(id);
  };
  const clickDescriptHandler = (id: string | number, e: any) => {
    e.stopPropagation();

    openFormHelper(TabIdsObjectEditingForm.DESCRIPTION_TAB, id);
  };
  const clickCommentHandler = (id: string | number, e: any) => {
    e.stopPropagation();

    openFormHelper(TabIdsObjectEditingForm.COMMENTS_TAB, id);
  };
  const clickDocumentHandler = (id: string | number, e: any) => {
    e.stopPropagation();

    openFormHelper(TabIdsObjectEditingForm.DOCUMENTS_TAB, id);
  };
  const clickAddTaskHandler = (id: string | number, e: any) => {
    e.stopPropagation();
    clickObjectCloseHandler();
    
    setFormDefaultValues();

    setFormOpen(true);
    setCreatingTask(false);
    setObjectId(id);
  };
  
  const openFormHelper = (tabIndex: number, idObjectEdited: string|number) => {
    clickObjectCloseHandler();

    setObjectsEditFormDefaultValues(nanoid(6));
    setIsEditObjectForm(true);
    setObjectsEditingFormOpen(true);
    setObjectsEditingFormActiveTabIndex(tabIndex);
    setObjectEditingId(idObjectEdited);

    getObject(idObjectEdited);
  };

  useEffect(() => {
    if (isDeleteModalOpen) clickObjectCloseHandler();
  }, [isDeleteModalOpen]);

  return (
    <Menu
      className={
        "task-menu-typical-task" +
        (isShowMenuPreloader
          ? " task-menu-typical-task__title-wr--show-preloader"
          : "")
      }
      anchorEl={anchorEl}
      open={!!anchorEl}
      onClose={() => clickObjectCloseHandler()}
      style={MenuStyle}
    >
      
      <MenuItem
        style={{ ...MenuItemStyle, marginBottom: 0 }}
        onClick={(e) => {
          clickDescriptHandler(id, e);
        }}
      >
      
        <MenuTitle className={"task-menu-typical-task__title-wr"}>
          <div className="task-menu-typical-task__title">Описание</div>
        </MenuTitle>
      </MenuItem>
      <MenuItem
        style={{ ...MenuItemStyle, marginBottom: 0 }}
        onClick={(e) => {
          clickCommentHandler(id, e);
        }}
      >
        <MenuTitle className={"task-menu-typical-task__title-wr"}>
          <div className="task-menu-typical-task__title">Комментарии</div>
        </MenuTitle>
      </MenuItem>
      <MenuItem
        style={{ ...MenuItemStyle, marginBottom: 0 }}
        onClick={(e) => clickDocumentHandler(id, e)}
      >
        <MenuTitle className={"task-menu-typical-task__title-wr"}>
          <div className="task-menu-typical-task__title">Документы</div>
        </MenuTitle>
      </MenuItem>

      {isUserCanAddTask && (
        <MenuItem
          style={{ ...MenuItemStyle, marginBottom: 0 }}
          onClick={(e) => clickAddTaskHandler(id, e)}
        >
          <MenuTitle className={"task-menu-typical-task__title-wr"}>
            <div className="task-menu-typical-task__title">Добавить задачу</div>
          </MenuTitle>
        </MenuItem>
      )}

      {isUserCanDeleteObject && (
        <MenuItem
          style={{ ...MenuItemStyle, marginBottom: 0 }}
          onClick={(e) => clickDeleteHandler(id, e)}
        >
          <MenuTitle className={"task-menu-typical-task__title-wr"}>
            <div className="task-menu-typical-task__title">Удалить объект</div>
          </MenuTitle>
        </MenuItem>
      )}
      
      <div className="task-menu-typical-task__preloader">
          <PreloaderLocal />
        </div>
    </Menu>
  );
};

const mapStateToProps = (state: State) => {
  return {
    isShowMenuPreloader: state.objectsPage.isShowMenuPreloader,
    isDeleteModalOpen: state.objectsPage.deleteObjectModal,
  };
};

const mapDispatchToProps = {
  checkDeleteObject,
  setDeleteObjectModalData,
  setObjectsEditingFormOpen,
  setObjectsEditFormDefaultValues,
  setIsEditObjectForm,
  setObjectsEditingFormActiveTabIndex,
  setObjectEditingId,
  getObject,
  
  setFormOpen,
  setCreatingTask,
  setObjectId,
  setFormDefaultValues
};

export default connect(mapStateToProps, mapDispatchToProps)(ObjectMenu);
