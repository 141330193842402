import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { State } from "../../../rootReducer";
import styled from "styled-components";
import Icon from "../../../common/components/Icon";
import { Redirect, useHistory, useParams } from "react-router-dom";
import palette from "../../../common/components/palette";

const NewRow = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
  //   overflow: auto;
  &:last-child {
    margin-bottom: 0;
  }
  ${({ styles }) => styles && { ...styles }}
`;

type Props = { payment_request_id: number };

const CheckPayment: React.FC<Props> = ({ payment_request_id }) => {
  let { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  if (!payment_request_id) {
    return <Redirect to="/CompanyDetails" />;
  }

  return (
    <NewRow style={{ margin: "auto", marginTop: "180px" }}>
      <div
        style={{
          boxShadow: "0px 4px 5px rgba(0, 0, 0, 0.2)",
          width: "565px",
          paddingTop: "30px",
          paddingBottom: "30px",
        }}
      >
        <div style={{ textAlign: "center" }}>
          <Icon name="CheckPayment" />
        </div>
        <div
          style={{
            textAlign: "center",
            marginTop: "30px",
            fontSize: "16px",
            fontWeight: 400,
          }}
        >
          Для Вас сформирован счёт на оплату и активирована подписка.
        </div>
        <div
          style={{
            textAlign: "center",
            fontSize: "16px",
            fontWeight: 400,
            marginTop: "30px",
          }}
        >
          При отсутствии оплаты по счёту в течении 5 дней подписка будет
          деактвивирована.
        </div>
        <div
          style={{
            textAlign: "center",
            marginTop: "30px",
            fontSize: "16px",
            fontWeight: 400,
          }}
        >
          {id ? (
            <a
              href={`${process.env.REACT_APP_PUBLIC_URL}/payment/bill-pdf-create/${id}`}
              target="_blank"
              style={{
                textDecoration: "none",
                color: palette.blue,
                margin: 0,
                padding: 0,
                cursor: "pointer",
              }} rel="noreferrer"
            >
              Загрузить счёт
            </a>
          ) : (
            ""
          )}
        </div>
        <div
          style={{
            textAlign: "center",
            fontSize: "16px",
            fontWeight: 400,
            marginTop: "20px",
            // marginBottom: "30px",
            cursor: "pointer",
            color: palette.blue,
          }}
          onClick={() => {
            history.push("/CompanyDetails");
          }}
        >
          Вернуться на страницу компании
        </div>
      </div>
    </NewRow>
  );
};

const mapStateToProps = (state: State) => {
  return {
    //   currentTariff: state.payment.currentTariff,
    // step: state.payment.step,
    // months: state.payment.months,
    // members: state.payment.members,
    //   paid_success: state.payment.paid_success,
    // paymentDetailsData: state.payment.paymentDetails,
    // commonInfoCompany: state.commonInfo.company,
    payment_request_id: state.payment.payment_request_id,
  };
};

const mapDispatchToProps = {
  // changeStep
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckPayment);
