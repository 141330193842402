import { sortConditions } from "./constants";

export const columnsOfTablesTypicalTasks = [
  {
    columnId: sortConditions.ID,
    showingText: "Id",
    style: { minWidth: "40px" },
  },
  {
    columnId: sortConditions.NAME,
    showingText: "Типовая задача",
    style: { minWidth: "280px", maxWidth: "700px" },
  },
  {
    columnId: sortConditions.BUSINESS,
    showingText: "Бизнес-процесс",
    style: { width: "200px", },
  },
  {
    columnId: sortConditions.EXECUTOR,
    showingText: "Исполнитель",
    style: { width: "200px" },
  },
  {
    columnId: sortConditions.AUTHOR,
    showingText: "Автор",
    style: { width: "200px" },
  },
  {
    columnId: sortConditions.CREATED_AT,
    showingText: "Дата создания",
    style: { width: "200px" },
  },
  {
    columnId: sortConditions.ACTIVITY,
    showingText: "Состояние",
    style: { width: "95px" },
  },
];
