import { Action } from "redux";
import {
  IDeleteObjectModal,
  IDeleteTaskModal,
  IObjectsServerData,
  ISortConditions,
} from "./interfaces";
import { ICheckAddTaskModal, IEditingObjectsFormData } from "./interfaces";
import { File, IMeta, User } from "../../common/types";
import { ObjectsTerms, OjectsPersonal } from "../../common/constants";

export type TypeObjectsFilterTerms =
  | ObjectsTerms.ALL_STATUS
  | ObjectsTerms.MORE_HUNDRED_STATUS
  | ObjectsTerms.MORE_SIXTY_STATUS
  | ObjectsTerms.MORE_THREETY_STATUS
  | ObjectsTerms.LESS_THREETY_STATUS
  | ObjectsTerms.OVERDUE_STATUS;

export type TypeFilterPersonal =
  | OjectsPersonal.PERSONAL_STATUS
  | OjectsPersonal.NOT_PERSONAL_STATUS;

export enum ObjectsPageActionTypes {
  GET_OBJECT = "@@OBJECTS_PAGE/GET_OBJECT",
  SET_CHECK_OBJECT_MODAL = "@@OBJECTS_PAGE/SET_CHECK_OBJECT_MODAL",
  CREATE_OBJECT = "@@OBJECTS_PAGE/CREATE_OBJECT",
  UPDATE_OBJECT = "@@OBJECTS_PAGE/UPDATE_OBJECT",
  UPDATE_OBJECT_FILES_STORE = "@@OBJECTS_PAGE/UPDATE_OBJECT_FILES_STORE",
  UPDATE_OBJECTS_STORE = "@@OBJECTS_PAGE/UPDATE_TYPICAL_TASK_STORE",
  UPDATE_TASK_STORE = "@@OBJECTS_PAGE/UPDATE_TASKS_STORE",
  SET_IS_EDIT_OBJECT_FORM = "@@OBJECTS_PAGE/UPDATE_OBJECTS_STORE",
  SET_CREATING_OBJECT = "@@OBJECTS_PAGE/SET_CREATING_OBJECT",
  SET_OBJECTS_EDITING_FORM_OPEN = "@@OBJECTS_PAGE/SET_OBJECTS_EDITING_FORM_OPEN",
  SET_OBJECTS_EDITING_FORM_ACTIVE_TAB_INDEX = "@@OBJECTS_PAGE/SET_OBJECTS_EDITING_FORM_ACTIVE_TAB_INDEX",
  GET_OBJECTS_DATA = "@@OBJECTS_PAGE/GET_OBJECTS_DATA",
  SET_OBJECTS_DATA = "@@OBJECTS_PAGE/SET_OBJECTS_DATA",
  SET_CURRENT_PAGE = "@@OBJECTS_PAGE/SET_CURRENT_PAGE",
  SET_LOADING_OBJECTS = "@@OBJECTS_PAGE/SET_LOADING_OBJECTS",
  SET_OBJECT_SECTION_LIST = "@@OBJECTS_PAGE/SET_OBJECT_SECTION_LIST",
  SET_SECTION_TASK_LIST = "@@OBJECTS_PAGE/SET_SECTION_TASK_LIST",
  SET_SORT_CONDITION = "@@OBJECTS_PAGE/SET_SORT_CONDITION",
  SET_IS_SHOW_MENU_PRELOADER = "@@OBJECTS_PAGE/SET_IS_SHOW_MENU_PRELOADER",
  SET_DELETE_OBJECT_MODAL_DATA = "@@OBJECTS_PAGE/SET_DELETE_OBJECT_MODAL_DATA",
  CHECK_DELETE_OBJECT = "@@OBJECTS_PAGE/CHECK_DELETE_OBJECT",
  DELETE_OBJECT = "@@OBJECTS_PAGE/DELETE_OBJECT",
  DELETE_OBJECT_FROM_STATE = "@@OBJECTS_PAGE/DELETE_OBJECT_FROM_STATE",
  SET_DELETE_TASK_MODAL_DATA = "@@OBJECTS_PAGE/SET_DELETE_TASK_MODAL_DATA",
  CHECK_DELETE_TASK = "@@OBJECTS_PAGE/CHECK_DELETE_TASK",
  DELETE_TASK_SECTION_FROM_STATE = "@@OBJECTS_PAGE/DELETE_TASK_SECTION_FROM_STATE",
  SET_DELETE_TASK_REGULAR = "@@OBJECTS_PAGE/SET_DELETE_TASK_REGULAR", //сделана для регулировки локальных данных
  DELETE_TASK_SECTION = "@@OBJECTS_PAGE/DELETE_TASK_SECTION",
  SET_OBJECTS_EDITING_FORM_DATA = "@@OBJECTS_PAGE/SET_OBJECTS_EDITING_FORM_DATA",
  SET_OBJECTS_EDITING_FORM_CHECK_TRIGGER = "@@OBJECTS_PAGE/SET_OBJECTS_EDITING_FORM_CHECK_TRIGGER",
  SET_LOADING_OBJECT = "@@OBJECTS_PAGE/SET_LOADING_OBJECT",
  SET_OBJECTS_EDITING_ID = "@@OBJECTS_PAGE/SET_OBJECTS_EDITING_ID",
  SET_OBJECTS_USER_RESPONSIBLE_SELECTED = "@@OBJECTS_PAGE/SET_OBJECTS_USER_RESPONSIBLE_SELECTED",
  SET_OBJECTS_USERS_RESPONSIBLE_LOADING = "@@OBJECTS_PAGE/SET_OBJECTS_USERS_RESPONSIBLE_LOADING",
  SET_OBJECTS_USERS_RESPONSIBLE = "@@OBJECTS_PAGE/SET_OBJECTS_USERS_RESPONSIBLE",
  GET_OBJECTS_USERS_RESPONSIBLE = "@@OBJECTS_PAGE/GET_OBJECTS_USERS_RESPONSIBLE",
  DELETE_FILE_STORE = "@@OBJECTS_PAGE/DELETE_FILE_STORE",
  SET_OBJECTS_FILTER_TERMS = "@@OBJECTS_PAGE/SET_OBJECTS_FILTER_TERMS",
  SET_OBJECTS_FILTER_USERS = "@@OBJECTS_PAGE/SET_OBJECTS_FILTER_USERS",
  SET_OBJECTS_USERS_FOR_FILTER = "@@TYPICAL_TASKS/SET_OBJECTS_USERS_FOR_FILTER",
  SET_OBJECTS_FILTER_PERSONAL = "@@OBJECTS_PAGE/SET_OBJECTS_FILTER_PERSONAL",
  SET_OBJECTS_USERS_FOR_FILTER_IS_LOADING = "@@OBJECTS_PAGE/SET_OBJECTS_USERS_FOR_FILTER_IS_LOADING",
  GET_OBJECTS_USERS_FOR_FILTER = "@@OBJECTS_PAGE/GET_OBJECTS_USERS_FOR_FILTER",
  SET_SEARCH_OBJECTS = "@@OBJECTS_PAGE/SET_SEARCH_OBJECTS",
  SET_OBJECTS_EDIT_FORM_DEFAULT_VALUES_TRIGGER = "@@OBJECTS_PAGE/SET_OBJECTS_EDIT_FORM_DEFAULT_VALUES_TRIGGER",
  GET_OBJECT_SECTION = "@@OBJECTS_PAGE/GET_OBJECT_SECTION",
  GET_TASK_STATISTIC = '@@OBJECTS_PAGE/GET_TASK_STATISTIC',
  GET_TASKS = '@@OBJECTS_PAGE/GET_TASKS',
  GET_META = "@@OBJECTS_PAGE/GET_META",
  SET_META = "@@OBJECTS_PAGE/SET_META",
}

export interface getMetaAction extends Action<ObjectsPageActionTypes.GET_META> {
  
}

export interface setMetaAction extends Action<ObjectsPageActionTypes.SET_META> {
  meta: IMeta;
}

export interface getObjectsAction
  extends Action<ObjectsPageActionTypes.GET_OBJECTS_DATA> {
  params?: object;
  action?: string;
}

export interface getTaskStatisticAction
  extends Action<ObjectsPageActionTypes.GET_TASK_STATISTIC> {
  sectionId: string|number;
}

export interface getTasksAction
  extends Action<ObjectsPageActionTypes.GET_TASKS> {
  sectionId: string|number;
}

export interface getObjectSectionAction
  extends Action<ObjectsPageActionTypes.GET_OBJECT_SECTION> {
  objectId: number|string
}

export interface setObjectsEditFormDefaultValuesAction
  extends Action<ObjectsPageActionTypes.SET_OBJECTS_EDIT_FORM_DEFAULT_VALUES_TRIGGER> {
  trigger: string;
}

export interface setObjectsAction
  extends Action<ObjectsPageActionTypes.SET_OBJECTS_DATA> {
  objects: IObjectsServerData | IObjectsServerData[];
  action?: string
}

export interface setObjectsFilterTermsAction
  extends Action<ObjectsPageActionTypes.SET_OBJECTS_FILTER_TERMS> {
  terms: number[];
}

export interface setObjectsFilterUsersAction
  extends Action<ObjectsPageActionTypes.SET_OBJECTS_FILTER_USERS> {
  users: number[];
}

export interface setObjectsFilterPersonalAction
  extends Action<ObjectsPageActionTypes.SET_OBJECTS_FILTER_PERSONAL> {
  personal: TypeFilterPersonal;
}

export interface setObjectsUsersForFilterAction
  extends Action<ObjectsPageActionTypes.SET_OBJECTS_USERS_FOR_FILTER> {
  usersDataForFilter: User[];
}

export interface setObjectsUsersForFilterIsLoadingAction
  extends Action<ObjectsPageActionTypes.SET_OBJECTS_USERS_FOR_FILTER_IS_LOADING> {
  usersDataForFilterIsLoading: boolean;
}

export interface setSearchObjectsAction extends Action<ObjectsPageActionTypes.SET_SEARCH_OBJECTS> {
  searchObjects: string|null;
}

export interface deleteFileStoreAction
  extends Action<ObjectsPageActionTypes.DELETE_FILE_STORE> {
  id: string | number;
}

export interface setCheckCreateObjectModalAction
  extends Action<ObjectsPageActionTypes.SET_CHECK_OBJECT_MODAL> {
  data: ICheckAddTaskModal;
}

export interface createObjectAction
  extends Action<ObjectsPageActionTypes.CREATE_OBJECT> {
  data: object;
}

export interface updateObjectsAction
  extends Action<ObjectsPageActionTypes.UPDATE_OBJECT> {
  data: object;
  files: FileList;
}

export interface updateTaskStoreAction
  extends Action<ObjectsPageActionTypes.UPDATE_TASK_STORE> {
  task: any;
}

export interface updateObjectsStoreAction
  extends Action<ObjectsPageActionTypes.UPDATE_OBJECTS_STORE> {
  data: IObjectsServerData;
}

export interface setIsEditObjectFormAction
  extends Action<ObjectsPageActionTypes.SET_IS_EDIT_OBJECT_FORM> {
  isEditObjectsForm: boolean;
}

export interface setCreatingObjectAction
  extends Action<ObjectsPageActionTypes.SET_CREATING_OBJECT> {
  isCreatingObject: boolean;
}

export interface setObjectsEditingFormOpenAction
  extends Action<ObjectsPageActionTypes.SET_OBJECTS_EDITING_FORM_OPEN> {
  isEditingObjectsFormOpen: boolean;
}

export interface setObjectEditingFormActiveTabIndexAction
  extends Action<ObjectsPageActionTypes.SET_OBJECTS_EDITING_FORM_ACTIVE_TAB_INDEX> {
  activeTabIndex: number;
}

export interface setLoadingObjectsAction
  extends Action<ObjectsPageActionTypes.SET_LOADING_OBJECTS> {
  isLoadingObjects: boolean;
}
export interface setCurrentPageAction
  extends Action<ObjectsPageActionTypes.SET_CURRENT_PAGE> {
  currentPage: number;
}

export interface setObjectSectionListAction
  extends Action<ObjectsPageActionTypes.SET_OBJECT_SECTION_LIST> {
  sections: any[];
}
export interface setObjectSectionTaskListAction
  extends Action<ObjectsPageActionTypes.SET_SECTION_TASK_LIST> {
  tasks: any[];
}

export interface setSortConditionAction
  extends Action<ObjectsPageActionTypes.SET_SORT_CONDITION> {
  sortCondition: ISortConditions;
}

export interface setIsShowMenuPreloaderAction
  extends Action<ObjectsPageActionTypes.SET_IS_SHOW_MENU_PRELOADER> {
  isShowMenuPreloader: boolean;
}

export interface deleteObjectAction
  extends Action<ObjectsPageActionTypes.DELETE_OBJECT> {
  id: string | number;
}

export interface setDeleteObjectAction
  extends Action<ObjectsPageActionTypes.SET_DELETE_OBJECT_MODAL_DATA> {
  data: any;
}

export interface setDeleteTaskAction
  extends Action<ObjectsPageActionTypes.SET_DELETE_TASK_MODAL_DATA> {
  data: IDeleteTaskModal;
}

export interface checkDeleteTaskAction
  extends Action<ObjectsPageActionTypes.CHECK_DELETE_TASK> {
  id: string | number;
}

export interface deleteTaskFromStateAction extends Action<ObjectsPageActionTypes.DELETE_TASK_SECTION_FROM_STATE> {
  id: string|number;
}


export interface checkDeleteObjectAction
  extends Action<ObjectsPageActionTypes.CHECK_DELETE_OBJECT> {
  id: string | number;
}

export interface deleteObjectFromStateAction
  extends Action<ObjectsPageActionTypes.DELETE_OBJECT_FROM_STATE> {
  id: string | number;
}

export interface setDeleteStateTaskRegularAction
  extends Action<ObjectsPageActionTypes.SET_DELETE_TASK_REGULAR> {
  deleteStateRegular: boolean;
}

export interface deleteTaskAction
  extends Action<ObjectsPageActionTypes.DELETE_TASK_SECTION> {
  taskId: string | number;
}

export interface setObjectsEditingFormDataAction
  extends Action<ObjectsPageActionTypes.SET_OBJECTS_EDITING_FORM_DATA> {
  editingFormData: IEditingObjectsFormData;
}

export interface setObjectsEditingFormCheckTriggerAction
  extends Action<ObjectsPageActionTypes.SET_OBJECTS_EDITING_FORM_CHECK_TRIGGER> {
  checkTrigger: string;
}

export interface setObjectsEditingIdAction
  extends Action<ObjectsPageActionTypes.SET_OBJECTS_EDITING_ID> {
  idObjectEdited: string | number;
}

export interface getObjectAction
  extends Action<ObjectsPageActionTypes.GET_OBJECT> {
  id: number | string;
}

export interface setLoadingObjectAction
  extends Action<ObjectsPageActionTypes.SET_LOADING_OBJECT> {
  isLoadingObject: boolean;
}

export interface setObjectsUsersResponsibleLoadingAction
  extends Action<ObjectsPageActionTypes.SET_OBJECTS_USERS_RESPONSIBLE_LOADING> {
  usersDataIsLoading: boolean;
}

export interface setObjectsUsersResponsibleAction
  extends Action<ObjectsPageActionTypes.SET_OBJECTS_USERS_RESPONSIBLE> {
  usersData: User[];
}

export interface setObjectsUsersResponsibleSelectedAction
  extends Action<ObjectsPageActionTypes.SET_OBJECTS_USER_RESPONSIBLE_SELECTED> {
  userSelected: { value: string; label: string };
}

export interface updateObjectFilesStoreAction
  extends Action<ObjectsPageActionTypes.UPDATE_OBJECT_FILES_STORE> {
  files: File[]
}

export type ObjectsPageAction =
  | updateObjectFilesStoreAction
  | updateTaskStoreAction
  | getMetaAction
  | setMetaAction
  | getTasksAction
  | getTaskStatisticAction
  | getObjectSectionAction
  | setObjectsEditFormDefaultValuesAction
  | getObjectsAction
  | setObjectsAction
  | setObjectsFilterTermsAction
  | setObjectsFilterUsersAction
  | setObjectsFilterPersonalAction
  | setObjectsUsersForFilterAction
  | setObjectsUsersForFilterIsLoadingAction
  | setSearchObjectsAction
  | setObjectSectionListAction
  | setObjectSectionTaskListAction
  | deleteFileStoreAction
  | setCheckCreateObjectModalAction
  | setObjectsUsersResponsibleSelectedAction
  | setObjectsUsersResponsibleLoadingAction
  | setObjectsUsersResponsibleAction
  | setObjectsEditingIdAction
  | setLoadingObjectAction
  | getObjectAction
  | setObjectsEditingFormCheckTriggerAction
  | setObjectsEditingFormDataAction
  | createObjectAction
  | updateObjectsAction
  | updateObjectsStoreAction
  | setIsEditObjectFormAction
  | setCreatingObjectAction
  | setObjectsEditingFormOpenAction
  | setObjectEditingFormActiveTabIndexAction
  | setLoadingObjectsAction
  | setSortConditionAction
  | setIsShowMenuPreloaderAction
  | setDeleteObjectAction
  | checkDeleteObjectAction
  | deleteObjectFromStateAction
  | deleteTaskAction
  | deleteTaskFromStateAction
  | setDeleteTaskAction
  | setDeleteStateTaskRegularAction
  | setCurrentPageAction;
