import React from 'react';
import UsersSelectTypicalTasks from "../ui/selectors/UsersSelectTypicalTasks";
import ActivitySelectTypicalTasks from "../ui/selectors/ActivitySelectTypicalTasks";
import PersonalToggle from "../ui/toggle/PersonalToggle";
import { State } from "../../../../rootReducer";
import { setFormDefaultValuesTrigger, setIsEditForm, setTaskFormOpen } from "../../actions";
import { connect } from "react-redux";
import { nanoid } from "nanoid";

import './FiltersTasks.scss';

const FiltersTasks: React.FC<{
  setTaskFormOpen: (isFormTasksOpen: boolean) => void;
  setIsEditForm: (isEditForm: boolean) => void,
  setFormDefaultValuesTrigger: (formDefaultValuesTrigger: string) => void
}> = ({
  setTaskFormOpen,
  setIsEditForm,
  setFormDefaultValuesTrigger
}) => {
  const formOpenHandler = () => {
    setFormDefaultValuesTrigger(nanoid(6));
    
    setIsEditForm(false);
    setTaskFormOpen(true);
  };
  
  return (
    <div className='filters-tasks'>
      <div className="filters-tasks__left">
        <div className="filters-tasks__filters-selectors">
          <div className="filters-tasks__filters-selector-item">
            <div className="filters-tasks__filters-selector-item-title">Автор</div>
            <div className="filters-tasks__filters-selector-item-element">
              <UsersSelectTypicalTasks />
            </div>
          </div>

          <div className="filters-tasks__filters-selector-item">
            <div className="filters-tasks__filters-selector-item-title">Активность</div>
            <div className="filters-tasks__filters-selector-item-element filters-tasks__filters-selector-item-element--width">
              <ActivitySelectTypicalTasks isSingleSelect={true} />
            </div>
          </div>
        </div>

        <div className="filters-tasks__filters-toggle">
          <PersonalToggle />
        </div>
      </div>
      
      <div className="filters-tasks__right">
        <button className='filters-tasks__add-btn' 
                type='button'
                onClick={() => formOpenHandler()}
        >
          + Добавить типовую задачу
        </button>
      </div>
    </div>
  )
}

const mapStateToProps = (state: State) => {
  return {
    
  }
}

const mapDispatchToProps = {
  setTaskFormOpen,
  setIsEditForm,
  setFormDefaultValuesTrigger
}

export default connect(mapStateToProps, mapDispatchToProps)(FiltersTasks);