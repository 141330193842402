import {
  types,
  fetchTasksAction,
  fetchSearchAction,
  checkDeleteProjectAction,
  deleteProjectAction,
} from "./action_types";
import { types as typesCommon } from "../../common/action_types";
import { takeEvery, call, put, debounce } from "redux-saga/effects";
import { fetchData } from "../../utils/fetchData";
import {
  changeTaskList,
  deleteProjectFromProjectsState,
  setAllProjects,
  setDeleteProjectModalOpen,
  setIsFetchingTasks,
  updateProjects,
} from "./actions";
import { store } from "../../App";
import {
  CollectAllFilters,
  sorting,
  table_filter,
} from "../../common/shared_data";
import { Item, Task } from "../../common/types";
import { milisecondDateFromOurFormat } from "../TaskInfoPlate/reducer";
import {
  setLoadingProjects,
  setSearchClear,
} from "../../common/actions";
import { formatStringDate } from "../../common/format";
import {
  deleteProjectStatuses,
  errorMessagesConstants,
  messagesProjectConstants,
} from "../../common/constants";
import { CreateNotif } from "../../utils/createNotification";
import { response } from "express";

export function* watchFiltersActualToggleProjects() {
  yield takeEvery(types.SET_ALL_OR_ACTUAL_PROJECTS, apply_filters_orders);
}

export function* watchSetStatusFilterProjects() {
  yield takeEvery(types.SET_FILTERS_PROJECTS, apply_filters_orders);
}

export function* watchSetTableOrderProjects() {
  yield takeEvery(types.SET_TABLE_ORDER, apply_filters_orders);
}

export function* watchFetchProjectTasks() {
  yield takeEvery(types.FETCH_TASKS, fetchTasks);
}

export function* watchSetProjectSearch() {
  yield debounce(500, typesCommon.GET_SEARCH_PROJECTS, fetchSearch);
}

export function* watchDeleteProjects() {
  yield takeEvery(types.DELETE_PROJECT, deleteProject);
}

export function* watchCheckDeleteProject() {
  yield takeEvery(types.CHECK_DELETE_PROJECT, checkDeleteProject);
}

export function* watchGetAllProjects() {
  yield takeEvery(types.GET_ALL_PROJECTS, getAllProjectsData);
} 

function* getAllProjectsData() {
  yield put(setLoadingProjects(true));

  let {tasksOrder, tableFilter} = store.getState().projectsPage;
  const {current_set} = store.getState().commonInfo.filters;
  
  if (localStorage.getItem('cols_order_project')) {
    const parsedObj = JSON.parse(
      localStorage.getItem('cols_order_project') as string
    );
    
    tasksOrder.col_name = parsedObj.name;
    tasksOrder.order_direct = parsedObj.order_direct;
  }

  try {  
    const response = yield call(
      fetchData.get,
      `/api/v1/projects?limit=1000` +
      display_type(current_set) +
      sorting(tasksOrder) +
      CollectAllFilters(current_set) +
      table_filter(tableFilter)
    );

    if(response?.data) {
      yield put(setAllProjects(response.data));
    }

  } catch (error) {
    console.error('Не удалось получить список проектов');
  }

  yield put(setLoadingProjects(false));
}

function* fetchSearch({ search, abortController }: fetchSearchAction) {
  try {
    let response: { data: Task[] } | null = null;

    yield put(setIsFetchingTasks(true));
    yield put(setSearchClear(false, false));

    response = yield call(
      fetchData.get,
      `/api/v1/projects?search=${search.trim().toLowerCase()}`,
      {},
      "",
      abortController
    );

    if (response?.data) {
      yield put(setAllProjects(response?.data));
    }

    yield put(setIsFetchingTasks(false));
  } catch (e) {
    console.error(errorMessagesConstants.FIND_PROJECTS);
    yield put(setIsFetchingTasks(false));
  }
}

function* fetchTasks({
  projectId,
  pageNum,
  abortController,
}: fetchTasksAction) {
  const {
    isFetchingTasks: isLoading,
    tasks,
    tableFilter,
    tasksOrder,
    // filters,
    tableOrTreeToggler,
    // selectedProject
  } = store.getState().projectsPage;

  const { current_set } = store.getState().commonInfo.filters;

  // делаю копию объекта стэйта, чтобы оригинальный стэйт не изменился
  let Copyed_obj = {
    date_from: "",
    date_to: "",
    executors: [] as Item[],
    authors: [] as Item[],
    statuses: [] as number[],
    actual: true,
  };
  Object.keys(current_set).forEach((key) => {
    let keyType = typeof current_set[key];
    if (keyType === "object") Copyed_obj[key] = [...current_set[key]];
    else Copyed_obj[key] = current_set[key];
  });
  // проверяю, существует ли наличие статуса "просрочено"
  let outdated = false;
  let in_work_native = false;
  if (Copyed_obj.statuses.includes(14)) {
    if (Copyed_obj.statuses.includes(10)) {
      // статус "в работе" тоже есть. Тогда статус "просрочено" удаляем, и оставляем признак outdated
      let index = Copyed_obj.statuses.findIndex((it) => it === 14);
      Copyed_obj.statuses.splice(index, 1);
      in_work_native = true;
    } else {
      // статуса "в работе" нет, тогда меняем 14 статус на 10
      Copyed_obj.statuses[Copyed_obj.statuses.indexOf(14)] = 10;
    }
    outdated = true;
  }

  if (!isLoading && pageNum === 1) yield put(setIsFetchingTasks(true));
  let response;

  if (projectId) {
    if (tableOrTreeToggler === "table") {
      response = yield call(
        fetchData.get,
        `/api/v1/tasks?project_id=${projectId}&page=${pageNum}` +
          display_type(Copyed_obj) +
          sorting(tasksOrder) +
          CollectAllFilters(Copyed_obj) +
          table_filter(tableFilter),
        {},
        "",
        abortController
      );
    } else if (tableOrTreeToggler === "tree") {
      response = yield call(
        fetchData.get,
        `/api/v1/projects/${projectId}/trees?` +
          display_type(Copyed_obj) +
          sorting(tasksOrder) +
          CollectAllFilters(Copyed_obj) +
          table_filter(tableFilter),
        {},
        "",
        abortController
      );
    } else if (tableOrTreeToggler === "gantt") {
      response = yield call(
        fetchData.get,
        `/api/v1/tasks?project_id=${projectId}&limit=100000` +
          display_type(Copyed_obj) +
          sorting(tasksOrder) +
          CollectAllFilters(Copyed_obj) +
          table_filter(tableFilter),
        {},
        "",
        abortController
      );
    }
  }

  if (response) {
    response.forEach((item) => {
      item.begin = formatStringDate(item.begin);
      item.end = formatStringDate(item.end);
    });
    if (outdated) {
      if (in_work_native) {
        // не нужно фильтровать по дедлайну с 10 статусом, "в работе" тоже нужны
        if (pageNum === 1) yield put(changeTaskList([...response]));
        else yield put(changeTaskList([...tasks, ...response]));
      } else {
        let filtered = response.filter((respItem) => {
          if (respItem.status_id === 10) {
            if (
              new Date(Date.now()) >
              new Date(milisecondDateFromOurFormat(respItem.end))
            )
              return respItem;
          }
        });
        if (pageNum === 1) {
          yield put(
            changeTaskList([
              ...filtered,
              ...response.filter((item) => item.status_id !== 10),
            ])
          );
        } else {
          yield put(
            changeTaskList([
              ...tasks,
              ...filtered,
              ...response.filter((item) => item.status_id !== 10),
            ])
          );
        }
      }
    } else {
      if (pageNum === 1) yield put(changeTaskList([...response]));
      else yield put(changeTaskList([...tasks, ...response]));
    }
  }
  yield put(setIsFetchingTasks(false));
}

function* apply_filters_orders() {
  const { tasksOrder, tableFilter, selectedProject, tableOrTreeToggler } =
    store.getState().projectsPage;
  const { current_set } = store.getState().commonInfo.filters;

  yield put(setLoadingProjects(true));

  // делаю копию объекта стэйта, чтобы оригинальный стэйт не изменился
  let Copyed_obj = {
    date_from: "",
    date_to: "",
    executors: [] as Item[],
    authors: [] as Item[],
    statuses: [] as number[],
    actual: true,
  };
  Object.keys(current_set).forEach((key) => {
    let keyType = typeof current_set[key];
    if (keyType === "object") Copyed_obj[key] = [...current_set[key]];
    else Copyed_obj[key] = current_set[key];
  });

  // проверяю, существует ли наличие статуса "просрочено"
  let outdated = false;
  let in_work_native = false;
  if (Copyed_obj.statuses.includes(14)) {
    if (Copyed_obj.statuses.includes(10)) {
      // статус "в работе" тоже есть. Тогда статус "просрочено" удаляем, и оставляем признак outdated
      let index = Copyed_obj.statuses.findIndex((it) => it === 14);
      Copyed_obj.statuses.splice(index, 1);
      in_work_native = true;
    } else {
      // статуса "в работе" нет, тогда меняем 14 статус на 10
      Copyed_obj.statuses[Copyed_obj.statuses.indexOf(14)] = 10;
    }
    outdated = true;
  }

  let response;
  if (tableOrTreeToggler === "table") {
    response = yield call(
      fetchData.get,
      `/api/v1/projects?limit=1000` +
        display_type(current_set) +
        sorting(tasksOrder) +
        CollectAllFilters(current_set) +
        table_filter(tableFilter)
    );

    document.querySelector('.projectAllTable__wrapper-tasks')?.scrollTo({ 
      top: 0, 
      behavior: 'smooth' 
    });
  } else if (tableOrTreeToggler === "tree") {
    response = yield call(
      fetchData.get,
      `/api/v1/projects/${selectedProject?.id}/trees?` +
        display_type(current_set) +
        sorting(tasksOrder) +
        CollectAllFilters(current_set) +
        table_filter(tableFilter)
    );
  } else if (tableOrTreeToggler === "gantt") {
    response = yield call(
      fetchData.get,
      `/api/v1/tasks?project_id=${selectedProject?.id}&limit=1000` +
        display_type(current_set) +
        sorting(tasksOrder) +
        CollectAllFilters(current_set) +
        table_filter(tableFilter)
    );
  }

  yield put(setLoadingProjects(false));

  let result;
  if (response.data) result = response.data;
  else result = response;

  if (result) {
    result.forEach((item) => {
      item.begin = formatStringDate(item.begin);
      item.end = formatStringDate(item.end);
    });
    if (outdated) {
      if (in_work_native) {
        // не нужно фильтровать по дедлайну с 10 статусом, "в работе" тоже нужны
        yield put(setAllProjects([...response.data]));
      } else {
        let filtered = response.filter((respItem) => {
          if (respItem.status_id === 10) {
            if (
              new Date(Date.now()) >
              new Date(milisecondDateFromOurFormat(respItem.end))
            )
              return respItem;
          }
        });
        yield put(
          setAllProjects([
            ...filtered,
            ...response.filter((item) => item.status_id !== 10),
          ])
        );
      }
    } else {
      yield put(setAllProjects([...result]));
    }
  }
}

function display_type(argument) {
  return argument.exec_auth === "" ? "&displayType=relevant" : "";
}

function* checkDeleteProject({ id }: checkDeleteProjectAction) {
  yield put(
    setDeleteProjectModalOpen({
      projectId: id,
      isDeleteModalOpen: false,
      isShowMenuPreloader: true,
    })
  );

  let response: any = null;

  response = yield call(fetchData.get, `/api/v1/projects/${id}/check-delete`);

  yield put(
    setDeleteProjectModalOpen({
      projectId: id,
      isDeleteModalOpen: true,
      statusFromServer: {constraints: response?.constraints},
      isShowMenuPreloader: false,
    })
  );
}

function* deleteProject({ id }: deleteProjectAction) {
  const errorStatuses = [403, 404, 500];

  yield put(
    setDeleteProjectModalOpen({
      projectId: id,
      isDeleteModalOpen: true,
      isShowPreloader: true,
    })
  );

  const response = yield call(fetchData.delete, `/api/v1/projects/${id}`);

  yield put(
    setDeleteProjectModalOpen({
      projectId: id,
      isDeleteModalOpen: false,
      isShowPreloader: false,
    })
  );

  if (response?.status === 204) {
    yield put(deleteProjectFromProjectsState(id));
    CreateNotif(messagesProjectConstants.DELETE_PROJECT_SUCCESS, "success");
  }

  if (errorStatuses.includes(response?.status)) {
    CreateNotif(messagesProjectConstants.DELETE_PROJECT_ERROR);
  }
}
