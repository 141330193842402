import React, { FC, useEffect } from 'react';
import { connect } from "react-redux";
import { State } from "../../rootReducer";
import FormEditingObject from "./components/FormEditingObject/FormEditingObject";
import ObjectsList from "./components/ObjectsList/objectsList";
import { getObjects, setCurrentPage } from './actions';
import CheckAddTask from "./components/ui/modals/CheckAddTask/CheckAddTask";
import FormAddTask from "./components/FormAddTask/FormAddTask";
import FormEditTask from "./components/FormEditTask/FormEditTask";

const ObjectsPage: FC<{
  getObjects: (obj?: any) => void;
  setCurrentPage: (pageNum: number) => void;
}> = ({
  getObjects,
  setCurrentPage
}) => {

  useEffect(() => {
    setCurrentPage(1);
    getObjects();
  }, []);
    
  return (
    <div className='objects-page' style={{ 
      position: 'relative', 
      width: '100%', 
      height: '100%',
      overflow: 'hidden',
    }}>
      <ObjectsList/>
      
      <FormEditingObject />
      <FormAddTask />
      
      <FormEditTask />
      <CheckAddTask />
    </div>
  );
};

const mapStateToProps = (state: State) => ({
 
});

const mapDispatchToProps = {
  getObjects,
  setCurrentPage
};

export default connect(mapStateToProps, mapDispatchToProps)(ObjectsPage);
