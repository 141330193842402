import React, { useEffect, useState } from "react";
import { TextareaAutosize } from "@material-ui/core";
import TimeTaskToogle from "../../ui/toggle/TimeTaskToogle";
import calIcon from "../../../assets/cal.svg";
import execIcon from "../../../assets/exec.svg";
import Executors from "../../Executors/Executors";
import AdditionalFields from "../AdditionalFields/AdditionalFields";
import { connect } from "react-redux";
import { State } from "../../../../../rootReducer";
import {
  setTaskFullFormOpen,
  setTaskFormOpen,
  dispatchCheckboxItem,
  setNormalFormData,
  createTypicalTask, 
  updateTypicalTask,
} from "../../../actions";
import CheckList from "../../CheckList/CheckList";
import {
  IAdditionalFieldTypicalTask,
  ICheckListTypicalTask,
  IExecutorFormNormal,
  INormalFormData
} from "../../../interfaces";
import PreloaderLocal from "../../../../../common/components/PreloaderLocal/PreloaderLocal";
import { TypeFilterActivity, TypeTimeTask } from "../../../action_types";
import OtherTypicalTask from "../components/OtherTypicalTask/OtherTypicalTask";
import { ReactComponent as CloseIcon } from "../../../../../img/TrueIcons/close.svg";
import { errorValidationMessages } from "../../../../ObjectsPage/constants";

import "./normalForm.scss";

const NormalForm: React.FC<{
  idTaskEdited?: string|number,
  isEditForm: boolean; 
  isFormTasksOpen: boolean;
  isCreatingTypicalTask: boolean,
  setTaskFormOpen: (mode: boolean) => void;
  setTaskFullFormOpen: (mode: boolean) => void;
  dispatchCheckboxItem: (
    orderNumber: number,
    text: string,
    checkboxState: boolean,
    actionType: string
  ) => void;
  setNormalFormData: (normalFormData: INormalFormData) => void,
  createTypicalTask: (data: object) => void,
  updateTypicalTask: (data: object) => void,
  name?: string,
  timeValue?: string|number,
  timeType?: TypeTimeTask,
  executor?: IExecutorFormNormal,
  delegation?: boolean,
  description?: string,
  checkList?: ICheckListTypicalTask[],
  additionalFields?: IAdditionalFieldTypicalTask[],
  activity?: TypeFilterActivity
}> = ({
  idTaskEdited,
  isEditForm,
  isFormTasksOpen,
  isCreatingTypicalTask,
  setTaskFormOpen,
  setTaskFullFormOpen,
  dispatchCheckboxItem,
  setNormalFormData,
  createTypicalTask,
  updateTypicalTask,
  name,
  timeValue,
  timeType,
  executor,
  delegation,
  description,
  additionalFields,
  checkList,
  activity
}) => {
  const errorMessages = {
    required: errorValidationMessages.REQUIRED,
    maxTimeValue: errorValidationMessages.MAX4,
  };

  const [fields, setFields] = useState<any>();
  const [skipCheck, setSkipCheck] = useState<boolean>(true);
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [errorState, setErrorState] = useState<any>({
    name: '',
    timeValue: '',
    executor: ''
  });
  
  const collectData = (): object => {
    let dataObject = {
      idTaskEdited,
      name,
      timeValue,
      timeType,
      executor,
      delegation,
      description,
      checkList,
      fields,
      activity
    };

    return dataObject;
  };
  
  const onChangeFieldsPosition = (fields: IAdditionalFieldTypicalTask[]) => {
    setFields(fields);
  };
  
  const onChangeNameHandler = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    checkHelper('name', e.target.value);
    setNormalFormData({name: e.target.value});
  }

  const onChangeTimeDayHandler = (e) => {
    let inputValue = e.target.value;
    // проверка чтобы не было после запятой больше двух символов (меньше сотой части)
    const decimalPattern = /^(\d*\.?\d{0,1})?$/;

    if (decimalPattern.test(inputValue)) {
      if (inputValue.length >= 2 && inputValue[0] === "0" && inputValue[1] !== ".") {
        checkHelper('timeValue', inputValue.substring(1));
        setNormalFormData({timeValue: inputValue.substring(1)});
      } else {
        checkHelper('timeValue', inputValue);
        setNormalFormData({timeValue: inputValue});
      }  
    } 
  }

  const onChangeDescriptionHandler = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNormalFormData({description: e.target.value});
  }
  
  const onSubmitHandler = () => {
    const data = collectData();
    let isValid = checkHelper();
    
    if(isValid) {
      if(isEditForm) updateTypicalTask(data);
      else createTypicalTask(data); 
    }
  };

  const checkHelper = (type?: string, string?: string): boolean => {
    let isValid = true;

    // On change
    if(type === 'name' && !string?.length) {
      setErrorHelper('name', 'required')
      isValid = false;
    }
    if(type === 'timeValue' && (string && string?.toString().length > 4)) {
      setErrorHelper('timeValue', 'maxTimeValue')
      isValid = false;
    }
    if(type === 'executor' && !string?.length) {
      if(skipCheck) {
        setSkipCheck(false);
      }
      else {
        setErrorHelper('executor', 'required')
        isValid = false;
      }
    }

    // On submit
    if(!type && !name?.length) {
      setErrorHelper('name', 'required')
      isValid = false;
    }
    if(!type && (timeValue && timeValue?.toString().length > 4)) {
      setErrorHelper('timeValue', 'maxTimeValue')
      isValid = false;
    }
    if(!type && !executor?.label.length) {
      setErrorHelper('executor', 'required')
      isValid = false;
    }
    
    if(isValid) {
      setErrorState(state => {
        let newState = {...state};
        
        if(type) {
          return {
            ...newState,
            [type]: '' 
          };
        }
        
        return state;
      });
    }
    
    setIsFormValid(isValid);
    
    return isValid;
  };
  
  const setErrorHelper = (field, errorType) => {
    setErrorState(state => {
      const newState = {...state};

      if(field) {
        newState[field] = errorMessages[errorType];
      }

      return newState;
    });
  }
  
  const onCloseHandler = () => {
    setTaskFormOpen(!isFormTasksOpen);
    setTaskFullFormOpen(false);
  };
  
  useEffect(() => {
    checkHelper('executor', executor?.label);
  }, [executor]);
  
  return (
    <div className="normal-form container_add_typical_from">
      <div className="add_normal_form_options">
        <div className="normal-form__title-wr">
          {!isEditForm && (
            <h2 className="normal-form__title">Добавляем типовую задачу</h2>
          )}
          
          {isEditForm && (
            <h2 className="normal-form__title">
              Редактируем типовую задачу <span>"{name}"</span>
            </h2>
          )}

          <button className="normal-form__close"
                  onClick={onCloseHandler}
          >
            <CloseIcon />
          </button>
        </div>
        
        <h3 className="title_options">Базовые параметры</h3>
        
        <TextareaAutosize
          className={"name-typical-task" +
            (errorState.name?.length ? ' normal-form__input--error' : '')
          }
          placeholder="* Наименование типовой задачи"
          onChange={(e) => {
            onChangeNameHandler(e);
          }}
          value={name}
        />

        {errorState.name?.length > 0 && (
          <div className="normal-form__error-message">
            {errorState?.name}
          </div>
        )}
        
        <div className="time_task">
          <div className="name_time_task">
            <img src={calIcon} alt="" className="img_task_time" />
            <div>* Срок исполнения</div>
          </div>
          <input
            type="number"
            min='0.01'
            step='0.01'
            className={"input_time_day" +
                      (errorState.timeValue?.length || !timeValue ? ' normal-form__input--error' : '')}
            value={timeValue}
            onChange={(e) => {
              onChangeTimeDayHandler(e);
            }}
          />
          
          <div className="toogle">
            <TimeTaskToogle />
          </div>
        </div>

        {errorState.timeValue?.length > 0 && (
          <div className="normal-form__error-message">
            {errorState?.timeValue}
          </div>
        )}
        
        <div className="executor_add_task">
          <div className="name_executor_task">
            <img src={execIcon} alt="" className="img_task_time" />
            <div>* Исполнитель</div>
          </div>
          
          <div className={"select_executor" +
            (errorState.executor?.length ? ' normal-form__input--error' : '')
          }>
            <Executors disabled={false} checkedPersonalTask={false} />
          </div>
        </div>

        {errorState.executor?.length > 0 && (
          <div className="normal-form__error-message">
            {errorState?.executor}
          </div>
        )}
        
        <div className="description_task">
          <h3 className="title_options">Исполнение задачи</h3>
          
          <TextareaAutosize
            style={{ height: "60px" }}
            className={"typical-task-description"}
            placeholder="Описание задачи"
            onChange={(e) => {
              onChangeDescriptionHandler(e);
            }}
            value={description}
          />
          
          <div className="checkbox_typical_task">
            <CheckList
              action={(
                orderNumber,
                nameItem,
                checkboxValue,
                actionType,
              ) => {
                dispatchCheckboxItem(
                  orderNumber,
                  nameItem,
                  checkboxValue,
                  actionType
                );
              }}
              checkList={checkList}
              disabled={false}
            />
          </div>
        </div>
      </div>

      <div className="container_additional_fields">
        <div className="normal-form__additional-fields">
          <h3 className="normal-form__additional-fields-title">Состав дополнительных полей</h3>
          
          <div className="normal-form__additional-fields-content">
            <AdditionalFields
              data={additionalFields}
              onChangeFieldsPosition={onChangeFieldsPosition}
            />
          </div>
        </div>
      </div>

      {isEditForm && (
        <div className="normal-form__other">
          <OtherTypicalTask />
        </div>
      )}

      <div className="normal-form__btns">
        <button className={"normal-form__btn normal-form__btn--submit" +
               (isCreatingTypicalTask ? " normal-form__btn--show-preloader" : "")
              }
              onClick={onSubmitHandler}
              disabled={!timeValue}
        >
          <span className="normal-form__btn-title">
            {isEditForm && (
              <>Сохранить типовую задачу</>
            )}

            {!isEditForm && (
              <>Добавить типовую задачу</>
            )}
          </span>
          <span className="normal-form__btn-preloader">
            <PreloaderLocal />
          </span>
        </button>

        <button className="normal-form__btn normal-form__btn--cancel"
          onClick={onCloseHandler}
        >
          <span className="normal-form__btn-title">Отменить</span>
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = (state: State) => {
  return {
    isCreatingTypicalTask: state.typicalTasksPage.isCreatingTypicalTask,
    isFormTasksOpen: state.typicalTasksPage.isFormTasksOpen,
    isEditForm: state.typicalTasksPage.isEditForm,
    idTaskEdited: state.typicalTasksPage.normalForm.idTaskEdited,
    name: state.typicalTasksPage.normalForm.name,
    timeValue: state.typicalTasksPage.normalForm.timeValue,
    timeType: state.typicalTasksPage.normalForm.timeType,
    executor: state.typicalTasksPage.normalForm.executor,
    delegation: state.typicalTasksPage.normalForm.delegation,
    description: state.typicalTasksPage.normalForm.description,
    checkList: state.typicalTasksPage.normalForm.checkList,
    additionalFields: state.typicalTasksPage.normalForm.additionalFields,
    activity: state.typicalTasksPage.normalForm.activity
  };
};

const mapDispatchToProps = {
  setTaskFullFormOpen,
  setTaskFormOpen,
  dispatchCheckboxItem,
  setNormalFormData,
  createTypicalTask,
  updateTypicalTask
};

export default connect(mapStateToProps, mapDispatchToProps)(NormalForm);
