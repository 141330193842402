import React, { useEffect, useState } from "react";
import { State } from "../../../../../../../rootReducer";
import { connect } from "react-redux";
import TypicalTaskBp from "../../../ui/TypicalTaskBp/TypicalTaskBp";
import {
  flatten,
  returnBackElementToPosition,
  setActiveElement,
  setTaskPosition,
  unflatten
} from "../../../../../helpers/helpersCore";
import {
  INormalFormBPData,
  ITaskBP,
  ITaskConnectModal,
  ITaskDeleteConnectionModal,
  ITaskFlattenBP
} from "../../../../../interfaces";
import {
  checkActionAllowedCenter,
  getConnections,
  getTasksConnectionStatus
} from "../../../../../helpers/helpersLogicCenter";
import {
  setActionConnectIdTrigger, 
  setNormalFormBp,
  setTaskConnectModalBp,
  setTaskDeleteConnectionModalBp
} from "../../../../../actions";
import {
  taskBPPositions,
  taskBPTypes,
  taskConnectStatuses,
  taskConnectStatusesBetween
} from "../../../../../constants";
import {
  checkActionAllowedBetween,
  getConnectionsBetween,
  getTasksConnectionStatusBetween
} from "../../../../../helpers/helpersLogicBetween";
import { getConnectionsDelete, getTasksDeleteConnectionStatus } from "../../../../../helpers/helpersLogicDeleteBreak";

import './TypicalTasksBp.scss';

const TypicalTasksBp: React.FC<{
  tasksItems?: ITaskBP[],
  actionConnectIdTrigger?: number|null,
  actionDeleteConnectIdTrigger?: number|null,
  setTaskConnectModalBp: (data: ITaskConnectModal) => void,
  setTaskDeleteConnectionModalBp: (data: ITaskDeleteConnectionModal) => void,
  setActionConnectIdTrigger: (idTrigger: number|null) => void,
  setNormalFormBp: (data: INormalFormBPData) => void,
  tasksItemsUpdateTrigger?: null|string
}> = ({
  tasksItems,
  actionConnectIdTrigger,
  actionDeleteConnectIdTrigger,
  setTaskConnectModalBp,
  setTaskDeleteConnectionModalBp,
  setActionConnectIdTrigger,
  setNormalFormBp,
  tasksItemsUpdateTrigger
}) => {
  const [items, setItems] = useState<ITaskFlattenBP[]>([]);
  const [currentIndexLocal, setCurrentIndexLocal] = useState<number|null>(null);
  const [overIndexLocal, setOverIndexLocal] = useState<number|null>(null);
  const [needUpdateState, setNeedUpdateState] = useState<boolean>(false);
  
  const onDropBetweenHandler = (currentIndex: number, overIndex: number) => {
    let newState: any = [...items];
    const connectionStatus = getTasksConnectionStatusBetween(currentIndex, overIndex, newState);
    const actionAllowed = checkActionAllowedBetween(connectionStatus, currentIndex, overIndex, newState);
    const indexOffset = currentIndex < overIndex ? 1 : 0;

    setCurrentIndexLocal(currentIndex);
    setOverIndexLocal(overIndex);

    if(connectionStatus === taskConnectStatusesBetween.STATUS_ERROR) {
      // Вызов модалки
      setTaskConnectModalBp({connectionStatusTrigger: taskConnectStatusesBetween.STATUS_ERROR});
    }
    else {
      if(actionAllowed) {
        newState = getConnectionsBetween(connectionStatus, currentIndex, overIndex, newState);
      }
      else {
        setTaskConnectModalBp({connectionStatusTrigger: taskConnectStatuses.STATUS_RESTRICTED});
        newState = returnBackElementToPosition(currentIndex, newState);
      }
      
      newState = setTaskPosition(currentIndex, overIndex, taskBPPositions.HIDDEN, newState);
      newState = returnBackElementToPosition(overIndex - indexOffset, newState);

      setItems(newState);
    }
  }
  
  const onDropCenterHandler = (currentIndex: number, overIndex: number) => {
    const connectionStatus = getTasksConnectionStatus(currentIndex, overIndex, items);

    // Вызов модалки с выбором действий над задачей
    setTaskConnectModalBp({
      connectionStatusTrigger: connectionStatus,
      currentTask: items[currentIndex],
      overTask: items[overIndex]
    });

    setCurrentIndexLocal(currentIndex);
    setOverIndexLocal(overIndex);
  }
  
  const onStartHandler = (currentIndex: number) => {
    let res = setActiveElement(currentIndex, -1, items);
    setItems(res);
  }
  
  const onStopHandler = (currentIndex: number) => {
    let res = setActiveElement(currentIndex, -1, items);
    setItems(res);
  }
  
  const onMoveCenterHandler = (currentIndex: number, overIndex: number, action?: string) => {
    let res: any = null;
    
    if(action === 'reset') {
      res = setActiveElement(currentIndex, -1, items);
    }
    else {
      res = setActiveElement(currentIndex, overIndex, items);
      res = setTaskPosition(currentIndex, overIndex, taskBPPositions.HIDDEN, res);
    }
    
    setItems(res);
  }
  
  const onMoveBetweenHandler = (currentIndex: number, overIndex: number, partPosition: number) => {
    const connectionStatus = getTasksConnectionStatusBetween(currentIndex, overIndex, items);

    if(connectionStatus === taskConnectStatusesBetween.STATUS_5) {
      let res = setTaskPosition(currentIndex, overIndex, partPosition, items);
      setItems(res);
    }
  }
  
  const onDeleteHandler = (currentIndex: number, action: string) => {
    const connectionStatus = getTasksDeleteConnectionStatus(currentIndex, items, action);

    // Вызов модалки с выбором действий над задачей
    setTaskDeleteConnectionModalBp({
      connectionStatusTrigger: connectionStatus
    });

    setCurrentIndexLocal(currentIndex);
  }

  useEffect(() => {
    if(tasksItems?.length && !items.length) {
      let result = flatten(tasksItems);
      setItems(result);
    }
  }, [tasksItems]);

  useEffect(() => {
    if(items.length) {
      if(needUpdateState) {
        const stateItems = unflatten(items);

        setNormalFormBp({tasksItems: stateItems});
      }
      
      setNeedUpdateState(true);
    }
  }, [items]);

  useEffect(() => {
    if(tasksItems?.length) {
      let result = flatten(tasksItems);
      setItems(result);
    }
  }, [tasksItemsUpdateTrigger]);

  // После выбора действия над задачей в модалке - связи
  useEffect(() => {
    if(typeof actionConnectIdTrigger === 'number') {
      const actionAllowed = checkActionAllowedCenter(actionConnectIdTrigger, currentIndexLocal, overIndexLocal, items);
      
      if(actionConnectIdTrigger > 0 && actionAllowed) {
        const newState = getConnections(actionConnectIdTrigger, currentIndexLocal, overIndexLocal, items);
        
        setItems(newState);
      }
      else {
        // Возврат в исходную позицию
        setItems((state) => {
          let newState = [...state];
          
          if(currentIndexLocal) {
            newState = returnBackElementToPosition(currentIndexLocal, newState);
          }
          
          return newState;
        });
      }
      
      if(!actionAllowed) {
        setTaskConnectModalBp({connectionStatusTrigger: taskConnectStatuses.STATUS_RESTRICTED});
      }
      
      setActionConnectIdTrigger(null);
    }
  }, [actionConnectIdTrigger]);

  // После выбора действия над задачей в модалке - удаление и разрыв связей
  useEffect(() => {
    if(typeof actionDeleteConnectIdTrigger === 'number') {
      if(actionDeleteConnectIdTrigger > 0) {
        const newState = getConnectionsDelete(actionDeleteConnectIdTrigger, currentIndexLocal, items);
        
        setItems([...newState]);
        
        if(!newState.length) {
          setNormalFormBp({tasksItems: []});
        }
      }
      
      setTaskDeleteConnectionModalBp({connectionActionTrigger: null});
    }
  }, [actionDeleteConnectIdTrigger]);
  
  return (
    <div className='typical-tasks-bp'>
      <div className="typical-tasks-bp__items-header">
        <div className="typical-tasks-bp__items-header-col">Id</div>
        <div className="typical-tasks-bp__items-header-col">Типовая задача</div>
        <div className="typical-tasks-bp__items-header-col">Срок исполнения</div>
        <div className="typical-tasks-bp__items-header-col"></div>
      </div>
      
      <div className="typical-tasks-bp__items">
        {!!items?.length && items.map((item, i) => (
          <React.Fragment key={item.id}>
            <TypicalTaskBp
              name={item.title}
              currentIndex={i}
              isParent={item.isParent}
              isLastParent={item.isLastParent}
              controlledPosition={item?.controlledPosition ?? undefined}
              connectionAmount={item.connectionAmount ?? 0}
              className={
                (!item.isParent ? 'typical-task-bp--child' : 'typical-task-bp--parent') +
                (item.isActive ? ' typical-task-bp--active' : '') +
                (item.taskType === taskBPTypes.SERIAL ? ' typical-task-bp--serial' : '') +
                (item.taskType === taskBPTypes.PARALLEL ? ' typical-task-bp--parallel' : '') +
                (!item.isParent && (items[i - 1]?.isParent && (items[i + 1]?.isParent || !items[i + 1])) ? ' typical-task-bp--one-child' : '') +
                (!item.isParent && (!items[i - 1]?.isParent && items[i + 1]?.isParent) ? ' typical-task-bp--last-child' : '') +
                (item?.showPosition ? (item.showPosition === taskBPPositions.PARENT)
                      ? ' typical-task-bp--show-position-parent'
                      : ' typical-task-bp--show-position-child'
                    : ''
                ) +
                (item.isParent && (items[i + 1] && !items[i + 1]?.isParent) ? ' typical-task-bp--parent-has-child' : '')
              }
              data={item}
              onStart={onStartHandler}
              onStop={onStopHandler}
              onDropCenter={onDropCenterHandler}
              onMoveCenter={onMoveCenterHandler}
              onDropBetween={onDropBetweenHandler}
              onMoveBetween={onMoveBetweenHandler}
              onDelete={onDeleteHandler}
            />
          </React.Fragment>
        ))}
      </div>

      {!items?.length && (
        <div className="typical-tasks-bp__default-message">Нет добавленных задач...</div>
      )}
    </div>
  )
}

const mapStateToProps = (state: State) => {
  return {
    tasksItems: state.businessPage.normalFormBP.tasksItems,
    actionConnectIdTrigger: state.businessPage.normalFormBP.actionConnectIdTrigger,
    actionDeleteConnectIdTrigger: state.businessPage.taskDeleteConnectionModal.connectionActionTrigger,
    tasksItemsUpdateTrigger: state.businessPage.normalFormBP.tasksItemsUpdateTrigger
  };
};

const mapDispatchToProps = {
  setTaskConnectModalBp,
  setActionConnectIdTrigger,
  setTaskDeleteConnectionModalBp,
  setNormalFormBp
};

export default connect(mapStateToProps, mapDispatchToProps)(TypicalTasksBp);
