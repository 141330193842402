import { Reducer } from "redux";

import {
  TreatyPageActions,
  TreatyPageActionTypes,
  ITreatyPageState,
} from "./action_types";
import { TabIdsExtraFormTreaty, TypicalTreatyActual } from "./interfaces";
import { TypicalTreatiesActivity, TypicalTreatiesPersonal } from "./interfaces";
import { sortConditions, sortOrders } from "./constants";

const initialState = {
  mainTreaty: [],
  treaties: [],
  isEditForm: false,
  isFormTreatyOpen: false,
  isFullFormTreatyOpen: false,
  extraForm: {
    treatyBp: [],
    currentPageBpListTreaty: 1,
    isLoading: false,
    activeTabIndex: TabIdsExtraFormTreaty.BPS_TAB,
    isExtraFormTreatyDefaultOpen: false,
    isTasksAndBpOpen: false,
    isEditStageOpen: false,
    isCreateStageOpen: false,
    searchTypicalBps: "",
    isEditStage: {
      id: "",
      nameStage: "",
    },
    treatyForFilter: { value: "all", label: "Не важно" },
  },
  isLoadingTreaty: false,
  isLoadingTreatyCard: false,
  deleteStageTreatyModal: {
    stageId: "",
    isDeleteModalOpen: false,
    isShowPreloader: false,
    statusFromServer: {
      constraints: [],
    },
  },
  deleteStageItemTreatyModal: {
    stageId: "",
    itemId: "",
    isDeleteModalOpen: false,
    isShowPreloader: false,
    statusFromServer: {
      constraints: [],
    },
  },
  normalForm: {
    main: false,
    id: "",
    author: null,
    createdAt: "",
    activity: TypicalTreatiesActivity.ACTIVE_STATUS,
    idTreatyEdit: "",
    name: "",
    description: "",
    selectedItemId: "",
    stages: [],
  },
  filters: {
    activity: TypicalTreatiesActivity.ACTIVE_STATUS,
    personal: TypicalTreatiesPersonal.NOT_PERSONAL_STATUS,
  },
  currentPage: 1,
  sortCondition: {
    columnId: sortConditions.CREATED_AT,
    value: sortOrders.ASC,
  },
  searchTt: "",
  deleteTypicalTreatiesModal: {
    TtId: "",
    isDeleteModalOpen: false,
    isShowPreloader: false,
    statusFromServer: {
      constraints: [],
    },
  },
  typicalTreatiesMeta: null,
  isSavingTreaty: false,
} as ITreatyPageState;

export type TreatyPageState = typeof initialState;

const reducer: Reducer<TreatyPageState, TreatyPageActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case TreatyPageActionTypes.SET_MAIN_TREATIES:
      return {
        ...state,
        mainTreaty: action.mainTreaty,
      };

    case TreatyPageActionTypes.SET_TREATIES:
      if (Array.isArray(action.treaties)) {
        if (action.action === "add") {
          return {
            ...state,
            treaties: [...state.treaties, ...action.treaties],
          };
        }

        return {
          ...state,
          treaties: action.treaties,
        };
      } else {
        return {
          ...state,
          treaties: [action.treaties, ...state.treaties],
        };
      }

    case TreatyPageActionTypes.SET_NORMAL_FORM_DATA:
      return {
        ...state,
        normalForm: {
          ...state.normalForm,
          ...action.normalFormData,
        },
      };

    case TreatyPageActionTypes.SET_IS_EDIT_FORM_TREATY: {
      return {
        ...state,
        isEditForm: action.isEditForm,
      };
    }

    case TreatyPageActionTypes.SET_IS_FORM_TREATY:
      return {
        ...state,
        isFormTreatyOpen: action.isFormTreatyOpen,
      };

    case TreatyPageActionTypes.SET_IS_FULL_FORM_TREATY:
      return {
        ...state,
        isFullFormTreatyOpen: action.isFullFormTreatyOpen,
      };

    case TreatyPageActionTypes.SET_FORM_DEFAULT_VALUES:
      return {
        ...initialState,
      };

    case TreatyPageActionTypes.SET_EXTRA_FORM_TREATY_DEFAULT_OPEN:
      return {
        ...state,
        extraForm: {
          ...state.extraForm,
          isExtraFormTreatyDefaultOpen: action.isExtraFormTreatyDefaultOpen,
        },
      };

    case TreatyPageActionTypes.SET_LOADING_TREATY:
      return {
        ...state,
        isLoadingTreaty: action.isLoadingTreaty,
      };

    case TreatyPageActionTypes.SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.currentPage,
      };

    case TreatyPageActionTypes.SET_TYPICAL_TREATIES_FILTER_ACTIVITY:
      return {
        ...state,
        filters: {
          ...state.filters,
          activity: action.activity,
        },
      };

    case TreatyPageActionTypes.SET_TYPICAL_TREATIES_FILTER_PERSONAL:
      return {
        ...state,
        filters: {
          ...state.filters,
          personal: action.personal,
        },
      };

    case TreatyPageActionTypes.SET_SORT_CONDITION:
      return {
        ...state,
        sortCondition: {
          ...state.sortCondition,
          ...action.sortCondition,
        },
      };

    case TreatyPageActionTypes.SET_SEARCH_TRATIES:
      return {
        ...state,
        searchTt: action.searchTt,
      };
    case TreatyPageActionTypes.SET_LOADING_TREATY_CARD:
      return {
        ...state,
        isLoadingTreatyCard: action.isLoadingTreatyCard,
      };

    case TreatyPageActionTypes.SET_EXTRA_FORM_STAGE_DATA:
      return {
        ...state,
        extraForm: {
          ...state.extraForm,
          isEditStage: {
            ...state.extraForm.isEditStage,
            ...action.stageData,
          },
        },
      };

    case TreatyPageActionTypes.SET_EXTRA_FORM_OPEN_TASKS_BP_FORM:
      return {
        ...state,
        extraForm: {
          ...state.extraForm,
          isTasksAndBpOpen: action.isTasksAndBpOpen,
        },
      };

    case TreatyPageActionTypes.SET_CURRENT_PAGE_BP_LIST_TREATY:
      return {
        ...state,
        extraForm: {
          ...state.extraForm,
          currentPageBpListTreaty: action.currentPageBpList,
        },
      };

    case TreatyPageActionTypes.SET_EXTRA_FORM_OPEN_STAGE_EDIT_FORM:
      return {
        ...state,
        extraForm: {
          ...state.extraForm,
          isEditStageOpen: action.isEditStageOpen,
        },
      };

    case TreatyPageActionTypes.SET_EXTRA_FORM_OPEN_STAGE_CREATE_FORM:
      return {
        ...state,
        extraForm: {
          ...state.extraForm,
          isCreateStageOpen: action.isCreateStageOpen,
        },
      };

    case TreatyPageActionTypes.SET_LOADING_TREATY_CARD:
      return {
        ...state,
        isLoadingTreatyCard: action.isLoadingTreatyCard,
      };

    case TreatyPageActionTypes.SET_TT_META:
      return {
        ...state,
        typicalTreatiesMeta: action.typicalTreatiesMeta,
      };

    case TreatyPageActionTypes.SET_DELETE_MODAL_TREATY:
      return {
        ...state,
        deleteTypicalTreatiesModal: {
          ...state.deleteTypicalTreatiesModal,
          ...action.data,
        },
      };

    case TreatyPageActionTypes.DELETE_TYPICAL_TREATIES_FROM_STATE:
      return {
        ...state,
        treaties: state.treaties.filter((treaty) => treaty.id !== action.id),
      };

    case TreatyPageActionTypes.UPDATE_TREATY_STAGE:
      const updatedStages = state.normalForm.stages.map((stage) => {
        if (stage.id === action.id) {
          return {
            ...stage,
            name: action.name,
          };
        } else {
          return stage;
        }
      });
      return {
        ...state,
        normalForm: {
          ...state.normalForm,
          stages: updatedStages,
        },
      };

    case TreatyPageActionTypes.DELETE_STAGE_STORE:
      const deleteStage = state.normalForm.stages.filter(
        (stage) => stage.id !== action.id
      );

      return {
        ...state,
        normalForm: {
          ...state.normalForm,
          stages: deleteStage,
        },
      };

    case TreatyPageActionTypes.SET_DELETE_STAGE_MODAL_TREATY:
      return {
        ...state,
        deleteStageTreatyModal: {
          ...state.deleteStageTreatyModal,
          ...action.data,
        },
      };

    case TreatyPageActionTypes.SET_DELETE_ITEM_STAGE_MODAL_TREATY:
      return {
        ...state,
        deleteStageItemTreatyModal: {
          ...state.deleteStageItemTreatyModal,
          ...action.data,
        },
      };

    case TreatyPageActionTypes.ADD_STAGE:
      const newStage = {
        id: action.data.id,
        name: action.data.name,
        isNew: action.data.isNew
      };

      return {
        ...state,
        normalForm: {
          ...state.normalForm,
          stages: [...state.normalForm.stages, newStage],
        },
      };

    case TreatyPageActionTypes.SET_TYPICAL_TREATY_ACTUAL:
      return {
        ...state,
        normalForm: {
          ...state.normalForm,
          stages: state.normalForm.stages.map((stage) => {
            if (stage.id == action.stageId) {
              return {
                ...stage,
                status: action.isActual,
              };
            }
            return stage;
          }),
        },
      };

    case TreatyPageActionTypes.SET_BP_LIST_TREATY:
      if (Array.isArray(action.bpList)) {
        if (action.action === "add") {
          return {
            ...state,
            extraForm: {
              ...state.extraForm,
              treatyBp: [...state.extraForm.treatyBp, ...action.bpList],
            },
          };
        }
        return {
          ...state,
          extraForm: {
            ...state.extraForm,
            treatyBp: action.bpList,
          },
        };
      } else {
        return {
          ...state,
          extraForm: {
            ...state.extraForm,
            treatyBp: [action.bpList, ...state.extraForm.treatyBp],
          },
        };
      }

    case TreatyPageActionTypes.SET_LOADING_EXTRA_FORM_TREATY:
      return {
        ...state,
        extraForm: {
          ...state.extraForm,
          isLoading: action.isLoading,
        },
      };

    case TreatyPageActionTypes.SET_MAIN_BOOL:
      return {
        ...state,
        normalForm: {
          ...state.normalForm,
          main: action.isMain,
        },
      };

    case TreatyPageActionTypes.SET_EXTRA_FORM_ACTIVE_TAB_INDEX: {
      return {
        ...state,
        extraForm: {
          ...state.extraForm,
          activeTabIndex: action.activeTabIndex,
        },
      };
    }

    case TreatyPageActionTypes.SET_STAGE_ID_TREATY:
      return {
        ...state,
        normalForm: {
          ...state.normalForm,
          selectedItemId: action.id,
        },
      };
    case TreatyPageActionTypes.SET_TASK_ITEMS_STAGE_TREATY:
      const { selectedItemId } = state.normalForm;
      // Находим нужный этап (stage) по stageId
      const updatedTreatyStages = state.normalForm.stages?.map((stage) =>
        stage.id === selectedItemId
          ? {
              ...stage,
              item_list: [...(stage.item_list || []), action.itemAdd],
            }
          : stage
      );
      return {
        ...state,
        normalForm: {
          ...state.normalForm,
          stages: updatedTreatyStages,
        },
      };

    case TreatyPageActionTypes.SET_SEARCH_BPS:
      return {
        ...state,
        extraForm: {
          ...state.extraForm,
          searchTypicalBps: action.searchTypicalBps,
        },
      };

    case TreatyPageActionTypes.DELETE_ITEM_FROM_STAGES:
      const updatedStagesItems = state.normalForm.stages.map((stage) => {
        if (stage.id === action.stageId) {
          const updatedItems = (stage.item_list || []).filter(
            (item) => item.id !== action.itemId
          );
          return {
            ...stage,
            item_list: updatedItems,
          };
        }
        return stage;
      });
      return {
        ...state,
        normalForm: {
          ...state.normalForm,
          stages: updatedStagesItems,
        },
      };

    case TreatyPageActionTypes.UPDATE_TREATY_STORE:
      return { ...state, mainTreaty: action.data };

    case TreatyPageActionTypes.UPDATE_TYPICAL_TREATY_STORE:
      const newTasks = [...state.treaties];
      const updateIndex = state.treaties.findIndex(
        (item) => item.id === action.data.id
      );

      if (updateIndex === -1) {
        return state;
      }

      newTasks[updateIndex] = action.data;

      return { ...state, treaties: newTasks };

    case TreatyPageActionTypes.SET_TREATY_FORM_FILTER:
      return {
          ...state,
          extraForm: {
            ...state.extraForm,
            treatyForFilter: action.treatyForFilter,
          },
        };
    case TreatyPageActionTypes.SET_SAVING_TREATY: {
      return {
          ...state,
          isSavingTreaty: action.isSavingTreaty
          }
        }
    default:
      return state;
  }
};

export default reducer;
