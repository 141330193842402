import React from "react";
import { State } from "../../../../rootReducer";
import { connect } from "react-redux";
import PreloaderLocal from "../../../../common/components/PreloaderLocal/PreloaderLocal";
import NormalFormBP from "./NormalFormBP/NormalFormBP";
import ExtraFormBP from "./ExtraFormBP/ExtraFormBP";

import "./FormBP.scss";

const FormBP: React.FC<{
  isFormBPOpen: boolean;
  isFullFormBPOpen: boolean;
  isLoadingBpCard: boolean;
}> = ({
  isFormBPOpen,
  isFullFormBPOpen,
  isLoadingBpCard
}) => {

  return (
    <div
      className={
        "form-bp" +
        (isFormBPOpen ? " form-bp--open" : "") +
        (isFullFormBPOpen ? " form-bp--open-full" : "") +
        (isLoadingBpCard ? " form-bp--show-preloader" : "")
      }>
      <div className="form-bp__content">
        <div className="form-bp__left">
          <div className="form-bp__preloader">
            <PreloaderLocal size="big" />
          </div>
          
          <NormalFormBP />
        </div>

        <div className="form-bp__right">
          <div className="form-bp__preloader">
            <PreloaderLocal />
          </div>
          
          <ExtraFormBP />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: State) => {
  return {
    isFormBPOpen: state.businessPage.isFormBPOpen,
    isFullFormBPOpen: state.businessPage.isFullFormBPOpen,
    isLoadingBpCard: state.businessPage.isLoadingBpCard
  };
};

const mapDispatchToProps = {
  
};

export default connect(mapStateToProps, mapDispatchToProps)(FormBP);
