import { cookieMaster } from './CookieMaster';
import { urlApp } from '../App';
import { responseHandler, statusCheck } from '../responseHandler';
import { defaultHeaders } from "../common/constants";

type Headers = {
  [key: string]: string;
};

let company_id = localStorage.getItem("company_id");

export class FetchData {
  private headers: Headers = {
    "X-Requested-With": "XMLHttpRequest",
    "Content-Type": "application/json",
    Authorization: `${cookieMaster.getCookie("access_token")}`,
    "company-id": `${company_id}`,
    [defaultHeaders.FROM_WEB.TITLE]: defaultHeaders.FROM_WEB.VALUE
  };
  public responseHandlerMethod: string = "json";

  private checkHeaders(headers: Headers | {}) {
    if (Object.keys(headers).length > 0) {
      return headers;
    }
    return this.headers;
  }

  private responseHandlerMethodCheck(responseHandlerMethod: string) {
    if (responseHandlerMethod) {
      return responseHandlerMethod;
    }

    return this.responseHandlerMethod;
  }

  private async makeRequest(
    url: string,
    method: string,
    body: any,
    headers: Headers | string = this.headers,
    responseHandlerMethod: string = this.responseHandlerMethod,
    abortController = new AbortController(),
    urlAppOption?: string
  ): Promise<any | undefined> {
    const { signal } = abortController || {};

    try {
      const res = await fetch(`${urlAppOption ? urlAppOption : urlApp}${url}`, {
        signal: signal,
        method,
        headers: this.checkHeaders(headers),
        body,
      });

      if(url.includes('/api/v1/tasks') && method === 'DELETE') {
        return {status: res.status};
      }
      
      if(url.includes('/api/v1/typical-tasks') && method === 'DELETE') {
        return {status: res.status};
      }

      if(url.includes('/api/v1/projects') && method === 'DELETE') {
        return {status: res.status};
      }

      const res_1 = await statusCheck(
        res,
        this.responseHandlerMethodCheck(responseHandlerMethod)
      );

      return responseHandler(res_1, url, res.status);
    } catch (error) {
      console.error(error);
    }
  }

  private async makeRequestFull(
    url: string,
    method: string,
    body: any,
    responseHandlerMethod: string = this.responseHandlerMethod,
    abortController = new AbortController(),
    urlAppOption?: string
  ): Promise<any | undefined> {
    const { signal } = abortController || {};

    try {
      const res = await fetch(`${urlAppOption ? urlAppOption : urlApp}${url}`, {
        signal: signal,
        method,
        headers: this.checkHeaders(this.headers),
        body,
      });
      const responseHandled = await statusCheck(
        res,
        this.responseHandlerMethodCheck(responseHandlerMethod)
      );

      return {
        status: res.status,
        ...responseHandled
      }
    }
    catch (error) {
      console.error(error);
    }
  }

  public get = (
    url: string,
    headers?: Headers | string,
    responseHandlerMethod?: string,
    abortController?: any
  ) => {
    if(headers === 'returnFullResponse') {
      return this.makeRequestFull(url, 'GET', null, responseHandlerMethod);
    }
    
    return this.makeRequest(
      url,
      "GET",
      null,
      headers,
      responseHandlerMethod,
      abortController
    );
  };

  public post = (
    url: string,
    body?: any,
    headers?: Headers | string,
    responseHandlerMethod?: string
  ) => {
    if(headers === 'returnFullResponse') {
      return this.makeRequestFull(url, "POST", body, responseHandlerMethod);
    }
    return this.makeRequest.call(
      this,
      url,
      "POST",
      body,
      headers,
      responseHandlerMethod
    );
  };

  public patch = (
    url: string,
    body: any,
    urlAppOption?: string,
    headers?: Headers,
    responseHandlerMethod?: string,
  ) => {
    if(urlAppOption === 'returnFullResponse') {
      return this.makeRequestFull(url, "PATCH", body, responseHandlerMethod);
    }
    return this.makeRequest(url, "PATCH", body, headers, responseHandlerMethod, undefined, urlAppOption);
  };

  public put = (
    url: string,
    body: any,
    headers?: Headers,
    responseHandlerMethod?: string
  ) => {
    return this.makeRequest(url, "PUT", body, headers, responseHandlerMethod);
  };

  public delete = (
    url: string,
    body?: any,
    urlAppOption?: string, 
    headers?: Headers,
    responseHandlerMethod?: string
  ) => {
    return this.makeRequest(
      url,
      "DELETE",
      body,
      headers,
      responseHandlerMethod,
      undefined,
      urlAppOption
    );
  };

  public addHeaders(headers: Headers): void {
    this.headers = {
      ...this.headers,
      ...headers,
    };
  }
}

export const fetchData = new FetchData();
