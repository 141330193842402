import React, { FC } from "react";
import {ReactComponent as CloseIcon} from '../../../../../../../img/TrueIcons/close.svg';

import './ArrayTypicalTaskItem.scss';

const ArrayTypicalTaskItem: FC<{
  id: string,
  value: string|number,
  deleteHandler: (id: string) => void
}> = ({
  id,
  value, 
  deleteHandler
}) => {
  return (
    <div className='array-typical-task-item'>
      <div className="array-typical-task-item__value">
        {value}
      </div>
      
      <div className="array-typical-task-item__actions">
        <button className="array-typical-task-item__btn array-typical-task-item__btn--delete"
                onClick={() => deleteHandler(id)}
        >
          <CloseIcon /> 
        </button>
      </div>
    </div>
  )
}

export default ArrayTypicalTaskItem;
