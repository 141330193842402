import React, { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import ArrayTypicalTaskItem from "./components/ArrayTypicalTaskItem";
import { connect } from "react-redux";
import { State } from "../../../../../../rootReducer";
import {
  deleteExtraFormArrayValues,
  setExtraFormArrayValues,
  setExtraFormSelectTabData
} from "../../../../actions";
import { TArrayValues } from "../../../../action_types";
import { errorValidationMessages } from "../../../../../ObjectsPage/constants";

import './ArrayTypicalTask.scss';

const ArrayTypicalTask: FC<{
  selectedTabName: string,
  arrayValues: TArrayValues[],
  setExtraFormArrayValues: (selectedTabName: string, value: string|number) => void,
  deleteExtraFormArrayValues: (selectedTabName: string, id: string|number) => void,
  checkTrigger: string,
  onCheck: (isValid: boolean, type: string) => void
}> = ({ 
  selectedTabName,
  arrayValues,
  setExtraFormArrayValues,
  deleteExtraFormArrayValues,
  checkTrigger,
  onCheck
}) => {
  const errorMessages = {
    min: errorValidationMessages.MIN1
  };
  
  const tableRows = useRef<HTMLDivElement>(null);
  const [value, setValue] = useState<string>('');
  const [skipCheck, setSkipCheck] = useState<boolean>(true);
  const [errorState, setErrorState] = useState<string>('');

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const addHandler = () => {
    if(!value.length) return;

    const valueLocal = value.trim();
    
    setExtraFormArrayValues(selectedTabName, valueLocal);
    setValue('');
    checkHelper(valueLocal);

    setTimeout(() => {
      tableRows.current?.scrollTo({
        top: tableRows.current?.scrollHeight,
        left: 0,
        behavior: 'smooth'
      });
    }, 100);
  }

  const deleteHandler = ( id: string ) => {
    deleteExtraFormArrayValues(selectedTabName, id);
  }

  const checkHelper = ( value?: string ) => {
    let isValid = true;

    setErrorState('');

    // console.log('value', value);

    if(value) return;

    if(skipCheck) {
      setSkipCheck(false);
      return;
    }
    
    if (!arrayValues.length) {
      setErrorState(errorMessages[ 'min' ]);
      isValid = false;
    }
    
    if(value?.length) isValid = true;

    if ( onCheck ) {
      onCheck(isValid, 'array');
    }
  };

  useEffect(() => {
    checkHelper();
  }, [checkTrigger]);
  
  return (
    <div className='array-typical-task'>
      <div className="array-typical-task__title">Массив возможных значений</div>

      <div className="array-typical-task__table">
        <div className="array-typical-task__table-header">Значение</div>

        {arrayValues.length > 0 && (
          <div className="array-typical-task__table-rows" ref={tableRows}>
            {
              arrayValues.map(( el ) => (
                <div className="array-typical-task__table-row" key={el.id}>
                  <ArrayTypicalTaskItem
                    id={el.id}
                    value={el.value}
                    deleteHandler={deleteHandler}
                  />
                </div>
              ))
            }
          </div>
        )}

        {!arrayValues.length && (
          <div className="array-typical-task__table-no-values">Нет добавленных значений...</div>
        )}
      </div>

      <div className="array-typical-task__add-wr">
        <div className="array-typical-task__add-left">
          <input type="text"
                 className={"array-typical-task__input" +
                   (errorState ? ' array-typical-task__input--error' : '')
                 }
                 placeholder='Введите значение...'
                 onChange={onInputChange}
                 value={value}
          />
        </div>
        <div className="array-typical-task__add-right">
          <button className="array-typical-task__input-btn"
                  onClick={addHandler}
          >
            Добавить
          </button>
        </div>
      </div>

      {errorState && (
        <div className="array-typical-task__error-message">
          {errorState}
        </div>
      )}
    </div>
  )
}

const mapStateToProps = (state: State) => {
  return {
    checkTrigger: state.typicalTasksPage.extraForm.checkTrigger
  }
};

const mapDispatchToProps = {
  setExtraFormSelectTabData,
  setExtraFormArrayValues,
  deleteExtraFormArrayValues
};

export default connect(mapStateToProps, mapDispatchToProps)(ArrayTypicalTask);
