import React, { FC, useEffect, useState } from "react";
import { connect } from "react-redux";
import { State } from "../../../../../rootReducer";
import { IEditingObjectsFormData } from "../../../interfaces";
import { errorValidationMessages } from "../../../constants";

import './TextAreaObjects.scss';

const TextAreaObjects: FC<{
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void,
  value?: string,
  checkTrigger?: string,
  name?: keyof IEditingObjectsFormData
}> = ({
  onChange,
  value,
  checkTrigger,
  name,
}) => {
  const errorMessages = {
    max: errorValidationMessages.MAX500,
    required: errorValidationMessages.REQUIRED
  };
  const rules: any = {
    title: [{required: true}, {max: 500}]
  };

  const [skipCheck, setSkipCheck] = useState<boolean>(true);
  const [errorState, setErrorState] = useState<string[]>([]);
  
  const checkHelper = () => {
    if(skipCheck) {
      setSkipCheck(false);
      return;
    }

    setErrorState([]);

    if(name) {
      if(rules[name]) {
        rules[name].forEach(rule => {
          if(rule['required'] && !value?.length) {
            setErrorState((state) => ([ ...state, errorMessages[ 'required' ]]));
          }

          if(rule['max']) {
            if(value && (value?.length > rule.max)) {
              setErrorState((state) => ([...state, errorMessages[ 'max' ]]));
            }
          }
        });
      }
    }
  };
  
  const onChangeLocal = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange(e);
  }

  useEffect(() => {
    checkHelper();
  }, [checkTrigger, value]);
  
  return ( 
    <div className={'textarea-objects' +
                    (errorState.length ? ' textarea-objects--error' : '')
    }>
      <textarea className="textarea-objects__input" 
             placeholder='Введите значение...'
             value={value}
             onChange={onChangeLocal}
      ></textarea>

      {errorState.length > 0 && (
        <div className="input-objects__error-messages">
          {errorState.map((error, i) => (
            <div className="input-objects__error-message" key={i}>
              {error}
            </div>
          ))}
        </div>
      )}
    </div>
  )  
}
const mapStateToProps = (state: State) => {
  return {
    checkTrigger: state.objectsPage.editingObjectsForm.checkTrigger
  }
}

const mapDispatchToProps = {
  
}

export default connect(mapStateToProps, mapDispatchToProps)(TextAreaObjects);
