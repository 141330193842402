import { taskBPPositions, taskBPTypes, taskConnectStatusesBetween } from "../constants";
import { ITaskFlattenBP } from "../interfaces";
import {
  arrayMove, 
  childToParent,
  flatten,
  getIndexOffset, 
  setTaskPosition,
  syncConnectAmount, 
  syncParentIndex,
  unflatten
} from "./helpersCore";

export const getTasksConnectionStatusBetween = (currentIndex, overIndex, items) => {
  return checkConnectionStatusBetween(currentIndex, overIndex, items) ?? taskConnectStatusesBetween.STATUS_ERROR;
}

export const getConnectionsBetween = (
  connectionStatus: number, 
  currentIndex: number, 
  overIndex: number, 
  items: ITaskFlattenBP[]
): ITaskFlattenBP[] => {
  let result: any = null;
  
  if(connectionStatus === taskConnectStatusesBetween.STATUS_1) {
    result = getConnectionsBetweenStatus_1(currentIndex, overIndex, items);    
  }
  
  if(connectionStatus === taskConnectStatusesBetween.STATUS_3) {
    result = getConnectionsBetweenStatus_3(currentIndex, overIndex, items);    
  }
  
  if(connectionStatus === taskConnectStatusesBetween.STATUS_5) {
    result = getConnectionsBetweenStatus_5(currentIndex, overIndex, items);    
  }
  
  return result ?? items;
}

const getConnectionsBetweenStatus_1 = (
  currentIndex: number, 
  overIndex: number, 
  items: ITaskFlattenBP[]
): ITaskFlattenBP[] => {

  let result: any = [];
  const indexOffset = getIndexOffset(currentIndex, overIndex);
  const parentTo = getUpParent(overIndex, items);
  const parentIndexTo = parentTo?.parentIndex;
  
  const parentFrom = items[currentIndex];
  const parentIndexFrom = parentFrom.parentIndex;
  
  const newConnectionType = parentTo?.taskType;

  if(newConnectionType) {
    parentFrom.taskType = newConnectionType;
  }

  let from: any = null;
  let to: any = null;

  result = unflatten(items);

  result.forEach(el => {
    if(el.parentIndex === parentIndexFrom) from = el.parentIndex;
    if(el.parentIndex === parentIndexTo) to = el.parentIndex;
  });
  
  if(Number.isInteger(from) && Number.isInteger(to)) {
    result = arrayMove(result, from, to + indexOffset);
    result = syncParentIndex(result);
  }

  result = flatten(result);

  return result ?? items;
}

const getConnectionsBetweenStatus_3 = (
  currentIndex: number,
  overIndex: number,
  items: ITaskFlattenBP[]
): ITaskFlattenBP[] => {
  let result: any = null;
  const indexOffset = currentIndex < overIndex ? 1 : 0; // Перетаскиваем сверху вниз - 1
  const parentTo = items[ overIndex - 1 ];
  const parentFrom = items[currentIndex];
  let newConnectionType = parentTo?.taskType;

  if(parentTo.isParent) newConnectionType = taskBPTypes.PARALLEL;
  
  if(newConnectionType) {
    parentFrom.taskType = newConnectionType;
  }
  
  items[currentIndex].isParent = false;
  
  result = arrayMove(items, currentIndex, overIndex - indexOffset);
  
  result[overIndex - indexOffset].parentIndex = result[(overIndex - indexOffset) - 1].parentIndex;
  
  result = syncConnectAmount(result);

  return result ?? items;
}

const getConnectionsBetweenStatus_5 = (
  currentIndex: number,
  overIndex: number,
  items: ITaskFlattenBP[]
): ITaskFlattenBP[] => {
  let result: any = null;
  const position = items[currentIndex]?.showPosition ?? taskBPPositions.PARENT;

  result = setTaskPosition(currentIndex, overIndex, taskBPPositions.HIDDEN, items);

  if(position === taskBPPositions.PARENT) {
    // Для ребенка
    if(!items[currentIndex].isParent) {
      const indexOffset = currentIndex < overIndex ? 0 : 1; // Перетаскиваем сверху вниз - 0

      result = childToParent(items, currentIndex, overIndex - indexOffset);
    }
    // Для родителя
    else {
      result = getConnectionsBetweenStatus_1(currentIndex, overIndex, items);
    }
  }
  
  if(position === taskBPPositions.CHILD) {
    result = getConnectionsBetweenStatus_3(currentIndex, overIndex, items);
  }

  return result ?? items;
}

export const checkActionAllowedBetween = (status, currentIndex, overIndex, items): boolean => {
  let isAllowed = false;

  if(actionParentWithChildsBetweenRestricted(status, currentIndex, overIndex, items)) isAllowed = true;

  return isAllowed;
}

const checkConnectionStatusBetween = (currentIndex, overIndex, items) => {
  if(statusInsideGroup(currentIndex, overIndex, items)) return taskConnectStatusesBetween.STATUS_1;
  if(statusInsideSubGroup(currentIndex, overIndex, items)) return taskConnectStatusesBetween.STATUS_3;
  if(statusBetweenBoundarySubGroupAndGroup(currentIndex, overIndex, items)) return taskConnectStatusesBetween.STATUS_5;
}

/*
* Пояснение к ТЗ
* Группа задач - две и более задачи, объединенные одним типом связи
* Граница между группами задач - сверху и снизу есть группа задач
* Стек подзадач - дочерние задачи
* 
* Для родительских задач сейчас сделана упрощенная реализация - перемещаем задачу между двумя другими и она становится в ту связь, которая была между ними
* Для дочерних сделана такая же упрощенная реализация, как и для родителей
* */

// Перемещение позиции внутрь группы задач. Позиция встает в новое положение в такой же связи, которая актуальная для этой группы задач. Без подтверждения действия, status = 1
const statusInsideGroup = (currentIndex, overIndex, items) => {
  const upElement = items[ overIndex - 1 ];
  const downElement = items[overIndex];
  
  if(!upElement || !downElement) return false;

  // Перетаскиваем родителя между родителями
  const isParent = upElement?.isParent && downElement.isParent;
  
  return isParent;
}

// Перемещение позиции на границу между группами задач. Позиция встает в новое положение, получая последовательные связи с задачами выше и ниже, status = 2
const statusBetweenBoundaryGroup = (currentIndex, overIndex, items) => {
  
}

// Перемещение позиции внутрь группы подзадач. Позиция встает в новое положение в такой же связи, которая актуальная для этой группы подзадач. Без подтверждения действия, status = 3
const statusInsideSubGroup = (currentIndex, overIndex, items) => {
  const upElement = items[ overIndex - 1 ];
  const downElement = items[overIndex];
  
  if(
    (!upElement?.isParent && !downElement?.isParent) || 
    (upElement?.isParent && !downElement?.isParent)
  ) return true;
  
  return false;
}

// Перемещение позиции на границу между группами подзадач. Позиция встает в новое положение, получая последовательные связи с задачами выше и ниже, status = 4
const statusBetweenBoundarySubGroup = (currentIndex, overIndex, items) => {
  
}

// Перемещение позиции на границу между группой подзадач и группой задач, status = 5
const statusBetweenBoundarySubGroupAndGroup = (currentIndex, overIndex, items) => {
  const upElement = items[ overIndex - 1 ];
  const downElement = items[overIndex];

  if(!upElement?.isParent || downElement?.isParent) return true;

  return false;
}

// Local fn

const getUpParent = (
  overIndex: number,
  items: ITaskFlattenBP[]
): ITaskFlattenBP|null => {
  let parent: any = null;
  const parentIndex = items[overIndex]?.parentIndex;
  
  items = items.filter(item => item.isParent);

  items.forEach((item, i) => {
    if(item.parentIndex === parentIndex) {
      parent = items[i - 1] ?? null;
    }
  });

  return parent;
}

// Попытка переместить родителя с дочерними элементами в другого родителя, для связей между
const actionParentWithChildsBetweenRestricted = (action, currentIndex, overIndex, items) => {
  let isAllowed = true;
  const isLastElement = ((items?.length - 1) === currentIndex);

  const currentElement = items[currentIndex];
  const upElement = items[overIndex - 1];

  if(!currentElement || !upElement) return false;

  if(
    !isLastElement &&
    (currentElement.isParent && !items[currentIndex + 1].isParent) && 
    !upElement.isParent) 
  {
    isAllowed = false;
  }

  return isAllowed;
}
