import moment from "moment/moment";

export const scrollToBottom = (selector: string) => {
  const element = document.querySelector(selector);

  if(element) {
    element.scrollTo(0, element.scrollHeight);
  }
}

export const getDuration = (duration: string): string => {
  return Math.floor(moment.duration(duration).asDays()) >= 1
          ? Math.floor(moment.duration(duration).asDays()) + " д."
          : Math.floor(moment.duration(duration).asHours()) + " ч.";
}

export const isPositiveDuration = (duration: string): boolean => {
  return moment.duration(duration).asHours() > 0;
}