import { Reducer } from "redux";
import { ITasksState } from "./interfaces";
import { TasksAction, TasksActionTypes } from "./action_types";
import { deleteTaskStatuses, sortConditions, sortOrders } from "./constants";
import { cookieMaster } from "../../utils/CookieMaster";
import { IMeta, TimeSheetCache } from "../../common/types";

const initialState: ITasksState = {
  tasks: [],
  isLoadingTasks: false,
  statusWindow: true,
  sortConditions: {
    columnId: sortConditions.END,
    value: sortOrders.DESC,
  },
  currentPage: 1,
  deleteTaskModal: {
    taskId: "",
    isDeleteModalOpen: false,
    isShowPreloader: false,
    isShowMenuPreloader: false,
    statusFromServer: { constraints: [] },
  },
  //настройка
  filters: {
    filterParametersList: [{ text: "Статус", value: 1 }],
    appliedFilters: {
      authors: [],
      executors: [],
      statuses: [],
    },
    draft: 0,
    addNewRow: true,
    exec_auth: "",
  },
  isLoading: false,
  searchTasks: "",
  selectedUserId: parseInt(cookieMaster.getCookie("user_id")),
  tasksMeta: null,
  timeSheet: [],
  TimeSheetCache: [],
  taskDoneConfirmation: {
    showTaskDoneConfirmationWindow: false,
    short_version: false,
    taskId: 0,
  },
};

export type TasksObjectsState = typeof initialState;

const reducer: Reducer<TasksObjectsState, TasksAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case TasksActionTypes.SET_TASKS_LIST:
      if(Array.isArray(action.tasks)) {
        if(action.action === 'add') {
          return {
            ...state,
            tasks: [
              ...state.tasks,
              ...action.tasks
            ]
          };
        }
        
        return {
          ...state,
          tasks: action.tasks
        };
      }
      else {
        return {
          ...state,
          tasks: [
            action.tasks,
            ...state.tasks,
          ],
        };
      }

    case TasksActionTypes.SET_SORT_CONDITION:
      return {
        ...state,
        sortConditions: {
          ...state.sortConditions,
          ...action.sortCondition,
        },
      };

    case TasksActionTypes.SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.currentPage,
      };

    case TasksActionTypes.DELETE_TASK_FROM_STATE:
      return {
        ...state,
        tasks: state.tasks.filter((task) => task.id !== action.id),
      };

    case TasksActionTypes.SET_DELETE_MODAL_DATA:
      return {
        ...state,
        deleteTaskModal: {
          ...state.deleteTaskModal,
          taskId: action.taskId,
          isDeleteModalOpen: action.isDeleteModalOpen,
          isShowPreloader: action.isShowPreloader,
          isShowMenuPreloader: action.isShowMenuPreloader,
          statusFromServer: action.statusFromServer,
        },
      };

    case TasksActionTypes.SET_LOADING_TASK:
      return {
        ...state,
        isLoading: action.isLoading,
      };

    case TasksActionTypes.SET_SEARCH_TASKS:
      return {
        ...state,
        searchTasks: action.searchTasks,
      };
    case TasksActionTypes.SET_FILTERS:
      // пока что реализовано только для целочисленных значений внутри массива
      return {
        ...state,
        filters: Object.assign(
          {},
          state.filters,
          { appliedFilters: action.filterObject },
          { draft: 0 }
        ),
      };

    case TasksActionTypes.SET_SELECTED_USER_ID:
      return {
        ...state,
        selectedUserId: action.id,
      };

    case TasksActionTypes.SET_TASKS_META:
      return {
        ...state,
        tasksMeta: action.tasksMeta,
      };

    case TasksActionTypes.UPDATE_STATUS_WINDOW_TRUE:
      return {
        ...state,
        statusWindow: true,
      };
    case TasksActionTypes.UPDATE_STATUS_WINDOW_FALSE:
      return {
        ...state,
        statusWindow: false,
      };

    case TasksActionTypes.LOCAL_TIMESHEET_RECORD:
      let newData;

      switch (action.actionType) {
        case "add":
          const found_task = state.timeSheet.find(
            (task) => task.task_id === action.taskId
          );

          if (found_task) {
            let copy_main_array = state.timeSheet;
            copy_main_array.splice(
              copy_main_array.findIndex(
                (task) => task.task_id === action.taskId
              ),
              1
            );

            if (action.task_load_sum !== undefined)
              found_task["task_load_sum"] = action.task_load_sum as number;
            if (action.status_id) found_task["status_id"] = action.status_id;
            if (action.commentText?.length)
              found_task["comment_text"] = action.commentText;
            if (action.commentFiles)
              found_task["comment_files"] = action.commentFiles;

            newData = [...copy_main_array, found_task];
          } else {
            newData = { task_id: action.taskId };

            if (action.task_load_sum)
              newData["task_load_sum"] = action.task_load_sum;

            if (action.status_id) newData["status_id"] = action.status_id;
            if (action.commentText?.length)
              newData["comment_text"] = action.commentText;
            if (action.commentFiles)
              newData["comment_files"] = action.commentFiles;
            newData = [...state.timeSheet, newData];
          }

          break;

        case "clear_whole":
          newData = [];
          break;
      }

      return {
        ...state,
        timeSheet: newData,
      };

    case TasksActionTypes.TIMESHEET_CACHE_RECORD:
      let result: TimeSheetCache[] = state.TimeSheetCache;

      switch (action.actionType) {
        case "write":
          let existing_item = state.TimeSheetCache.find(
            (item) => item.task_id === action.task_id
          );

          if (existing_item) {
            state.TimeSheetCache.splice(
              state.TimeSheetCache.findIndex(
                (item) => item.task_id === action.task_id
              ),
              1
            );
            if (action.task_load_sum !== undefined)
              existing_item["task_load_sum"] = action.task_load_sum;
            if (action.status_id !== undefined)
              existing_item["status_id"] = action.status_id;
            result.push(existing_item);
          } else {
            if (action.task_id) {
              let newRec = {
                task_id: action.task_id,
              };
              if (action.task_load_sum)
                newRec["task_load_sum"] = action.task_load_sum;
              if (action.status_id) newRec["status_id"] = action.status_id;
              result.push(newRec);
            }
          }
          break;

        case "clear":
          result = [];
          break;
      }

      return {
        ...state,
        TimeSheetCache: result,
      };

    case TasksActionTypes.TASK_DONE_CONFIRMATION:
      return {
        ...state,
        taskDoneConfirmation: {
          showTaskDoneConfirmationWindow: action.show,
          taskId: action.taskId,
          short_version: action.short_version,
        },
      };

    case TasksActionTypes.UPDATE_TASK_STORE:
      const newTasks = [...state.tasks];
      const updateIndex = state.tasks.findIndex(
        (task) => task.id === action.task.id
      );

      if (updateIndex === -1) {
        return state;
      }

      newTasks[updateIndex] = action.task;

      return { ...state, tasks: newTasks };

    default:
      return state;
  }
};

export default reducer;
