import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { State } from "../../../../../rootReducer";
import MultiSelectVanilla from "../../../../../common/components/ui/MultiSelectVanilla/MultiSelectVanilla";
import { getUsersFilterIds, getUsersFilterData } from "../../../selectors";
import { getTypicalTasks, setCurrentPage, setTypicalTasksFilterUsers } from "../../../actions";
import { TypicalTasksActionTypes } from "../../../action_types";

type Props = {
  usersFilterData: any;
  setTypicalTasksFilterUsers: (userIds: number[]) => void;
  usersFilterIds: number[];
  disabled?: boolean;
  usersDataForFilterIsLoading?: boolean;
  getTypicalTasks: (params?: object) => void,
  setCurrentPage: (currentPage: number) => void
};

const UsersSelectTypicalTasks: React.FC<Props> = ({
  usersFilterData,
  usersFilterIds,
  setTypicalTasksFilterUsers,
  disabled,
  usersDataForFilterIsLoading,
  getTypicalTasks,
  setCurrentPage
}) => {
  const dispatch = useDispatch();
  const [skipGetTask, setSkipGetTask] = useState<boolean>(true);
  const [placeholder, setPlaceholder] = useState<string>('Все');

  const open = () => {
    dispatch({type: TypicalTasksActionTypes.GET_TYPICAL_TASKS_USERS_FOR_FILTER});

    setSkipGetTask(false);
  }
  
  useEffect(() => {
    if(!skipGetTask) {
      getTypicalTasks();
    }

    if(usersFilterData.selected.length === 0) {
      setPlaceholder('Все');
    }
    else if(usersFilterData.selected.length < 2) {
      setPlaceholder(usersFilterData?.selected[0]?.text);
    }
    else {
      setPlaceholder(usersFilterData?.selected[0]?.text + ' и другие...');
    }
  }, [usersFilterIds]);
  
  return (
    <MultiSelectVanilla
      isLoading={usersDataForFilterIsLoading}
      placeholder={placeholder}
      disabled={disabled}
      itemsList={usersFilterData.list}
      selectedItems={usersFilterData.selected}
      isValid={usersFilterData.selected.length > 0}
      openHandler={() => open()}
      addHandler={(value: number) => {
        setTypicalTasksFilterUsers([...usersFilterIds, value as number]);
        setCurrentPage(1);
      }}
      removeHandler={(value: number) => {
        setTypicalTasksFilterUsers(usersFilterIds.filter((id) => id !== value));
      }}
    />
  );
};

const mapStateToProps = (state: State) => {
  return {
    usersDataForFilterIsLoading: state.typicalTasksPage.filters.usersDataForFilterIsLoading,
    usersFilterIds: getUsersFilterIds(state), // Ид выбранных юзеров
    usersFilterData: getUsersFilterData(state) // Получить юзеров для фильтрации
  };
};

const mapDispatchToProps = {
  setTypicalTasksFilterUsers,
  getTypicalTasks,
  setCurrentPage
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersSelectTypicalTasks);
