import React, { FC } from 'react';
import './PreloaderLocal.scss';

const PreloaderLocal: FC<{
  size?: string
}> = ({
  size
}) => {
  return (
    <div className={
      "preloader-local" +
      (size === "big" ? " preloader-local--big" : "")
    }>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  )
};

export default PreloaderLocal;
