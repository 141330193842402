import React from "react";
import { connect } from "react-redux";
import { State } from "../../../../../rootReducer";
import { TypeFilterPersonal } from "../../../action_types";
import { TypicalTasksPersonal } from "../../../../../common/constants";
import { getTypicalTasks, setCurrentPage, setTypicalTasksFilterPersonal } from "../../../actions";

import './PersonalToggle.scss';

const PersonalToggle: React.FC<{
  personalFilterId: TypeFilterPersonal,
  setTypicalTasksFilterPersonal: (id: TypeFilterPersonal) => void,
  getTypicalTasks: (params?: object) => void,
  setCurrentPage: (currentPage: number) => void
}> = ({
  personalFilterId,
  setTypicalTasksFilterPersonal,
  getTypicalTasks,
  setCurrentPage
}) => {
  const defaultValues = [
    {text: 'Все', value: TypicalTasksPersonal.NOT_PERSONAL_STATUS},
    {text: 'Мои', value: TypicalTasksPersonal.PERSONAL_STATUS}
  ];
  
  const setValueHandler = (value) => {
    setCurrentPage(1);
    setTypicalTasksFilterPersonal(value);

    getTypicalTasks();
  }
  
  return (
    <div className='personal-toggle'>
      {defaultValues.map((item, index) => (
        <div className={'personal-toggle__item' +
                       (personalFilterId === item.value ? ' personal-toggle__item--active' : '')}
             key={index}
             onClick={() => setValueHandler(item.value)}
        >
          {item.text}
        </div>
      ))}
    </div>
  )
};

const mapStateToProps = (state: State) => {
  return {
    personalFilterId: state.typicalTasksPage.filters.personal
  }
}

const mapDispatchToProps = {
  setTypicalTasksFilterPersonal,
  getTypicalTasks,
  setCurrentPage
}

export default connect(mapStateToProps, mapDispatchToProps)(PersonalToggle);
