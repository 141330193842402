import React from "react";
import { connect } from "react-redux";
import { State } from "../../../../../rootReducer";
import { TypeFilterPersonal } from "../../../action_types";
import { OjectsPersonal } from "../../../../../common/constants";
import { getObjects, setCurrentPage, setObjectsFilterPersonal } from "../../../actions";

import './PersonalToggle.scss';

const PersonalToggle: React.FC<{
  personalFilterId: TypeFilterPersonal,
  setObjectsFilterPersonal: (id: TypeFilterPersonal) => void,
  getObjects: (params?: object) => void,
  setCurrentPage: (currentPage: number) => void
}> = ({
  personalFilterId,
  setObjectsFilterPersonal,
  getObjects,
  setCurrentPage
}) => {
  const defaultValues = [
    {text: 'Все', value: OjectsPersonal.NOT_PERSONAL_STATUS},
    {text: 'Мои', value: OjectsPersonal.PERSONAL_STATUS}
  ];
  
  const setValueHandler = (value) => {
    setCurrentPage(1);
    setObjectsFilterPersonal(value);
    
    getObjects();
  }
  
  return (
    <div className='personal-toggle'>
      {defaultValues.map((item, index) => (
        <div className={'personal-toggle__item' +
                       (personalFilterId === item.value ? ' personal-toggle__item--active' : '')}
             key={index}
             onClick={() => setValueHandler(item.value)}
        >
          {item.text}
        </div>
      ))}
    </div>
  )
};

const mapStateToProps = (state: State) => {
  return {
    personalFilterId: state.objectsPage.filters.personal
  }
}

const mapDispatchToProps = {
  setObjectsFilterPersonal,
  getObjects,
  setCurrentPage
}

export default connect(mapStateToProps, mapDispatchToProps)(PersonalToggle);
