import {
  sortConditions,
  taskConnectActions,
  taskConnectStatuses, taskDeleteConnectionActions,
  taskDeleteConnectionStatuses
} from "./constants";

export const columnsOfTablesBusinessProcess = [
  {
    columnId: sortConditions.ID,
    showingText: "Id",
  },
  {
    columnId: sortConditions.NAME,
    showingText: "Бизнес-процесс",
  },
  {
    columnId: sortConditions.TREATY,
    showingText: "Типовые договоры",
  },

  {
    columnId: sortConditions.ALL_TASKS,
    showingText: "Всего задач",
  },
  {
    columnId: sortConditions.DURATION,
    showingText: "Срок исполнения",
  },
  {
    columnId: sortConditions.AUTHOR,
    showingText: "Автор",
  },
  {
    columnId: sortConditions.CREATED_AT,
    showingText: "Дата создания",
  },
  {
    columnId: sortConditions.STATUS,
    showingText: "Состояние",
  },
];

export const modalConnectionData = {
  /* Общие */
  
  [taskConnectStatuses.STATUS_ERROR]: {
    title: 'Произошла ошибка при создании связи...',
    btns: [
      {actionId: taskConnectActions.ACTION_ERROR, title: ''}
    ]
  },
  
  [taskConnectStatuses.STATUS_RESTRICTED]: {
    title: 'Действие запрещено',
    btns: [
      {actionId: taskConnectActions.ACTION_RESTRICTED, title: ''}
    ]
  },

  /* Для родителей */

  [taskConnectStatuses.STATUS_1]: {
    title: '',
    // 0 элемент массива - текст для параллельной задачи
    // 1 элемент массива - текст для подзадачи
    btns: [
      {actionId: taskConnectActions.ACTION_1, title: 'Сделать параллельной с задачей %%'},
      {actionId: taskConnectActions.ACTION_2, title: 'Сделать подзадачей для задачи %%'}
    ]
  },
  [taskConnectStatuses.STATUS_2]: {
    title: '',
    btns: [
      {actionId: taskConnectActions.ACTION_3, title: 'Добавить в группу параллельных задач'},
      {actionId: taskConnectActions.ACTION_4, title: 'Сделать подзадачей для задачи %%'}
    ]
  },
  [taskConnectStatuses.STATUS_3]: {
    title: '',
    btns: [
      {actionId: taskConnectActions.ACTION_5, title: 'Добавить в группу параллельных задач'},
      {actionId: taskConnectActions.ACTION_6, title: 'Сделать подзадачей для задачи %%'}
    ]
  },
  [taskConnectStatuses.STATUS_4]: {
    title: '',
    btns: [
      {actionId: taskConnectActions.ACTION_7, title: 'Добавить в группу параллельных задач'},
      {actionId: taskConnectActions.ACTION_8, title: 'Сделать подзадачей для задачи %%'}
    ]
  },
  
  /* Границы */
  
  [taskConnectStatuses.STATUS_9]: {
    title: '',
    btns: [
      {actionId: taskConnectActions.ACTION_13, title: 'Сделать параллельной с задачей %%'},
      {actionId: taskConnectActions.ACTION_14, title: 'Сделать подзадачей для задачи %%'}
    ]
  },
  [taskConnectStatuses.STATUS_10]: {
    title: '',
    btns: [
      {actionId: taskConnectActions.ACTION_15, title: 'Добавить в группу параллельных задач'},
      {actionId: taskConnectActions.ACTION_16, title: 'Сделать подзадачей для задачи %%'}
    ]
  },
  [taskConnectStatuses.STATUS_11]: {
    title: '',
    btns: [
      {actionId: taskConnectActions.ACTION_17, title: 'Сделать параллельной с задачей %%'},
      {actionId: taskConnectActions.ACTION_18, title: 'Сделать подзадачей для задачи %%'}
    ]
  },
  [taskConnectStatuses.STATUS_12]: {
    title: '',
    btns: [
      {actionId: taskConnectActions.ACTION_19, title: 'Добавить в группу параллельных задач'},
      {actionId: taskConnectActions.ACTION_20, title: 'Сделать подзадачей для задачи %%'}
    ]
  },
  
  /* Для дочерних элементов */
  
  [taskConnectStatuses.STATUS_5]: {
    title: '',
    btns: [
      {actionId: taskConnectActions.ACTION_9, title: 'Сделать параллельной с задачей %%'}
    ]
  },
  [taskConnectStatuses.STATUS_6]: {
    title: '',
    btns: [
      {actionId: taskConnectActions.ACTION_10, title: 'Добавить в группу параллельных задач'}
    ]
  },
  [taskConnectStatuses.STATUS_7]: {
    title: '',
    btns: [
      {actionId: taskConnectActions.ACTION_11, title: 'Добавить в группу параллельных задач'}
    ]
  },
  [taskConnectStatuses.STATUS_8]: {
    title: '',
    btns: [
      {actionId: taskConnectActions.ACTION_12, title: 'Добавить в группу параллельных задач'}
    ]
  },

  /* Границы */
  
  [taskConnectStatuses.STATUS_13]: {
    title: '',
    btns: [
      {actionId: taskConnectActions.ACTION_21, title: 'Сделать параллельной с задачей %%'}
    ]
  },
  [taskConnectStatuses.STATUS_14]: {
    title: '',
    btns: [
      {actionId: taskConnectActions.ACTION_22, title: 'Добавить в группу параллельных задач'}
    ]
  },
};

export const modalDeleteConnectionData = {
  /* Общие */

  [taskDeleteConnectionStatuses.STATUS_ERROR_DELETE]: {
    title: 'Произошла ошибка при удалении задачи...',
    btns: []
  },

  [taskDeleteConnectionStatuses.STATUS_ERROR_CONNECTION]: {
    title: 'Произошла ошибка при разрыве связи...',
    btns: []
  },
  
  /* Удаление задач */
  
  [taskDeleteConnectionStatuses.STATUS_1]: {
    title: 'Удаляем задачу из бизнес-процесса',
    text: 'Задача будет удалена из бизнес-процесса.<br/>Вы сможете добавить ее снова позже, если потребуется',
    btns: [
      {actionId: taskDeleteConnectionActions.ACTION_1, title: 'Удалить'}
    ]
  },
  [taskDeleteConnectionStatuses.STATUS_2]: {
    title: 'Удаляем родительскую задачу и весь стек подзадач из бизнес-процесса',
    text: 'Данная задача имеет вложенный стек подзадач.<br/>Вы можете удалить только полный комплект "Родительская задача + стек ее подзадач"',
    btns: [
      {actionId: taskDeleteConnectionActions.ACTION_2, title: 'Удалить комплект'}
    ]
  },

  /* Разрыв связей */

  [taskDeleteConnectionStatuses.STATUS_3]: {
    title: 'Разрываем параллельную связь',
    text: 'В месте разрыва параллельной связи будет образована последовательная связь',
    btns: [
      {actionId: taskDeleteConnectionActions.ACTION_3, title: 'Разорвать'}
    ]
  },
  [taskDeleteConnectionStatuses.STATUS_4]: {
    title: 'Разрываем связь родительской задачи со стеком ее подзадач',
    text: 'Все подзадачи станут задачами на первом уровне вложенности.<br/>Существующие параллельные связи между ними сохранятся',
    btns: [
      {actionId: taskDeleteConnectionActions.ACTION_4, title: 'Разорвать'}
    ]
  },
  [taskDeleteConnectionStatuses.STATUS_5]: {
    title: 'Разрываем связь родительской задачи со стеком ее подзадач',
    text: 'Родительская задача находится в параллельной связи с задачей ниже.<br/>Вам нужно определить - что сделать со стеком подзадач',
    btns: [
      {actionId: taskDeleteConnectionActions.ACTION_5, title: 'Разорвать связь и поместить подзадачи на первый уровень в последовательных связях'},
      {actionId: taskDeleteConnectionActions.ACTION_6, title: 'Разорвать связь и сформировать из подзадач группу параллельных задач'}
    ]
  },
};
