import { Reducer } from "redux";
import {
  BusinessPageActions,
  BusinessPageActionTypes,
  IBusinessPageState,
} from "./action_types";
import { sortConditions, sortOrders } from "./constants";
import { BusinessProcessActivity, BusinessProcessPersonal } from "./interfaces";

const initialState = {
  businessProcess: [],
  currentPage: 1,
  isLoadingBp: false,
  sortCondition: {
    columnId: sortConditions.CREATED_AT,
    value: sortOrders.ASC,
  },
  isFormBPOpen: false,
  isFullFormBPOpen: false,
  isLoadingBpCard: false,
  isCreatingBP: false,
  isEditForm: false,
  idTaskEdited: false,
  normalFormBP: {
    idBPEditing: "",
    name: "",
    actionConnectIdTrigger: null,
    activity: BusinessProcessActivity.ACTIVE_STATUS,
    author: '',
    createdAt: '',
    tasksItemsUpdateTrigger: null,
    tasksItems: [],
  },
  filters: {
    activity: BusinessProcessActivity.ACTIVE_STATUS,
    personal: BusinessProcessPersonal.NOT_PERSONAL_STATUS,
  },
  extraFormBP: {
    isLoading: false,
    typicalTasksBp: [],
    currentPageTypicalTasks: 1,
    searchTypicalTasksBp: "",
    isExtraFormBPDefaultOpen: false,
    bpForFilter: { value: "all", label: "Не важно" },
    treatyForFilterTasks: { value: "all", label: "Не важно" },
  },
  deleteBusinessProcessModal: {
    bpId: "",
    isDeleteModalOpen: false,
    isShowPreloader: false,
    statusFromServer: {
      constraints: [],
    },
  },
  activeStateBusinessProcessModal: {
    bpId: "",
    isActiveModalOpen: false,
    isShowPreloader: false,
    statusFromServer: {
      constraints: [],
    },
  },
  taskConnectModal: {
    isModalOpen: false,
    connectionStatusTrigger: null,
    currentTask: null,
    overTask: null
  },
  taskDeleteConnectionModal: {
    isModalOpen: false,
    connectionStatusTrigger: null,
    connectionActionTrigger: null
  },
  searchBP: "",
  businessProcessMeta: null,
} as IBusinessPageState;

export type BusinessPageState = typeof initialState;

const reducer: Reducer<BusinessPageState, BusinessPageActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case BusinessPageActionTypes.SET_BUSINESS_PROCESS:
      if (Array.isArray(action.businessProcess)) {
        if (action.action === "add") {          
          return {
            ...state,
            businessProcess: [
              ...state.businessProcess,
              ...action.businessProcess,
            ],
          };
        }
        return {
          ...state,
          businessProcess: action.businessProcess,
        };
      } else {        
        return {
          ...state,
          businessProcess: [action.businessProcess, ...state.businessProcess],
        };
      }

    case BusinessPageActionTypes.SET_LOADING_BUSINESS_PROCESS:
      return {
        ...state,
        isLoadingBp: action.isLoadingBp,
      };

    case BusinessPageActionTypes.SET_LOADING_BPS:
      return {
        ...state,
        isLoadingBpCard: action.isLoadingBpCard,
      };
      
    case BusinessPageActionTypes.SET_IS_CREATING_BP:
      return {
        ...state,
        isCreatingBP: action.isCreatingBP
      };

    case BusinessPageActionTypes.SET_SORT_CONDITION:
      return {
        ...state,
        sortCondition: {
          ...state.sortCondition,
          ...action.sortCondition,
        },
      };

    case BusinessPageActionTypes.SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.currentPage,
      };

    case BusinessPageActionTypes.SET_BP_FULL_FORM_OPEN:
      return {
        ...state,
        isFullFormBPOpen: action.isFullFormBPOpen,
      };

    case BusinessPageActionTypes.SET_BP_FORM_OPEN:
      return {
        ...state,
        isFormBPOpen: action.isFormBPOpen,
      };

    case BusinessPageActionTypes.SET_BUSINESS_PROCESS_FILTER_ACTIVITY:
      return {
        ...state,
        filters: {
          ...state.filters,
          activity: action.activity,
        },
      };

    case BusinessPageActionTypes.SET_BUSINESS_PROCESS_FILTER_PERSONAL:
      return {
        ...state,
        filters: {
          ...state.filters,
          personal: action.personal,
        },
      };
    case BusinessPageActionTypes.SET_DELETE_MODAL_BP:
      return {
        ...state,
        deleteBusinessProcessModal: {
          ...state.deleteBusinessProcessModal,
          ...action.data,
        },
      };

      case BusinessPageActionTypes.SET_ACTIVE_STATE_MODAL_BP:
        return {
          ...state,
          activeStateBusinessProcessModal: {
            ...state.activeStateBusinessProcessModal,
            ...action.data,
          },
        };

    case BusinessPageActionTypes.SET_SEARCH_BUSINESS_PROCESS:
      return {
        ...state,
        searchBP: action.searchBP,
      };

    case BusinessPageActionTypes.SET_NORMAL_FORM_BP:
      return {
        ...state,
        normalFormBP: {
          ...state.normalFormBP,
          ...action.normalFormBP,
        },
      };

    case BusinessPageActionTypes.SET_NORMAL_FORM_DEFAULT_VALUES_BP:
      return {
        ...state,
        normalFormBP: {
          ...initialState.normalFormBP
        },
      };

    case BusinessPageActionTypes.DELETE_BUSINESS_PROCESS_FROM_STATE:
      return {
        ...state,
        businessProcess: state.businessProcess.filter(
          (bp) => bp.id !== action.id
        ),
      };

    case BusinessPageActionTypes.SET_TASK_CONNECT_MODAL_BP:
      return {
        ...state,
        taskConnectModal: {
          ...state.taskConnectModal,
          ...action.data,
        },
      };

    case BusinessPageActionTypes.SET_TASK_DELETE_CONNECTION_MODAL_BP:
      return {
        ...state,
        taskDeleteConnectionModal: {
          ...state.taskDeleteConnectionModal,
          ...action.data
        }
      };

    case BusinessPageActionTypes.SET_ACTION_CONNECT_ID_TRIGGER:
      return {
        ...state,
        normalFormBP: {
          ...state.normalFormBP,
          actionConnectIdTrigger: action.idTrigger,
        },
      };

    case BusinessPageActionTypes.SET_LOADING_TYPICAL_TASKS_BP:
      return {
        ...state,
        extraFormBP: {
          ...state.extraFormBP,
          isLoading: action.isLoading,
        },
      };

    case BusinessPageActionTypes.SET_CURRENT_PAGE_TYPICAL_TASKS_BP:
      return {
        ...state,
        extraFormBP: {
          ...state.extraFormBP,
          currentPageTypicalTasks: action.currentPageTypicalTasks,
        },
      };
    case BusinessPageActionTypes.SET_TYPICAL_TASKS_BP:
      if (Array.isArray(action.typicalTasksBp)) {
        if (action.action === "add") {
          return {
            ...state,
            extraFormBP: {
              ...state.extraFormBP,
              typicalTasksBp: [
                ...state.extraFormBP.typicalTasksBp,
                ...action.typicalTasksBp,
              ],
            },
          };
        }
        return {
          ...state,
          extraFormBP: {
            ...state.extraFormBP,
            typicalTasksBp: action.typicalTasksBp,
          },
        };
      } else {
        return {
          ...state,
          extraFormBP: {
            ...state.extraFormBP,
            typicalTasksBp: [
              action.typicalTasksBp,
              ...state.extraFormBP.typicalTasksBp,
            ],
          },
        };
      }

    case BusinessPageActionTypes.SET_SEARCH_TYPICAL_TASKS_BP:
      return {
        ...state,
        extraFormBP: {
          ...state.extraFormBP,
          searchTypicalTasksBp: action.searchTypicalTasksBp,
        },
      };

    case BusinessPageActionTypes.SET_BP_META:
      return {
        ...state,
        businessProcessMeta: action.businessProcessMeta,
      };

    case BusinessPageActionTypes.SET_EXTRA_FORM_BP_DEFAULT_OPEN:
      return {
        ...state,
        extraFormBP: {
          ...state.extraFormBP,
          isExtraFormBPDefaultOpen: action.isExtraFormBPDefaultOpen,
        },
      };

    case BusinessPageActionTypes.SET_BP_FORM_FILTER:
      return {
        ...state,
        extraFormBP: {
          ...state.extraFormBP,
          bpForFilter: action.bpForFilter,
        },
      };
    case BusinessPageActionTypes.SET_TREATY_FORM_FILTER_TASKS:
       return {
         ...state,
         extraFormBP: {
          ...state.extraFormBP,
          treatyForFilterTasks: action.treatyForFilterTasks,
          },
        };
    case BusinessPageActionTypes.SET_TASK_ITEMS_BP:
      return {
        ...state,
        normalFormBP: {
          ...state.normalFormBP,
          tasksItems: [...state.normalFormBP.tasksItems, action.taskItems],
          tasksItemsUpdateTrigger: action.updateTrigger
        },
      };

      case BusinessPageActionTypes.SET_IS_EDIT_FORM_BP: {
        return {
          ...state,
          isEditForm: action.isEditForm
        }
      }
      case BusinessPageActionTypes.UPDATE_BP_STORE:
        const newBP = [...state.businessProcess];
        const updateIndex = state.businessProcess.findIndex(item => item.id === action.businessProcess.id);
  
        if(updateIndex === -1) {
          return state;
        }
  
        newBP[updateIndex] = action.businessProcess;
  
        return {...state, businessProcess: newBP};

    default:
      return state;
  }
};

export default reducer;
