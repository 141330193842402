import React, { FC } from "react";
import { IEditingObjectsFile } from "../../../interfaces";

import { ReactComponent as FileIcon } from "../../../../../img/TrueIcons/file-word.svg";
import { ReactComponent as CloseIcon } from "../../../../../img/TrueIcons/close.svg";

import './FileItem.scss';
import { urlApp } from "../../../../../App";

const FileItem: FC<{
  data: IEditingObjectsFile,
  onDelete?: (id: string|number, name: string) => void
}> = ({
  data,
  onDelete
}) => {
  return (
    <div className="objects-file-item">
      <div className="objects-file-item__left">
        <div className="objects-file-item__ico">
          <FileIcon />
        </div>
      </div>
      <div className="objects-file-item__right">
        <div className="objects-file-item__info">
          {data?.link && (
            <a download
               href={urlApp + `/files/${data.link}/download`}
            >
              {data?.name}
            </a>
          )}

          {!data?.link && (
            <>{data?.name}</>
          )}

          {data?.type && (
            <div>
              <span>{data.type}, </span>

              {data?.size && (
                <span>{data.size} Кбайт</span>
              )}
            </div>
          )}
        </div>

        {!data.link && (
          <div className='objects-file-item__delete'>
            <button className="objects-file-item__delete-btn"
                    onClick={() => onDelete ? onDelete(data.id, data.name) : () => {}}
            >
              <CloseIcon />
            </button>
          </div>
        )}
      </div>
    </div>
  )  
}

export default FileItem;
