import React, { FC, useEffect, useState } from "react";
import MultiSelectVanilla, {
  IMultiSelectVanillaList
} from "../../../../../../common/components/ui/MultiSelectVanilla/MultiSelectVanilla";

import './SelectTypicalTask.scss';

const SelectTypicalTask: FC<{
  itemsList: IMultiSelectVanillaList[],
  title?: string,
  onChangeSelect: (value: any) => void,
  selectedItem: any,
}> = ({
  itemsList,
  title, 
  onChangeSelect,
  selectedItem
}) => {
  const [placeholder, setPlaceholder] = useState<string>(itemsList[0]?.text);

  const setPlaceholderHelper = () => {
    itemsList.forEach(el => {
      if(el.value === selectedItem) setPlaceholder(el.text);
    })
  };
  
  useEffect(() => {
    setPlaceholderHelper();
  }, [selectedItem, placeholder]);
  
  return (
    <div className='select-typical-task'>
      {title &&(
        <div className="select-typical-task__title">{title}</div>
      )}
      
      <div className="select-typical-task__select">
        <MultiSelectVanilla
          placeholder={placeholder}
          withImage={false}
          showSearchField={false}
          isSingleSelect={true}
          disabled={false}
          itemsList={itemsList}
          selectedItems={[]}
          addHandler={(value) => {
            setPlaceholder(itemsList[value]?.text);
            onChangeSelect(value);
          }}
          removeHandler={() => {

          }}
        />
      </div>
    </div>
  )
}

export default SelectTypicalTask;
