export enum datePickerFormat {
  STANDARD_DATE = 'dd.MM.yyyy',
  STANDARD_DATE_TIME = 'dd.MM.yyyy HH:mm',
}

export enum datePickerText {
  CANCEL_LABEL = 'Отменить',
  TODAY_LABEL = 'Сейчас',
  INVALID_DATE = 'Введите дату...',
  INVALID_LABEL = 'Введите дату...',
  MAX_DATE_MESSAGE = 'Плюс 1 год относительно текущей даты',
  MIN_DATE_MESSAGE = 'Минус 1 год относительно текущей даты',
}
