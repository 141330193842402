import { all } from "redux-saga/effects";
import { watchFetchCreateCompany, watchFetchToken } from "./pages/Login/saga";
import {
  watchActualRequest,
  watchAmount,
  watchCreateBill,
  watchFetchModulesPrice,
  watchFetchOfflineBillStatus,
  watchFetchOnlineBillStatus,
  watchSetSelectedModules,
} from "./pages/Payment/saga";
import {
  watchFetchTasks,
  watchSetStatusFilterTasks,
  watchFiltersActualToggle,
  watchSetTableOrderTasks,
  watchLoadSpecificTask,
  watchFetchTasksVisualization,
  watchReadTaskNotification,
  watcherUploadFile,
  watcherDeleteFile,
  watchSendCommentTasks,
  watchClosingDayDispatchAll,
  watchTimeSheetActions,
  watchFetchTaskDependence, watchDeleteTask, watchCheckDeleteTask, watchGetTask,
} from "./pages/Tasks/saga";
import {
  watchFetchProjectTasks,
  watchSetStatusFilterProjects,
  watchSetTableOrderProjects,
  watchFiltersActualToggleProjects,
  watchSetProjectSearch,
  watchDeleteProjects,
  watchCheckDeleteProject,
  watchGetAllProjects,
} from "./pages/Projects/saga";
import {
  watchFetchCommonInfo,
  watchUpdateTask,
  watchFetchExistingUser,
  watchNotificationsRead,
  watchLoadNextPage,
  watchClearNotifications,
  watchClearSearch,
  watchGetOneProject,
  watchGetPositionsFromServer,
} from "./common/saga";
import {
  watchCreateProject,
  watchUploadFile,
  watchDeleteFile,
  watchRemoveProjectSection,
  watchChangeSectionName,
  watchUpdateProject,
  watchAddProjectSection,
  watchDeleteProject,
} from "./pages/NewProject/saga";
import {
  watchCreateUser,
  watchPasswordChange,
  watchPasswordReset,
  watchDeleteUser,
  watchAddUserPosition, 
  watchCreateUserDefaultNotification,
} from "./pages/NewUser/saga";
import {
  watchOpenDeps,
  watchCreateDep,
  watchFetchUsers,
  watchGetDepGroupUsers,
  watchAddUserToDepartment,
  watchAddUserToGroup,
  watchRemoveUserFromDepartment,
  watchRemoveUserFromGroup,
  watchCreateGroup,
  watchSetTableOrderUsers,
  watchUpdateDepGroupInfo,
  watch_delete_dep_group,
  watchGetNextPageAmongAll,
} from "./pages/Users/saga";
import {
  watchFetchSections,
  watchDelegateTask,
  watchSetRecalculateLoad,
} from "./pages/NewTask/saga";
import {
  watchDeleteComment,
  watchUploadCommentFile,
  watchDeleteCommentFile,
  watchSendComment,
  watchfetchProjectSectionsTaskPlate,
  watchGetComments,
  watchRecalculateLoad,
  watchDispatchCheckboxItem,
  watchDeligateConfirm,
  watchCreateTask,
  watchChangeTemplRepeatTask,
} from "./pages/TaskInfoPlate/saga";
import {
  watchCreateChecklistItems,
  watchCreateResolutionItem,
  watchCreatMeeting,
  watchEditMeeting,
  watchFetchMeetings,
  watchPatchResolutionItem,
} from "./pages/Old_Meetings/saga";
import {
  watchCheckDeleteTypicalTask,
  watchCreateTypicalTask,
  watchDeleteTypicalTask,
  watchGetTypicalTask,
  watchGetTypicalTasks,
  watchGetUsersDataForFilter,
  watchSearchTypicalTasks,
  watchUpdateTypicalTask,
} from "./pages/TypicalTasks/saga";
import {
  watchCheckDeleteObject,
  watchGetObject,
  watchCreateObject,
  watchGetUsersResponsible,
  watchUpdateObject,
  watchGetObjects,
  watchDeleteObject,
  watchGetUsersDataForObjectsFilter,
  watchCheckDeleteTaskObject,
  watchDeleteTaskSectionObject,
  watchGetObjectSection,
  watchGetTaskObjectSection,
  watchSearchObjects,
} from "./pages/ObjectsPage/saga";
import {
  watchCreateTaskObject,
  watchGetObjectSectionId,
} from "./pages/ObjectsPage/components/FormAddTask/saga";
import {
  watchGetObjectsFormEditTask,
  watchGetStages,
  watchGetTaskChecklist,
  watchGetTaskObject,
  watchUpdateTaskObject,
} from "./pages/ObjectsPage/components/FormEditTask/saga";
import {
  watchCheckDeleteTaskM,
  watchDeleteTaskM,
  watchGetTasksM,
  watchSearchTasksM,
  watchSetStatusFilterTasksM,
  watchUpdateTaskM,
} from "./pages/TasksPage/saga";
import {
  watchCheckChangeStateBp,
  watchCheckDeleteBp,
  watchCreateBP,
  watchDeleteBp,
  watchGetBp,
  watchGetBusinessProcess,
  watchGetTypicalTasksForBp,
  watchSearchBP,
  watchSearchTypicalTasksBP,
  watchSortTypicalTasksInBP,
  watchSortTypicalTasksInTreaty,
  watchUpdateBusinessProcess,
} from "./pages/BusinessPage/saga";
import {
  watchGetMainTreaty,
  watchTt, 
  watchSearchTt, 
  watchCheckDeleteTreaty, 
  watchDeleteTreaty,
  watchGetTypicalTreaty,
  watchCreateTreaty,
  watchUpdateTreaty,
  watchCheckDeleteStage,
  watchGetBPSForTreaty,
  watchSearchTypicalBPsTreaty,
  watchUpdateTypicalTreaty,
  watchSortTypicalBPsTreaty,
} from "./pages/TypicalTreaties/saga";

export function* rootSaga() {
  yield all([
    watchPatchResolutionItem(),
    watchCreateResolutionItem(),
    watchFetchMeetings(),
    watchEditMeeting(),
    watchCreatMeeting(),
    watchCreateTask(),
    watchFetchToken(),
    watchFetchCreateCompany(),
    watchFetchTasks(),
    watchUpdateDepGroupInfo(),
    watchSetStatusFilterTasks(),
    watchLoadSpecificTask(),
    watchFetchProjectTasks(),
    watchSetStatusFilterProjects(),
    watchSetTableOrderProjects(),
    watchFiltersActualToggleProjects(),
    watchFetchCommonInfo(),
    watchCreateProject(),
    watchDeleteProject(),
    watchUploadFile(),
    watchDeleteFile(),
    watchCreateUser(),
    watchDeleteUser(),
    watchOpenDeps(),
    watchCreateDep(),
    watchSetRecalculateLoad(),
    watchFetchUsers(),
    watchUpdateTask(),
    watchDeleteComment(),
    watchUploadCommentFile(),
    watchDeleteCommentFile(),
    watchSendComment(),
    watchGetDepGroupUsers(),
    watchFetchExistingUser(),
    watchAddUserToDepartment(),
    watchAddUserToGroup(),
    watchRemoveUserFromDepartment(),
    watchRemoveUserFromGroup(),
    watchCreateGroup(),
    watchRemoveProjectSection(),
    watchChangeSectionName(),
    watchUpdateProject(),
    watchSetProjectSearch(),
    watchAddProjectSection(),
    watchAddUserPosition(),
    watchFetchSections(),
    watchfetchProjectSectionsTaskPlate(),
    watchDelegateTask(),
    watchPasswordChange(),
    watchFiltersActualToggle(),
    watchPasswordReset(),
    watchSetTableOrderTasks(),
    watchSetTableOrderUsers(),
    watchGetComments(),
    watchNotificationsRead(),
    watchLoadNextPage(),
    watchRecalculateLoad(),
    watchDispatchCheckboxItem(),
    watch_delete_dep_group(),
    watchGetNextPageAmongAll(),
    watchDeligateConfirm(),
    watchFetchTasksVisualization(),
    watchFetchTaskDependence(),
    watchChangeTemplRepeatTask(),
    watchClearNotifications(),
    watchReadTaskNotification(),
    watchTimeSheetActions(),
    watchClosingDayDispatchAll(),
    watcherDeleteFile(),
    watcherUploadFile(),
    watchSendCommentTasks(),
    watchCreateChecklistItems(),
    watchDeleteUser(),
    watchAmount(),
    watchActualRequest(),
    watchFetchModulesPrice(),
    watchCreateBill(),
    watchSetSelectedModules(),
    watchFetchOnlineBillStatus(),
    watchFetchOfflineBillStatus(),
    watchClearSearch(),
    watchGetOneProject(),
    watchDeleteTask(),
    watchCheckDeleteTask(),
    watchDeleteProjects(),
    watchCheckDeleteProject(),
    // watchGetProjects(),
    watchGetUsersDataForFilter(),
    watchGetTypicalTasks(),
    watchGetTypicalTask(),
    watchCreateTypicalTask(),
    watchCheckDeleteTypicalTask(),
    watchDeleteTypicalTask(),
    watchUpdateTypicalTask(),
    watchSearchTypicalTasks(),
    watchUpdateObject(),
    watchCreateObject(),
    watchCheckDeleteObject(),
    watchDeleteObject(),
    watchGetObject(),
    watchGetObjects(),
    watchGetUsersResponsible(),
    watchCreateTaskObject(),
    watchGetUsersDataForObjectsFilter(),
    watchCheckDeleteTaskObject(),
    watchDeleteTaskSectionObject(),
    watchGetTaskObject(),
    watchGetStages(),
    watchUpdateTaskObject(),
    watchCheckDeleteTaskM(),
    watchDeleteTaskM(),
    watchGetTasksM(),
    watchSetStatusFilterTasksM(),
    watchSearchTasksM(),
    watchGetObjectSectionId(),
    watchGetTaskObjectSection(),
    watchGetObjectSection(),
    watchGetObjectsFormEditTask(),
    watchGetTaskChecklist(),
    watchUpdateTaskM(),
    watchSearchObjects(),
    watchCheckDeleteBp(),
    watchDeleteBp(),
    watchGetBp(),
    watchSearchBP(),
    watchCreateBP(),
    watchGetTypicalTasksForBp(),
    watchSearchTypicalTasksBP(),
    watchGetBusinessProcess(),
    watchUpdateBusinessProcess(),
    watchCheckChangeStateBp(),
    watchGetMainTreaty(),
    watchTt(),
    watchSearchTt(),
    watchCheckDeleteTreaty(),
    watchDeleteTreaty(),
    watchGetTypicalTreaty(),
    watchCreateTreaty(),
    watchUpdateTreaty(),
    watchCheckDeleteStage(),
    watchGetBPSForTreaty(),
    watchSearchTypicalBPsTreaty(),
    watchUpdateTypicalTreaty(),
    watchGetAllProjects(),
    watchCreateUserDefaultNotification(),
    watchGetTask(),
    watchGetPositionsFromServer(),
    watchSortTypicalTasksInBP(),
    watchSortTypicalBPsTreaty(),
    watchSortTypicalTasksInTreaty()
  ]);
}
