import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { State } from "../../../rootReducer";
import styled from "styled-components";
import Icon from "../../../common/components/Icon";
import {
  actualRequest,
  billPDFCreate,
  changeStep,
  createBill,
  fetchOfflineBillStatus,
  fetchOnlineBillStatus,
} from "../actions";
import Button from "../../../common/components/newComponent/Buttons/Button";
import { useHistory } from "react-router-dom";
import { nanoid } from "nanoid";
import BreadCrumbsText from "../Components/breadCrumbText";
import BreadCrumbsArrow from "../Components/breadCrumbArrow";
import palette from "../../../common/components/palette";
import axios from "axios";
import { cookieMaster } from "../../../utils/CookieMaster";
import { CreateNotif } from "../../../utils/createNotification";

const NewRow = styled.div`
  display: flex;
  margin-bottom: 16px;
  //   overflow: auto;
  &:last-child {
    margin-bottom: 0;
  }
  margin: "200px 0 0 200px" ${({ styles }) => styles && { ...styles }};
`;

const StepTitle = styled.div`
  font-size: 24px;
  margin-bottom: 24px;
  font-weight: 500;
  font-size: 36px;
  text-align: left;
`;

const CheckBoxInput = styled.div`
  padding: 8px 5px;
  font-size: 17px;
  display: flex;
  align-items: center;
  //   margin-top: 10px;
  > span {
    margin: 0px 8px;
    width: auto;
  }
`;
const Preloader = styled.div`
  &:before {
        content: '';
        background: #fff;
        z-index: 3;
        // width:100%;
        // height: 100%;
        position:absolute;
        // top:-5px;
        right:0;
  }
      &:after {
        content: '';
        border-radius: 50%;
        border: 3px solid #1BAAF0;
        z-index: 3;
        border-bottom-color: transparent;
        border-left-color: transparent;
        animation: spin .5s linear infinite;
        transform: translateX(-50%);
        position:absolute;
        top:40px;
        right:10px;
        width:16px;
        height:16px;
  `;

type Props = {
  months_count: number;
  loading: boolean;
  users_count: number;
  total_price: string;
  subscribe_status: boolean;
  subscribe_end: string;
  modules: string[];
  bill_info: any;
  url: string;
  payment_request_id: number;
  state_payment: any;
  is_active_paymethod: boolean;
  isPaid: boolean;

  changeStep: (step: number) => void;
  createBill: (
    company_id: number | string,
    months_count: number,
    users_count: number,
    modules: string[],
    payment_type: string
  ) => void;
};

const PaymentMethod: React.FC<Props> = ({
  changeStep,
  months_count,
  users_count,
  total_price,
  modules,
  subscribe_status,
  subscribe_end,
  bill_info,
  url,
  payment_request_id,
  state_payment,
  is_active_paymethod,
  isPaid,
  loading,
}) => {
  const breadCrumbsItems = ["Выбор подписки", "Оплата"];
  const [loadTwo, setLoadTwo] = useState(false);
  const dispatch = useDispatch();

  const history = useHistory();
  const company_id: any = localStorage.getItem("company_id");

  const btnPayment = () => {
    history.goBack();
  };

  useEffect(() => {
    if (!is_active_paymethod) {
      history.push(`/payment`);
    }

    dispatch(fetchOnlineBillStatus(company_id));
    dispatch(fetchOfflineBillStatus(company_id));
  }, []);

  // useEffect(() => {
  //   if (url) history.push(url);
  // }, [url]);

  const goToPayWithPaymentType = (type) => {
    if (company_id && type === "online") {
      dispatch(
        createBill(+company_id, months_count, users_count, modules, type)
      );
    }
    if (!isPaid && type === "offline") {
      // dispatch(
      //   createBill(+company_id, months_count, users_count, modules, type)
      // );
      //   history.push("/payment/offline-success");

      axios
        .post(
          `${process.env.REACT_APP_PUBLIC_API}/payment/bill`,
          {
            company_id: company_id,
            months_count: months_count,
            users_count: users_count,
            modules: modules,
            payment_type: "offline",
          },
          {
            headers: {
              Accept: "application/json",
              "company-id": company_id,
              Authorization: `${cookieMaster.getCookie("access_token")}`,
            },
          }
        )
        .then((res) => {
          history.push(`/payment/offline-success/${res.data.id}`);
          console.log(res);
        })
        .catch((e) => {
          console.log(e);
          CreateNotif(e.response.data.details.message);
          setLoadTwo(false);
        });

      setLoadTwo(false);
    }
  };

  useEffect(() => {
    if (isPaid) history.push("/payment/online-success");
  }, [isPaid]);

  let stringAmount = (+bill_info.amount).toLocaleString("ru-RU", {
    style: "currency",
    currency: "RUB",
  });

  return (
    <div style={{ marginLeft: "350px", marginTop: "10px" }}>
      <div>
        <NewRow styles={{ marginBottom: "24px" }}>
          {breadCrumbsItems.map((name, number) => {
            return (
              <div style={{ display: "flex" }} key={nanoid()}>
                <BreadCrumbsText
                  contextStep={number + 1}
                  currentStep={2}
                  name={name}
                  changeStep={changeStep}
                />
                {breadCrumbsItems.length !== number + 1 && <BreadCrumbsArrow />}
                {/*если это не последний пункт*/}
              </div>
            );
          })}
        </NewRow>
      </div>
      <NewRow style={{ justifyContent: "center" }}>
        <div style={{ padding: "24px", width: "100%", margin: "auto" }}>
          <div
            style={{
              width: "827px",
              height: "700px",
              background: "#FFFFFF",
              boxShadow: "0px 4px 5px rgba(0, 0, 0, 0.2)",
              borderRadius: "4px",
              padding: "20px 69px",
            }}
          >
            <StepTitle>Способ оплаты</StepTitle>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                // height: "55px",
                fontSize: "17px",
                fontWeight: 400,
                borderBottom: "1px solid #666565",
                paddingBottom: "24px",
              }}
            >
              {subscribe_status && (
                <>
                  <span
                    style={{
                      paddingBottom: "5px",
                    }}
                  >
                    Текущая подписка действует до: {subscribe_end} г.
                  </span>
                  <span
                    style={{
                      paddingBottom: "5px",
                    }}
                  >
                    Сумма: {stringAmount}
                  </span>
                </>
              )}
              <span> Сумма по новому счету: {total_price}</span>
            </div>

            <div>
              <div
                style={{
                  display: "flex",
                  height: "110px",
                  borderBottom: "1px solid #666565",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <div
                    style={{
                      //   marginRight: "30px",
                      width: "100%",
                      lineHeight: "20px",
                      textAlign: "left",
                      marginLeft: "10px",
                      fontSize: "22px",
                      display: "flex",
                      marginTop: "32px",
                      // cursor: "pointer",
                    }}
                  >
                    <Icon name={"Vector"} />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <span style={{ marginLeft: "26px" }}>
                        Банковская карта
                      </span>
                    </div>{" "}
                  </div>
                  {url && (
                    <div
                      style={{
                        marginBottom: "5px",
                        paddingLeft: "63px",
                        fontSize: "14px",
                        paddingTop: "20px",
                        color: palette.textDark,
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <span> {"Заявка ожидает оплаты. "}</span>
                      <a
                        href={url}
                        style={{
                          textDecoration: "none",
                          color: palette.blue,
                          margin: 0,
                          padding: 0,
                        }}
                        target="_blank" rel="noreferrer"
                      >
                        Перейти
                      </a>
                    </div>
                  )}
                </div>{" "}
                <div style={{ position: "relative" }}>
                  {loading ? (
                    <Preloader />
                  ) : (
                    <div
                      style={{ marginTop: "44.333px" }}
                      onClick={() => {
                        goToPayWithPaymentType("online");
                      }}
                    >
                      <Icon name={"VectorPayment"} />
                    </div>
                  )}
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  height: "110px",
                  borderBottom: "1px solid #666565",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <div
                    style={{
                      //   marginRight: "30px",
                      width: "100%",
                      lineHeight: "20px",
                      textAlign: "left",
                      marginLeft: "10px",
                      fontSize: "22px",
                      display: "flex",
                      marginTop: "32px",
                    }}
                  >
                    <Icon name={"paymentCheck"} />
                    <span style={{ marginLeft: "26px" }}>
                      Счёт для юридических лиц и ИП
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      justifyContent: "space-between",
                      paddingTop: "5px",
                      paddingLeft: "63px",
                    }}
                  >
                    <div
                      style={{
                        textAlign: "center",
                        fontSize: "16px",
                        fontWeight: 400,
                        paddingBottom: "10px",
                      }}
                    >
                      <span>
                        Для зачисления средств необходимо проверить реквизиты.
                      </span>
                    </div>
                    {payment_request_id && (
                      <div>
                        <span> {"У вас есть неоплаченный счет. "}</span>
                        <a
                          href={`${process.env.REACT_APP_PUBLIC_URL}/payment/bill-pdf-create/${payment_request_id}`}
                          target="_blank"
                          style={{
                            textDecoration: "none",
                            color: palette.blue,
                            margin: 0,
                            padding: 0,
                            cursor: "pointer",
                          }} rel="noreferrer"
                        >
                          Cкачать
                        </a>
                      </div>
                    )}
                  </div>
                </div>
                <div style={{ position: "relative" }}>
                  {loadTwo ? (
                    <Preloader />
                  ) : (
                    <div
                      style={{ marginTop: "44.333px" }}
                      onClick={() => {
                        goToPayWithPaymentType("offline");
                        setLoadTwo(true);
                      }}
                    >
                      <Icon name={"VectorPayment"} />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <Button
              title={"Назад"}
              design={"outline"}
              style={{
                width: "163px",
                height: "50px",
                alignItems: "center",
                textAlign: "center",
                fontSize: "18px",
                marginTop: "220px",
              }}
              onClick={btnPayment}
            />
          </div>
        </div>
      </NewRow>
    </div>
  );
};

const mapStateToProps = (state: State) => {
  return {
    //   currentTariff: state.payment.currentTariff,
    step: state.payment.step,
    months_count: state.payment.months_count,
    loading: state.payment.loading,
    users_count: state.payment.users_count,
    total_price: state.payment.total_price,
    modules: state.payment.modules,
    subscribe_end: state.payment.subscribe_end,
    subscribe_status: state.payment.subscribe_status,
    bill_info: state.payment.bill_info,
    url: state.payment.url,
    payment_request_id: state.payment.payment_request_id,
    is_active_paymethod: state.payment.is_active_paymethod,
    isPaid: state.payment.isPaid,
  };
};

const mapDispatchToProps = {
  changeStep,
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentMethod);
