import React, { useCallback, useEffect, useState } from "react";
import { State } from "../../../../../../../rootReducer";
import { connect } from "react-redux";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import { makeStyles } from "@material-ui/core/styles";
import CheckboxMui from "../../../../../../../common/components/ui/CheckboxMui/CheckboxMui";

import "./StagesTreaty.scss";
import { AccordionSummary } from "@material-ui/core";
import { AccordionIcon2 } from "../../../../../../../img/projectIcons";
import SelectActualStage from "../SelectActualStage/SelectActualStage";
import ItemsTreaties from "../ItemsStageTreaties/ItemsTreaties";
import MenuTreatyMain from "../../../../ui/MenuTreatyMain/MenuTreatyMain";
import { ReactComponent as DotsIcon } from "../../../../../../../img/TrueIcons/dots.svg";
import { ReactComponent as BurgerIcon } from "../../../../../../../img/TrueIcons/burger.svg";
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  setExtraFormActiveTabIndex,
  setExtraFormTreatyCreateFormOpen,
  setExtraFormTreatyDefaultOpen,
  setExtraFormTreatyEditFormOpen,
  setExtraFormTreatyTasksAndBPFormOpen,
  setStageIdTreaty,
  setTaskItemsStageTreaty,
} from "../../../../../actions";
import { Stage, TabIdsExtraFormTreaty } from "../../../../../interfaces";
import moment from "moment";
import { getDuration, isPositiveDuration } from "../../../../../../BusinessPage/helpers/helpersCommon";

const StagesTreaty: React.FC<{
  stage: Stage;
  main?: boolean;
  setExtraFormTreatyDefaultOpen: (mode: boolean) => void;
  setStageIdTreaty: (id?: number | string) => void;
  setExtraFormTreatyTasksAndBPFormOpen: (mode: boolean) => void;
  setExtraFormActiveTabIndex: (mode: number) => void;
  setExtraFormTreatyEditFormOpen: (mode: boolean) => void;
  setExtraFormTreatyCreateFormOpen: (mode: boolean) => void;
  setStageData: (data: any) => void;
}> = ({
  stage,
  main,
  setExtraFormTreatyDefaultOpen,
  setStageIdTreaty,
  setExtraFormTreatyTasksAndBPFormOpen,
  setExtraFormActiveTabIndex,
  setExtraFormTreatyEditFormOpen,
  setExtraFormTreatyCreateFormOpen,
  setStageData
}) => {
  const [openAccord, setOpenAccord] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const menuActionsOpenHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleSetAnchorEl = (value) => {
    setAnchorEl(value);
  };

  const [stagesTreatyData, setStagesTreatyData] = useState<any>([]);

  const useStyles = makeStyles((theme) => ({
    MuiAccordionroot: {
      "&.MuiAccordion-root": {
        margin: "10px 0",
      },
    },
  }));
  const classes = useStyles();
  const elementWithClass = document.querySelector(".PrivateSwitchBase-input-7");
  const onChangeAccordion = (expanded) => {
    setOpenAccord(expanded);
    setExtraFormTreatyTasksAndBPFormOpen(false);
    setExtraFormTreatyDefaultOpen(true);

    if (expanded) {
      //   getObjectSection(object.id);
    }
  };

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: stage.id,
    });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const updateItemLists = useCallback((newStagesTreatyData) => {
    setStageData((prevStageData) => {
      
      const updatedStageData = prevStageData.map((stageItem) => {
        if (stageItem.id == stage.id) {
          return { ...stageItem, item_list: newStagesTreatyData };
        }
        return stageItem;
      });
  
      return updatedStageData;
    });
  },[setStageData, stage.id]);
  
  const handleOnDragEnd = useCallback((event: any) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      setStagesTreatyData((prev: any[]) => {
        const activeIndex = prev.findIndex(
          (item: any) => item.id === active.id
        );
        const overIndex = prev.findIndex((item: any) => item.id === over.id);
        const updatedStagesTreatyData = arrayMove(prev, activeIndex, overIndex);

        updateItemLists(updatedStagesTreatyData)

        return arrayMove(prev, activeIndex, overIndex);
      });
    }
  },[setStagesTreatyData, updateItemLists]);

  useEffect(() => {
    if (stage?.item_list) {
      setStagesTreatyData(stage.item_list);
    }
  }, [stage?.item_list]);

  const handleChangeTasksStage = (id: number | string) => {
    setExtraFormTreatyDefaultOpen(false);
    setStageIdTreaty(id);
    setExtraFormTreatyEditFormOpen(false);
    setExtraFormTreatyCreateFormOpen(false);
    setExtraFormTreatyTasksAndBPFormOpen(true);
    setExtraFormActiveTabIndex(TabIdsExtraFormTreaty.TASKS_TAB);
  };

  const handleChangeBpsStage = (id: number | string) => {
    setExtraFormTreatyDefaultOpen(false);
    setStageIdTreaty(id);
    setExtraFormTreatyEditFormOpen(false);
    setExtraFormTreatyCreateFormOpen(false);
    setExtraFormTreatyTasksAndBPFormOpen(true);
    setExtraFormActiveTabIndex(TabIdsExtraFormTreaty.BPS_TAB);
  };

  const onChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStageData((prevStageData) => {
      const updatedStageCheck = prevStageData.map((stageItem) => {
        if (stageItem.id == stage.id) {
          return { ...stageItem, status: event.target.checked ? 1 : 0 };
        }
        return stageItem;
      });
  
      return updatedStageCheck;
    });
  }  

  return (
    <div
      className="stage-treaty"
      ref={setNodeRef}
      {...attributes}
      style={style}
    >
      {main &&
      <div className="stage-treaty__burger">
         <BurgerIcon {...listeners} />
      </div>}
      <div className="stage-treaty__content">
        <Accordion
          className="accordion_treaty"
          expanded={openAccord}
          onChange={(e, expanded) => onChangeAccordion(expanded)}
          classes={{ root: classes.MuiAccordionroot }}
        >
          <AccordionSummary>
            <div
              className={
                openAccord
                  ? "accordion_treaty__vector open"
                  : "accordion_treaty__vector"
              }
            >
              <AccordionIcon2 />
            </div>
            <div className="accordion_treaty__name">{stage.name}</div>
            <div className="accordion_treaty__count_bps">
              {stage.count_bp ?? "0"}
            </div>
            <div className="accordion_treaty__count_tasks">
              {stage.count_task ?? "0"}
            </div>
            <div className="accordion_treaty__durartion">
              {isPositiveDuration(stage.duration) ? getDuration(stage.duration) : <span>&mdash;</span>}
            </div>
            <div
              onClick={(e) => e.stopPropagation()}
              className="accordion_treaty__actual"
            >
              {main ? (
                <CheckboxMui
                  title=""
                  checked={stage?.status === 1}
                  handleChange={(e) => onChangeCheckbox(e)}
                />
              ) : (
                <SelectActualStage stageId={stage.id} stage={stage} />
              )}
            </div>
            {main && (
              <div className="task-typical__actions">
                <button
                  className="task-typical__actions-btn"
                  onClick={(e) => menuActionsOpenHandler(e)}
                >
                  <DotsIcon />
                </button>
              </div>
            )}

            <MenuTreatyMain
              name={stage.name}
              id={stage.id}
              anchorEl={anchorEl}
              setAnchorEl={handleSetAnchorEl}
            />
          </AccordionSummary>
          <AccordionDetails style={{ display: "block" }}>
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragEnd={handleOnDragEnd}
            >
              <SortableContext
                items={stagesTreatyData as []}
                strategy={verticalListSortingStrategy}
              >
                {stagesTreatyData.length ? stagesTreatyData?.map((item: any) => {
                  return (
                    <ItemsTreaties key={item.id} item={item} stageId={stage.id} />
                  );
                }) : <div className="stages_items_none">Список исполняемых позиций пока пуст</div>}
              </SortableContext>
            </DndContext>
            {main &&
            <div className="stage-treaty__add-items">
              Добавить
              <span
                className="stage-treaty__add-items__item"
                onClick={() => handleChangeBpsStage(stage.id)}
              >
                бизнес-процесс
              </span>{" "}
              или
              <span
                className="stage-treaty__add-items__item"
                onClick={() => handleChangeTasksStage(stage.id)}
              >
                типовую задачу
              </span>
            </div>
            }
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
};

const mapStateToProps = (state: State) => {
  return {
    main: state.typicalTreatyPage.normalForm.main,
  };
};

const mapDispatchToProps = {
  setExtraFormTreatyDefaultOpen,
  setStageIdTreaty,
  setExtraFormTreatyTasksAndBPFormOpen,
  setExtraFormActiveTabIndex,
  setExtraFormTreatyEditFormOpen,
  setExtraFormTreatyCreateFormOpen,
};

export default connect(mapStateToProps, mapDispatchToProps)(StagesTreaty);
