import React, { FC, useEffect, useState } from "react";
import { connect } from "react-redux";
import { State } from "../../../../../../rootReducer";
import DatePickerTrue from "../../../ui/DatePickerTrue/DatePickerTrue";
import moment from "moment";
import { datePickerFormat, datePickerText } from "../../../ui/DatePickerTrue/constants";
import SelectSimple from "../../../../../../common/components/ui/SelectSimple/SelectSimple";
import InputSimple from "../../../../../../common/components/ui/InputSimple/InputSimple";
import PriorityCustom from "../../../../../../common/components/ui/PriorityCustom/PriorityCustom";
import TooltipQuestion from "../../../../../../common/components/ui/TooltipQuestion/TooltipQuestion";
import TextAreaSimple from "../../../../../../common/components/ui/TextAreaSimple/TextAreaSimple";
import { IObjectsFormEditTaskData } from "../../interfaces";
import { setCheckbox, setSimpleDataFormEditTask, setSelectObservers, setSelectSelectedValue, setSelectValues } from "../../actions";
import { IObjectsFormAddTaskDataSelect } from "../../../FormAddTask/interfaces";
import { getAllExecutors, getTypicalTaskExecutor } from "../../../FormAddTask/selectors";
import SelectObserversTask from "../ui/SelectObservers/SelectObservers";
import { getObjectSelectedSelector, getObjectsSelector } from "../../selectors";
import { checkDates, getDeadline } from "../../../../helpers";

import './DescriptionTab.scss';

const DescriptionTab: FC<{
  formData: IObjectsFormEditTaskData,
  isUserCanEdit: boolean,
  setSelectValues: (data: IObjectsFormAddTaskDataSelect[], name: keyof IObjectsFormEditTaskData) => void,
  setSelectSelectedValue: (data: IObjectsFormAddTaskDataSelect, name: keyof IObjectsFormEditTaskData) => void,
  setCheckbox: (value: boolean, name: keyof IObjectsFormEditTaskData) => void,
  setInput: (value: string|Date, name: keyof IObjectsFormEditTaskData) => void,
  // executor: IObjectsFormAddTaskDataSelect,
  executorsAll: IObjectsFormAddTaskDataSelect[],
  setSelectObservers: (ids: number[]) => void,
  objects:IObjectsFormAddTaskDataSelect[],
  checkTrigger: string,
  objectSelected: IObjectsFormAddTaskDataSelect
}> = ({
  formData,
  isUserCanEdit,
  setSelectValues,
  setSelectSelectedValue,
  setCheckbox,
  setInput,
  // executor,
  executorsAll,
  setSelectObservers,
  objects,
  checkTrigger,
  objectSelected
}) => {
  const [dateErrorMessages, setDateErrorMessages] = useState<any>({
    startDate: [],
    endDate: []
  });
  
  const onChangeDate = (date: Date|null, name: keyof IObjectsFormEditTaskData) => {
    const dataHelpers = getDeadline(formData.duration, date, name, formData.startDate ?? '', formData.endDate ?? '');
    
    setDateErrorMessages(dataHelpers.datesMessages);
    setInput(dataHelpers.deadlineTime, 'endDate');
    
    if(date) {
      setInput(date, name);
    }
  }

  // УДАЛИТЬ ПОСЛЕ РЕЛИЗА, ЕСЛИ НЕ НУЖНО
  const onChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>, name: keyof IObjectsFormEditTaskData) => {
    setCheckbox(event.target.checked, name);
  };

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>, name: keyof IObjectsFormEditTaskData) => {
    setInput(e.target.value, name);
  };

  const onChangeSelect = (data: any, name: keyof IObjectsFormEditTaskData) => {
    setSelectSelectedValue(data, name);
    
    if(name === 'objects') {
      setInput(data.value, 'project_id');
    }
  };

  const onChangeTextArea = (e: React.ChangeEvent<HTMLTextAreaElement>, name: keyof IObjectsFormEditTaskData) => {
    setInput(e.target.value, name);
  };

  const onChangeObservers = (ids: number[]) => {
    setSelectObservers(ids);
  };

  useEffect(() => {
    if(executorsAll.length) {
      setSelectValues(executorsAll, 'executor');
    }
    
    if(objects.length) {
      setSelectValues(objects, 'objects');
    }
  }, [executorsAll, objects]);

  useEffect(() => {
    // Тут нужно доработать
    if(checkTrigger.length) {
      let data;
      
      if(formData?.startDate) {
        data = checkDates(formData.startDate, 'startDate', formData.startDate ?? '', formData.endDate ?? '');
      }

      if(formData?.endDate) {
        data = checkDates(formData.endDate, 'endDate', formData.startDate ?? '', formData.endDate ?? '');
        
        setDateErrorMessages(data);
      }
    }
  }, [checkTrigger]);

  useEffect(() => {
    setDateErrorMessages({
      startDate: [],
      endDate: []
    });
    
    const startMessages = checkDates(formData.startDate ?? '', 'startDate', formData.startDate?? '', formData.endDate ?? '');
    const endMessages = checkDates(formData.endDate ?? '', 'endDate', formData.startDate ?? '', formData.endDate?? '');

    if(startMessages.startDate.length) {
      setDateErrorMessages(state => {
        return {
          ...state,
          startDate: startMessages.startDate
        }
      });
    }
    
    if(endMessages.endDate.length) {
      setDateErrorMessages(state => {
        return {
          ...state,
          endDate: endMessages.endDate
        }
      });
    }
  }, [formData]);
  
  return (
    <div className='description-tab-objects-task-edit'>
      {!isUserCanEdit && (
        <div className="description-tab-objects-task-edit--disabled"></div>
      )}

      {(formData.author?.name && (formData.author.name?.length > 0)) &&(
        <div className="description-tab-objects-task-edit__info">
          <div className="description-tab-objects-task-edit__info-row">
            <div className="description-tab-objects-task-edit__info-left">Автор</div>
            <div className="description-tab-objects-task-edit__info-right">
              {formData.author?.surname + ' ' + formData.author?.name}
            </div>
          </div>

          {formData?.createdAt && (
            <div className="description-tab-objects-task-edit__info-row">
              <div className="description-tab-objects-task-edit__info-left">Дата создания</div>
              <div className="description-tab-objects-task-edit__info-right">
                {moment(formData.createdAt).format('DD.MM.YYYY')}
              </div>
            </div>
          )}
        </div>
      )}

      <div className="description-tab-objects-task-edit__blocks">
        <div className="description-tab-objects-task-edit__block-item">
          <div className="description-tab-objects-task-edit__block-item-left">
            <div className="description-tab-objects-task-edit__block-item-title">* Объект</div>
          </div>

          <div className="description-tab-objects-task-edit__block-item-right">
            <div className="description-tab-objects-task-edit__block-item-select">
              <SelectSimple
                name='objects'
                selectedValue={objectSelected}
                dataForSelect={formData.objects.data}
                onChange={(e) => onChangeSelect(e, 'objects')}
                checkTriggerExternal={checkTrigger}
                rules={[{required: true}]}
              />
            </div>
          </div>
        </div>

        <div className="description-tab-objects-task-edit__block-item">
          <div className="description-tab-objects-task-edit__block-item-left">
            <div className="description-tab-objects-task-edit__block-item-title">* Этап</div>
          </div>

          <div className="description-tab-objects-task-edit__block-item-right">
            <div className="description-tab-objects-task-edit__block-item-select">
              <SelectSimple
                name='stages'
                selectedValue={formData.stages.selectedValue}
                dataForSelect={formData.stages.data}
                onChange={(e) => onChangeSelect(e, 'stages')}
                checkTriggerExternal={checkTrigger}
                rules={[{required: true}]}
              />
            </div>
          </div>
        </div>

        <div className="description-tab-objects-task-edit__block-item">
          <div className="description-tab-objects-task-edit__block-item-left">
            <div className="description-tab-objects-task-edit__block-item-title">* Наименование <br/> задачи</div>
          </div>

          <div className="description-tab-objects-task-edit__block-item-right">
            <div className="description-tab-objects-task-edit__block-item-input">
              <InputSimple
                name='title'
                onChange={(e) => onChangeInput(e, 'title')}
                value={formData.title}
                checkTriggerExternal={checkTrigger}
                rules={[{required: true}, {max: 200}]}
              />
            </div>
          </div>
        </div>

        <div className="description-tab-objects-task-edit__block-item">
          <div className="description-tab-objects-task-edit__block-item-left">
            <div className="description-tab-objects-task-edit__block-item-title">Описание задачи</div>
          </div>

          <div className="description-tab-objects-task-edit__block-item-right">
            <TextAreaSimple
              name='description'
              onChange={(e) => onChangeTextArea(e, 'description')}
              value={formData.description}
              checkTriggerExternal={checkTrigger}
            />
          </div>
        </div>

        <div className="description-tab-objects-task-edit__block-item">
          <div className="description-tab-objects-task-edit__block-item-left">
            <div className="description-tab-objects-task-edit__block-item-title">* Приоритет</div>
          </div>

          <div className="description-tab-objects-task-edit__block-item-right">
            <div className="description-tab-objects-task-edit__block-item-input">
              <SelectSimple
                name='priorities'
                selectedValue={formData.priorities.selectedValue}
                dataForSelect={formData.priorities.data}
                onChange={(e) => onChangeSelect(e, 'priorities')}
                props={{
                  formatOptionLabel: (data) => (
                    <PriorityCustom priorityName={data.label} />
                  )
                }}
                checkTriggerExternal={checkTrigger}
                rules={[{required: true}]}
              />
            </div>
          </div>
        </div>

        <div className="description-tab-objects-task-edit__block-item">
          <div className="description-tab-objects-task-edit__block-item-left">
            <div className="description-tab-objects-task-edit__block-item-title">* Исполнитель</div>
          </div>

          <div className="description-tab-objects-task-edit__block-item-right">
            <div className="description-tab-objects-task-edit__block-item-select">
              <SelectSimple
                name='executor'
                selectedValue={formData.executor.selectedValue}
                dataForSelect={formData.executor.data}
                onChange={(e) => onChangeSelect(e, 'executor')}
                checkTriggerExternal={checkTrigger}
                rules={[{required: true}]}
              />
            </div>
            {/* УДАЛИТЬ ПОСЛЕ РЕЛИЗА, ЕСЛИ НЕ НАДО */}
            {/*<div className="description-tab-objects-task-edit__block-item-chk-wr">
              <div className="description-tab-objects-task-edit__block-item-chk">
                <CheckboxMui
                  title="Разрешить делегирование"
                  checked={formData.delegation}
                  handleChange={(e) => onChangeCheckbox(e, 'delegation')}
                />
              </div>
              <div className="description-tab-objects-task-edit__block-item-info">
                <TooltipQuestion title='Разрешить делегирование' />
              </div>
            </div>*/}
          </div>
        </div>

        <div className="description-tab-objects-task-edit__block-item">
          <div className="description-tab-objects-task-edit__block-item-left">
            <div className="description-tab-objects-task-edit__block-item-title">Наблюдатели</div>
          </div>

          <div className="description-tab-objects-task-edit__block-item-right">
            <div className="description-tab-objects-task-edit__block-item-select-wr">
              <div className="description-tab-objects-task-edit__block-item-select">
                <SelectObserversTask
                  onChangeObservers={(data) => onChangeObservers(data)}
                />
              </div>
              <div className="description-tab-objects-task-edit__block-item-info">
                <TooltipQuestion title='Наблюдатели' />
              </div>
            </div>
          </div>
        </div>

        <div className="description-tab-objects-task-edit__block-item">
          <div className="description-tab-objects-task-edit__block-item-left">
            <div className="description-tab-objects-task-edit__block-item-title">* Начало</div>
          </div>

          <div className="description-tab-objects-task-edit__block-item-right">
            <div className="description-tab-objects-task-edit__block-item-input">
              <DatePickerTrue
                format={datePickerFormat.STANDARD_DATE_TIME}
                minDateMessage={datePickerText.MIN_DATE_MESSAGE}
                maxDateMessage={datePickerText.MAX_DATE_MESSAGE}
                errorMessages={dateErrorMessages.startDate}
                date={moment(formData.startDate)}
                onChangeDate={(date) => onChangeDate(date, 'startDate')}
              />
            </div>
          </div>
        </div>

        <div className="description-tab-objects-task-edit__block-item">
          <div className="description-tab-objects-task-edit__block-item-left">
            <div className="description-tab-objects-task-edit__block-item-title">* Дедлайн</div>
          </div>

          <div className="description-tab-objects-task-edit__block-item-right">
            <div className="description-tab-objects-task-edit__block-item-input">
              <DatePickerTrue
                format={datePickerFormat.STANDARD_DATE_TIME}
                minDateMessage={datePickerText.MIN_DATE_MESSAGE}
                maxDateMessage={datePickerText.MAX_DATE_MESSAGE}
                errorMessages={dateErrorMessages.endDate}
                date={moment(formData.endDate)}
                onChangeDate={(date) => onChangeDate(date, 'endDate')}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state: State) => {
  return {
    formData: state.objectsFormEditTask.data,
    // executor: getTypicalTaskExecutor(state),
    executorsAll: getAllExecutors(state),
    objects: getObjectsSelector(state),
    checkTrigger: state.objectsFormEditTask.checkTrigger,
    objectSelected: getObjectSelectedSelector(state)
  }
};

const mapDispatchToProps = {
  setCheckbox,
  setInput: setSimpleDataFormEditTask,
  setSelectObservers,
  setSelectValues,
  setSelectSelectedValue,
}

export default connect(mapStateToProps, mapDispatchToProps)(DescriptionTab);
