import React, { FC, useEffect, useState } from "react";
import { connect } from "react-redux";
import { State } from "../../../../../../rootReducer";
import { errorValidationMessages } from "../../../../../ObjectsPage/constants";

import './InputTypicalTask.scss';

const InputTypicalTask: FC<{
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
  onCheck: (isValid: boolean, type: string) => void,
  value: string,
  checkTrigger: string
}> = ({
  onChange,
  value,
  checkTrigger,
  onCheck
}) => {
  const errorMessages = {
    max: errorValidationMessages.MAX100,
    required: errorValidationMessages.REQUIRED
  };
  
  const [skipCheck, setSkipCheck] = useState<boolean>(true);
  const [errorState, setErrorState] = useState<string>();
  
  const checkHelper = (e?: React.ChangeEvent<HTMLInputElement>) => {
    const valueLocal = e?.target.value ? e?.target.value : value;
    let isValid = true;
    
    setErrorState('');

    if(skipCheck) {
      setSkipCheck(false);
      return;
    }

    if(!valueLocal?.length) {
      setErrorState(errorMessages[ 'required' ]);
      isValid = false;
    }
    
    if(valueLocal?.length >= 100) {
      setErrorState(errorMessages[ 'max' ]);
      isValid = false;
    }
      
    onCheck(isValid, 'input');
  };
  
  const onChangeLocal = (e: React.ChangeEvent<HTMLInputElement>) => {
    checkHelper(e);
    onChange(e);
  }

  useEffect(() => {
    checkHelper();
  }, [checkTrigger]);
  
  return ( 
    <div className={'input-typical-task' +
                    (errorState ? ' input-typical-task--error' : '')
    }>
      <div className="input-typical-task__title">Наименование поля</div>
      <input type="text" 
             className="input-typical-task__input" 
             placeholder='Введите значение...'
             value={value}
             onChange={onChangeLocal}
      />

      {errorState && (
        <div className="input-typical-task__error-message">
          {errorState}
        </div>
      )}
    </div>
  )  
}
const mapStateToProps = (state: State) => {
  return {
    checkTrigger: state.typicalTasksPage.extraForm.checkTrigger
  }
}

const mapDispatchToProps = {
  
}

export default connect(mapStateToProps, mapDispatchToProps)(InputTypicalTask);
