// Верхнее меню в экране "Задачи"
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { State } from "../../../../rootReducer";
import Button from "../../../../common/components/newComponent/Buttons/Button";
import Icon from "../../../../common/components/Icon";
import { ShowFiltersWindow } from "../../../../common/actions";
import FiltersTasksWindow from "../ui/ModalFiltersTasks/ModalFiltersTasks";
import { setFilters } from "../../../../common/actions";

import "./FiltersTasks.scss";
import { getUsersFilterData } from "../../selectors";
import { useHistory } from "react-router-dom";

  import {setFormOpen} from "../../../ObjectsPage/components/FormAddTask/actions" 
import UserProjectSelect from "../../../../common/components/Selects/UserProjectSelect/UserProjectSelect";

type Props = {
  ShowFiltersWindow: () => void;
  showFiltersWindow: boolean;
  filters: any;
  dataForUserSelect: any;
  setFilters: (
    data_from?: string,
    date_to?: string,
    executors?: number[],
    authors?: number[],
    statuses?: number[],
    actual?: boolean
  ) => void;
  current_user: number;
  setFormOpen: (mode: boolean) => void;
};

const FiltersTasks: React.FC<Props> = ({
  ShowFiltersWindow,
  showFiltersWindow,
  filters,
  dataForUserSelect,
  setFilters,
  current_user,
  setFormOpen,
}) => {
  let history = useHistory();

  let default_filter_active = false;
  let filter_btn_inscription;
  Object.keys(filters["default_templates"]).forEach((item) => {
    if (filters["default_templates"][item].active) {
      filter_btn_inscription = item;
      default_filter_active = true;
    }
  });
  if (filter_btn_inscription === undefined) {
    Object.keys(filters["templates"]).forEach((item) => {
      if (filters["templates"][item].active) {
        filter_btn_inscription = item;
      }
    });
  }

  useEffect(() => {
    setFilters("", "", [], [], [], false);
    history.replace(`${current_user}`);
  }, []);

  const clickAddTaskHandler = (e: any) => {
    e.stopPropagation();
    setFormOpen(true);
  };

  return (
    <div className="filters-obj-tasks">
      <div className="filters-obj-tasks__left">
        <div className="filters-obj-tasks__filters-selectors">
          <div className="filters-obj-tasks__filters-selector-item">
            <div className="filters-obj-tasks__filters-selector-item-element">
              <Button
                design={default_filter_active ? "outline" : "blue_lightest"}
                onClick={() => ShowFiltersWindow()}
                style={{
                  border: "1px solid #DBDBDB",
                  height: "50px",
                  marginRight: "8px",
                  marginTop: "-5px",
                  marginLeft: "10px",
                  padding: "10px",
                  minWidth: "180px",
                  display: "block",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  color: "#292A34",
                  fontWeight: "400",
                  fontSize: "18px",
                  textAlign: "center",
                }}
              >
                <Icon
                  name={"VectorFilter"}
                  style={{ marginRight: "5px", paddingBottom: "4px" }}
                />
                <span>
                  {" "}
                  {filter_btn_inscription === "Все"
                    ? "Все фильтры"
                    : filter_btn_inscription
                    ? filter_btn_inscription
                    : "Пользовательские"}{" "}
                </span>
              </Button>
            </div>
          </div>

          <div className="filters-obj-tasks__filters-selector-item">
            <div className="filters-obj-tasks__filters-selector-item-title">
              Для сотрудника
            </div>
            <div className="filters-obj-tasks__filters-selector-item-element filters-obj-tasks__filters-selector-item-element--width">
              <UserProjectSelect
                type="tasks"
                itemsList={dataForUserSelect?.list}
                attached_items={dataForUserSelect?.attached_items}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="filters-obj-tasks__right">
        <button
          className="filters-obj-tasks__add-btn"
          type="button"
          onClick={(e) => clickAddTaskHandler(e)}
        >
          + Добавить задачу
        </button>
      </div>
      {showFiltersWindow && <FiltersTasksWindow context={"tasks"} />}
    </div>
  );
};

const mapStateToProps = (state: State) => {
  return {
    showFiltersWindow: state.commonInfo.filters.showFiltersWindow,
    filters: state.commonInfo.filters,
    dataForUserSelect: getUsersFilterData(state),
    current_user: state.commonInfo.current_user,
    // isFormOpen: state.tasksObjects.,
  };
};

const mapDispatchToProps = {
  ShowFiltersWindow,
  setFilters,
  setFormOpen,
};

export default connect(mapStateToProps, mapDispatchToProps)(FiltersTasks);
