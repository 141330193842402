import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { State } from "../../../../../rootReducer";
import MultiSelectVanilla from "../../../../../common/components/ui/MultiSelectVanilla/MultiSelectVanilla";
import {
  getObjects,
  setCurrentPage,
  setObjectsFilterTerms,
} from "../../../actions";
import { ObjectsTerms } from "../../../../../common/constants";
import { getTermsFilterData, getTermsFilterIds } from "../../../selectors";

type Props = {
  termsFilterData: any;
  termsFilterId: number[];
  setObjectsFilterTerms: (terms: number[]) => void;
  disabled?: boolean;
  getObjects: (params?: object) => void;
  setCurrentPage: (currentPage: number) => void;
};

const TermSelectObjects: React.FC<Props> = ({
  termsFilterData,
  termsFilterId,
  setObjectsFilterTerms,
  disabled,
  getObjects,
  setCurrentPage,
}) => {
  const [placeholder, setPlaceholder] = useState<any>(ObjectsTerms.ALL_TEXT);
  const [skipGetObjects, setSkipGetObjects] = useState<boolean>(true);

  const open = () => {
    setSkipGetObjects(false);
  };

  useEffect(() => {
    if (!skipGetObjects) {
      getObjects();
    }

    if (termsFilterData.selected.length === 0) {
      setPlaceholder("Все");
    } else if (termsFilterData.selected.length < 2) {
      setPlaceholder(termsFilterData?.selected[0]?.text);
    } else {
      setPlaceholder(termsFilterData?.selected[0]?.text + " и другие...");
    }
  }, [termsFilterId]);

  return (
    <MultiSelectVanilla
      withImage={false}
      placeholder={placeholder}
      disabled={disabled}
      itemsList={termsFilterData.list}
      showSearchField={false}
      selectedItems={termsFilterData.selected}
      isValid={termsFilterData.selected.length > 0}
      openHandler={() => open()}
      addHandler={(value: number) => {
        // По логике работы из ТЗ это должен быть взаимоисключающий селект, одиночный выбор. А в ТЗ указан мульти-селект
        setObjectsFilterTerms([value as number]);
        setCurrentPage(1);
      }}
      removeHandler={(value: number) => {
        setObjectsFilterTerms(termsFilterId.filter((id) => id !== value));
      }}
    />
  );
};

const mapStateToProps = (state: State) => {
  return {
    termsFilterId: getTermsFilterIds(state),
    termsFilterData: getTermsFilterData(state),
  };
};

const mapDispatchToProps = {
  setObjectsFilterTerms,
  getObjects,
  setCurrentPage,
};

export default connect(mapStateToProps, mapDispatchToProps)(TermSelectObjects);
