import { getPositions } from "../../common/selectors";
import { State } from "../../rootReducer";
import { createSelector } from "reselect";
import { Item, User } from "../../common/types";

export const getUsers = (state: State): User[] => state.commonInfo.users;
export const selectedUserId = (state: State): number =>
  state.tasksPage.selectedUserId;
export const current_user = (state: State): any =>
  state.commonInfo.current_user;


export const getUsersFilterData = createSelector(
  [getUsers, getPositions, selectedUserId, current_user],
  (users, positions, selectedUserId, current_user) => {
    let selected = null as Item | null;

    let found_my_user = users?.find((user) => {
      return user.id === current_user;
    });

    let attached_items: Item[] = [];
    if (found_my_user) {
      attached_items.push({
        text: `${found_my_user.surname} ${found_my_user.name}`,
        name_cap_letters:
          found_my_user.surname.substr(0, 1).toUpperCase() +
          found_my_user.name.substr(0, 1).toUpperCase(),
        value: found_my_user.id,
        subtitle: found_my_user.email,
        position: positions.find(
          (pos) => pos.id === found_my_user?.positions[0]
        )?.name,
        urlImage: found_my_user?.image?.url,
      });
    }

    let list = users
      ?.map((user) => {
        let item = {
          text: `${user.surname} ${user.name}`,
          name_cap_letters:
            user.surname.substr(0, 1).toUpperCase() +
            user.name.substr(0, 1).toUpperCase(),
          value: user.id,
          subtitle: user.email,
          position: positions.find((pos) => pos.id === user.positions[0])?.name,
          urlImage: user?.image?.url,
        };
        if (user.id === selectedUserId) selected = item;
        return item;
      })
      .filter((list_item) => list_item.value !== current_user);

    return { selected, list, attached_items };
  }
);


