import React, { FC, useEffect } from "react";
import { connect } from "react-redux";
import { State } from "../../../../../../../rootReducer";
import { INormalFormTreaty } from "../../../../../interfaces";
import { ReactComponent as CalendarIcon } from "../../../../../../../img/TrueIcons/calendar.svg";
import { ReactComponent as UserTaskIco } from "../../../../../../../img/TrueIcons/user-task.svg";
import { ReactComponent as BriefcaseIco } from "../../../../../../../img/TrueIcons/briefcase.svg";
import moment from "moment";
import { TypicalTreatiesActivity } from "../../../../../interfaces";
import { TypeTypicalTreatiesActivity } from "../../../../../action_types";
import {
  setNormalForm,
  setTypicalTreatiesFilterActivity,
} from "../../../../../actions";
import SelectTypicalTask from "../../../../../../TypicalTasks/components/FormTasks/ui/SelectTypicalTask/SelectTypicalTask";

import "./OtherTreatyCard.scss";

const OtherTreatyCard: FC<{
  setNormalForm: (mode: INormalFormTreaty) => void;
  author?: string;
  createdAt?: string;
  activity?: TypeTypicalTreatiesActivity;
  main?: boolean;
}> = ({ setNormalForm, author, createdAt, activity, main }) => {
  const formatDate = (date) => moment(date).format("DD.MM.YYYY");
  const dataActivityValues = [
    {
      text: TypicalTreatiesActivity.ACTIVE_TEXT_FORM as string,
      value: TypicalTreatiesActivity.ACTIVE_STATUS,
    },
    {
      text: TypicalTreatiesActivity.NOT_ACTIVE_TEXT_FORM as string,
      value: TypicalTreatiesActivity.NOT_ACTIVE_STATUS,
    },
  ];

  const onChangeSelect = (value: TypeTypicalTreatiesActivity) => {
    setNormalForm({ activity: value });
  };

  return (
    <div className="other-treaty">
      <div className="other-treaty__title">Прочее</div>

      <div className="other-treaty__items">
        {author && (
          <div className="other-treaty__item">
            <div className="other-treaty__item-left">
              <div className="other-treaty__item-title-wr">
                <div className="other-treaty__item-title-ico">
                  <UserTaskIco />
                </div>
                <div className="other-treaty__item-title">Автор</div>
              </div>
            </div>

            <div className="other-treaty__item-right">
              <div className="other-treaty__item-text">{author}</div>
            </div>
          </div>
        )}

        {createdAt && (
          <div className="other-treaty__item">
            <div className="other-treaty__item-left">
              <div className="other-treaty__item-title-wr">
                <div className="other-treaty__item-title-ico">
                  <CalendarIcon />
                </div>
                <div className="other-treaty__item-title">Дата создания</div>
              </div>
            </div>

            <div className="other-treaty__item-right">
              <div className="other-treaty__item-text">{formatDate(createdAt)}</div>
            </div>
          </div>
        )}

        <div className="other-treaty__item">
          <div className="other-treaty__item-left">
            <div className="other-treaty__item-title-wr">
              <div className="other-treaty__item-title-ico">
                <BriefcaseIco />
              </div>
              <div className="other-treaty__item-title">Состояние</div>
            </div>
          </div>

          <div className="other-treaty__item-right">
            <div className="other-treaty__item-select">
              {main ? "Активный" : 
              <SelectTypicalTask
                itemsList={dataActivityValues}
                onChangeSelect={(value) => onChangeSelect(value)}
                selectedItem={main ? 1 : activity}
              />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: State) => {
  return {
    author: state.typicalTreatyPage.normalForm.author,
    activity: state.typicalTreatyPage.normalForm.activity,
    createdAt: state.typicalTreatyPage.normalForm.createdAt,
    main: state.typicalTreatyPage.normalForm.main,
  };
};

const mapDispatchToProps = {
  setNormalForm,
  setTypicalTreatiesFilterActivity,
};

export default connect(mapStateToProps, mapDispatchToProps)(OtherTreatyCard);
