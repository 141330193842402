import React, { ChangeEvent, FC, useEffect, useState } from "react";
import TextAreaSimple from "../../../../common/components/ui/TextAreaSimple/TextAreaSimple";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { DataTypes, FieldSizes, TabIdsExtraForm } from "../../../TypicalTasks/constants";
import SelectSimple from "../../../../common/components/ui/SelectSimple/SelectSimple";
import CheckboxMui from "../../../../common/components/ui/CheckboxMui/CheckboxMui";
import {
  IExtraArrayValuesSelected,
  IFormAddTaskAdditionalFields,
  IObjectsFormAddTaskDataSelect
} from "../FormAddTask/interfaces";
import {
  setChecklist,
  setExtraFileValue,
  setExtraInputValue,
  setExtraRadio,
  setExtraSelectChecklistValues,
  setExtraSelectedValue,
  setExtraSelectSelectedValue,
  setExtraSelectValues
} from "../FormEditTask/actions";
import { connect } from "react-redux";
import { State } from "../../../../rootReducer";
import {
  getCheckboxes, 
  getChecklist,
  getFileServerSelected,
  getInputServerSelected,
  getRadioServerSelected,
  getSelect,
  getSelectServerSelected
} from "../FormEditTask/selectors";
import { IEditingObjectsFile } from "../../interfaces";
import { nanoid } from "nanoid";
import FileItem from "../ui/FileItem/FileItem";
import { errorValidationMessages } from "../../constants";
import InputSimple from "../../../../common/components/ui/InputSimple/InputSimple";
import { IChecklistTask } from "../FormEditTask/interfaces";

import './AdditionalBlock.scss';

const AdditionalBlock:FC<{
  data: IFormAddTaskAdditionalFields,
  from?: string,
  setExtraSelectedValue: ( name: keyof IFormAddTaskAdditionalFields, data: IExtraArrayValuesSelected[] ) => void
  setExtraSelectChecklistValues: (data: IExtraArrayValuesSelected|IExtraArrayValuesSelected[]) => void,
  setExtraRadio: (value: string, serverId: string) => void,
  setExtraInputValue: (value: string, serverId: string) => void,
  setExtraFileValue: (value: IEditingObjectsFile) => void,
  setExtraSelectValues: (values: IObjectsFormAddTaskDataSelect[]) => void,
  setExtraSelectSelectedValue: (value: IObjectsFormAddTaskDataSelect) => void,
  checklistDataSelector: IExtraArrayValuesSelected[],
  checkboxesDataSelector: IExtraArrayValuesSelected[],
  selectDataSelector: IObjectsFormAddTaskDataSelect[],
  checklist?: IChecklistTask[],
  checkboxesSelected?: IExtraArrayValuesSelected[]
  selectSelectedValues?: IObjectsFormAddTaskDataSelect[]
  selectSelectedValue?: IObjectsFormAddTaskDataSelect
  radioSelected?: { value: string },
  radioSelectedServerSelector: {value: any, serverId: string},
  selectSelectedServerSelector: {value: any, serverId: string},
  inputSelectedServerSelector: {value: any, serverId: string},
  fileSelectedServerSelector: IEditingObjectsFile,
  selectedFile?: IEditingObjectsFile,
  checkTrigger: string,
  onSetExtraFiles?: (files: any) => void,
  setChecklist: (data: IChecklistTask) => void
}> = ({
  data,
  from,
  setExtraSelectedValue,
  setExtraSelectChecklistValues,
  setExtraRadio,
  setExtraInputValue,
  setExtraSelectValues,
  setExtraFileValue,
  setExtraSelectSelectedValue,
  checklistDataSelector,
  checkboxesDataSelector,
  selectDataSelector,
  checklist,
  checkboxesSelected,
  selectSelectedValues,
  selectSelectedValue,
  radioSelected,
  selectedFile,
  checkTrigger,
  onSetExtraFiles,
  radioSelectedServerSelector,
  selectSelectedServerSelector,
  inputSelectedServerSelector,
  fileSelectedServerSelector,
  setChecklist
}) => {
  const [isExecuteTab, setIsExecuteTab] = useState<boolean>(from === 'executionTab');
  const [filesToUpload, setFilesToUpload] = useState<any>();

  const [progressCurrent, setProgressCurrent] = useState<number>(0);
  const [progressTotal, setProgressTotal] = useState<number>(0);
  const [progressWidth, setProgressWidth] = useState<number>(0);
  
  const [errorCheckboxState, setErrorCheckboxState] = useState<string[]>([]);
  const [errorRadioState, setErrorRadioState] = useState<string[]>([]);
  const [errorFileState, setErrorFileState] = useState<string[]>([]);
  
  const onChangeChecklist = (event: React.ChangeEvent<HTMLInputElement>, chk: IChecklistTask) => {
    setChecklist({
      ...chk,
      done: event.target.checked
    })
  };
  
  const onChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>, id: string|number, name: string, from: keyof IFormAddTaskAdditionalFields, serverId?: string) => {
    setExtraSelectedValue(from, {
      // @ts-ignore
      id,
      value: name,
      checked: event.target.checked,
      serverId
    });
  };
  
  const getIsRequired = (tabId): boolean => {
    let isRequired = false;

    data.extra.forEach(field => {
      if(field.id === tabId) {
        isRequired = field.required;
      }
    });
    
    return isRequired;
  };
  
  const checkCheckboxes = (data, tabId) => {
    const isRequired = getIsRequired(tabId);
    
    if(isRequired) {
      if(tabId === TabIdsExtraForm.CHECKBOX_TAB) {
        setErrorCheckboxState([]);

        let ok = false;

        data.forEach(checkbox => {
          if(checkbox.checked) {
            ok = true;
          }
        });

        if(!ok) setErrorCheckboxState([errorValidationMessages.REQUIRED]);
      }

      if(tabId === TabIdsExtraForm.RADIO_TAB) {
        setErrorRadioState([]);
        if(!data.value?.length) setErrorRadioState([errorValidationMessages.REQUIRED]);
      }

      if(tabId === TabIdsExtraForm.DOCUMENT_TAB) {
        setErrorFileState([]);
        if(!data.name?.length) setErrorFileState([errorValidationMessages.REQUIRED]);
      }
    }
  }
  
  const onChangeRadio = (event: React.ChangeEvent<HTMLInputElement>, serverId: string) => {
    setExtraRadio(event.target.value, serverId);
  };

  const onChangeSelect = (data: IObjectsFormAddTaskDataSelect, serverId: string) => {
    data.serverId = serverId;
    
    setExtraSelectSelectedValue(data);
  };

  const onChangeTextArea = (
    e: React.ChangeEvent<HTMLTextAreaElement|HTMLInputElement>, 
    serverId: string
  ) => {
    setExtraInputValue(e.target.value, serverId);
  };
  
  const calculateProgressWidth = (checklistSelected: IChecklistTask[]) => {
    const totalLength = checklistSelected.length;
    const gap = +(100 / totalLength).toFixed(2);
    let currentLength = 0;

    checklistSelected.forEach(checklist => {
      if(checklist.done) {
        currentLength++
      }
    });

    setProgressTotal(totalLength);
    setProgressCurrent(currentLength);
    setProgressWidth(currentLength * gap);
  };

  const translateFile = (file, serverId) => {
    let reader = new FileReader();

    reader.onload = function () {
      // @ts-ignore
      let result = [...new Uint8Array(reader.result)]
        .map((item) => item.toString(16).padStart(2, "0"))
        .join("");

      setFilesToUpload([{
        id: serverId,
        name: file.name,
        ext: getExtension(file.name),
        data: result
      }]);

      return result;
    };

    reader.readAsArrayBuffer(file);
  }

  function getExtension(filename: string): string {
    return filename.substring(filename.lastIndexOf('.') + 1);
  }
  
  const onChangeFile = (e: ChangeEvent<HTMLInputElement>, serverId: string) => {
    const { files } = e.currentTarget;
    let filesDataArr: IEditingObjectsFile[] = [];

    if(files) {
      for(let i = 0; i < files.length; ++i) {
        filesDataArr.push({
          id: nanoid(6),
          name: files[i].name,
          type: files[i].type,
          size: Math.trunc(files[i].size / 1024).toLocaleString()
        });
      }

      setExtraFileValue(filesDataArr[0]);
      translateFile(files[0], serverId);
    }
  }

  const onDeleteFileHandler = (id: string|number, name: string) => {
    setExtraFileValue({ id: "", link: "", name: "", size: '', type: "" });

    setFilesToUpload((state) => {
      const newState: any = [];

      if(state) {
        // @ts-ignore
        for(let file of state) {
          if(file.name !== name) newState.push(file);
        }
      }

      return newState;
    });
  };
  
  useEffect(() => {
    if(fileSelectedServerSelector.name?.length) {
      setExtraFileValue(fileSelectedServerSelector);
    }
  }, [fileSelectedServerSelector]);
  
  useEffect(() => {
    if(inputSelectedServerSelector.value?.toString().length) {
      setExtraInputValue(inputSelectedServerSelector.value, inputSelectedServerSelector.serverId);
    }
  }, [inputSelectedServerSelector]);
  
  useEffect(() => {
    if(radioSelectedServerSelector.value?.length) {
      setExtraRadio(radioSelectedServerSelector.value, radioSelectedServerSelector.serverId);
    }
  }, [radioSelectedServerSelector]);
  
  useEffect(() => {
    if(selectSelectedServerSelector.value?.length) {
      setExtraSelectSelectedValue({
        label: selectSelectedServerSelector.value, 
        value: selectSelectedServerSelector.value, 
        serverId: selectSelectedServerSelector.serverId
      });
    }
  }, [selectSelectedServerSelector]);
  
  useEffect(() => {
    if(filesToUpload?.length) {
      if(onSetExtraFiles) {
        onSetExtraFiles(filesToUpload);
      }
    }
  }, [filesToUpload]);

  useEffect(() => {
    if(checklistDataSelector?.length) {
      setExtraSelectChecklistValues(checklistDataSelector);
    }
  }, [checklistDataSelector]);
  
  useEffect(() => {
    if(checklist?.length) {
     calculateProgressWidth(checklist);
    }
  }, [checklist]);

  useEffect(() => {
    if(checkboxesDataSelector?.length && !checkboxesSelected?.length) {
      setExtraSelectedValue('checkboxesSelected', checkboxesDataSelector);
    }
  }, [checkboxesDataSelector]);
  
  useEffect(() => {
    if(selectDataSelector?.length && !selectSelectedValues?.length) {
      setExtraSelectValues(selectDataSelector);
    }
  }, [selectDataSelector]);

  useEffect(() => {
    if(checkTrigger.length) {
      if(data.checkboxesSelected?.length) {
        checkCheckboxes(data.checkboxesSelected, TabIdsExtraForm.CHECKBOX_TAB);
      }
    }
  }, [checkTrigger, data.checkboxesSelected]);

  useEffect(() => {
    if(checkTrigger.length) {
      checkCheckboxes(radioSelected, TabIdsExtraForm.RADIO_TAB);
    }
  }, [checkTrigger, radioSelected]);

  useEffect(() => {
    if(checkTrigger.length && data.selectedFile) {
      checkCheckboxes(data.selectedFile, TabIdsExtraForm.DOCUMENT_TAB);
    }
  }, [checkTrigger, data.selectedFile]);
  
  return (
    <div className={"additional-block" +
                  (isExecuteTab ? ' additional-block--execution' : '')
    }>
      {(checklistDataSelector && checklistDataSelector.length > 0) && (
        <div className="additional-block__full-form-item">
          {!isExecuteTab && (
            <div className="additional-block__full-form-item-left">
              <div className="additional-block__full-form-item-title">Состав чек-листа</div>
            </div>
          )}

          <div className="additional-block__full-form-item-right">
            {isExecuteTab && (
              <>
                <div className="additional-block__full-form-item-title">
                  Состав чек-листа
                  ({progressCurrent}/{progressTotal})
                </div>

                <div className="additional-block__checklist-progress">
                  <div className="additional-block__checklist-progress-line"
                       style={{width: progressWidth + '%'}}
                  ></div>
                </div>
              </>
            )}

            <ol className="additional-block__full-form-item-list">
              {data.checklist?.map((chk, i) => (
                <>
                  {!isExecuteTab && (
                    <li key={i}>
                      {chk}
                    </li>
                  )}

                  {isExecuteTab && (
                    <li key={chk.id}>
                      <CheckboxMui
                        title={chk.name}
                        checked={chk.done}
                        handleChange={(e) => onChangeChecklist(e, chk)}
                      />
                    </li>
                  )}
                </>
              ))}
            </ol>
          </div>
        </div>
      )}

      {data.extra?.map((field, i) => (
        <div className="additional-block__full-form-item" key={i}>
          <div className="additional-block__full-form-item-left">
            <div className="additional-block__full-form-item-title">
              {field.required && (
                <>* </>
              )}
              {field.name}
              {field.data?.type === DataTypes.VALUE_NUMBERS && (
                <div style={{fontSize: '11px'}}>(только числовые значения)</div>
              )}
            </div>
          </div>

          <div className="additional-block__full-form-item-right">
            {(field.id === TabIdsExtraForm.INPUT_TAB) && (
              <div className='additional-block__full-form-textarea'>
                {(field.data?.size === FieldSizes.VALUE_BIG && field.data?.type === DataTypes.VALUE_ANY) && (
                  <TextAreaSimple
                    name='description'
                    onChange={(e) => {onChangeTextArea(e, field.serverId ?? '')}}
                    value={data.inputSelected?.value ?? ''}
                    rules={[{required: field.required}, {max: 500}]}
                    checkTriggerExternal={checkTrigger}
                  />
                )}

                {(field.data?.size === FieldSizes.VALUE_STANDARD || field.data?.type === DataTypes.VALUE_NUMBERS) && (
                  <InputSimple
                    name='title'
                    inputType={field.data?.type === DataTypes.VALUE_ANY ? 'text' : 'number'}
                    onChange={(e) => {onChangeTextArea(e, field.serverId ?? '')}}
                    checkTriggerExternal={checkTrigger}
                    value={data.inputSelected?.value ?? ''}
                    rules={[{required: field.required}, {max: 100}]}
                  />
                )}
              </div>
            )}

            {((field.id === TabIdsExtraForm.SELECT_TAB) && selectSelectedValues) && (
              <div className='additional-block__full-form-select'>
                <SelectSimple
                  name={null}
                  selectedValue={{value: selectSelectedValue?.value ?? '', label: selectSelectedValue?.label ?? ''}}
                  dataForSelect={selectSelectedValues}
                  onChange={(e) => {onChangeSelect(e, field.serverId ?? '')}}
                  rules={[{required: field.required}]}
                  checkTriggerExternal={checkTrigger}
                />
              </div>
            )}

            {(field.id === TabIdsExtraForm.RADIO_TAB) && (
              <div className='additional-block__full-form-radio'>
                <RadioGroup value={radioSelected?.value}
                            onChange={(e) => onChangeRadio(e, field.serverId ?? '')}
                >
                  {field.data?.list && field.data.list?.map((value, j) => (
                    <FormControlLabel
                      key={j}
                      value={value}
                      control={<Radio />}
                      label={value}
                    />
                  ))}
                </RadioGroup>

                {errorRadioState.length > 0 && (
                  <div className="additional-block__error-messages">
                    {errorRadioState.map((error, i) => (
                      <div className="additional-block__error-message" key={i}>
                        {error}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}
            
            {(field.id === TabIdsExtraForm.CHECKBOX_TAB) && (
              <div className='additional-block__full-form-checkbox'>
                {checkboxesSelected?.map((value) => (
                  <CheckboxMui
                    key={value.id}
                    title={value.value}
                    checked={value.checked}
                    handleChange={(e) => onChangeCheckbox(e, value.id ?? '', value.value, 'checkboxesSelected', field.serverId ?? '')}
                  />
                ))}

                {errorCheckboxState.length > 0 && (
                  <div className="additional-block__error-messages">
                    {errorCheckboxState.map((error, i) => (
                      <div className="additional-block__error-message" key={i}>
                        {error}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}

            {(field.id === TabIdsExtraForm.DOCUMENT_TAB) && (
              <div className='additional-block__full-form-file'>
                {(selectedFile && !selectedFile.link?.length) && (
                  <button className="additional-block__full-form-file-btn" type='button'>
                    <input className="additional-block__full-form-file-btn-file"
                           type="file"
                           multiple={false}
                           onChange={(e) => onChangeFile(e, field.serverId ?? '')}
                           onClick={(e) => e.currentTarget.value = ''}
                    />
                    <span className="additional-block__full-form-file-btn-title">Прикрепить</span>
                  </button>
                )}
                
                {(selectedFile && selectedFile.name?.length > 0) && (
                  <div className="additional-block__file-info">
                    <FileItem
                      data={selectedFile}
                      onDelete={onDeleteFileHandler}
                    />
                  </div>
                )}

                {errorFileState.length > 0 && (
                  <div className="additional-block__error-messages">
                    {errorFileState.map((error, i) => (
                      <div className="additional-block__error-message" key={i}>
                        {error}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  )
}

const mapStateToProps = (state: State) => ({
  checklist: state.objectsFormEditTask.additionalFields.checklist,
  checkboxesSelected: state.objectsFormEditTask.additionalFields.checkboxesSelected,
  radioSelected: state.objectsFormEditTask.additionalFields.radioSelected,
  selectSelectedValues: state.objectsFormEditTask.additionalFields.selectSelectedValues,
  selectSelectedValue: state.objectsFormEditTask.additionalFields.selectSelectedValue,
  selectedFile: state.objectsFormEditTask.additionalFields.selectedFile,
  checklistDataSelector: getChecklist(state),
  checkboxesDataSelector: getCheckboxes(state),
  selectDataSelector: getSelect(state),
  checkTrigger: state.objectsFormEditTask.checkTrigger,
  inputSelectedServerSelector: getInputServerSelected(state),
  radioSelectedServerSelector: getRadioServerSelected(state),
  selectSelectedServerSelector: getSelectServerSelected(state),
  fileSelectedServerSelector: getFileServerSelected(state)
});

const mapDispatchToProps = {
  setExtraSelectedValue,
  setExtraSelectChecklistValues,
  setExtraRadio,
  setExtraSelectSelectedValue,
  setExtraSelectValues,
  setExtraInputValue,
  setExtraFileValue,
  setChecklist
};

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalBlock);
