import React, { FC } from "react";
import Object from "./components/Object/Object";
import { connect } from "react-redux";
import { State } from "../../../../rootReducer";
import { IObjectsServerData } from "../../interfaces";
import PreloaderLocal from "../../../../common/components/PreloaderLocal/PreloaderLocal";
import {
  getObjects,
  setCurrentPage,
  setIsEditObjectForm,
  setObjectsEditFormDefaultValues,
  setObjectsEditingFormOpen
} from "../../actions";
import TitleTasks from "../ObjectsTableHeader/TableHeader";
import FilterObjects from "../FilterObjects/FilterObjects";
import { nanoid } from "nanoid";
import { IMeta } from "../../../../common/types";

import "./styles.scss";

const ObjectList: FC<{
  objects: IObjectsServerData[];
  pageNum: number;
  setCurrentPage: (mode: number) => void;
  isLoadingObjects: boolean;
  setObjectsEditingFormOpen: (isEditingObjectsFormOpen: boolean) => void,
  setObjectsEditFormDefaultValues: (trigger: string) => void,
  setIsEditObjectForm: (isEditObjectsForm: boolean) => void,
  meta: IMeta|null
  getObjects: (params?: object, action?: string) => void;
}> = ({ 
  objects, 
  pageNum, 
  setCurrentPage, 
  isLoadingObjects,
  setObjectsEditingFormOpen,
  setObjectsEditFormDefaultValues,
  setIsEditObjectForm,
  meta,
  getObjects
}) => {
  const scrollHandler = (e) => {
    const { scrollHeight, scrollTop, clientHeight } = e.currentTarget;

    if((scrollHeight <= scrollTop + clientHeight + 10) &&
      pageNum < (meta?.last_page ?? 1)
    ) {
      setCurrentPage(pageNum + 1);
      getObjects({}, 'add');
    }
  }

  const formOpenHandler = () => {
    setObjectsEditFormDefaultValues(nanoid(6));
    setIsEditObjectForm(false);
    setObjectsEditingFormOpen(true);
  };

  return (
    <div className="main-objects">
      <h1 className="main-objects__title">Объекты</h1>
      
      <div className="main-objects__filters">
        <FilterObjects />
      </div>
      
      <div className="main-objects__content">
        <TitleTasks />
        <div className="main-objects__table">
          <div
            className={`main-objects__preloader-wr 
                          ${
                            isLoadingObjects
                              ? " main-objects__preloader-wr--show"
                              : ""
                          }`}
          >
            <div className="main-objects__preloader">
              <PreloaderLocal />
            </div>
          </div>

          <div className="main-objects__table-items"
                onScroll={(e: React.MouseEvent<HTMLDivElement>) => scrollHandler(e)}
          >
            {objects?.length > 0  ? (
              objects.map((item) => {
                return <Object object={item} key={item?.id} />;
              })
            ) : (
              <div className="info_none_objects">
                Список объектов пока пуст, вы можете
                <span className="add_obj_link"
                      onClick={formOpenHandler}
                >
                  добавить объект
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: State) => ({
  objects: state.objectsPage.objects,
  isLoadingObjects: state.objectsPage.isLoadingObjects,
  pageNum: state.objectsPage.currentPage,
  meta: state.objectsPage.meta
});

const mapDispatchToProps = {
  setCurrentPage,
  setObjectsEditingFormOpen,
  setObjectsEditFormDefaultValues,
  setIsEditObjectForm,
  getObjects
};

export default connect(mapStateToProps, mapDispatchToProps)(ObjectList);
