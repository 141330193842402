import React, { useState, useCallback, useLayoutEffect, useRef } from 'react';
import Input from '../../../common/components/Inputs/Input';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { validatePassword } from '../../../utils/validPatterns';
import { fetchData } from '../../../utils/fetchData';
import { CreateNotif } from '../../../utils/createNotification';
import CustomBlock from './CustomBlock';
import Button from '../../../common/components/newComponent/Buttons/Button';
import styled from 'styled-components';
import { cookieMaster } from '../../../utils/CookieMaster';
import { State } from '../../../rootReducer';
import { connect } from 'react-redux';

const FormStyle = styled.form`
  display: flex;
  flex-direction: column;

  div:first-child {
    margin-bottom: 4px;
  }
  button {
    margin-top: 24px;
  }
`;

type Props = {
  email:string|undefined
};


const PasswordRecovery: React.FC<Props> = ({email}) => {

    // Получаем из state для текущего User email


  const [password, setPassword] = useState('');
  const [password_confirmation, setPasswordConfirm] = useState('');
  const [in_load, setInload] = useState(false);
  const location = useLocation();
  const history = useHistory();

 // console.log("email",email)

  let emailRef = useRef(email);
  
  console.log("emailRef.current",emailRef.current)

  const formSubmitHandler = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      setInload(true);
      let token =  cookieMaster.getCookie('access_token'),
        emailRef ;

      let response = await fetchData.post(
        '/api/v1/password/change',
        JSON.stringify({
          email,
          token,
          password,
          password_confirmation,
        })
      );
      if (response?.success) {
        CreateNotif('Пароль успешно изменен', 'success');
      }
      setInload(false);
    },
    [
      history,
      location.pathname,
      location.search,
      password,
      password_confirmation,
    ]
  );


  return (
    <CustomBlock title="Новый пароль">
      <FormStyle onSubmit={formSubmitHandler}>
        <Input
          inputType="password"
          placeholder="Новый пароль"
          withIcon={true}
          value={password}
          title=""
          changeHandler={(value) => setPassword(value)}
          isValid={password ? validatePassword(password) : true}
          validText="Пароль должен состоять из 6-30 символов и включать в себя хотя бы одно число и букву в верхнем регистре"
        />
        <Input
          inputType="password"
          placeholder="Повторить"
          withIcon={true}
          value={password_confirmation}
          title=""
          changeHandler={(value) => setPasswordConfirm(value)}
          isValid={password ? validatePassword(password) : true}
          validText="Пароль должен состоять из 6-30 символов и включать в себя хотя бы одно число и букву в верхнем регистре"
        />
        <Button big title="Сохранить" isSending={in_load} />
      </FormStyle>
    </CustomBlock>
  );
};


const mapStateToProps = (state: State) => {
  return {
    email:state.commonInfo?.currentUserInfo?.email,
  };
};

export default connect(mapStateToProps)(PasswordRecovery);