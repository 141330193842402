import React  from 'react';
import { connect } from 'react-redux';
import { State } from "../../../../../rootReducer";
import { getUsersSpectatorsData, getUsersSpectatorsIds } from "../../../selectors";
import MultySelect from "../../../../../common/components/Selects/MultySelect";

type Props = {
  usersData: any;
  usersIds: number[];
  disabled?: boolean;
  onChangeObservers: (ids: number[]) => void;
};

const SelectObserversObjects: React.FC<Props> = ({
  usersData,
  usersIds,
  disabled,
  onChangeObservers
}) => {
  return (
    <MultySelect
      placeholder='Введите значение...'
      disabled={disabled}
      itemsList={usersData.list}
      selectedItems={usersData.selected}
      isValid={usersData.selected.length > 0}
      addHandler={(value: number) => {
        onChangeObservers([...usersIds, value as number]);
      }}
      removeHandler={(value: number) => {
        onChangeObservers(usersIds.filter((id) => id !== value));
      }}
    />
  );
};

const mapStateToProps = (state: State) => {
  return {
    usersDataIsLoading: state.objectsFormAddTask.data.spectators.usersDataIsLoading,
    usersIds: getUsersSpectatorsIds(state), // Ид выбранных юзеров
    usersData: getUsersSpectatorsData(state) // Получить юзеров для фильтрации
  };
};

const mapDispatchToProps = {
  
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectObserversObjects);
