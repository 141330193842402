import React, { useCallback, useState, useRef } from "react";
import Input from "../../../common/components/Inputs/Input";
import { Link, useHistory } from "react-router-dom";
import { fetchData } from "../../../utils/fetchData";
import CustomBlock from "./CustomBlock";
import styled from "styled-components";
import Button from "../../../common/components/newComponent/Buttons/Button";
import { State } from "../../../rootReducer";
import { connect } from "react-redux";

type Props = {
  email: string | undefined;
};

const FormStyle = styled.form`
  display: flex;
  flex-direction: column;
`;
const SpanStyle = styled.span`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #707070;
  margin-bottom: ${({ isSended }) => (isSended ? "24px" : "8px")};
`;
const WrapperButton = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  width: 100%;
  button {
    width: 132px;
  }
`;
const EmailForm2: React.FC<Props> = ({ email }) => {
  let emailRef = useRef(email);
  console.log("emailRef", emailRef);
  const [email2, setEmail] = useState(email ? email : "");
  const [isSended, setIsSended] = useState(false);
  const [isSending, setIsSending] = useState(false);
  let history = useHistory();
  let validationEmail =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const formSubmitHandler = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      setIsSending(true);
      e.preventDefault();
      let result = await fetchData.post(
        "/api/v1/password/reset",
        JSON.stringify({ email })
      );

      console.log("email", email);
      if (typeof result !== "undefined") {
        setIsSended(true);
      }
      setIsSending(false);
    },
    [email]
  );

  return (
    <CustomBlock title={isSended ? "Спасибо!" : "Изменение пароля"}>
      <FormStyle onSubmit={formSubmitHandler}>
        <SpanStyle isSended={isSended}>
          {isSended
            ? "Мы отправили инструкции по изменению пароля на ваш адрес электронной почты. Если в течение десяти минут электронное письмо не получено, проверьте правильность отправленного адреса."
            : 'Нажмите "продолжить" для начала смены пароля для указанного email:'}
        </SpanStyle>
        {!isSended ? (
          <>
            <Input
              inputType="text"
              placeholder="Email"
              title=""
              withIcon={false}
              value={email}
              changeHandler={(value) => setEmail(value)}
              disabled={true}
            />
            <WrapperButton>
              <Button
                big
                title="Продолжить"
                disabled={!validationEmail.test(email ? email : "")}
                isSending={isSending}
              />
              <Button
                big
                title="Назад"
                design="outline"
                onClick={(e) => {
                  e.preventDefault();
                  history.push("/users");
                }}
              />
            </WrapperButton>
          </>
        ) : (
          <Button
            big
            title="Готово"
            onClick={(e) => {
              e.preventDefault();
              history.push("/users");
            }}
          />
        )}
      </FormStyle>
    </CustomBlock>
  );
};

const mapStateToProps = (state: State) => {
  return {
    email: state.commonInfo?.currentUserInfo?.email,
  };
};

export default connect(mapStateToProps)(EmailForm2);
