import React, { useState } from "react";
import { connect } from "react-redux";
import { State } from "../../../../../rootReducer";
import MultiSelectVanilla from "../../../../../common/components/ui/MultiSelectVanilla/MultiSelectVanilla";
import {
  getBusinessProcess,
  setCurrentPage,
  setBPFilterActivity,
} from "../../../actions";
import { BusinessProcessActivity } from "../../../interfaces";
import { TypeBPFilterActivity } from "../../../action_types";

type Props = {
  activityFilterId: TypeBPFilterActivity;
  setBPFilterActivity: (id: TypeBPFilterActivity) => void;
  disabled?: boolean;
  isSingleSelect?: boolean;
  getBusinessProcess: (params?: object) => void;
  setCurrentPage: (currentPage: number) => void;
};

const ActivitySelectBP: React.FC<Props> = ({
  setBPFilterActivity,
  disabled,
  isSingleSelect,
  getBusinessProcess,
  setCurrentPage,
}) => {
  const [placeholder, setPlaceholder] = useState<any>(
    BusinessProcessActivity.ACTIVE_TEXT
  );
  const defaultValues = [
    {
      text: BusinessProcessActivity.ALL_TEXT,
      value: BusinessProcessActivity.ALL_STATUS,
    },
    {
      text: BusinessProcessActivity.ACTIVE_TEXT,
      value: BusinessProcessActivity.ACTIVE_STATUS,
    },
    {
      text: BusinessProcessActivity.NOT_ACTIVE_TEXT,
      value: BusinessProcessActivity.NOT_ACTIVE_STATUS,
    },
  ];

  const addHandler = (value) => {
    setCurrentPage(1);
    setPlaceholder(defaultValues[value]?.text);
    setBPFilterActivity(value);
    getBusinessProcess();
  };

  return (
    <MultiSelectVanilla
      placeholder={placeholder}
      withImage={false}
      showSearchField={false}
      isSingleSelect={isSingleSelect}
      disabled={disabled}
      itemsList={defaultValues}
      selectedItems={[]}
      addHandler={(value) => addHandler(value)}
      removeHandler={() => {}}
    />
  );
};

const mapStateToProps = (state: State) => {
  return {
    activityFilterId: state.businessPage.filters.activity,
  };
};

const mapDispatchToProps = {
  setBPFilterActivity,
  getBusinessProcess,
  setCurrentPage,
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivitySelectBP);
