import React from "react";
import { connect } from "react-redux";
import { State } from "../../../../../rootReducer";
import { TypeBPFilterPersonal } from "../../../action_types";
import {
  getBusinessProcess,
  setCurrentPage,
  setBPFilterPersonal,
} from "../../../actions";

import { BusinessProcessPersonal } from "../../../interfaces";

const PersonalToggle: React.FC<{
  personalFilterId: TypeBPFilterPersonal;
  setBPFilterPersonal: (id: TypeBPFilterPersonal) => void;
  getBusinessProcess: (params?: object) => void;
  setCurrentPage: (currentPage: number) => void;
}> = ({
  personalFilterId,
  setBPFilterPersonal,
  getBusinessProcess,
  setCurrentPage,
}) => {
  const defaultValues = [
    { text: "Все", value: BusinessProcessPersonal.NOT_PERSONAL_STATUS },
    { text: "Мои", value: BusinessProcessPersonal.PERSONAL_STATUS },
  ];

  const setValueHandler = (value) => {
    setCurrentPage(1);
    setBPFilterPersonal(value);

    getBusinessProcess();
  };

  return (
    <div className="personal-toggle">
      {defaultValues.map((item, index) => (
        <div
          className={
            "personal-toggle__item" +
            (personalFilterId === item.value
              ? " personal-toggle__item--active"
              : "")
          }
          key={index}
          onClick={() => setValueHandler(item.value)}
        >
          {item.text}
        </div>
      ))}
    </div>
  );
};

const mapStateToProps = (state: State) => {
  return {
    personalFilterId: state.businessPage.filters.personal,
  };
};

const mapDispatchToProps = {
  setBPFilterPersonal,
  getBusinessProcess,
  setCurrentPage,
};

export default connect(mapStateToProps, mapDispatchToProps)(PersonalToggle);
