import { call, debounce, put, select, takeEvery } from "redux-saga/effects";
import { TreatyPageActionTypes, checkDeleteTreatyAction, createTreatyAction, deleteTreatyStageAction, deleteTypicalTreatiesAction, getTypicalTreatyAction } from "./action_types";
import { State } from "../../rootReducer";
import { fetchData } from "../../utils/fetchData";
import { 
  deleteTypicalTreatiesFromState, 
  setDeleteModalTypicalTreaties, 
  setIsLoadingTreaty, 
  setIsLoadingTreatyCard, 
  setNormalForm, 
  setMainTreaties, 
  setTreaties, 
  setDeleteStageTreatyModal, 
  setLoadingExtraFormTreaty, 
  setBpListTreaty,
  updateTypicalTreatyStore,
  updateMainTreatyStore,
  setMainBool,
  setIsSavingTreaty
} from "./actions";
import { IMainTreatyServerData, TypicalTreatiesActivity, TypicalTreatiesPersonal } from "./interfaces";
import { deleteTypicalTreatiesStatuses, deleteStageStatuses, messagesTypicalTreaties, sortOrders } from "./constants";
import { CreateNotif } from "../../utils/createNotification";


export function* watchGetMainTreaty() {
  yield takeEvery(TreatyPageActionTypes.GET_MAIN_TREATIES, getMainTreaty);
}

export function* watchCheckDeleteTreaty() {
  yield takeEvery(TreatyPageActionTypes.CHECK_DELETE_TREATY, checkDeleteTreaty);
}

export function* watchDeleteTreaty() {
  yield takeEvery(TreatyPageActionTypes.DELETE_TYPICAL_TREATIES, deleteTypicalTreaties);
}

export function* watchTt() {
  yield takeEvery(TreatyPageActionTypes.GET_TYPICAL_TRATIES, getTTs);
}

export function* watchSearchTt() {
  yield debounce(500, TreatyPageActionTypes.SET_SEARCH_TRATIES, getTTs);
}

export function* watchCreateTreaty() {
  yield takeEvery(TreatyPageActionTypes.CREATE_TREATY, createTreaty);
}

export function* watchUpdateTreaty() {
  yield takeEvery(TreatyPageActionTypes.UPDATE_TREATY, updateTreaty);
}

export function* watchUpdateTypicalTreaty() {
  yield takeEvery(TreatyPageActionTypes.UPDATE_TYPICAL_TREATY, updateTypicalTreaty);
}

export function* watchCheckDeleteStage() {
  yield takeEvery(TreatyPageActionTypes.DELETE_STAGE, checkDeleteStage);
}

export function* watchGetBPSForTreaty() {
  yield takeEvery(TreatyPageActionTypes.GET_BP_LIST_TREATY, getTypicalBpsTreaty);
}

export function* watchSearchTypicalBPsTreaty() {
  yield takeEvery(TreatyPageActionTypes.SET_SEARCH_BPS, getTypicalBpsTreaty);
}

export function* watchSortTypicalBPsTreaty() {
  yield takeEvery(TreatyPageActionTypes.SET_TREATY_FORM_FILTER, getTypicalBpsTreaty);
}

function* getMainTreaty({params, action}: any) {
  const currentCompanyId = localStorage.getItem("company_id");
  yield put(setIsLoadingTreatyCard(true));

  const response = yield call(
    fetchData.get,
    `/api/v1/${currentCompanyId}/main-agreement`
  )
  const convertedData = convertServerToFrontDataHelper(response);

if (response) {
  yield put(setMainTreaties([response]))
  if(action === "form"){
      yield put(setNormalForm(convertedData));
    }else if(action === "add"){
      const updateStage: any = []
      response.stages.forEach((item) => {
        if(item.status){
         updateStage.push({...item, status: 0}) 
        }
      })
      
      yield put(setNormalForm({stages: updateStage }))
    }
  }
  yield put(setIsLoadingTreatyCard(false));
}


function* checkDeleteTreaty({ id }: checkDeleteTreatyAction) {
  yield put(
    setDeleteModalTypicalTreaties({
      TtId: id,
      isDeleteModalOpen: true,
      isShowPreloader: true,
    })
  )

  let response: any = null;

  response = { constraints: [deleteTypicalTreatiesStatuses.DELETE_TREATY_ALLOWED] };

  yield put(setDeleteModalTypicalTreaties({
    TtId: id,
    isDeleteModalOpen: true,
    statusFromServer: response,
    isShowPreloader: false,
  }));
}

function* deleteTypicalTreaties({ id }: deleteTypicalTreatiesAction) {
  const errorStatuses = [403, 404, 500];

  yield put(
    setDeleteModalTypicalTreaties({
      TtId: id,
      isDeleteModalOpen: true,
      isShowPreloader: true,
    })
  )

  const response = yield call(
    fetchData.delete,
    `/api/v1/typical-agreements/${id}`
  )

  yield put(setDeleteModalTypicalTreaties({
    TtId: id,
    isDeleteModalOpen: false,
    isShowPreloader: false,
  }))

  yield put(deleteTypicalTreatiesFromState(id));
  CreateNotif(messagesTypicalTreaties.DELETE_TREATY_SUCCESS, "success");

  if (errorStatuses.includes(response?.status)) {
    CreateNotif(messagesTypicalTreaties.DELETE_TREATY_ERROR);
  }
}

export function* watchGetTypicalTreaty() {
  yield takeEvery(TreatyPageActionTypes.GET_TYPICAL_TREATY, getTypicalTreaty);
}

function* getTTs({ params, action }: any) {
  const currentCompanyId = localStorage.getItem("company_id");
  const filters = yield select((state: State) => state.typicalTreatyPage.filters);
  const currentUser = yield select((state: State) => state.commonInfo.currentUserInfo);
  const sortCondition = yield select((state: State) => state.typicalTreatyPage.sortCondition);
  const search = yield select((state: State) => state.typicalTreatyPage.searchTt);

  if (!currentCompanyId) return;

  const currentPage = yield select(
    (state: State) => state.typicalTreatyPage.currentPage
  );

  let orderType = '';
  let url = '?';

  if (sortCondition) {
    let columnId = sortCondition.columnId;

    orderType = sortCondition.value === sortOrders.DESC ? '' : '-';
    url += 'sort=' + orderType + columnId;
  }

  if (filters) {
    if (filters.activity === TypicalTreatiesActivity.ACTIVE_STATUS) {
      url += `&filter[status]=1`;
    }

    if (filters.activity === TypicalTreatiesActivity.NOT_ACTIVE_STATUS) {
      url += `&filter[status]=0`;
    }

    if (filters.personal === TypicalTreatiesPersonal.PERSONAL_STATUS) {
      url += `&filter[author_id][]=${currentUser.id}`;
    }
  }

  if (currentPage) {
    url += `&page=${currentPage}`;
  }

  if (search?.length) {
    url += `&filter[search]=${search}`;
  }
  
  yield put(setIsLoadingTreaty(true));

  const response: any[] = yield call(
    fetchData.get,
    `/api/v1/${currentCompanyId}/typical-agreements${url}`
  );

  if (response) {
    yield put(setTreaties(response, action));
  }

  yield put(setIsLoadingTreaty(false));
}

function* getTypicalTreaty({id}: getTypicalTreatyAction) {
  yield put(setIsLoadingTreatyCard(true));

  let response: any = yield call(
    fetchData.get,
    `/api/v1/typical-agreements/${id}`
  );
  const convertedData = convertServerToFrontDataHelper(response);

  if(response) {
    yield put(setMainBool(false));
    yield put(setNormalForm(convertedData));
  }
  
  yield put(setIsLoadingTreatyCard(false));
}

function* updateTreaty({data}: any) {
  yield put(setIsSavingTreaty(true))
  const convertedData = yield convertFrontToServerDataHelper(data);

  const response: any = yield call(
    fetchData.put,
    `/api/v1/main-agreement/${convertedData.id}`,
    JSON.stringify(convertedData)
  );

  if(response) {
    yield put(updateMainTreatyStore([response]));
    CreateNotif(messagesTypicalTreaties.UPDATE_TREATY_SUCCESS, 'success');
  }
  yield put(setIsSavingTreaty(false))
}

function* updateTypicalTreaty({data}: any) {
  yield put(setIsSavingTreaty(true))
    
  const convertedData = yield convertFrontToServerDataHelper(data);

  const response: any = yield call(
    fetchData.put,
    `/api/v1/typical-agreements/${data.id}`,
    JSON.stringify(convertedData)
  );
  if(response) {
    yield put(updateTypicalTreatyStore(response));

    CreateNotif(messagesTypicalTreaties.UPDATE_TREATY_SUCCESS, 'success');
  }
  yield put(setIsSavingTreaty(false))
}


function* createTreaty({data}: createTreatyAction) {
  yield put(setIsLoadingTreaty(true));
  yield put(setIsSavingTreaty(true))

  const currentCompanyId = localStorage.getItem('company_id');
  const convertedData = yield convertFrontToServerDataHelper(data);

  const response: any = yield call(
    fetchData.post,
    `/api/v1/${currentCompanyId}/typical-agreements`,
    JSON.stringify(convertedData)
  );
  if(response) {
    yield put(setTreaties(response, "add"));
    CreateNotif(messagesTypicalTreaties.CREATE_TREATY_SUCCESS, 'success');
  }else{
    CreateNotif(messagesTypicalTreaties.CREATE_TREATY_ERROR, 'error');
  }
  yield put(setIsSavingTreaty(false))
  yield put(setIsLoadingTreaty(false));
}

function* checkDeleteStage({id}: deleteTreatyStageAction) {
  
  let response: any = null;
  
 /* response = yield call(
    fetchData.get,
    `/api/v1/stage/${id}/check-delete`
  );*/
  
  if(response?.constraints.length === 0) {
    response = {constraints: [deleteStageStatuses.DELETE_STAGE_ALLOWED]};
  }

  if(!response) {
    response = {constraints: [deleteStageStatuses.DELETE_STAGE_ERROR]};
  }
  
  yield put(setDeleteStageTreatyModal({
    stageId: id,
    isDeleteModalOpen: true,
    statusFromServer: {constraints: [deleteStageStatuses.DELETE_STAGE_ALLOWED as string]} 
  }));
}

function* getTypicalBpsTreaty({params, action}: any) {
  const currentCompanyId = localStorage.getItem('company_id');
  const currentUser = yield select((state: State) => state.commonInfo.currentUserInfo);

  if(!currentCompanyId) return;
  
  const search = yield select((state: State) => state.typicalTreatyPage.extraForm.searchTypicalBps);
  const currentPage = yield select((state: State) => state.typicalTreatyPage.extraForm.currentPageBpListTreaty);
  const bpTreaty= yield select((state: State) => state.typicalTreatyPage.extraForm.treatyForFilter);
  let url = '?sort=-id&filter[status]=1';

  if(currentPage) {
    url += `&page=${currentPage}`;
  }

  if(search?.length) {
    url += `&filter[search]=${search}`;
  }

  if(bpTreaty){
    if(bpTreaty.value === "notActive"){
    url += `&filter[in_agreements]=0`
   }else if(typeof bpTreaty.value === 'number'){
    url += `&filter[stage_id]=${bpTreaty.value}`
  }
  }

  yield put(setLoadingExtraFormTreaty(true));

  const response: any[] = yield call(
    fetchData.get,
    `/api/v1/${currentCompanyId}/typical-bp${url}`
  );

  if(response) {      
    yield put(setBpListTreaty(response, action));
  }

  yield put(setLoadingExtraFormTreaty(false));
}


function convertServerToFrontDataHelper(data: any): any { 
  const stages = data.stages && data.stages?.sort((a, b) => a.order_id - b.order_id).map((stage, index) => {
    const items = stage.item_list?.map((item, i) => {
      const result: any = {
        ...item,
        id: item.id ?? "",
        order_id: item.order_id,
        bp: item.type == 2 ? true : false,
        name: item.name,
        duration: item.duration
      }      
      return result
    })
    
    const result: any = {
      ...stage,
      id: stage.id ?? "",
      name: stage.name,
      status: stage.status,
      order_id: stage.order_id,
      item_list: items ?? [],
      duration: stage.duration
    }
    
    return result
  })
  
  let dataLocal = {
    id: data.id,
    name: data.name,
    description: data.description ?? "",
    author: data.author?.name ? data.author?.surname + ' ' + data.author?.name : data.author,
    authorId: data.author?.id,
    // author: data.author,
    createdAt: data?.created_at,
    activity: (data.status === 1) ? 1 : 2,
    stages: stages ?? []
  }

  return dataLocal;
}

function* convertFrontToServerDataHelper(data: any){
  const currentCompanyId = localStorage.getItem('company_id');
  const currentUser = yield select((state: State) => state.commonInfo.currentUserInfo);
  const stages = data.stageData && data.stageData?.map((stage, index) => {
    const items = stage.item_list?.map((item, i) => {
      const result: any = {
        item_id: item.id ?? "",
        order_id: i,
        type: item.bp ? 2 : 1
      }
      return result
    })

    let result: any = {
      id: stage.id ,
      name: stage.name,
      status: stage.status ? 1 : 0,
      order_id: index,
      item_list: items ?? []
    }
    if (stage.isNew) {
      delete result.id;
    }
    return result
  })
  
  return {
    id: data.id ?? null,
    name: data.name,
    description: data.description ? data.description : null,
    // При редактировании автор берется тот, который есть. При создании нового договора- текущий юзер
    author: data.author ?? currentUser.id, 
    company_id: currentCompanyId,
    status: (data.activity === TypicalTreatiesActivity.ACTIVE_STATUS),
    stages: stages ?? [],
  };
}