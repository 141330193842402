import React, { FC, useEffect, useState } from 'react';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { connect } from "react-redux";
import { State } from "../../../../../rootReducer";
import {ReactComponent as CloseIcon} from '../../../../../img/TrueIcons/close.svg';
import PreloaderLocal from "../../../../../common/components/PreloaderLocal/PreloaderLocal";
import { deleteBusinessProcessStatuses } from '../../../constants';
import { IDeleteBusinessProcessModal } from '../../../interfaces';
import { setDeleteModalBp, deleteBp } from '../../../actions';

import './ModalDeleteBp.scss';

const ModalDeleteBp: FC<{
  isDeleteModalOpen?: boolean,
  deleteBpModal: IDeleteBusinessProcessModal,
  setDeleteModalBp: (data: IDeleteBusinessProcessModal) => void,
  deleteBp: (id: string|number) => void
}> = ({
  isDeleteModalOpen,
  deleteBpModal,
  setDeleteModalBp,
  deleteBp
}) => {
  const textObj = {
    [deleteBusinessProcessStatuses.DELETE_BUSINESS_PROCESS_ALLOWED]:
      {title: 'Удаление бизнес-процесса', text: 'Бизнес-процесс будет удален. Больше его никто не увидит.<br/>Действие необратимо.', btnDeleteText: 'Удалить'},
    [deleteBusinessProcessStatuses.DELETE_BUSINESS_PROCESS_ERROR]:
      {title: 'Удаление бизнес-процесса', text: 'Произошла ошибка при удалении бизнес-процесса...'}
  };
  
  const [text, setText] = useState<{
    title: string,
    text: string,
    btnDeleteText?: string
  }>(textObj[deleteBusinessProcessStatuses.DELETE_BUSINESS_PROCESS_ERROR]);

  useEffect(() => {
    if(deleteBpModal.statusFromServer?.constraints.length) {
      const {constraints} = deleteBpModal.statusFromServer;
      const result = textObj[constraints[0]];

      if(result) setText(result);
      else setText(textObj[deleteBusinessProcessStatuses.DELETE_BUSINESS_PROCESS_ERROR]);
    }
  }, [deleteBpModal.isDeleteModalOpen]);

  const handleClose = () => {
    setDeleteModalBp({isDeleteModalOpen: false});
  };

  const deleteIsBp = () => {
    deleteBp(deleteBpModal.bpId ?? '');
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={'modal-delete-object'}
        open={isDeleteModalOpen ?? false}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={isDeleteModalOpen}>
          <div className={'modal-delete-business-process__content-wr'}>
            <div className="modal-delete-business-process__content">

              <div className="modal-delete-business-process__content-item">
                <div className={'modal-delete-business-process__title-wr'}>
                  <div className="modal-delete-business-process__title">{text?.title}</div>
                  <div className="modal-delete-business-process__delete-ico-wr"
                       onClick={handleClose}
                  >
                    <CloseIcon />
                  </div>
                </div>

                <div className="modal-delete-business-process__text"
                     dangerouslySetInnerHTML={{__html: text?.text}}
                >
                </div>
              </div>
            </div>
            
            <div className="modal-delete-business-process__btns">
              {text?.btnDeleteText &&
                <button className={"modal-delete-business-process__btn modal-delete-business-process__btn--delete " + (deleteBpModal.isShowPreloader ? ' modal-delete-business-process__btn--show-preloader' : '')}>
                <span className="modal-delete-business-process__btn-title"
                      onClick={deleteIsBp}
                >{text?.btnDeleteText}</span>
                  <span className="modal-delete-business-process__btn-preloader">
                  <PreloaderLocal />
                </span>
                </button>
              }
              
              <button className="modal-delete-business-process__btn modal-delete-business-process__btn--cancel"
                      onClick={handleClose}
              >
                <span className="modal-delete-business-process__btn-title">Отмена</span>
              </button>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
}

const mapStateToProps = (state: State) => {
  return {
    isDeleteModalOpen: state.businessPage.deleteBusinessProcessModal.isDeleteModalOpen,
    deleteBpModal: state.businessPage.deleteBusinessProcessModal
  };
};

const mapDispatchToProps = {
  setDeleteModalBp,
  deleteBp
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalDeleteBp);
