import React, { FC } from "react";
import { CustomTooltip } from "../Tooltip/CustomTooltip";

import './TooltipQuestion.scss';

const TooltipQuestion: FC<{
  title: string
}> = ({
  title
}) => {
  return (
    <CustomTooltip title={title} >
      <div className='tooltip-question'>
        <div className="tooltip-question__content">?</div>
      </div>
    </CustomTooltip>
  )
};

export default TooltipQuestion;
