// ************** Тут создаются задачи **************************
import React, { useEffect, useState, useMemo } from "react";
import SelectWithSecondaryBtnProject from "../../../common/components/Selects/SelectWithSecondaryBtnProject"; //**** Добавлено
import { useLocation } from "react-router-dom";
import {
  SelectData,
  Item,
  Section,
  Schedule,
  File as FileType,
  Project,
} from "../../../common/types";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  getSelectedTask,
  getUsersSelectList,
  getProjectsSelectList,
  getSectionSelectList,
} from "../selectors";
import { State } from "../../../rootReducer";
import { updateTask } from "../../../common/actions";
import TaskWorkLoad from "./TaskWorkLoad";
import { TextareaAutosize } from "@material-ui/core";
import {
  setTaskPrivate,
  changeReducerCheckboxItem,
  createTask,
  deleteFile,
  dispatchCheckboxItem,
  fetchProjectSectionsTaskPlate,
  isSendingNewTask,
  recalculateTaskLoad,
  sendReqToChangeTemplRepTask,
  setCyclicTaskToChange,
  setDefaultValues,
  setExecutorId,
  setFinishCyclick,
  setShownTaskInfoPlate,
  setStartCyclick,
  setTaskDescription,
  setTaskProject,
  setTaskSection,
  successfulCreatedNewTasks,
  updateTaskInfoPlate,
  uploadFile,
  setTaskBegin,
  setTaskEnd,
  setTaskCreateTime,
  setIsMeetingTask,
  setIsSuccessTaskCreate,
  setTasksObservers,
} from "../actions";
import { setPrivateToggle } from "../../Tasks/actions";
import CheckListModule from "../../../common/components/CheckList/CheckListModule";
import Button from "../../../common/components/newComponent/Buttons/Button";
import Chips from "../../../common/components/ChipsItem";
import NameWithInput from "../../../common/components/newComponent/Inputs/NameWithInput";
import styled from "styled-components";
import Prioritys from "../../../common/components/Priority/Prioritys";
import ExecutorSelect from "../../TaskInfoPlate/components/ExecutorSelectWithDelegate";
import Delegate from "../../TaskInfoPlate/components/Delegate";
import TaskBegin from "../../TaskInfoPlate/components/TaskBegin";
import TaskEnd from "../../TaskInfoPlate/components/TaskEnd";
import FileElem from "../../../common/components/FileElem/FileElem";
import Dependencies from "./Dependencies";
import RepeatTask from "./RepeatTask";
import EndRepeatTask from "./EndRepeatTask";
import { fetchData } from "../../../utils/fetchData";
import { dateFormatUTC } from "../reducer";
import { useContext } from "react";
import { useStateContext } from "../../../useStateContext";
import { User } from "../../../common/types";
import { CreateNotif } from "../../../utils/createNotification";
import TasksObserversSelect from "./TasksObserversSelect";
import PreloaderLocal from "../../../common/components/PreloaderLocal/PreloaderLocal";

export let status = "task"; //**********************************************************

let project: Item | null = null; //**********************************************************

let projectId: number | null = null;
let section: Section | null = null; //**********************************************************

type Props = {
  setPrivateToggle: (privateToggle: boolean) => void;
  users: User[];
  checkedPersonalTask: boolean;
  setCheckedPersonalTask: (checkedPersonalTask: boolean) => void;
  selectedTask: any;
  projectsSelect: SelectData;
  id_bp: any;

  usersSelect: {
    author: Item;
    executor: Item;
    list: Item[];
  };
  uploadFile: (file: File) => void;
  deleteFile: (id: number) => void;
  sectionSelect: SelectData;
  commentFiles: FileType[];
  sections: Section[];
  schedule: Schedule | null;
  checkList: object[];
  priorities: any[];
  currentUser: any;
  updateTask: (id: number, params: any, withNotif?: boolean) => void;
  dispatchCheckboxItem: (
    orderNumber: number,
    text: string,
    checkboxState: boolean,
    actionType: string
  ) => void;
  changeReducerCheckboxItem: (
    orderNumber: number,
    text: string,
    checkboxState: boolean,
    actionType: string
  ) => void;
  setTaskDescription: (val: string) => void;
  name: string;
  executor_id: number | null;
  taskInfoActiveTab: string;
  description: string;
  project_id: number | null;
  project_section_id: number | null;
  successfulCreatedNewTasks: (value: any) => any;
  setTaskProject: (val: number) => void;
  fetchProjectSectionsTaskPlate: (val: number) => void;
  setTaskSection: (id: number) => void;
  setExecutorId: (id: number | null) => void;
  createTask: (executor_id?: number) => void;
  setDefaultValues: () => void;
  repeat: any;
  isSendingNewTask: (isSending: boolean) => void;
  isSendingCreateNewTasks: boolean;
  isShownTaskInfoPlate: boolean;
  setCyclicTaskToChange: (obj: {}) => void;
  parameter_trigger: boolean;
  sendReqToChangeTemplRepTask: (obj: {}) => void;
  setStartCyclick: (
    period?: string,
    params?: Array<number>,
    interval?: number,
    show_modal_window?: boolean,
    stringForMainPart?: string
  ) => void;
  setFinishCyclick: (
    ending_condition?: string,
    end_count?: number | null,
    end_date?: string | null
  ) => void;
  projects: Project[];
  isShowAddObject: boolean;
  setIsShowAddObject: (mode: boolean) => void;
  taskName: string;
  cellData?: any;
  setCellData?: (mode: any) => void;
  setDataEnd?: (mode: any) => void;
  addEventHandler?: (event: any, ans: any, type?: any) => void;
  begin: string;
  end: string;
  isCreated;
  setIsSuccessTaskCreate: (mode: boolean) => void;
  deleteSelectedEvent?: (id) => void;
  createdTaskInfo: any;
  spectators: number[];
  setTasksObservers: (spectators: number[]) => void;
};

const WrapperChipsBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
`;


const InfoTab: React.FC<Props> = ({
  setPrivateToggle,
  users,
  checkedPersonalTask,
  setCheckedPersonalTask,
  dispatchCheckboxItem,
  checkList,
  taskInfoActiveTab,
  changeReducerCheckboxItem,
  selectedTask,
  projectsSelect,
  usersSelect,
  sections,
  sectionSelect,
  schedule,
  updateTask,
  project_id,
  setTaskDescription,
  setTaskProject,
  fetchProjectSectionsTaskPlate,
  setTaskSection,
  setExecutorId,
  createTask,
  setDefaultValues,
  name,
  description,
  project_section_id,
  uploadFile,
  deleteFile,
  commentFiles,
  successfulCreatedNewTasks,
  currentUser,
  executor_id,
  repeat,
  isSendingNewTask,
  isSendingCreateNewTasks,
  isShownTaskInfoPlate,
  setCyclicTaskToChange,
  sendReqToChangeTemplRepTask,
  setStartCyclick,
  setFinishCyclick,
  parameter_trigger,
  priorities,
  id_bp,
  projects,
  isShowAddObject,
  setIsShowAddObject,
  taskName,
  cellData,
  setCellData,
  setDataEnd,
  addEventHandler,
  begin,
  end,
  isCreated,
  setIsSuccessTaskCreate,
  deleteSelectedEvent,
  createdTaskInfo,
  spectators,
  setTasksObservers,
}) => {
  //************************************ */
  const {
    checkedTaskBegin,
    setCheckedTaskBegin,
    checkedTaskEnd,
    setCheckedTaskEnd,
    startTaskTime,
    setStartTaskTime,
    tableOrTreeToggler, // Переключатель между рабочими и личными задачами
    setTableOrTreeToggler,
    meetingId,
    setMeetingId,
    projectIdCurrent,
    setProjectIdCurrent,
    projectSectionCurrent,
    setProjectSectionCurrent,
  } = useContext(useStateContext);

  const [projectsAll, setProjectsAll] = useState<Project[]>();
  const [sectionsAll, setSectionsAll] = useState<Section[]>();
  const [created, setCreated] = useState<any>();
  const [isValid, setIsValid] = useState<boolean>(false);
  const [timeValid, setTimeValid] = useState<boolean>(true);

  const { priority_id } = useSelector((state: State) => state.taskInfoPlate);
  const location = useLocation();

  useEffect(() => {
    setCheckedTaskBegin(false);
    setCheckedTaskEnd(false);
    if (selectedTask && checkedPersonalTask) {
      if (selectedTask.begin === "01-01-1970 00:00:00")
        setCheckedTaskBegin(true);
      if (selectedTask.end === "01-01-2099 00:00:00") setCheckedTaskEnd(true);
    } else {
      setCheckedPersonalTask(false);
    }

    if (selectedTask?.spectators) {
      setTasksObservers(selectedTask.spectators);
    }
  }, [selectedTask, isShownTaskInfoPlate]);

  useEffect(() => {
    if (selectedTask?.spectators) {
      setTasksObservers(selectedTask.spectators);
    }
  }, [selectedTask]);

  useEffect(() => {
    if (selectedTask) {
      if (isShownTaskInfoPlate && selectedTask.spectators !== spectators) {
        // Обновление наблюдателей
        updateTask(selectedTask?.id, { spectators });
      }
    }
  }, [spectators]);

  function changeTaskBegin() {
    //************************************* */

    let dateNow = new Date();

    let dateNowString = `${dateNow
      .toLocaleString()
      .split(",")[0]
      .split(".")
      .join("-")}${dateNow.toLocaleString().split(",")[1]}`;

    if (selectedTask) {
      // если обновление задачи

      if (checkedTaskBegin === false) {
        // поменяется на на true
        setCheckedTaskBegin(true);
        updateTask(selectedTask!.id, {
          begin: "01-01-1970 00:00:00",
          private: true,
        });
      }

      if (checkedTaskBegin === true) {
        // поменяется на на false
        setCheckedTaskBegin(false);
        updateTask(selectedTask!.id, { begin: dateNowString, private: true });
      }
    } else {
      // если создание новой задачи

      if (checkedTaskBegin === false) {
        // поменяется на на true
        setCheckedTaskBegin(true);
        dispatch(setTaskBegin("01-01-1970 00:00:00"));
      }

      if (checkedTaskBegin === true) {
        // поменяется на на false
        setCheckedTaskBegin(false);
        dispatch(setTaskBegin(dateNowString));
      }
    }
  }
  function changeTaskEnd() {
    //************************************* */

    let tomorrow = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);

    let tomorrowString = `${tomorrow
      .toLocaleString()
      .split(",")[0]
      .split(".")
      .join("-")}${tomorrow.toLocaleString().split(",")[1]}`;

    if (selectedTask) {
      // если обновление задачи

      if (checkedTaskEnd === false) {
        // поменяется на true
        setCheckedTaskEnd(true);
        updateTask(selectedTask!.id, {
          end: "01-01-2099 00:00:00",
          private: true,
        });
      }

      if (checkedTaskEnd === true) {
        // поменяется на false
        setCheckedTaskEnd(false);
        updateTask(selectedTask!.id, { end: tomorrowString, private: true });
      }
    } else {
      // если создание новой задачи

      if (checkedTaskEnd === false) {
        // поменяется на на true
        setCheckedTaskEnd(true);
        dispatch(setTaskEnd("01-01-2099 00:00:00"));
      }

      if (checkedTaskEnd === true) {
        // поменяется на на false
        setCheckedTaskEnd(false);
        dispatch(setTaskEnd(tomorrowString));
      }
    }
  }

  useEffect(() => {
    if (isShownTaskInfoPlate === true) {

      if (projectId) {
        fetchData
          .get(`/api/v1/projects/${projectId}/sections`)
          .then((sections) => {
            setSectionsAll(sections);
          })
          .catch((error) => {
            console.error("В системе нет секций");
          });
      }   
    }
  }, [isShownTaskInfoPlate]);

  useEffect(() => {
    if (projectsSelect.list.length > 0 && projectsSelect.selected === null) {
      if (
        location.pathname.match("/projects") &&
        projectIdCurrent !== 0 &&
        projectSectionCurrent !== 0
      ) {
        if (selectedTask) {
        } else {
          setTaskProject(projectIdCurrent as number);
          fetchProjectSectionsTaskPlate(projectIdCurrent as number);
        }

        if (selectedTask) {
        } else setTaskSection(projectSectionCurrent as number);

        setProjectIdCurrent(0);
        setProjectSectionCurrent(0);
      }

      if (
        location.pathname.match("/projects") &&
        projectIdCurrent !== 0 &&
        projectSectionCurrent === 0
      ) {
        if (selectedTask) {
        } else {
          setTaskProject(projectIdCurrent as number);
          fetchProjectSectionsTaskPlate(projectIdCurrent as number);
        }

        setProjectIdCurrent(0);
        setProjectSectionCurrent(0);
      }

      if (
        !location.pathname.match("/projects") &&
        projectsSelect.list.length > 0 &&
        projectsSelect.selected === null
      ) {
        let index: number;
        for (index = 0; index < projectsSelect.list.length; ++index) {
          if (projectsSelect.list[index].text === "Быстрые задачи") {
            project = projectsSelect.list[index];
            projectId = +projectsSelect.list[index].value;

            let value = projectId;
            let params = { project_id: value };
            let section0 = sections.find((sec) => sec.project_id === value);
            if (section0) {
              section = section0;
            }
            if (section) params["project_section_id"] = section.id;
            if (selectedTask) {
            } else {
              setTaskProject(value as number);
              fetchProjectSectionsTaskPlate(value as number);
            }

            let value2: number | null = null;

            if (projectId) {
              if (sections && sections.length > 0) {
                let index2: number;
                for (index2 = 0; index2 < sections.length; ++index2) {
                  if (
                    sections[index2].project_id &&
                    sections[index2].project_id == projectId
                  ) {
                    if (sections[index2].name === "Основной раздел") {
                      if (sections[index2].id && sections[index2].id !== 0)
                        value2 = sections[index2].id;
                    }
                  }
                }
              }
            }

            if (selectedTask) {
            } else setTaskSection(value2 as number);
          }
        }
      }
    }
  }, [schedule, project_id, projectIdCurrent, projectSectionCurrent]);

  const dispatch = useDispatch();
  const [selectedItems, setSelectedItems] = useState<Item[]>([]); // Сотрудники, которые выбраны в чипсах
  const [cyclicTaskInfo, setCyclicTaskInfo] = useState<any>(null);
  const [applyBtnRepeatBlock, setApplyBtnRepeatBlock] =
    useState<boolean>(false);
  const taskInfoPlate = useSelector((state: State) => state.taskInfoPlate);

  const { isMeetingTask } = taskInfoPlate;

  const weekDays: { day: number; name: string }[] = [
    { day: 1, name: "Пн" },
    { day: 2, name: "Вт" },
    { day: 3, name: "Ср" },
    { day: 4, name: "Чт" },
    { day: 5, name: "Пт" },
    { day: 6, name: "Сб" },
    { day: 7, name: "Вс" },
  ];

  const disabledComponent =
    currentUser?.id === selectedTask?.author_id ||
    currentUser?.id === selectedTask?.executor_id ||
    !selectedTask
      ? false
      : currentUser?.roles[0] !== 1;
  // компоненты ОТКЛЮЧЕНЫ, если: пользователь, из под которого смотришь на задачу (не автор, не исполнитель, не администратор)

  useEffect(() => {
    if (!isShownTaskInfoPlate) {
      let item;
      if (currentUser) {
        item = {
          text: `${currentUser.surname} ${currentUser.name}`,
          value: currentUser.id,
          urlImage: currentUser?.image?.url,
        };
      }
      if (item && item.text) setSelectedItems([item]);
      setExecutorId(currentUser?.id);
    }
  }, [isShownTaskInfoPlate]);

  useEffect(() => {
    setApplyBtnRepeatBlock(false);
  }, [taskInfoActiveTab]);

  useEffect(() => {
    (async () => {
      if (selectedTask?.cyclic_task_id) {
        let cycleInfo = await fetchData.get(
          `/api/v1/cyclic-tasks/` + selectedTask?.cyclic_task_id
        );
        if (cycleInfo) {
          if (cycleInfo.params === null) cycleInfo["params"] = [];
          if (cycleInfo.end_count)
            cycleInfo["end_count"] = JSON.parse(cycleInfo.end_count)[0]; // из стринга-массива вытаскиваю первое значение
          if (cycleInfo.end_date) {
            cycleInfo["end_date"] =
              cycleInfo.end_date.substr(8, 2) +
              "-" +
              cycleInfo.end_date.substr(5, 2) +
              "-" +
              cycleInfo.end_date.substr(0, 4) +
              " 00:00:00";
          }
          if (typeof cycleInfo.params === "string") {
            cycleInfo["params"] = JSON.parse(cycleInfo.params);
          }
          setCyclicTaskInfo(cycleInfo);

          ///////////////     генерирование полного названяия для строки состояния цикла
          let result_string = "";
          switch (cycleInfo.period) {
            case "every day":
              result_string += "Каждый";
              break;
            case "every week":
              result_string += "Каждую";
              break;
            case "every month":
              result_string += "Каждый";
              break;
          }

          if (cycleInfo.interval.toString() === "1") result_string += " ";
          else result_string += " " + cycleInfo.interval + " ";

          switch (cycleInfo.period) {
            case "every day":
              result_string += "день";
              break;
            case "every week":
              result_string += "неделю";
              break;
            case "every month":
              result_string += "месяц";
              break;
          }

          if (cycleInfo.params.length) {
            if (cycleInfo.period === "every week") {
              result_string += " в: ";
              cycleInfo.params.forEach((item, number) => {
                let found_day = weekDays.find((day) => day.day === item);
                result_string += (number === 0 ? "" : ", ") + found_day?.name;
              });
            } else if (cycleInfo.period === "every month") {
              result_string += " по: ";
              cycleInfo.params.forEach((item, number) => {
                result_string += (number === 0 ? "" : ", ") + item;
              });
            }
          }

          result_string = result_string.replace(/\s\s+/g, " "); // удалять двойные пробелы

          // запись в редюсер всего по repeat
          setStartCyclick(
            cycleInfo.period,
            cycleInfo.params === null ? [] : cycleInfo.params,
            cycleInfo.interval,
            false,
            result_string
          );
          setFinishCyclick(
            cycleInfo.ending_condition,
            cycleInfo.end_count !== null ? cycleInfo.end_count : null,
            cycleInfo["end_date"]
          );
        }
      } else {
        setStartCyclick("", [], 1, false, "");
        setFinishCyclick("never", null, null);
        setCyclicTaskInfo(null);
        setApplyBtnRepeatBlock(false);
      }
    })();
  }, [selectedTask?.id, selectedTask?.cyclic_task_id, parameter_trigger]);

  useEffect(() => {
    setApplyBtnRepeatBlock(false);
  }, [selectedTask?.id]);

  useEffect(() => {
    // нужно, чтобы в редюсере было значение первого пользователя в чипсине, пока нет selectedTask
    if (selectedItems.length) {
      if (!executor_id) {
        setExecutorId(selectedItems[0].value as number);
        setTimeout(() => recalculateTaskLoad(), 500);
      }
    }
  }, [selectedItems]);

  useEffect(() => {
    if (selectedTask) {
      if (cyclicTaskInfo) {
        //////// проверка поля params, потому что это массив, и при простом сравнении после изменения, он дает неверный результат
        let params_not_equals = false;
        if (
          Object.keys(repeat.params).length !==
          Object.keys(cyclicTaskInfo.params).length
        ) {
          params_not_equals = true;
        } else {
          Object.keys(repeat.params).forEach((each) => {
            let match = false;
            Object.keys(cyclicTaskInfo.params).forEach((key) => {
              if (each === key) match = true;
            });
            if (!match) params_not_equals = true;
          });
        }
        // проверка, был ли изменен хоть 1 параметр в блоке с повторениями
        if (
          repeat.end_count !== cyclicTaskInfo.end_count ||
          repeat.end_date !== cyclicTaskInfo.end_date ||
          repeat.ending_condition !== cyclicTaskInfo.ending_condition ||
          repeat.interval !== cyclicTaskInfo.interval ||
          repeat.period !== cyclicTaskInfo.period ||
          params_not_equals
        ) {
          setApplyBtnRepeatBlock(true);
        } else {
          setApplyBtnRepeatBlock(false);
        }
      } else {
        if (repeat.period !== "") {
          // задача создана, она еще не циклическая, но какой то параметр уже установлен
          setApplyBtnRepeatBlock(true);
        }
      }
    }
  }, [repeat]);

  // дедлайн по умолчанию- через сутки после начала задачи
  useEffect(() => {
    dispatch(
      updateTaskInfoPlate({
        ...taskInfoPlate,
        begin: dateFormatUTC(Date.now()),
        end: dateFormatUTC(Date.now() + 3600000 * 24),
      })
    );
  }, [isShownTaskInfoPlate]);

  const styleDiv = document.querySelector(".main_div");

  if (id_bp) {
    styleDiv?.setAttribute("style", "pointer-events: none");
  }

  useEffect(() => {
    if (isCreated) {
      if (addEventHandler) {
        if (cellData !== null) {
          if (created) {
            addEventHandler(
              {
                name: created.taskName,
                start_at: created.begin,
                end_at: created.end,
                priority_id: created.priority_id,
                task_id: createdTaskInfo.id,
                event_task_id: createdTaskInfo.event_tasks[0].id,
                is_cylcic: createdTaskInfo.cyclic_task_id ? true : false,
              },
              cellData.newEvent,
              "EVENT_TASK_TYPE"
            );
          }

          setIsShowAddObject(false);
          setIsSuccessTaskCreate(false);
        } else {
          if (created) {
            addEventHandler(
              {
                name: created.taskName,
                start_at: created.begin,
                end_at: created.end,
                priority_id: created.priority_id,
                task_id: createdTaskInfo.id,
                event_task_id: createdTaskInfo.event_tasks[0].id,
                is_cylcic: createdTaskInfo.cyclic_task_id ? true : false,
              },
              false,
              "EVENT_TASK_TYPE"
            );
          }
          setIsShowAddObject(false);
          setIsSuccessTaskCreate(false);
        }
      }
    }
  }, [isCreated]);

  const addEv = (taskName, begin, end, priority_id) => {
    setCreated({ taskName, begin, end, priority_id });
  };

  useMemo(() => {
    if (
      name?.length &&
      project_id &&
      project_section_id &&
      selectedItems &&
      selectedItems.length &&
      timeValid
    ) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [name, project_id, project_section_id, selectedItems, timeValid]);

  return (
    <div className="main_div">
      <div className={"full_width_components"}>
        <div className={"description"}>
          <TextareaAutosize
            disabled={
              selectedTask && selectedTask.id_bp !== null ? true : false
            }
            aria-label="description_textarea_show"
            placeholder="Введите описание"
            className={"input_div collapsed"}
            minRows={2}
            onFocus={(e) => {
              if (e.currentTarget.classList.length) {
                for (let i = 0; i < e.currentTarget.classList.length; i++) {
                  if (e.currentTarget.classList[i] === "collapsed") {
                    e.currentTarget.classList.remove("collapsed");
                  }
                }
              }
            }}
            onChange={(e) => {
              if (!disabledComponent) setTaskDescription(e.currentTarget.value);
            }}
            onBlur={(e) => {
              if (selectedTask) {
                e.currentTarget.classList.add("collapsed");
                if (!disabledComponent) {
                  if (e.target.value !== selectedTask?.description) {
                    // мгновенно обновляю значение для заколлапсированного состояния
                    if ([0, null].indexOf(selectedTask.cyclic_task_id) === -1) {
                      // сравнение одного с несколькими
                      // эта задача вообще циклическая или сама по себе ?
                      // значит циклическая

                      // больше не нужно запоминать выбор в текущей сессии работы с задачей

                      setCyclicTaskToChange({ description: e.target.value }); // заношу значения изменяемых параметров в временное хранилище, пока выводится окно с вопросом
                    } else {
                      updateTask(selectedTask.id, {
                        description: e.target.value,
                      });
                    }
                  }
                }
              } else setTaskDescription(e.target.value);
            }}
            value={description ?? ''}
          />
        </div>

        <CheckListModule
          action={(
            orderNumber,
            nameItem,
            checkboxValue,
            actionType,
            onlyChangeReducer
          ) => {
            if (selectedTask) {
              if (onlyChangeReducer) {
                changeReducerCheckboxItem(
                  orderNumber,
                  nameItem,
                  checkboxValue,
                  actionType
                );
              } else {
                dispatchCheckboxItem(
                  orderNumber,
                  nameItem,
                  checkboxValue,
                  actionType
                );
              }
            } else {
              changeReducerCheckboxItem(
                orderNumber,
                nameItem,
                checkboxValue,
                actionType
              );
            }
          }}
          checkList={checkList}
          disabled={selectedTask && selectedTask.id_bp !== null ? true : false}
        />
      </div>

      <div className="info_tab">
        {/************************************************************************************************** */}
        <NameWithInput
          disabled={selectedTask && selectedTask.id_bp !== null ? true : false}
          title="Проект*"
          iconName="folder"
          preloader={true}
        >
    

          <SelectWithSecondaryBtnProject // Для проектов- свой модуль. Данные о количестве проектов передаются в -> SelectWithSecondaryBtnProject -> SelectBtnProject, а там выводится предупреждение если нет проектов
            projectsSelect={projectsSelect.list.length} // передаем - сколько проектов выбранно. Если 0- выведем предупреждение.
            disabled={
              (selectedTask && selectedTask.id_bp !== null) ||
              checkedPersonalTask
                ? true
                : false
            }
            list={projectsSelect.list.sort((a, b) =>
              a.text > b.text ? 1 : -1
            )}
            selected={
              projectsSelect.selected === null
                ? project
                : projectsSelect.selected
            }
            selectHandler={(value, target) => {
              let params = { project_id: value };
              let section = sections.find((sec) => sec.project_id === value);
              if (section) params["project_section_id"] = section.id;
              if (selectedTask) {
                if ([0, null].indexOf(selectedTask.cyclic_task_id) === -1) {
                  // сравнение одного с несколькими
                  // эта задача вообще циклическая или сама по себе ?
                  // значит циклическая

                  // больше не нужно запоминать выбор в текущей сессии работы с задачей

                  setCyclicTaskToChange(params); // заношу значения изменяемых параметров в временное хранилище, пока выводится окно с вопросом
                } else {
                  updateTask(selectedTask.id, params);
                }
              } else {
                setTaskProject(value as number);
                fetchProjectSectionsTaskPlate(value as number);
              }
            }}
          />
          <div className={
            "task-info-plate__preloader" +
            (!projectsSelect.list.length ? ' task-info-plate__preloader--show' : '')
          }>
            <PreloaderLocal/>
          </div>
        </NameWithInput>
        {/*
         ********************************************************************************************* */}
        <NameWithInput
          title="Раздел*"
          inputName={"sectionSelect"}
          iconName="folder"
        >
          <SelectWithSecondaryBtnProject
            projectsSelect={projectsSelect}
            fieldName="sectionSelect"
            list={sectionSelect.list}
            selected={sectionSelect.selected}
            disabled={
              (selectedTask && selectedTask.id_bp !== null) ||
              checkedPersonalTask
                ? true
                : false
            }
            selectHandler={(value) => {
              if (selectedTask) {
                // проверить шаблон и спросить про изменение
                if ([0, null].indexOf(selectedTask.cyclic_task_id) === -1) {
                  // сравнение одного с несколькими
                  // эта задача вообще циклическая или сама по себе ?
                  // значит циклическая

                  // больше не нужно запоминать выбор в текущей сессии работы с задачей

                  setCyclicTaskToChange({ project_section_id: value }); // заношу значения изменяемых параметров в временное хранилище, пока выводится окно с вопросом
                } else {
                  updateTask(selectedTask.id, { project_section_id: value });
                }
              } else setTaskSection(value as number);
            }}
          />
        </NameWithInput>
        <NameWithInput
          title="Приоритет"
          iconName="prioritySelect"
          disabled={selectedTask && selectedTask.id_bp !== null ? true : false}
        >
          <Prioritys
            disabled={
              selectedTask && selectedTask.id_bp !== null ? true : false
            }
            bigSize
          />
        </NameWithInput>
        <NameWithInput title="Автор" iconName="people">
          <WrapperChipsBlock>
            <Chips
              image={selectedTask?.author?.image?.url}
              text={
                selectedTask
                  ? selectedTask?.author?.is_active
                    ? `${selectedTask?.author?.surname} ${selectedTask?.author?.name}`
                    : `${selectedTask?.author?.surname} ${selectedTask?.author?.name} (неактивен)`
                  : `${currentUser?.surname} ${currentUser?.name}`
              }
              disabled
            />
          </WrapperChipsBlock>
        </NameWithInput>

        <NameWithInput title="Исполнитель" iconName="people">
          <ExecutorSelect
            disabled={
              (selectedTask && selectedTask.id_bp !== null) ||
              checkedPersonalTask
                ? true
                : false
            }
            checkedPersonalTask={checkedPersonalTask}
            selectedItems={selectedItems}
            setSelectedItems={(arg) => setSelectedItems(arg)}
          />
        </NameWithInput>

        <NameWithInput
          title="Наблюдатели"
          iconName="people"
          inputName={"TasksObserversSelect"}
        >
          <TasksObserversSelect
            disabled={
              selectedTask && selectedTask.id_bp !== null ? true : false
            }
          />
        </NameWithInput>

        {selectedTask &&
          selectedTask?.executor_id === currentUser?.id &&
          Boolean(selectedTask.provide_to) && (
            <NameWithInput title="Делегировать" iconName="arrow-right">
              <Delegate
                selectedItems={selectedItems}
                setSelectedItems={(arg) => setSelectedItems(arg)}
              />
            </NameWithInput>
          )}

        {/* компонент для заведения даты начала задачи */}
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <NameWithInput title="Начало" iconName="calendar-dark">
            <div style={{ width: "232px" }}>
              {!checkedTaskBegin && (
                <TaskBegin
                  setTimeValid={setTimeValid}
                  cellData={cellData}
                  checkedPersonalTask={checkedPersonalTask}
                  checkedTaskBegin={checkedTaskBegin}
                  disabled={
                    (selectedTask && selectedTask.id_bp !== null) ||
                    checkedTaskBegin
                      ? true
                      : false
                  }
                />
              )}
            </div>
          </NameWithInput>

          {checkedPersonalTask && (
            <div style={{ marginRight: "60px", paddingTop: "20px" }}>
              <span
                style={{ cursor: "pointer", marginRight: "10px" }}
                onClick={changeTaskBegin}
              >
                {" "}
                Без даты
              </span>
              <input
                style={{ cursor: "pointer" }}
                type="checkbox"
                checked={checkedTaskBegin}
                onChange={changeTaskBegin}
              ></input>
            </div>
          )}
        </div>

        {/* компонент для заведения даты окончания задачи */}
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <NameWithInput title="Дедлайн" iconName="calendar-dark">
            <div style={{ width: "232px" }}>
              {!checkedTaskEnd && (
                <TaskEnd
                  setTimeValid={setTimeValid}
                  cellData={cellData}
                  setDataEnd={setDataEnd}
                  checkedPersonalTask={checkedPersonalTask}
                  checkedTaskEnd={checkedTaskEnd}
                  disabled={
                    (selectedTask && selectedTask.id_bp !== null) ||
                    checkedTaskEnd
                      ? true
                      : false
                  }
                />
              )}
            </div>
          </NameWithInput>

          {checkedPersonalTask && (
            <div style={{ marginRight: "60px", paddingTop: "20px" }}>
              <span
                style={{ cursor: "pointer", marginRight: "10px" }}
                onClick={changeTaskEnd}
              >
                {" "}
                Без даты
              </span>
              <input
                style={{ cursor: "pointer" }}
                type="checkbox"
                checked={checkedTaskEnd}
                onChange={changeTaskEnd}
              ></input>
            </div>
          )}
        </div>

        {/*repeat task*/}
        <NameWithInput title="Повторять" iconName="repeat">
          <RepeatTask
            disabledComponent={
              (selectedTask && selectedTask.id_bp !== null ? true : false) ||
              (currentUser?.id !== selectedTask?.author_id &&
                selectedTask !== null) ||
              (cyclicTaskInfo !== null &&
                currentUser?.id !== cyclicTaskInfo?.author_id)
            }
          />
        </NameWithInput>

        {repeat.period && (
          <NameWithInput title="Закончить повтор" iconName="repeat">
            <EndRepeatTask
              disabledComponent={
                (currentUser?.id !== selectedTask?.author_id &&
                  selectedTask !== null) ||
                (cyclicTaskInfo !== null &&
                  currentUser?.id !== cyclicTaskInfo?.author_id)
              }
            />
          </NameWithInput>
        )}

        {applyBtnRepeatBlock && (
          <NameWithInput title="" iconName="repeat">
            <Button
              style={{ marginLeft: 144 }}
              title={"Применить"}
              onClick={() => {
                sendReqToChangeTemplRepTask({ ...repeat });
              }}
            />
          </NameWithInput>
        )}
        {/*repeat task*/}

        {!selectedTask && (
          <NameWithInput title="Прикрепить файл" iconName="clip">
            <div>
              <input
                onChange={(e) => {
                  let files = e.currentTarget.files;
                  if (!files) return;
                  for (let i = 0; i < files?.length; i++) uploadFile(files[i]);
                }}
                type="file"
                id="comment_files"
                style={{ display: "none" }}
                multiple={true}
              />

              <label htmlFor="comment_files" className={"info_attach_file"}>
                Выбрать
              </label>

              {commentFiles.length > 0 && (
                <div className={"attachments"} style={{ width: "156px" }}>
                  <div style={{ height: "10px" }}> </div>
                  {commentFiles.map((elem) => (
                    <FileElem
                      url={elem.url}
                      key={elem.id}
                      id={+elem.id}
                      name={elem.name}
                      deleteHandler={deleteFile}
                    />
                  ))}
                </div>
              )}
            </div>
          </NameWithInput>
        )}

        {/* Тут выбирается поле "загрузка" */}
        <NameWithInput
          title="Загрузка"
          inputName={"TaskWorkLoad"}
          iconName="clock"
        >
          <TaskWorkLoad
            disabled={
              checkedPersonalTask ||
              (selectedTask && selectedTask?.id_bp !== null) ||
              disabledComponent
            }
          />
        </NameWithInput>

        {/* Переход в дерево проектов  */}

        {/* ******************************************************************** */}
        {(selectedTask
          ? selectedTask.child_tasks
            ? selectedTask.child_tasks.length > 0 ||
              selectedTask.parent_id !== null
              ? true
              : false
            : false
          : false) && (
          <NameWithInput title="Дерево проектов" iconName="arrow-right">
            <Button
              style={{ color: "black", background: "#eee" }}
              title={"Перейти в дерево проектов"}
              onClick={() => {
                if (selectedTask) {
                  localStorage.setItem("taskChoice", "true");
                  localStorage.setItem("taskId", String(selectedTask.id));

                  localStorage.setItem("project_show_toggle", "tree");

                  let id = selectedTask.project_id;
                  window.location.replace(`/projects/${id}`);
                } else {
                  window.location.replace(`/projects`);
                }
              }}
            />
          </NameWithInput>
        )}

        {/* ******************************************************************** */}

        {/* ***************** */}
        {selectedTask && selectedTask.id_bp === null && (
          <NameWithInput title="Зависимости" iconName="arrow-right">
            <Dependencies />
          </NameWithInput>
        )}

        {!selectedTask && (
          <>
            <hr
              style={{
                border: "1px solid #E1E1E1",
                boxShadow: "0px -1px 3px rgba(0, 0, 0, 0.15)",
                marginTop: "58px",
                marginBottom: "37px",
              }}
            />

            <div className={"taskInfoPlateBottomRowButtons"}>
              <Button
                title={"ДОБАВИТЬ"}
                style={{
                  marginLeft: "0px",
                  width: "163px",
                  height: "50px",
                  background: "#01B0E9",
                  border: "1px solid #01B0E9",
                  borderRadius: "4px",
                  color: !isValid ? "#E0E0E0" : "#FFFFFF",
                  display: "flex",
                  alignItems: "center",
                  textAlign: "center",
                  fontFamily: "Roboto",
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "16px",
                }}
                isSending={status === "task" ? isSendingCreateNewTasks : false}
                disabled={!isValid}
                onClick={() => {
                  let endStartTaskTime: any = new Date();

                  let startTaskTime_millisecond = Number(
                    endStartTaskTime - startTaskTime
                  );

                  let taskTime = Math.floor(startTaskTime_millisecond / 1000);

                  dispatch(setTaskCreateTime(taskTime));
                  status = "task";

                  if (
                    !taskInfoPlate?.priority_id ||
                    taskInfoPlate?.priority_id == 0
                  ) {
                    CreateNotif(
                      "Заполните поле Приоритет для создания задачи!",
                      "warning"
                    );
                  } else {
                    if (!isSendingCreateNewTasks) {
                      isSendingNewTask(true);
                      successfulCreatedNewTasks({
                        createdTasks: [],
                        executorsIds: selectedItems.map((item) => item.value),
                        checkItems: [...checkList],
                      });
                      selectedItems.forEach((item) => {
                        createTask(item.value as number);
                      });
                      addEv(taskName, begin, end, priority_id);
                    }

                    const { isMeetingTask } = taskInfoPlate;

                    if (isMeetingTask) {
                      dispatch(setIsMeetingTask(false));
                      setDefaultValues();
                      dispatch(setShownTaskInfoPlate(false));
                    }

                    if (location.pathname.match("/tasks")) {
                      if (checkedPersonalTask === true) {
                        setTableOrTreeToggler("personal");
                        dispatch(setPrivateToggle(true));
                      } else {
                        setTableOrTreeToggler("work");
                        dispatch(setPrivateToggle(false));
                      }
                    }
                  }
                }}
              />

              <Button
                style={{
                  marginLeft: "50px",
                  background: "white",
                  color: "#292A34",
                  width: "64px",
                  height: "24px",
                  display: "flex",
                  top: "8px",
                  border: "none",
                  position: "relative",
                  fontWeight: 400,
                  fontSize: "14px",
                }}
                title="ОТМЕНА"
                design="secondary"
                onClick={() => {
                  if (isMeetingTask) dispatch(setIsMeetingTask(false));
                  setDefaultValues();
                  dispatch(setShownTaskInfoPlate(false));
                  setIsShowAddObject(!isShowAddObject);
                  if (deleteSelectedEvent) {
                    deleteSelectedEvent(cellData?.newEvent?.id);
                  }
                }}
              />

              {/********************************** */}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: State) => {
  return {
    users: state.commonInfo.users,
    selectedTask: getSelectedTask(state),
    currentUser: state.commonInfo.currentUserInfo,
    executor_id: state.taskInfoPlate.executor_id,
    taskInfoActiveTab: state.taskInfoPlate.taskInfoActiveTab,
    projectsSelect: getProjectsSelectList(state),
    usersSelect: getUsersSelectList(state),
    sectionSelect: getSectionSelectList(state),
    sections: state.commonInfo.sections,
    schedule: state.taskInfoPlate.executorSchedule,
    checkList: state.taskInfoPlate.checkList,
    name: state.taskInfoPlate.name,
    description: state.taskInfoPlate.description,
    project_id: state.taskInfoPlate.project_id,
    project_section_id: state.taskInfoPlate.project_section_id,
    commentFiles: state.taskInfoPlate.commentFiles,
    repeat: state.taskInfoPlate.repeat,
    isSendingCreateNewTasks: state.taskInfoPlate.isSendingCreateNewTasks,
    isShownTaskInfoPlate: state.taskInfoPlate.isShownTaskInfoPlate,
    parameter_trigger: state.taskInfoPlate.parameter_trigger,
    priorities: state.commonInfo.priorities,
    id_bp: state.taskInfoPlate.id_bp,
    projects: state.projectsPage.projectsAll,
    begin: state.taskInfoPlate.begin,
    end: state.taskInfoPlate.end,
    isCreated: state.taskInfoPlate.isSuccessTaskCreate,
    createdTaskInfo: state.commonInfo.createdTaskInfo,
    spectators: state.taskInfoPlate.spectators,
  };
};

const mapDispatchToProps = {
  setTaskPrivate,
  updateTask,
  dispatchCheckboxItem,
  changeReducerCheckboxItem,
  setTaskDescription,
  setTaskProject,
  fetchProjectSectionsTaskPlate,
  setTaskSection,
  setExecutorId,
  createTask,
  setDefaultValues,
  uploadFile,
  deleteFile,
  successfulCreatedNewTasks,
  isSendingNewTask,
  setCyclicTaskToChange,
  sendReqToChangeTemplRepTask,
  setStartCyclick,
  setFinishCyclick,
  setPrivateToggle,
  setIsSuccessTaskCreate,
  setTasksObservers,
};

export default connect(mapStateToProps, mapDispatchToProps)(InfoTab);
