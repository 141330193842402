import React, { FC, useEffect } from "react";
import { connect } from "react-redux";
import { State } from "../../rootReducer";
import FormTreaties from './components/FormTreaties/FormTreaties';
import TypicalTreatiesList from "./components/TypicalTreatiesList/TypicalTreatiesList";
import { User } from "../../common/types";
import { getMainTreaties, getTypicalTreaties, setCurrentPage } from "./actions";
import { useHistory } from "react-router-dom";


const TreatyPage: FC<{
  currentUserInfo: User | null;
  getTypicalTreaties: (params?: object) => void;
  setCurrentPage: (mode: number) => void;
  getMainTreaties: (params?: object) => void;
}> = ({ currentUserInfo, getTypicalTreaties, setCurrentPage, getMainTreaties }) => {
  
  const routerHistory = useHistory();

  useEffect(() => {
    setCurrentPage(1);
    getMainTreaties()
    getTypicalTreaties();
  }, []);

  useEffect(() => {
    if (currentUserInfo && !currentUserInfo.isAdmin) {
      routerHistory.push("/");
    }
  }, [currentUserInfo]);

  return (
    <div
      className="treaty-page"
      style={{
        position: "relative",
        width: "100%",
        height: "100%",
        overflow: "hidden",
      }}
    >
      <FormTreaties />
      <TypicalTreatiesList />

    </div>
  );
};

const mapStateToProps = (state: State) => ({
  currentUserInfo: state.commonInfo.currentUserInfo,
});

const mapDispatchToProps = {
  getTypicalTreaties,
  setCurrentPage,
  getMainTreaties
};

export default connect(mapStateToProps, mapDispatchToProps)(TreatyPage);
