import { call, put, takeEvery } from "redux-saga/effects";
import { CreateNotif } from "../../../../utils/createNotification";
import { FormAddTaskActionTypes } from "./action_types";
import { setCreatingTask, setFormOpen, setObjectSectionData } from "./actions";
import { fetchData } from "../../../../utils/fetchData";
import { messagesObjectTask, statusObject } from "../../constants";
import { formatToStringUTC } from "../../../../common/format";
import { setObjectSectionTaskList } from "../../actions";
import { setTasksList } from "../../../TasksPage/actions";

export function* watchCreateTaskObject() {
  yield takeEvery(FormAddTaskActionTypes.CREATE_TASK, createTask);
}

export function* watchGetObjectSectionId() {
  yield takeEvery(FormAddTaskActionTypes.GET_OBJECT_SECTION_DATA, getObjectSectionId);
}

function* getObjectSectionId({objectId}: any) {
  const response = yield call(
    fetchData.get,
    `/api/v1/projects/${objectId}/sections`
  );

  if(response) {
    yield put(setObjectSectionData(response))
  }
}

function* createTask({data}: any) {
  yield put(setCreatingTask(true));
  
  const convertedData = yield convertFrontToServerDataHelper(data);

  const response: any = yield call(
    fetchData.post,
      '/api/v1/tasks',
      JSON.stringify(convertedData)
  );

  if(response) {
    yield put(setObjectSectionTaskList([response]));
    yield put(setTasksList(response));
    
    yield put(setFormOpen(false));
    
    CreateNotif(messagesObjectTask.CREATE_OBJECT_TASK_SUCCESS, 'success');
  }
  
  yield put(setCreatingTask(false));
}

function convertServerToFrontDataHelper(data: any): any {

  return {
    id: 'test'
  }
}

function* convertFrontToServerDataHelper(data: any) {
  let result: any = {
    description: data.description,
    executor_id: data.executor,
    name: data.title,
    typical_task_id: data.typicalTaskId,
    begin: formatToStringUTC(data.startDate),
    end: formatToStringUTC(data.endDate),
    project_id: data.objectId,
    priority_id: data.priority,
    private: data.private,
    spectators: data.spectators,
    workflow_id: 1,
    status_id: statusObject.IN_WORK_STATUS,
    provide_to: 0, // ?? Пока что не понятно, как это работает
    work_load: 1
  };
  
  if(data.objectSectionData?.length) {
    result.project_section_id = data.objectSectionData[0].id;
  }

  if(data.files.length) {
    result.files = [data.files[0]];
  }
  
  return result;
}
