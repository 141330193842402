import React, { FC, useEffect, useState } from 'react';
import {connect} from 'react-redux';
import Select from "react-select";
import { State } from "../../../../rootReducer";
import {
  IObjectsFormAddTaskData,
  IObjectsFormAddTaskDataSelect
} from "../../../../pages/ObjectsPage/components/FormAddTask/interfaces";
import { checkInputs } from "../../../../utils/helpers/helpers";

import './SelectSimple.scss';

const SelectSimple: FC<{
  selectedValue: IObjectsFormAddTaskDataSelect,
  dataForSelect: IObjectsFormAddTaskDataSelect[],
  name: keyof IObjectsFormAddTaskData|null
  onChange: (data: IObjectsFormAddTaskDataSelect) => void,
  props?: any,
  isDisabled?: boolean,
  checkTriggerExternal?: string,
  rules?: any,
  typicalTasksMeta?: any,
  currentPage?: number,
  onScroll?: (e: React.UIEvent<HTMLDivElement>) => void
  onSearchTypicalTasks?: (value: string) => void
}> = ({
  selectedValue,
  dataForSelect,
  name,
  onChange,
  props,
  isDisabled = false,
  checkTriggerExternal,
  rules,
  onScroll,
  onSearchTypicalTasks,
}) => {
  const [skipCheck, setSkipCheck] = useState<boolean>(true);
  const [errorState, setErrorState] = useState<string[]>([]);
  
  const checkHelper = () => {
    if(skipCheck) {
      setSkipCheck(false);
      return;
    }

    setErrorState([]);
    
    const checkResult = checkInputs(rules, selectedValue.value);

    if(Array.isArray(checkResult)) {
      setErrorState(checkResult);
    }
  };

  useEffect(() => {
    if(checkTriggerExternal?.length) {
      checkHelper();
    }
  }, [checkTriggerExternal, selectedValue]);
  
  return (
    <>
      <Select
        isDisabled={isDisabled}
        type="text"
        value={selectedValue}
        isClearable={true}
        options={dataForSelect}
        className={"react-select" +
                  (errorState.length ? ' react-select--error' : '')
        }
        classNamePrefix="react-select"
        onChange={(value) => {
          if(value) {
            onChange(value);
          } else {
            onChange({value: '', label: ''});
          }
        }}
        noOptionsMessage={() => 'Ничего не найдено...'}
        onMenuScrollToBottom={onScroll}
        onInputChange={(value: string, { action }) => {
          if(onSearchTypicalTasks && (action === 'input-change' )) {
            onSearchTypicalTasks(value);
          }
        }}
        onMenuOpen={() => {
          if(
            onSearchTypicalTasks &&
            (!dataForSelect.length || !selectedValue.value)
          ) {
            onSearchTypicalTasks('');
          }
        }}
        
        {...props}
      />

      {errorState.length > 0 && (
        <div className="input-objects__error-messages">
          {errorState.map((error, i) => (
            <div className="input-objects__error-message" key={i}>
              {error}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state: State) => {
  return {
    
  };
};


const mapDispatchToProps = {
  
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectSimple);
