import React from "react";
import PersonalToggle from "../ui/toggle/PersonalToggle";
import { State } from "../../../../rootReducer";
import { connect } from "react-redux";
import { nanoid } from "nanoid";

import "./FilterBP.scss";
import ActivitySelectBP from "../ui/selectors/ActivitySelectBP";
import { setBPFormOpen, setIsEditForm } from "../../actions";

const FiltersBp: React.FC<{
  setBPFormOpen: (mode: boolean) => void;
  setIsEditForm: (mode: boolean) => void;
}> = ({ setBPFormOpen, setIsEditForm }) => {
  const formOpenHandler = () => {
    setIsEditForm(false)
    setBPFormOpen(true);
  };

  return (
    <div className="filter-business-process">
      <div className="filter-business-process__left">
        <div className="filter-business-process__filters-selectors">
          <div className="filter-business-process__filters-selector-item">
            <div className="filter-business-process__filters-selector-item-title">
              Состояние
            </div>
            <div className="filter-business-process__filters-selector-item-element filter-business-process__filters-selector-item-element--width">
              <ActivitySelectBP isSingleSelect={true} />
            </div>
          </div>
        </div>

        <div className="filter-business-process__filters-toggle">
          <PersonalToggle />
        </div>
      </div>

      <div className="filter-business-process__right">
        <button
          className="filter-business-process__add-btn"
          type="button"
          onClick={() => formOpenHandler()}
        >
          + Добавить бизнес-процесс
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = (state: State) => {
  return {};
};

const mapDispatchToProps = {
  setBPFormOpen,
  setIsEditForm
};

export default connect(mapStateToProps, mapDispatchToProps)(FiltersBp);
