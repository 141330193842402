import React, { FC } from "react";
import InputTypicalTask from "../../ui/InputTypicalTask/InputTypicalTask";
import { connect } from "react-redux";
import { State } from "../../../../../../rootReducer";
import { IExtraFormDocumentTabData } from "../../../../action_types";
import { setExtraFormDocumentTabData } from "../../../../actions";

import './DocumentTabTypicalTask.scss';

const DocumentTabTypicalTask: FC<{
  title: string,
  setExtraFormDocumentTabData: (documentTabData: IExtraFormDocumentTabData) => void
}> = ({
  title,
  setExtraFormDocumentTabData
}) => {
  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setExtraFormDocumentTabData({title: e.target.value});
  };

  const onCheckHandler = (isValid: boolean) => {
    setExtraFormDocumentTabData({isValid});
  }
  
  return (
    <div className='document-tab-typical-task'>
      <div className="document-tab-typical-task__input">
        <InputTypicalTask
          onCheck={onCheckHandler}
          onChange={onChangeInput}
          value={title}
        />
      </div>
    </div>
  )
}

const mapStateToProps = (state: State) => {
  return {
    title: state.typicalTasksPage.extraForm.documentTab.title as string
  }
};

const mapDispatchToProps = {
  setExtraFormDocumentTabData
}

export default connect(mapStateToProps, mapDispatchToProps)(DocumentTabTypicalTask);
