import { errorValidationMessages } from "../../pages/ObjectsPage/constants";

export const checkInputs = (
  rules: any, 
  value: string|number
): string[]|boolean => {
  if(!rules) return false;
  
  const errorMessages = {
    max: errorValidationMessages.MAX500,
    required: errorValidationMessages.REQUIRED
  };
  let errorState: string[] = [];
  const valueLocal = value.toString();
  
  rules.forEach(rule => {
    if(rule['required'] && !valueLocal?.length) {
      errorState.push(errorMessages[ 'required' ]);
    }

    if(rule['max']) {
      if(value && (valueLocal?.length > rule.max)) {
        errorState.push(errorMessages[ 'max' ]);
      }
    }
  });

  return errorState;
}

export const translateFile = (file, fn) => {
  let reader = new FileReader();
  
  reader.onload = function() {
    // @ts-ignore
    let result = [...new Uint8Array(reader.result)]
      .map(( item ) => item.toString(16).padStart(2, "0"))
      .join("");

    fn([{
      name: file.name,
      ext: getExtension(file.name),
      data: result 
    }]);
  };
  
  reader.readAsArrayBuffer(file);
}

function getExtension(filename: string): string {
  return filename.substring(filename.lastIndexOf('.') + 1);
}
