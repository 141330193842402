import React, { FC, useEffect, useState } from 'react';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { connect } from "react-redux";
import { State } from "../../../../../rootReducer";
import {ReactComponent as CloseIcon} from '../../../../../img/TrueIcons/close.svg';
import PreloaderLocal from "../../../../../common/components/PreloaderLocal/PreloaderLocal";
import {  setDeleteTaskModalData, setDeleteStateTaskRegular, deleteTaskSection } from "../../../actions";
import { IDeleteTaskModal } from "../../../interfaces";
import { deleteTaskStatuses } from '../../../../../common/constants';

import './styles.scss';

const ModalDeleteTask: FC<{
  isDeleteModalOpen?: boolean,
  deleteTaskModal: IDeleteTaskModal,
  setDeleteTaskModalData: (data: IDeleteTaskModal) => void,
  deleteTaskSection: (id: number) => void;
}> = ({
  isDeleteModalOpen,
  deleteTaskModal,
  setDeleteTaskModalData,
  deleteTaskSection
}) => {
  const textObj = {
    [deleteTaskStatuses.DELETE_TASK_ALLOWED]:
      {title: 'Удаление задачи', text: 'Задача будет удалена. Больше ее никто не увидит.<br/>Действие необратимо.', btnDeleteText: 'Удалить'},
    [deleteTaskStatuses.DELETE_TASK_ERROR]:
      {title: 'Удаление задачи', text: 'Произошла ошибка при удалении задачи...'}
  };
  
  const [text, setText] = useState<{
    title: string,
    text: string,
    btnDeleteText?: string
  }>(textObj[deleteTaskStatuses.DELETE_TASK_ERROR]);

  useEffect(() => {
    if(deleteTaskModal.statusTaskFromServer?.constraints.length) {
      const {constraints} = deleteTaskModal.statusTaskFromServer;
      const result = textObj[constraints[0]];

      if(result) setText(result);
      else setText(textObj[deleteTaskStatuses.DELETE_TASK_ERROR]);
    }
  }, [deleteTaskModal.isDeleteTaskModalOpen]);

  const handleClose = () => {
    setDeleteTaskModalData({taskId: 0, isDeleteTaskModalOpen: false});
  };

  const deleteTask = (id) => {
    deleteTaskSection(id);
  };
    
  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={'modal-delete-object-task'}
        open={isDeleteModalOpen ?? false}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={isDeleteModalOpen}>
          <div className={'modal-delete-typical-task__content-wr'}>
            <div className="modal-delete-typical-task__content">

              <div className="modal-delete-typical-task__content-item">
                <div className={'modal-delete-typical-task__title-wr'}>
                  <div className="modal-delete-typical-task__title">{text?.title}</div>
                  <div className="modal-delete-typical-task__delete-ico-wr"
                       onClick={handleClose}
                  >
                    <CloseIcon />
                  </div>
                </div>

                <div className="modal-delete-typical-task__text"
                     dangerouslySetInnerHTML={{__html: text?.text}}
                >
                </div>
              </div>
            </div>
            
            <div className="modal-delete-typical-task__btns">
              {text?.btnDeleteText &&
                <button className={
                  "modal-delete-typical-task__btn modal-delete-typical-task__btn--delete " + 
                  (deleteTaskModal.isShowTaskPreloader ? ' modal-delete-typical-task__btn--show-preloader' : '')
                }>
                <span className="modal-delete-typical-task__btn-title"
                      onClick={() => deleteTask(deleteTaskModal.taskId)}
                >{text?.btnDeleteText}</span>
                  <span className="modal-delete-typical-task__btn-preloader">
                  <PreloaderLocal />
                </span>
                </button>
              }
              
              <button className="modal-delete-typical-task__btn modal-delete-typical-task__btn--cancel"
                      onClick={handleClose}
              >
                <span className="modal-delete-typical-task__btn-title">Отмена</span>
              </button>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
}

const mapStateToProps = (state: State) => {
  return {
    isDeleteModalOpen: state.objectsPage.deleteTaskModal.isDeleteTaskModalOpen,
    deleteTaskModal: state.objectsPage.deleteTaskModal,
    deleteState: state.objectsPage.deleteStateRegular
  };
};

const mapDispatchToProps = {
  setDeleteTaskModalData,
  setDeleteStateTaskRegular,
  deleteTaskSection
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalDeleteTask);
