export enum sortConditions {
  ID = "id",
  NAME = "name",
  ALL_STAGE = "stage_count",
  ALL_BUSINESS_PROCESS = "bp_count",
  ALL_TASKS = "task_count",
  DURATION = "duration",
  AUTHOR = "author",
  CREATED_AT = "created_at",
  STATUS = "status",
}

export enum sortOrders {
  ASC,
  DESC,
}

export enum deleteTypicalTreatiesStatuses {
  DELETE_TREATY = 0,
  DELETE_TREATY_ALLOWED = 'allowed',
  DELETE_TREATY_ERROR = 'error'
}

export enum deleteStageStatuses {
  DELETE_STAGE= 0,
  DELETE_STAGE_ALLOWED = 'allowed',
  DELETE_STAGE_ERROR = 'error'
}

export enum messagesTypicalTreaties {
  DELETE_TREATY_ERROR = 'Произошла ошибка при удалении типового договора...',
  DELETE_TREATY_SUCCESS = 'Типовой договор успешно удален',
  CREATE_TREATY_SUCCESS = 'Типовой договор успешно создан',
  CREATE_TREATY_ERROR = 'Ошибка создания типового договра',
  UPDATE_TREATY_SUCCESS = 'Типовой договор успешно изменен'
}
