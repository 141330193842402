import React, { FC, useEffect, useState } from 'react';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { connect } from "react-redux";
import {ReactComponent as CloseIcon} from '../../../../../../img/TrueIcons/close.svg';
import { State } from "../../../../../../rootReducer";
import { checkAddObjectStatuses, messagesObjects } from "../../../../constants";
import { setCheckCreateObjectModal } from "../../../../actions";
import { ICheckAddTaskModal } from "../../../../interfaces";

import './CheckAddTask.scss';

const CheckAddTask: FC<{
  isCheckModalOpen?: boolean,
  checkAddObjectModal?: ICheckAddTaskModal,
  setCheckCreateObjectModal: (data: ICheckAddTaskModal) => void
}> = ({
  isCheckModalOpen,
  checkAddObjectModal,
  setCheckCreateObjectModal
}) => {
  const textObj = {
    [checkAddObjectStatuses.ADD_OBJECT_ERROR]:
      {title: 'Создание объекта невозможно', text: 'В системе существуют объекты со схожими параметрами.<br />Проверьте корректность значений для полей «Номер SAP» и «Номер договора».', btnCancelText: 'Понятно'},
    [checkAddObjectStatuses.UPDATE_OBJECT_ERROR]:
      {title: 'Обновление объекта невозможно', text: 'В системе существуют объекты со схожими параметрами.<br />Проверьте корректность значений для полей «Номер SAP» и «Номер договора».', btnCancelText: 'Понятно'},
  };
  
  const [text, setText] = useState<{
    title: string,
    text: string,
    btnDeleteText?: string
    btnCancelText: string
  }>(textObj[messagesObjects.DELETE_OBJECT_ERROR]);

  useEffect(() => {
    if(checkAddObjectModal?.statusFromServer?.constraints.length) {
      const {constraints} = checkAddObjectModal.statusFromServer;
      const result = textObj[constraints[0]];

      if(result) setText(result);
      else setText(textObj[messagesObjects.DELETE_OBJECT_ERROR]);
    }
  }, [checkAddObjectModal]);

  const handleClose = () => {
    setCheckCreateObjectModal({isModalOpen: false});
  };
  
  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={'modal-delete-typical-task'}
        open={isCheckModalOpen ?? false}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={isCheckModalOpen}>
          <div className={'modal-delete-typical-task__content-wr'}>
            <div className="modal-delete-typical-task__content">

              <div className="modal-delete-typical-task__content-item">
                <div className={'modal-delete-typical-task__title-wr'}>
                  <div className="modal-delete-typical-task__title">{text?.title}</div>
                  <div className="modal-delete-typical-task__delete-ico-wr"
                       onClick={handleClose}
                  >
                    <CloseIcon />
                  </div>
                </div>

                <div className="modal-delete-typical-task__text"
                     dangerouslySetInnerHTML={{__html: text?.text}}
                >
                </div>
              </div>
            </div>
            
            <div className="modal-delete-typical-task__btns">
              <button className="modal-delete-typical-task__btn modal-delete-typical-task__btn--cancel"
                      onClick={handleClose}
              >
                <span className="modal-delete-typical-task__btn-title">{text?.btnCancelText}</span>
              </button>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
}

const mapStateToProps = (state: State) => {
  return {
    isCheckModalOpen: state.objectsPage.modalCheckAddTask?.isModalOpen,
    checkAddObjectModal: state.objectsPage.modalCheckAddTask
  };
};

const mapDispatchToProps = {
  setCheckCreateObjectModal
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckAddTask);
