import React, { useEffect } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import styled from "styled-components";
import { connect } from "react-redux";
import PreloaderLocal from "../../../../../common/components/PreloaderLocal/PreloaderLocal";
import { State } from "../../../../../rootReducer";

import "./MenuTreatyMain.scss";
import {
  deleteTreatyStage,
  setExtraFormTreatyCreateFormOpen,
  setExtraFormTreatyDefaultOpen,
  setExtraFormTreatyEditFormOpen,
  setExtraFormTreatyStageData,
  setExtraFormTreatyTasksAndBPFormOpen,
} from "../../../actions";

interface ITreatyMainMenuProps {
  id: number | string;
  anchorEl: HTMLElement | null;
  setAnchorEl: (arg0: React.MouseEvent<HTMLButtonElement> | null) => void;
  setExtraFormTreatyEditFormOpen: (mode: boolean) => void;
  setExtraFormTreatyStageData: (mode: any) => void;
  setExtraFormTreatyDefaultOpen: (mode: boolean) => void;
  setExtraFormTreatyCreateFormOpen: (mode: boolean) => void;
  name?: string;
  deleteTreatyStage: (id: number | string) => void;
  setExtraFormTreatyTasksAndBPFormOpen: (mode: boolean) => void;
  stages?: any;
}

const MenuStyle = {
  marginTop: "45px",
};

const MenuItemStyle = {
  display: "flex",
  alignItems: "center",
  marginBottom: "5px",
  fontSize: "14px",
};

const MenuIcon = styled.div`
  margin-right: 10px;
  display: flex;
  align-items: center;

  > svg {
    stroke: #292a34;
  }
`;

const MenuTitle = styled.div``;

const MenuTreatyMain = ({
  id,
  anchorEl,
  setAnchorEl,
  setExtraFormTreatyEditFormOpen,
  setExtraFormTreatyStageData,
  name,
  setExtraFormTreatyDefaultOpen,
  setExtraFormTreatyCreateFormOpen,
  deleteTreatyStage,
  setExtraFormTreatyTasksAndBPFormOpen,
  stages
}: ITreatyMainMenuProps) => {
  const clickTaskActionsCloseHandler = (e) => {
    setAnchorEl(null);
    e.stopPropagation();
  };

  const clickDeleteHandler = (id: string | number, e) => {
    e.stopPropagation();
    setAnchorEl(null);
    deleteTreatyStage(id)
  };
  const clickEditHandler = (id: string | number, e) => {
    e.stopPropagation();
    setExtraFormTreatyCreateFormOpen(false);
    setExtraFormTreatyStageData({ id: id, nameStage: name });
    setExtraFormTreatyEditFormOpen(true);
    setAnchorEl(null);
    setExtraFormTreatyDefaultOpen(false);
    setExtraFormTreatyTasksAndBPFormOpen(false)
  };

  return (
    <div>
      <Menu
        className="treaty-stages-menu "
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={(e) => clickTaskActionsCloseHandler(e)}
        style={MenuStyle}
      >
        <MenuItem
          style={{ ...MenuItemStyle, marginBottom: 0 }}
          onClick={(e) => clickEditHandler(id, e)}
        >
          <MenuTitle className={"treaty-stages-menu__title-wr"}>
            <div className="treaty-stages-menu__title">Редактировать этап</div>
            <div className="treaty-stages-menu__preloader">
              <PreloaderLocal />
            </div>
          </MenuTitle>
        </MenuItem>
        {stages.length > 1 ? 
        <MenuItem
          style={{ ...MenuItemStyle, marginBottom: 0 }}
          onClick={(e) => clickDeleteHandler(id, e)}
        >
          <MenuTitle className={"treaty-stages-menu__title-wr"}>
            <div className="treaty-stages-menu__title">Удалить этап</div>
            <div className="treaty-stages-menu__preloader">
              <PreloaderLocal />
            </div>
          </MenuTitle>
        </MenuItem> : ""}
      </Menu>
    </div>
  );
};

const mapStateToProps = (state: State) => {
  return {
    stages: state.typicalTreatyPage.normalForm.stages,
  };
};

const mapDispatchToProps = {
  setExtraFormTreatyEditFormOpen,
  setExtraFormTreatyStageData,
  setExtraFormTreatyDefaultOpen,
  setExtraFormTreatyCreateFormOpen,
  deleteTreatyStage,
  setExtraFormTreatyTasksAndBPFormOpen
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuTreatyMain);
