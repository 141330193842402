// Кнопка предупреждения о новых сообщениях (Вызывается NotificationWindow,
// где прорисовывается "Колокольчик" - кнопочка для прочтения сообщений
// и "точка" - уведомление о новых сообщениях)
import React, {useState} from 'react';
import {connect} from 'react-redux';
import {State} from '../../../rootReducer';
import './NotificationButton.sass';
import NotificationWindow from '../NotificationWindow/NotificationWindow';





const NotificationButton = ({
                    notifications
                }) => {

    const [showNotificationWindow, setShowNotificationWindow] = useState<boolean>(false);

    return (
              
                    <>
                      {/* Для показа уведомлений */} 
                    <NotificationWindow
                        showNotificationWindow={showNotificationWindow}
                        setShowNotificationWindow={setShowNotificationWindow}
                        notifications={notifications}
                    />
                    </>
    );
};

const mapStateToProps = (state: State) => ({
    notifications: state.commonInfo.remote_notifications.unread,
  
});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationButton);
