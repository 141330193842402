import React, { useEffect } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import styled from "styled-components";
import Trash from "../../../../../img/projectIcons/Trash";
import { connect } from "react-redux";
import PreloaderLocal from "../../../../../common/components/PreloaderLocal/PreloaderLocal";
import { State } from "../../../../../rootReducer";
import { checkDeleteTypicalTask } from "../../../actions";

import './TaskMenuTypicalTask.scss';

interface ITaskMenuProps {
  id: number|string,
  anchorEl: HTMLElement|null,
  setAnchorEl: (arg0: React.MouseEvent<HTMLButtonElement>|null) => void,
  isShowMenuPreloader: boolean,
  checkDeleteTypicalTask: (id: string|number) => void,
  isDeleteModalOpen?: boolean
}

const MenuStyle = {
  marginTop: '45px'
};

const MenuItemStyle = {
  display: 'flex',
  alignItems: 'center',
  marginBottom: '5px',
  fontSize: '14px'
};

const MenuIcon = styled.div `
  margin-right: 10px;
  display: flex;
  align-items: center;
  
  > svg {
    stroke: #292A34;
  }
`;

const MenuTitle = styled.div ``;

const TaskMenuTypicalTask = ({
  id, 
  anchorEl, 
  setAnchorEl,
  isShowMenuPreloader,
  checkDeleteTypicalTask,
  isDeleteModalOpen
}: ITaskMenuProps) => {
  const clickTaskActionsCloseHandler = () => {
    setAnchorEl(null);
  };
  
  const clickDeleteHandler = (id: string|number) => {
    checkDeleteTypicalTask(id);
    
  };

  useEffect(() => {
    if(isDeleteModalOpen) clickTaskActionsCloseHandler();
  }, [isDeleteModalOpen]);
  
  return ( 
    <Menu
      className="task-menu-typical-task"
      anchorEl={anchorEl}
      open={!!anchorEl}
      onClose={() => clickTaskActionsCloseHandler()}
      style={MenuStyle}
    >
      <MenuItem 
        style={{ ...MenuItemStyle, marginBottom: 0 }}
        onClick={() => clickDeleteHandler(id)}
      >
        <MenuIcon>
          <Trash />
        </MenuIcon>
        
        <MenuTitle className={'task-menu-typical-task__title-wr' + 
                            (isShowMenuPreloader ? ' task-menu-typical-task__title-wr--show-preloader' : '')
        }>
          <div className="task-menu-typical-task__title">Удалить типовую задачу</div>
          <div className="task-menu-typical-task__preloader">
            <PreloaderLocal />
          </div>
        </MenuTitle>
      </MenuItem>
    </Menu>
  )
}

const mapStateToProps = (state: State) => {
  return {
    isShowMenuPreloader: state.typicalTasksPage.isShowMenuPreloader,
    isDeleteModalOpen: state.typicalTasksPage.deleteTypicalTaskModal.isDeleteModalOpen,
  };
};

const mapDispatchToProps = {
  checkDeleteTypicalTask
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskMenuTypicalTask);
