import { IMeta } from "../../common/types";
import { ITaskBP } from "../BusinessPage/interfaces";
import { ITypicalTaskServerData } from "../TypicalTasks/interfaces";
import {
  TreatyPageActionTypes,
  setExtraFormTreatyDefaultOpenAction,
  setFormDefaultValuesAction,
  setIsEditFormAction,
  setIsLoadingTreatyAction,
  setIsTreatyFormOpenAction,
  setIsTreatyFullFormOpenAction,
  setNormalFormDataAction,
  setTreatiesAction,
  getTypicalTreatiesAction,
  setCurrentPageAction,
  TypeTypicalTreatiesActivity,
  setTypicalTreatiesFilterActivityAction,
  TypeTypicalTreatiesPersonal,
  setTypicalTreatiesFilterPersonalAction,
  setSortConditionsAction,
  setSearchTratiesAction,
  getTypicalTreatyAction,
  setIsLoadingTreatyCardAction,
setExtraFormTreatyStageDatatAction,
setExtraFormTreatyEditFormOpenAction,
  setTtMetaAction,
  deleteTypicalTreatiesAction,
  setDeleteModalTreatyAction,
  deleteTypicalTreatiesFromStateAction,
  checkDeleteTreatyAction,
  getMainTreatiesAction,
  setMainTreatiesAction,
  setExtraFormTreatyCreateFormOpenAction,
  createTreatyAction,
  updateTreatyAction,
  updateTreatyStageStoreAction,
  setDeleteStageTreatyModalAction,
  deleteTreatyStageAction,
  deleteTreatyStageStoreAction,
  addStageTreatyAction,
  setExtraFormActiveTabIndexAction,
  setLoadingExtraFormTreatyAction,
  setItemsStageTreatyExtraAction,
  setExtraFormTreatyTasksAndBPFormOpenAction,
  setBpListTreatyExtraFormAction,
  getBpListTreatyExtraFormAction,
  setCurrentPageBpListTreatyAction,
  setStageIdTreatyExtraAction,
  setSearchTypicalBpsAction,
  deleteItemFromStagesAction,
  setDeleteItemStageTreatyModalAction,
  updateTypicalTreatyAction,
  updateTreatyStoreAction,
  updateTypicalTreatyStoreAction,
  setMainBoolAction,
  updateStageActualAction,
  setTreatyForFilterExtraAction,
  setIsSavingTreatyTaskAction,
} from "./action_types";
import { INormalFormTreaty, ISortConditions, IDeleteTypicalTreatiesModal, IEditStage, ITypicalTreatiesServerData, IDeleteStageTreatiesModal, IDeleteStageItemTreatiesModal, IMainTreatyServerData, ITreatyFilterFormExtra} from "./interfaces";

export const getMainTreaties = (
  params?: {},
  action?: string
): getMainTreatiesAction => ({
  type: TreatyPageActionTypes.GET_MAIN_TREATIES,
  params,
  action
})

export const setMainTreaties = (
  mainTreaty: any,
) : setMainTreatiesAction => ({
  type: TreatyPageActionTypes.SET_MAIN_TREATIES,
  mainTreaty,
})

export const setNormalForm = (
  normalFormData: INormalFormTreaty
): setNormalFormDataAction => ({
  type: TreatyPageActionTypes.SET_NORMAL_FORM_DATA,
  normalFormData,
});

export const setIsEditForm = (isEditForm: boolean): setIsEditFormAction => ({
  type: TreatyPageActionTypes.SET_IS_EDIT_FORM_TREATY,
  isEditForm,
});

export const setIsTreatyFormOpen = (
  isFormTreatyOpen: boolean
): setIsTreatyFormOpenAction => ({
  type: TreatyPageActionTypes.SET_IS_FORM_TREATY,
  isFormTreatyOpen,
});

export const setIsTreatyFullFormOpen = (
  isFullFormTreatyOpen: boolean
): setIsTreatyFullFormOpenAction => ({
  type: TreatyPageActionTypes.SET_IS_FULL_FORM_TREATY,
  isFullFormTreatyOpen,
});

export const setIsLoadingTreaty = (isLoadingTreaty: boolean): setIsLoadingTreatyAction => ({
  type: TreatyPageActionTypes.SET_LOADING_TREATY,
  isLoadingTreaty
})

export const setTreaties = (treaties:any, action?: string): setTreatiesAction => ({
  type: TreatyPageActionTypes.SET_TREATIES,
  treaties,
  action
});

export const setFormDefaultValues = (): setFormDefaultValuesAction => ({
  type: TreatyPageActionTypes.SET_FORM_DEFAULT_VALUES
});

export const setExtraFormTreatyDefaultOpen = (isExtraFormTreatyDefaultOpen: boolean): setExtraFormTreatyDefaultOpenAction => ({
  type: TreatyPageActionTypes.SET_EXTRA_FORM_TREATY_DEFAULT_OPEN,
  isExtraFormTreatyDefaultOpen
});

export const getTypicalTreaties = (
  params?: {},
  action?: string
): getTypicalTreatiesAction => ({
  type: TreatyPageActionTypes.GET_TYPICAL_TRATIES,
  params,
  action,
});

export const setCurrentPage = (currentPage: number): setCurrentPageAction => ({
  type: TreatyPageActionTypes.SET_CURRENT_PAGE,
  currentPage,
});

export const setTypicalTreatiesFilterActivity = (activity: TypeTypicalTreatiesActivity): setTypicalTreatiesFilterActivityAction => ({
  type: TreatyPageActionTypes.SET_TYPICAL_TREATIES_FILTER_ACTIVITY,
  activity
})

export const setTypicalTreatiesFilterPersonal = (personal: TypeTypicalTreatiesPersonal): setTypicalTreatiesFilterPersonalAction => ({
  type: TreatyPageActionTypes.SET_TYPICAL_TREATIES_FILTER_PERSONAL,
  personal
})

export const setSortCondition = (
  sortCondition: ISortConditions
) : setSortConditionsAction => ({
  type: TreatyPageActionTypes.SET_SORT_CONDITION,
  sortCondition
})

export const setSearchTt = (searchTt: string | null): setSearchTratiesAction => ({
  type: TreatyPageActionTypes.SET_SEARCH_TRATIES,
  searchTt
})

export const setTtMeta = (typicalTreatiesMeta: IMeta): setTtMetaAction => ({
  type: TreatyPageActionTypes.SET_TT_META,
  typicalTreatiesMeta
})

export const deleteTypicalTreaties = (id: string | number): deleteTypicalTreatiesAction => ({
  type: TreatyPageActionTypes.DELETE_TYPICAL_TREATIES,
  id
})

export const setDeleteModalTypicalTreaties = (data: IDeleteTypicalTreatiesModal): setDeleteModalTreatyAction => ({
  type: TreatyPageActionTypes.SET_DELETE_MODAL_TREATY,
  data
})

export const deleteTypicalTreatiesFromState = (id: string | number): deleteTypicalTreatiesFromStateAction => ({
  type: TreatyPageActionTypes.DELETE_TYPICAL_TREATIES_FROM_STATE,
  id
})

export const checkDeleteTreaty = (id: string | number): checkDeleteTreatyAction => ({
  type: TreatyPageActionTypes.CHECK_DELETE_TREATY,
  id
})

export const getTypicalTreaty = (id: number|string): getTypicalTreatyAction => ({
  type: TreatyPageActionTypes.GET_TYPICAL_TREATY,
  id,
});

export const setIsLoadingTreatyCard = (isLoadingTreatyCard: boolean): setIsLoadingTreatyCardAction => ({
  type: TreatyPageActionTypes.SET_LOADING_TREATY_CARD,
  isLoadingTreatyCard
})

export const setExtraFormTreatyStageData = (stageData: IEditStage): setExtraFormTreatyStageDatatAction => ({
  type: TreatyPageActionTypes.SET_EXTRA_FORM_STAGE_DATA,
  stageData
});

export const setExtraFormTreatyEditFormOpen = (isEditStageOpen: boolean): setExtraFormTreatyEditFormOpenAction => ({
  type: TreatyPageActionTypes.SET_EXTRA_FORM_OPEN_STAGE_EDIT_FORM,
  isEditStageOpen
});

export const setExtraFormTreatyCreateFormOpen = (isCreateStageOpen: boolean): setExtraFormTreatyCreateFormOpenAction => ({
  type: TreatyPageActionTypes.SET_EXTRA_FORM_OPEN_STAGE_CREATE_FORM,
  isCreateStageOpen
});

export const updateTreatyStageStore = ( id: number | string, name: string): updateTreatyStageStoreAction => ({
  type: TreatyPageActionTypes.UPDATE_TREATY_STAGE,
  id,
  name
});

export const updateMainTreaty = (data: object): updateTreatyAction => ({
  type: TreatyPageActionTypes.UPDATE_TREATY,
  data
});

export const updateMainTreatyStore = (data: any): updateTreatyStoreAction => ({
  type: TreatyPageActionTypes.UPDATE_TREATY_STORE,
  data
});

export const updateTypicalTreaty= (data: object): updateTypicalTreatyAction => ({
  type: TreatyPageActionTypes.UPDATE_TYPICAL_TREATY,
  data
});

export const updateTypicalTreatyStore = (data: object): updateTypicalTreatyStoreAction => ({
  type: TreatyPageActionTypes.UPDATE_TYPICAL_TREATY_STORE,
  data
});

export const createTreaty = (data: object): createTreatyAction => ({
  type: TreatyPageActionTypes.CREATE_TREATY,
  data
});

export const deleteTreatyStage = (id: string | number): deleteTreatyStageAction => ({
  type: TreatyPageActionTypes.DELETE_STAGE,
  id
})

export const deleteTreatyStageStore = (id: number | string): deleteTreatyStageStoreAction => ({
  type: TreatyPageActionTypes.DELETE_STAGE_STORE,
  id,
});

export const setDeleteStageTreatyModal = (data: IDeleteStageTreatiesModal): setDeleteStageTreatyModalAction => ({
  type: TreatyPageActionTypes.SET_DELETE_STAGE_MODAL_TREATY,
  data
})

export const addStageTreaty = (data: any): addStageTreatyAction => ({
  type: TreatyPageActionTypes.ADD_STAGE,
  data
})

export const setTaskItemsStageTreaty = (
  itemAdd?: any,
): setItemsStageTreatyExtraAction => ({
  type: TreatyPageActionTypes.SET_TASK_ITEMS_STAGE_TREATY,
  itemAdd,
});

export const setStageIdTreaty = (
  id?: number | string,
): setStageIdTreatyExtraAction => ({
  type: TreatyPageActionTypes.SET_STAGE_ID_TREATY,
  id,
});

export const setExtraFormActiveTabIndex = (activeTabIndex: number): setExtraFormActiveTabIndexAction => ({
  type: TreatyPageActionTypes.SET_EXTRA_FORM_ACTIVE_TAB_INDEX,
  activeTabIndex
});

export const setLoadingExtraFormTreaty = (
  isLoading: boolean
): setLoadingExtraFormTreatyAction => ({
  type: TreatyPageActionTypes.SET_LOADING_EXTRA_FORM_TREATY,
  isLoading,
});

export const setExtraFormTreatyTasksAndBPFormOpen = (
  isTasksAndBpOpen: boolean
  ): setExtraFormTreatyTasksAndBPFormOpenAction => ({
  type: TreatyPageActionTypes.SET_EXTRA_FORM_OPEN_TASKS_BP_FORM,
  isTasksAndBpOpen
});

export const setBpListTreaty = (
  bpList: any,
  action?: string
): setBpListTreatyExtraFormAction => ({
  type: TreatyPageActionTypes.SET_BP_LIST_TREATY,
  bpList,
  action
});

export const getBpListTreatyExtraForm = (
  params?: {},
  action?: string
): getBpListTreatyExtraFormAction => ({
  type: TreatyPageActionTypes.GET_BP_LIST_TREATY,
  params,
  action,
});

export const setCurrentPageBpListTreaty = (currentPageBpList: number): setCurrentPageBpListTreatyAction => ({
  type: TreatyPageActionTypes.SET_CURRENT_PAGE_BP_LIST_TREATY,
  currentPageBpList,
});

export const setSearchTypicalBps = (searchTypicalBps: string|null): setSearchTypicalBpsAction => ({
  type: TreatyPageActionTypes.SET_SEARCH_BPS,
  searchTypicalBps
});

export const deleteItemFromStages = (stageId: string | number, itemId: string | number): deleteItemFromStagesAction => ({
  type: TreatyPageActionTypes.DELETE_ITEM_FROM_STAGES,
  stageId,
  itemId
});

export const setDeleteItemStageTreatyModal = (data: IDeleteStageItemTreatiesModal): setDeleteItemStageTreatyModalAction => ({
  type: TreatyPageActionTypes.SET_DELETE_ITEM_STAGE_MODAL_TREATY,
  data
});

export const setMainBool = (
  isMain: boolean
): setMainBoolAction => ({
  type: TreatyPageActionTypes.SET_MAIN_BOOL,
  isMain,
});

export const updateStageActual = (stageId: number | string, isActual: number): updateStageActualAction => ({
  type: TreatyPageActionTypes.SET_TYPICAL_TREATY_ACTUAL,
  stageId,
  isActual,
});

export const setTreatyForFilterExtra = (treatyForFilter: ITreatyFilterFormExtra): setTreatyForFilterExtraAction => ({
  type: TreatyPageActionTypes.SET_TREATY_FORM_FILTER,
  treatyForFilter
});

export const setIsSavingTreaty = (isSavingTreaty: boolean): setIsSavingTreatyTaskAction => ({
  type: TreatyPageActionTypes.SET_SAVING_TREATY,
  isSavingTreaty
});
