import React, { FC } from 'react';
import './TaskTreaties.scss'
import { State } from '../../../../../../../rootReducer';
import { connect } from 'react-redux';
import { getDuration, isPositiveDuration } from '../../../../../../BusinessPage/helpers/helpersCommon';


const TaskTreaties: FC<{ task: any, index: number }> = ({ task, index }) => {

  const renderChildTasks = (children) => {
    if (!children || children.length === 0) {
      return null;
    }

    return (
      <ul>
        {children.map((childTask, childIndex) => (
            <TaskTreaties task={childTask} index={childIndex} key={childTask.id} />
        ))}
      </ul>
    );
  };

  return (
    <>
    <div
      className={"task_item__before_task"}
    >
      <div className="task_item__id">{task.id}</div>
      <div className="task_item__name">{task?.title}</div>
      <div className="task_item__duration">
        {isPositiveDuration(task.time) ? (
          getDuration(task.time)
        ) : (
          <span>&mdash;</span>
        )}
      </div>
    </div>
      {/* Рекурсивный вызов для вложенных задач */}
      {renderChildTasks(task.children)}
      </>
  );
};

const mapStateToProps = (state: State) => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(TaskTreaties);