import {
  setCheckTriggerAction,
  FormEditTaskActionTypes,
  setFormOpenAction,
  setSelectObserversAction,
  setCreatingTaskAction,
  setActiveTabIndexAction,
  setSelectSelectedValueAction,
  setSelectValuesAction,
  setInputAction,
  setCheckboxAction,
  getTaskAction,
  setLoadingTaskAction,
  setTaskFormDataAction,
  getStagesAction,
  setStagesAction,
  updateTaskAction,
  setExtraSelectedValueAction,
  setExtraSelectChecklistValuesAction,
  setExtraRadioAction,
  setExtraSelectSelectedValueAction,
  setExtraSelectValuesAction,
  setExtraInputValueAction,
  setExtraFileValueAction,
  setStatusAction,
  setDefaultValuesAction,
  setObjectsAction,
  getObjectsAction,
  getChecklistAction,
  setChecklistAction, 
  setCheckRulesAction
} from "./action_types";
import {
  IExtraArrayValuesSelected,
  IFormAddTaskAdditionalFields,
  IObjectsFormAddTaskDataSelect
} from "../FormAddTask/interfaces";
import { IChecklistTask, IObjectsFormEditTaskData, IObjectsFormEditTaskDataSelect } from "./interfaces";
import { IEditingObjectsFile } from "../../interfaces";

export const setDefaultValues = (): setDefaultValuesAction => ({
  type: FormEditTaskActionTypes.SET_DEFAULT_VALUES
});

export const getTask = (id: string|number): getTaskAction => ({
  type: FormEditTaskActionTypes.GET_TASK,
  id
});

export const getStages = (): getStagesAction => ({
  type: FormEditTaskActionTypes.GET_STAGES
});

export const setStages = (stages: IObjectsFormEditTaskDataSelect[]): setStagesAction => ({
  type: FormEditTaskActionTypes.SET_STAGES,
  stages
});

export const getObjects = (): getObjectsAction => ({
  type: FormEditTaskActionTypes.GET_OBJECTS
});

export const setObjects = (objects: any): setObjectsAction => ({
  type: FormEditTaskActionTypes.SET_OBJECTS,
  objects
});

export const getChecklist = (taskId: string|number): getChecklistAction => ({
  type: FormEditTaskActionTypes.GET_CHECKLIST,
  taskId
});

export const setChecklist = (data: IChecklistTask): setChecklistAction => ({
  type: FormEditTaskActionTypes.SET_CHECKLIST,
  data
});

export const setTaskFormData = (taskFormData: IObjectsFormEditTaskData|null, additionalFields: any): setTaskFormDataAction => ({
  type: FormEditTaskActionTypes.SET_TASK_FORM_DATA,
  taskFormData,
  additionalFields
});

export const setLoadingTask = (isLoadingTask: boolean): setLoadingTaskAction => ({
  type: FormEditTaskActionTypes.SET_LOADING_TASK,
  isLoadingTask
});

export const setActiveTabIndex = (activeTabIndex: number): setActiveTabIndexAction => ({
  type: FormEditTaskActionTypes.SET_ACTIVE_TAB_INDEX,
  activeTabIndex 
});

export const setSelectObservers = (data: number[]): setSelectObserversAction => ({
  type: FormEditTaskActionTypes.SET_OBSERVERS,
  data
});

export const updateTask = (data: object): updateTaskAction => ({
  type: FormEditTaskActionTypes.UPDATE_TASK,
  data
});

export const setCreatingTask = (isCreatingTask: boolean): setCreatingTaskAction => ({
  type: FormEditTaskActionTypes.SET_CREATING_TASK,
  isCreatingTask
});

export const setCheckTrigger = (checkTrigger: string): setCheckTriggerAction => ({
  type: FormEditTaskActionTypes.SET_CHECK_TRIGGER,
  checkTrigger
});

export const setSelectSelectedValue = (data: IObjectsFormAddTaskDataSelect, name: keyof IObjectsFormEditTaskData): setSelectSelectedValueAction => ({
  type: FormEditTaskActionTypes.SET_SELECT_SELECTED_VALUE,
  data,
  name
});

export const setExtraSelectChecklistValues = (data: IExtraArrayValuesSelected|IExtraArrayValuesSelected[]): setExtraSelectChecklistValuesAction => ({
  type: FormEditTaskActionTypes.SET_EXTRA_SELECT_CHECKLIST_VALUES,
  data
});

export const setExtraSelectedValue = (name: keyof IFormAddTaskAdditionalFields, data: IExtraArrayValuesSelected|IExtraArrayValuesSelected[]): setExtraSelectedValueAction => ({
  type: FormEditTaskActionTypes.SET_EXTRA_SELECTED_VALUE,
  name,
  data,
});

export const setExtraRadio = (value: string, serverId: string): setExtraRadioAction => ({
  type: FormEditTaskActionTypes.SET_EXTRA_RADIO,
  value,
  serverId
});

export const setExtraSelectSelectedValue = (value: IObjectsFormAddTaskDataSelect): setExtraSelectSelectedValueAction => ({
  type: FormEditTaskActionTypes.SET_EXTRA_SELECT_SELECTED_VALUE,
  value
});

export const setExtraSelectValues = (values: IObjectsFormAddTaskDataSelect[]): setExtraSelectValuesAction => ({
  type: FormEditTaskActionTypes.SET_EXTRA_SELECT_VALUES,
  values
});

export const setExtraInputValue = (value: string, serverId: string): setExtraInputValueAction => ({
  type: FormEditTaskActionTypes.SET_EXTRA_INPUT_VALUE,
  value,
  serverId
});

export const setExtraFileValue = (value: IEditingObjectsFile): setExtraFileValueAction => ({
  type: FormEditTaskActionTypes.SET_EXTRA_FILE_VALUE,
  value
});

export const setSelectValues = (data: IObjectsFormAddTaskDataSelect[], name: keyof IObjectsFormEditTaskData): setSelectValuesAction => ({
  type: FormEditTaskActionTypes.SET_SELECT_VALUES,
  data,
  name
});

export const setCheckbox = (value: boolean, name: keyof IObjectsFormEditTaskData): setCheckboxAction => ({
  type: FormEditTaskActionTypes.SET_CHECKBOX,
  value,
  name
});

export const setSimpleDataFormEditTask = ( value: string|Date, name: keyof IObjectsFormEditTaskData): setInputAction => ({
  type: FormEditTaskActionTypes.SET_INPUT,
  value,
  name
});

export const setFormOpen = (isFormOpen: boolean): setFormOpenAction => ({
  type: FormEditTaskActionTypes.SET_FORM_OPEN,
  isFormOpen
});

export const setStatus = (status_id: number): setStatusAction => ({
  type: FormEditTaskActionTypes.SET_STATUS,
  status_id
});

export const setCheckRulesEditTask = (rules: any): setCheckRulesAction => ({
  type: FormEditTaskActionTypes.SET_CHECK_RULES,
  rules
});
