import { Reducer } from "redux";
import {
  ITypicalState,
  TypicalTasksAction,
  TypicalTasksActionTypes,
  TArrayValues
} from "./action_types";
import {
  TypicalTasksActivity,
  TypicalTasksPersonal,
  TypicalTasksTime,
} from "../../common/constants";
import { DataTypes, FieldSizes, sortConditions, sortOrders, TabIdsExtraForm } from "./constants";
import { nanoid } from "nanoid";

const initialState = {
  tasks: [],
  isLoadingTypicalTasks: false,
  isLoadingTypicalTask: false,
  isCreatingTypicalTask: false,
  formDefaultValuesTrigger: '',
  isShowMenuPreloader: false,
  isEditForm: false,
  filters: {
    usersDataForFilter: [],
    usersDataForFilterIsLoading: true,
    usersIds: [],
    activity: TypicalTasksActivity.ACTIVE_STATUS,
    personal: TypicalTasksPersonal.NOT_PERSONAL_STATUS,
  },
  sortCondition: {
    columnId: sortConditions.CREATED_AT, 
    value: sortOrders.ASC
  },
  isFormTasksOpen: false,
  normalForm: {
    idTaskEdited: '',
    addFieldTrigger: '',
    name: '',
    timeValue: '1',
    timeType: TypicalTasksTime.DAY_FORMAT,
    executor: {value: '', label: ''},
    delegation: false,
    description: '',
    checkList: [],
    additionalFields: [],
    author: '',
    createdAt: '',
    activity: TypicalTasksActivity.ACTIVE_STATUS,
  },
  extraForm: {
    activeTabIndex: TabIdsExtraForm.INPUT_TAB,
    fieldAddToNormalForm: null,
    checkTrigger: "",
    refreshCheckboxTrigger: '',
    isExtraFormDefaultOpen: false,
    inputTab: {
      title: "",
      dataType: DataTypes.VALUE_ANY,
      fieldSize: FieldSizes.VALUE_STANDARD,
      isRequired: false,
      isSendToProject: false,
      isValid: false
    },
    selectTab: {
      title: "",
      arrayValues: [],
      isRequired: false,
      isSendToProject: false,
      isValid: false
    },
    radioTab: {
      title: '',
      arrayValues: [],
      isRequired: false,
      isSendToProject: false,
      isValid: false
    },
    checkboxTab: {
      title: '',
      arrayValues: [],
      isRequired: false,
      isSendToProject: false,
      isValid: false
    },
    documentTab: {
      title: '',
      isRequired: false,
      isSendToProject: false,
      isValid: false
    }
  },
  isFullFormTasksOpen: false,
  isSendToProject: false,
  deleteTypicalTaskModal: {
    taskId: '',
    isDeleteModalOpen: false,
    isShowPreloader: false,
    statusFromServer: {constraints: []}
  },
  searchTypicalTasks: '',
  typicalTasksMeta: null,
  currentPage: 1
} as ITypicalState;

export type TypicalTasksState = typeof initialState;

const reducer: Reducer<TypicalTasksState, TypicalTasksAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {

    case TypicalTasksActionTypes.SET_TYPICAL_TASKS:
      if(Array.isArray(action.tasks)) {
        if(action.action === 'add') {
          return {
            ...state,
            tasks: [
              ...state.tasks,
              ...action.tasks
            ]
          };
        }

        return {
          ...state,
          tasks: action.tasks
        };
      }
      else {
        return {
          ...state,
          tasks: [
            action.tasks,
            ...state.tasks,
          ],
        };
      }

    case TypicalTasksActionTypes.UPDATE_TYPICAL_TASK_STORE:
      const newTasks = [...state.tasks];
      const updateIndex = state.tasks.findIndex(task => task.id === action.task.id);

      if(updateIndex === -1) {
        return state;
      }

      newTasks[updateIndex] = action.task;

      return {...state, tasks: newTasks};
      
    case TypicalTasksActionTypes.SET_TYPICAL_TASKS_FILTER_USERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          usersIds: action.users,
        },
      };

    case TypicalTasksActionTypes.SET_TYPICAL_TASKS_FILTER_ACTIVITY:
      return {
        ...state,
        filters: {
          ...state.filters,
          activity: action.activity,
        },
      };

    case TypicalTasksActionTypes.SET_TYPICAL_TASKS_FILTER_PERSONAL:
      return {
        ...state,
        filters: {
          ...state.filters,
          personal: action.personal,
        },
      };

    case TypicalTasksActionTypes.SET_TYPICAL_TASKS_USERS_FOR_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          usersDataForFilter: action.usersDataForFilter,
        },
      };

    case TypicalTasksActionTypes.SET_TYPICAL_TASKS_USERS_FOR_FILTER_IS_LOADING:
      return {
        ...state,
        filters: {
          ...state.filters,
          usersDataForFilterIsLoading: action.usersDataForFilterIsLoading,
        },
      };

    case TypicalTasksActionTypes.SET_TASK_FORM_OPEN:
      return {
        ...state,
        isFormTasksOpen: action.isFormTasksOpen,
      };

    case TypicalTasksActionTypes.SET_TASK_FULL_FORM_OPEN:
      return {
        ...state,
        isFullFormTasksOpen: action.isFullFormTasksOpen,
      };

    case TypicalTasksActionTypes.SET_LOADING_TYPICAL_TASKS:
      return {
        ...state,
        isLoadingTypicalTasks: action.isLoadingTypicalTasks,
      };
      
    case TypicalTasksActionTypes.SET_LOADING_TYPICAL_TASK:
      return {
        ...state,
        isLoadingTypicalTask: action.isLoadingTypicalTask
      };

    // Из TaskInfoPlate
    case TypicalTasksActionTypes.DISPATCH_CHECKBOX_ITEM:
      let new_copy_checklist: any = [];
      
      if(state.normalForm.checkList?.length) {
        new_copy_checklist = state?.normalForm.checkList.map((item) => {
          return item;
        });
      }

      let currentElem;
      // @ts-ignore
      if (new_copy_checklist[action.orderNumber - 1]) {
        if (action.actionType === "delete" && action.orderNumber) {
          new_copy_checklist.splice(action.orderNumber - 1, 1);
        } else {
          currentElem = {
            name: action.text,
            done: action.checkboxState,
          };
        }
      } else {
        currentElem = {
          name: action.text,
          done: action.checkboxState,
        };
      }

      if (action.orderNumber != null && action.actionType !== "delete") {
        // @ts-ignore
        new_copy_checklist[parseInt(action.orderNumber) - 1] = currentElem;
      }

      return {
        ...state,
        normalForm: {
          ...state.normalForm,
          checkList: new_copy_checklist
        },
      };
      
    // Normal form

    case TypicalTasksActionTypes.SET_NORMAL_FORM_DATA:
      return {
        ...state,
        normalForm: {
          ...state.normalForm,
          ...action.normalFormData
        },
      };

    //  Extra form

    case TypicalTasksActionTypes.SET_EXTRA_FORM_DEFAULT_OPEN:
      return {
        ...state,
        extraForm: {
          ...state.extraForm,
          isExtraFormDefaultOpen: action.isExtraFormDefaultOpen,
        },
      };
      
    case TypicalTasksActionTypes.SET_EXTRA_FORM_INPUT_TAB_DATA:
      return {
        ...state,
        extraForm: {
          ...state.extraForm,
          inputTab: {
            ...state.extraForm.inputTab,
            ...action.inputTabData,
          },
        },
      };
      
    case TypicalTasksActionTypes.SET_EXTRA_FORM_SELECT_TAB_DATA:
      return {
        ...state,
        extraForm: {
          ...state.extraForm,
          selectTab: {
            ...state.extraForm.selectTab,
            ...action.selectTabData
          },
        },
      };
      
    case TypicalTasksActionTypes.SET_EXTRA_FORM_RADIO_TAB_DATA:
      return {
        ...state,
        extraForm: {
          ...state.extraForm,
          radioTab: {
            ...state.extraForm.radioTab,
            ...action.radioTabData 
          }
        }
      }

    case TypicalTasksActionTypes.SET_EXTRA_FORM_CHECKBOX_TAB_DATA:
      return {
        ...state,
        extraForm: {
          ...state.extraForm,
          checkboxTab: {
            ...state.extraForm.checkboxTab,
            ...action.checkboxTabData 
          }
        }
      }

    case TypicalTasksActionTypes.SET_EXTRA_FORM_DOCUMENT_TAB_DATA:
      return {
        ...state,
        extraForm: {
          ...state.extraForm,
          documentTab: {
            ...state.extraForm.documentTab,
            ...action.documentTabData 
          }
        }
      }

    case TypicalTasksActionTypes.SET_EXTRA_FORM_ARRAY_VALUES: {
      const {selectedTabName, value} = action;
      
      let needPush = true;
      let data: TArrayValues[] = [];

      if(!state.extraForm[selectedTabName].arrayValues.length) {
        data = [{id: nanoid(6), value}];
      } 
      else {
        state.extraForm[selectedTabName].arrayValues.forEach(value => {
          if(value.value === value) {
            needPush = false;
          }
        });

        if(needPush) {
          data = [
            ...state.extraForm[selectedTabName].arrayValues,
            {id: nanoid(6), value}
          ];
        }
      }

      if(data.length) {
        return {
          ...state,
          extraForm: {
            ...state.extraForm,
            [selectedTabName]: {
              ...state.extraForm[selectedTabName],
              arrayValues: data
            }
          }
        }
      }

      return state;
    }
    
    case TypicalTasksActionTypes.DELETE_EXTRA_FORM_ARRAY_VALUES: {
      const {selectedTabName, id} = action;

      return {
        ...state,
        extraForm: {
          ...state.extraForm,
          [selectedTabName]: {
            ...state.extraForm[selectedTabName ],
            arrayValues: state.extraForm[selectedTabName].arrayValues.filter(el => el.id !== id)
          }
        }
      }
    }

    case TypicalTasksActionTypes.SET_EXTRA_FORM_CHECK_TRIGGER: {
      return {
        ...state,
        extraForm: {
          ...state.extraForm,
          checkTrigger: action.checkTrigger
        }
      }
    }

    case TypicalTasksActionTypes.SET_NORMAL_FORM_ADD_FILED_TRIGGER: {
      return {
        ...state,
        normalForm: {
          ...state.normalForm,
          addFieldTrigger: action.addFieldTrigger
        }
      }
    }

    case TypicalTasksActionTypes.SET_EXTRA_FORM_REFRESH_CHECKBOX_TRIGGER: {
      return {
        ...state,
        extraForm: {
          ...state.extraForm,
          refreshCheckboxTrigger: action.refreshCheckboxTrigger
        }
      }
    }

    case TypicalTasksActionTypes.SET_EXTRA_FORM_ACTIVE_TAB_INDEX: {
      return {
        ...state,
        extraForm: {
          ...state.extraForm,
          activeTabIndex: action.activeTabIndex
        }
      }
    }

    case TypicalTasksActionTypes.SET_CREATING_TYPICAL_TASK: {
      return {
        ...state,
        isCreatingTypicalTask: action.isCreatingTypicalTask
      }
    }

    case TypicalTasksActionTypes.SET_IS_EDIT_FORM: {
      return {
        ...state,
        isEditForm: action.isEditForm
      }
    }

    case TypicalTasksActionTypes.SET_FORM_DEFAULT_VALUES_TRIGGER: {
      return {
        ...state,
        normalForm: initialState.normalForm,
        extraForm: initialState.extraForm,
        formDefaultValuesTrigger: action.formDefaultValuesTrigger
      }
    }

    case TypicalTasksActionTypes.SET_IS_SHOW_MENU_PRELOADER: {
      return {
        ...state,
        isShowMenuPreloader: action.isShowMenuPreloader
      }
    }

    case TypicalTasksActionTypes.SET_DELETE_TYPICAL_TASK_MODAL_DATA:
      return {
        ...state,
        deleteTypicalTaskModal: {
          ...state.deleteTypicalTaskModal,
          ...action.data
        }
      };

    case TypicalTasksActionTypes.DELETE_TYPICAL_TASK_FROM_STATE:
      return {
        ...state,
        tasks: state.tasks.filter(task => task.id !== action.id)
      };

    case TypicalTasksActionTypes.SET_SORT_CONDITION:
      return {
        ...state,
        sortCondition: {
          ...state.sortCondition,
          ...action.sortCondition 
        }
      };

    case TypicalTasksActionTypes.SET_SEARCH_TYPICAL_TASKS:
      return {
        ...state,
        searchTypicalTasks: action.searchTypicalTasks
      }

    case TypicalTasksActionTypes.SET_TYPICAL_TASKS_META:
      return {
        ...state,
        typicalTasksMeta: action.typicalTasksMeta
      }

    case TypicalTasksActionTypes.SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.currentPage
      }
      
    default:
      return state;
  }
};

export default reducer;
