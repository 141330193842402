import React, { FC, useRef, useState } from 'react';
import { connect } from "react-redux";
import { State } from "../../../../rootReducer";
import PreloaderLocal from "../../../../common/components/PreloaderLocal/PreloaderLocal";
import {ReactComponent as CloseIcon} from '../../../../img/TrueIcons/close.svg';
import { AppBar, Box, Tab, Tabs, Typography } from "@material-ui/core";
import {
  createObject,
  deleteFileStore,
  setObjectEditingFormCheckTrigger,
  setObjectsEditingFormActiveTabIndex,
  setObjectsEditingFormOpen,
  updateObjects
} from "../../actions";
import { CheckContextsObjects, TabIdsObjectEditingForm } from "../../constants";
import Fade from "@material-ui/core/Fade";
import { nanoid } from "nanoid";
import { IEditingObjectsAuthor, IEditingObjectsFormData } from "../../interfaces";
import DescriptionTab from "./components/DescriptionTab/DescriptionTab";
import CommentsTab from "./components/CommentsTab/CommentsTab";
import DocumentsTab from "./components/DocumentsTab/DocumentsTab";
import useCheckRolesMeridian from "../../../../utils/hooks/useCheckRolesMeridian";

import './FormEditingObject.scss';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Fade in={value === index}>
      <div
        hidden={value !== index}
        id={`extra-form-tabpanel-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            <Typography component='div'>{children}</Typography>
          </Box>
        )}
      </div>
    </Fade>
  );
}

const FormEditingObject: FC<{
  isEditingObjectFormOpen: boolean,
  setObjectsEditingFormOpen: (isEditingObjectFormOpen: boolean) => void,
  setObjectsEditingFormActiveTabIndex: (activeTabIndex: number) => void,
  activeTabIndex?: number,
  isCreatingObject: boolean,
  isLoadingObject: boolean,
  isEditObjectsForm: boolean,
  updateObjects: (data: object, files: FileList) => void;
  createObject: (data: object, files: FileList) => void;
  setObjectEditingFormCheckTrigger: (checkTrigger: string) => void;
  formData: IEditingObjectsFormData,
  author?: IEditingObjectsAuthor,
  deleteFileStore: (id: string|number) => void,
  idObjectEdited?: number|string
}> = ({
  isEditingObjectFormOpen,
  setObjectsEditingFormActiveTabIndex,
  activeTabIndex,
  isCreatingObject,
  isLoadingObject,
  setObjectsEditingFormOpen,
  isEditObjectsForm,
  updateObjects,
  createObject,
  setObjectEditingFormCheckTrigger,
  formData,
  author,
  deleteFileStore,
  idObjectEdited
}) => {
   const { isUserCanEditObject } = useCheckRolesMeridian(CheckContextsObjects.IS_USER_CAN_EDIT_OBJECT, {
    authorId: author?.id
  });
  const formObjectsRef = useRef<HTMLDivElement | null>(null);
  const [filesToUpload, setFilesToUpload] = useState<any>();
  
  const onCloseHandler = () => {
    setObjectsEditingFormOpen(false);
  };

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setObjectsEditingFormActiveTabIndex(newValue);
  };

  const collectData = (): object => {
    const dataObject: any = {
      name: formData.name,
      declarant: formData.declarant,
      title: formData.title,
      numberSap: formData.numberSap,
      responsible: formData.responsible?.userSelected,
      contractNumber: formData.contractNumber,
      startDate: formData.startDate,
      endDate: formData.endDate,
      cost: formData.cost
    };
    
    if(isEditObjectsForm) {
      dataObject.id = idObjectEdited;
    }

    return dataObject;
  };
  
  const onSubmitHandler = () => {
    setObjectEditingFormCheckTrigger(nanoid(6));
    
    let data: any = {};
    
    setTimeout(() => {
      const isFormHaveError = !!(formObjectsRef.current?.querySelector('[class*="error"]'));
      
      // TODO удалить, для тестирования
      // data = collectData(); 
      // createObject(data, filesToUpload);
      // return
      
      if(!isFormHaveError) {
        data = collectData();

        if(isEditObjectsForm) updateObjects(data, filesToUpload);
        else createObject(data, filesToUpload);
      }
    });
  }

  const formCommonFileHandler = (files: FileList) => {
    if(files) {
      setFilesToUpload(files);
    }
  }

  const onDeleteFileHandler = (id: string|number, name: string) => {
    deleteFileStore(id);
    
    setFilesToUpload((state) => {
      const newState: any = [];

      if(state) {
        // @ts-ignore
        for(let file of state) {
          if(file.name !== name) newState.push(file);
        }
      }

      return newState;
    });
  };
  
  return (
    <div className={
        "form-editing-object" +
        (isEditingObjectFormOpen ? " form-editing-object--open" : "") +
        (isLoadingObject ? " form-editing-object--show-preloader" : "") +
        (!isEditObjectsForm ? " form-editing-object--add-form" : "")
      }
         ref={formObjectsRef}
    >
      <div className="form-editing-object__content-wr">
        <div className="form-editing-object__content">
          <div className="form-editing-object__preloader">
            <PreloaderLocal size="big" />
          </div>

          <div className="form-editing-object__title-wr">
            <h2 className="form-editing-object__title">
              {isEditObjectsForm && (
                <>{formData.name}</>
              )}

              {!isEditObjectsForm && (
                <>Добавляем новый объект</>
              )}
            </h2>

            <button className="form-editing-object__close"
                    onClick={onCloseHandler}
            >
              <CloseIcon />
            </button>
          </div>

          {/* {(formData.stage && formData.stage?.length > 0) && ( */}
            <div className="form-editing-object__title-stage"> 
              Текущий этап - ПИР 
              {/* {formData.stage} - текущий этап объекта(в первом релизе только ПИР) */}
            </div>
          {/* )} */}

          <div className="form-editing-object__tabs">
            <AppBar className="form-editing-object__tabs-header" position="static">
              <Tabs value={activeTabIndex} onChange={handleChange}>
                <Tab label="Описание" />
                <Tab label={
                  "Комментарии" + (formData.commentsCount ? ' (' + formData.commentsCount + ')' : '')
                }/>
                <Tab label={
                  "Документы" + (formData.documentsCount ? ' (' + formData.documentsCount + ')' : '')
                }/>
                <Tab label="История" disabled />
              </Tabs>
            </AppBar>

            <div className="form-editing-object__tab-content">
              <TabPanel value={activeTabIndex} index={TabIdsObjectEditingForm.DESCRIPTION_TAB}>
                <DescriptionTab
                  formCommonFileHandler={formCommonFileHandler}
                  isUserCanEdit={isUserCanEditObject}
                  onDeleteFile={onDeleteFileHandler}
                />
              </TabPanel>
              <TabPanel value={activeTabIndex} index={TabIdsObjectEditingForm.COMMENTS_TAB}>
                <CommentsTab isUserCanEdit={isUserCanEditObject} />
              </TabPanel>
              <TabPanel value={activeTabIndex} index={TabIdsObjectEditingForm.DOCUMENTS_TAB}>
                <DocumentsTab />
              </TabPanel>
              <TabPanel value={activeTabIndex} index={TabIdsObjectEditingForm.HISTORY_TAB}>
                История
              </TabPanel>
            </div>

            <div className="form-editing-object__btns">
              {activeTabIndex == TabIdsObjectEditingForm.DESCRIPTION_TAB && (
                <button className={"form-editing-object__btn form-editing-object__btn--submit" +
                  (isCreatingObject ? " form-editing-object__btn--show-preloader" : "")}
                        onClick={onSubmitHandler}
                        disabled={!isUserCanEditObject}
                >
              <span className="form-editing-object__btn-title">
                {isEditObjectsForm && (
                  <>Сохранить</>
                )}

                {!isEditObjectsForm && (
                  <>Добавить объект</>
                )}
              </span>
                  <span className="form-editing-object__btn-preloader">
                <PreloaderLocal />
              </span>
                </button>
              )}

              <button className="form-editing-object__btn form-editing-object__btn--cancel"
                      onClick={onCloseHandler}
              >
                <span className="form-editing-object__btn-title">Отменить</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: State) => ({
  isCreatingObject: state.objectsPage.isCreatingObject,
  isLoadingObject: state.objectsPage.isLoadingObject,
  isEditingObjectFormOpen: state.objectsPage.isEditingObjectsFormOpen,
  activeTabIndex: state.objectsPage.editingObjectsForm.activeTabIndex,
  isEditObjectsForm: state.objectsPage.isEditObjectsForm,
  formData: state.objectsPage.editingObjectsForm.data,
  author: state.objectsPage.editingObjectsForm.data.author,
  idObjectEdited: state.objectsPage.editingObjectsForm.idObjectEdited
});

const mapDispatchToProps = {
  setObjectsEditingFormOpen,
  setObjectsEditingFormActiveTabIndex,
  updateObjects,
  createObject,
  setObjectEditingFormCheckTrigger,
  deleteFileStore
};

export default connect(mapStateToProps, mapDispatchToProps)(FormEditingObject);
