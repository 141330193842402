import { ITypicalTaskServerData } from "../TypicalTasks/interfaces";
import { TypeBPFilterActivity } from "./action_types";
import { taskBPTypes } from "./constants";
import { ControlPosition } from "react-draggable";

export interface ISortConditions {
  columnId: string;
  value: number;
}

export interface IBusinessProcessServerData {
  typical_tasks?: any;
  id: number;
  name: string;
  treaty: {
    name: string;
    id: number;
  }[];
  count_tasks: number;
  time?: string;
  duration: string;
  author: {
    id: string | number;
    surname: string;
    name: string;
    image: string | null;
  };
  executor?: { name: string; surname: string };
  created_at: string;
  status: number;
}

export enum BusinessProcessActivity {
  ALL_TEXT = "Все",
  ACTIVE_TEXT = "Активные",
  ACTIVE_TEXT_FORM = "Активный",
  NOT_ACTIVE_TEXT_FORM = "Неактивный",
  NOT_ACTIVE_TEXT = "Неактивные",
  ALL_STATUS = 0,
  ACTIVE_STATUS = 1,
  NOT_ACTIVE_STATUS = 2,
}

export enum BusinessProcessPersonal {
  NOT_PERSONAL_STATUS = 0,
  PERSONAL_STATUS = 1,
}

export enum FilterTypicalTasksBp {
  ALL_TEXT = "Не важно",
  NOT_ACTIVE_TYPICAL_TASKS = "Не используются",
  ALL_STATUS = 0,
  NOT_ACTIVE_STATUS = 0.1,
}

export interface IDeleteBusinessProcessModal {
  bpId?: string|number,
  isDeleteModalOpen?: boolean,
  isShowPreloader?: boolean,
  statusFromServer?: {constraints: string[] | []}
}

export interface IActiveBusinessProcessModal {
  bpId?: string|number,
  isActiveModalOpen?: boolean,
  isShowPreloader?: boolean,
  statusFromServer?: {constraints: string[] | []}
}

export interface INormalFormBPData {
  idBPEditing?: number|string,
  name?: string;
  actionConnectIdTrigger?: number|null,
  tasksItemsUpdateTrigger?: null|string,
  tasksItems?: ITaskBP[] | any,
  activity?: TypeBPFilterActivity;
  author?: string,
  authorId?: string|number,
  createdAt?: string,
}

export interface IExtraFormBPData {
  isLoading: boolean;
  typicalTasksBp: ITypicalTaskServerData[],
  currentPageTypicalTasks: number;
  searchTypicalTasksBp: string | null;
  isExtraFormBPDefaultOpen: boolean;
  bpForFilter: IBPFilterFormExtra;
  treatyForFilterTasks: IBPFilterFormExtra;
}

export interface ITaskBP {
  parentIndex?: number;
  id: string|number,
  title: string,
  taskType: taskBPTypes,
  isActive?: boolean,
  children?: ITaskBP[],
  time?: any,
  isParent?: boolean
}

export interface ITaskFlattenBP {
  controlledPosition?: ControlPosition | undefined;
  id: string|number,
  isParent: boolean,
  isLastParent: boolean,
  parentIndex: number,
  title: string,
  isActive: boolean,
  taskType: taskBPTypes,
  connectionAmount?: number,
  showPosition?: number,
  time?: any
}

export interface ITaskConnectModal {
  isModalOpen?: boolean,
  connectionStatusTrigger?: number|null,
  currentTask?: ITaskFlattenBP|null,
  overTask?: ITaskFlattenBP|null
}

export interface ITaskDeleteConnectionModal {
  isModalOpen?: boolean,
  connectionStatusTrigger?: number|null,
  connectionActionTrigger?: number|null
}

export interface IBPFilterFormExtra {
  value: string|number,
  label: string
}
