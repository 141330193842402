import React, { FC, useEffect, useState } from 'react';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { connect } from "react-redux";
import { State } from "../../../../../rootReducer";
import {ReactComponent as CloseIcon} from '../../../../../img/TrueIcons/close.svg';
import PreloaderLocal from "../../../../../common/components/PreloaderLocal/PreloaderLocal";
import { activeStateBusinessProcessStatuses } from '../../../constants';
import { BusinessProcessActivity, IActiveBusinessProcessModal, INormalFormBPData } from '../../../interfaces';
import { setChangeActiveStateModalBp, setNormalFormBp } from '../../../actions';

import './ModalActiveStateBP.scss';

const ModalStatusBp: FC<{
  isActiveModalOpen?: boolean,
  activeBpModal: IActiveBusinessProcessModal,
  setChangeActiveStateModalBp: (data: IActiveBusinessProcessModal) => void,
  setNormalFormBp: (mode: INormalFormBPData) => void;
}> = ({
  isActiveModalOpen,
  activeBpModal,
  setChangeActiveStateModalBp,
  setNormalFormBp
}) => {
  const textObj = {
    [activeStateBusinessProcessStatuses.ACTIVE_BUSINESS_PROCESS_ERROR]:
    {title: 'Деактивация бизнес-процесса', text: 'Бизнес-процесс нельзя деактивировать, так как он выполняется в договоре.'},
  };
  
  const [text, setText] = useState<{
    title: string,
    text: string,
    btnDeleteText?: string
  }>(textObj[activeStateBusinessProcessStatuses.ACTIVE_BUSINESS_PROCESS_ERROR]);

  useEffect(() => {
    if(activeBpModal.statusFromServer?.constraints.length) {
      const {constraints} = activeBpModal.statusFromServer;
      const result = textObj[constraints[0]];

      if(result) setText(result);
      else setText(textObj[activeStateBusinessProcessStatuses.ACTIVE_BUSINESS_PROCESS_ERROR]);
    }
  }, [activeBpModal.isActiveModalOpen]);

  const handleClose = () => {
    setChangeActiveStateModalBp({isActiveModalOpen: false});
    setNormalFormBp({activity: BusinessProcessActivity.ACTIVE_STATUS})
  };
  
  const statusCheck = () => {
    //изменение состояния
    setChangeActiveStateModalBp({isActiveModalOpen: false});
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={'modal-status-business-process'}
        open={isActiveModalOpen ?? false}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={isActiveModalOpen}>
          <div className={'modal-status-business-process__content-wr'}>
            <div className="modal-status-business-process__content">

              <div className="modal-status-business-process__content-item">
                <div className={'modal-status-business-process__title-wr'}>
                  <div className="modal-status-business-process__title">{text?.title}</div>
                  <div className="modal-status-business-process__delete-ico-wr"
                       onClick={handleClose}
                  >
                    <CloseIcon />
                  </div>
                </div>

                <div className="modal-status-business-process__text"
                     dangerouslySetInnerHTML={{__html: text?.text}}
                >
                </div>
              </div>
            </div>
            
            <div className="modal-status-business-process__btns">
              {text?.btnDeleteText &&
                <button className={"modal-status-business-process__btn modal-status-business-process__btn--status " + (activeBpModal.isShowPreloader ? ' modal-status-business-process__btn--show-preloader' : '')}>
                <span className="modal-status-business-process__btn-title"
                      onClick={statusCheck}
                >{text?.btnDeleteText}</span>
                  <span className="modal-status-business-process__btn-preloader">
                  <PreloaderLocal />
                </span>
                </button>
              }
              
              <button className="modal-status-business-process__btn modal-status-business-process__btn--cancel"
                      onClick={handleClose}
              >
                <span className="modal-status-business-process__btn-title">Отмена</span>
              </button>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
}

const mapStateToProps = (state: State) => {
  return {
    isActiveModalOpen: state.businessPage.activeStateBusinessProcessModal.isActiveModalOpen,
    activeBpModal: state.businessPage.activeStateBusinessProcessModal
  };
};

const mapDispatchToProps = {
  setChangeActiveStateModalBp,
  setNormalFormBp
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalStatusBp);
