import React, { useEffect } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import styled from "styled-components";
import GoToBp from "../../../../../img/projectIcons/goToBp";
import Trash from "../../../../../img/projectIcons/Trash";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { State } from "../../../../../rootReducer";
import { checkDeleteTask } from "../../../actions";
import { IDeleteTaskModal } from "../../../interfaces";
import PreloaderLocal from "../../../../../common/components/PreloaderLocal/PreloaderLocal";

import "./MenuTask.scss";

interface ITaskMenuProps {
  id: number | string;
  anchorEl: HTMLElement | null;
  setAnchorEl: (arg0: React.MouseEvent<HTMLButtonElement> | null) => void;
  checkDeleteTask: (id: string | number) => void;
  deleteTaskModal: IDeleteTaskModal;
}

const MenuStyle = {
  marginTop: "45px",
};

const MenuItemStyle = {
  display: "flex",
  alignItems: "center",
  marginBottom: "5px",
  fontSize: "14px",
};

const MenuIcon = styled.div`
  margin-right: 10px;
  display: flex;
  align-items: center;

  > svg {
    stroke: #292a34;
  }
`;

const MenuTitle = styled.div``;

const TaskMenu = ({
  id,
  anchorEl,
  setAnchorEl,
  checkDeleteTask,
  deleteTaskModal,
}: ITaskMenuProps) => {
  const history = useHistory();

  useEffect(() => {
    if (deleteTaskModal.isDeleteModalOpen) clickTaskActionsCloseHandler();
  }, [deleteTaskModal]);

  const clickTaskActionsCloseHandler = () => {
    setAnchorEl(null);
  };

  const clickDeleteHandler = (id: string | number, e: any) => {
    checkDeleteTask(id);
    e.stopPropagation();
  };

  return (
    <Menu
      className="task-menu"
      anchorEl={anchorEl}
      open={!!anchorEl}
      onClose={(e: any) => {
        clickTaskActionsCloseHandler();
        e.stopPropagation();
      }}
      style={MenuStyle}
    >
      <MenuItem
        style={{ ...MenuItemStyle, marginBottom: 0 }}
        onClick={(e) => clickDeleteHandler(id, e)}
      >
        <MenuIcon>
          <Trash />
        </MenuIcon>

        <MenuTitle
          className={
            "task-menu__title-wr" +
            (deleteTaskModal.isShowMenuPreloader
              ? " task-menu__title-wr--show-preloader"
              : "")
          }
        >
          <div className="task-menu__title">Удалить задачу</div>
          <div className="task-menu__preloader">
            <PreloaderLocal />
          </div>
        </MenuTitle>
      </MenuItem>
    </Menu>
  );
};

const mapStateToProps = (state: State) => {
  return {
    deleteTaskModal: state.tasksObjects.deleteTaskModal,
  };
};

const mapDispatchToProps = {
  checkDeleteTask,
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskMenu);
