import React, { FC } from "react";
import { columnsOfTablesTypicalTasks } from "../../sharedData";
import SortIcon from "../../../../common/components/SortIcon";
import { connect } from "react-redux";
import { State } from "../../../../rootReducer";
import { sortConditions } from "../../constants";
import { ISortConditions } from "../../interfaces";
import { getTypicalTasks, setCurrentPage, setSortCondition } from "../../actions";

import "./TableHeaderTypicalTasks.scss";

const TableHeaderTypicalTasks: FC<{
  sortCondition: ISortConditions,
  setSortCondition: (sortCondition: ISortConditions) => void,
  getTypicalTasks: (params?: object) => void,
  setCurrentPage: (currentPage: number) => void
}> = ({
  sortCondition,
  setSortCondition,
  getTypicalTasks,
  setCurrentPage
}) => {
  const onClickCondition = (columnId, value) => {
    setCurrentPage(1);
    setSortCondition({columnId, value});
    getTypicalTasks({columnId, value});
  };

  return (
    <div className="typical-tasks_table_header">
      <div className="block_table_head">
        {Object.keys(columnsOfTablesTypicalTasks).map((key) => {
          return columnsOfTablesTypicalTasks[key].columnId === sortConditions.ID ||
            columnsOfTablesTypicalTasks[key].columnId === sortConditions.NAME ||
            columnsOfTablesTypicalTasks[key].columnId === sortConditions.BUSINESS ||
            columnsOfTablesTypicalTasks[key].columnId === sortConditions.EXECUTOR ||
            columnsOfTablesTypicalTasks[key].columnId === sortConditions.AUTHOR ||
            columnsOfTablesTypicalTasks[key].columnId === sortConditions.CREATED_AT ||
            columnsOfTablesTypicalTasks[key].columnId === sortConditions.ACTIVITY ? (
              
            <div
              className={`header-item-typical-tasks__${columnsOfTablesTypicalTasks[key].columnId}`}
              key={columnsOfTablesTypicalTasks[key].columnId}
              // style={columnsOfTablesTypicalTasks[key].style}
            >
              <span
                onClick={() => {
                  const value = sortCondition.columnId === columnsOfTablesTypicalTasks[key].columnId
                    ? sortCondition.value === 2 ? 1 : 2
                    : 1;
                  
                  onClickCondition(columnsOfTablesTypicalTasks[key].columnId, value);
                }}
                className={`header_items_typical_tasks ${columnsOfTablesTypicalTasks[key].columnId}`}
              >
                
              <span className="text_header">{columnsOfTablesTypicalTasks[key].showingText}</span> 
                <SortIcon
                  active={sortCondition.columnId === columnsOfTablesTypicalTasks[key].columnId}
                  activeSort={sortCondition.columnId === columnsOfTablesTypicalTasks[key].columnId && sortCondition.value === 1 ? "top" : "bottom"
                  }
                />
              </span>
          </div>
          ) : null;
        })}
      </div>
    </div>
  );
};

const mapStateToProps = (state: State) => ({
  sortCondition: state.typicalTasksPage.sortCondition
});

const mapDispatchToProps = {
  setSortCondition,
  getTypicalTasks,
  setCurrentPage
};

export default connect(mapStateToProps, mapDispatchToProps)(TableHeaderTypicalTasks);
