import React, { useEffect, useState } from "react";
import moment from "moment";
import { ITypicalTaskServerData } from "../../../interfaces";
import { connect } from "react-redux";
import { 
  getTypicalTask, 
  setFormDefaultValuesTrigger, 
  setIsEditForm 
} from "../../../actions";
import { State } from "../../../../../rootReducer";
import { nanoid } from "nanoid";
import { TypicalTasksActivity } from "../../../../../common/constants";
import {ReactComponent as DotsIcon} from '../../../../../img/TrueIcons/dots.svg';
import TaskMenuTypicalTask from "../../ui/TaskMenuTypicalTask/TaskMenuTypicalTask";

import "./TypicalTask.scss";

type Props = {
  task: ITypicalTaskServerData;
  getTypicalTask: (id: string|number) => void,
  setIsEditForm: (isEditForm: boolean) => void,
  setFormDefaultValuesTrigger: (formDefaultValuesTrigger: string) => void
};

const TypicalTask: React.FC<Props> = ( {
  task,
  getTypicalTask,
  setIsEditForm,
  setFormDefaultValuesTrigger
}) => {
  const formatDate = (date) => moment(date).format('DD.MM.YYYY');
  const [anchorEl, setAnchorEl] = useState<HTMLElement|null>(null);
  const [isTaskMenuOpen, setIsTaskMenuOpen] = useState<boolean>(false);
  
  const [authorName, setAuthorName] = useState<string>(task.author?.surname + ' ' + task.author?.name);
  const [executorName, setExecutorName] = useState<string>(task.executor?.surname + ' ' + task.executor?.name);

  const openEditHandler = (id: string|number) => {
    if(isTaskMenuOpen) return;
    
    setFormDefaultValuesTrigger(nanoid(6));
    setIsEditForm(true);
    getTypicalTask(id);
  }
  
  const taskActionsOpenHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    setIsTaskMenuOpen(true);
    setAnchorEl(e.currentTarget);
  }

  const handleSetAnchorEl = value => {
    if(!value) setIsTaskMenuOpen(false);

    setAnchorEl(value);
  };

  useEffect(() => {
    setAuthorName(task.author?.surname + ' ' + task.author?.name)
  }, [task.author?.name, task.author?.surname]);

  useEffect(() => {
    setExecutorName(task.executor?.surname + ' ' + task.executor?.name)
  }, [task.executor?.name, task.executor?.surname]);
    
  return (
    <div className="task-typical"
          onClick={() => openEditHandler(task.id)}
    >
      <div className="id_task">{task.id}</div>
      <div className="name_task">{task.name}</div>
      <div className="task-typical__bp bp_task">
        <div className="task-typical__bp-items">
          {task?.bp?.length && (
            task.bp.map(el => (
              <div className="task-typical__bp-item">{el}</div>
            ))
          )}

          {!task?.bp?.length && (
            <div className="task-typical__bp-item">&mdash;</div>
          )}
        </div>
      </div>
      <div className="executor_task">
        <div className='task-typical__avatar'>
          {task.executor?.image && (
            <div className="task-typical__avatar-img-wr">
              <img className='task-typical__avatar-img'
                   src={task.executor.image}
                   alt={executorName}
              />
            </div>
          )}

          {!task.executor?.image && (
            <div className="task-typical__avatar-text">
              {task.executor?.surname.substring(0, 1)}
              {task.executor?.name.substring(0, 1)}
            </div>
          )}
        </div>
        {executorName}
      </div>
      
      <div className="author_task">
        <div className='task-typical__avatar'>
          {task.author?.image && (
            <div className="task-typical__avatar-img-wr">
              <img className='task-typical__avatar-img' 
                   src={task.author.image} 
                   alt={authorName} 
              />
            </div>
          )}

          {!task.author?.image && (
            <div className="task-typical__avatar-text">
              {task.author?.surname.substring(0, 1)}
              {task.author?.name.substring(0, 1)}
            </div>
          )}
        </div>
        {authorName}
      </div>
      
      <div className="date_task">{formatDate(task?.created_at)}</div>
      
      <div className="activity_task">
        {task.status ? 
          TypicalTasksActivity.ACTIVE_TEXT_FORM : 
          TypicalTasksActivity.NOT_ACTIVE_TEXT_FORM
        }
      </div>
      
      <div className='task-typical__actions'>
        <button className='task-typical__actions-btn'
          onClick={(e) => taskActionsOpenHandler(e)}
        >
          <DotsIcon />
        </button>
      </div>

      <TaskMenuTypicalTask
        id={task.id}
        anchorEl={anchorEl}
        setAnchorEl={handleSetAnchorEl}
      />
    </div>
  );
};

const mapStateToProps = (state: State) => ({
  
});

const mapDispatchToProps = {
  getTypicalTask,
  setIsEditForm,
  setFormDefaultValuesTrigger
};

export default connect(mapStateToProps, mapDispatchToProps)(TypicalTask);
